import {
  postLike,
  postLikeReset,
  postUnLike,
  postUnLikeReset,
} from './postLike.actions';
import type { DefaultResponse } from '../../../services/RoRApiProvider';

export const mapStateToProps = state => {
  return {
    postLikeData: state.postLike.data,
    postLikeError: state.postLike.error,
    postLikeIsLoading: state.postLike.isLoading,
  };
};

export const mapDispatchToProps = dispatch => {
  return {
    postLike(payload) {
      dispatch(postLike(payload));
    },
    postLikeReset() {
      dispatch(postLikeReset());
    },
    postUnlike(payload) {
      dispatch(postUnLike(payload));
    },
    postUnLikeReset() {
      dispatch(postUnLikeReset());
    },
  };
};

export type PostLikeMapStateToProps = {
  postLikeData: DefaultResponse,
  postLikeError: any,
  postLikeIsLoading: boolean,
};

export type PostLikeMapDispatchToProps = {
  postLike: () => void,
  postUnLike: () => void,
  postLikeReset: () => void,
  postUnLikeReset: () => void,
};

export default {
  mapStateToProps,
  mapDispatchToProps,
};
