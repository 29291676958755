// @flow
import React from 'react';
import moment from 'moment';
import _get from 'lodash/get';
import _throttle from 'lodash/throttle';
import { message } from 'antd';
import * as yup from 'yup';
import { Formik, FormikComputedProps } from 'formik';
import { connect } from 'react-redux';
import { Scrollbars } from 'react-custom-scrollbars';
import Avatar from '../Avatar/Avatar';
import Button from '../Button/Button';
import Input from '../Input/Input';
import Select from '../Select/Select';
import Toggle from '../Toggle/Toggle';
import RadioButtons from '../RadioButtons/RadioButtons';
import account from '../../redux/modules/account/account.containers';
import accountDetails from '../../redux/modules/accountDetails/accountDetails.containers';
import contributionCommittedDonationCalculate from '../../redux/modules/contributionCommittedDonationCalculate/contributionCommittedDonationCalculate.containers';
import contributionCommittedDonationMake from '../../redux/modules/contributionCommittedDonationMake/contributionCommittedDonationMake.containers';
import type { AccountDetailsMapStateToProps } from '../../redux/modules/accountDetails/accountDetails.containers';
import type { UserDetails } from '../../services/RoRUsersApiProvider';
import type {
  ContributionCommittedDonationCalculateDispatchToProps,
  ContributionCommittedDonationCalculateMapStateToProps,
} from '../../redux/modules/contributionCommittedDonationCalculate/contributionCommittedDonationCalculate.containers';
import type {
  ContributionCommittedDonationMakeDispatchToProps,
  ContributionCommittedDonationMakeMapStateToProps,
} from '../../redux/modules/contributionCommittedDonationMake/contributionCommittedDonationMake.containers';
import LoadingSpinner from '../LoadingSpinner/LoadingSpinner';
import type { WithDisplayDimensionsOutputProps } from '../../hoc/withDisplayDimensions';
import withDisplayDimensions from '../../hoc/withDisplayDimensions';
import { CONTRIBUTE_MIN_AMOUNT } from '../ContributePayment/ContributePayment';
import './ContributeCommitted.scss';
import ContributePaymentMethodSelector from '../ContributePaymentMethodSelector/ContributePaymentMethodSelector';
import {
  CONTRIBUTION_CREDIT_CARD_EXTRA_CHARGE,
  CONTRIBUTION_ACH_FREE,
} from '../../constants/forms';
import { receivingContributionLimit } from '../../utilities/authorization';
import formatCurrency from '../../utilities/formatCurrency';

type Props = AccountDetailsMapStateToProps &
  ContributionCommittedDonationCalculateDispatchToProps &
  ContributionCommittedDonationCalculateMapStateToProps &
  WithDisplayDimensionsOutputProps &
  ContributionCommittedDonationMakeDispatchToProps &
  ContributionCommittedDonationMakeMapStateToProps & {
    userDetails: UserDetails,
    onGoBackRequest: Function,
    onPaymentRequest: Function,
    customPaymentToken?: string,
    customPaymentEmail?: string,
    onAddNewBankAccountRequest?: Function,
    onPaymentSuccess?: (paymentData: any) => void,
    groupId?: number,
  };

type PaymentData = {
  timeSpan: number,
  paidOutFrequency: number,
  total: number,
};

type State = PaymentData & {
  formikFormTouched: boolean,
  mobileStep: number,
};

class ContributeCommitted extends React.Component<Props, State> {
  formikForm: any = null;

  constructor(props: Props) {
    super(props);

    this.state = {
      total: 0,
      timeSpan: 12,
      paidOutFrequency: 1,
      formikFormTouched: false,
      mobileStep: 1,
    };

    this.props.contributionCommittedDonationMakeReset();
    this.props.contributionCommittedDonationCalculateReset();
  }

  componentDidUpdate(prevProps: Props) {
    if (this.props.isMobile !== prevProps.isMobile) {
      this.setState(() => ({ mobileStep: 1 }));
    }

    if (
      !prevProps.contributionCommittedDonationMakeData &&
      this.props.contributionCommittedDonationMakeData
    ) {
      message.success(
        `
        You have successfully made a committed contribution to
        ${this.props.userDetails.first_name} ${this.props.userDetails.last_name}
      `,
        5,
      );

      if (this.props.onPaymentSuccess) {
        this.props.onPaymentSuccess({
          ...this.props.contributionCommittedDonationMakeData,
          type: 'committed',
        });
      }
    }

    if (
      !prevProps.contributionCommittedDonationMakeError &&
      this.props.contributionCommittedDonationMakeError
    ) {
      message.error(
        this.props.contributionCommittedDonationMakeError.localMessage ||
          'An error has occurred and your contribution has been canceled',
        5,
      );
    }
  }

  render() {
    return (
      <div className="contribute-committed">
        {this.renderHead()}
        <div className="contribute-committed__body">
          <Formik {...this.getFormikProps()}>
            {props => this.renderInnerForm(props)}
          </Formik>
        </div>
      </div>
    );
  }

  renderHead() {
    return (
      <div className="align-items-center pt-4 pb-1 pl-3 pr-3 border-bottom text-center">
        <div className="row fix-width">
          <div className="col-12 col-md-3 text-left contribute-committed__button-change-wrapper">
            <Button
              className="contribute-committed__button-change"
              size="small"
              type="button"
              buttonType="link"
              onClick={this.handleGoBackButton}>
              &lt;{' '}
              {this.props.isMobile && this.state.mobileStep > 1
                ? 'Back'
                : 'Change'}
            </Button>
          </div>
          <div className="col-12 col-md-6 mt-0 mb-3">
            <h3 className="contribute-committed__title">
              Committed Contribution
            </h3>
          </div>
          <div className="col-12 col-md-3 text-md-right mb-4">
            <div className="contribute-committed__candidate justify-content-center justify-content-md-end">
              <span>To</span>
              <Avatar
                source={this.props.userDetails.profile_image}
                type="x-small"
                isCandidate
              />
              <span>
                {this.props.userDetails.first_name}{' '}
                {this.props.userDetails.last_name}
              </span>
            </div>
          </div>
        </div>
      </div>
    );
  }

  renderInnerForm(props: FormikComputedProps) {
    const { handleSubmit } = props;
    return (
      <form className="contribute-committed__form" onSubmit={handleSubmit}>
        <div className="row no-gutters">
          {!this.props.isMobile || this.state.mobileStep === 1
            ? this.renderFirstStep(props)
            : null}
          {!this.props.isMobile || this.state.mobileStep === 2
            ? this.renderSecondStep(props)
            : null}
          {!this.props.isMobile || this.state.mobileStep === 3
            ? this.renderThirdStep(props)
            : null}
        </div>
        {this.renderFooter(props)}
      </form>
    );
  }

  renderFirstStep(props: FormikComputedProps) {
    const { touched, errors, values } = props;

    return (
      <div className="col-md-4 px-4 py-4 contribute-committed__step1">
        <div className="row justify-content-between pt-5 pb-3">
          <div className="col-5 d-flex text-left align-items-center justify-content-between">
            <div className="contribute-committed__total-text">
              <h4 className="contribute-committed__label--big mb-0">Total</h4>
              <div className="contribute-committed__total-text-sign">$</div>
            </div>
          </div>
          <div className="col-7 pl-0 d-flex align-items-center">
            <Input
              className="mb-0"
              label=""
              placeholder=""
              name="total"
              type="number"
              value={this.state.total}
              useFormik={false}
              onChange={_throttle(this.handleTotalInputChange, 750)}
              min={CONTRIBUTE_MIN_AMOUNT}
              max={this.getMaxContribution()}
              error={
                !this.isTotalValid() && this.state.formikFormTouched
                  ? `You can contribute between ${formatCurrency(
                      CONTRIBUTE_MIN_AMOUNT,
                    )} and ${formatCurrency(this.getMaxContribution())}`
                  : ''
              }
            />
          </div>
        </div>
        <p className="fs-13 pt-2 pb-4">
          This is the total amount you’ll contribute over the entire period,
          which will then be divided according to your distribution choice.
        </p>
        <div className="row">
          <div className="col-12 text-left">
            <p className="contribute-committed__label">
              Pay ${parseFloat(this.state.total || 0).toFixed(2)} with:
            </p>
            <ContributePaymentMethodSelector
              error={
                touched.paymentMethod && errors.paymentMethod
                  ? errors.paymentMethod
                  : ''
              }
              customToken={this.props.customPaymentToken}
              customEmail={this.props.customPaymentEmail}
            />
            <p className="contribute-committed__label--micro">
              {CONTRIBUTION_CREDIT_CARD_EXTRA_CHARGE}{' '}
              <span className="contribute-committed__label--micro-strong">
                {CONTRIBUTION_ACH_FREE}
              </span>
            </p>
          </div>
        </div>
        {this.props.onAddNewBankAccountRequest ? (
          <div className="row mt-2">
            <div className="col-md-4 pb-4 pb-md-0 d-flex justify-content-center justify-content-md-end order-3">
              <Button
                type="button"
                buttonType="link"
                className="p-0"
                block
                noBorder
                onClick={this.props.onAddNewBankAccountRequest}
                size="small"
                noTextTransform={true}>
                Add new bank account
              </Button>
            </div>
          </div>
        ) : null}
      </div>
    );
  }

  renderSecondStep(props: FormikComputedProps) {
    const { touched, errors } = props;

    return (
      <div className="col-md-4 px-md-4 py-md-4 contribute-committed__step2">
        <div className="row fix-width pt-2 pt-md-4 pb-md-3 align-items-center">
          <div className="col-4 pt-2 mt-1">
            <p className="contribute-committed__label">Over the next</p>
          </div>
          <div className="col-8 pl-3">
            <Select
              label=""
              size="small"
              name="timeSpan"
              useFormik={false}
              value={this.state.timeSpan.toString()}
              onChange={this.handleTimeSpanSelectChange}
              options={this.getSelectTimeSpanOptions()}
              error={!this.state.timeSpan ? 'This field is required' : ''}
            />
          </div>
        </div>
        <div className="row fix-width pt-md-4">
          <div className="col-4 pt-2 mt-1">
            <p className="contribute-committed__label">Paid out every</p>
          </div>
          <RadioButtons
            label=""
            className="col-12"
            items={this.getRadioPaidOutItems()}
            onChange={this.handlePaidOutFrequencyChange}
            value={this.state.paidOutFrequency.toString()}
            size="big"
            useFormik={false}
            name="paidOutFrequency"
            error={!this.state.paidOutFrequency ? 'This field is required' : ''}
          />
        </div>
      </div>
    );
  }

  renderThirdStep(props: FormikComputedProps) {
    const { touched, errors } = props;

    return (
      <div className="col-md-4 px-4 py-4 contribute-committed__step3">
        <div className="px-md-4 py-4">
          <Scrollbars universal style={{ height: 320 }}>
            {this.getPayments().map((payment, index) =>
              this.renderPaymentInput(payment, index),
            )}
          </Scrollbars>
        </div>
      </div>
    );
  }

  renderFooter(props: FormikComputedProps) {
    const { touched, values, errors } = props;

    return (
      <div className="contribute-committed__footer">
        <div className="row pt-4 pb-2">
          <div className="col-md-4 text-center text-left-md order-2 order-md-1">
            <div className="d-flex pt-3 pb-2 pl-3">
              <Toggle
                name="public"
                error={touched.public && errors.public ? errors.public : ''}
              />
              <p className="modal-toggle-label text-uppercase">Public</p>
            </div>
            <hr className="mt-1 d-block d-md-none" />
          </div>
          <div className="col-md-4 pb-4 pb-md-0 d-flex justify-content-md-center justify-content-md-end order-3">
            <Button
              type="button"
              buttonType="outline"
              className="mt-0"
              block
              noBorder
              onClick={this.props.onGoBackRequest}
              size={this.props.isMobile ? 'small' : 'normal'}>
              Cancel
            </Button>
            {!this.props.isMobile || this.state.mobileStep === 3 ? (
              <Button
                type="button"
                buttonType="primary"
                className="mt-0"
                block
                noBorder
                onClick={this.handleConfirmButtonClick}
                size={this.props.isMobile ? 'small' : 'normal'}
                loading={this.props.contributionCommittedDonationMakeIsLoading}>
                Confirm
              </Button>
            ) : null}
            {this.props.isMobile && this.state.mobileStep < 3 ? (
              <Button
                type="button"
                buttonType="primary"
                className="mt-0"
                block
                noBorder
                onClick={() => this.handleMobileNextButton(props)}
                size="small">
                Next
              </Button>
            ) : null}
          </div>
        </div>
      </div>
    );
  }

  renderPaymentInput(payment: { date: string, amount: number }, index: number) {
    return (
      <div
        className="row no-gutters pt-3 pb-1 justify-content-between fix-width"
        key={index}>
        <div className="col-4 d-flex flex-column justify-content-center align-content-start">
          <p className="contribute-committed__label mb-1">
            <span className="fs-18">{index + 1}</span>st payment
          </p>
          <p className="small mb-0">{payment.date}</p>
        </div>
        <div className="col-6 pr-3 d-flex align-items-center justify-content-end">
          <p className="contribute-committed__label px-3 mb-0">$</p>
          <Input
            className="mb-0"
            label=""
            placeholder=""
            size="small"
            name={`payment-${index}`}
            value={payment.amount.toFixed(2)}
            useFormik={false}
            disabled={true}
            type="number"
            error={''}
          />
          <LoadingSpinner
            className="contribute-committed__payment-spinner"
            size="small"
            type="dark"
            visible={this.props.contributionCommittedDonationCalculateIsLoading}
          />
        </div>
      </div>
    );
  }

  getFormikProps() {
    return {
      initialValues: {
        paymentMethod: 'newCard',
        public: '1',
      },
      validationSchema: yup.object().shape({
        paymentMethod: yup.string().required('This field is required'),
      }),
      onSubmit: this.handleFormSubmit,
      ref: this.setFormikRef,
    };
  }

  getSelectTimeSpanOptions() {
    // TODO: confirm allowed options
    return [
      { label: '12 months (full cycle)', value: '12' },
      { label: '6 months', value: '6' },
      { label: '3 months', value: '3' },
    ];
  }

  getRadioPaidOutItems() {
    return [
      { label: 'Month', value: '1' },
      { label: '2 months', value: '2' },
      { label: '3 months', value: '3' },
    ];
  }

  callCalculatePaymentService() {
    this.props.calculateCommittedDonation({
      amount_in_cents: this.state.total * 100,
      period_in_months: this.state.timeSpan,
      frequency_in_months: this.state.paidOutFrequency,
      customToken: this.props.customPaymentToken,
      customEmail: this.props.customPaymentEmail,
    });
  }

  setFormikRef = (ref: Formik) => {
    this.formikForm = ref;
  };

  handleTimeSpanSelectChange = ({ value }) => {
    const timeSpan = parseInt(value);
    this.setState(
      () => ({
        timeSpan,
      }),
      () => {
        this.callCalculatePaymentService();
      },
    );
  };

  handlePaidOutFrequencyChange = ({ value }) => {
    const paidOutFrequency = parseInt(value);

    this.setState(
      () => ({
        paidOutFrequency,
      }),
      () => {
        this.callCalculatePaymentService();
      },
    );
  };

  handleTotalInputChange = ({ value }) => {
    const numericValue = parseFloat(value);
    const total = numericValue >= 0 ? numericValue : 0;

    this.setState(
      () => ({
        total,
      }),
      () => {
        this.callCalculatePaymentService();
      },
    );
  };

  handleGoBackButton = () => {
    if (
      this.props.onGoBackRequest &&
      (!this.props.isMobile || this.state.mobileStep === 1)
    ) {
      this.props.onGoBackRequest();
    } else {
      this.setState((prevState: State) => ({
        mobileStep: prevState.mobileStep - 1,
      }));
    }
  };

  handleMobileNextButton = (props: FormikComputedProps) => {
    const { setTouched } = props;
    if (this.state.mobileStep === 1 && !this.isTotalValid()) {
      this.setState(
        () => ({ formikFormTouched: true }),
        () => {
          setTouched({
            paymentMethod: true,
          });
        },
      );
    } else {
      this.setState((prevState: State) => ({
        mobileStep: prevState.mobileStep + 1,
      }));
    }
  };

  getPayments() {
    const calculatedPayment = _get(
      this.props,
      'contributionCommittedDonationCalculateData.schedule',
    );
    const { timeSpan, paidOutFrequency } = this.state;
    const extraMonth = timeSpan % paidOutFrequency > 0;
    const numberOfPayments = !extraMonth
      ? Math.floor(timeSpan / paidOutFrequency)
      : Math.floor(timeSpan / paidOutFrequency) + 1;
    const payments = [];

    if (calculatedPayment) {
      calculatedPayment.forEach(payment => {
        payments.push({
          date: moment(payment.due_date).format('YYYY/MM/DD'),
          amount: payment.amount_in_cents / 100,
        });
      });
    } else {
      for (let i = 0; i < numberOfPayments; i += 1) {
        payments.push({
          date: moment().format('YYYY/MM/DD'),
          amount: 0,
        });
      }
    }

    return payments;
  }

  getMaxContribution = () => {
    return receivingContributionLimit(this.props.userDetails);
  };

  isTotalValid() {
    return yup
      .number()
      .min(1)
      .max(this.getMaxContribution())
      .isValidSync(this.state.total);
  }

  handleConfirmButtonClick = () => {
    this.setState(
      () => ({ formikFormTouched: true }),
      () => {
        if (this.isTotalValid()) {
          this.formikForm.submitForm();
        }
      },
    );
  };

  handleFormSubmit = (values: any) => {
    const { total, timeSpan, paidOutFrequency } = this.state;

    if (values.paymentMethod === 'newCard') {
      this.props.onPaymentRequest({
        formValues: Object.assign(
          { total, timeSpan, paidOutFrequency, payments: this.getPayments() },
          values,
        ),
        typeOfContribution: 'committed',
        userDetails: this.props.userDetails,
      });
    } else {
      let payload: any = {
        amount_in_cents: parseInt(parseFloat(total) * 100),
        candidate_id: this.props.userDetails.id,
        public: values.public === '1',
        stripe_source_id: values.paymentMethod,
        period_in_months: timeSpan,
        frequency_in_months: paidOutFrequency,
      };

      if (this.props.groupId) {
        payload.group_id = this.props.groupId;
      }

      if (this.props.customPaymentEmail && this.props.customPaymentToken) {
        payload.customEmail = this.props.customPaymentEmail;
        payload.customToken = this.props.customPaymentToken;
      }

      this.props.makeCommittedDonation(payload);
    }
  };
}

const mapStateToProps = (state, ownProps) => {
  return {
    ...account.mapStateToProps(state),
    ...accountDetails.mapStateToProps(state),
    ...contributionCommittedDonationCalculate.mapStateToProps(state),
    ...contributionCommittedDonationMake.mapStateToProps(state),
    ...ownProps,
  };
};

const mapDispatchToProps = dispatch => {
  return {
    ...contributionCommittedDonationCalculate.mapDispatchToProps(dispatch),
    ...contributionCommittedDonationMake.mapDispatchToProps(dispatch),
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(withDisplayDimensions(ContributeCommitted));
