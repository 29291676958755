// @flow
import { createAction } from 'redux-actions';
import {
  ACCOUNT_SSA_FORM_DATA_ERROR,
  ACCOUNT_SSA_FORM_DATA_LOADING,
  ACCOUNT_SSA_FORM_DATA_RESET,
  ACCOUNT_SSA_FORM_DATA_SUCCESS,
} from './accountSsaFormData.constants';
import { instance as rorUsersApiProvider } from '../../../services/RoRUsersApiProvider';
import type { PostGetSsaFormDataPayload } from '../../../services/RoRUsersApiProvider';

export const accountSsaFormDataError = createAction(
  ACCOUNT_SSA_FORM_DATA_ERROR,
);

export const accountSsaFormDataLoading = createAction(
  ACCOUNT_SSA_FORM_DATA_LOADING,
);

export const accountSsaFormDataSuccess = createAction(
  ACCOUNT_SSA_FORM_DATA_SUCCESS,
);

export const accountSsaFormDataReset = createAction(
  ACCOUNT_SSA_FORM_DATA_RESET,
);

export const getSsaFormData = (payload: PostGetSsaFormDataPayload) => {
  return async (dispatch: Function) => {
    try {
      dispatch(accountSsaFormDataLoading());
      const response = await rorUsersApiProvider.getSsaFormData(payload);

      dispatch(accountSsaFormDataSuccess(response));
    } catch (error) {
      dispatch(accountSsaFormDataError(error));
    }
  };
};
