export type SsaFormHtmlData = {
  socialSecurityNumber: string,
  legalName: string,
  dob: string,
  address: string,
  location: string,
  phoneNumber: string,
  consent: string,
  companyAddress: string,
  companyAgent: string,
  companyName: string,
  reasons: string,
};

export default (data: SsaFormHtmlData) => `
<!DOCTYPE html>
<html>
  <head>
  <title></title>
  <style>
  @media print {
  .page-break {
    display: block;
    break-before: always;
  }
  #print {
    visibility: hidden;
  }
  @page {
    margin: 0mm;
  }
}

div {
  display: block;
}

.tp-btm-brdr {
  border-top: solid thin;
  border-bottom: solid thin;
}

.btm-brdr {
  border-bottom: solid thin;
}

input {
  border: none;
}

textarea {
  height: 100%;
  width: 100%;
  border: none;
  resize: none;
}

.title {
  font-size: 1.1em;
}

#container {
  top: 0;
  bottom: 0;
  right: 0;
  left: 0;
  margin: 0;
  padding: 0;
  border: 0;
}

.pg-div {
  height: 275mm;
  width: 210mm;
  position: relative;
  margin: auto;
  font-size: 1.05em;
}

.top-block {
  display: flex;
  justify-content: space-between;
}

.top-block p {
  margin-bottom: 0;
}

.tleft {
  flex: 1;
}

.tleft-p {
  text-align: left;
}

.tright {
  flex: 1;
  align-self: flex-end;
}

.tright-p {
  text-align: right;
}

#form-name {
  text-align: center;
}

#name-dob-ssn div {
  display: inline-block;
}

#printed-name {
  width: 45%;
  height: 43px;
}

#date-of-birth {
  padding-left: 5px;
  border-right: solid thin;
  border-left: solid thin;
  width: 20%;
  height: 43px;
}

#ssn {
  width: 30%;
  height: 43px;
}

#release-label {
  padding-top: 2px;
  width: 100%;
}

#release-input-div {
  height: 70px;
}

#reasons p {
  border-top: solid thin;
}

#reasons ul {
  display: flex;
  flex-wrap: wrap;
  width: 80%;
}

#reasons li {
  list-style: none;
  display: inline-block;
  width: calc(80% / 2);
}

#company div {
  display: flex;
}

#company p {
  margin-top: 0;
}

#company input {
  flex-grow: 1;
  width: 80%;
}

#company-name label {
  height: 25px;
  padding-right: 15px;
}

#company-address label {
  padding-right: 4px;
}

#guardian-para {
  border-top: solid thin;
}

#gp1 {
  margin-top: 4px;
}

#gp3 {
  margin-bottom: 2px;
}

#signature-box {
  height: 25px;
  display: flex;
  justify-content: space-between;
}

#date-signed {
  border-left: solid thin;
}

#relationship p {
  margin-bottom: 0;
}

#contact-info p {
  margin-top: 0;
}

.contact {
  height: 25px;
}

.privacy-title {
  margin-top: 0;
  padding-top: 0;
  text-align: center;
}

#tear-off {
  text-align: center;
  border-bottom: 1px dashed #000;
  height: 10px;
  margin-bottom: 10px;
}

#tear-off span {
  background: #fff;
  padding: 0 5px;
}

#print-div {
  text-align: center;
}
  </style>
  </head>
  <body>
    <div id='container'>
      <div id='pg1' class='pg-div'>
        <div class='top-block btm-brdr'>
          <div class='tleft'>
            <p class='tleft-p'>
              Form <b>SSA-89</b> (02-2018)<br>
              Discontinue Previous Editions<br>
              Social Security Administration<br>
            </p>
          </div>

          <div class='tright'>
            <p class='tright-p'>
              Page 1 of 2<br>
              OMB No.0960-0760
            </p>
          </div>
        </div>

        <div id='form-name' class='title'>
          <b>Authorization for the Social Security Administration (SSA)<br>
          To Release Social Security Number (SSN) Verification</b>
        </div>

        <div id='name-dob-ssn' class='tp-btm-brdr'>
          <div id='printed-name'>
            <label for='printed-name'>Printed Name:</label><br>
            <input type='text' name='name' value="${
              data.legalName
            }" class='name-input' size='40'>
          </div>
          <div id='date-of-birth'>
            <label for='dob'>Date of Birth:</label>
            <input type='text' name='dob' value="${
              data.dob
            }" class='dob-input' size='10'>
          </div>
          <div id='ssn'>
            <label for='ssn'>Social Security Number:</label><br>
            <input type='text' name='ssn' value="${
              data.socialSecurityNumber
            }"  class='ssn-input' size='11'>
          </div>
        </div>

        <div id='release-div' class='tp-btm-brdr'>
          <div id='release-label-div' class='btm-brdr'>
            <label  for='release-text'>
              I want this information released because I am conducting the following business transaction:
            </label>
          </div>
          <div id='release-input-div'>
            <textarea name='reason-for-release' id='release-text' maxlength='200'>
             ${data.consent}
            </textarea>
          </div>
        </div>

        <div id='reasons'>
          <p>Reason (s) for using CBSV: (Please select all that apply)</p>
          <ul>
            <li>
              <input type='checkbox' ${
                data.reasons === 'Mortgage Service' ? 'checked' : ''
              } name='mortgage' class='cbleft' id='cb1'>
              <label for='cb1'>Mortgage Service</label>
            </li>
            <li>
              <input type='checkbox' ${
                data.reasons === 'Banking Service' ? 'checked' : ''
              } name='banking' class='cbright' id='cb2'>
              <label for='cb2'>Banking Service</label>
            </li>
            <li>
              <input type='checkbox' ${
                data.reasons === 'Background Check' ? 'checked' : ''
              } name='bg-check' class='cbleft' id='cb3'>
              <label for='cb3'>Background Check</label>
            </li>
            <li>
              <input type='checkbox' ${
                data.reasons === 'License Requirement' ? 'checked' : ''
              } name='license' class='cbright' id='cb4'>
              <label for='cb4'>License Requirement</label>
            </li>
            <li>
              <input type='checkbox' ${
                data.reasons === 'Credit Check' ? 'checked' : ''
              } name='credit' class='cbleft' id='cb5'>
              <label for='cb5'>Credit Check</label>
            </li>
            <li>
              <input type='checkbox' ${
                data.reasons === 'Other' ? 'checked' : ''
              } name='other' class='cbright' id='cb6'>
              <label for='cb6'>Other</label>
            </li>
          </ul>
        </div>

        <div id='company' class='tp-btm-brdr'>
          <p>with the following company ("the Company"):</p>
          <div id='company-name'>
            <label for='company-name'>Company Name:</label>
            <input type='text' value=" ${
              data.companyName
            }" name='company-name' size='20' class='btm-brdr'>
          </div>
          <div id='company-address'>
            <label for='company-name'>Company Address:</label>
            <input type='text' value="${
              data.companyAddress
            }" name='company-address' size='40'>
          </div>
        </div>

        <div id='company-agent'>
          <p>
            ${data.consent}
          </p>
          <div id='agent-label'>
            <label for='agent-info'>
              The name and address of the Company's Agent is:
            </label>
          </div>
          <div id='agent-info-div'>
            <textarea name='agent-info' value="Royal Kastens, 786 Stephanie Circle, Great Falls, VA 22066-2838" id='agent-info' maxlength='200'>
              ${data.companyAgent}
            </textarea>
          </div>
        </div>

        <div id='guardian-para'>
          <p id='gp1'>
            I am the individual to whom the Social Security number was issued or the parent or legal guardian of a
            minor, or the legal guardian of a legally incompetent adult. I declare and affirm under the panlty of
            perjury that the information contained herein is true and correct. I acknowledge that if I make any
            representation that I know is false to obtain information from Social Security record, I could be found
            guilty of a misdemeanor and fined up to $5,000.
          </p>
          <p id='gp2'>
            <b>This consent is valid only for 90 days from the date signed, unless indicated otherwise by the
            individual named above. If you wish to change this timeframe, fill in the following:</b>
          </p>
          <p id='gp3'>
            <b>This consent is valid for ________ days from the date signed. ____________ (Please initial.)</b>
          </p>
        </div>

        <div id='signature-box' class='tp-btm-brdr'>
          <div id='signature'>
            <span>Signature:</span>
          </div>
          <div id='date-signed'>
            <span>Date Signed:</span>
          </div>
          <div></div>
        </div>

        <div id='relationship' class='btm-brdr'>
          <p>
            Relationship (if not the individual to whom the SSN was issued):
          </p>
        </div>

        <div id='contact-info'>
          <p>
            <b>Contact information of individual signing authorization:</b>
          </p>
          <div class='contact address btm-brdr'>
            <label for='contact-address'><b>Address:</b></label>
            <input type='text' name='contact-address' value="${
              data.address
            }" size='40'>
          </div>
          <div class='contact city btm-brdr'>
            <label for='contact-city'><b>City/State/ZIP:</b></label>
            <input type='text' name='contact-city' value="${
              data.location
            }" size='40'>
          </div>
          <div class='contact phone btm-brdr'>
            <label for='contact-phone'><b>Phone Number:</b></label>
            <input type='text' name='contact-phone' value="${
              data.phoneNumber
            }" size='20'>
          </div>
        </div>
      </div>

      <div class='page-break'></div>

      <div id='pg2' class='pg-div'>
        <div class='top-block btm-brdr'>
          <div class='tleft'>
            <p class='tleft-p'>
              Form <b>SSA-89</b> (02-2018)
            </p>
          </div>

          <div class='tright'>
            <p class='tright-p'>
              Page 2 of 2
            </p>
          </div>
        </div>

        <div id='privacy'>
          <p class='privacy-title title'>
            <b>Privacy Act Statement<br>
            Collection and Use of Personal Information</b>
          </p>
          <p>
            Sections 205(a) and 1106 of the Social Security Act, as amended, allow us to collect this information.<br>
            Furnishing us this information is voluntary. However, failing to provide all or part of the information may<br>
            prevent us from releasing information to a designated company or company's agent.
          </p>
          <p>
            We will use the information to verify your name and Social Security number (SSN).  In addition, we may<br>
            share this information in accordance with the Privacy Act and other Federal laws.  For example, where<br>
            authorized, we may use and disclose this information in computer matching programs, in which our records<br>
            are compared with other records to establish or verify a person's eligibility for Federal benefit programs and<br>
            for repayment of incorrect or delinquent debts under these programs.
          </p>
          <p>
            A list of routine uses is available in our Privacy Act System of Records Notice (SORN) 60-0058, entitled<br>
            Master Files of SSN Holders and SSN Applications. Additional information and a full listing of all our SORNs<br>
            are available on our website at <a href='www.socialsecurity.gov/foia/bluebook' target='_blank'>www.socialsecurity.gov/foia/bluebook</a>.
          </p>
          <p>
            <b>Paperwork Reduction Act Statement</b> - This information collection meets the requirements of<br>
            44 U.S.C Â§ 3507, as amended by section 2 of the <u>Paperwork Reduction Act of 1995</u>. You do not need to<br>
            answer these questions unless we display a valid Office of Management and Budget control number. We<br>
            estimate that it will take about 3 minutes to complete the form. You may send comments on our time<br>
            estimate above to: SSA, 6401 Security Blvd., Baltimore, MD 21235-6401. <b>Send to this address only<br>
            comments relating to our time estimate, not the completed form.</b>
          </p>
        </div>

        <div id='tear-off'>
          <span>
            TEAR OFF
          </span>
        </div>

        <div id='notice'>
          <p>
            <b>NOTICE TO NUMBER HOLDER</b>
          </p>
          <p>
            The Company and/or its Agent have entered into an agreement with SSA that, among other things,<br>
            includes restrictions on the further use and disclosure of SSA's verification of your SSN. To view a copy of<br>
            the entire model agreement, visit <a href='http://www.ssa.gov/cbsv/docs/SampleUserAgreement.pdf' target='_blank'>http://www.ssa.gov/cbsv/docs/SampleUserAgreement.pdf</a>.
          </p>
        </div>
        <div id='print-div'>
          <button id='print' onclick='window.print();return false;'>
            Print
          </button>
        </div>
      </div>
    </div>
  </body>
</html>`;
