// @flow
import React from 'react';
import classNames from 'classnames';
import Modal from '../Modal/Modal';
import ContributeOptions from '../ContributeOptions/ContributeOptions';
import type { ContributionTypeType } from '../ContributeOptions/ContributeOptions';

type Props = {
  isOpen: boolean,
  onAfterOpen?: Function,
  onCloseRequest?: Function,
  className?: string,
  onContributionTypeSelected: (
    contributionType: ContributionTypeType,
  ) => void,
  allowPledge?: boolean,
};

class ModalContributeOptions extends React.Component<Props, {}> {
  render() {
    const {
      isOpen,
      onAfterOpen,
      onCloseRequest,
      className,
      allowPledge = true,
      ...rest
    } = this.props;

    return (
      <Modal
        contentLabel="Contribute to candidate"
        isOpen={isOpen}
        onAfterOpen={onAfterOpen}
        onCloseRequest={onCloseRequest}
        className={this.getClassName()}
        noPaddingContent
        size="big"
        {...rest}>
        {isOpen ? (
          <ContributeOptions
            allowPledge={allowPledge}
            onContributionTypeSelected={this.props.onContributionTypeSelected}
          />
        ) : null}
      </Modal>
    );
  }

  getClassName() {
    return classNames({
      'modal-contribute': true,
      [this.props.className || '']: this.props.className,
    });
  }
}

export default ModalContributeOptions;
