// @flow
import makeDefaultServerCallReducer from '../../utilities/makeDefaultServerCallReducer';
import {
  POST_SHARE_SUCCESS,
  POST_SHARE_RESET,
  POST_SHARE_LOADING,
  POST_SHARE_ERROR,
} from './postShare.constants';

const initialState = {
  isLoading: false,
  error: null,
  data: null,
};

export const reducer = makeDefaultServerCallReducer({
  loadingActions: [POST_SHARE_LOADING],
  successActions: [POST_SHARE_SUCCESS],
  errorActions: [POST_SHARE_ERROR],
  resetActions: [POST_SHARE_RESET],
  initialState,
});
