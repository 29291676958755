//@flow
import * as React from 'react';
import * as yup from 'yup';
import { Formik, FormikComputedProps } from 'formik';

import './AddCollaboratorsForm.scss';
import Input from '../Input/Input';
import Button from '../Button/Button';

type FormValues = {
  emails: string,
};

type Props = {
  onSubmit: Function,
  initialFormValues?: FormValues,
  isLoading: boolean,
};

type State = {
  emails: string,
};

class AddCollaboratorsForm extends React.Component<Props, State> {
  constructor(props: Props) {
    super(props);
    this.state = { emails: '' };
  }

  render() {
    return (
      <div className="add-collaborators-form">
        <Formik {...this.getFormikProps()}>
          {props => this.renderInnerForm(props)}
        </Formik>
      </div>
    );
  }

  renderInnerForm(props: FormikComputedProps) {
    const { touched, errors, handleSubmit, values, formikProps } = props;

    return (
      <form
        className="row justify-content-md-center"
        onSubmit={handleSubmit.bind(formikProps)}>
        <div className="col">
          <div className="row px-4">
            <div className="col-lg-12">
              <Input
                name="emails"
                type="text "
                error={touched.emails && errors.emails ? errors.emails : ''}
              />
              <p className="text-center">
                Type email addresses separated by a comma
              </p>
              <div className="px-5 pt-1">
                <Button
                  type="submit"
                  disabled={this.props.isLoading || !values.emails}
                  loading={this.props.isLoading}
                  buttonType="primary"
                  size="small"
                  block={true}>
                  Invite
                </Button>
              </div>
            </div>
          </div>
        </div>
      </form>
    );
  }

  getFormikProps() {
    return {
      initialValues: this.props.initialFormValues || { emails: '' },
      validationSchema: yup.object().shape({
        emails: yup
          .string()
          .required('This field is required')
          .test(
            'contains-emails',
            'This field can only contain emails',
            value => {
              const emails = value.replace(/\s/g, '').split(',');
              const emailRegex = /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
              let valid = true;

              for (let i = 0; i < emails.length; i++) {
                if (emails[i] === '' || !emailRegex.test(emails[i])) {
                  valid = false;
                }
              }

              return valid;
            },
          ),
      }),
      onSubmit: this.onFormSubmit,
      isSubmitting: this.props.isLoading,
    };
  }

  onFormSubmit = (values: FormValues, formikProps: any) => {
    if (this.props.onSubmit) {
      this.props.onSubmit(values);
      formikProps.resetForm();
    }
  };
}

export default AddCollaboratorsForm;
