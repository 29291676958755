// @flow
import makeDefaultServerCallReducer from '../../utilities/makeDefaultServerCallReducer';
import {
  USER_POSTS_ERROR,
  USER_POSTS_LOADING,
  USER_POSTS_SUCCESS,
  USER_POSTS_RESET,
  USER_POSTS_LIKE_POST,
  USER_POSTS_UNLIKE_POST,
  USER_POSTS_SHARE_POST,
} from './userPosts.constants';
import _cloneDeep from 'lodash/cloneDeep';
import _get from 'lodash/get';
import _find from 'lodash/find';

const initialState = {
  isLoading: false,
  error: null,
  data: null,
};

const shareUserPost = (state, action) => {
  if (state.data) {
    const data = _cloneDeep(state.data);

    if (_get(data, 'posts')) {
      let postToUpdate = _find(
        data.posts,
        post => post.id === action.payload.postId,
      );

      if (postToUpdate) {
        postToUpdate.share_count += 1;
      }
    }

    return {
      ...state,
      data,
    };
  }

  return state;
};

const likeUserPost = (state, action) => {
  if (state.data) {
    const data = _cloneDeep(state.data);

    if (_get(data, 'posts')) {
      let postToUpdate = _find(
        data.posts,
        post => post.id === action.payload.postId,
      );

      if (postToUpdate) {
        postToUpdate.like_count += 1;
        postToUpdate.has_liked = true;
      }
    }

    return {
      ...state,
      data,
    };
  }

  return state;
};

const unLikeUserPost = (state, action) => {
  if (state.data) {
    const data = _cloneDeep(state.data);

    if (_get(data, 'posts')) {
      let postToUpdate = _find(
        data.posts,
        post => post.id === action.payload.postId,
      );

      if (postToUpdate) {
        postToUpdate.like_count -= 1;
        postToUpdate.has_liked = false;
      }
    }

    return {
      ...state,
      data,
    };
  }

  return state;
};

export const reducer = makeDefaultServerCallReducer({
  loadingActions: [USER_POSTS_LOADING],
  successActions: [USER_POSTS_SUCCESS],
  errorActions: [USER_POSTS_ERROR],
  resetActions: [USER_POSTS_RESET],
  otherActions: [
    { name: USER_POSTS_LIKE_POST, callback: likeUserPost },
    { name: USER_POSTS_UNLIKE_POST, callback: unLikeUserPost },
    { name: USER_POSTS_SHARE_POST, callback: shareUserPost },
  ],
  initialState,
});
