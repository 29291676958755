// @flow
import makeDefaultServerCallReducer from '../../utilities/makeDefaultServerCallReducer';
import {
  GROUP_DELETE_ERROR,
  GROUP_DELETE_LOADING,
  GROUP_DELETE_SUCCESS,
  GROUP_DELETE_RESET,
} from './groupDelete.constants';

const initialState = {
  isLoading: false,
  error: null,
  data: null,
};

export const reducer = makeDefaultServerCallReducer({
  loadingActions: [GROUP_DELETE_LOADING],
  successActions: [GROUP_DELETE_SUCCESS],
  errorActions: [GROUP_DELETE_ERROR],
  resetActions: [GROUP_DELETE_RESET],
  initialState,
});
