// @flow
import { createAction } from 'redux-actions';
import {
  LIST_STATES_ERROR,
  LIST_STATES_LOADING,
  LIST_STATES_SUCCESS,
  LIST_STATES_RESET,
} from './listStates.constants';
import { instance as rorApiProvider } from '../../../services/RoRApiProvider';

export const listStatesError = createAction(LIST_STATES_ERROR);

export const listStatesLoading = createAction(LIST_STATES_LOADING);

export const listStatesSuccess = createAction(LIST_STATES_SUCCESS);

export const listStatesReset = createAction(LIST_STATES_RESET);

export const listStates = (payload: { interest: string }) => {
  return async (dispatch: Function) => {
    try {
      dispatch(listStatesLoading());

      const response = await rorApiProvider.listBasedOnTypes({
        type: 'states',
        limit: 100,
      });

      dispatch(listStatesSuccess(response));
    } catch (error) {
      dispatch(listStatesError(error));
    }
  };
};
