// @flow
import makeDefaultServerCallReducer from '../../utilities/makeDefaultServerCallReducer';
import {
  LIST_INTERESTS_ERROR,
  LIST_INTERESTS_LOADING,
  LIST_INTERESTS_SUCCESS,
  LIST_INTERESTS_RESET,
  LIST_INTERESTS_NEXT_PAGE,
} from './listInterests.constants';
import _cloneDeep from 'lodash/cloneDeep';

const setNextPageData = (state, action) => {
  if (state.data && state.data.interests) {
    const data = _cloneDeep(state.data);
    const interests = data.interests.concat(action.payload.interests || []);

    return {
      ...state,
      isLoading: false,
      data: { ...action.payload, interests },
    };
  }

  return {
    ...state,
    isLoading: false,
  };
};

const initialState = {
  isLoading: false,
  error: null,
  data: null,
};

export const reducer = makeDefaultServerCallReducer({
  loadingActions: [LIST_INTERESTS_LOADING],
  successActions: [LIST_INTERESTS_SUCCESS],
  errorActions: [LIST_INTERESTS_ERROR],
  resetActions: [LIST_INTERESTS_RESET],
  otherActions: [{ name: LIST_INTERESTS_NEXT_PAGE, callback: setNextPageData }],
  initialState,
});
