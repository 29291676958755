// @flow
import React from 'react';
import moment from 'moment';
import './PostComment.scss';
import Avatar from '../Avatar/Avatar';
import type { PostComment } from '../../services/RoRPostsApiProvider';
import { isCitizen } from '../../utilities/authorization';
import isPrytanyVerified from '../../utilities/isPrytanyVerified';

type Props = {
  comment: PostComment,
};

const PostCommentComponent = ({ comment }: Props) => {
  const date = moment(comment.created_at);
  return (
    <div className="row post-comment">
      <div className="col-12 col-md-1">
        <Avatar
          type="tiny"
          source={comment.owner.profile_image}
          isCandidate={comment.owner.role.code === 'candidate'}
          verifiedLevel={comment.owner.verified_level}
          verifiedDetail={comment.owner.verified_detail}
        />
      </div>
      <div className="col-12 col-md-11">
        <div className="post-comment__content-box">
          <div className="post-comment__header">
            <span className="post-comment__user fs-14 d-inline-block mr-2">
              {comment.owner.first_name} {comment.owner.last_name}
            </span>
            <span className="post-comment__date">
              {date.format('MMM').toUpperCase()} {date.format('Do, HH:mm A')}
            </span>
          </div>
          <p className="post-comment__body">{comment.comment}</p>
          <div className="post-comment__footer">
            <div className="row fix-width">
              <div className="col-8 px-0 d-flex align-items-center">
                {/*<Button buttonType="link" className="px-0"
                        size="small">{!comment.has_liked ? 'Like' : 'Unlike'}</Button>
                <span className="px-3">&middot;</span>
                <Button buttonType="link" className="px-0" size="small">Reply</Button>*/}
              </div>
              <div className="col-4 px-0 d-flex justify-content-end align-items-center">
                {/*<div className="post-comment__like-count">
                  <img src="/images/icon_heart.png" alt=""/>
                  <span>{comment.like_count}</span>
                </div>*/}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default PostCommentComponent;
