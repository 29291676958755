// @flow
import { createAction } from 'redux-actions';
import {
  ACCOUNT_PROFILE_ERROR,
  ACCOUNT_PROFILE_LOADING,
  ACCOUNT_PROFILE_RESET,
  ACCOUNT_PROFILE_SUCCESS,
} from './accountProfile.constants';
import { instance as rorUsersApiProvider } from '../../../services/RoRUsersApiProvider';
import type { GetUserProfilePayload } from '../../../services/RoRUsersApiProvider';

export const accountProfileError = createAction(ACCOUNT_PROFILE_ERROR);

export const accountProfileLoading = createAction(ACCOUNT_PROFILE_LOADING);

export const accountProfileSuccess = createAction(ACCOUNT_PROFILE_SUCCESS);

export const accountProfileReset = createAction(ACCOUNT_PROFILE_RESET);

export const getAccountProfile = (payload?: GetUserProfilePayload) => {
  return async (dispatch: Function) => {
    try {
      dispatch(accountProfileLoading());

      const response = await rorUsersApiProvider.accountProfile(payload);

      dispatch(accountProfileSuccess(response));
    } catch (error) {
      dispatch(accountProfileError(error));
    }
  };
};
