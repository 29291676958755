export const GROUP_MODERATORS_SUCCESS =
  '@@prytany/global/GROUP_MODERATORS_SUCCESS';
export const GROUP_MODERATORS_LOADING =
  '@@prytany/global/GROUP_MODERATORS_LOADING';
export const GROUP_MODERATORS_ERROR = '@@prytany/global/GROUP_MODERATORS_ERROR';
export const GROUP_MODERATORS_RESET = '@@prytany/global/GROUP_MODERATORS_RESET';
export const GROUP_MODERATORS_REMOVE_USER_FROM_LIST =
  '@@prytany/global/GROUP_MODERATORS_REMOVE_USER_FROM_LIST';
export const GROUP_MODERATORS_NEXT_PAGE =
  '@@prytany/global/GROUP_MODERATORS_NEXT_PAGE';
