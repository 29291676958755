// @flow
import React from 'react';
import moment from 'moment';
import { DatePicker } from 'antd';
import withDisplayDimensions from '../../hoc/withDisplayDimensions';
import Button from '../../components/Button/Button';
import Post from '../../components/Post/Post';
import type { PostProps } from '../../components/Post/Post';
import type { WithDisplayDimensionsOutputProps } from '../../hoc/withDisplayDimensions';
import './PostListWithFilters.scss';

type Props = WithDisplayDimensionsOutputProps & {
  loadingPosts?: boolean,
  posts: PostProps[],
  userId?: string,
};

type State = {
  activeDatePickerDates: string[],
  datePickerOpen: boolean,
  displayFilters: boolean,
  datePickerDate: moment.Moment | null,
  calendarDisplayedOnMount: boolean,
};

class PostListWithFilters extends React.Component<Props, State> {
  constructor(props: Props) {
    super(props);

    this.state = {
      activeDatePickerDates: [],
      datePickerOpen: false,
      displayFilters: false,
      datePickerDate: null,
      calendarDisplayedOnMount: false,
    };
  }

  componentDidMount() {
    if (typeof window !== 'undefined') {
      // Gives the browser some extra time to place all elements
      setTimeout(() => {
        this.setState(
          () => ({ calendarDisplayedOnMount: true }),
          () => this.updateActiveDatePickerDates(),
        );
      }, 1500);
    }
  }

  componentWillMount() {
    if (typeof window !== 'undefined') {
      this.setState(() => ({
        displayFilters: !this.props.isMobile,
      }));
    }
  }

  componentDidUpdate(prevProps: Props) {
    const prevPosts = prevProps.posts || [];
    const posts = this.props.posts || [];

    if (
      prevPosts.length !== posts.length &&
      this.state.calendarDisplayedOnMount
    ) {
      this.updateActiveDatePickerDates();
    }

    if (this.props.isMobile !== prevProps.isMobile) {
      this.setState((prevState: State) => {
        const displayFilters = !this.props.isMobile;

        return {
          displayFilters,
          datePickerOpen: displayFilters,
          datePickerDate: displayFilters ? prevState.datePickerDate : null,
        };
      });
    }
  }

  render() {
    return (
      <div className="row fix-width post-list-with-filters">
        <div className="col-md-3 post-list-with-filters__sidebar">
          <div className="row post-list-with-filters__filters-mobile">
            <div className="col-12 text-right">
              <a href="" onClick={this.handleDisplayFiltersToggle}>
                <img src="/images/icon_filter.png" alt="" />
                <span>Filter</span>
              </a>
            </div>
          </div>
          {this.renderFilters()}
        </div>
        <div className="col-md-9 pl-md-5">{this.renderPostList()}</div>
      </div>
    );
  }

  renderFilters() {
    return (
      this.state.displayFilters && (
        <div className="row post-list-with-filters__filters pb-4">
          <div className="col-5 fs-15">
            <strong>Filter</strong>
          </div>
          <div className="col-7 text-right">
            <Button
              buttonType="link"
              className="link-violet fw-700 post-list-with-filters__reset-filters-button"
              onClick={this.handleResetFilters}>
              RESET FILTERS
            </Button>
          </div>
          <div className="col-12 post-list-with-filters__date-picker-wrapper pt-2 pb-4">
            <DatePicker
              className="post-list-with-filters__date-picker"
              dropdownClassName="post-list-with-filters__date-picker-dropdown"
              onChange={this.handleDatePickerChange}
              open={this.state.datePickerOpen}
              showToday={false}
              disabledDate={this.disableDatePickerDate}
              value={this.state.datePickerDate}
            />
          </div>
        </div>
      )
    );
  }

  renderPostList() {
    const allPosts = this.props.posts || [];
    let posts;
    let emptyPostsMessage = null;

    // TODO: filter by type
    if (!this.state.datePickerDate) {
      posts = allPosts;
    } else {
      posts = allPosts.filter(
        (post: PostProps) =>
          moment(post.createdAt).format('YYYY-MM-DD') ===
          moment(this.state.datePickerDate).format('YYYY-MM-DD'),
      );
    }

    if (!this.props.loadingPosts && !posts.length && !allPosts.length) {
      emptyPostsMessage = (
        <span>
          You don't have any posts yet. Click on "New post" button to create
          one.
        </span>
      );
    } else if (!this.props.loadingPosts && !posts.length && allPosts.length) {
      emptyPostsMessage = (
        <span>There are not available posts for the selected date.</span>
      );
    }

    return (
      <React.Fragment>
        {posts.map((post: PostProps) => (
          <Post
            key={post.id}
            {...post}
            userIsOwner={this.props.userId === post.userId}
          />
        ))}
        {emptyPostsMessage}
      </React.Fragment>
    );
  }

  updateActiveDatePickerDates() {
    const posts = this.props.posts || [];

    this.setState((prevState: State) => {
      const datePickerOpen = prevState.datePickerOpen || !this.props.isMobile;

      return {
        activeDatePickerDates: posts.map(post =>
          moment(post.createdAt).format('YYYY-MM-DD'),
        ),
        datePickerDate: datePickerOpen ? prevState.datePickerDate : null,
        datePickerOpen,
      };
    });
  }

  handleResetFilters = () => {
    this.setState(() => ({ datePickerDate: null }));
  };

  handleDisplayFiltersToggle = (e: any) => {
    e.preventDefault();

    this.setState((prevState: State) => ({
      datePickerDate: null,
      displayFilters: !prevState.displayFilters,
      datePickerOpen: !prevState.displayFilters,
    }));
  };

  handleDatePickerChange = (date: moment.Moment) => {
    this.setState((prevState: State) => {
      if (!prevState.datePickerDate) {
        return {
          datePickerDate: date,
        };
      }

      return {
        datePickerDate: date.isSame(prevState.datePickerDate) ? null : date,
      };
    });
  };

  disableDatePickerDate = (date: moment.Moment) => {
    if (date) {
      return (
        this.state.activeDatePickerDates.indexOf(date.format('YYYY-MM-DD')) ===
        -1
      );
    }

    return false;
  };
}

export default withDisplayDimensions(PostListWithFilters);
