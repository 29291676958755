export const ACCOUNT_LOGIN_LOADING = '@@prytany/global/ACCOUNT_LOGIN_LOADING';
export const ACCOUNT_LOGIN_SUCCESS = '@@prytany/global/ACCOUNT_LOGIN_SUCCESS';
export const ACCOUNT_LOGIN_ERROR = '@@prytany/global/ACCOUNT_LOGIN_ERROR';
export const ACCOUNT_LOGOUT_SUCCESS = '@@prytany/global/ACCOUNT_LOGOUT_SUCCESS';
export const ACCOUNT_LOGOUT_LOADING = '@@prytany/global/ACCOUNT_LOGOUT_LOADING';
export const ACCOUNT_LOGOUT_ERROR = '@@prytany/global/ACCOUNT_LOGOUT_ERROR';
export const ACCOUNT_RESET_PASSWORD_SUCCESS =
  '@@prytany/global/ACCOUNT_RESET_PASSWORD_SUCCESS';
export const ACCOUNT_RESET_PASSWORD_LOADING =
  '@@prytany/global/ACCOUNT_RESET_PASSWORD_LOADING';
export const ACCOUNT_RESET_PASSWORD_ERROR =
  '@@prytany/global/ACCOUNT_RESET_PASSWORD_ERROR';
export const ACCOUNT_RESET = '@@prytany/global/ACCOUNT_RESET';
export const ACCOUNT_DEACTIVATE_LOADING =
  '@@prytany/global/ACCOUNT_DEACTIVATE_LOADING';
export const ACCOUNT_DEACTIVATE_SUCCESS =
  '@@prytany/global/ACCOUNT_DEACTIVATE_SUCCESS';
export const ACCOUNT_DEACTIVATE_ERROR =
  '@@prytany/global/ACCOUNT_DEACTIVATE_ERROR';
