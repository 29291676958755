import {
  getRaceDetails,
  raceDetailsReset as reset,
} from './raceDetails.actions';
import type {
  RaceCandidate,
  RaceDetails,
  RaceFeed,
  RaceGroup,
} from '../../../services/RoRRacesApiProvider';

export const mapStateToProps = state => {
  return {
    raceDetailsData: state.raceDetails.data,
    raceDetailsError: state.raceDetails.error,
    raceDetailsIsLoading: state.raceDetails.isLoading,
  };
};

export const mapDispatchToProps = dispatch => {
  return {
    getDetailsForRace(payload) {
      dispatch(getRaceDetails(payload));
    },
    raceDetailsReset() {
      dispatch(reset());
    },
  };
};

export type RaceDetailsMapStateToProps = {
  raceDetailsData: {
    race: RaceDetails & { candidates: RaceCandidate[] },
  } | null,
  raceDetailsError: any,
  raceDetailsIsLoading: boolean,
};

export type RaceDetailsMapDispatchToProps = {
  getDetailsForRace: Function,
  raceDetailsReset: Function,
};

export default {
  mapStateToProps,
  mapDispatchToProps,
};
