// @flow
import React from 'react';
import { connect } from 'react-redux';
import { message } from 'antd';
import listRoles from '../../redux/modules/listRoles/listRoles.containers';
import signUp from '../../redux/modules/signUp/signUp.containers';
import type {
  SignUpMapStateToProps,
  SignUpMapDispatchToProps,
} from '../../redux/modules/signUp/signUp.containers';
import SignUpCampaignManagerForm from '../../components/SignUpCampaignManagerForm/SignUpCampaignManagerForm';
import './CampaignManagerSignUpPage.scss';
import type {
  ListRolesMapDispatchToProps,
  ListRolesMapStateToProps,
} from '../../redux/modules/listRoles/listRoles.containers';
import Loading from '../../components/Loading/Loading';
import Link from '../../components/Link/Link';
import { URL_LOGIN_PAGE } from '../../config/urls';

type Props = SignUpMapStateToProps &
  SignUpMapDispatchToProps &
  ListRolesMapStateToProps &
  ListRolesMapDispatchToProps & {
    accountLogin: Function,
    accountReset: Function,
    accountData: { [key: string]: any } | null,
    accountError: {
      localMessage: string,
    } | null,
    accountIsLoading: boolean,
    location: any,
  };

type State = {
  isSuccessScreen: boolean,
};

class CampaignManagerSignUpPage extends React.Component<Props, State> {
  constructor(props) {
    super(props);

    this.state = {
      isSuccessScreen: false,
    };

    this.props.signUpReset();
    this.props.getListRoles();
  }

  componentDidUpdate(prevProps: Props) {
    if (this.props.signUpError && !prevProps.signUpError) {
      message.error(
        this.props.signUpError.localMessage ||
          'An error has occurred during the sign up.',
      );
      this.props.signUpReset();
    }

    if (this.props.signUpData && !this.state.isSuccessScreen) {
      this.setState(() => ({ isSuccessScreen: true }));
    }
  }

  render() {
    return (
      <Loading
        loading={this.props.listRolesIsLoading || !this.props.listRolesData}>
        <section className="campaign-manager-signup content">
          <div className="container-fluid fix-width">
            <div className="row justify-content-md-center">
              {this.state.isSuccessScreen
                ? this.renderSuccessScreen()
                : this.renderForm()}
            </div>
          </div>
        </section>
      </Loading>
    );
  }

  renderForm() {
    return (
      <div className="col-lg-12 col-md-6 pr-5-md">
        <h1 className="mb-3">Welcome to Prytany</h1>
        <SignUpCampaignManagerForm {...this.getFormProps()} />
      </div>
    );
  }

  renderSuccessScreen() {
    return (
      <div className="col">
        <h1>Thank you for registering with Prytany!</h1>
        <p className="pt-4">
          Your account is currently being reviewed and Prytany may contact you
          if further verification is needed and&nbsp; you will be notified when
          your account has been approved. If you have any questions,&nbsp;
          please email us at{' '}
          <Link href="mailto:support@prytany.com" isExternal={true}>
            support@prytany.com
          </Link>
        </p>
        <div className="row no-gutters justify-content-center py-5">
          <div className="col-12 col-sm-6 col-md-3 col-lg-2">
            <Link href={URL_LOGIN_PAGE} type="button" className="btn-block">
              GO TO LOGIN
            </Link>
          </div>
        </div>
      </div>
    );
  }

  getFormProps() {
    const campaignManagerRole = this.props.listRolesData.roles.find(
      (role: any) => role.code === 'campaign_manager',
    );

    return {
      isLoading: this.props.signUpIsLoading,
      initialFormValues: {
        email: '',
        name: '',
        lastName: '',
        password: '',
        passwordConfirm: '',
      },
      onFormSubmit: values =>
        this.props.signUp({
          email: values.email,
          first_name: values.name,
          last_name: values.lastName,
          role_id: campaignManagerRole.id,
          password: values.password,
          verification_token: this.props.match.params.tokenId,
        }),
    };
  }
}

const mapStateToProps = (state, ownProps) => {
  return {
    ...signUp.mapStateToProps(state),
    ...listRoles.mapStateToProps(state),
    ...ownProps,
  };
};

const mapDispatchToProps = dispatch => {
  return {
    ...signUp.mapDispatchToProps(dispatch),
    ...listRoles.mapDispatchToProps(dispatch),
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(CampaignManagerSignUpPage);
