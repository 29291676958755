import { leaveGroup, groupLeaveReset as reset } from './groupLeave.actions';
import type { DeleteGroupLeavePayload } from '../../../services/RoRGroupsApiProvider';

export const mapStateToProps = state => {
  return {
    groupLeaveData: state.groupLeave.data,
    groupLeaveIsLoading: state.groupLeave.isLoading,
    groupLeaveError: state.groupLeave.error,
  };
};

export const mapDispatchToProps = dispatch => {
  return {
    leaveGroup(payload: DeleteGroupLeavePayload) {
      dispatch(leaveGroup(payload));
    },
    groupLeaveReset() {
      dispatch(reset());
    },
  };
};

export type GroupLeaveMapStateToProps = {
  groupLeaveError: any,
  groupLeaveIsLoading: boolean,
  groupLeaveData: any,
};

export type GroupLeaveMapDispatchToProps = {
  leaveGroup: (payload: DeleteGroupLeavePayload) => void,
  groupLeaveReset: Function,
};

export default {
  mapStateToProps,
  mapDispatchToProps,
};
