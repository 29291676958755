// @flow
import * as React from 'react';
import { Field } from 'formik';
import classNames from 'classnames';
import Button from '../Button/Button';
import './TextArea.scss';

type Props = {
  className?: string,
  inputClassName?: string,
  error?: string,
  label?: string,
  name: string,
  placeholder?: string,
  children?: any,
  useFormik?: boolean,
  addonButtonProps?: any,
  addonButtonContent?: any,
  addonButtonResetInputOnClick?: boolean,
};

const TextArea = (props: Props) => {
  const { useFormik, ...rest } = props;
  return useFormik ? (
    <TextAreaFormik {...rest} />
  ) : (
    <TextAreaIsolated {...rest} />
  );
};

TextArea.defaultProps = {
  useFormik: true,
  addonButtonProps: {},
  addonButtonResetInputOnClick: false,
};

const TextAreaFormik = (props: Props) => {
  function handleAddonButtonClick(value: string, name: string) {
    if (props.addonButtonProps && props.addonButtonProps.onClick) {
      props.addonButtonProps.onClick({ value, name });
    }
  }

  return (
    <div className={getClassName(props)}>
      {props.label ? <label htmlFor={props.name}>{props.label}</label> : null}
      <Field
        className={getInputClassName(props)}
        component="textarea"
        id={props.name}
        name={props.name}
        placeholder={props.placeholder}
      />
      <TextAreaAddon
        inputValue="read formik field value"
        onClick={(value, name) => handleAddonButtonClick(value, name)}
        {...props}
      />
      {props.children || null}
      {props.error ? (
        <span className="text-area__error-message">{props.error}</span>
      ) : null}
    </div>
  );
};

type TextAreaIsolatedProps = Props & { onChange?: Function, value?: string };
type TextAreaIsolatedState = { value: string };

class TextAreaIsolated extends React.Component<
  TextAreaIsolatedProps,
  TextAreaIsolatedState,
> {
  inputId: string;

  constructor(props) {
    super(props);

    this.inputId = `text-area-${props.name}`;
    this.state = {
      value: this.props.value || '',
    };
  }

  componentDidUpdate(prevProps) {
    if (
      prevProps.value !== this.props.value &&
      this.state.value !== this.props.value
    ) {
      this.setState(() => ({ value: this.props.value }));
    }
  }

  render() {
    const {
      addonButtonContent,
      addonButtonResetInputOnClick,
      addonButtonProps,
      className,
      onChange,
      label,
      name,
      error,
      children,
      inputClassName,
      ...rest
    } = this.props;

    return (
      <div className={getClassName(this.props)}>
        {label ? <label htmlFor={name}>{label}</label> : null}
        <textarea
          className={getInputClassName(this.props)}
          id={this.inputId}
          onChange={this.handleChange}
          value={this.state.value}
          {...rest}
        />
        <TextAreaAddon
          {...this.props}
          inputValue={this.state.value}
          onClick={this.handleAddonButtonClick}
        />
        {children || null}
        {error ? (
          <span className="text-area__error-message">{error}</span>
        ) : null}
      </div>
    );
  }

  handleChange = (e: any) => {
    const value = e.target.value;

    this.setState(
      () => ({ value }),
      () => {
        if (this.props.onChange) {
          this.props.onChange({
            name: this.props.name,
            value: this.state.value,
          });
        }
      },
    );
  };

  handleAddonButtonClick = (inputValue: string, name: string) => {
    if (this.props.addonButtonProps && this.props.addonButtonProps.onClick) {
      this.props.addonButtonProps.onClick({ value: inputValue, name });
    }

    if (this.props.addonButtonResetInputOnClick) {
      this.setState({ value: '' });
    }
  };
}

const TextAreaAddon = (
  props: Props & { inputValue: string, onClick?: Function },
) => {
  const { addonButtonProps, addonButtonContent, inputValue, name } = props;
  //$FlowFixMe
  const { onClick: parentClickHandler, ...rest } = addonButtonProps;

  return addonButtonContent ? (
    <Button
      block
      buttonType="primary"
      onClick={() => {
        if (props.onClick) props.onClick(inputValue, name);
      }}
      className="text-area__addon-button"
      {...rest}>
      {addonButtonContent}
    </Button>
  ) : null;
};

function getClassName(props) {
  return classNames({
    'form-group': true,
    'text-area': true,
    [props.className || '']: props.className,
  });
}

function getInputClassName(props) {
  return classNames({
    'form-control': true,
    'form-control--error': props.error,
    [props.inputClassName || '']: props.inputClassName,
  });
}

export default TextArea;
