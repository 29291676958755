// @flow
import makeDefaultServerCallReducer from '../../utilities/makeDefaultServerCallReducer';
import {
  RACE_GROUPS_ERROR,
  RACE_GROUPS_LOADING,
  RACE_GROUPS_SUCCESS,
  RACE_GROUPS_RESET,
} from './raceGroups.constants';

const initialState = {
  isLoading: false,
  error: null,
  data: null,
};

export const reducer = makeDefaultServerCallReducer({
  loadingActions: [RACE_GROUPS_LOADING],
  successActions: [RACE_GROUPS_SUCCESS],
  errorActions: [RACE_GROUPS_ERROR],
  resetActions: [RACE_GROUPS_RESET],
  initialState,
});
