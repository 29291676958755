// @flow
import Axios, { AxiosResponse, AxiosError } from 'axios';
import RoRApiResponseTransformer from '../utilities/RoRApiResponseTransformer';
import RoRApiProvider from './RoRApiProvider';
import type { DefaultResponse } from './RoRApiProvider';

class RoRContributionsApiProvider extends RoRApiProvider {
  constructor() {
    super();
  }

  getDirectDonationDetails(
    payload: GetDirectDonationDetailsPayload,
  ): Promise<GetDirectDonationDetailsResponse> {
    const requestConfig = {
      headers: {
        'Content-Type': 'application/json',
        ...this.getAuthenticationHeaders(),
      },
    };

    return this.axios
      .get(`/apis/donations/${payload.donationId}`, requestConfig)
      .then((response: AxiosResponse) =>
        RoRApiResponseTransformer.parseResponse(response),
      )
      .catch((error: AxiosError) =>
        Promise.reject(RoRApiResponseTransformer.parseError(error)),
      );
  }

  getStripeSources(
    payload: GetStripeSourcesPayload = {},
  ): Promise<GetStripeSourcesResponse> {
    const requestConfig = {
      headers: {
        'Content-Type': 'application/json',
        ...this.getAuthenticationHeaders({
          customToken: payload.customToken,
          customEmail: payload.customEmail,
        }),
      },
    };

    return this.axios
      .get('/apis/users/stripe_sources', requestConfig)
      .then((response: AxiosResponse) =>
        RoRApiResponseTransformer.parseResponse(response),
      )
      .catch((error: AxiosError) =>
        Promise.reject(RoRApiResponseTransformer.parseError(error)),
      );
  }

  addNewAch(payload: PostAddNewAchPayload): Promise<DefaultResponse> {
    const requestConfig = {
      headers: {
        'Content-Type': 'multipart/form-data',
        ...this.getAuthenticationHeaders({
          customToken: payload.customToken,
          customEmail: payload.customEmail,
        }),
      },
    };

    const formData = new FormData();
    formData.append(
      `donation[bank_account_source]`,
      payload.bank_account_source,
    );

    return this.axios
      .post(`/apis/donations/add_bank_account`, formData, requestConfig)
      .then((response: AxiosResponse) =>
        RoRApiResponseTransformer.parseResponse(response, true),
      )
      .catch((error: AxiosError) =>
        Promise.reject(RoRApiResponseTransformer.parseError(error)),
      );
  }

  verifyAch(payload: PostVerifyAchPayload): Promise<DefaultResponse> {
    const requestConfig = {
      headers: {
        'Content-Type': 'multipart/form-data',
        ...this.getAuthenticationHeaders({
          customToken: payload.customToken,
          customEmail: payload.customEmail,
        }),
      },
    };

    const formData = new FormData();
    formData.append(`donation[stripe_source_id]`, payload.stripe_source_id);
    formData.append(
      `donation[amounts_to_verify][]`,
      payload.amounts_to_verify[0].toString(),
    );
    formData.append(
      `donation[amounts_to_verify][]`,
      payload.amounts_to_verify[1].toString(),
    );

    return this.axios
      .post(`/apis/donations/verify_amounts`, formData, requestConfig)
      .then((response: AxiosResponse) =>
        RoRApiResponseTransformer.parseResponse(response, true),
      )
      .catch((error: AxiosError) =>
        Promise.reject(RoRApiResponseTransformer.parseError(error)),
      );
  }

  makeDirectDonation(
    payload: PostMakeDirectDonationPayload,
  ): Promise<DefaultResponse> {
    const requestConfig = {
      headers: {
        'Content-Type': 'multipart/form-data',
        ...this.getAuthenticationHeaders({
          customToken: payload.customToken,
          customEmail: payload.customEmail,
        }),
      },
    };

    const formData = new FormData();

    Object.keys(payload).forEach((key: string) => {
      const value = payload[key];

      if (value !== null) {
        formData.append(`donation[${key}]`, value);
      }
    });

    return this.axios
      .post(`/apis/donations`, formData, requestConfig)
      .then((response: AxiosResponse) =>
        RoRApiResponseTransformer.parseResponse(response, true),
      )
      .catch((error: AxiosError) =>
        Promise.reject(RoRApiResponseTransformer.parseError(error)),
      );
  }

  getCommittedDonationDetails(
    payload: GetCommittedDonationDetailsPayload,
  ): Promise<GetCommittedDonationDetailsResponse> {
    const requestConfig = {
      headers: {
        'Content-Type': 'application/json',
        ...this.getAuthenticationHeaders(),
      },
    };

    return this.axios
      .get(`/apis/committed_donations/${payload.donationId}`, requestConfig)
      .then((response: AxiosResponse) =>
        RoRApiResponseTransformer.parseResponse(response),
      )
      .catch((error: AxiosError) =>
        Promise.reject(RoRApiResponseTransformer.parseError(error)),
      );
  }

  makeCommittedDonation(
    payload: PostMakeCommittedDonationPayload,
  ): Promise<DefaultResponse> {
    const requestConfig = {
      headers: {
        'Content-Type': 'multipart/form-data',
        ...this.getAuthenticationHeaders({
          customToken: payload.customToken,
          customEmail: payload.customEmail,
        }),
      },
    };

    const formData = new FormData();

    Object.keys(payload).forEach((key: string) => {
      const value = payload[key];

      if (value !== null) {
        formData.append(`committed_donation[${key}]`, value);
      }
    });

    return this.axios
      .post(`/apis/committed_donations`, formData, requestConfig)
      .then((response: AxiosResponse) =>
        RoRApiResponseTransformer.parseResponse(response, true),
      )
      .catch((error: AxiosError) =>
        Promise.reject(RoRApiResponseTransformer.parseError(error)),
      );
  }

  calculateCommittedDonation(
    payload: PostCalculateCommittedDonationPayload,
  ): Promise<PostCalculateCommittedDonationResponse> {
    const requestConfig = {
      headers: {
        'Content-Type': 'multipart/form-data',
        ...this.getAuthenticationHeaders({
          customToken: payload.customToken,
          customEmail: payload.customEmail,
        }),
      },
    };

    const formData = new FormData();

    Object.keys(payload).forEach((key: string) => {
      const value = payload[key];

      if (value !== null) {
        formData.append(`committed_donation[${key}]`, value);
      }
    });

    return this.axios
      .post(`/apis/committed_donations/calculate`, formData, requestConfig)
      .then((response: AxiosResponse) =>
        RoRApiResponseTransformer.parseResponse(response),
      )
      .catch((error: AxiosError) =>
        Promise.reject(RoRApiResponseTransformer.parseError(error)),
      );
  }

  cancelCommittedDonationDetails(
    payload: DeleteCommittedDonationDetailsPayload,
  ): Promise<DefaultResponse> {
    const requestConfig = {
      headers: {
        'Content-Type': 'application/json',
        ...this.getAuthenticationHeaders(),
      },
    };

    return this.axios
      .delete(`/apis/committed_donations/${payload.donationId}`, requestConfig)
      .then((response: AxiosResponse) =>
        RoRApiResponseTransformer.parseResponse(response),
      )
      .catch((error: AxiosError) =>
        Promise.reject(RoRApiResponseTransformer.parseError(error)),
      );
  }

  getPledgeDonationDetails(
    payload: GetPledgeDonationDetailsPayload,
  ): Promise<GetPledgeDonationDetailsResponse> {
    const requestConfig = {
      headers: {
        'Content-Type': 'application/json',
        ...this.getAuthenticationHeaders(),
      },
    };

    return this.axios
      .get(`/apis/pledges/${payload.donationId}`, requestConfig)
      .then((response: AxiosResponse) =>
        RoRApiResponseTransformer.parseResponse(response),
      )
      .catch((error: AxiosError) =>
        Promise.reject(RoRApiResponseTransformer.parseError(error)),
      );
  }

  makePledgeDonation(
    payload: PostMakePledgeDonationPayload,
  ): Promise<DefaultResponse> {
    const requestConfig = {
      headers: {
        'Content-Type': 'multipart/form-data',
        ...this.getAuthenticationHeaders({
          customToken: payload.customToken,
          customEmail: payload.customEmail,
        }),
      },
    };

    const formData = new FormData();

    Object.keys(payload).forEach((key: string) => {
      const value = payload[key];

      if (value !== null) {
        formData.append(`pledge[${key}]`, value);
      }
    });

    return this.axios
      .post(`/apis/pledges`, formData, requestConfig)
      .then((response: AxiosResponse) =>
        RoRApiResponseTransformer.parseResponse(response, true),
      )
      .catch((error: AxiosError) =>
        Promise.reject(RoRApiResponseTransformer.parseError(error)),
      );
  }

  claimPledge(payload: PostClaimPledgePayload): Promise<DefaultResponse> {
    const requestConfig = {
      headers: {
        'Content-Type': 'multipart/form-data',
        ...this.getAuthenticationHeaders({
          customToken: payload.customToken,
          customEmail: payload.customEmail,
        }),
      },
    };

    const formData = new FormData();

    return this.axios
      .post(`/apis/pledges/${payload.pledgeId}/claim`, formData, requestConfig)
      .then((response: AxiosResponse) =>
        RoRApiResponseTransformer.parseResponse(response, true),
      )
      .catch((error: AxiosError) =>
        Promise.reject(RoRApiResponseTransformer.parseError(error)),
      );
  }

  cancelPledgeDonationDetails(
    payload: DeletePledgeDonationDetailsPayload,
  ): Promise<DefaultResponse> {
    const requestConfig = {
      headers: {
        'Content-Type': 'application/json',
        ...this.getAuthenticationHeaders(),
      },
    };

    return this.axios
      .delete(`/apis/pledges/${payload.donationId}`, requestConfig)
      .then((response: AxiosResponse) =>
        RoRApiResponseTransformer.parseResponse(response),
      )
      .catch((error: AxiosError) =>
        Promise.reject(RoRApiResponseTransformer.parseError(error)),
      );
  }
}

export type GetStripeSourcesPayload = {
  customToken?: string,
  customEmail?: string,
};

export type GetStripeSourcesResponse = {
  stripe_sources: {
    stripe_source_id: string,
    source_type: string,
    status: string,
    account_number?: string,
    bank_name?: string,
    credit_card_number?: string,
    credit_card_type?: string,
    exp_month?: number,
    exp_year?: number,
  }[],
};

export type PostAddNewAchPayload = {
  bank_account_source: string,
  customToken?: string,
  customEmail?: string,
};

export type PostVerifyAchPayload = {
  stripe_source_id: string,
  amounts_to_verify: number[],
  customToken?: string,
  customEmail?: string,
};

export type GetDirectDonationDetailsPayload = {
  donationId: number,
};

export type GetDirectDonationDetailsResponse = {
  id: number,
  candidate_full_name: string,
  profile_image: string,
  total_amount_in_cents: number,
  created_at: string,
};

export type PostMakeDirectDonationPayload = {
  amount_in_cents: number,
  candidate_id: number,
  public: boolean,
  stripe_source_id: string,
  customToken?: string,
  customEmail?: string,
  group_id?: number,
};

export type GetCommittedDonationDetailsPayload = {
  donationId: number,
};

export type GetCommittedDonationDetailsResponse = {
  committed_donation: {
    id: number,
    candidate_full_name: string,
    profile_image: string,
    total_amount_in_cents: number,
    created_at: string,
    payments: {
      amount_in_cents: number,
      due_date: string,
      status: string,
    }[],
  },
};

export type PostMakeCommittedDonationPayload = {
  amount_in_cents: number,
  candidate_id: number,
  period_in_months: number,
  public: boolean,
  stripe_source_id: string,
  frequency_in_months: number,
  customToken?: string,
  customEmail?: string,
  group_id?: number,
};

export type PostCalculateCommittedDonationPayload = {
  amount_in_cents: number,
  period_in_months: number,
  frequency_in_months: number,
  customToken?: string,
  customEmail?: string,
};

export type PostCalculateCommittedDonationResponse = {
  schedule: { amount_in_cents: number, due_date: string }[],
};

export type DeleteCommittedDonationDetailsPayload = {
  donationId: number,
};

export type GetPledgeDonationDetailsPayload = {
  donationId: number,
};

export type GetPledgeDonationDetailsResponse = {
  pledge: {
    id: number,
    candidate_full_name: string,
    profile_image: string,
    total_amount_in_cents: number,
    created_at: string,
    condition: string,
    interest_name: string,
    due_date: string,
    status: string,
    claim_status: 'pending' | 'requested' | 'completed' | 'failed',
  },
};

export type PostMakePledgeDonationPayload = {
  amount_in_cents: number,
  candidate_id: number,
  period_in_months: number,
  public: boolean,
  stripe_source_id: string,
  condition: 'increase' | 'maintain' | 'decrease',
  sub_interest_id: number,
  customToken?: string,
  customEmail?: string,
  group_id?: number,
};

export type PostClaimPledgePayload = {
  pledgeId: number,
  customToken?: string,
  customEmail?: string,
};

export type DeletePledgeDonationDetailsPayload = {
  donationId: number,
};

export default RoRContributionsApiProvider;
export const instance = new RoRContributionsApiProvider();
