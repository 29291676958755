import React from 'react';
import classNames from 'classnames';
import Link from '../Link/Link';
import './BreadCrumb.scss';

type BreadCrumbLink = {
  href: string,
  content: any,
  title: string,
  active: boolean,
};
type Props = {
  links: BreadCrumbLink[],
};

const BreadCrumb = (props: Props) => {
  const prevIndex = props.links.length - 2;
  let prevLink = null;

  if (prevIndex >= 0) {
    prevLink = props.links[prevIndex];
  }

  return (
    <div className="breadcrumb breadcrumb-component">
      {prevLink ? (
        <Link
          href={prevLink.href}
          title={prevLink.title || ''}
          className="breadcrumb__back-arrow">
          <img src="/images/back-violet.png" alt="" />
        </Link>
      ) : null}
      {props.links.map((link, index) => (
        <div key={index} className={getWrapperClassName(link)}>
          {index !== 0 ? <span>&gt;</span> : null}
          <Link href={link.href} title={link.title || ''}>
            {link.content}
          </Link>
        </div>
      ))}
    </div>
  );
};

function getWrapperClassName(link: BreadCrumbLink) {
  return classNames({
    'breadcrumb__link-wrapper': true,
    'breadcrumb__link-wrapper--active': link.active,
  });
}

BreadCrumb.defaultProps = {
  links: [],
};

export default BreadCrumb;
