// @flow
import React from 'react';
import { connect } from 'react-redux';
import { Formik, FormikComputedProps } from 'formik';
import { message } from 'antd';
import Button from '../../components/Button/Button';
import Link from '../../components/Link/Link';
import LoadingSpinner from '../../components/LoadingSpinner/LoadingSpinner';
import account from '../../redux/modules/account/account.containers';
import {
  URL_SIGN_UP_PAGE,
  URL_LOGIN_PAGE,
  URL_FORGOT_PASSWORD_PAGE,
  URL_CONTACT_US_PAGE,
} from '../../config/urls';
import type {
  AccountMapStateToProps,
  AccountMapDispatchToProps,
} from '../../redux/modules/account/account.containers';
import './ForgotPasswordPage.scss';

import Input from '../../components/Input/Input';
import * as yup from 'yup';

type FormValues = {
  email: string,
};

type Props = AccountMapStateToProps &
  AccountMapDispatchToProps & {
    onSubmit: Function,
  };

type State = {
  submitted: boolean,
};

class ForgotPasswordPage extends React.Component<Props, State> {
  constructor(props: Props) {
    super(props);
    (this: any).handleRetryButtonClick = this.handleRetryButtonClick.bind(this);
    this.state = { submitted: false };
  }

  componentDidUpdate(prevProps: Props, prevState: State) {
    if (!prevProps.accountError && this.props.accountError) {
      message.error(
        this.props.accountError.localMessage ||
          'An error has occurred during the process.',
      );
    }
  }

  render() {
    return (
      <div className="forgot-password">
        <section className="content">
          <div className="container-fluid fix-width">
            <div className="row justify-content-md-center">
              <div className="col-lg-5 col-md-6 pr-5-md forgot-password__form-container">
                <h1>Reset your password</h1>
                <div className="forgot-password__message">
                  {this.state.submitted
                    ? this.renderMessage()
                    : this.renderForm()}
                </div>
              </div>
              <div className="col-lg-7 col-md-6 text-right d-none d-md-block img-white">
                <img src="/images/white_h.jpg" className="img-fluid" alt="" />
              </div>
            </div>
          </div>
        </section>
      </div>
    );
  }

  renderMessage() {
    let content = null;

    if (this.props.accountIsLoading) {
      content = <LoadingSpinner type={'dark'} visible={true} />;
    } else if (this.props.accountError) {
      content = (
        <p className="forgot-password__success-message">
          {this.props.accountError.localMessage}, please{' '}
          <Button
            onClick={this.handleRetryButtonClick}
            buttonType="link"
            size="small"
            className="forgot-password__retry-button">
            try again
          </Button>{' '}
          or{' '}
          <Link href={URL_CONTACT_US_PAGE} className="link-violet">
            contact us
          </Link>
          .
        </p>
      );
    } else {
      content = (
        <p className="forgot-password__success-message">
          You will receive an email with new password if the email id provided
          is a valid one, please check your email and{' '}
          <Link href={URL_LOGIN_PAGE} className="link-violet">
            login
          </Link>
          .
        </p>
      );
    }
    return content;
  }

  renderForm() {
    return (
      <div>
        <Formik {...this.getFormikProps()}>
          {props => this.renderInnerForm(props)}
        </Formik>
        <p>
          Enter your email and we'll send you a link to reset your password. If
          you are not yet registered, click on{' '}
          <Link href={URL_SIGN_UP_PAGE} className="link-violet">
            Sign up
          </Link>
          .
        </p>
      </div>
    );
  }

  renderInnerForm(props: FormikComputedProps) {
    const { touched, errors, handleSubmit, values } = props;

    return (
      <form className="row justify-content-md-center" onSubmit={handleSubmit}>
        <div className="col">
          <div className="row">
            <div className="col-lg-12">
              <Input
                name="email"
                type="email"
                error={touched.email && errors.email ? errors.email : ''}
                addonButtonContent="Send"
                addonButtonProps={{
                  disabled: !values.email,
                  loading: this.props.accountIsLoading,
                  type: 'submit',
                }}
                addonButtonResetInputOnClick={true}
                disabled={this.props.accountIsLoading}
              />
            </div>
          </div>
        </div>
      </form>
    );
  }

  handleRetryButtonClick() {
    this.setState(() => ({ submitted: false }));
  }

  getFormikProps() {
    return {
      initialValues: this.props.initialFormValues || { email: '' },
      validationSchema: yup.object().shape({
        email: yup
          .string()
          .required('This field is required')
          .email('This field must be an email address'),
      }),
      onSubmit: this.onFormSubmit,
      isSubmitting: this.props.isLoading,
    };
  }

  onFormSubmit = (values: FormValues) => {
    this.props.accountResetPassword({ user: { email: values.email } });
    this.setState(() => ({ submitted: true }));
  };
}

const mapStateToProps = (state, ownProps) => {
  return {
    ...account.mapStateToProps(state),
    ...ownProps,
  };
};

const mapDispatchToProps = dispatch => {
  return {
    ...account.mapDispatchToProps(dispatch),
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(ForgotPasswordPage);
