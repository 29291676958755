// @flow
import makeDefaultServerCallReducer from '../../utilities/makeDefaultServerCallReducer';
import {
  GROUP_MODERATORS_ERROR,
  GROUP_MODERATORS_LOADING,
  GROUP_MODERATORS_SUCCESS,
  GROUP_MODERATORS_RESET,
  GROUP_MODERATORS_REMOVE_USER_FROM_LIST,
  GROUP_MODERATORS_NEXT_PAGE,
} from './groupModerators.constants';
import _cloneDeep from 'lodash/cloneDeep';

const initialState = {
  isLoading: false,
  error: null,
  data: null,
};

const removeUserFromModeratorsList = (state, action) => {
  const { payload } = action;

  if (state.data && state.data.data) {
    const data = _cloneDeep(state.data);

    if (data.data.moderators) {
      let moderators = [];

      data.data.moderators.forEach(user => {
        if (user.id !== payload.userId) {
          moderators.push(user);
        }
      });

      data.data.moderators = moderators;

      return {
        ...state,
        data,
      };
    }
  }

  return state;
};

const setNextPageData = (state, action) => {
  const { payload } = action;

  if (state.data && state.data.data) {
    const data = _cloneDeep(state.data);

    if (data.data.moderators) {
      const moderators = (data.data.moderators || []).concat(
        payload.data.moderators || [],
      );

      return {
        ...state,
        data: {
          ...payload,
          data: {
            moderators,
          },
        },
        isLoading: false,
      };
    }
  }

  return state;
};

export const reducer = makeDefaultServerCallReducer({
  loadingActions: [GROUP_MODERATORS_LOADING],
  successActions: [GROUP_MODERATORS_SUCCESS],
  errorActions: [GROUP_MODERATORS_ERROR],
  resetActions: [GROUP_MODERATORS_RESET],
  otherActions: [
    {
      name: GROUP_MODERATORS_REMOVE_USER_FROM_LIST,
      callback: removeUserFromModeratorsList,
    },
    { name: GROUP_MODERATORS_NEXT_PAGE, callback: setNextPageData },
  ],
  initialState,
});
