// @flow
import { createAction } from 'redux-actions';
import {
  POST_ADD_ERROR,
  POST_ADD_LOADING,
  POST_ADD_SUCCESS,
  POST_ADD_RESET,
} from './postAdd.constants';
import { accountPostsSetAsFirst } from '../accountPosts/accountPosts.actions';
import { instance as rorPostsApiProvider } from '../../../services/RoRPostsApiProvider';
import type { PostNewPostPayload } from '../../../services/RoRPostsApiProvider';

export const postAddError = createAction(POST_ADD_ERROR);

export const postAddLoading = createAction(POST_ADD_LOADING);

export const postAddSuccess = createAction(POST_ADD_SUCCESS);

export const postAddReset = createAction(POST_ADD_RESET);

export const postAdd = (payload: NewPostPayload) => {
  return async (dispatch: Function) => {
    try {
      const apiPayload = parseSignUpPayload(payload);

      dispatch(postAddLoading());

      const response = await rorPostsApiProvider.newPost(apiPayload);

      dispatch(postAddSuccess(response));
      dispatch(accountPostsSetAsFirst(response.post));
    } catch (error) {
      dispatch(postAddError(error));
    }
  };
};

function parseSignUpPayload(payload: NewPostPayload) {
  const parsedPayload: PostNewPostPayload = {
    title: payload.title,
    description: payload.content,
    commentable: payload.allowComments === '1',
  };

  if (payload.postImage) {
    parsedPayload.post_image = payload.postImage;
  }

  if (payload.postTo && payload.postTo !== 'all') {
    parsedPayload.group_id = parseInt(payload.postTo);
  }

  return parsedPayload;
}

type NewPostPayload = {
  title: string,
  content: string,
  postTo: string,
  allowComments: '' | '1',
  postImage: File | null,
};
