import {
  getGroupMembers,
  groupMembersReset as reset,
} from './groupMembers.actions';
import type {
  GetGroupMembersPayload,
  GetGroupMembersResponse,
} from '../../../services/RoRGroupsApiProvider';

export const mapStateToProps = state => {
  return {
    groupMembersData: state.groupMembers.data,
    groupMembersError: state.groupMembers.error,
    groupMembersIsLoading: state.groupMembers.isLoading,
  };
};

export const mapDispatchToProps = dispatch => {
  return {
    getMembersForGroup(payload: GetGroupMembersPayload) {
      dispatch(getGroupMembers(payload));
    },
    groupMembersReset() {
      dispatch(reset());
    },
  };
};

export type GroupMembersMapStateToProps = {
  groupMembersData: GetGroupMembersResponse,
  groupMembersError: any,
  groupMembersIsLoading: boolean,
};

export type GroupMembersMapDispatchToProps = {
  getMembersForGroup: (payload: GetGroupMembersPayload) => void,
  groupMembersReset: () => void,
};

export default {
  mapStateToProps,
  mapDispatchToProps,
};
