// @flow
import React from 'react';
import _get from 'lodash/get';
import { connect } from 'react-redux';
import ListWithAvatars from '../../components/ListWithAvatars/ListWithAvatars';
import account from '../../redux/modules/account/account.containers';
import accountDetails from '../../redux/modules/accountDetails/accountDetails.containers';
import groupDetails from '../../redux/modules/groupDetails/groupDetails.containers';
import groupModerators from '../../redux/modules/groupModerators/groupModerators.containers';
import type { GroupDetailsMapStateToProps } from '../../redux/modules/groupDetails/groupDetails.containers';
import type {
  AccountDetailsMapDispatchToProps,
  AccountDetailsMapStateToProps,
} from '../../redux/modules/accountDetails/accountDetails.containers';
import type {
  GroupModeratorsMapDispatchToProps,
  GroupModeratorsMapStateToProps,
} from '../../redux/modules/groupModerators/groupModerators.containers';
import './GroupAboutPage.scss';
import { URL_USER_PUBLIC_PROFILE } from '../../config/urls';
import EditGroupTab from '../../components/EditGroupTab/EditGroupTab';
import { isCitizen } from '../../utilities/authorization';
import isPrytanyVerified from '../../utilities/isPrytanyVerified';

type Props = GroupDetailsMapStateToProps &
  GroupModeratorsMapDispatchToProps &
  GroupModeratorsMapStateToProps &
  AccountDetailsMapDispatchToProps &
  AccountDetailsMapStateToProps & {
    match: any,
    location: any,
    history: any,
  };

type State = {
  editDescription: boolean,
};

const MODERATORS_PAGE_SIZE = 10;

class GroupAboutPage extends React.Component<Props, State> {
  constructor(props: Props) {
    super(props);

    this.state = {
      editDescription: false,
    };

    this.props.groupModeratorsReset();
  }

  componentDidMount() {
    this.props.getModeratorsForGroup({
      groupId: this.getGroupId(),
      limit: MODERATORS_PAGE_SIZE,
    });
  }

  componentDidUpdate(prevProps: Props, prevState: State) {
    //TODO: group update
  }

  render() {
    return (
      <div className="group-about-page container-fluid">
        <div className="row fix-width no-gutters pt-5">
          {this.renderSideBar()}
          {this.renderGroupDetails()}
        </div>
      </div>
    );
  }

  renderSideBar() {
    const moderators =
      (this.props.groupModeratorsData &&
        this.props.groupModeratorsData.data.moderators) ||
      [];
    return (
      <div className="col-12 col-md-3 pr-md-4">
        <ListWithAvatars
          title="Moderators"
          emptyListMessage="There are no moderators for this group"
          items={moderators.map(user => ({
            id: user.id,
            name: `${user.first_name} ${user.last_name}`.trim(),
            href: URL_USER_PUBLIC_PROFILE.replace(':id', user.id),
            image: user.profile_image,
            badge: user.role.code === 'candidate',
            verifiedLevel: user.verified_level,
            verifiedDetail: user.verified_detail,
          }))}
          buttonBottomText={
            this.props.groupModeratorsData &&
            this.props.groupModeratorsData.next_page
              ? 'VIEW MORE'
              : ''
          }
          onButtonBottomClick={() =>
            this.props.getModeratorsForGroupNextPage({
              groupId: this.getGroupId(),
              limit: MODERATORS_PAGE_SIZE,
            })
          }
          buttonBottomProps={{ loading: this.props.groupModeratorsIsLoading }}
          fixedHeight={200}
          displayBottomSeparator={false}
        />
      </div>
    );
  }

  renderGroupDetails() {
    const group =
      this.props.groupDetailsData && this.props.groupDetailsData.group;

    return (
      <div className="col-12 col-md-9">
        <div className="group-about-page__details pt-4 pb-4 px-4">
          {group ? <EditGroupTab {...this.props} /> : null}
        </div>
      </div>
    );
  }

  getGroupId() {
    return parseInt(this.props.match.params.groupId);
  }

  isModerator() {
    return _get(
      this.props,
      'groupDetailsData.group.current_user.is_moderator',
      false,
    );
  }
}

const mapStateToProps = (state, ownProps) => {
  return {
    ...accountDetails.mapStateToProps(state),
    ...groupDetails.mapStateToProps(state),
    ...groupModerators.mapStateToProps(state),
    ...ownProps,
  };
};

const mapDispatchToProps = dispatch => {
  return {
    ...groupModerators.mapDispatchToProps(dispatch),
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(GroupAboutPage);
