//@flow
import type { UserDetails } from '../services/RoRUsersApiProvider';
import trim from 'lodash/trim';
import isPresent from './isPresent';

const fullName = ({
  first_name,
  last_name,
}: {
  first_name: string,
  last_name: string,
}) => {
  return [first_name, last_name]
    .map(trim)
    .filter(isPresent)
    .join(' ');
};

export default fullName;
