//@flow
import * as React from 'react';
import Currency from '../Currency/Currency';
import type { UserDetails, UserRole } from '../../services/RoRUsersApiProvider';
import './CampaignOverview.scss';
import {
  isCandidate,
  isCitizen,
  isPac,
  isPoliticalParty,
} from '../../utilities/authorization';
import type { AuthenticatedUser } from '../../services/RoRApiProvider';
import moment from 'moment';
import values from 'lodash/values';

type Props = {
  user: AuthenticatedUser | null,
  userDetails: UserDetails | null,
};

const getCampaignTotal = (donations: { [string]: number } = {}) => {
  return values(donations).reduce(
    (total: number, amount: number) => total + amount,
  );
};

const CampaignOverview = ({ user, userDetails }: Props) => {
  if (!user || !userDetails) {
    return null;
  }

  const createdAt = moment(userDetails.created_at).format('MMM YYYY');
  const campaignTotal = getCampaignTotal(userDetails.donations);

  return (
    <div className="campaign-overview container-fluid">
      <div className="row fix-width">
        <div className="col-sm-12 col-md-5 col-xl-6 pr-0 pl-0 pl-md-3">
          <div className="row pt-1 pt-md-5">
            <div className="col pl-3 pl-md-0 pt-2 pt-md-1 ">
              <h1 className="name">Edit Profile</h1>
              <br />
              <span className="d-none d-lg-inline">
                {isCandidate(user) ? (
                  <img
                    src="/images/icon_candidate_big.png"
                    alt=""
                    className="pr-2"
                    height="24"
                  />
                ) : null}
              </span>
              {user && (
                <span className="date">{`${
                  isCitizen(user) ? 'Constituent' : user.role.name
                } ${
                  !(isPoliticalParty(user) || isPac(user))
                    ? `| Participant since ${createdAt}`
                    : ''
                }`}</span>
              )}
            </div>
          </div>
        </div>
        {isCitizen(user) ? (
          <div className="col-sm-12 col-md-7 col-xl-6 pt-2 pt-md-5 pl-0 pr-md-0">
            <div className="row pt-1">
              <div className="col-6 col-lg-12 text-right px-md-3 px-0 money">
                {/*Campaign total returns in cents, hence why is divided by 100*/}
                <Currency amount={campaignTotal / 100} showDecimals={true} />
              </div>
            </div>
          </div>
        ) : null}
        <div className="col-sm-12 col-md-7 col-xl-6 pt-2 pt-md-5 pl-0 pr-md-0">
          {userDetails.race ? (
            <div className="row">
              <div className="col text-md-right pr-0 pr-md-3">
                <h4 className="fs-18">{userDetails.race.name}</h4>
              </div>
            </div>
          ) : null}
        </div>
      </div>
    </div>
  );
};

export default CampaignOverview;
