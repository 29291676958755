// @flow
import * as React from 'react';
import { Field } from 'formik';
import classNames from 'classnames';

import './RadioButtons.scss';

type Props = {
  className?: string,
  inputClassName?: string,
  error?: string,
  label?: string,
  name: string,
  items: Array<{ label: string, value: string }>,
  size?: 'big' | 'normal',
  useFormik?: boolean,
};

const RadioButtons = (props: Props) => {
  const { useFormik, ...rest } = props;

  return useFormik ? (
    <RadioButtonsFormik {...rest} />
  ) : (
    <RadioButtonsIsolated {...rest} />
  );
};

RadioButtons.defaultProps = {
  useFormik: true,
  size: 'normal',
};

const RadioButtonsFormik = (props: Props) => {
  return (
    <div className={getClassName(props)}>
      <p className="mb-md-3 mb-2">{props.label}</p>
      <Field
        name={props.name}
        render={({ form, field }) => {
          return (
            <div className="radio__options-container">
              {props.items.map(item => {
                const inputId = `radio-${props.name}-${item.value}`;
                return (
                  <div key={inputId} className={getCustomInputClassName(props)}>
                    <input
                      onChange={field.onChange}
                      onBlur={field.onBlur}
                      name={props.name}
                      value={item.value}
                      checked={field.value === item.value}
                      id={inputId}
                      type="radio"
                      className="radio__input"
                    />
                    <label htmlFor={inputId} className="radio__input-label">
                      {item.label}
                    </label>
                  </div>
                );
              })}
            </div>
          );
        }}
      />
      {props.error ? (
        <span className="radio__error-message">{props.error}</span>
      ) : null}
    </div>
  );
};

type RadioButtonsIsolatedProps = Props & {
  onChange?: Function,
  value?: string,
};
type RadioButtonsIsolatedState = { value: string };

class RadioButtonsIsolated extends React.Component<
  RadioButtonsIsolatedProps,
  RadioButtonsIsolatedState,
> {
  inputId: string;

  constructor(props) {
    super(props);

    this.inputId = `select-${props.name}`;
    this.state = {
      value: this.props.value || '',
    };
  }

  componentDidUpdate(prevProps) {
    if (
      prevProps.value !== this.props.value &&
      this.state.value !== this.props.value
    ) {
      this.setState(() => ({ value: this.props.value }));
    }
  }

  render() {
    const {
      error,
      className,
      inputClassName,
      label,
      items,
      size,
      onChange,
      value,
      ...rest
    } = this.props;

    return (
      <div className={getClassName(this.props)}>
        {label ? <p className="mb-md-3 mb-2">{label}</p> : null}
        <div className="radio__options-container">
          {items.map(item => {
            const inputId = `radio-${this.props.name}-${item.value}`;
            return (
              <div
                key={inputId}
                className={getCustomInputClassName(this.props)}>
                <input
                  className="radio__input"
                  id={inputId}
                  onChange={this.handleChange}
                  value={item.value}
                  checked={this.state.value === item.value}
                  type="radio"
                  {...rest}
                />
                <label htmlFor={inputId} className="radio__input-label">
                  {item.label}
                </label>
              </div>
            );
          })}
        </div>
        {error ? <span className="radio__error-message">{error}</span> : null}
      </div>
    );
  }

  handleChange = (e: any) => {
    const value = e.target.value;

    this.setState(
      () => ({ value }),
      () => {
        if (this.props.onChange) {
          this.props.onChange({
            name: this.props.name,
            value: this.state.value,
          });
        }
      },
    );
  };
}

function getClassName(props) {
  return classNames({
    'form-group': true,
    [props.className || '']: props.className,
  });
}

function getCustomInputClassName(props) {
  return classNames({
    radio: true,
    'radio-primary': true,
    'radio-inline': true,
    [`radio--size-${props.size || ''}`]: true,
    'radio--error': props.error,
    [props.inputClassName || '']: props.inputClassName,
  });
}

export default RadioButtons;
