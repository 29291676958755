// @flow
import React from 'react';
import classNames from 'classnames';
import Button from '../Button/Button';
import Modal from '../Modal/Modal';
import './ModalFirstTimeTutorial.scss';

type Props = {
  isOpen: boolean,
  onAfterOpen?: Function,
  onCloseRequest?: Function,
  className?: string,
};

type State = {
  currentSection: 'welcome' | 'feed' | 'profiles' | 'contribute',
  currentStep: number,
};

class ModalFirstTimeTutorial extends React.Component<Props, State> {
  constructor(props: Props) {
    super(props);

    (this: any).handleBeginButtonClick = this.handleBeginButtonClick.bind(this);
    (this: any).handleCloseRequest = this.handleCloseRequest.bind(this);
    (this: any).handleNextStep = this.handleNextStep.bind(this);
    (this: any).handlePrevStep = this.handlePrevStep.bind(this);
    (this: any).handleSectionChange = this.handleSectionChange.bind(this);
    this.state = {
      currentSection: 'welcome',
      currentStep: 0,
    };
  }

  render() {
    const { isOpen, onAfterOpen, ...rest } = this.props;

    return (
      <Modal
        contentLabel="Create a new post"
        isOpen={isOpen}
        onAfterOpen={onAfterOpen}
        onCloseRequest={this.handleCloseRequest}
        className={this.getClassName()}
        size="big"
        {...rest}>
        {isOpen ? (
          <React.Fragment>
            {this.state.currentSection !== 'welcome' ? (
              <SideBarSelector
                activeSection={this.state.currentSection}
                onSectionSelect={this.handleSectionChange}
              />
            ) : null}
            <div className="modal-tutorial__content">
              {this.renderContent()}
            </div>
          </React.Fragment>
        ) : null}
      </Modal>
    );
  }

  renderContent() {
    const renderConfig = {
      welcome: () => this.renderWelcome(),
      feed: () => this.renderFeedSection(),
      profiles: () => this.renderProfilesSection(),
      contribute: () => this.renderContributeSection(),
    };

    return renderConfig[this.state.currentSection]();
  }

  renderWelcome() {
    return (
      <div className="modal-tutorial__welcome-screen">
        <div className="modal-tutorial__welcome-screen-controls">
          <h1 className="modal-tutorial__welcome-screen-title">
            Welcome to Prytany
          </h1>
          <p>
            Let's take a moment to get to know the
            <br /> main features and ways around the app.
          </p>
        </div>
        <div className="modal-tutorial__welcome-screen-controls">
          <Button
            onClick={this.handleBeginButtonClick}
            buttonType="primary"
            className="modal-tutorial__welcome-screen-primary-button">
            Begin
          </Button>
          <Button
            onClick={this.handleCloseRequest}
            buttonType="link"
            className="modal-tutorial__welcome-screen-secondary-button">
            I'm ok, thanks
          </Button>
        </div>
      </div>
    );
  }

  renderFeedSection() {
    const feedSteps = [
      <div className="modal-tutorial__welcome-screen">
        <div className="modal-tutorial__welcome-screen-content">
          <div className="modal-tutorial__layout--two-columns">
            <div className="modal-tutorial__layout--two-columns-item modal-tutorial__text-container">
              <div className="modal-tutorial__step">
                <span className="modal-tutorial__step-number">Step 1</span> of 5
              </div>
              <h1 className="modal-tutorial__title">
                The Feed: navigate through the latest activity
              </h1>
              <p className="modal-tutorial__text">
                Welcome again! thank you for joining this platform
                revolutionizing the political community.
              </p>
              <p className="modal-tutorial__text">
                The landing screen upon sign in is the activity feed, where you
                have a chronological display of all of the different activities
                your followed candidates, groups and users perform, such as
                posts, contributions, races activity.
              </p>
            </div>
            <div className="modal-tutorial__layout--two-columns-item modal-tutorial__image-container">
              <img
                src="/images/modal-tutorial-feed-step-1.png"
                className="modal-tutorial__image"
                alt=""
              />
            </div>
          </div>
        </div>
        <div className="modal-tutorial__welcome-screen-controls">
          <StepSelector
            currentStep={0}
            totalSteps={5}
            onNextClick={this.handleNextStep}
            onPrevClick={this.handlePrevStep}
          />
        </div>
      </div>,
      <div className="modal-tutorial__welcome-screen">
        <div className="modal-tutorial__welcome-screen-content">
          <div className="modal-tutorial__layout--hero">
            <div className="modal-tutorial__layout--two-columns-item modal-tutorial__text-container">
              <div className="modal-tutorial__step">
                <span className="modal-tutorial__step-number">Step 2</span> of 5
              </div>
              <h1 className="modal-tutorial__title">Main navigation (top)</h1>
              <img
                src="/images/modal-tutorial-feed-step-2-a.png"
                className="modal-tutorial__image modal-tutorial__layout--hero-banner"
                alt=""
              />
              <div className="modal-tutorial__layout--hero-text-container">
                <div className="modal-tutorial__layout--hero-text-item">
                  <h2 className="modal-tutorial__subtitle">Logo</h2>
                  <p className="modal-tutorial__text">
                    Logo Click (or tap) on it whenever you need to return home.
                  </p>
                  <h2 className="modal-tutorial__subtitle">Search icon</h2>
                  <p className="modal-tutorial__text">
                    Tap this icon to access the search functionallity where you
                    will be able to find your query of Candidates, Groups, Races
                    or Constituents. This is the way to start engaging with your
                    interests on the platform by folowing them (refer to
                    “Profiles” for more info).
                  </p>
                </div>
                <div className="modal-tutorial__layout--hero-text-item">
                  <h2 className="modal-tutorial__subtitle">My Account</h2>
                  <p className="modal-tutorial__text">
                    This menu houses your message inbox, account settings and
                    help.
                  </p>
                  <img
                    src="/images/modal-tutorial-feed-step-2-b.png"
                    className=""
                    alt=""
                  />
                  <p className="modal-tutorial__text">
                    If you are browsing from a mobile device you can find the
                    navigation by tapping on the “hamburger” icon over the top
                    left corner.
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="modal-tutorial__welcome-screen-controls">
          <StepSelector
            currentStep={1}
            totalSteps={5}
            onNextClick={this.handleNextStep}
            onPrevClick={this.handlePrevStep}
          />
        </div>
      </div>,
      <div className="modal-tutorial__welcome-screen">
        <div className="modal-tutorial__layout--two-columns">
          <div className="modal-tutorial__layout--two-columns-item modal-tutorial__text-container">
            <div className="modal-tutorial__step">
              <span className="modal-tutorial__step-number">Step 3</span> of 5
            </div>
            <h1 className="modal-tutorial__title">Side Column</h1>
            <h2 className="modal-tutorial__subtitle">New post button</h2>
            <p className="modal-tutorial__text">
              Click or tap on this button to create a new post with your own
              content for the community to see.
            </p>
            <h2 className="modal-tutorial__subtitle">Filters</h2>
            <p className="modal-tutorial__text">
              The activity feed can get pretty dense as you engage with the
              Prytany. Use these options to narrow down the content presented to
              you either by type of author or type of content.
            </p>
          </div>
          <div className="modal-tutorial__layout--two-columns-item modal-tutorial__image-medium-container">
            <img
              src="/images/modal-tutorial-feed-step-3.png"
              className="modal-tutorial__image-medium"
              alt=""
            />
          </div>
        </div>
        <div className="modal-tutorial__welcome-screen-controls">
          <StepSelector
            currentStep={2}
            totalSteps={5}
            onNextClick={this.handleNextStep}
            onPrevClick={this.handlePrevStep}
          />
        </div>
      </div>,
      <div className="modal-tutorial__welcome-screen">
        <div className="modal-tutorial__layout--two-columns">
          <div className="modal-tutorial__layout--two-columns-item modal-tutorial__text-container">
            <div className="modal-tutorial__step">
              <span className="modal-tutorial__step-number">Step 4</span> of 5
            </div>
            <h1 className="modal-tutorial__title">Side Column</h1>
            <h2 className="modal-tutorial__subtitle">Groups</h2>
            <p className="modal-tutorial__text">
              Prytany provides the possibility of creating and or joining groups
              supporting particular candidates or interests.
            </p>
            <h2 className="modal-tutorial__subtitle">Following</h2>
            <p className="modal-tutorial__text">
              See all of the candidates and constituents you decided to follow
              to get updates on their activity.
            </p>
            <h2 className="modal-tutorial__subtitle">Races</h2>
            <p className="modal-tutorial__text">
              See all of the activity on races you decided to follow to get
              updates.
            </p>
          </div>
          <div className="modal-tutorial__layout--two-columns-item modal-tutorial__image-medium-container">
            <img
              src="/images/modal-tutorial-feed-step-4.png"
              className="modal-tutorial__image-medium"
              alt=""
            />
          </div>
        </div>
        <div className="modal-tutorial__welcome-screen-controls">
          <StepSelector
            currentStep={3}
            totalSteps={5}
            onNextClick={this.handleNextStep}
            onPrevClick={this.handlePrevStep}
          />
        </div>
      </div>,
      <div className="modal-tutorial__welcome-screen">
        <div className="modal-tutorial__layout--two-columns">
          <div className="modal-tutorial__layout--two-columns-item modal-tutorial__text-container">
            <div className="modal-tutorial__step">
              <span className="modal-tutorial__step-number">Step 5</span> of 5
            </div>
            <h1 className="modal-tutorial__title">Activity</h1>
            <p className="modal-tutorial__text">
              We display different activity items in the feed thru cards. You
              can find updates such as people joining your groups, new followers
              or latest ranking of candidates in races you take interest on.
              Posts are pieces of content created specially by users (candidates
              or other constituents, as yourself!). You can open a post to read
              it fully, and then like, comment and or share it. There’s also
              updates on latest contributions, detailing who contributed, to
              whom, amount and conditions (if applicable). That type of card
              features a button to replicate that same contribution yourself.
            </p>
          </div>
          <div className="modal-tutorial__layout--two-columns-item modal-tutorial__image-medium-container">
            <img
              src="/images/modal-tutorial-feed-step-5.png"
              className="modal-tutorial__image-medium"
              alt=""
            />
          </div>
        </div>
        <div className="modal-tutorial__welcome-screen-controls">
          <StepSelector
            currentStep={4}
            totalSteps={5}
            onNextClick={this.handleNextStep}
            onPrevClick={this.handlePrevStep}
          />
        </div>
      </div>,
    ];

    return feedSteps[this.state.currentStep];
  }

  renderProfilesSection() {
    const profileSteps = [
      <div className="modal-tutorial__welcome-screen">
        <div className="modal-tutorial__welcome-screen-content">
          <div className="modal-tutorial__layout--two-columns">
            <div className="modal-tutorial__layout--two-columns-item modal-tutorial__text-container d-block">
              <div className="modal-tutorial__step">
                <span className="modal-tutorial__step-number">Step 1</span> of 3
              </div>
              <h1 className="modal-tutorial__title">Profile pages</h1>
              <p className="modal-tutorial__text">
                We established you could find your query of candidates or users
                thru the search box, and you can also encounter them in some of
                the feed’s updates; so now to open their profile pages
                containing detailed information and actions, you can click on
                their name or avatar (profile pic).
                <br />
                To distinguish candidates from regular users, be aware of the
                purple badge next to their pics:
              </p>
              <img
                src="/images/modal-tutorial-profile-step-1-a.png"
                className="modal-tutorial__image--small"
                alt=""
              />
            </div>
            <div className="modal-tutorial__layout--two-columns-item modal-tutorial__image-container">
              <img
                src="/images/modal-tutorial-profile-step-1-b.png"
                className="modal-tutorial__image"
                alt=""
              />
            </div>
          </div>
        </div>
        <div className="modal-tutorial__welcome-screen-controls">
          <StepSelector
            currentStep={0}
            totalSteps={3}
            onNextClick={this.handleNextStep}
            onPrevClick={this.handlePrevStep}
          />
        </div>
      </div>,
      <div className="modal-tutorial__welcome-screen">
        <div className="modal-tutorial__welcome-screen-content">
          <div className="modal-tutorial__layout--two-columns">
            <div className="modal-tutorial__layout--two-columns-item modal-tutorial__text-container d-block">
              <div className="modal-tutorial__step">
                <span className="modal-tutorial__step-number">Step 2</span> of 3
              </div>
              <h1 className="modal-tutorial__title">Constituent profile</h1>
              <p className="modal-tutorial__text">
                When constituents set their profile as public, you can access
                them the same way as candidates’. This is also the way your own
                profile looks. It features the particular activity of the user,
                his/her stats and interests.
              </p>
            </div>
            <div className="modal-tutorial__layout--two-columns-item modal-tutorial__image-container">
              <img
                src="/images/modal-tutorial-profile-step-2.png"
                className="modal-tutorial__image"
                alt=""
              />
            </div>
          </div>
        </div>
        <div className="modal-tutorial__welcome-screen-controls">
          <StepSelector
            currentStep={1}
            totalSteps={3}
            onNextClick={this.handleNextStep}
            onPrevClick={this.handlePrevStep}
          />
        </div>
      </div>,
      <div className="modal-tutorial__welcome-screen">
        <div className="modal-tutorial__welcome-screen-content">
          <div className="modal-tutorial__layout--hero">
            <div className="modal-tutorial__layout--two-columns-item modal-tutorial__text-container">
              <div className="modal-tutorial__step">
                <span className="modal-tutorial__step-number">Step 3</span> of 3
              </div>
              <h1 className="modal-tutorial__title mb-5">
                Actions available in profile pages
              </h1>
              <div className="modal-tutorial__layout--hero-text-container">
                <div className="modal-tutorial__layout--hero-text-item">
                  <img
                    src="/images/modal-tutorial-profile-step-3-a.png"
                    className="mb-1"
                    alt=""
                  />
                  <h2 className="modal-tutorial__subtitle">Follow toggle</h2>
                  <p className="modal-tutorial__text">
                    Next to the user’s name you will find the Follow toggle.
                    Activate it and you wil be receiving updates of that person
                    on your feed. You can quit following by deactivating the
                    toggle.
                  </p>
                </div>
                <div className="modal-tutorial__layout--hero-text-item">
                  <img
                    src="/images/modal-tutorial-profile-step-3-b.png"
                    className="mb-3"
                    alt=""
                  />
                  <h2 className="modal-tutorial__subtitle">
                    Contribute button (candidate)
                  </h2>
                  <p className="modal-tutorial__text">
                    To the right side of the candidate’s profile header you have
                    the contribute button to access a choice of contribution
                    methods to support your candidate.
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="modal-tutorial__welcome-screen-controls">
          <StepSelector
            currentStep={2}
            totalSteps={3}
            onNextClick={this.handleNextStep}
            onPrevClick={this.handlePrevStep}
          />
        </div>
      </div>,
    ];

    return profileSteps[this.state.currentStep];
  }

  renderContributeSection() {
    const profileSteps = [
      <div className="modal-tutorial__welcome-screen">
        <div className="modal-tutorial__welcome-screen-content">
          <div className="modal-tutorial__layout--three-columns">
            <div className="modal-tutorial__layout--three-columns-item modal-tutorial__text-container">
              <div className="modal-tutorial__step">
                <span className="modal-tutorial__step-number">Step 1</span> of 3
              </div>
              <h1 className="modal-tutorial__title mt-3">
                Contribution methods
              </h1>
              <p className="modal-tutorial__text">
                We offer three different types of contribution:
              </p>
              <div className="modal-tutorial__layout--three-columns-text-container">
                <div className="modal-tutorial__layout--three-columns-text-item">
                  <img
                    src="/images/icon_contribute_direct.png"
                    className="modal-tutorial__image-icon"
                    alt=""
                  />
                  <h2 className="modal-tutorial__subtitle">Direct</h2>
                  <p className="modal-tutorial__text">
                    Make a one-time contribution that will go to your candidate
                    right away.
                  </p>
                </div>
                <div className="modal-tutorial__layout--three-columns-text-item">
                  <img
                    src="/images/icon_contribute_commited.png"
                    className="modal-tutorial__image-icon"
                    alt=""
                  />
                  <h2 className="modal-tutorial__subtitle">Commit</h2>
                  <p className="modal-tutorial__text">
                    Schedule multiple contributions over a period of time.
                    Future commitments are cancelled if your candidate drops
                    out.
                  </p>
                </div>
                <div className="modal-tutorial__layout--three-columns-text-item">
                  <img
                    src="/images/icon_contribute_pledge.png"
                    className="modal-tutorial__image-icon"
                    alt=""
                  />
                  <h2 className="modal-tutorial__subtitle">Pledge</h2>
                  <p className="modal-tutorial__text">
                    Commit a contribution to a candidate to amplify or counter a
                    special interest.
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="modal-tutorial__welcome-screen-controls">
          <StepSelector
            currentStep={0}
            totalSteps={3}
            onNextClick={this.handleNextStep}
            onPrevClick={this.handlePrevStep}
          />
        </div>
      </div>,
      <div className="modal-tutorial__welcome-screen">
        <div className="modal-tutorial__welcome-screen-content">
          <div className="modal-tutorial__layout--two-columns">
            <div className="modal-tutorial__layout--two-columns-item modal-tutorial__text-container d-block">
              <div className="modal-tutorial__step">
                <span className="modal-tutorial__step-number">Step 2</span> of 3
              </div>
              <h1 className="modal-tutorial__title">Payment methods</h1>
              <h2 className="modal-tutorial__subtitle">Total</h2>
              <p className="modal-tutorial__text">
                As a first step, you have to input the total amount you intend
                to contribute, regardless of its possible spread over any number
                of months (if applicable), which you will choose later.
              </p>
              <h2 className="modal-tutorial__subtitle">Pay with</h2>
              <p className="modal-tutorial__text">
                You are able to select a new credit card to process your payment
                right away (with a 2,9% + $0.30 of charge), any stored credit
                card, or any stored bank account (free of charge). You can set
                up a bank account through the{' '}
                <strong>“Add a new Bank Account”</strong> link.
              </p>
            </div>
            <div className="modal-tutorial__layout--two-columns-item modal-tutorial__image-shadow-container">
              <img
                src="/images/modal-tutorial-contribute-step-2.png"
                className="modal-tutorial__image"
                alt=""
              />
            </div>
          </div>
        </div>
        <div className="modal-tutorial__welcome-screen-controls">
          <StepSelector
            currentStep={1}
            totalSteps={3}
            onNextClick={this.handleNextStep}
            onPrevClick={this.handlePrevStep}
          />
        </div>
      </div>,
      <div className="modal-tutorial__welcome-screen">
        <div className="modal-tutorial__welcome-screen-content">
          <div className="modal-tutorial__layout--hero">
            <div className="modal-tutorial__layout--two-columns-item modal-tutorial__text-container">
              <div className="modal-tutorial__step">
                <span className="modal-tutorial__step-number">Step 3</span> of 3
              </div>
              <h1 className="modal-tutorial__title mb-3">
                Pledge contribution
              </h1>
              <h2 className="modal-tutorial__subtitle mb-3">Condition</h2>
              <div className="modal-tutorial__layout--hero-text-container">
                <div className="modal-tutorial__layout--hero-text-item">
                  <div>
                    <img
                      src="/images/icon-pledge-increase.png"
                      className="modal-tutorial__image-icon--small"
                      alt=""
                    />
                    <div className="modal-tutorial__text-container--with-icon">
                      <h2 className="modal-tutorial__subtitle">
                        Increase rating
                      </h2>
                      <p className="modal-tutorial__text">
                        Contribution will be fulfilled if the candidate
                        increases rating over the selected special interest.
                      </p>
                    </div>
                  </div>
                  <div>
                    <img
                      src="/images/icon-pledge-maintain.png"
                      className="modal-tutorial__image-icon--small"
                      alt=""
                    />
                    <div className="modal-tutorial__text-container--with-icon">
                      <h2 className="modal-tutorial__subtitle">
                        Maintain rating
                      </h2>
                      <p className="modal-tutorial__text">
                        Contribution will be fulfilled if the candidate
                        maintains rating over the selected special interest.
                      </p>
                    </div>
                  </div>
                  <div>
                    <img
                      src="/images/icon-pledge-decrease.png"
                      className="modal-tutorial__image-icon--small"
                      alt=""
                    />
                    <div className="modal-tutorial__text-container--with-icon">
                      <h2 className="modal-tutorial__subtitle">
                        Decrease rating
                      </h2>
                      <p className="modal-tutorial__text">
                        Contribution will be fulfilled if the candidate
                        decreases rating over the selected special interest.
                      </p>
                    </div>
                  </div>
                </div>
                <div className="modal-tutorial__layout--hero-text-item">
                  <h2 className="modal-tutorial__subtitle">About</h2>
                  <p className="modal-tutorial__text">
                    Here you select the special interest group under which the
                    candidate will be rated.
                  </p>
                  <div className="modal-tutorial__layout--two-columns-item modal-tutorial__image-shadow-container">
                    <img
                      src="/images/modal-tutorial-contribute-step-3.png"
                      className="modal-tutorial__image-contribute"
                      alt=""
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="modal-tutorial__welcome-screen-controls">
          <StepSelector
            currentStep={2}
            totalSteps={3}
            onNextClick={this.handleNextStep}
            onPrevClick={this.handlePrevStep}
          />
        </div>
      </div>,
    ];

    return profileSteps[this.state.currentStep];
  }

  handleSectionChange(section: 'feed' | 'profiles' | 'contribute') {
    this.setState(() => ({ currentSection: section, currentStep: 0 }));
  }

  handleBeginButtonClick() {
    this.setState(() => ({ currentSection: 'feed' }));
  }

  handlePrevStep() {
    this.setState(() => ({ currentStep: this.state.currentStep - 1 }));
  }

  handleNextStep() {
    this.setState(() => ({ currentStep: this.state.currentStep + 1 }));
  }

  getClassName() {
    return classNames({
      'modal-tutorial': true,
      'modal-tutorial--has-sidebar': this.state.currentSection !== 'welcome',
      [this.props.className || '']: this.props.className,
    });
  }

  handleCloseRequest() {
    if (this.props.onCloseRequest) {
      this.props.onCloseRequest();
    }
  }
}

export default ModalFirstTimeTutorial;

const SideBarSelector = (props: {
  activeSection: string,
  onSectionSelect: Function,
}) => {
  const { onSectionSelect, activeSection } = props;

  function getSidebarButtonProps() {
    return {
      buttonType: 'link',
      className: 'modal-tutorial__sidebar-button',
    };
  }

  return (
    <ul className="modal-tutorial__sidebar">
      <li className="modal-tutorial__sidebar-title">Sections</li>
      <li
        className={`modal-tutorial__sidebar-item ${
          activeSection === 'feed' ? 'modal-tutorial__sidebar-item--active' : ''
        }`}>
        <Button
          {...getSidebarButtonProps()}
          onClick={onSectionSelect.bind(this, 'feed')}>
          FEED
        </Button>
      </li>
      <li
        className={`modal-tutorial__sidebar-item ${
          activeSection === 'profiles'
            ? 'modal-tutorial__sidebar-item--active'
            : ''
        }`}>
        <Button
          {...getSidebarButtonProps()}
          onClick={onSectionSelect.bind(this, 'profiles')}>
          PROFILES
        </Button>
      </li>
      <li
        className={`modal-tutorial__sidebar-item ${
          activeSection === 'contribute'
            ? 'modal-tutorial__sidebar-item--active'
            : ''
        }`}>
        <Button
          {...getSidebarButtonProps()}
          onClick={onSectionSelect.bind(this, 'contribute')}>
          CONTRIBUTE
        </Button>
      </li>
    </ul>
  );
};

const StepSelector = (props: {
  currentStep: number,
  onNextClick: Function,
  onPrevClick: Function,
  totalSteps: number,
}) => {
  const { totalSteps, currentStep, onPrevClick, onNextClick } = props;

  function changeStep(direction: 'prev' | 'next') {
    if (direction === 'prev' && currentStep > 0) {
      onPrevClick();
    } else if (direction === 'next' && currentStep < totalSteps - 1) {
      onNextClick();
    }
  }

  const indicators = [];
  for (let i = 0; i < totalSteps; i++) {
    indicators.push(
      <li className="modal-tutorial__step-selector-item" key={`indicator-${i}`}>
        <span
          className={`modal-tutorial__step-selector-indicator ${
            currentStep === i
              ? 'modal-tutorial__step-selector-indicator--active'
              : ''
          }`}
        />
      </li>,
    );
  }

  return (
    <div className="modal-tutorial__step-selector">
      <Button
        buttonType="link"
        className="modal-tutorial__step-selector-button-prev"
        onClick={changeStep.bind(this, 'prev')}>
        {'<'}
      </Button>
      <ul className="modal-tutorial__step-selector-list">{indicators}</ul>
      <Button
        buttonType="link"
        className="modal-tutorial__step-selector-button-next"
        onClick={changeStep.bind(this, 'next')}>
        {'>'}
      </Button>
    </div>
  );
};
