// @flow
import React from 'react';
import _get from 'lodash/get';
import { connect } from 'react-redux';
import moment from 'moment';
import SideTabPanel from '../../components/SideTabPanel/SideTabPanel';
import Table from '../../components/Table/Table';
import Currency from '../../components/Currency/Currency';
import Avatar from '../../components/Avatar/Avatar';
import account from '../../redux/modules/account/account.containers';
import accountDetails from '../../redux/modules/accountDetails/accountDetails.containers';
import accountCandidatePledges from '../../redux/modules/accountCandidatePledges/accountCandidatePledges.containers';
import accountCandidateDirectContributions from '../../redux/modules/accountCandidateDirectContributions/accountCandidateDirectContributions.containers';
import accountCandidateCommitments from '../../redux/modules/accountCandidateCommitments/accountCandidateCommitments.containers';
import type { AccountDetailsMapStateToProps } from '../../redux/modules/accountDetails/accountDetails.containers';
import type {
  AccountCandidateCommitmentsMapDispatchToProps,
  AccountCandidateCommitmentsMapStateToProps,
} from '../../redux/modules/accountCandidateCommitments/accountCandidateCommitments.containers';
import type {
  CandidateCommitment,
  CandidateDirectContribution,
  CandidatePledge,
} from '../../services/RoRUsersApiProvider';
import type {
  AccountCandidateDirectContributionsMapDispatchToProps,
  AccountCandidateDirectContributionsMapStateToProps,
} from '../../redux/modules/accountCandidateDirectContributions/accountCandidateDirectContributions.containers';
import type {
  AccountCandidatePledgesMapDispatchToProps,
  AccountCandidatePledgesMapStateToProps,
} from '../../redux/modules/accountCandidatePledges/accountCandidatePledges.containers';
import Button from '../../components/Button/Button';
import ModalContributionDetails from '../../components/ModalContributionDetails/ModalContributionDetails';
import {
  isCitizen,
  isPac,
  isPoliticalParty,
} from '../../utilities/authorization';
import type { AccountMapStateToProps } from '../../redux/modules/account/account.containers';
import isPrytanyVerified from '../../utilities/isPrytanyVerified';
import type { UserVerifiedLevel } from '../../services/RoRUsersApiProvider';

type Props = AccountMapStateToProps &
  AccountDetailsMapStateToProps &
  AccountCandidateCommitmentsMapDispatchToProps &
  AccountCandidateCommitmentsMapStateToProps &
  AccountCandidateDirectContributionsMapDispatchToProps &
  AccountCandidateDirectContributionsMapStateToProps &
  AccountCandidatePledgesMapDispatchToProps &
  AccountCandidatePledgesMapStateToProps & {};

type State = {
  currentTab: 'contributed' | 'committed' | 'pledged',
  contributionDetailsPayload: {
    type: 'direct' | 'committed' | 'pledged',
    id: string,
    contributor: {
      name: string,
      profileImage: string,
      verifiedLevel?: UserVerifiedLevel,
      verifiedDetail?: string,
    },
  } | null,
};

const PAGE_SIZE = 10;

class DashboardContributionsPage extends React.Component<Props, State> {
  constructor(props: Props) {
    super(props);

    this.state = {
      currentTab: 'contributed',
      contributionDetailsPayload: null,
    };

    this.props.accountCandidateCommitmentsReset();
    this.props.accountCandidateDirectContributionsReset();
    this.props.accountCandidatePledgesReset();
  }

  componentDidMount() {
    this.fetchContributions();
  }

  componentDidUpdate(prevProps: Props, prevState: State) {
    if (prevState.currentTab !== this.state.currentTab) {
      this.fetchContributions();
      this.setState(() => ({ contributionDetailsPayload: null }));
    }
  }

  render() {
    const { contributionDetailsPayload } = this.state;
    return (
      <div className="dashboard-contributions-page container-fluid">
        <div className="row fix-width">
          <div className="col-md-3">
            <SideTabPanel
              tabs={this.getSideTabs()}
              defaultSelectedTab={'contributed'}
              onTabSelected={this.handleSideTabSelection}
            />
          </div>
          <div className="col-md-9">
            <div className="dashboard-page__table-wrapper">
              <Table {...this.getTableProps()} />
            </div>
          </div>
        </div>
        <ModalContributionDetails
          isOpen={contributionDetailsPayload !== null}
          onCloseRequest={this.handleCloseDetailsModal}
          {...contributionDetailsPayload || {}}
        />
      </div>
    );
  }

  renderDetailsButton(data) {
    return (
      <Button
        buttonType="link"
        type="button"
        size="small"
        onClick={() => this.handleOpenContributionDetailsModal(data)}>
        Details
      </Button>
    );
  }

  fetchContributions(page?: number = 1) {
    const fetchData = {
      committed: payload => this.props.getAccountCandidateCommitments(payload),
      pledged: payload => this.props.getAccountCandidatePledges(payload),
      contributed: payload =>
        this.props.getAccountCandidateDirectContributions(payload),
    };

    fetchData[this.state.currentTab]({
      limit: PAGE_SIZE,
      page,
    });
  }

  getContributionsSource() {
    const source = {
      committed: this.props.accountCandidateCommitmentsData,
      pledged: this.props.accountCandidatePledgesData,
      contributed: this.props.accountCandidateDirectContributionsData,
    };

    return source[this.state.currentTab];
  }

  isLoadingContributions() {
    return (
      this.props.accountCandidateCommitmentsIsLoading ||
      this.props.accountCandidatePledgesIsLoading ||
      this.props.accountCandidateDirectContributionsIsLoading
    );
  }

  getSideTabs() {
    const { accountData, accountDetailsData } = this.props;

    if (!accountData || !accountDetailsData) {
      return [];
    }

    const { user } = accountData;

    const {
      //userDetails
      user: { donations = {} },
    } = accountDetailsData;

    const tabs = [
      {
        key: 'contributed',
        content: (
          <React.Fragment>
            <p>Contributed</p>
            <Currency amount={donations.contributed / 100} />
          </React.Fragment>
        ),
      },
      {
        key: 'committed',
        content: (
          <React.Fragment>
            <p>Committed</p>
            <Currency amount={donations.committed / 100} />
          </React.Fragment>
        ),
      },
    ];

    if (!isPoliticalParty(user) || !isPac(user)) {
      tabs.push({
        key: 'pledged',
        content: (
          <React.Fragment>
            <p>Pledged</p>
            <Currency amount={donations.pledged / 100} />
          </React.Fragment>
        ),
      });
    }

    return tabs;
  }

  getTableProps() {
    const source = this.getContributionsSource();

    return {
      dataSource: this.getTableData(),
      columns: this.getTableColumns(),
      pagination:
        source && source.total_pages > 1
          ? {
              total: source.total_pages * PAGE_SIZE,
              current: source.current_page,
              pageSize: PAGE_SIZE,
            }
          : false,
      loading: this.isLoadingContributions(),
      onChange: this.handleTableChange,
    };
  }

  getTableColumns() {
    const getData = {
      committed: () => this.getCommittedTableColumns(),
      pledged: () => this.getPledgedTableColumns(),
      contributed: () => this.getContributedTableColumns(),
    };

    return getData[this.state.currentTab]();
  }

  getCommittedTableColumns() {
    return [
      {
        title: 'NAME',
        dataIndex: 'name',
        key: 'name',
        render: (text: string, data: any) => (
          <React.Fragment>
            <Avatar
              className="dashboard-page__table-cell__name-avatar"
              source={data.profileImage}
              verifiedLevel={data.verifiedLevel}
              verifiedDetail={data.verifiedDetail}
              type="tiny"
            />
            <span className="dashboard-page__table-cell__name-text">
              {text}
            </span>
          </React.Fragment>
        ),
      },
      {
        title: 'STATE',
        dataIndex: 'state',
        key: 'state',
      },
      {
        title: 'DATE',
        dataIndex: 'date',
        key: 'date',
      },
      {
        title: 'DURATION',
        dataIndex: 'duration',
        key: 'duration',
      },
      {
        title: 'AMOUNT',
        dataIndex: 'amount',
        key: 'amount',
        render: (amount: number) => <Currency amount={amount} />,
      },
      {
        title: '',
        dataIndex: 'message',
        key: 'message',
        render: (text, data) => this.renderDetailsButton(data),
      },
    ];
  }

  getPledgedTableColumns() {
    return [
      {
        title: 'NAME',
        dataIndex: 'name',
        key: 'name',
        defaultSortOrder: 'ascend',
        render: (text: string, data: any) => (
          <React.Fragment>
            <Avatar
              className="dashboard-page__table-cell__name-avatar"
              source={data.profileImage}
              verifiedLevel={data.verifiedLevel}
              verifiedDetail={data.verifiedDetail}
              type="tiny"
            />
            <span className="dashboard-page__table-cell__name-text">
              {text}
            </span>
          </React.Fragment>
        ),
      },
      {
        title: 'STATE',
        dataIndex: 'state',
        key: 'state',
      },
      {
        title: 'DATE',
        dataIndex: 'date',
        key: 'date',
      },
      {
        title: 'TIME LEFT',
        dataIndex: 'timeLeft',
        key: 'timeLeft',
      },
      {
        title: 'CONDITION',
        dataIndex: 'condition',
        key: 'condition',
        render: (condition: number) => (
          <img src={`/images/icon-pledge-${condition}.png`} />
        ),
      },
      {
        title: 'AMOUNT',
        dataIndex: 'amount',
        key: 'amount',
        render: (amount: number) => <Currency amount={amount} />,
      },
      {
        title: '',
        dataIndex: 'message',
        key: 'message',
        render: (text, data) => this.renderDetailsButton(data),
      },
    ];
  }

  getContributedTableColumns() {
    return [
      {
        title: 'NAME',
        dataIndex: 'name',
        key: 'name',
        defaultSortOrder: 'ascend',
        render: (text: string, data: any) => (
          <React.Fragment>
            <Avatar
              className="dashboard-page__table-cell__name-avatar"
              source={data.profileImage}
              verifiedLevel={data.verifiedLevel}
              verifiedDetail={data.verifiedDetail}
              type="tiny"
            />
            <span className="dashboard-page__table-cell__name-text">
              {text}
            </span>
          </React.Fragment>
        ),
      },
      {
        title: 'STATE',
        dataIndex: 'state',
        key: 'state',
      },
      {
        title: 'DATE',
        dataIndex: 'date',
        key: 'date',
      },
      {
        title: 'AMOUNT',
        dataIndex: 'amount',
        key: 'amount',
        render: (amount: number) => <Currency amount={amount} />,
      },
      {
        title: '',
        dataIndex: 'message',
        key: 'message',
        render: (text, data) => this.renderDetailsButton(data),
      },
    ];
  }

  getTableData() {
    const getData = {
      committed: () => this.getCommittedTableData(),
      pledged: () => this.getPledgedTableData(),
      contributed: () => this.getContributedTableData(),
    };

    return getData[this.state.currentTab]();
  }

  getCommittedTableData() {
    const data = _get(
      this.props,
      'accountCandidateCommitmentsData.data.committed',
      [],
    );

    return data.map((contribution: CandidateCommitment) => ({
      key: contribution.id,
      name: `${contribution.contributor.first_name} ${
        contribution.contributor.last_name
      }`,
      state: contribution.contributor.state.code,
      date: moment(contribution.created_at).format('MM/DD/YYYY'),
      duration: `${contribution.period_in_months} months`,
      amount: contribution.amount / 100,
      profileImage: contribution.contributor.profile_image,
      verifiedLevel: contribution.contributor.verified_level,
      verifiedDetail: contribution.contributor.verified_detail,
      type: 'committed',
    }));
  }

  getPledgedTableData() {
    const data = _get(
      this.props,
      'accountCandidatePledgesData.data.pledged',
      [],
    );

    return data.map((contribution: CandidatePledge) => ({
      key: contribution.id,
      name: `${contribution.contributor.first_name} ${
        contribution.contributor.last_name
      }`,
      state: contribution.contributor.state.code,
      date: moment(contribution.created_at).format('MM/DD/YYYY'),
      condition: contribution.condition,
      amount: contribution.amount / 100,
      timeLeft: `${moment(contribution.due_date).diff(
        moment(contribution.created_at),
        'months',
      )} m`,
      profileImage: contribution.contributor.profile_image,
      verifiedLevel: contribution.contributor.verified_level,
      verifiedDetail: contribution.contributor.verified_detail,
      type: 'pledged',
    }));
  }

  getContributedTableData() {
    const data = _get(
      this.props,
      'accountCandidateDirectContributionsData.data.contributed',
      [],
    );

    return data.map((contribution: CandidateDirectContribution) => ({
      key: contribution.id,
      name: `${contribution.contributor.first_name} ${
        contribution.contributor.last_name
      }`,
      state: contribution.contributor.state.code,
      date: moment(contribution.created_at).format('MM/DD/YYYY'),
      amount: contribution.amount / 100,
      profileImage: contribution.contributor.profile_image,
      verifiedLevel: contribution.contributor.verified_level,
      verifiedDetail: contribution.contributor.verified_detail,
      type: 'direct',
    }));
  }

  handleTableChange = (pagination: any, filters: any, sorter: any) => {
    // TODO: add sorting logic once the server supports it
    this.fetchContributions(pagination.current);
  };

  handleOpenContributionDetailsModal = data => {
    this.setState({
      contributionDetailsPayload: {
        id: data.key,
        type: data.type,
        contributor: {
          name: data.name,
          profileImage: data.profileImage,
          verifiedLevel: data.verifiedLevel,
          verifiedDetail: data.verifiedDetail,
        },
      },
    });
  };

  handleCloseDetailsModal = () =>
    this.setState({ contributionDetailsPayload: null });

  handleSideTabSelection = (
    tabKey: 'contributed' | 'committed' | 'pledged',
  ) => {
    this.setState(() => ({ currentTab: tabKey }));
  };
}

const mapStateToProps = (state, ownProps) => {
  return {
    ...account.mapStateToProps(state),
    ...accountDetails.mapStateToProps(state),
    ...accountCandidateCommitments.mapStateToProps(state),
    ...accountCandidatePledges.mapStateToProps(state),
    ...accountCandidateDirectContributions.mapStateToProps(state),
    ...ownProps,
  };
};

const mapDispatchToProps = dispatch => {
  return {
    ...accountCandidateCommitments.mapDispatchToProps(dispatch),
    ...accountCandidatePledges.mapDispatchToProps(dispatch),
    ...accountCandidateDirectContributions.mapDispatchToProps(dispatch),
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(DashboardContributionsPage);
