import {
  accountInboxMessageDetailsReset as reset,
  getAccountInboxMessageDetails as getInboxMessageDetails,
  getAccountInboxMessageDetailsNextPage as getInboxMessageDetailsNextPage,
} from './accountInboxMessageDetails.actions';
import type {
  GetUserInboxMessageDetailsPayload,
  GetUserInboxMessageDetailsResponse,
} from '../../../services/RoRUsersApiProvider';

export const mapStateToProps = state => {
  return {
    accountInboxMessageDetailsData: state.accountInboxMessageDetails.data,
    accountInboxMessageDetailsError: state.accountInboxMessageDetails.error,
    accountInboxMessageDetailsIsLoading:
      state.accountInboxMessageDetails.isLoading,
  };
};

export const mapDispatchToProps = dispatch => {
  return {
    getAccountInboxMessageDetails(payload: GetUserInboxMessageDetailsPayload) {
      dispatch(getInboxMessageDetails(payload));
    },
    accountInboxMessageDetailsReset() {
      dispatch(reset());
    },
    getAccountInboxMessageDetailsNextPage(
      payload: GetUserInboxMessageDetailsPayload,
    ) {
      dispatch(getInboxMessageDetailsNextPage(payload));
    },
  };
};

export type AccountInboxMessageDetailsMapStateToProps = {
  accountInboxMessageDetailsData: GetUserInboxMessageDetailsResponse,
  accountInboxMessageDetailsError: any,
  accountInboxMessageDetailsIsLoading: boolean,
};

export type AccountInboxMessageDetailsMapDispatchToProps = {
  getAccountInboxMessageDetails: (
    payload: GetUserInboxMessageDetailsPayload,
  ) => void,
  accountInboxMessageDetailsReset: () => void,
  getAccountInboxMessageDetailsNextPage: (
    payload: GetUserInboxMessageDetailsPayload,
  ) => void,
};

export default {
  mapStateToProps,
  mapDispatchToProps,
};
