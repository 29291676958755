import {
  POST_REMOVE_ERROR,
  POST_REMOVE_LOADING,
  POST_REMOVE_SUCCESS,
  POST_REMOVE_RESET,
} from './postRemove.constants';

const initialState = {
  isLoading: false,
  error: null,
  data: null,
};

export const reducer = (state = initialState, action) => {
  switch (action.type) {
    case POST_REMOVE_LOADING:
      return {
        ...state,
        isLoading: true,
        error: null,
        data: null,
      };
    case POST_REMOVE_SUCCESS:
      return {
        ...state,
        isLoading: false,
        error: null,
        data: action.payload,
      };
    case POST_REMOVE_ERROR:
      return {
        ...state,
        isLoading: false,
        error: action.payload,
        data: null,
      };
    case POST_REMOVE_RESET:
      return {
        ...state,
        isLoading: false,
        error: null,
        data: null,
      };
    default:
      return state;
  }
};
