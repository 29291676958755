// @flow
import { createAction } from 'redux-actions';
import {
  GROUP_LEAVE_ERROR,
  GROUP_LEAVE_LOADING,
  GROUP_LEAVE_RESET,
  GROUP_LEAVE_SUCCESS,
} from './groupLeave.constants';
import { instance as rorGroupsApiProvider } from '../../../services/RoRGroupsApiProvider';
import type { DeleteGroupLeavePayload } from '../../../services/RoRGroupsApiProvider';
import { getGroupDetails } from '../groupDetails/groupDetails.actions';

export const groupLeaveError = createAction(GROUP_LEAVE_ERROR);

export const groupLeaveLoading = createAction(GROUP_LEAVE_LOADING);

export const groupLeaveSuccess = createAction(GROUP_LEAVE_SUCCESS);

export const groupLeaveReset = createAction(GROUP_LEAVE_RESET);

export const leaveGroup = (payload: DeleteGroupLeavePayload) => {
  return async (dispatch: Function) => {
    try {
      dispatch(groupLeaveLoading());

      const response = await rorGroupsApiProvider.groupLeave(payload);

      dispatch(groupLeaveSuccess(response));
      dispatch(getGroupDetails(payload));
    } catch (error) {
      dispatch(groupLeaveError(error));
    }
  };
};
