// @flow
import React from 'react';
import Button from '../Button/Button';
import Link from '../Link/Link';
import Avatar from '../Avatar/Avatar';
import { Scrollbars } from 'react-custom-scrollbars';
import withDisplayDimensions from '../../hoc/withDisplayDimensions';
import type { WithDisplayDimensionsOutputProps } from '../../hoc/withDisplayDimensions';
import './RankingList.scss';

type Props = WithDisplayDimensionsOutputProps & {
  items: {
    name: string,
    image: string,
    href: string,
    key: number | string,
    badge: boolean,
    rank: number,
  }[],
  buttonTopText?: string,
  buttonTopLink?: string,
  onButtonTopClick?: Function,
  buttonBottomText?: string,
  onButtonBottomClick?: Function,
  title: string,
  displayBottomSeparator?: boolean,
};

const RankingList = (props: Props) => {
  return (
    <div className="ranking-list row">
      <div className="col-12">
        <div className="row align-items-center">
          <div className="col-8">
            <h4>{props.title}</h4>
          </div>
          {props.buttonTopText ? (
            <div className="col-4 text-right">
              {props.buttonTopLink ? (
                <Link
                  className="ranking-list__button-top"
                  href={props.buttonTopLink}>
                  {props.buttonTopText}
                </Link>
              ) : (
                <Button
                  buttonType="link"
                  type="button"
                  onClick={props.onButtonTopClick}>
                  {props.buttonTopText}
                </Button>
              )}
            </div>
          ) : null}
        </div>
        <div className="row">
          <div className="col-12">
            <Scrollbars universal style={{ height: 210 }}>
              <ul>
                {props.items.map((item, index) => (
                  <li className="ranking-list__item" key={item.key}>
                    {item.image ? (
                      <Link href={item.href}>
                        <Avatar
                          source={item.image}
                          isCandidate={item.badge}
                          type={getAvatarSize(props)}
                        />
                      </Link>
                    ) : null}
                    <div className="ranking-list__item-description">
                      <div className="ranking-list__item-description-top">
                        <span className="ranking-list__item-rank">
                          {item.rank}
                        </span>
                        {/*<Button
                            buttonType="outline"
                            type="button"
                            onClick={props.onButtonTopClick}
                            className="ranking-list__item-plus-button"
                          >
                            +
                          </Button>*/}
                      </div>
                      <Link
                        href={item.href}
                        className="link-violet ranking-list__item-name">
                        {item.name}
                      </Link>
                    </div>
                  </li>
                ))}
              </ul>
            </Scrollbars>
          </div>
        </div>
        {props.displayBottomSeparator || props.buttonBottomText ? (
          <div className="row">
            <div className="col-12 ranking-list__bottom-separator text-center">
              <hr />
              {props.buttonBottomText ? (
                <Button
                  type="button"
                  onClick={props.onButtonBottomClick}
                  buttonType="light">
                  {props.buttonBottomText}
                </Button>
              ) : null}
            </div>
          </div>
        ) : null}
      </div>
    </div>
  );
};

function getAvatarSize(props: Props) {
  if (props.isDisplayLG) return 'medium';
  if (props.isDisplayMD) return 'small';

  return 'x-small';
}

RankingList.defaultProps = {
  displayBottomSeparator: true,
};

export default withDisplayDimensions(RankingList);
