// @flow
import React from 'react';
import moment from 'moment';
import Avatar from '../Avatar/Avatar';
import Link from '../Link/Link';
import {
  URL_MY_ACCOUNT_MAIN_PAGE,
  URL_RACE_ACTIVITY_PAGE,
} from '../../config/urls';
import './DashboardTopMenu.scss';
import Button from '../Button/Button';
import type { UserDetails } from '../../services/RoRUsersApiProvider';
import type { AuthenticatedUser } from '../../services/RoRApiProvider';
import {
  isCandidate,
  isPac,
  isPoliticalParty,
} from '../../utilities/authorization';
import trim from 'lodash/trim';
import fullName from '../../utilities/fullName';

type Props = {
  user?: AuthenticatedUser,
  userDetails?: UserDetails,
  onNewPostButtonClick?: Function,
};

const DashboardTopMenu = ({
  user,
  userDetails,
  onNewPostButtonClick,
}: Props) => {
  if (!user || !userDetails) {
    return null;
  }

  return (
    <div className="container-fluid dashboard-top-menu">
      <div className="row fix-width">
        <div className="col-8">
          <div className="row pt-5">
            <div className="col-3 col-lg-2">
              <Avatar source={userDetails.profile_image} />
            </div>
            <div className="col-9 col-lg-10 pl-4 pt-1">
              <h1 className="name">{fullName(userDetails)}</h1>
              {isCandidate(user) && (
                <span>
                  <img
                    src="/images/icon_candidate_big.png"
                    alt=""
                    className="dashboard-top-menu__candidate-badge"
                  />
                </span>
              )}
              <br />
              {!(isPoliticalParty(user) || isPac(user)) ? (
                <span className="date">
                  Participate since{' '}
                  {moment(userDetails.created_at).format('MMM YYYY')}
                </span>
              ) : null}
              <span>
                <Link
                  href={URL_MY_ACCOUNT_MAIN_PAGE}
                  className="link-violet"
                  type="link">
                  EDIT PROFILE
                </Link>
              </span>
              {isCandidate(user) && userDetails.race && userDetails.race.id ? (
                <Link
                  type="dark"
                  href={URL_RACE_ACTIVITY_PAGE.replace(
                    ':raceId',
                    userDetails.race.id,
                  )}>
                  <h4>
                    Running for <strong>{userDetails.race.name}</strong>
                  </h4>
                </Link>
              ) : null}
            </div>
          </div>
        </div>
        <div className="col-4 pt-5 mt-1 pr-0">
          <div className="row">
            <div className="col text-right">
              <Button
                onClick={onNewPostButtonClick}
                buttonType="primary"
                type="button"
                className="mb-3">
                New Post
              </Button>
              <h4 className="social pt-1">
                {userDetails.followers_count}&nbsp;
                <span>FOLLOWERS </span>&nbsp;
                {userDetails.likes_count}&nbsp;
                <span>LIKES</span>
              </h4>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default DashboardTopMenu;
