import {
  ACCOUNT_PROFILE_UPDATE_SUCCESS,
  ACCOUNT_PROFILE_UPDATE_RESET,
  ACCOUNT_PROFILE_UPDATE_LOADING,
  ACCOUNT_PROFILE_UPDATE_ERROR,
} from './accountProfileUpdate.constants';
import makeDefaultServerCallReducer from '../../utilities/makeDefaultServerCallReducer';

const initialState = {
  isLoading: false,
  error: null,
  data: null,
};

export const reducer = makeDefaultServerCallReducer({
  loadingActions: [ACCOUNT_PROFILE_UPDATE_LOADING],
  successActions: [ACCOUNT_PROFILE_UPDATE_SUCCESS],
  errorActions: [ACCOUNT_PROFILE_UPDATE_ERROR],
  resetActions: [ACCOUNT_PROFILE_UPDATE_RESET],
  initialState,
});
