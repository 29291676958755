// @flow
import React from 'react';
import moment from 'moment';
import _get from 'lodash/get';
import { message } from 'antd';
import Link from '../../components/Link/Link';
import Loading from '../../components/Loading/Loading';
import { connect } from 'react-redux';
import listRaces from '../../redux/modules/listRaces/listRaces.containers';
import raceDetails from '../../redux/modules/raceDetails/raceDetails.containers';
import type {
  ListRacesMapStateToProps,
  ListRacesMapDispatchToProps,
} from '../../redux/modules/listRaces/listRaces.containers';
import type {
  RaceDetailsMapStateToProps,
  RaceDetailsMapDispatchToProps,
} from '../../redux/modules/raceDetails/raceDetails.containers';

import './RaceActivityRankingPage.scss';
import RaceList from '../../components/RaceList/RaceList';
import CardContender from '../../components/CardContender/CardContender';
import {
  URL_FEED_PAGE,
  URL_RACE_ACTIVITY_PAGE,
  URL_RACE_ACTIVITY_RANKING_PAGE,
} from '../../config/urls';
import BreadCrumb from '../../components/BreadCrumb/BreadCrumb';
import RaceHeader from '../../components/RaceHeader/RaceHeader';

type Props = ListRacesMapStateToProps &
  ListRacesMapDispatchToProps &
  RaceDetailsMapStateToProps &
  RaceDetailsMapDispatchToProps & {
    children: any,
    location: any,
    history: any,
  };

type State = {};

class RaceActivityRankingPage extends React.Component<Props, State> {
  componentWillMount() {
    const raceId = this.getRaceId();
    message.loading('Loading Race Activity...', 3);
    this.props.listRacesReset();
    this.props.raceDetailsReset();
    this.props.getDetailsForRace({ raceId });
    this.props.getListRaces();
  }

  render() {
    return (
      <div className="race-activity-ranking-page container-fluid content">
        {this.renderContent()}
      </div>
    );
  }

  renderContent() {
    return !this.isLoading() ? (
      <Loading loading={this.isLoading()} type="spinner">
        <div className="row fix-width-1290">
          <div className="col-12 pt-3 pt-md-4">
            <BreadCrumb links={this.getBreadCrumbLinks()} />
          </div>
        </div>

        <RaceHeader className="race-activity-ranking-page__header" />

        <div className="race-activity-ranking-page__content container-fluid info">
          <div className="row d-flex d-md-none secondary-menu px-4">
            <div className="col-12 text-left item py-2">
              <Link
                href="#"
                type="secondary"
                className="race-activity-ranking-page__mobile-link">
                <img
                  src="/images/icon_races.png"
                  alt=""
                  style={{ marginRight: '5px' }}
                />
                CURRENT RACES
              </Link>
            </div>
          </div>
          <div className="row fix-width">
            <div className="col-md-9 px-0 pr-md-5">
              <div className="race-activity-ranking-page__contenders fix-width">
                {this.props.raceDetailsData.candidates.contenders.map(
                  (contender, index) => (
                    <CardContender
                      className="race-activity-ranking-page__contenders-item"
                      {...contender}
                      key={index}
                    />
                  ),
                )}
              </div>
            </div>
            <div className="col-md-3 px-3 sidebar">
              <div className="row group-list d-none d-md-flex pt-5">
                <div className="col-12 px-0 sidebar-widget-list">
                  <RaceList
                    races={_get(this.props, 'listRacesData.data.races', [])}
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </Loading>
    ) : null;
  }

  renderHeader() {
    const race = this.props.raceDetailsData && this.props.raceDetailsData.race;

    return (
      <div className="race-activity-ranking-page__header row fix-width">
        <div className="col-12 col-md-8">
          <div className="row pt-3 pt-md-4 pt-lg-4">
            <div className="col-12 col-lg-10 pl-0 pl-md-3 pl-lg-2 pl-xl-0 pt-2">
              <h1 className="race-activity-ranking-page__race-name pr-sm-2">
                {race.name}
              </h1>
              <span className="d-none d-sm-block pb-3">
                Election due {moment(race.due_date).format('MMM Do YYYY')}.
              </span>
            </div>
          </div>
        </div>
        <div className="col-12 col-md-4 pt-1 pt-sm-4 pt-md-4 mt-4 pr-0">
          <div className="row">
            <div className="race-activity-ranking-page__header-counter col text-left text-md-right pl-0 pl-md-3">
              <span>
                <strong>{race.candidates_count}</strong> Candidates
              </span>
              <span>
                <strong>{race.followers_count}</strong> Followers
              </span>
            </div>
          </div>
        </div>
      </div>
    );
  }

  getRaceId() {
    return parseInt(this.props.match.params.raceId);
  }

  getBreadCrumbLinks() {
    const raceId = this.getRaceId();
    return [
      {
        href: URL_FEED_PAGE,
        content: 'FEED',
        active: false,
        title: 'Your feed page',
      },
      {
        href: URL_RACE_ACTIVITY_PAGE.replace(':raceId', raceId),
        content: 'RACE ACTIVITY',
        active: false,
        title: `${this.props.raceDetailsData.race.name}'s Race`,
      },
      {
        href: URL_RACE_ACTIVITY_RANKING_PAGE.replace(':raceId', raceId),
        content: 'CANDIDATES RANKING',
        active: true,
        title: `${this.props.raceDetailsData.race.name}'s Candidates Ranking`,
      },
    ];
  }

  isLoading() {
    return !(this.props.raceDetailsData && this.props.listRacesData);
  }
}

const mapStateToProps = (state, ownProps) => {
  return {
    ...raceDetails.mapStateToProps(state),
    ...listRaces.mapStateToProps(state),
    ...ownProps,
  };
};

const mapDispatchToProps = dispatch => {
  return {
    ...listRaces.mapDispatchToProps(dispatch),
    ...raceDetails.mapDispatchToProps(dispatch),
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(RaceActivityRankingPage);
