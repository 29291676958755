import {
  ACCOUNT_DETAILS_SUCCESS,
  ACCOUNT_DETAILS_RESET,
  ACCOUNT_DETAILS_LOADING,
  ACCOUNT_DETAILS_ERROR,
} from './accountDetails.constants';
import makeDefaultServerCallReducer from '../../utilities/makeDefaultServerCallReducer';

const initialState = {
  isLoading: false,
  error: null,
  data: null,
};

export const reducer = makeDefaultServerCallReducer({
  loadingActions: [ACCOUNT_DETAILS_LOADING],
  successActions: [ACCOUNT_DETAILS_SUCCESS],
  errorActions: [ACCOUNT_DETAILS_ERROR],
  resetActions: [ACCOUNT_DETAILS_RESET],
  initialState,
});
