// @flow
import React from 'react';
import './ContributionRulesPage.scss';

const ContributionRulesPage = () => (
  <div className="contribution-rules-page">
    <section className="content">
      <div className="container-fluid fix-width">
        <div className="row justify-content-md-center">
          <div className="col-lg-8 col-md-9 pr-8-md">
            <h1>Contribution Rules</h1>
            <span className="contribution-rules-page__subtitle-wrapper">
              <h5 className="contribution-rules-page__subtitle">
                By contributing, I affirm:
              </h5>
            </span>
            <ol>
              <li>
                <p>
                  I am a U.S. citizen or lawfully admitted permanent resident
                  (i.e., green card holder).
                </p>
              </li>
              <li>
                <p>
                  This contribution is made from my own funds, and funds are not
                  being provided to me by another person or entity for the
                  purpose of making this contribution.
                </p>
              </li>
              <li>
                <p>
                  I am making this contribution with my own personal credit card
                  or personal bank account and not with a corporate or business
                  credit card or a card issued to another person.
                </p>
              </li>
              <li>
                <p>I am at least eighteen years old or;</p>
              </li>
              <li>
                <p>
                  If I am under eighteen years of age: The decision to
                  contribute is made knowingly and voluntarily by myself; The
                  funds, goods or services contributed are owned or controlled
                  by the minor, proceeds from a trust for which I am a
                  beneficiary or funds withdrawn by the me from a financial
                  account opened and maintained in my name; and, The
                  contribution is not made using funds given to me as a gift for
                  the purpose of making the contribution, and is not in any way
                  controlled by another individual.
                </p>
              </li>
              <li>
                <p>I am not a federal contractor.</p>
              </li>
              <li>
                <p>
                  The name on record at Prytany is your legal name and will be
                  used for reporting this contribution to the FEC. If this name
                  is not your legal name please contact Prytany at
                  Contact@prytany.com. Email subject should read: Legal name.
                </p>
              </li>
            </ol>
          </div>
        </div>
      </div>
    </section>
  </div>
);

export default ContributionRulesPage;
