// @flow
import React from 'react';
import { connect } from 'react-redux';
import { message } from 'antd';
import _get from 'lodash/get';
import _filter from 'lodash/filter';
import Select from '../../components/Select/Select';
import Button from '../../components/Button/Button';
import LoadingSpinner from '../../components/LoadingSpinner/LoadingSpinner';
import Feed from '../../components/Feed/Feed';
import ModalNewGroup from '../../components/ModalNewGroup/ModalNewGroup';
import ModalNewPost from '../../components/ModalNewPost/ModalNewPost';
import ListWithAvatars from '../../components/ListWithAvatars/ListWithAvatars';
import {
  URL_GROUP_PAGE,
  URL_USER_PUBLIC_PROFILE,
  URL_RACE_ACTIVITY_PAGE,
  URL_SEARCH_PAGE,
} from '../../config/urls';
import account from '../../redux/modules/account/account.containers';
import accountDetails from '../../redux/modules/accountDetails/accountDetails.containers';
import accountGroups from '../../redux/modules/accountGroups/accountGroups.containers';
import accountFeed from '../../redux/modules/accountFeed/accountFeed.containers';
import accountFollowings from '../../redux/modules/accountFollowings/accountFollowings.containers';
import userProfileFollowers from '../../redux/modules/userProfileFollowers/userProfileFollowers.containers';
import userDetails from '../../redux/modules/userDetails/userDetails.containers';
import listRoles from '../../redux/modules/listRoles/listRoles.containers';
import withDisplayDimensions from '../../hoc/withDisplayDimensions';
import SingleRowMenu from '../../components/SingleRowMenu/SingleRowMenu';
import type { WithDisplayDimensionsOutputProps } from '../../hoc/withDisplayDimensions';
import type {
  AccountDetailsMapDispatchToProps,
  AccountDetailsMapStateToProps,
} from '../../redux/modules/accountDetails/accountDetails.containers';
import accountRaces from '../../redux/modules/accountRaces/accountRaces.containers';
import type {
  AccountRacesMapDispatchToProps,
  AccountRacesMapStateToProps,
} from '../../redux/modules/accountRaces/accountRaces.containers';
import type {
  AccountFeedMapDispatchToProps,
  AccountFeedMapStateToProps,
} from '../../redux/modules/accountFeed/accountFeed.containers';
import type {
  UserDetailsMapDispatchToProps,
  UserDetailsMapStateToProps,
} from '../../redux/modules/userDetails/userDetails.containers';
import type {
  UserProfileFollowersMapStateToProps,
  UserProfileFollowersMapDispatchToProps,
} from '../../redux/modules/userProfileFollowers/userProfileFollowers.containers';
import type { FeedItem, UserDetails } from '../../services/RoRUsersApiProvider';
import './FeedPage.scss';
import type { PostRemoveMapStateToProps } from '../../redux/modules/postRemove/postRemove.containers';
import type {
  PostAddMapStateToProps,
  PostAddMapDispatchToProps,
} from '../../redux/modules/postAdd/postAdd.containers';
import type {
  ListRolesMapDispatchToProps,
  ListRolesMapStateToProps,
} from '../../redux/modules/listRoles/listRoles.containers';

import postAdd from '../../redux/modules/postAdd/postAdd.containers';
import postRemove from '../../redux/modules/postRemove/postRemove.containers';
import SearchType from '../../constants/SearchType';
import type { AccountMapStateToProps } from '../../redux/modules/account/account.containers';
import capitalize from 'lodash/capitalize';
import pluralize from 'pluralize';
import { allowedGroupType, isCitizen } from '../../utilities/authorization';
import upperCase from 'lodash/upperCase';
import ModalContribute from '../../components/ModalContribute/ModalContribute';
import ContributionType from '../../constants/ContributionType';
import type { ContributionTypeType } from '../../components/ContributeOptions/ContributeOptions';
import isPrytanyVerified from '../../utilities/isPrytanyVerified';

// TODO: update prop types once types are exported from redux modules
type Props = WithDisplayDimensionsOutputProps &
  AccountMapStateToProps &
  AccountDetailsMapStateToProps &
  AccountDetailsMapDispatchToProps &
  ListRolesMapDispatchToProps &
  ListRolesMapStateToProps &
  AccountRacesMapStateToProps &
  AccountRacesMapDispatchToProps &
  AccountFeedMapStateToProps &
  AccountFeedMapDispatchToProps &
  UserDetailsMapDispatchToProps &
  UserDetailsMapStateToProps &
  PostAddMapStateToProps &
  PostAddMapDispatchToProps &
  PostRemoveMapStateToProps &
  UserProfileFollowersMapStateToProps &
  UserProfileFollowersMapDispatchToProps & {
    match: any,
    location: any,
    history: any,
    accountGroupsData: any,
    accountGroupsError: any,
    accountGroupsIsLoading: boolean,
    accountFollowingsData: any,
    accountFollowingsError: any,
    accountFollowingsIsLoading: boolean,
    getAccountGroups: Function,
    getAccountFollowings: Function,
  };

type State = {
  modalOpened: 'newGroup' | 'contribute' | 'newPost' | null,
  filterByUserType: 'candidate' | 'citizen' | '',
  filterByContentType:
    | 'new_post'
    | 'group_join'
    | 'race'
    | 'contribution'
    | 'google_alert'
    | '',
  mobileSubSection: 'feed' | 'groups' | 'following' | 'races',
  displayFilters: boolean,
  feedItemButtonClickedIndex: number | null,
  contributionType: ContributionTypeType,
};

const ACCOUNT_FEED_PAGE_SIZE = 10;

class FeedPage extends React.Component<Props, State> {
  constructor(props: Props) {
    super(props);

    this.state = {
      //TODO: add logic to detect first time users
      modalOpened: null,
      filterByUserType: '',
      filterByContentType: '',
      mobileSubSection: 'feed',
      displayFilters: !this.props.isMobile,
      feedItemButtonClickedIndex: null,
      contributionType: null,
    };

    this.props.accountFeedReset();
    this.props.userDetailsReset();
    this.props.accountRacesReset();
    this.props.profileFollowersReset();
    this.props.listRolesReset();
  }

  componentDidMount() {
    message.loading('Updating feed...', 3);
    this.props.getAccountFeed({
      limit: ACCOUNT_FEED_PAGE_SIZE,
      userId: this.props.accountData.user.id,
    });
    this.props.getAccountGroups();
    this.props.getAccountFollowings();
    this.props.getAccountRaces({ page: 1, limit: 10 });
    this.props.getUserProfileFollowers({ id: this.props.accountData.user.id });
    this.props.getListRoles();
  }

  componentDidUpdate(prevProps: Props, prevState: State) {
    if (this.props.isMobile !== prevProps.isMobile) {
      this.setState(() => ({
        mobileSubSection: 'feed',
        displayFilters: !this.props.isMobile,
      }));
    }

    if (!prevProps.postAddError && this.props.postAddError) {
      message.error(
        this.props.postAddError.localMessage ||
          'An error has occurred while deleting the post.',
      );
    }

    if (!prevProps.postAddData && this.props.postAddData) {
      this.props.getAccountFeed({
        limit: ACCOUNT_FEED_PAGE_SIZE,
        userId: this.props.accountData.user.id,
      });
    }

    if (prevState.filterByContentType !== this.state.filterByContentType) {
      this.props.getAccountFeed({
        userId: this.props.accountData.user.id,
        limit: ACCOUNT_FEED_PAGE_SIZE,
        feed_type: this.state.filterByContentType,
      });
    }

    if (prevState.filterByUserType !== this.state.filterByUserType) {
      this.props.getAccountFeed({
        userId: this.props.accountData.user.id,
        limit: ACCOUNT_FEED_PAGE_SIZE,
        role: this.state.filterByUserType,
      });
    }

    if (!prevProps.postRemoveError && this.props.postRemoveError) {
      message.error(
        this.props.postRemoveError.localMessage ||
          'An error has occurred while deleting the post.',
      );
    }

    if (!prevProps.postRemoveData && this.props.postRemoveData) {
      message.success(
        this.props.postRemoveData.localMessage || 'Post successfully deleted.',
      );
      this.props.getAccountFeed({
        userId: this.props.accountData.user.id,
        limit: ACCOUNT_FEED_PAGE_SIZE,
      });
    }

    if (!this.state.displayFilters && prevState.displayFilters) {
      this.handleResetFilters();
    }

    if (
      this.props.userDetailsData &&
      !prevProps.userDetailsData &&
      this.state.feedItemButtonClickedIndex !== null
    ) {
      this.openContributionModal();
    }
  }

  render() {
    const userDetails: UserDetails = _get(
      this.props,
      'userDetailsData.user',
      null,
    );
    return (
      <div className="feed-page">
        <div className="container-fluid px-0">
          {this.renderMobileMenu()}
          <section className="feed-page__content row fix-width no-gutters pt-md-5 px-3 px-xl-0">
            {this.renderFeedSection()}
            {this.renderRightColumn()}
          </section>
        </div>
        <ModalNewGroup
          preselectedUsers={[]}
          isOpen={this.state.modalOpened === 'newGroup'}
          onCloseRequest={this.handleCloseAllModals}
          onSuccess={this.handleNewGroupSuccess}
          onError={this.handleNewGroupError}
        />
        {this.state.modalOpened === 'contribute' && (
          <ModalContribute
            isOpen={true}
            onCloseRequest={this.handleCloseAllModals}
            contributionType={this.state.contributionType}
            userDetails={userDetails}
            onGoBackRequest={this.handleCloseAllModals}
            onSuccess={this.handleCloseAllModals}
          />
        )}
        <ModalNewPost
          isOpen={this.state.modalOpened === 'newPost'}
          groupId={
            this.props.groupDetailsData && this.props.groupDetailsData.group.id
          }
          groupName={
            this.props.groupDetailsData &&
            this.props.groupDetailsData.group.name
          }
          onCloseRequest={this.handleCloseAllModals}
          onSuccess={this.handleNewPostSuccess}
          onError={this.handleNewPostError}
        />
      </div>
    );
  }

  renderNewPostButton() {
    return !this.props.isMobile || this.state.mobileSubSection === 'feed' ? (
      <div className="row fix-width no-gutters mb-0 pt-2 pb-2 pt-md-0 pb-md-4">
        <div className="col-6 offset-3 offset-md-2 col-md-8">
          <Button
            onClick={this.openNewPostModal}
            buttonType="primary"
            type="button"
            className="mb-0 group-posts-page__new-post-button">
            New Post
          </Button>
        </div>
      </div>
    ) : null;
  }

  renderMobileMenu() {
    return this.props.isMobile ? (
      <div className="row fix-width no-gutters">
        <div className="col-12">
          {this.props.isMobile && this.state.mobileSubSection !== 'feed' ? (
            <Button
              buttonType="link"
              type="button"
              onClick={this.handleBackToMobileFeed}>
              &larr; Back
            </Button>
          ) : null}
        </div>
        <div className="col-12">
          <SingleRowMenu
            content={[
              {
                key: 'groups',
                image: '/images/icon_groups-1.png',
                text: 'GROUPS',
                onClick: this.handleMobileMenuClick,
              },
              {
                key: 'following',
                image: '/images/icon_stars.png',
                text: 'FOLLOWING',
                onClick: this.handleMobileMenuClick,
              },
              {
                key: 'followers',
                image: '/images/icon_stars.png',
                text: 'FOLLOWERS',
                onClick: this.handleMobileMenuClick,
              },
              {
                key: 'races',
                image: '/images/icon_stars.png',
                text: 'RACES',
                onClick: this.handleMobileMenuClick,
              },
            ]}
          />
        </div>
        <div className="col-12 d-flex justify-content-end align-items-center">
          {this.state.mobileSubSection === 'feed' ? (
            <Button
              className="feed-page__mobile-filter-button"
              buttonType="link"
              type="button"
              onClick={this.handleDisplayFiltersToggle}>
              <img src="/images/icon_filter.png" alt="" />
              <span>Filter</span>
            </Button>
          ) : null}
        </div>
      </div>
    ) : null;
  }

  // TODO: add load more button
  renderFeedSection() {
    return !this.props.isMobile || this.state.mobileSubSection === 'feed' ? (
      <div className="feed-list col-12 col-md-8 order-1 order-md-0">
        {this.getFeedItems().length > 0
          ? this.renderFeed()
          : this.renderEmptyFeed()}
        <div className="d-flex justify-content-center">
          <LoadingSpinner
            type="dark"
            visible={this.props.accountFeedIsLoading}
          />
        </div>
      </div>
    ) : null;
  }

  renderFeed() {
    return (
      <Feed
        feedItems={this.getFeedItems()}
        onScrollEndReached={this.handleFeedScrollEndReached}
        onFeedItemButtonClick={(feedItem, index) =>
          this.handleFeedItemButtonClick(feedItem, index)
        }
        activeFeedItemIndex={this.state.feedItemButtonClickedIndex}
        userId={_get(this.props, 'accountData.user.id')}
        className="pr-md-4 feed-page__content-items"
      />
    );
  }

  renderEmptyFeed() {
    return !this.props.accountFeedIsLoading ? (
      <React.Fragment>
        <div className="row">
          <h6 className="col-12 text-center mb-5">
            Your feed is empty!
            <br />
            <span className="feed-page__empty-feed-subtitle">
              Start following news here:
            </span>
          </h6>
        </div>
        <div className="row feed-page__empty-feed pl-3">
          <div className="col-12 col-md-4 px-lg-3 pb-3 pb-md-0 pl-md-0">
            <Button
              buttonType="link"
              type="button"
              className="feed-page__empty-feed-action"
              onClick={() =>
                this.handleNavigateToSearchClick(SearchType.GROUPS)
              }>
              <img src="/images/icon_groups.png" className="img-fluid" alt="" />
              <h6 className="feed-page__empty-feed-action-title">
                Explore Groups
              </h6>
            </Button>
          </div>
          <div className="col-12 col-md-4 px-lg-3 pb-3 pb-md-0 pl-md-0">
            <Button
              buttonType="link"
              type="button"
              className="feed-page__empty-feed-action"
              onClick={() =>
                this.handleNavigateToSearchClick(SearchType.RACES)
              }>
              <img src="/images/icon_races.svg" className="img-fluid" alt="" />
              <h6 className="feed-page__empty-feed-action-title">
                Current Races
              </h6>
            </Button>
          </div>
          <div className="col-12 col-md-4 px-lg-3 pb-3 pb-md-0 pl-md-0">
            <Button
              buttonType="link"
              type="button"
              className="feed-page__empty-feed-action"
              onClick={this.openNewPostModal}>
              <img
                src="/images/icon_new-post.svg"
                className="img-fluid"
                alt=""
              />
              <h6 className="feed-page__empty-feed-action-title">
                Create Post
              </h6>
            </Button>
          </div>
        </div>
      </React.Fragment>
    ) : null;
  }

  renderRightColumn() {
    return (
      <div className="col-12 col-md-4 px-4 px-md-3 px-lg-4 px-xl-5">
        {this.renderNewPostButton()}
        {this.renderFilters()}
        {this.renderGroups()}
        {this.renderFollowedList()}
        {this.renderFollowingRaces()}
      </div>
    );
  }

  renderFilters() {
    return this.state.displayFilters ? (
      <div className="row">
        <div className="col-12">
          <div className="row align-items-center">
            <div className="col-6">
              <span className="feed-page__right-column-label">Filter Feed</span>
            </div>
            <div className="col-6 d-flex justify-content-end">
              <Button
                buttonType="link"
                className="fw-600"
                type="button"
                onClick={this.handleResetFilters}>
                RESET FILTERS
              </Button>
            </div>
          </div>
          <Select
            className="pt-3 pb-3"
            label="Type of user"
            size="small"
            placeholder="All"
            name="filterByUserType"
            useFormik={false}
            disabled={this.props.listRolesIsLoading}
            value={this.state.filterByUserType}
            onChange={this.handleSelectFilterByUserChange}
            options={this.getSelectFilterByUserOptions()}
            error=""
          />
          <Select
            className="pt-3 pb-3"
            label="Type of content"
            size="small"
            placeholder="All"
            name="filterByContentType"
            useFormik={false}
            value={this.state.filterByContentType}
            onChange={this.handleSelectFilterByContentChange}
            options={this.getSelectFilterByContentOptions()}
            error=""
          />
          <hr className="mt-4" />
        </div>
      </div>
    ) : null;
  }

  renderGroups() {
    const { user } = this.props.accountData || {};
    const { groups = [] } = this.props.accountGroupsData || {};

    return !this.props.isMobile || this.state.mobileSubSection === 'groups' ? (
      <ListWithAvatars
        title={capitalize(pluralize(allowedGroupType(user)))}
        emptyListMessage="You are not part of any group yet."
        buttonTopText={`CREATE NEW ${upperCase(allowedGroupType(user))}`}
        onButtonTopClick={this.openNewGroupModal}
        items={groups.map(group => ({
          id: group.id,
          name: group.name,
          href: URL_GROUP_PAGE.replace(':groupId', group.id),
          image: group.group_image,
          badge: false,
        }))}
      />
    ) : null;
  }

  renderFollowedList() {
    const followed =
      (this.props.accountFollowingsData &&
        this.props.accountFollowingsData.followings) ||
      [];

    return !this.props.isMobile ||
      this.state.mobileSubSection === 'following' ? (
      <ListWithAvatars
        title="Following"
        emptyListMessage="You don't follow any users yet"
        items={followed.map(user => ({
          id: user.id,
          name: `${user.first_name} ${user.last_name}`.trim(),
          href: URL_USER_PUBLIC_PROFILE.replace(':id', user.id),
          image: user.profile_image,
          badge: user.role.code === 'candidate',
          verifiedLevel: user.verified_level,
          verifiedDetail: user.verified_detail,
        }))}
      />
    ) : null;
  }

  renderFollowingRaces() {
    const races =
      (this.props.accountRacesData && this.props.accountRacesData.data.races) ||
      [];

    return !this.props.isMobile || this.state.mobileSubSection === 'races' ? (
      <ListWithAvatars
        title="Races"
        emptyListMessage="You don't follow any races yet"
        items={races.map(race => ({
          id: race.id,
          name: race.name,
          href: URL_RACE_ACTIVITY_PAGE.replace(':raceId', race.id),
          image: '/images/icon_group.png',
        }))}
      />
    ) : null;
  }

  getFeedItems() {
    //Temporary fix for backend issue in which feeds are returned without a details key. Remove filter when it is fixed.
    const allFeedItems = _get(
      this.props,
      'accountFeedData.data.feeds',
      [],
    ).filter(feedItem => {
      return !!feedItem.details;
    });

    return allFeedItems;
  }

  getSelectFilterByUserOptions() {
    // TODO: confirm allowed options
    let options = [];
    let roles = [];
    if (!this.props.listRolesIsLoading) {
      roles = _filter(
        _get(this.props, 'listRolesData.roles'),
        role => role.code === 'citizen' || role.code === 'candidate',
      );
    }
    roles.map(role => {
      options.push({
        label: role.name,
        value: role.id,
      });
    });

    return options;
  }

  getSelectFilterByContentOptions() {
    // TODO: confirm allowed options
    return [
      { label: 'Posts', value: 'new_post' },
      { label: 'Contributions', value: 'contribution' },
      { label: 'Groups', value: 'group_join' },
      { label: 'Races', value: 'race' },
      { label: 'News Alerts', value: 'google_alert' },
    ];
  }

  handleFeedScrollEndReached = () => {
    let params: {
      page?: number,
      limit?: number,
      feed_type?:
        | 'new_post'
        | 'contribution'
        | 'group_join'
        | 'race'
        | 'google_alert',
      role?: 'citizen' | 'candidate',
    } = { limit: ACCOUNT_FEED_PAGE_SIZE };

    if (this.state.filterByContentType) {
      params.feed_type = this.state.filterByContentType;
    }

    if (this.state.filterByUserType) {
      params.role = this.state.filterByUserType;
    }

    if (!this.props.accountFeedIsLoading) {
      this.props.getAccountFeedNextPage(params);
    }
  };

  handleMobileMenuClick = (data: { item: any, index: number }) => {
    this.setState((prevState: State) => ({
      mobileSubSection: data.item.key,
      displayFilters: prevState.displayFilters && data.item.key === 'feed',
    }));
  };

  openNewPostModal = () => {
    this.setState(() => ({ modalOpened: 'newPost' }));
  };

  handleNewPostSuccess = (newPostData: any) => {
    this.props.getAccountFeed();
    this.handleCloseAllModals();
    message.success(
      <SuccessMessage
        history={this.props.history}
        location={this.props.location}
        groupId={this.props.match.params.groupId}
      />,
      5,
    );
  };

  handleNewPostError = (newPostError: any) => {
    message.error(
      newPostError.localMessage ||
        'An error has occurred and the post was not created.',
    );
  };

  handleDisplayFiltersToggle = () => {
    this.setState((prevState: State) => ({
      displayFilters: !prevState.displayFilters,
    }));
  };

  handleBackToMobileFeed = () => {
    this.setState(() => ({ mobileSubSection: 'feed' }));
  };

  handleSelectFilterByUserChange = ({ value }) => {
    this.setState(() => ({ filterByUserType: value }));
  };

  handleSelectFilterByContentChange = ({ value }) => {
    this.setState(() => ({ filterByContentType: value }));
  };

  handleResetFilters = () => {
    this.setState(() => ({ filterByUserType: '', filterByContentType: '' }));
  };

  handleFeedItemButtonClick = (feedItem: FeedItem, index: number) => {
    let details;

    this.props.userDetailsReset();

    this.setState(
      () => ({ feedItemButtonClickedIndex: index }),
      () => {
        if (feedItem.type === 'commitment') {
          details = feedItem.details.committed_donation;
        }

        if (feedItem.type === 'pledge') {
          details = feedItem.details.pledge;
        }

        if (feedItem.type === 'contribution') {
          details = feedItem.details.donation;
        }

        this.props.getDetailsForUser({ userId: details.candidate.id });
      },
    );
  };

  openNewGroupModal = () => {
    this.setState(() => ({ modalOpened: 'newGroup' }));
  };

  openContributionModal() {
    const feedItem: FeedItem | null =
      this.getFeedItems().find(
        (item, index) => index === this.state.feedItemButtonClickedIndex,
      ) || null;

    if (feedItem) {
      const { DIRECT, COMMITTED, PLEDGE } = ContributionType;
      const contributionType = {
        contribution: DIRECT,
        commitment: COMMITTED,
        pledge: PLEDGE,
      }[(feedItem.type: any)];

      //$FlowFixMe
      this.setState(() => ({
        modalOpened: 'contribute',
        contributionType: contributionType,
      }));
    }
  }

  handleNewGroupSuccess = (newGroupData: any) => {
    this.handleCloseAllModals();
    message.success('A new group was created.', 5);
  };

  handleCloseAllModals = () => {
    this.setState(() => ({
      modalOpened: null,
      feedItemButtonClickedIndex: null,
      contributionType: null,
    }));
  };

  handleNewGroupError = (newGroupError: any) => {
    message.error(
      newGroupError.localMessage ||
        'An error has occurred and the group was not created.',
    );
  };

  handleNavigateToSearchClick = (searchType: $Values<typeof SearchType>) => {
    this.props.history.push(`${URL_SEARCH_PAGE}?searchType=${searchType}`);
  };
}

const SuccessMessage = ({
  history,
  location,
  groupId,
}: {
  history: any,
  location: any,
  groupId: number,
}) => {
  const groupPostsUrl = '/feed';

  function handleClick() {
    history.push(groupPostsUrl);
  }

  return (
    <React.Fragment>
      <span>A new post was created. </span>
      {location.pathname !== groupPostsUrl && (
        <Button
          buttonType="link"
          onClick={handleClick}
          size="small"
          className="group-page__message-link">
          View post.
        </Button>
      )}
    </React.Fragment>
  );
};

const mapStateToProps = (state, ownProps) => {
  return {
    ...account.mapStateToProps(state),
    ...accountDetails.mapDispatchToProps(state),
    ...accountGroups.mapStateToProps(state),
    ...accountFeed.mapStateToProps(state),
    ...accountFollowings.mapStateToProps(state),
    ...accountRaces.mapStateToProps(state),
    ...postAdd.mapStateToProps(state),
    ...postRemove.mapStateToProps(state),
    ...userDetails.mapStateToProps(state),
    ...userProfileFollowers.mapStateToProps(state),
    ...listRoles.mapStateToProps(state),
    ...ownProps,
  };
};

const mapDispatchToProps = dispatch => {
  return {
    ...accountDetails.mapDispatchToProps(dispatch),
    ...accountGroups.mapDispatchToProps(dispatch),
    ...accountFeed.mapDispatchToProps(dispatch),
    ...accountFollowings.mapDispatchToProps(dispatch),
    ...accountRaces.mapDispatchToProps(dispatch),
    ...postAdd.mapDispatchToProps(dispatch),
    ...userDetails.mapDispatchToProps(dispatch),
    ...userProfileFollowers.mapDispatchToProps(dispatch),
    ...listRoles.mapDispatchToProps(dispatch),
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(withDisplayDimensions(FeedPage));
