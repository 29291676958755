// @flow
import makeDefaultServerCallReducer from '../../utilities/makeDefaultServerCallReducer';
import {
  POST_COMMENTS_ERROR,
  POST_COMMENTS_LOADING,
  POST_COMMENTS_SUCCESS,
  POST_COMMENTS_RESET,
  POST_COMMENTS_SET_AS_FIRST,
} from './postComments.constants';
import _cloneDeep from 'lodash/cloneDeep';

const setPostCommentAsFirst = (state, action) => {
  if (state.data && state.data.post_comments) {
    const data = _cloneDeep(state.data);

    data.post_comments.unshift(action.payload);

    return {
      ...state,
      data,
    };
  }

  return {
    ...state,
    data: {
      post_comments: [action.payload],
    },
  };
};

const initialState = {
  isLoading: false,
  error: null,
  data: null,
};

export const reducer = makeDefaultServerCallReducer({
  loadingActions: [POST_COMMENTS_LOADING],
  successActions: [POST_COMMENTS_SUCCESS],
  errorActions: [POST_COMMENTS_ERROR],
  resetActions: [POST_COMMENTS_RESET],
  otherActions: [
    { name: POST_COMMENTS_SET_AS_FIRST, callback: setPostCommentAsFirst },
  ],
  initialState,
});
