import {
  ACCOUNT_PAYMENT_METHODS_LIST_SUCCESS,
  ACCOUNT_PAYMENT_METHODS_LIST_LOADING,
  ACCOUNT_PAYMENT_METHODS_LIST_ERROR,
  ACCOUNT_PAYMENT_METHODS_LIST_RESET,
} from './accountPaymentMethods.constants';

const initialState = {
  isLoading: false,
  error: null,
  data: null,
};

export const reducer = (state = initialState, action) => {
  switch (action.type) {
    case ACCOUNT_PAYMENT_METHODS_LIST_LOADING:
      return {
        ...state,
        isLoading: true,
        error: null,
      };
    case ACCOUNT_PAYMENT_METHODS_LIST_SUCCESS:
      return {
        ...state,
        isLoading: false,
        error: null,
        data: action.payload,
      };
    case ACCOUNT_PAYMENT_METHODS_LIST_ERROR:
      return {
        ...state,
        isLoading: false,
        error: action.payload,
        data: null,
      };
    case ACCOUNT_PAYMENT_METHODS_LIST_RESET:
      return {
        ...state,
        isLoading: false,
        error: null,
        data: null,
      };
    default:
      return state;
  }
};
