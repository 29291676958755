// @flow
import React from 'react';
import { connect } from 'react-redux';
import classNames from 'classnames';
import _get from 'lodash/get';
import _throttle from 'lodash/throttle';
import Avatar from '../Avatar/Avatar';
import Button from '../Button/Button';
import Input from '../Input/Input';
import Table from '../Table/Table';
import Modal from '../Modal/Modal';
import accountGroups from '../../redux/modules/accountGroups/accountGroups.containers';
import groupSendInvitation from '../../redux/modules/groupSendInvitation/groupSendInvitation.containers';
import type {
  GroupSendInvitationMapDispatchToProps,
  GroupSendInvitationMapStateToProps,
} from '../../redux/modules/groupSendInvitation/groupSendInvitation.containers';
import './ModalInvitePerson.scss';
import type {
  UserListMapDispatchToProps,
  UserListMapStateToProps,
} from '../../redux/modules/userList/userList.containers';

type Props = UserListMapDispatchToProps &
  UserListMapStateToProps &
  GroupSendInvitationMapDispatchToProps &
  GroupSendInvitationMapStateToProps & {
    isOpen: boolean,
    onAfterOpen?: Function,
    onCloseRequest?: Function,
    className?: string,
    onSuccess: Function,
    onError: (error: string) => void,
  };

type State = {
  searchValue: string,
  tableSelectedRows: any[],
  tableSelectedRowKeys: number[],
};

const GROUP_PAGE_SIZE = 6;

class ModalInvitePerson extends React.Component<Props, State> {
  constructor(props: Props) {
    super(props);

    this.state = {
      searchValue: '',
      tableSelectedRows: [],
      tableSelectedRowKeys: [],
    };
  }

  componentWillMount() {
    this.props.accountGroupsReset();
    this.props.groupSendInvitationReset();
  }

  componentDidMount() {
    this.fetchGroups();
  }

  componentDidUpdate(prevProps: Props) {
    if (!prevProps.isOpen && this.props.isOpen) {
      this.setState(
        () => ({ searchValue: '' }),
        () => {
          this.fetchGroups();
          this.props.groupSendInvitationReset();
        },
      );
    }

    if (
      !prevProps.groupSendInvitationData &&
      this.props.groupSendInvitationData
    ) {
      this.props.onSuccess();
      this.props.onCloseRequest();
    }

    if (
      !prevProps.groupSendInvitationError &&
      this.props.groupSendInvitationError
    ) {
      this.props.onError(
        this.props.groupSendInvitationError.localMessage ||
          'An error has occurred and the invitations were not send.',
      );
    }
  }

  render() {
    const { className, onSuccess, onError, ...rest } = this.props;

    return (
      <Modal
        contentLabel="Invite people"
        className={this.getClassName()}
        noPaddingContent
        size="normal"
        {...rest}>
        {this.props.isOpen ? this.renderContent() : null}
      </Modal>
    );
  }

  renderContent() {
    return (
      <React.Fragment>
        <h2 className="modal-component__title text-center mt-3 mb-3" />
        <div className="row pb-3 pt-3 fix-width modal-invite-people__body">
          <Input
            className="col-8 offset-2 px-0 mb-0"
            size="small"
            label=""
            placeholder=""
            name="search"
            type="text"
            icon="/images/icon_lens.png"
            value={this.state.searchValue}
            useFormik={false}
            onChange={_throttle(this.handleSearchGroup, 750)}
          />
          <div className="col-12">
            <Table {...this.getTableProps()} />
          </div>
        </div>
        <div className="row py-3 fix-width justify-content-center align-items-center">
          <Button
            onClick={this.props.onCloseRequest}
            buttonType="outline"
            type="button"
            className="col-8 col-md-3 col-lg-2 mx-2 mb-3 mb-md-0"
            size="small"
            disabled={this.props.groupSendInvitationIsLoading}>
            Cancel
          </Button>
          <Button
            onClick={this.handleSendInvitations}
            buttonType="primary"
            type="button"
            className="col-8 col-md-3 col-lg-2 mx-2"
            size="small"
            disabled={!this.state.tableSelectedRowKeys.length}
            loading={this.props.groupSendInvitationIsLoading}>
            Send Invitations
          </Button>
        </div>
      </React.Fragment>
    );
  }

  getClassName() {
    return classNames({
      'modal-invite-people': true,
      [this.props.className || '']: this.props.className,
    });
  }

  getTableProps() {
    return {
      dataSource: this.getTableData(),
      columns: this.getTableColumns(),
      loading: this.props.accountGroupsIsLoading,
      onChange: this.handleTableChange,
      rowSelection: {
        onChange: this.handleTableRowSelection,
        getCheckboxProps: (record: any) => ({
          disabled: false,
          name: record.key.toString(),
        }),
        selectedRowKeys: this.state.tableSelectedRowKeys,
      },
    };
  }

  getTableData() {
    return this.filterGroups();
  }

  getTableColumns() {
    return [
      {
        title: 'GROUP',
        dataIndex: 'name',
        key: 'name',
        defaultSortOrder: 'ascend',
        render: (text: string, item: any) => {
          return (
            <React.Fragment>
              <Avatar
                className="dashboard-page__table-cell__name-avatar"
                source={item.groupImage}
                type="tiny"
              />
              <span>{text}</span>
            </React.Fragment>
          );
        },
      },
    ];
  }

  handleTableChange = (pagination: any, filters: any, sorter: any) => {
    // TODO: add sorting logic once the server supports it
    this.fetchGroups(pagination.current);
  };

  fetchGroups(page: number = 1) {
    this.props.getAccountGroups({
      page,
      limit: GROUP_PAGE_SIZE,
      groupId: this.state.searchValue,
    });
  }

  // This function is used to filter groups already present in the Redux store.
  // The getTableData() function utilizes it for deciding what to display in the
  // table's content. It must return an array of objects.
  filterGroups(query: string = this.state.searchValue) {
    const allGroups = _get(this.props, 'accountGroupsData.groups', []);
    let returnedArray;
    if (query === '') {
      returnedArray = allGroups;
    } else {
      returnedArray = allGroups.filter(
        group => group.name.includes(query) === true,
      );
    }

    return returnedArray.map(group => ({
      key: group.id,
      name: group.name,
      groupImage: group.group_image,
      code: group.code,
    }));
  }

  handleSendInvitations = () => {
    for (let i = 0; i < this.state.tableSelectedRowKeys.length; i += 1) {
      this.props.groupSendInvitation({
        userIds: [this.props.profileID],
        groupId: this.state.tableSelectedRowKeys[i],
      });
    }
  };

  handleSearchGroup = (inputData: { name: string, value: string }) => {
    this.setState(
      () => ({ searchValue: inputData.value }),
      () => {
        this.filterGroups();
      },
    );
  };

  handleTableRowSelection = (
    selectedRowKeys: number[],
    selectedRows: any[],
  ) => {
    this.setState(() => ({
      tableSelectedRows: selectedRows,
      tableSelectedRowKeys: selectedRowKeys,
    }));
  };
}

const mapStateToProps = (state, ownProps) => {
  return {
    ...accountGroups.mapStateToProps(state),
    ...groupSendInvitation.mapStateToProps(state),
    ...ownProps,
  };
};

const mapDispatchToProps = dispatch => {
  return {
    ...accountGroups.mapDispatchToProps(dispatch),
    ...groupSendInvitation.mapDispatchToProps(dispatch),
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(ModalInvitePerson);
