import {
  userProfileFollowersReset as reset,
  getUserProfileFollowers,
} from './userProfileFollowers.actions';
import type { GetFollowersPayload } from '../../../services/RoRProfileApiProvider';

export const mapStateToProps = state => {
  return {
    profileFollowersData: state.userProfileFollowers.data,
    profileFollowersError: state.userProfileFollowers.error,
    profileFollowersIsLoading: state.userProfileFollowers.isLoading,
  };
};

export const mapDispatchToProps = dispatch => {
  return {
    getUserProfileFollowers(payload: GetFollowersPayload) {
      dispatch(getUserProfileFollowers(payload));
    },
    profileFollowersReset() {
      dispatch(reset());
    },
  };
};

export type UserProfileFollowersMapStateToProps = {
  profileFollowersData: any,
  profileFollowersError: any,
  profileFollowersIsLoading: boolean,
};

export type UserProfileFollowersMapDispatchToProps = {
  getUserProfileFollowers: Function,
  profileFollowersReset: Function,
};

export default {
  mapStateToProps,
  mapDispatchToProps,
};
