import {
  addModeratorsToGroup as addModerators,
  groupAddModeratorsReset as reset,
} from './groupAddModerators.actions';
import type {
  PostGroupAddModeratorsPayload,
  PostGroupAddModeratorsResponse,
} from '../../../services/RoRGroupsApiProvider';

export const mapStateToProps = state => {
  return {
    groupAddModeratorsData: state.groupAddModerators.data,
    groupAddModeratorsError: state.groupAddModerators.error,
    groupAddModeratorsIsLoading: state.groupAddModerators.isLoading,
  };
};

export const mapDispatchToProps = dispatch => {
  return {
    addModeratorsToGroup(payload: PostGroupAddModeratorsPayload) {
      dispatch(addModerators(payload));
    },
    groupAddModeratorsReset() {
      dispatch(reset());
    },
  };
};

export type GroupAddModeratorsMapStateToProps = {
  groupAddModeratorsData: PostGroupAddModeratorsResponse,
  groupAddModeratorsError: any,
  groupAddModeratorsIsLoading: boolean,
};

export type GroupAddModeratorsMapDispatchToProps = {
  addModeratorsToGroup: (payload: PostGroupAddModeratorsPayload) => void,
  groupAddModeratorsReset: () => void,
};

export default {
  mapStateToProps,
  mapDispatchToProps,
};
