// @flow
import makeDefaultServerCallReducer from '../../utilities/makeDefaultServerCallReducer';
import {
  USER_PROFILE_GROUPS_SUCCESS,
  USER_PROFILE_GROUPS_RESET,
  USER_PROFILE_GROUPS_LOADING,
  USER_PROFILE_GROUPS_ERROR,
} from './userProfileGroups.constants';

const initialState = {
  isLoading: false,
  error: null,
  data: null,
};

export const reducer = makeDefaultServerCallReducer({
  loadingActions: [USER_PROFILE_GROUPS_LOADING],
  successActions: [USER_PROFILE_GROUPS_SUCCESS],
  errorActions: [USER_PROFILE_GROUPS_ERROR],
  resetActions: [USER_PROFILE_GROUPS_RESET],
  initialState,
});
