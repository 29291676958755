// @flow
import { createAction } from 'redux-actions';
import {
  GROUP_DENY_REQUEST_ERROR,
  GROUP_DENY_REQUEST_LOADING,
  GROUP_DENY_REQUEST_RESET,
  GROUP_DENY_REQUEST_SUCCESS,
} from './groupDenyRequest.constants';
import { instance as rorGroupsApiProvider } from '../../../services/RoRGroupsApiProvider';
import type { DeleteDenyMemberRequestPayload } from '../../../services/RoRGroupsApiProvider';
import { groupMemberPendingRequestsRemoveItem } from '../groupMemberPendingRequests/groupMemberPendingRequests.actions';

export const groupDenyRequestError = createAction(GROUP_DENY_REQUEST_ERROR);

export const groupDenyRequestLoading = createAction(GROUP_DENY_REQUEST_LOADING);

export const groupDenyRequestSuccess = createAction(GROUP_DENY_REQUEST_SUCCESS);

export const groupDenyRequestReset = createAction(GROUP_DENY_REQUEST_RESET);

export const groupDenyRequest = (payload: DeleteDenyMemberRequestPayload) => {
  return async (dispatch: Function) => {
    try {
      let response = {};
      dispatch(groupDenyRequestLoading());

      response = await rorGroupsApiProvider.groupDenyMemberRequest(payload);
      dispatch(groupMemberPendingRequestsRemoveItem(payload));

      dispatch(groupDenyRequestSuccess(response));
    } catch (error) {
      dispatch(groupDenyRequestError(error));
    }
  };
};
