// @flow
import * as React from 'react';
import classNames from 'classnames';
import uuid from 'uuid/v4';

import './FileInput.scss';
import { message } from 'antd';

type Props = {
  className?: string,
  labelClassName?: string,
  onChange?: Function,
  error?: string,
  type: 'default' | 'transparent' | 'icon-only',
  placeholder?: string,
  size?: 'normal' | 'small',
};

type State = {
  error: string,
  placeholder: string,
};

class FileInput extends React.Component<Props, State> {
  inputId: string;
  inputRef: any;

  static defaultProps = {
    type: 'default',
    size: 'normal',
  };

  static getDerivedStateFromProps(nextProps: Props, nextState: State) {
    if (nextProps.error) {
      return {
        error: nextProps.error,
        placeholder: '',
      };
    } else if (nextState.error) {
      return {
        error: nextState.error,
        placeholder: '',
      };
    } else {
      return null;
    }
  }

  constructor(props: Props) {
    super(props);

    this.inputId = uuid();
    this.state = {
      error: this.props.error || '',
      placeholder: this.props.placeholder || '',
    };
  }

  render() {
    return (
      <div className={this.getClassName()}>
        <input {...this.getInputProps()} />
        <label className={this.getLabelClassName()} htmlFor={this.inputId}>
          {this.props.type !== 'icon-only' && this.state.placeholder}
        </label>
        {this.state.error && (
          <div className="file-input__error-message">{this.state.error}</div>
        )}
      </div>
    );
  }

  getInputProps() {
    return {
      onChange: this.handleFileChange,
      type: 'file',
      className: 'custom-file-input file-input__input-element',
      id: this.inputId,
      ref: this.setInputRef,
    };
  }

  setInputRef = (ref: any) => {
    this.inputRef = ref;
  };

  getClassName() {
    return classNames({
      'file-input': true,
      [`file-input--${this.props.type}`]: true,
      [`file-input--size-${this.props.size || ''}`]: true,
      'file-input--error': this.state.error,
      'custom-file': true, // custom-file classes are inherited from bootstrap
      [this.props.className || '']: this.props.className,
    });
  }

  getLabelClassName() {
    return classNames({
      'custom-file-label': true,
      'file-input__label': true,
      'file-input__label--error': this.state.error,
      [`file-input__label--${this.props.type}`]: true,
      [`file-input__label--size-${this.props.size || ''}`]: true,
      [this.props.labelClassName || '']: this.props.labelClassName,
    });
  }

  handleFileChange = (e: Event) => {
    const files = this.inputRef.files;

    if (files && files[0] && files[0].size < 50000000) {
      this.setState(() => ({
        error: '',
        placeholder: files[0].name,
      }));
    } else {
      this.inputRef.value = '';
      this.setState(() => ({
        error: 'The selected file should be smaller than 50 MB',
      }));
    }

    if (this.props.onChange) {
      this.props.onChange(files);
    }
  };
}

export default FileInput;
