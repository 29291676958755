// @flow
const RoleCode = Object.freeze({
  ADMIN: 'admin',
  CANDIDATE: 'candidate',
  CAMPAIGN_MANAGER: 'campaign_manager',
  CITIZEN: 'citizen',
  POLITICAL_PARTY: 'political_party',
  TRADITIONAL_PAC: 'traditional_pac',
  LEADERSHIP_PAC: 'leadership_pac',
  SUPER_PAC: 'super_pac',
  HYBRID_PAC: 'hybrid_pac',
});

export default RoleCode;
