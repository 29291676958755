// @flow
import Axios, { AxiosResponse, AxiosError } from 'axios';
import RoRApiResponseTransformer from '../utilities/RoRApiResponseTransformer';
import RoRApiProvider from './RoRApiProvider';
import type { DefaultResponse } from './RoRApiProvider';
import type { Feed } from './RoRUsersApiProvider';

class RoRRacesApiProvider extends RoRApiProvider {
  constructor() {
    super();
  }

  raceDetails(payload: GetRaceDetailsPayload): Promise<GetRaceDetailsResponse> {
    const requestConfig = {
      headers: {
        'Content-Type': 'application/json',
        ...this.getAuthenticationHeaders(),
      },
    };

    return this.axios
      .get(`apis/races/${payload.raceId}`, requestConfig)
      .then((response: AxiosResponse) =>
        RoRApiResponseTransformer.parseResponse(response),
      )
      .catch((error: AxiosError) =>
        Promise.reject(RoRApiResponseTransformer.parseError(error)),
      );
  }

  raceCandidates(
    payload: GetRaceCandidatesPayload,
  ): Promise<GetRaceCandidatesResponse> {
    const requestConfig = {
      headers: {
        'Content-Type': 'application/json',
        ...this.getAuthenticationHeaders(),
      },
    };

    return this.axios
      .get(`apis/races/${payload.raceId}/candidates`, requestConfig)
      .then((response: AxiosResponse) =>
        RoRApiResponseTransformer.parseResponse(response),
      )
      .catch((error: AxiosError) =>
        Promise.reject(RoRApiResponseTransformer.parseError(error)),
      );
  }

  raceFeed(payload: GetRaceFeedPayload): Promise<GetRaceFeedResponse> {
    const requestConfig = {
      headers: {
        'Content-Type': 'application/json',
        ...this.getAuthenticationHeaders(),
      },
      params: {
        race_id: payload.raceId,
        page: payload.page || 1,
        limit: payload.limit || 20,
      },
    };

    return this.axios
      .get(`apis/feeds`, requestConfig)
      .then((response: AxiosResponse) =>
        RoRApiResponseTransformer.parseResponse(response, true),
      )
      .catch((error: AxiosError) =>
        Promise.reject(RoRApiResponseTransformer.parseError(error)),
      );
  }

  raceGroups(payload: GetRaceGroupsPayload): Promise<GetRaceGroupsResponse> {
    const requestConfig = {
      headers: {
        'Content-Type': 'application/json',
        ...this.getAuthenticationHeaders(),
      },
      params: {
        page: payload.page || 1,
        limit: payload.limit || 20,
      },
    };

    return this.axios
      .get(`apis/races/${payload.raceId}/groups`, requestConfig)
      .then((response: AxiosResponse) =>
        RoRApiResponseTransformer.parseResponse(response, true),
      )
      .catch((error: AxiosError) =>
        Promise.reject(RoRApiResponseTransformer.parseError(error)),
      );
  }

  newRaceThought(
    payload: PostRaceThoughtPayload,
  ): Promise<PostRaceThoughtResponse> {
    const requestConfig = {
      headers: {
        'Content-Type': 'application/json',
        ...this.getAuthenticationHeaders(),
      },
    };
    const requestPayload = {
      race_thought: { ...payload.race_thought },
    };

    return this.axios
      .post(
        `apis/races/${payload.raceId}/race_thoughts`,
        requestPayload,
        requestConfig,
      )
      .then((response: AxiosResponse) =>
        RoRApiResponseTransformer.parseResponse(response),
      )
      .catch((error: AxiosError) =>
        Promise.reject(RoRApiResponseTransformer.parseError(error)),
      );
  }

  listRaces(payload: GetRacesPayload): Promise<GetRacesResponse> {
    const requestConfig = {
      headers: {
        'Content-Type': 'application/json',
        ...this.getAuthenticationHeaders(),
      },
      params: payload,
    };

    return this.axios
      .get('/apis/races', requestConfig)
      .then((response: AxiosResponse) =>
        RoRApiResponseTransformer.parseResponse(response, true),
      )
      .catch((error: AxiosError) =>
        Promise.reject(RoRApiResponseTransformer.parseError(error)),
      );
  }

  followRace(payload: PostRaceFollowPayload): Promise<PostRaceFollowResponse> {
    const requestConfig = {
      headers: {
        'Content-Type': 'application/json',
        ...this.getAuthenticationHeaders(),
      },
    };

    return this.axios
      .post(`apis/races/${payload.raceId}/follow`, {}, requestConfig)
      .then((response: AxiosResponse) =>
        RoRApiResponseTransformer.parseResponse(response),
      )
      .catch((error: AxiosError) =>
        Promise.reject(RoRApiResponseTransformer.parseError(error)),
      );
  }

  unfollowRace(
    payload: PostRaceFollowPayload,
  ): Promise<PostRaceFollowResponse> {
    const requestConfig = {
      headers: {
        'Content-Type': 'application/json',
        ...this.getAuthenticationHeaders(),
      },
    };

    return this.axios
      .post(`apis/races/${payload.raceId}/unfollow`, {}, requestConfig)
      .then((response: AxiosResponse) =>
        RoRApiResponseTransformer.parseResponse(response),
      )
      .catch((error: AxiosError) =>
        Promise.reject(RoRApiResponseTransformer.parseError(error)),
      );
  }
}

export type RaceDetails = {
  id: number,
  name: string,
  due_date: string,
  followers_count: number,
  candidates_count: number,
  state: {
    id: number,
    name: string,
    code: string,
  },
  current_user: {
    is_following: boolean,
  },
};

export type GetRaceDetailsPayload = {
  raceId: number,
};

export type GetRaceDetailsResponse = DefaultResponse & {
  data: {
    race: RaceDetails,
  },
};

export type RaceCandidate = {
  id: number,
  rank: number,
  first_name: string,
  last_name: string,
  profile_image: string,
  is_public: boolean,
  role: {
    id: number,
    name: string,
    code: string,
    admin: boolean,
  },
  donations: {
    contributed: number,
    committed: number,
    pledged: number,
  },
};

export type GetRaceCandidatesPayload = {
  raceId: number,
};

export type GetRaceCandidatesResponse = DefaultResponse & {
  data: RaceCandidate[],
};

export type GetRaceFeedPayload = {
  raceId: number,
  page?: number,
  limit?: number,
};

export type GetRaceFeedResponse = DefaultResponse & {
  data: { feeds: Feed },
};

export type RaceGroup = {
  id: number,
  name: string,
  code: string,
  group_image: string,
};

export type GetRaceGroupsPayload = {
  raceId: number,
  page?: number,
  limit?: number,
};

export type GetRaceGroupsResponse = DefaultResponse & {
  data: { groups: RaceGroup[] },
};

export type RaceThought = {
  id: number,
  thought: string,
  race: {
    id: string,
    name: string,
    due_date: string,
    state_id: number,
    created_at: string,
    updated_at: string,
  },
  owner: {
    id: number,
    first_name: string,
    last_name: string,
    profile_image: string,
    is_public: boolean,
    role: {
      id: number,
      name: string,
      code: string,
      admin: boolean,
    },
  },
};

export type GetRacesPayload = {
  name?: string,
  limit?: number,
  page?: number,
};

export type GetRacesResponse = DefaultResponse & {
  data: {
    races: {
      id: string,
      name: string,
      due_date: string,
    }[],
  },
};

export type PostRaceThoughtPayload = {
  raceId: number,
  race_thought: {
    thought: string,
  },
};

export type PostRaceThoughtResponse = {
  thought: RaceThought,
};

export type PostRaceFollowPayload = {
  raceId: number,
};

export type PostRaceFollowResponse = DefaultResponse & {
  race: RaceDetails,
};

export default RoRRacesApiProvider;
export const instance = new RoRRacesApiProvider();
