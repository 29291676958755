// @flow
import { createAction } from 'redux-actions';
import {
  USER_PROFILE_FEED_ERROR,
  USER_PROFILE_FEED_LOADING,
  USER_PROFILE_FEED_RESET,
  USER_PROFILE_FEED_SUCCESS,
  USER_PROFILE_FEED_LIKE_POST,
  USER_PROFILE_FEED_UNLIKE_POST,
  USER_PROFILE_FEED_NEXT_PAGE,
  USER_PROFILE_FEED_SHARE_POST,
} from './userProfileFeed.constants';
import { instance as rorUsersApiProvider } from '../../../services/RoRUsersApiProvider';
import { clientStore } from '../../../redux/clientStore';
import _get from 'lodash/get';
import type { GetUserFeedPayload } from '../../../services/RoRUsersApiProvider';

export const userProfileFeedError = createAction(USER_PROFILE_FEED_ERROR);

export const userProfileFeedLoading = createAction(USER_PROFILE_FEED_LOADING);

export const userProfileFeedSuccess = createAction(USER_PROFILE_FEED_SUCCESS);

export const userProfileFeedReset = createAction(USER_PROFILE_FEED_RESET);

export const userProfileFeedLikePost = createAction(
  USER_PROFILE_FEED_LIKE_POST,
);

export const userProfileFeedUnlikePost = createAction(
  USER_PROFILE_FEED_UNLIKE_POST,
);

export const userProfileFeedSharePost = createAction(
  USER_PROFILE_FEED_SHARE_POST,
);

export const userProfileFeedNextPage = createAction(
  USER_PROFILE_FEED_NEXT_PAGE,
);

export const getUserProfileFeed = (payload: GetUserFeedPayload) => {
  return async (dispatch: Function) => {
    try {
      dispatch(userProfileFeedLoading());

      const response = await rorUsersApiProvider.userProfileFeed(payload);

      dispatch(userProfileFeedSuccess(response));
    } catch (error) {
      dispatch(userProfileFeedError(error));
    }
  };
};

export const getUserProfileFeedNextPage = (payload: GetUserFeedPayload) => {
  return async (dispatch: Function) => {
    try {
      const nextPage = _get(
        clientStore.getState(),
        `userProfileFeed.data.next_page`,
      );

      if (nextPage) {
        dispatch(userProfileFeedLoading());

        const response = await rorUsersApiProvider.userProfileFeed({
          limit: payload.limit,
          page: nextPage,
          userId: payload.userId,
        });

        dispatch(userProfileFeedNextPage(response));
      }
    } catch (error) {
      dispatch(userProfileFeedError(error));
    }
  };
};
