import {
  claimPledge as claim,
  contributionPledgeClaimReset as reset,
} from './contributionPledgeClaim.actions';
import type { PostClaimPledgePayload } from '../../../services/RoRContributionsApiProvider';
import type { DefaultResponse } from '../../../services/RoRApiProvider';

export const mapStateToProps = state => {
  return {
    contributionPledgeClaimData: state.contributionPledgeClaim.data,
    contributionPledgeClaimError: state.contributionPledgeClaim.error,
    contributionPledgeClaimIsLoading: state.contributionPledgeClaim.isLoading,
  };
};

export const mapDispatchToProps = dispatch => {
  return {
    claimPledge(payload: PostClaimPledgePayload) {
      dispatch(claim(payload));
    },
    contributionPledgeClaimReset() {
      dispatch(reset());
    },
  };
};

export type ContributionPledgeClaimMapStateToProps = {
  contributionPledgeClaimData: DefaultResponse,
  contributionPledgeClaimError: any,
  contributionPledgeClaimIsLoading: boolean,
};

export type ContributionPledgeClaimDispatchToProps = {
  claimPledge: (payload: PostClaimPledgePayload) => void,
  contributionPledgeClaimReset: () => void,
};

export default {
  mapStateToProps,
  mapDispatchToProps,
};
