//@flow
import React, { Component } from 'react';
import './ModalInviteByContact.scss';
import Modal from '../Modal/Modal';
import classNames from 'classnames';
import Input from '../Input/Input';
import type { ModalProps } from '../Modal/Modal';
import { Formik } from 'formik';
import * as yup from 'yup';
import Button from '../Button/Button';
import groupInviteByContact from '../../redux/modules/groupInviteByContact/groupInviteByContact.containers';
import { connect } from 'react-redux';
import isValidEmail, { normalizeEmail } from '../../validators/isValidEmail';
import isValidPhoneNumber, {
  normalizePhoneNumber,
} from '../../validators/isValidPhoneNumber';
import { message } from 'antd';

import type {
  GroupInviteByContactMapDispatchToProps,
  GroupInviteByContactMapStateToProps,
} from '../../redux/modules/groupInviteByContact/groupInviteByContact.containers';
import type { GroupDetails } from '../../services/RoRGroupsApiProvider';

type Props = ModalProps &
  GroupInviteByContactMapDispatchToProps &
  GroupInviteByContactMapStateToProps & {
    groupDetails: GroupDetails,
  };

class ModalInviteByContact extends Component<Props> {
  componentDidMount() {
    this.props.groupInviteByContactReset();
  }

  componentDidUpdate(prevProps: Props) {
    if (
      !prevProps.groupInviteByContactData &&
      this.props.groupInviteByContactData
    ) {
      message.success('Invites were sent successfully.');
      this.props.onCloseRequest && this.props.onCloseRequest();
    }
    if (
      !prevProps.groupInviteByContactError &&
      this.props.groupInviteByContactError
    ) {
      message.error(
        this.props.groupInviteByContactError.localMessage ||
          'An error has occurred and the invites were not sent.',
      );
    }
  }

  render() {
    const { ...modalProps } = this.props;
    return (
      <Modal
        head="Invite to a Group"
        contentLabel="Invite by Email or Phone Number"
        className={this.getClassName()}
        size="small"
        {...modalProps}>
        {modalProps.isOpen ? this.renderContent() : null}
      </Modal>
    );
  }

  renderContent() {
    return (
      <div>
        <div className="row">
          <div className="col-sm-12">
            <p>
              The Prytany mobile app allows you to sync your contacts so you can
              invite friends and colleagues more easily. Visit your mobile app
              store to download now.
            </p>
          </div>
        </div>
        <Formik {...this.getFormikProps()}>
          {({ touched, errors, isValid, isSubmitting, handleSubmit }) => (
            <form onSubmit={handleSubmit}>
              <div className="row">
                <div className="col-12">
                  <Input
                    className="mb-4"
                    label=""
                    placeholder="Email address or phone number"
                    name="emailOrPhoneNumber"
                    type="text"
                    size="small"
                    error={
                      touched.emailOrPhoneNumber &&
                      (errors.emailOrPhoneNumber || '')
                    }
                  />
                </div>
              </div>
              <div className="col-12 offset-md-6 col-md-6 order-4">
                <div className="row justify-content-end">
                  <div className="col-4">
                    <Button
                      type="button"
                      onClick={this.props.onCloseRequest}
                      buttonType="link"
                      noBorder
                      className="modal-new-group__cancel-button"
                      disabled={this.props.groupInviteByContactIsLoading}>
                      Cancel
                    </Button>
                  </div>
                  <div className="col-4">
                    <Button
                      type="submit"
                      buttonType="primary"
                      block
                      noBorder
                      disabled={!isValid}
                      loading={this.props.groupInviteByContactIsLoading}>
                      Invite
                    </Button>
                  </div>
                </div>
              </div>
            </form>
          )}
        </Formik>
      </div>
    );
  }

  getClassName() {
    return classNames({
      'modal-invite-by-contact': true,
      [this.props.className || '']: this.props.className,
    });
  }

  getFormikProps() {
    return {
      initialValues: { emailOrPhoneNumber: '' },
      validationSchema: yup.object().shape(this.getValidationSchema()),
      onSubmit: this.handleInvite,
    };
  }

  getValidationSchema() {
    let schema: any = {
      emailOrPhoneNumber: yup
        .string()
        .required('This field is required')
        .test(
          'Must be a valid email or phone number',
          value => isValidEmail(value) || isValidPhoneNumber(value),
        ),
    };
    return schema;
  }

  handleInvite = ({ emailOrPhoneNumber }) => {
    const { groupInviteByContact, groupDetails } = this.props;
    groupInviteByContact({
      groupId: groupDetails.id,
      email: isValidEmail(emailOrPhoneNumber)
        ? normalizeEmail(emailOrPhoneNumber)
        : undefined,
      telephone: isValidPhoneNumber(emailOrPhoneNumber)
        ? normalizePhoneNumber(emailOrPhoneNumber)
        : undefined,
    });
  };
}

const mapStateToProps = (state, ownProps) => {
  return {
    ...groupInviteByContact.mapStateToProps(state),
    ...ownProps,
  };
};

const mapDispatchToProps = dispatch => {
  return {
    ...groupInviteByContact.mapDispatchToProps(dispatch),
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(ModalInviteByContact);
