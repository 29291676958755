import {
  getUserRssFeed as getRssFeed,
  userRssFeedReset as reset,
} from './userRssFeed.actions';
import type {
  GetUserRSSFeedPayload,
  GetUserRSSFeedResponse,
} from '../../../services/RoRUsersApiProvider';

export const mapStateToProps = state => {
  return {
    userRssFeedData: state.userRssFeed.data,
    userRssFeedError: state.userRssFeed.error,
    userRssFeedIsLoading: state.userRssFeed.isLoading,
  };
};

export const mapDispatchToProps = dispatch => {
  return {
    getUserRssFeed(payload: GetUserRSSFeedPayload) {
      dispatch(getRssFeed(payload));
    },
    userRssFeedReset() {
      dispatch(reset());
    },
  };
};

export type UserRssFeedMapStateToProps = {
  userRssFeedData: GetUserRSSFeedResponse,
  userRssFeedError: any,
  userRssFeedIsLoading: boolean,
};

export type UserRssFeedMapDispatchToProps = {
  getUserRssFeed: (payload: GetUserRSSFeedPayload) => void,
  userRssFeedReset: () => void,
};

export default {
  mapStateToProps,
  mapDispatchToProps,
};
