// @flow
import makeDefaultServerCallReducer from '../../utilities/makeDefaultServerCallReducer';
import {
  GROUP_RENAME_ERROR,
  GROUP_RENAME_LOADING,
  GROUP_RENAME_SUCCESS,
  GROUP_RENAME_RESET,
} from './groupRename.constants';

const initialState = {
  isLoading: false,
  error: null,
  data: null,
};

export const reducer = makeDefaultServerCallReducer({
  loadingActions: [GROUP_RENAME_LOADING],
  successActions: [GROUP_RENAME_SUCCESS],
  errorActions: [GROUP_RENAME_ERROR],
  resetActions: [GROUP_RENAME_RESET],
  initialState,
});
