// @flow
import React from 'react';
import iphoneAppStoreImage from '../../../public/images/app-ios-badge.png';
import androidAppStoreImage from '../../../public/images/app-android-badge.png';
import './PrivacyPolicyPage.scss';

const PrivacyPolicyPage = () => (
  <div className="privacy-policy">
    <section className="content">
      <div className="container-fluid fix-width">
        <div className="row justify-content-md-center">
          <div className="col-lg-8 col-md-9 pr-8-md">
            <h1>Privacy Policy</h1>
            <p>June 24, 2019</p>
            <h2>OUR COMMITMENT TO PRIVACY</h2>
            <p>
              Prytany LLC knows that you care about how your personal
              information is used and shared, and we take your privacy
              seriously. Accordingly, we have adopted a written information
              security program. We understand that you may have questions or
              concerns regarding how your personal information that you provide
              to Prytany will be used and secured by Prytany. To make this
              Privacy Policy and our written information security program easy
              to find, we make them available on the home page of the Prytany
              website at prytany.com (the "website") and, for our Privacy
              Policy, also through our mobile applications. You may also email
              us at prytany.com with any privacy-related questions you have.
            </p>
            <p>
              Capitalized terms used in this Privacy Policy but not otherwise
              defined in this Privacy Policy have the meanings given to them in
              our User Agreement. Our User Agreement is available on our
              website.
            </p>
            <h2>APPLICABILITY OF PRIVACY POLICY</h2>
            <p>
              This Privacy Policy applies to all information we collect through
              our web and mobile applications from Prytany users, including you.
              When you are no longer a Prytany user, we will continue to share
              your information as described in this policy. This policy only
              applies to your use of Prytany and does not apply to the practices
              or service of any payment processing service used by you through
              Prytany’s web and mobile applications.
            </p>
            <p>
              Throughout this policy, we use the term "personal information" to
              describe information that can be associated with a specific person
              and can be used to identify that person. We do not consider
              personal information to include information that has been
              aggregated and/or anonymized so that it does not identify a
              specific user. Prytany will own such aggregated and anonymized
              information.
            </p>
            <h2>THE INFORMATION WE COLLECT</h2>
            <p>
              When you visit the Prytany website or use one of our mobile
              applications, we collect your IP address, and standard web log
              information, such as your browser type and the pages you accessed
              in our website. We also may collect certain geo-location
              information. If you do not agree to our collection of this
              information, you may not be able to use Prytany.
            </p>
            <p>
              If you register as a Prytany Constituent, a Registered Campaign, a
              Registered National Party Committee or a Political Action
              Committee (PAC), we collect the following information from you:
            </p>
            <ul>
              <li>
                Account Information - text-enabled cellular/wireless telephone
                number, machine or mobile device ID and other similar
                information, and your user name and password.
              </li>
              <li>
                Identification Information - your full name, mailing address,
                email address, and date of birth. If you desire to make a
                political contribution or pledge using the Prytany Platform, we
                also will require you to give us your occupation and the name of
                your employer. If we need to verify your identification, we also
                may require you to scan and send to us, using the ID scanning
                application provided on the Prytany website, a picture of your
                driver’s license or other government-issued identification card,
                which may include your US passport, and in certain cases we also
                may require you to provide us with your Social Security Number.
                Prytany will not store the scanned images of your identification
                card although it will retain the unique identifying barcode or
                other identifier for future user access verification purposes
                and to avoid multiple accounts for the same individual. Prytany
                will comply with the requirements of the US Social Security
                Administration with respect to the secure storage of your Social
                Security Number. For a Registered Campaign, we collect such
                identification information about the authorized officer
                associated with such campaign (usually its Treasurer) and the
                Federal Election Commission Identification Number assigned to
                such campaign. For a Registered National Party Committee, we
                collect such identification information about the chairperson or
                treasurer of such national party committee and the Federal
                Election Commission Identification Number assigned to such
                committee. For a PAC, we collect such identification information
                about the authorized officer associated with such committee
                (usually its Treasurer) and the Federal Election Commission
                Identification Number assigned to such PAC.
              </li>
              <li>
                Device Information - information about you: (a) from your mobile
                device such as your device type, machine or mobile device
                identification number, media access control address,
                geo-location, time zone, language setting, browser type, and IP
                address, and (b) from third parties for purposes of transaction
                processing, identity verification, fraud detection or prevention
                and other similar purposes.
              </li>
              <li>
                Social Web Information - including but not limited to your email
                account information. Social and e-mail contact information helps
                you send messages to other members of your Groups (as well as
                helping us improve our service and fight fraud). You may manage
                certain contact preferences and notifications in your account
                settings.
              </li>
              <li>
                Other information - we may collect additional information from
                you not specifically described here that we request and that you
                permit us to collect.
              </li>
            </ul>
            <p>
              Before permitting you to use or to continue using Prytany, we may
              require additional information from you that we can use to verify
              your identity or other information or to manage risk and
              compliance throughout our relationship. We may also obtain
              information about you from third parties such as identity
              verification, fraud prevention and similar service.
            </p>
            <p>
              When you are using Prytany, we collect information about your
              Prytany Account activity, including your contribution, pledge and
              messaging history and your interests in political and social
              causes. We also may collect information about your mobile device,
              computer or other access device for fraud prevention and other
              similar purposes.
            </p>
            <p>
              When you make a contribution using Prytany, your designated
              preferred funding source (either your bank account and routing
              number or your debit and credit card) will be shared with
              Prytany’s payment processing service provider. Prytany, however,
              will not have full visibility of such information and will not
              store such financial information. We will collect information
              about your contribution and pledge amounts and the recipients of
              your contributions and pledges and will assign you a rating based
              on your contribution and pledge history.
            </p>
            <p>
              Finally, we may collect additional information from or about you
              in other ways not specifically described here. For example, we may
              collect information related to your contacts with us.
            </p>
            <h2>INFORMATION FROM CHILDREN</h2>
            <p>
              We do not knowingly solicit or collect information from any
              individuals under the age of 13. If we become aware that we have
              unknowingly collected Personal Information from a child under the
              age of 13, such information will be deleted from our system.
            </p>
            <h2>HOW WE USE COOKIES</h2>
            <p>
              When you visit our website, use our mobile application or the
              Prytany Platform, we may use cookies and other tracking
              technologies (collectively, "Cookies"). We use Cookies to
              recognize you as a Prytany user; customize Prytany for your use,
              customize other content and contribution opportunities for you;
              measure the effectiveness of our profiling of Prytany users;
              perform a wide range of analytics; mitigate risk and prevent
              potential fraud; and to promote trust and safety across our sites
              and the Prytany Platform.
            </p>
            <p>
              Certain aspects and features of the Prytany Platform and our
              website are only available through the use of Cookies, so if you
              choose to disable or decline Cookies, your use of our website and
              the Prytany Platform may be limited or not possible.
            </p>
            <p>
              Do Not Track: Do Not Track ("DNT") is an optional browser setting
              that allows you to express your preferences regarding tracking by
              advertisers and other third-parties. We do not track users unless
              they enable the “remember me” feature on the Prytany signup page,
              in which case we store their email/username using browser cookies.
              If you set DNT, then you will be unable to utilize the “remember
              me” feature for future log-ins to your Prytany Account.
            </p>
            <h2>HOW WE PROTECT AND STORE PERSONAL INFORMATION</h2>
            <p>
              We store and process your personal information using third party
              servers located in data centers in the United States. We also use
              computer safeguards such as firewalls and data encryption, we
              enforce physical access controls to our office and files, and we
              authorize access to personal information only for those employees
              who require it to fulfill their job responsibilities. We comply
              with the requirements of the US Social Security Administration
              with respect to the secure storage of your Social Security Number.
            </p>
            <p>
              We strive to ensure security on our systems. Despite our efforts,
              we cannot guarantee that personal information will not be
              accessed, disclosed, altered or destroyed by breach of our
              administrative, managerial and technical safeguards. Therefore, we
              urge you to take adequate precautions to protect your personal
              data as well, including never sharing your Prytany password with
              anyone.
            </p>
            <p>
              If Prytany learns of a systems security breach, we may attempt to
              notify you electronically so that you can take appropriate
              protective steps. By using the Prytany Platform, you agree that
              Prytany may communicate with you electronically. Prytany may post
              a notice on its website or mobile application if a security breach
              occurs. We may also send an email to you at the email address you
              have provided to us. You consent to receiving notices by email.
              You agree to provide Prytany up-to-date contact information,
              including email address. Depending on where you live, you may have
              a legal right to receive notice of a security breach in writing.
              To receive free written notice of a security breach (or to
              withdraw your consent from receiving electronic notice of a
              security breach), please email us at{' '}
              <a href="mailto:privacy@Prytany.com">privacy@Prytany.com</a>.
            </p>
            <h2>HOW WE USE THE PERSONAL INFORMATION WE COLLECT</h2>
            <p>We may use your personal information to:</p>
            <ul>
              <li>provide the service and support you request;</li>
              <li>
                process your contributions and pledges, monitor and send notices
                to you about your contributions and pledges, and assign you a
                rating based on your activity on the Prytany Platform;
              </li>
              <li>
                resolve disputes, collect fees, and troubleshoot problems;
              </li>
              <li>
                prevent potentially fraudulent, prohibited or illegal
                activities, and enforce our User Agreement;
              </li>
              <li>
                create an account connection between your Prytany Account and
                our third-party payment processing service provider;
              </li>
              <li>
                customize, personalize, measure, and improve our service and the
                content and layout of our website;
              </li>
              <li>
                send you updates about political news and political candidates,
                campaigns and other organizations;
              </li>
              <li>
                compare information for accuracy and verify it with third
                parties;
              </li>
              <li>perform other duties as required by law; and</li>
              <li>
                perform analytics using aggregated and anonymized information
                based on information provided by you.
              </li>
            </ul>
            <h2>
              HOW WE SHARE PERSONAL INFORMATION WITHIN THE PRYTANY PLATFORM
            </h2>
            <p>
              To process contributions and pledges made using the Prytany
              Platform, we need to share some of your personal information with
              the payment processing service linked to the Prytany Platform and
              the Registered Campaign, Registered National Party Committee or
              PAC to which you are contributing or pledging. Your contact
              information, date of sign-up, date of birth, occupation, employer,
              the number of contributions and pledges you have made and other
              rating and verification metrics may be provided to the Registered
              Campaigns, Registered National Party Committees, PACs and,
              depending on your privacy settings, other Prytany users in a Group
              that you have created or joined when you use the Group feature of
              the Prytany Platform.
            </p>
            <p>
              We work with Registered Campaigns, Registered National Party
              Committees and PACs to enable them to accept contributions and
              pledges from you using Prytany. We confirm to that Registered
              Campaign, Registered National Party Committee or PAC that you are
              a Prytany Constituent and that the Registered Campaign, Registered
              National Party Committee or PAC should accept your contribution or
              pledge via the Prytany Platform.
            </p>
            <p>
              We do not access or store your credit card number or bank account
              number and therefore cannot disclose it to anyone. That
              information will be provided to our third-party payment processing
              service enabled via Prytany and it will be safeguarded in
              accordance with their privacy policies.
            </p>
            <h2>HOW WE SHARE PERSONAL INFORMATION WITH OTHER PARTIES</h2>
            <p>
              Prytany does not share your personal information with third
              parties for their promotional or marketing purposes.
            </p>
            <p>We may share your personal information with:</p>
            <ul>
              <li>
                Companies that Prytany LLC plans to merge with or be acquired by
                or, in the event of any bankruptcy, a bankruptcy estate. Should
                such a combination occur, we will require that the new combined
                entity follow this privacy policy, as updated from time-to-time,
                with respect to your personal information. If your personal
                information could be used by us or our successor contrary to
                this policy, you will receive prior notice from us or our
                successor and the opportunity to communicate preferences you may
                have, if applicable.
              </li>
              <li>
                Law enforcement, government officials, or other third parties if
                Prytany is requested to do so by a subpoena, court order or
                similar legal procedure, or where the disclosure of personal
                information is reasonably necessary to prevent physical harm or
                financial loss, to report suspected illegal activity, or to
                investigate violations of the Prytany User Agreement, or as
                otherwise required by law.
              </li>
              <li>
                Third party service providers who assist us in making the
                Prytany Platform available to you or who provide identification
                verification, fraud detection or similar services on our behalf
                or on behalf of the payment processing service enabled by
                Prytany or any Registered Campaign, Registered National Party
                Committee or PAC.
              </li>
              <li>
                The Registered Campaign, Registered National Party Committee or
                PAC receiving your contribution or pledge.
              </li>
              <li>
                Depending on the privacy setting of your Prytany Account and of
                any Prytany Group that you may create or join, your Prytany
                Group members and the Prytany Group members of the Registered
                Campaign, Registered National Party Committee or PAC receiving
                your contribution or pledge.
              </li>
              <li>
                Service providers under contract who help with parts of our
                business operations (for example, identity verification, fraud
                prevention, payment processing, website and database hosting and
                administration, business support service, internet service and
                information technology service). Our contracts dictate that
                these service providers only use your information in connection
                with the service they perform for us and not for their own
                benefit.
              </li>
              <li>
                Your bank or credit card issuer with your consent and at your
                direction to do so, including if you authorize an account
                connection with your bank or credit card issuer.
              </li>
            </ul>
            <h2>HOW YOU CAN ACCESS OR CHANGE YOUR PERSONAL INFORMATION</h2>
            <p>
              You can review and update your personal information in your
              Prytany Account settings at any time by logging in to your Prytany
              Account.
            </p>
            <h2>LINKS TO OTHER SITES</h2>
            <p>
              The Prytany website or mobile application may contain links to
              other third-party websites. Prytany does not control the
              information collection of third-party websites that can be reached
              through links from the website. We encourage our users to be aware
              when they are leaving the Prytany website and to read the privacy
              statements of any third-party website that collects personally
              identifiable information.
            </p>
            <h2>CHANGES TO OUR PRIVACY POLICY</h2>
            <p>
              Prytany is always improving. As the Prytany Platform evolves we
              may occasionally update our Privacy Policy. If we modify this
              Privacy Policy, we will post a link to the revised Privacy Policy
              on the homepage of our website, and we will also revise the date
              first stated above. If we make material changes in the way we use
              personal information, we will notify you by posting an
              announcement on our mobile application or on the homepage of our
              website or by sending you an e-mail. It is your responsibility to
              periodically review this Privacy Policy; users are bound by any
              changes to the Privacy Policy by using the Prytany Platform after
              such changes have been first posted.
            </p>
            <h2>HOW TO CONTACT US</h2>
            <p>
              If you have questions or concerns regarding this Privacy Policy,
              or any feedback pertaining to your privacy and the Prytany
              Platform that you would like us to consider, please email us at
              <a href="mailto:contact@prytany.com">contact@prytany.com</a> or
              write to us at c/o Prytany LLC, 786 Stephanie Circle, Great Falls,
              VA 22066.
            </p>
            <h2>Consumer Privacy Notice</h2>
            <p>
              Prytany is a political contribution and messaging platform
              provided by Prytany LLC ("Prytany") and as such is considered to
              be a social platform.
            </p>
            <h4>Facts</h4>
            <p>What does Prytany do with your personal information?</p>
            <h4>Why?</h4>
            <p>
              Social platform companies choose how they share your personal
              information. Federal law gives consumers the right to limit some
              but not all sharing. Federal law also requires us to tell you how
              we collect, share, and protect your personal information. Please
              read this notice carefully to understand what we do.
            </p>
            <h4>What?</h4>
            <p>
              The types of personal information we collect and, in certain
              cases, share depend on the way you use the Prytany Platform. This
              information can include:
            </p>
            <ul>
              <li>
                Your full name, mailing address, email address and date of birth
              </li>
              <li>Your occupation and employer</li>
              <li>
                A scanned picture of your driver’s license or other
                government-issued ID, including your US passport
              </li>
              <li>Your Social Security Number</li>
              <li>
                Your contribution history, including pending periodic
                contributions and pledges made using the Prytany Platform
              </li>
            </ul>
            <p>
              When you are no longer a Prytany user, we continue to share your
              information as described in this notice.
            </p>
            <h4>How?</h4>
            <p>
              All social media platform companies need to share users’ personal
              information to run their everyday business. In the section below,
              we list the reasons social media platform companies can share
              their users’ personal information; the reasons Prytany chooses to
              share; and whether you can limit this sharing.
            </p>
            <table>
              <thead>
                <tr>
                  <th>Reasons we can share your personal information</th>
                  <th>Does Prytany share?</th>
                  <th>Can you limit this sharing?</th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td>
                    <strong>For our everyday business purposes—</strong>
                    <p>
                      such as to process your contributions and pledges, to
                      maintain your Prytany Account, to respond to court orders
                      and legal investigations, and to report to Registered
                      Campaigns, Registered National Party Committees and PACs
                    </p>
                  </td>
                  <td>Yes</td>
                  <td>No</td>
                </tr>
                <tr>
                  <td>
                    <strong>For our marketing purposes—</strong>
                    <p>to offer our platform to you</p>
                  </td>
                  <td>Yes</td>
                  <td>No</td>
                </tr>
                <tr>
                  <td>For joint marketing with other companies</td>
                  <td>No</td>
                  <td>We don't joint market</td>
                </tr>
                <tr>
                  <td>
                    <strong>
                      For our affiliates’ everyday business purposes—
                    </strong>
                    <p>information about your contributions and experiences</p>
                  </td>
                  <td>No</td>
                  <td>We don't share with any affiliate</td>
                </tr>
                <tr>
                  <td>
                    <strong>
                      For our affiliates’ everyday business purposes—
                    </strong>
                    <p>information about your creditworthiness</p>
                  </td>
                  <td>No</td>
                  <td>We don't share with any affiliate</td>
                </tr>
                <tr>
                  <td>
                    <strong>For our affiliates to market to you</strong>
                  </td>
                  <td>No</td>
                  <td>We don’t share with any affiliate</td>
                </tr>
                <tr>
                  <td>
                    <strong>For nonaffiliates to market to you</strong>
                  </td>
                  <td>No</td>
                  <td>
                    We don’t permit our contract partners to market to you
                  </td>
                </tr>
              </tbody>
            </table>

            <h3>Who we are</h3>
            <h4>Who is providing this Privacy Policy?</h4>
            <p>
              This privacy notice is provided by Prytany LLC in connection with
              its provision of Prytany, and is applicable to your personal
              activity on the Prytany Platform.
            </p>

            <h3>What we do</h3>
            <h4>How does Prytany protect my personal information?</h4>
            <p>
              To protect your personal information from unauthorized access and
              use, we use security measures that comply with federal and
              applicable state law. These measures include computer safeguards
              and secured files and buildings.
            </p>
            <h4>How does Prytany collect my personal information?</h4>
            <p>We collect your personal information, for example, when you</p>
            <ul>
              <li>create a Prytany Account or provide account information</li>
              <li>give us your contact information</li>
              <li>
                use the Prytany Platform to make a political contribution or
                pledge
              </li>
              <li>
                use the ID card scanning application provided on the Prytany
                website
              </li>
            </ul>
            <h4>Why can’t I limit all sharing?</h4>
            <p>Federal law gives you the right to limit only</p>
            <ul>
              <li>
                sharing for affiliates’ everyday business purposes — information
                about your creditworthiness
              </li>
              <ul>
                <li>
                  We do not share your personal information with any affiliate
                </li>
              </ul>
              <li>affiliates from using your information to market to you</li>
              <ul>
                <li>
                  We do not share your personal information with any affiliate
                </li>
              </ul>
              <li>sharing for nonaffiliates to market to you</li>
              <ul>
                <li>We do not permit our contract partners to market to you</li>
              </ul>
            </ul>
            <p>
              State laws and individual companies may give you additional rights
              to limit sharing. See below for more information on your rights
              under state law.
            </p>

            <h3>Definitions</h3>
            <dl>
              <dt>Affiliates</dt>
              <dd>Companies related by common ownership or control.</dd>
              <dt>Nonaffiliates</dt>
              <dd>
                Companies not related by common ownership or control. They can
                be financial and nonfinancial companies.
              </dd>
              <ul>
                <li>
                  <dd>
                    Nonaffiliates with which we share personal information
                    include service providers that perform service or functions
                    on our behalf.
                  </dd>
                </li>
              </ul>
              <dt>Joint Marketing</dt>
              <dd>
                A formal agreement between nonaffiliated financial companies
                that together market financial products or service to you.
              </dd>
              <ul>
                <li>
                  <dd>We have no joint marketing partners.</dd>
                </li>
              </ul>
            </dl>

            <h3>Other important information</h3>
            <p>We do not transfer personal information to other countries.</p>
            <p>
              <strong>California:</strong> If your Prytany Account has a
              California mailing address, we will not share personal information
              we collect about you except to the extent permitted under
              California law.
            </p>
            <p>
              <strong>Vermont:</strong> If your Prytany Account has a Vermont
              mailing address, we will not share personal information we collect
              about you with non-affiliates unless the law allows or you provide
              authorization.
            </p>
            <div className="mobileStore">
              <div className="appleAppStoreIcon">
                <img src={iphoneAppStoreImage} alt="" />
              </div>
              <div className="androidAppStoreIcon">
                <img src={androidAppStoreImage} alt="" />
              </div>
            </div>
            <br />
            <p>
              Prytany is a political contribution and messaging platform
              provided by Prytany LLC © 2018 Prytany LLC.
            </p>
            <p>Copyright © Prytany. Patent pending.</p>
          </div>
        </div>
      </div>
    </section>
  </div>
);

export default PrivacyPolicyPage;
