import { deleteGroup, groupDeleteReset as reset } from './groupDelete.actions';
import type { DeleteGroupDeletePayload } from '../../../services/RoRGroupsApiProvider';
import type { DefaultResponse } from '../../../services/RoRApiProvider';

export const mapStateToProps = state => {
  return {
    groupDeleteData: state.groupDelete.data,
    groupDeleteError: state.groupDelete.error,
    groupDeleteIsLoading: state.groupDelete.isLoading,
  };
};

export const mapDispatchToProps = dispatch => {
  return {
    groupDelete(payload: DeleteGroupDeletePayload) {
      dispatch(deleteGroup(payload));
    },
    groupDeleteReset() {
      dispatch(reset());
    },
  };
};

export type GroupDeleteMapStateToProps = {
  groupDeleteData: DefaultResponse,
  groupDeleteError: any,
  groupDeleteIsLoading: boolean,
};

export type GroupDeleteMapDispatchToProps = {
  groupDelete: (payload: DeleteGroupDeletePayload) => void,
  groupDeleteReset: () => void,
};

export default {
  mapStateToProps,
  mapDispatchToProps,
};
