// @flow
import moment from 'moment';
import { createAction } from 'redux-actions';
import {
  SIGN_UP_ERROR,
  SIGN_UP_LOADING,
  SIGN_UP_SUCCESS,
  SIGN_UP_RESET,
} from './signUp.constants';
import { instance as rorApiProvider } from '../../../services/RoRApiProvider';
import type {
  CampaignManagerSignUpPayload,
  PostSignUpPayload,
} from '../../../services/RoRApiProvider';
import _get from 'lodash/get';
import { clientStore } from '../../clientStore';

export const signUpError = createAction(SIGN_UP_ERROR);

export const signUpLoading = createAction(SIGN_UP_LOADING);

export const signUpSuccess = createAction(SIGN_UP_SUCCESS);

export const signUpReset = createAction(SIGN_UP_RESET);

export const signUp = (
  payload: CampaignManagerSignUpPayload | SignUpActionPayload,
) => {
  return async (dispatch: Function) => {
    try {
      let response;
      const roles = _get(clientStore.getState(), `listRoles.data.roles`);
      const campaignManagerRoleId = _get(
        roles.find((role: any) => role.code === 'campaign_manager'),
        'id',
      );

      dispatch(signUpLoading());

      // From Campaign Manager SignUp page, key "role_id" is present as Int
      if (payload.role_id === campaignManagerRoleId) {
        //$FlowFixMe
        response = await rorApiProvider.signUp(payload);
      } else {
        //$FlowFixMe
        const apiPayload = parseSignUpPayload(payload);

        response = await rorApiProvider.signUp(apiPayload);
      }

      dispatch(signUpSuccess(response));
    } catch (error) {
      dispatch(signUpError(error));
    }
  };
};

function parseSignUpPayload(payload: SignUpActionPayload): PostSignUpPayload {
  let parsedPayload: PostSignUpPayload = {
    email: payload.email,
    password: payload.password,
    role_id: parseInt(payload.userRoleId),
    first_name: payload.name,
    last_name: payload.lastName,
    dob: moment(payload.dateOfBirth).format('MM/DD/YYYY'),
    telephone: payload.telephone,
    state_id: parseInt(payload.state),
    city: payload.city,
    zip: payload.zip,
    address1: payload.addressLine1,
    address2: payload.addressLine2,
    biography: payload.biography,
    public_profile: !!payload.publicProfile,
    entity_interests_attributes: payload.interests.map(interestId => ({
      interest_id: parseInt(interestId),
    })),
    profile_image: payload.profilePicture,
    'g-recaptcha-response': payload.recaptchaToken,
    invited_by_group_id: payload.invitedByGroupId,
  };

  if (payload.campaignManagers && payload.campaignManagers.length) {
    parsedPayload.cm_invitations_attributes = payload.campaignManagers.map(
      email => ({ email: email.toString().trim() }),
    );
  }

  if (payload.fecNumber) {
    parsedPayload.fec_number = payload.fecNumber;
  }

  if (payload.middleInitial) {
    parsedPayload.middle_initial = payload.middleInitial;
  }

  return parsedPayload;
}

export type SignUpActionPayload = {
  userRoleId: string,
  // Basics
  email: string,
  name: string,
  lastName: string,
  middleInitial?: string,
  dateOfBirth: Date,
  password: string,
  passwordConfirm: string,
  fecNumber?: string,
  recaptchaToken: string,
  // Profile
  campaignManagers?: string[],
  telephone: string,
  addressLine1: string,
  addressLine2: string,
  biography: string,
  city: string,
  zip: string,
  interests: string[],
  publicProfile?: string,
  state: string,
  termsAndConditions: string[],
  profilePicture: File | null,
  invitedByGroupId: string,
};
