import {
  accountSsaFormDataReset as reset,
  getSsaFormData as getFormData,
} from './accountSsaFormData.actions';
import type { DefaultResponse } from '../../../services/RoRApiProvider';
import type {
  PostGetSsaFormDataPayload,
  PostGetSsaFormDataResponse,
} from '../../../services/RoRUsersApiProvider';

export const mapStateToProps = state => {
  return {
    accountSsaFormDataData: state.accountSsaFormData.data,
    accountSsaFormDataError: state.accountSsaFormData.error,
    accountSsaFormDataIsLoading: state.accountSsaFormData.isLoading,
  };
};

export const mapDispatchToProps = dispatch => {
  return {
    getSsaFormData(payload: PostGetSsaFormDataPayload) {
      dispatch(getFormData(payload));
    },
    accountSsaFormDataReset() {
      dispatch(reset());
    },
  };
};

export type AccountSsaFormDataMapStateToProps = {
  accountSsaFormDataData: PostGetSsaFormDataResponse | null,
  accountSsaFormDataError: any,
  accountSsaFormDataIsLoading: boolean,
};

export type AccountSsaFormDataMapDispatchToProps = {
  getSsaFormData: (payload: PostGetSsaFormDataPayload) => void,
  accountSsaFormDataReset: () => void,
};

export default {
  mapStateToProps,
  mapDispatchToProps,
};
