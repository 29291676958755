// @flow
import React from 'react';
import CardHeader from '../CardHeader/CardHeader';
import './CardRaceThought.scss';

type Props = {
  userAvatar: string,
  userIsCandidate: boolean,
  userFullName: string,
  userId: number,
  thought: string,
  date: string,
};

const CardRaceThought = (props: Props) => {
  return (
    <div className="row card-race-thought">
      <div className="col-12 px-0 mx-0">
        <CardHeader
          userId={props.userId}
          userAvatar={props.userAvatar}
          userIsCandidate={props.userIsCandidate}
          userFullName={props.userFullName}
          date={props.date}
        />
        <div className="row align-items-center">
          <div className="col-2 px-1 text-center pt-2">
            <img src="/images/icon_quote.png" alt="" className="img-fluid" />
          </div>
          <div className="col-10 pr-4">
            <p className="f-nunito fs-18">{props.thought}</p>
          </div>
        </div>
      </div>
    </div>
  );
};

export default CardRaceThought;
