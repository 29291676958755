export const ACCOUNT_FEED_LOADING = '@@prytany/global/ACCOUNT_FEED_LOADING';
export const ACCOUNT_FEED_SUCCESS = '@@prytany/global/ACCOUNT_FEED_SUCCESS';
export const ACCOUNT_FEED_ERROR = '@@prytany/global/ACCOUNT_FEED_ERROR';
export const ACCOUNT_FEED_RESET = '@@prytany/global/ACCOUNT_FEED_RESET';
export const ACCOUNT_FEED_LIKE_POST = '@@prytany/global/ACCOUNT_FEED_LIKE_POST';
export const ACCOUNT_FEED_UNLIKE_POST =
  '@@prytany/global/ACCOUNT_FEED_UNLIKE_POST';
export const ACCOUNT_FEED_SHARE_POST =
  '@@prytany/global/ACCOUNT_FEED_SHARE_POST';
export const ACCOUNT_FEED_NEXT_PAGE = '@@prytany/global/ACCOUNT_FEED_NEXT_PAGE';
