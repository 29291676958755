// @flow
import React from 'react';
import classNames from 'classnames';
import Modal from '../Modal/Modal';
import GroupDelete from '../GroupDelete/GroupDelete';

type Props = {
  isOpen: boolean,
  onCloseRequest: Function,
  onSuccess?: Function,
  onError?: Function,
  className?: string,
};

class ModalGroupDelete extends React.Component<Props, {}> {
  render() {
    const {
      isOpen,
      onCloseRequest,
      className,
      onSuccess,
      onError,
      ...rest
    } = this.props;

    return (
      <Modal
        contentLabel="Rename Group"
        isOpen={isOpen}
        onCloseRequest={onCloseRequest}
        className={this.getClassName()}
        noPaddingContent
        size="small"
        {...rest}>
        {isOpen ? (
          <GroupDelete
            onSuccess={onSuccess}
            onCancel={onCloseRequest}
            onError={onError}
          />
        ) : null}
      </Modal>
    );
  }

  getClassName() {
    return classNames({
      'modal-rename-group': true,
      [this.props.className || '']: this.props.className,
    });
  }
}

export default ModalGroupDelete;
