// @flow
import * as React from 'react';
import classNames from 'classnames';
import './TabPanel.scss';

type Props = {
  className?: string,
  items: Array<string>,
  initialActiveIndex?: number | null,
  onTabChange: Function,
  centered?: boolean,
  noPadding?: boolean,
};

type State = {
  active: number,
};

class TabPanel extends React.Component<Props, State> {
  constructor(props: Props) {
    super(props);

    this.state = {
      active: this.props.initialActiveIndex || 0,
    };
  }

  render() {
    return (
      <div className={this.getClassNames()}>
        <div className="container-fluid">
          <ul className="row fix-width scrollable tab-panel__container tab-panel__list">
            {this.props.items.map((item, index) => {
              if (item instanceof Object) {
                if (item.regLink && item.regLink === true) {
                  return (
                    <li className="titem">
                      <a
                        className="tab-panel__button tab-panel__button--link"
                        href={item.path}>
                        {item.label}
                      </a>
                    </li>
                  );
                } else {
                  return (
                    <li
                      className={
                        index === this.state.active
                          ? 'tab-panel__item tab-panel__item--active'
                          : 'tab-panel__item'
                      }
                      key={index}>
                      <button
                        className="tab-panel__button"
                        onClick={() => this.handleClickButton(index)}
                        type="button">
                        {item.label}
                      </button>
                    </li>
                  );
                }
              } else {
                return (
                  <li
                    className={
                      index === this.state.active
                        ? 'tab-panel__item tab-panel__item--active'
                        : 'tab-panel__item'
                    }
                    key={index}>
                    <button
                      className="tab-panel__button"
                      onClick={() => this.handleClickButton(index)}
                      type="button">
                      {item}
                    </button>
                  </li>
                );
              }
            })}
          </ul>
        </div>
      </div>
    );
  }

  getClassNames() {
    return classNames({
      'tab-panel': true,
      'tab-panel--centered': this.props.centered,
      'tab-panel--no-padding': this.props.noPadding,
      [this.props.className || '']: this.props.className,
    });
  }

  handleClickButton = (index: number) => {
    this.setState(
      () => ({ active: index }),
      () => {
        if (this.props.onTabChange) {
          this.props.onTabChange(this.state.active);
        }
      },
    );
  };
}

export default TabPanel;
