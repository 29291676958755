//@flow
import React from 'react';
import Button from '../Button/Button';
import Input from '../Input/Input';
import Select from '../Select/Select';
import Toggle from '../Toggle/Toggle';
import accountProfile from '../../redux/modules/accountProfile/accountProfile.containers';
import { connect } from 'react-redux';
import type {
  AccountProfileMapDispatchToProps,
  AccountProfileMapStateToProps,
} from '../../redux/modules/accountProfile/accountProfile.containers';
import * as yup from 'yup';
import { Formik, FormikProps } from 'formik';
import listStates from '../../redux/modules/listStates/listStates.containers';
import accountProfileUpdate from '../../redux/modules/accountProfileUpdate/accountProfileUpdate.containers';
import './ContributeProfile.scss';
import type {
  ListStatesMapDispatchToProps,
  ListStatesMapStateToProps,
} from '../../redux/modules/listStates/listStates.containers';
import type {
  AccountProfileUpdateMapDispatchToProps,
  AccountProfileUpdateMapStateToProps,
} from '../../redux/modules/accountProfileUpdate/accountProfileUpdate.containers';
import { message, Icon } from 'antd';

type Props = AccountProfileMapStateToProps &
  AccountProfileMapDispatchToProps &
  AccountProfileUpdateMapStateToProps &
  AccountProfileUpdateMapDispatchToProps &
  ListStatesMapStateToProps &
  ListStatesMapDispatchToProps & {
    onGoBackRequest: Function,
    onSuccess: Function,
  };

type State = {};

class ContributeProfile extends React.Component<Props, State> {
  componentDidMount() {
    this.props.getListStates();
  }

  componentDidUpdate(prevProps: Props, prevState: State) {
    if (
      !prevProps.accountProfileUpdateError &&
      this.props.accountProfileUpdateError
    ) {
      message.error(
        this.props.accountProfileUpdateError.localMessage ||
          'An error has occurred and your profile was not updated.',
      );
    }

    if (
      !prevProps.accountProfileUpdateData &&
      this.props.accountProfileUpdateData
    ) {
      this.props.accountProfileUpdateReset();
      this.props.onSuccess();
    }
  }

  getFormikConfigProps = () => {
    const {
      address1,
      address2,
      city,
      state,
      zip_code,
      dob,
      telephone,
      occupation,
      employer,
    } = this.props.accountProfileData.user;
    return {
      initialValues: {
        address1,
        address2,
        city,
        state_id: state ? state.id.toString() : null,
        zip_code,
        dob,
        telephone,
        occupation,
        employer,
      },
      validationSchema: yup.object().shape({
        address1: yup
          .string()
          .ensure()
          .trim()
          .required('This field is required'),
        city: yup
          .string()
          .ensure()
          .trim()
          .required('This field is required'),
        state_id: yup
          .string()
          .ensure()
          .trim()
          .required('This field is required'),
        zip_code: yup
          .string()
          .ensure()
          .trim()
          .required('This field is required')
          .min(4, 'Minimum 4 characters.')
          .max(10, 'Maximum 10 characters allowed'),
        dob: yup
          .string()
          .ensure()
          .trim()
          .required('This field is required'),
        occupation: yup
          .string()
          .ensure()
          .trim()
          .required('This field is required'),
        employer: yup
          .string()
          .ensure()
          .trim()
          .required('This field is required'),
      }),
      onSubmit: this.handleValidSubmit,
    };
  };

  handleValidSubmit = values => {
    this.props.updateAccountProfile(values);
  };

  render() {
    const { listStatesData } = this.props;
    const stateOptions = listStatesData
      ? listStatesData.states.map(state => ({
          label: state.name,
          value: state.id.toString(),
        }))
      : [];

    return (
      <div className="contribute-profile">
        <div className="contribute-profile__head">
          <div className="contribute-profile__button-change-wrapper">
            <Button
              className="contribute-profile__button-change"
              size="small"
              type="button"
              buttonType="link"
              onClick={this.props.onGoBackRequest}>
              &lt; Change
            </Button>
          </div>
          <h4 className="contribute-profile__title">Contact Information</h4>
          <div className="contribute-profile__head-transparent-block" />
        </div>
        <Formik {...this.getFormikConfigProps()}>
          {({ touched, errors, handleSubmit }: FormikProps) => (
            <form className="" onSubmit={handleSubmit}>
              <div className="contribute-profile__body px-5 pt-3">
                <div className="row justify-content-center">
                  <div className="col-12 col-md-5 pb-4 px-0 px-md-2">
                    <Input
                      className="pt-1"
                      label={
                        <label htmlFor="address1">
                          Address Line 1{' '}
                          <span className="required-asterisk">*</span>
                        </label>
                      }
                      size="small"
                      name="address1"
                      type="text"
                      error={
                        touched.address1 && errors.address1
                          ? errors.address1
                          : ''
                      }
                    />
                  </div>
                  <div className="col-12 col-md-5 pb-4 px-0 px-md-2">
                    <Input
                      className="pt-1"
                      label={<label htmlFor="address2">Address Line 2</label>}
                      size="small"
                      name="address2"
                      type="text"
                      error={
                        touched.address1 && errors.address2
                          ? errors.address2
                          : ''
                      }
                    />
                  </div>
                  <div className="col-12 col-md-5 pb-4 px-0 px-md-2">
                    <Input
                      className="pt-1"
                      label={
                        <label htmlFor="city">
                          City <span className="required-asterisk">*</span>
                        </label>
                      }
                      size="small"
                      name="city"
                      type="text"
                      error={touched.city && errors.city ? errors.city : ''}
                    />
                  </div>
                  <div className="col-12 col-md-3 pb-4 px-0 px-md-2">
                    <Select
                      className="mt-n1"
                      label={
                        <label htmlFor="state">
                          State <span className="required-asterisk">*</span>
                        </label>
                      }
                      name="state_id"
                      options={stateOptions}
                      error={
                        touched.state_id && errors.state_id
                          ? errors.state_id
                          : ''
                      }
                    />
                  </div>
                  <div className="col-12 col-md-2 pb-4 px-0 px-md-2">
                    <Input
                      className="pt-1"
                      label={
                        <label htmlFor="zip_code">
                          Zip <span className="required-asterisk">*</span>
                        </label>
                      }
                      size="small"
                      name="zip_code"
                      type="text"
                      error={
                        touched.zip_code && errors.zip_code
                          ? errors.zip_code
                          : ''
                      }
                    />
                  </div>
                  <div className="col-12 col-md-5 pb-4 px-0 px-md-2">
                    <Input
                      className="pt-1"
                      label={
                        <label htmlFor="dob">
                          Date of Birth{' '}
                          <span className="required-asterisk">*</span>
                        </label>
                      }
                      size="small"
                      name="dob"
                      type="date"
                      maxDate={new Date()}
                      placeholder="MM/DD/YYYY"
                      error={touched.dob && errors.dob ? errors.dob : ''}
                    />
                  </div>
                  <div className="col-12 col-md-5 pb-4 px-0 px-md-2">
                    <Input
                      className="pt-1"
                      label={
                        <label htmlFor="telephone">Telephone Number</label>
                      }
                      size="small"
                      name="telephone"
                      type="text"
                      error={
                        touched.telephone && errors.telephone
                          ? errors.telephone
                          : ''
                      }
                    />
                  </div>
                  <div className="col-12 col-md-5 pb-4 px-0 px-md-2">
                    <Input
                      className="px-0 pt-1"
                      label={
                        <label htmlFor="occupation">
                          Occupation{' '}
                          <span className="required-asterisk">*</span>
                        </label>
                      }
                      size="small"
                      name="occupation"
                      type="text"
                      error={
                        touched.occupation && errors.occupation
                          ? errors.occupation
                          : ''
                      }
                    />
                  </div>
                  <div className="col-12 col-md-5 pb-4 px-0 px-md-2">
                    <Input
                      className="pt-1"
                      label={
                        <label htmlFor="employer">
                          Employer <span className="required-asterisk">*</span>
                        </label>
                      }
                      size="small"
                      name="employer"
                      type="text"
                      error={
                        touched.employer && errors.employer
                          ? errors.employer
                          : ''
                      }
                    />
                  </div>
                  <div className="col-12 col-md-10 pb-4 px-0">
                    <Icon type="info-circle" className="info-circle mr-1" />
                    <strong>The Federal Election Commission (FEC)</strong>{' '}
                    requires that we gather this information from you in order
                    to process your contribution
                  </div>
                </div>
              </div>
              <div className="contribute-profile__footer">
                <div className="row pt-4 pb-2">
                  <div className="col-12 col-md-7 pb-3" />
                  <div className="col-4 col-md-2 pr-1">
                    <Button
                      type="button"
                      buttonType="outline"
                      block
                      noBorder
                      onClick={this.props.onGoBackRequest}
                      size={this.props.isMobile ? 'small' : 'normal'}>
                      Cancel
                    </Button>
                  </div>
                  <div className="col-8 col-md-3 pl-1">
                    <Button
                      type="submit"
                      buttonType="primary"
                      block
                      noBorder
                      size={this.props.isMobile ? 'small' : 'normal'}
                      loading={false}>
                      Continue
                    </Button>
                  </div>
                </div>
              </div>
            </form>
          )}
        </Formik>
      </div>
    );
  }
}

const mapStateToProps = (state, ownProps) => {
  return {
    ...accountProfile.mapStateToProps(state),
    ...accountProfileUpdate.mapStateToProps(state),
    ...listStates.mapStateToProps(state),
    ...ownProps,
  };
};

const mapDispatchToProps = dispatch => {
  return {
    ...accountProfile.mapDispatchToProps(dispatch),
    ...accountProfileUpdate.mapDispatchToProps(dispatch),
    ...listStates.mapDispatchToProps(dispatch),
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(ContributeProfile);
