// @flow
import type {
  UserDetails,
  UserListItem,
  UserListView,
  UserProfile,
} from '../services/RoRUsersApiProvider';
import _some from 'lodash/some';
import RoleCode from '../constants/RoleCode';
import type { AuthenticatedUser } from '../services/RoRApiProvider';
import GroupType from '../constants/GroupType';

type UserType =
  | AuthenticatedUser
  | UserDetails
  | UserProfile
  | UserListView
  | UserListItem
  | null;

const hasAnyRole = (
  user,
  ...roleCodes: $Values<typeof RoleCode>[]
): boolean => {
  return !!user && _some(roleCodes, roleCode => roleCode === user.role.code);
};

export const isCitizen = (user: UserType) => {
  return hasAnyRole(user, RoleCode.CITIZEN);
};

export const isCandidate = (user: UserType) => {
  return hasAnyRole(user, RoleCode.CANDIDATE);
};

export const isPoliticalParty = (user: UserType) => {
  return hasAnyRole(user, RoleCode.POLITICAL_PARTY);
};

export const isTraditionalPac = (user: UserType) => {
  return hasAnyRole(user, RoleCode.TRADITIONAL_PAC);
};

export const isLeadershipPac = (user: UserType) => {
  return hasAnyRole(user, RoleCode.LEADERSHIP_PAC);
};

export const isSuperPac = (user: UserType) => {
  return hasAnyRole(user, RoleCode.SUPER_PAC);
};

export const isHybridPac = (user: UserType) => {
  return hasAnyRole(user, RoleCode.HYBRID_PAC);
};

export const isPac = (user: UserType) => {
  return (
    isTraditionalPac(user) ||
    isLeadershipPac(user) ||
    isSuperPac(user) ||
    isHybridPac(user)
  );
};

export const hasDashboard = (user: UserType) => {
  return isCandidate(user) || isPoliticalParty(user) || isPac(user);
};

/**
 * Returns true if user make any type of donation
 */
export const canDonate = (user: UserType) => {
  return !isSuperPac(user);
};

export const canAcceptPledge = (user: UserType) => {
  return !isPoliticalParty(user) && !isPac(user);
};

export const canJoinGroup = (user: UserType) => {
  return !isSuperPac(user);
};

export const allowedGroupType = (user: UserType) => {
  return isSuperPac(user) || isHybridPac(user)
    ? GroupType.CAMPAIGN
    : GroupType.GROUP;
};

export const receivingContributionLimit = (user: UserType) => {
  const roleCode: any = user && user.role && user.role.code;
  return (
    {
      [RoleCode.CANDIDATE]: 2800,
      [RoleCode.POLITICAL_PARTY]: 35500,
      [RoleCode.TRADITIONAL_PAC]: 5000,
      [RoleCode.LEADERSHIP_PAC]: 5000,
    }[roleCode] || Infinity
  );
};
