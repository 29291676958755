import {
  groupDenyRequest as approveRequest,
  groupDenyRequestReset as reset,
} from './groupDenyRequest.actions';
import type { DefaultResponse } from '../../../services/RoRApiProvider';

export const mapStateToProps = state => {
  return {
    groupDenyRequestData: state.groupDenyRequest.data,
    groupDenyRequestError: state.groupDenyRequest.error,
    groupDenyRequestIsLoading: state.groupDenyRequest.isLoading,
  };
};

export const mapDispatchToProps = dispatch => {
  return {
    groupDenyRequest(payload) {
      dispatch(approveRequest(payload));
    },
    groupDenyRequestReset() {
      dispatch(reset());
    },
  };
};

export type GroupDenyRequestMapStateToProps = {
  groupDenyRequestData: DefaultResponse,
  groupDenyRequestError: any,
  groupDenyRequestIsLoading: boolean,
};

export type GroupDenyRequestMapDispatchToProps = {
  groupDenyRequest: Function,
  groupDenyRequestReset: Function,
};

export default {
  mapStateToProps,
  mapDispatchToProps,
};
