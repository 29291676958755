import {
  verifyAch as verifyAchAccount,
  contributionVerifyAchReset as reset,
} from './contributionVerifyAch.actions';
import type { PostVerifyAchPayload } from '../../../services/RoRContributionsApiProvider';
import type { DefaultResponse } from '../../../services/RoRApiProvider';

export const mapStateToProps = state => {
  return {
    contributionVerifyAchData: state.contributionVerifyAch.data,
    contributionVerifyAchError: state.contributionVerifyAch.error,
    contributionVerifyAchIsLoading: state.contributionVerifyAch.isLoading,
  };
};

export const mapDispatchToProps = dispatch => {
  return {
    verifyAch(payload: PostVerifyAchPayload) {
      dispatch(verifyAchAccount(payload));
    },
    contributionVerifyAchReset() {
      dispatch(reset());
    },
  };
};

export type ContributionVerifyAchMapStateToProps = {
  contributionVerifyAchData: DefaultResponse,
  contributionVerifyAchError: any,
  contributionVerifyAchIsLoading: boolean,
};

export type ContributionVerifyAchDispatchToProps = {
  verifyAch: (payload: PostVerifyAchPayload) => void,
  contributionVerifyAchReset: () => void,
};

export default {
  mapStateToProps,
  mapDispatchToProps,
};
