// @flow
import React from 'react';
import { connect } from 'react-redux';
import classNames from 'classnames';
import _get from 'lodash/get';
import _throttle from 'lodash/throttle';
import Avatar from '../Avatar/Avatar';
import Button from '../Button/Button';
import Currency from '../Currency/Currency';
import Input from '../Input/Input';
import Table from '../Table/Table';
import Modal from '../Modal/Modal';
import userList from '../../redux/modules/userList/userList.containers';
import groupSendInvitation from '../../redux/modules/groupSendInvitation/groupSendInvitation.containers';
import type { GroupDetails } from '../../services/RoRGroupsApiProvider';
import type {
  UserListMapDispatchToProps,
  UserListMapStateToProps,
} from '../../redux/modules/userList/userList.containers';
import type {
  GroupSendInvitationMapDispatchToProps,
  GroupSendInvitationMapStateToProps,
} from '../../redux/modules/groupSendInvitation/groupSendInvitation.containers';
import './ModalInvitePeople.scss';
import { isCandidate, isCitizen } from '../../utilities/authorization';
import isPrytanyVerified from '../../utilities/isPrytanyVerified';
import type { UserListItem } from '../../services/RoRUsersApiProvider';

type Props = UserListMapDispatchToProps &
  UserListMapStateToProps &
  GroupSendInvitationMapDispatchToProps &
  GroupSendInvitationMapStateToProps & {
    isOpen: boolean,
    onAfterOpen?: Function,
    onCloseRequest?: Function,
    className?: string,
    onSuccess: Function,
    onError: (error: string) => void,
    groupDetails: GroupDetails,
  };

type State = {
  searchValue: string,
  tableSelectedRows: any[],
  tableSelectedRowKeys: number[],
};

const USER_PAGE_SIZE = 6;

class ModalInvitePeople extends React.Component<Props, State> {
  constructor(props: Props) {
    super(props);

    this.state = {
      searchValue: '',
      tableSelectedRows: [],
      tableSelectedRowKeys: [],
    };
  }

  componentWillMount() {
    this.props.userListReset();
    this.props.groupSendInvitationReset();
  }

  componentDidMount() {
    this.fetchUsers();
  }

  componentDidUpdate(prevProps: Props) {
    if (!prevProps.isOpen && this.props.isOpen) {
      this.setState(
        () => ({ searchValue: '' }),
        () => {
          this.fetchUsers();
          this.props.groupSendInvitationReset();
        },
      );
    }

    if (
      !prevProps.groupSendInvitationData &&
      this.props.groupSendInvitationData
    ) {
      this.props.onSuccess();
      this.props.onCloseRequest();
    }

    if (
      !prevProps.groupSendInvitationError &&
      this.props.groupSendInvitationError
    ) {
      this.props.onError(
        this.props.groupSendInvitationError.localMessage ||
          'An error has occurred and the invitations were not send.',
      );
    }
  }

  render() {
    const { className, onSuccess, onError, ...rest } = this.props;

    return (
      <Modal
        contentLabel="Invite people"
        className={this.getClassName()}
        noPaddingContent
        size="normal"
        {...rest}>
        {this.props.isOpen ? this.renderContent() : null}
      </Modal>
    );
  }

  renderContent() {
    return (
      <React.Fragment>
        <h2 className="modal-component__title text-center mt-3 mb-3">
          Invite people to "{this.props.groupDetails.name}"
        </h2>
        <div className="row pb-3 pt-3 fix-width modal-invite-people__body">
          <Input
            className="col-8 offset-2 px-0 mb-0"
            size="small"
            label=""
            placeholder=""
            name="search"
            type="text"
            icon="/images/icon_lens.png"
            value={this.state.searchValue}
            useFormik={false}
            onChange={_throttle(this.handleSearchUser, 750)}
          />
          <div className="col-12">
            <Table {...this.getTableProps()} />
          </div>
        </div>
        <div className="row py-3 fix-width justify-content-center align-items-center">
          <Button
            onClick={this.props.onCloseRequest}
            buttonType="outline"
            type="button"
            className="col-8 col-md-3 col-lg-2 mx-2 mb-3 mb-md-0"
            size="small"
            disabled={this.props.groupSendInvitationIsLoading}>
            Cancel
          </Button>
          <Button
            onClick={this.handleSendInvitations}
            buttonType="primary"
            type="button"
            className="col-8 col-md-3 col-lg-2 mx-2"
            size="small"
            disabled={!this.state.tableSelectedRowKeys.length}
            loading={this.props.groupSendInvitationIsLoading}>
            Send Invitations
          </Button>
        </div>
      </React.Fragment>
    );
  }

  getClassName() {
    return classNames({
      'modal-invite-people': true,
      [this.props.className || '']: this.props.className,
    });
  }

  getTableProps() {
    return {
      dataSource: this.getTableData(),
      columns: this.getTableColumns(),
      pagination:
        this.props.userListData && this.props.userListData.total_pages > 1
          ? {
              total: this.props.userListData.total_pages * USER_PAGE_SIZE,
              current: this.props.userListData.current_page,
              pageSize: USER_PAGE_SIZE,
            }
          : false,
      loading: this.props.userListIsLoading,
      onChange: this.handleTableChange,
      rowSelection: {
        onChange: this.handleTableRowSelection,
        getCheckboxProps: (record: any) => ({
          disabled: false,
          name: record.key,
        }),
        selectedRowKeys: this.state.tableSelectedRowKeys,
      },
    };
  }

  getTableData() {
    const users = _get(this.props, 'userListData.data.users', []);

    return users.map(user => ({
      key: user.id,
      name: `${user.first_name} ${user.last_name}`.trim(),
      state: user.state ? user.state.name : '',
      sharedInterests: user.shared_interests || 0,
      contributed: (user.donations.contributed || 0) / 100,
      user,
    }));
  }

  getTableColumns() {
    return [
      {
        title: 'NAME',
        dataIndex: 'name',
        key: 'name',
        defaultSortOrder: 'ascend',
        render: (name: string, item: { user: UserListItem }) => {
          return (
            <React.Fragment>
              <Avatar
                isCandidate={isCandidate(item.user)}
                verifiedLevel={item.user.verified_level}
                verifiedDetail={item.user.verified_detail}
                className="dashboard-page__table-cell__name-avatar"
                source={item.user.profile_image}
                type="tiny"
              />
              <span>{name}</span>
            </React.Fragment>
          );
        },
      },
      {
        title: 'STATE',
        dataIndex: 'state',
        key: 'state',
      },
      {
        title: 'SHARED INTERESTS',
        dataIndex: 'sharedInterests',
        key: 'sharedInterests',
      },
      {
        title: 'CONTRIBUTED',
        dataIndex: 'contributed',
        key: 'contributed',
        render: (amount: number) => <Currency amount={amount} />,
      },
    ];
  }

  handleTableChange = (pagination: any, filters: any, sorter: any) => {
    // TODO: add sorting logic once the server supports it
    this.fetchUsers(pagination.current);
  };

  fetchUsers(page: number = 1) {
    this.props.getUserList({
      page,
      limit: USER_PAGE_SIZE,
      name: this.state.searchValue,
      exclude_group: this.props.groupDetails.id,
    });
  }

  handleSendInvitations = () => {
    this.props.groupSendInvitation({
      userIds: this.state.tableSelectedRowKeys,
      groupId: this.props.groupDetails.id,
    });
    this.setState(() => ({ tableSelectedRows: [], tableSelectedRowKeys: [] }));
  };

  handleSearchUser = (inputData: { name: string, value: string }) => {
    this.setState(
      () => ({ searchValue: inputData.value }),
      () => {
        this.fetchUsers();
      },
    );
  };

  handleTableRowSelection = (
    selectedRowKeys: number[],
    selectedRows: any[],
  ) => {
    this.setState(() => ({
      tableSelectedRows: selectedRows,
      tableSelectedRowKeys: selectedRowKeys,
    }));
  };
}

const mapStateToProps = (state, ownProps) => {
  return {
    ...userList.mapStateToProps(state),
    ...groupSendInvitation.mapStateToProps(state),
    ...ownProps,
  };
};

const mapDispatchToProps = dispatch => {
  return {
    ...userList.mapDispatchToProps(dispatch),
    ...groupSendInvitation.mapDispatchToProps(dispatch),
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(ModalInvitePeople);
