// @flow
import * as React from 'react';
import { connect } from 'react-redux';
import * as _get from 'lodash/get';
import Table from '../../components/Table/Table';
import Currency from '../../components/Currency/Currency';
import Avatar from '../../components/Avatar/Avatar';
import Link from '../../components/Link/Link';
import { URL_USER_PUBLIC_PROFILE } from '../../config/urls';
import './PublicProfileContributionsTab.scss';
import type {
  UserProfileContributionsMapDispatchToProps,
  UserProfileContributionsMapStateToProps,
} from '../../redux/modules/userProfileContributions/userProfileContributions.containers';
import userProfileContributions from '../../redux/modules/userProfileContributions/userProfileContributions.containers';
import { isCitizen } from '../../utilities/authorization';
import isPrytanyVerified from '../../utilities/isPrytanyVerified';

type Props = UserProfileContributionsMapStateToProps &
  UserProfileContributionsMapDispatchToProps & {
    fetchAfterMount: boolean,
    userId: number,
  };
type State = any;

const PAGE_SIZE = 10;

class PublicProfileContributions extends React.Component<Props, State> {
  constructor(props: Props) {
    super(props);
  }

  componentDidMount() {
    if (this.props.fetchAfterMount) {
      this.props.profileContributionsReset();
      this.fetchContributions();
    }
  }

  render() {
    return (
      <div className="contributions content fix-width">
        <div className="row">
          <div className="col-12">
            <div className="contributions__table-wrapper">
              <Table {...this.getTableProps()} />
            </div>
          </div>
        </div>
      </div>
    );
  }

  getTableProps() {
    const totalPages = _get(
      this.props,
      'profileContributionsData.total_pages',
      0,
    );
    const currentPage = _get(
      this.props,
      'profileContributionsData.current_page',
      1,
    );
    const dataSource = _get(
      this.props,
      'profileContributionsData.data.contributed',
      [],
    ).map(contribution => ({
      ...contribution,
      key: contribution.id.toString(),
    }));

    console.log('dataSource', dataSource);

    return {
      dataSource,
      columns: this.getTableColumns(),
      loading: this.props.profileContributionsIsLoading,
      pagination:
        totalPages > 1
          ? {
              total: totalPages * PAGE_SIZE,
              current: currentPage,
              pageSize: PAGE_SIZE,
            }
          : false,
      onChange: this.handleTableChange,
    };
  }

  getTableColumns() {
    return [
      {
        title: 'NAME',
        dataIndex: 'name',
        key: 'name',
        render: (text: string, data: any) => (
          <React.Fragment key={data.contributor.id}>
            <Avatar
              className="dashboard-page__table-cell__name-avatar"
              source={data.contributor.profile_image}
              type="tiny"
              verifiedLevel={data.contributor.verified_level}
              verifiedDetail={data.contributor.verified_detail}
            />
            <Link
              href={URL_USER_PUBLIC_PROFILE.replace(
                ':id',
                data.contributor.id,
              )}>
              <span className="dashboard-page__table-cell__name-text">{`${
                data.contributor.first_name
              } ${data.contributor.last_name}`}</span>
            </Link>
          </React.Fragment>
        ),
      },
      {
        title: 'STATE',
        dataIndex: 'state',
        key: 'state',
        render: (text: string, data: any) => (
          <React.Fragment key={data.contributor.id}>
            <span className="dashboard-page__table-cell__name-text">{`${
              data.contributor.state.name
            }`}</span>
          </React.Fragment>
        ),
      },
      {
        title: 'AMOUNT',
        dataIndex: 'amount',
        key: 'amount',
        render: (amount: number) => <Currency amount={amount / 100} />,
      },
    ];
  }

  fetchContributions(page: number = 1, limit: number = PAGE_SIZE) {
    this.props.getUserProfileContributions({
      page,
      limit,
      id: this.props.userId,
    });
  }

  handleTableChange = (pagination: any, filters: any, sorter: any) => {
    // TODO: add sorting logic once the server supports it
    this.fetchContributions(pagination.current);
  };
}

const mapStateToProps = (state, ownProps) => {
  return {
    ...userProfileContributions.mapStateToProps(state),
    ...ownProps,
  };
};

const mapDispatchToProps = dispatch => {
  return {
    ...userProfileContributions.mapDispatchToProps(dispatch),
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(PublicProfileContributions);
