import {
  addRaceThought,
  raceThoughtAddReset as reset,
} from './raceThoughtAdd.actions';
import type { RaceGroup } from '../../../services/RoRRacesApiProvider';

export const mapStateToProps = state => {
  return {
    raceThoughtAddData: state.raceThoughtAdd.data,
    raceThoughtAddError: state.raceThoughtAdd.error,
    raceThoughtAddIsLoading: state.raceThoughtAdd.isLoading,
  };
};

export const mapDispatchToProps = dispatch => {
  return {
    createRaceThought(payload) {
      dispatch(addRaceThought(payload));
    },
    raceThoughtAddReset() {
      dispatch(reset());
    },
  };
};

export type RaceThoughtAddMapStateToProps = {
  raceThoughtAddData: { groups: RaceGroup } | null,
  raceThoughtAddError: any,
  raceThoughtAddIsLoading: boolean,
};

export type RaceThoughtAddMapDispatchToProps = {
  createRaceThought: Function,
  raceThoughtAddReset: Function,
};

export default {
  mapStateToProps,
  mapDispatchToProps,
};
