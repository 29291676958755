import {
  getAccountCandidateDirectContributions,
  accountCandidateDirectContributionsReset as reset,
} from './accountCandidateDirectContributions.actions';
import type { GetCandidateDirectContributionsResponse } from '../../../services/RoRUsersApiProvider';

export const mapStateToProps = state => {
  return {
    accountCandidateDirectContributionsData:
      state.accountCandidateDirectContributions.data,
    accountCandidateDirectContributionsError:
      state.accountCandidateDirectContributions.error,
    accountCandidateDirectContributionsIsLoading:
      state.accountCandidateDirectContributions.isLoading,
  };
};

export const mapDispatchToProps = dispatch => {
  return {
    getAccountCandidateDirectContributions(payload) {
      dispatch(getAccountCandidateDirectContributions(payload));
    },
    accountCandidateDirectContributionsReset() {
      dispatch(reset());
    },
  };
};

export type AccountCandidateDirectContributionsMapStateToProps = {
  accountCandidateDirectContributionsData: GetCandidateDirectContributionsResponse,
  accountCandidateDirectContributionsError: any,
  accountCandidateDirectContributionsIsLoading: boolean,
};

export type AccountCandidateDirectContributionsMapDispatchToProps = {
  getAccountCandidateDirectContributions: Function,
  accountCandidateDirectContributionsReset: Function,
};

export default {
  mapStateToProps,
  mapDispatchToProps,
};
