// @flow
import { createAction } from 'redux-actions';
import {
  ACCOUNT_DETAILS_ERROR,
  ACCOUNT_DETAILS_LOADING,
  ACCOUNT_DETAILS_RESET,
  ACCOUNT_DETAILS_SUCCESS,
} from './accountDetails.constants';
import { instance as rorUsersApiProvider } from '../../../services/RoRUsersApiProvider';
import type { GetUserDetailsPayload } from '../../../services/RoRUsersApiProvider';

export const accountDetailsError = createAction(ACCOUNT_DETAILS_ERROR);

export const accountDetailsLoading = createAction(ACCOUNT_DETAILS_LOADING);

export const accountDetailsSuccess = createAction(ACCOUNT_DETAILS_SUCCESS);

export const accountDetailsReset = createAction(ACCOUNT_DETAILS_RESET);

export const getAccountDetails = (payload?: GetUserDetailsPayload) => {
  return async (dispatch: Function) => {
    try {
      dispatch(accountDetailsLoading());

      const response = await rorUsersApiProvider.accountDetails(payload);

      dispatch(accountDetailsSuccess(response));
    } catch (error) {
      dispatch(accountDetailsError(error));
    }
  };
};
