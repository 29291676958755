import {
  getPostComments,
  postCommentsReset as reset,
} from './postComments.actions';

export const mapStateToProps = state => {
  return {
    postCommentsData: state.postComments.data,
    postCommentsError: state.postComments.error,
    postCommentsIsLoading: state.postComments.isLoading,
  };
};

export const mapDispatchToProps = dispatch => {
  return {
    getCommentsForPost(payload) {
      dispatch(getPostComments(payload));
    },
    postCommentsReset() {
      dispatch(reset());
    },
  };
};

export default {
  mapStateToProps,
  mapDispatchToProps,
};
