// @flow
import React from 'react';
import ReactModal from 'react-modal';
import classNames from 'classnames';
import './Modal.scss';

export type ModalProps = {
  children?: any,
  isOpen: boolean,
  onAfterOpen?: Function,
  onCloseRequest?: Function,
  className?: string,
  contentLabel?: string,
  noPaddingContent?: boolean,
  head?: any,
  size?: 'big' | 'normal' | 'small' | 'micro',
};

ReactModal.setAppElement('#root');

const Modal = (props: ModalProps) => {
  const {
    isOpen,
    onAfterOpen,
    onCloseRequest,
    className,
    head,
    noPaddingContent,
    ...rest
  } = props;
  return (
    <ReactModal
      closeTimeoutMS={150}
      isOpen={isOpen}
      onAfterOpen={onAfterOpen}
      onRequestClose={onCloseRequest}
      className={getClassName(props)}
      style={{
        overlay: {
          backgroundColor: 'rgba(94, 84, 99, 0.82)',
          overflow: 'auto',
        },
      }}
      {...rest}>
      <div className="modal-component__close" onClick={onCloseRequest}>
        <img src="/images/close-modal.png" />
      </div>
      {props.head ? (
        <div className="modal-component__head text-center">
          {typeof props.head === 'string' ? <h5>{props.head}</h5> : props.head}
        </div>
      ) : null}
      <div className="modal-component__content">{props.children}</div>
    </ReactModal>
  );
};

Modal.defaultProps = {
  size: 'normal',
};

function getClassName(props: ModalProps) {
  return classNames({
    'modal-component': true,
    [`modal-component--size-${props.size || ''}`]: true,
    'modal-component--no-padding-content': props.noPaddingContent,
    [props.className || '']: props.className,
  });
}

export default Modal;
