export const ACCOUNT_INBOX_MESSAGE_LIST_SUCCESS =
  '@@prytany/global/ACCOUNT_INBOX_MESSAGE_LIST_SUCCESS';
export const ACCOUNT_INBOX_MESSAGE_LIST_LOADING =
  '@@prytany/global/ACCOUNT_INBOX_MESSAGE_LIST_LOADING';
export const ACCOUNT_INBOX_MESSAGE_LIST_ERROR =
  '@@prytany/global/ACCOUNT_INBOX_MESSAGE_LIST_ERROR';
export const ACCOUNT_INBOX_MESSAGE_LIST_RESET =
  '@@prytany/global/ACCOUNT_INBOX_MESSAGE_LIST_RESET';
export const ACCOUNT_INBOX_MESSAGE_LIST_REMOVE_ITEM =
  '@@prytany/global/ACCOUNT_INBOX_MESSAGE_LIST_REMOVE_ITEM';
export const ACCOUNT_INBOX_MESSAGE_LIST_MARK_AS_READ_ITEM =
  '@@prytany/global/ACCOUNT_INBOX_MESSAGE_LIST_MARK_AS_READ_ITEM';
