import {
  makePledgeDonation as makeDonation,
  contributionPledgeDonationMakeReset as reset,
} from './contributionPledgeDonationMake.actions';
import type { PostMakePledgeDonationPayload } from '../../../services/RoRContributionsApiProvider';
import type { DefaultResponse } from '../../../services/RoRApiProvider';

export const mapStateToProps = state => {
  return {
    contributionPledgeDonationMakeData:
      state.contributionPledgeDonationMake.data,
    contributionPledgeDonationMakeError:
      state.contributionPledgeDonationMake.error,
    contributionPledgeDonationMakeIsLoading:
      state.contributionPledgeDonationMake.isLoading,
  };
};

export const mapDispatchToProps = dispatch => {
  return {
    makePledgeDonation(payload: PostMakePledgeDonationPayload) {
      dispatch(makeDonation(payload));
    },
    contributionPledgeDonationMakeReset() {
      dispatch(reset());
    },
  };
};

export type ContributionPledgeDonationMakeMapStateToProps = {
  contributionPledgeDonationMakeData: DefaultResponse,
  contributionPledgeDonationMakeError: any,
  contributionPledgeDonationMakeIsLoading: boolean,
};

export type ContributionPledgeDonationMakeDispatchToProps = {
  makePledgeDonation: (payload: PostMakePledgeDonationPayload) => void,
  contributionPledgeDonationMakeReset: () => void,
};

export default {
  mapStateToProps,
  mapDispatchToProps,
};
