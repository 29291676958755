import {
  accountVerifyReset as reset,
  verifyAccount as verify,
} from './accountVerify.actions';
import type { DefaultResponse } from '../../../services/RoRApiProvider';
import type { VerifyAccountActionPayload } from './accountVerify.actions';

export const mapStateToProps = state => {
  return {
    accountVerifyData: state.accountVerify.data,
    accountVerifyError: state.accountVerify.error,
    accountVerifyIsLoading: state.accountVerify.isLoading,
  };
};

export const mapDispatchToProps = dispatch => {
  return {
    verifyAccount(payload: VerifyAccountActionPayload) {
      dispatch(verify(payload));
    },
    accountVerifyReset() {
      dispatch(reset());
    },
  };
};

export type AccountVerifyMapStateToProps = {
  accountVerifyData: DefaultResponse | null,
  accountVerifyError: any,
  accountVerifyIsLoading: boolean,
};

export type AccountVerifyMapDispatchToProps = {
  verifyAccount: (payload: VerifyAccountActionPayload) => void,
  accountVerifyReset: () => void,
};

export default {
  mapStateToProps,
  mapDispatchToProps,
};
