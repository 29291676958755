// @flow
import React from 'react';
import _get from 'lodash/get';
import Button from '../../components/Button/Button';
import Table from '../../components/Table/Table';
import { message, Popconfirm } from 'antd';
import { connect } from 'react-redux';
import Avatar from '../../components/Avatar/Avatar';
import accountDetails from '../../redux/modules/accountDetails/accountDetails.containers';
import groupDetails from '../../redux/modules/groupDetails/groupDetails.containers';
import groupUpdate from '../../redux/modules/groupUpdate/groupUpdate.containers';
import type { GroupDetailsMapStateToProps } from '../../redux/modules/groupDetails/groupDetails.containers';
import type {
  AccountDetailsMapDispatchToProps,
  AccountDetailsMapStateToProps,
} from '../../redux/modules/accountDetails/accountDetails.containers';
import type {
  GroupUpdateMapDispatchToProps,
  GroupUpdateMapStateToProps,
} from '../../redux/modules/groupUpdate/groupUpdate.containers';
import './GroupAboutPage.scss';
import ModalAddSupportingCandidates from '../../components/ModalAddSupportingCandidates/ModalAddSupportingCandidates';

type Props = GroupDetailsMapStateToProps &
  GroupUpdateMapDispatchToProps &
  GroupUpdateMapStateToProps &
  AccountDetailsMapDispatchToProps &
  AccountDetailsMapStateToProps & {
    match: any,
    location: any,
    history: any,
  };

type State = {
  addSupportingCandidatesModalOpen: boolean,
  calledRemoveCandidate: boolean,
  searchNameValue: string,
};

class GroupCandidatesPage extends React.Component<Props, State> {
  constructor(props: Props) {
    super(props);

    this.state = {
      addSupportingCandidatesModalOpen: false,
      calledRemoveCandidate: false,
      searchNameValue: '',
    };

    this.props.updateGroupDetailsReset();
  }

  componentDidUpdate(prevProps: Props) {
    if (
      !prevProps.groupDetailsUpdateData &&
      this.props.groupDetailsUpdateData &&
      this.state.calledRemoveCandidate
    ) {
      this.setState(
        () => ({ calledRemoveCandidate: false }),
        () => {
          message.success('Candidate successfully removed from group.');
          this.props.updateGroupDetailsReset();
        },
      );
    }

    if (
      !prevProps.groupDetailsUpdateError &&
      this.props.groupDetailsUpdateError &&
      this.state.calledRemoveCandidate
    ) {
      this.setState(
        () => ({ calledRemoveCandidate: false }),
        () => {
          message.error(
            "An error has occurred and the candidate wasn't removed from group.",
          );
          this.props.updateGroupDetailsReset();
        },
      );
    }
  }

  render() {
    return (
      this.props.groupDetailsData && (
        <div className="group-members-page container-fluid">
          {this.renderTopBar()}
          <div className="row fix-width no-gutters pt-3">
            {this.renderCandidatesTable()}
          </div>
          <ModalAddSupportingCandidates
            isOpen={this.state.addSupportingCandidatesModalOpen}
            onCloseRequest={this.toggleAddSupportingCandidatesModalVisibility}
            onSuccess={this.toggleAddSupportingCandidatesModalVisibility}
            groupDetails={this.props.groupDetailsData.group}
          />
        </div>
      )
    );
  }

  renderCandidatesTable() {
    return (
      <div className="col-12 col-md-9 offset-md-3">
        <div className="group-members-page__table-wrapper">
          <Table {...this.getTableProps()} />
        </div>
      </div>
    );
  }

  getTableProps() {
    const tableProps: any = {
      dataSource: this.getTableData(),
      columns: this.getTableColumns(),
      pagination: false,
      loading:
        this.props.groupDetailsIsLoading ||
        this.props.groupDetailsUpdateIsLoading,
    };

    return tableProps;
  }

  getTableData() {
    const groupSupportingCandidates = _get(
      this.props,
      'groupDetailsData.group.supporting_candidates',
      [],
    );

    let tableData = [];
    groupSupportingCandidates.forEach(candidate => {
      if (candidate.id === this.props.accountDetailsData.user.id) return;

      tableData.push({
        key: candidate.id,
        name: `${candidate.first_name} ${candidate.last_name}`.trim(),
        state: candidate.state ? candidate.state.name : '',
        profileImage: candidate.profile_image,
        role: candidate.role.code,
        removeButton: true,
      });
    });

    return tableData;
  }

  getTableColumns() {
    return [
      {
        title: 'NAME',
        dataIndex: 'name',
        key: 'name',
        defaultSortOrder: 'ascend',
        render: (text: string, item: any) => {
          return (
            <React.Fragment>
              <Avatar
                isCandidate={item.role === 'candidate'}
                className="dashboard-page__table-cell__name-avatar"
                source={item.profileImage}
                type="tiny"
              />
              <span>{text}</span>
            </React.Fragment>
          );
        },
        sorter: (a: any, b: any) => a.name.localeCompare(b.name),
      },
      {
        title: '',
        dataIndex: 'removeButton',
        key: 'removeButton',
        render: (removeButton: boolean, data) => {
          return removeButton && this.isModerator() ? (
            this.renderRemoveCandidateButton(data)
          ) : (
            <span className="group-members-page__table-placeholder" />
          );
        },
      },
    ];
  }

  renderRemoveCandidateButton(data) {
    return (
      <Popconfirm
        title="Are you sure you want to remove this member from the group?"
        onConfirm={() => this.handleRemoveCandidate(data)}
        okText="Yes"
        cancelText="No">
        <Button buttonType="link" type="button">
          <img src="/images/icon_trash-red.png" alt="" />
        </Button>
      </Popconfirm>
    );
  }

  handleRemoveCandidate = (data: any) => {
    this.setState(
      () => ({ calledRemoveCandidate: true }),
      () => {
        this.props.updateGroupDetails({
          groupId: this.getGroupId(),
          group_candidates_attributes: [
            { candidate_id: parseInt(data.key), _destroy: true },
          ],
        });
      },
    );
  };

  renderTopBar() {
    const isMember = _get(
      this.props.groupDetailsData.group,
      'current_user.is_member',
      false,
    );
    const isModerator = _get(
      this.props.groupDetailsData.group,
      'current_user.is_moderator',
      false,
    );

    return !this.props.isMobile ? (
      <div className="row fix-width">
        <div className="col-12 offset-md-3 col-md-9 pr-md-4 pt-4 pb-2">
          <div className="row no-gutters">
            <div className="col-7 col-lg-8 px-0" />
            {isMember || isModerator ? (
              <div className="offset-1 col-4 col-lg-3 px-0">
                <Button
                  onClick={this.toggleAddSupportingCandidatesModalVisibility}
                  buttonType="primary"
                  type="button"
                  className="mb-3 group-posts-page__new-post-button"
                  size="small">
                  Add candidates
                </Button>
              </div>
            ) : null}
          </div>
        </div>
      </div>
    ) : null;
  }

  toggleAddSupportingCandidatesModalVisibility = () => {
    this.setState((prevState: State) => ({
      addSupportingCandidatesModalOpen: !prevState.addSupportingCandidatesModalOpen,
    }));
  };

  getGroupId() {
    return parseInt(this.props.match.params.groupId);
  }

  isModerator() {
    return _get(
      this.props,
      'groupDetailsData.group.current_user.is_moderator',
      false,
    );
  }
}

const mapStateToProps = (state, ownProps) => {
  return {
    ...accountDetails.mapStateToProps(state),
    ...groupDetails.mapStateToProps(state),
    ...groupUpdate.mapStateToProps(state),
    ...ownProps,
  };
};

const mapDispatchToProps = dispatch => {
  return {
    ...groupUpdate.mapDispatchToProps(dispatch),
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(GroupCandidatesPage);
