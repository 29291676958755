// @flow
import { createAction } from 'redux-actions';
import {
  GROUP_REMOVE_MEMBER_ERROR,
  GROUP_REMOVE_MEMBER_LOADING,
  GROUP_REMOVE_MEMBER_RESET,
  GROUP_REMOVE_MEMBER_SUCCESS,
} from './groupRemoveMember.constants';
import { instance as rorGroupsApiProvider } from '../../../services/RoRGroupsApiProvider';
import type { DeleteGroupRemoveMemberPayload } from '../../../services/RoRGroupsApiProvider';
import { groupMembersRemoveUserFromList } from '../groupMembers/groupMembers.actions';

export const groupRemoveMemberError = createAction(GROUP_REMOVE_MEMBER_ERROR);

export const groupRemoveMemberLoading = createAction(
  GROUP_REMOVE_MEMBER_LOADING,
);

export const groupRemoveMemberSuccess = createAction(
  GROUP_REMOVE_MEMBER_SUCCESS,
);

export const groupRemoveMemberReset = createAction(GROUP_REMOVE_MEMBER_RESET);

export const removeMemberFromGroup = (
  payload: DeleteGroupRemoveMemberPayload,
) => {
  return async (dispatch: Function) => {
    try {
      dispatch(groupRemoveMemberLoading());
      const response = await rorGroupsApiProvider.groupRemoveMember(payload);

      dispatch(groupMembersRemoveUserFromList(payload));
      dispatch(groupRemoveMemberSuccess(response));
    } catch (error) {
      dispatch(groupRemoveMemberError(error));
    }
  };
};
