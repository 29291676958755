// @flow
import Axios, { AxiosResponse, AxiosError } from 'axios';
import RoRApiResponseTransformer from '../utilities/RoRApiResponseTransformer';
import RoRApiProvider from './RoRApiProvider';
import type { DefaultResponse } from './RoRApiProvider';
import type { UserListView } from './RoRUsersApiProvider';

class RoRPostsApiProvider extends RoRApiProvider {
  constructor() {
    super();
  }

  postDetails(payload: GetPostDetailsPayload): Promise<GetPostDetailsResponse> {
    const requestConfig = {
      headers: {
        'Content-Type': 'application/json',
        ...this.getAuthenticationHeaders(),
      },
    };

    return this.axios
      .get(`apis/posts/${payload.postId}`, requestConfig)
      .then((response: AxiosResponse) =>
        RoRApiResponseTransformer.parseResponse(response),
      )
      .catch((error: AxiosError) =>
        Promise.reject(RoRApiResponseTransformer.parseError(error)),
      );
  }

  deletePost(payload: GetPostDetailsPayload): Promise<DefaultResponse> {
    const requestConfig = {
      headers: {
        'Content-Type': 'application/json',
        ...this.getAuthenticationHeaders(),
      },
    };

    return this.axios
      .delete(`apis/posts/${payload.postId}`, requestConfig)
      .then((response: AxiosResponse) =>
        RoRApiResponseTransformer.parseResponse(response),
      )
      .catch((error: AxiosError) =>
        Promise.reject(RoRApiResponseTransformer.parseError(error)),
      );
  }

  newPost(payload: PostNewPostPayload): Promise<PostNewPostResponse> {
    const requestConfig = {
      headers: {
        'Content-Type': 'multipart/form-data',
        ...this.getAuthenticationHeaders(),
      },
    };
    const formData = new FormData();

    Object.keys(payload).forEach((key: string) => {
      const value = payload[key];

      if (value !== null) {
        formData.append(`post[${key}]`, value);
      }
    });

    return this.axios
      .post('/apis/posts', formData, requestConfig)
      .then((response: AxiosResponse) =>
        RoRApiResponseTransformer.parseResponse(response),
      )
      .catch((error: AxiosError) =>
        Promise.reject(RoRApiResponseTransformer.parseError(error)),
      );
  }

  postComments(
    payload: GetPostCommentsPayload,
  ): Promise<GetPostCommentsResponse> {
    const requestConfig = {
      headers: {
        'Content-Type': 'application/json',
        ...this.getAuthenticationHeaders(),
      },
      params: {
        limit: payload.limit,
        page: payload.page,
      },
    };

    return this.axios
      .get(`apis/posts/${payload.postId}/comments`, requestConfig)
      .then((response: AxiosResponse) =>
        RoRApiResponseTransformer.parseResponse(response),
      )
      .catch((error: AxiosError) =>
        Promise.reject(RoRApiResponseTransformer.parseError(error)),
      );
  }

  newPostComment(
    payload: PostNewPostCommentPayload,
  ): Promise<PostNewPostCommentResponse> {
    const requestConfig = {
      headers: {
        'Content-Type': 'multipart/form-data',
        ...this.getAuthenticationHeaders(),
      },
    };
    const formData = new FormData();

    Object.keys(payload).forEach((key: string) => {
      const value = payload[key];

      if (value !== null) {
        formData.append(`post_comment[${key}]`, value);
      }
    });

    return this.axios
      .post(`apis/posts/${payload.postId}/comments`, formData, requestConfig)
      .then((response: AxiosResponse) =>
        RoRApiResponseTransformer.parseResponse(response),
      )
      .catch((error: AxiosError) =>
        Promise.reject(RoRApiResponseTransformer.parseError(error)),
      );
  }

  likePost(payload: LikePostPayload): Promise<LikePostResponse> {
    const requestConfig = {
      headers: {
        'Content-Type': 'application/json',
        ...this.getAuthenticationHeaders(),
      },
    };

    return this.axios
      .patch(`apis/posts/${payload.postId}/like`, {}, requestConfig)
      .then((response: AxiosResponse) =>
        RoRApiResponseTransformer.parseResponse(response),
      )
      .catch((error: AxiosError) =>
        Promise.reject(RoRApiResponseTransformer.parseError(error)),
      );
  }

  unLikePost(payload: LikePostPayload): Promise<LikePostResponse> {
    const requestConfig = {
      headers: {
        'Content-Type': 'application/json',
        ...this.getAuthenticationHeaders(),
      },
    };

    return this.axios
      .patch(`apis/posts/${payload.postId}/unlike`, {}, requestConfig)
      .then((response: AxiosResponse) =>
        RoRApiResponseTransformer.parseResponse(response),
      )
      .catch((error: AxiosError) =>
        Promise.reject(RoRApiResponseTransformer.parseError(error)),
      );
  }

  sharePost(payload: SharePostPayload): Promise<SharePostResponse> {
    const requestConfig = {
      headers: {
        'Content-Type': 'application/json',
        ...this.getAuthenticationHeaders(),
      },
    };

    return this.axios
      .patch(`apis/posts/${payload.postId}/share`, {}, requestConfig)
      .then((response: AxiosResponse) =>
        RoRApiResponseTransformer.parseResponse(response),
      )
      .catch((error: AxiosError) =>
        Promise.reject(RoRApiResponseTransformer.parseError(error)),
      );
  }

  flagPost(payload: FlagPostPayload): Promise<FlagPostResponse> {
    const requestConfig = {
      headers: {
        'Content-Type': 'application/json',
        ...this.getAuthenticationHeaders(),
      },
    };

    return this.axios
      .patch(`apis/posts/${payload.postId}/report`, {}, requestConfig)
      .then((response: AxiosResponse) =>
        RoRApiResponseTransformer.parseResponse(response),
      )
      .catch((error: AxiosError) =>
        Promise.reject(RoRApiResponseTransformer.parseError(error)),
      );
  }
}

export type Post = {
  id: number,
  title: string,
  short_description: string,
  commentable: boolean,
  created_at: string,
  like_count: number,
  share_count: number,
  comment_count: number,
  has_liked: boolean,
  post_image: string,
  description: string,
  owner: {
    id: number,
    first_name: string,
    last_name: string,
    profile_image: string,
    is_public: boolean,
    role: {
      id: number,
      name: string,
      code: string,
      admin: boolean,
    },
  },
};

export type GetPostDetailsPayload = {
  postId: number,
};

export type GetPostDetailsResponse = {
  post: Post,
};

export type newPost = {
  id: number,
  title: number,
  short_description: number,
  commentable: boolean,
  created_at: string,
  like_count: number,
  share_count: number,
  comment_count: number,
  has_liked: boolean,
  post_image: string,
  description: number,
};

export type PostNewPostPayload = {
  title: string,
  description: string,
  post_image: File | null,
  commentable: boolean,
  group_id?: number,
};

export type PostNewPostResponse = DefaultResponse & {
  post: newPost,
};

export type PostComment = {
  id: number,
  comment: string,
  created_at: string,
  like_count: number,
  has_liked: boolean,
  ['user' | 'owner']: UserListView
};

export type GetPostCommentsPayload = {
  postId: number,
  limit: number,
  page: number,
};

export type GetPostCommentsResponse = DefaultResponse & {
  post_comments: PostComment[],
};

export type PostNewPostCommentPayload = {
  postId: number,
  comment: string,
};

export type PostNewPostCommentResponse = DefaultResponse & {
  comment: PostComment,
};

export type LikePostPayload = {
  postId: number,
};

export type LikePostResponse = DefaultResponse & {
  post: {
    id: number,
    like_count: number,
  },
};

export type SharePostPayload = {
  postId: number,
};

export type SharePostResponse = {
  post: Post,
};

export type FlagPostPayload = {
  postId: number,
};

export type FlagPostResponse = DefaultResponse & {
  post: {
    id: number,
    like_count: number,
  },
};

export default RoRPostsApiProvider;
export const instance = new RoRPostsApiProvider();
