// @flow
import { createAction } from 'redux-actions';
import {
  ACCOUNT_POSTS_ERROR,
  ACCOUNT_POSTS_LOADING,
  ACCOUNT_POSTS_RESET,
  ACCOUNT_POSTS_SUCCESS,
  ACCOUNT_POSTS_SET_AS_FIRST,
  ACCOUNT_POSTS_LIKE_POST,
  ACCOUNT_POSTS_UNLIKE_POST,
  ACCOUNT_POSTS_SHARE_POST,
} from './accountPosts.constants';
import { instance as rorUsersApiProvider } from '../../../services/RoRUsersApiProvider';

export const accountPostsError = createAction(ACCOUNT_POSTS_ERROR);

export const accountPostsLoading = createAction(ACCOUNT_POSTS_LOADING);

export const accountPostsSuccess = createAction(ACCOUNT_POSTS_SUCCESS);

export const accountPostsReset = createAction(ACCOUNT_POSTS_RESET);

export const accountPostsSetAsFirst = createAction(ACCOUNT_POSTS_SET_AS_FIRST);

export const accountPostLikePost = createAction(ACCOUNT_POSTS_LIKE_POST);

export const accountPostUnLikePost = createAction(ACCOUNT_POSTS_UNLIKE_POST);

export const accountPostsSharePost = createAction(ACCOUNT_POSTS_SHARE_POST);

export const getAccountPosts = () => {
  return async (dispatch: Function) => {
    try {
      dispatch(accountPostsLoading());

      const response = await rorUsersApiProvider.accountPosts();

      dispatch(accountPostsSuccess(response));
    } catch (error) {
      dispatch(accountPostsError(error));
    }
  };
};
