// @flow
import makeDefaultServerCallReducer from '../../utilities/makeDefaultServerCallReducer';
import {
  GROUP_MEMBERS_ERROR,
  GROUP_MEMBERS_LOADING,
  GROUP_MEMBERS_SUCCESS,
  GROUP_MEMBERS_RESET,
  GROUP_MEMBERS_REMOVE_USER_FROM_LIST,
} from './groupMembers.constants';
import _cloneDeep from 'lodash/cloneDeep';

const initialState = {
  isLoading: false,
  error: null,
  data: null,
};

const removeUserFromMembersList = (state, action) => {
  const { payload } = action;

  if (state.data && state.data.data) {
    const data = _cloneDeep(state.data);

    if (data.data.members) {
      let members = [];

      members = data.data.members.filter(member => {
        return member.id !== payload.userId;
      });

      data.data.members = members;

      return {
        ...state,
        data,
      };
    }
  }

  return state;
};

export const reducer = makeDefaultServerCallReducer({
  loadingActions: [GROUP_MEMBERS_LOADING],
  successActions: [GROUP_MEMBERS_SUCCESS],
  errorActions: [GROUP_MEMBERS_ERROR],
  resetActions: [GROUP_MEMBERS_RESET],
  otherActions: [
    {
      name: GROUP_MEMBERS_REMOVE_USER_FROM_LIST,
      callback: removeUserFromMembersList,
    },
  ],
  initialState,
});
