// @flow
import makeDefaultServerCallReducer from '../../utilities/makeDefaultServerCallReducer';
import {
  GROUP_REMOVE_MODERATOR_ERROR,
  GROUP_REMOVE_MODERATOR_LOADING,
  GROUP_REMOVE_MODERATOR_SUCCESS,
  GROUP_REMOVE_MODERATOR_RESET,
} from './groupRemoveModerator.constants';

const initialState = {
  isLoading: false,
  error: null,
  data: null,
};

export const reducer = makeDefaultServerCallReducer({
  loadingActions: [GROUP_REMOVE_MODERATOR_LOADING],
  successActions: [GROUP_REMOVE_MODERATOR_SUCCESS],
  errorActions: [GROUP_REMOVE_MODERATOR_ERROR],
  resetActions: [GROUP_REMOVE_MODERATOR_RESET],
  initialState,
});
