import { postRemove, postRemoveReset as reset } from './postRemove.actions';
import type { DefaultResponse } from '../../../services/RoRApiProvider';

export const mapStateToProps = state => {
  return {
    postRemoveData: state.postRemove.data,
    postRemoveError: state.postRemove.error,
    postRemoveIsLoading: state.postRemove.isLoading,
  };
};

export const mapDispatchToProps = dispatch => {
  return {
    deletePost(payload) {
      dispatch(postRemove(payload));
    },
    postRemoveReset() {
      dispatch(reset());
    },
  };
};

export default {
  mapStateToProps,
  mapDispatchToProps,
};

export type PostRemoveMapStateToProps = {
  postRemoveData: DefaultResponse,
  postRemoveError: any,
  postRemoveIsLoading: boolean,
};

export type PostRemoveMapDispatchToProps = {
  deletePost: () => void,
  postRemoveReset: () => void,
};
