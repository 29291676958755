import {
  getCandidatesList,
  candidatesListReset as listReset,
} from './listCandidates.actions';

export const mapStateToProps = state => {
  return {
    candidatesListData: state.candidates.data,
    candidatesListError: state.candidates.error,
    candidatesListIsLoading: state.candidates.isLoading,
  };
};

export const mapDispatchToProps = dispatch => {
  return {
    getCandidatesList(payload) {
      dispatch(getCandidatesList(payload));
    },
    candidatesListReset() {
      dispatch(listReset());
    },
  };
};

export type CandidatesMapStateToProps = {
  candidatesListData: any,
  candidatesListError: any,
  candidatesListIsLoading: boolean,
};

export type CandidatesMapDispatchToProps = {
  getCandidatesList: Function,
  candidatesListReset: Function,
};
export default {
  mapStateToProps,
  mapDispatchToProps,
};
