// @flow
import React from 'react';
import classNames from 'classnames';
import Modal from '../Modal/Modal';
import Button from '../Button/Button';
import Select from '../Select/Select';
import * as yup from 'yup';
import { Formik, FormikComputedProps } from 'formik';
import type { UserDetails } from '../../services/RoRUsersApiProvider';

type Props = {
  isOpen: boolean,
  className?: string,
  candidateList: [],
  onCloseRequest: Function,
  onNextClick: Function,
};

class ModalContributeCandidate extends React.Component<Props, {}> {
  formikForm: any = null;

  render() {
    const { isOpen, onCloseRequest, className, ...rest } = this.props;

    return (
      <Modal
        contentLabel="Contribute to candidate"
        isOpen={isOpen}
        head="To which candidate would you like to make your contribution?"
        onCloseRequest={onCloseRequest}
        className={this.getClassName()}
        noPaddingContent
        size="big"
        {...rest}>
        <React.Fragment>
          {isOpen ? (
            this.props.candidateList.length > 0 ? (
              <Formik {...this.getFormikProps()}>
                {props => (
                  <form onSubmit={props.handleSubmit}>
                    <div className="modal-contribute-candidate__candidate-select-form">
                      <Select
                        className="mx-auto mt-5 w-50"
                        label=""
                        name="candidateSelect"
                        options={this.getSelectItems()}
                        placeholder="--select candidate--"
                        error={
                          props.touched.candidateSelect &&
                          props.errors.candidateSelect
                            ? props.errors.candidateSelect
                            : ''
                        }
                      />
                    </div>
                    <div className="align-items-center m-3 p-3">
                      <Button
                        className="float-right m-4"
                        buttonType="primary"
                        type="submit">
                        Next
                      </Button>
                    </div>
                  </form>
                )}
              </Formik>
            ) : (
              <div className="text-center mb-5">
                Please add supporting candidates before donating via a group.
              </div>
            )
          ) : null}
        </React.Fragment>
      </Modal>
    );
  }

  getFormikProps() {
    return {
      initialValues: {
        candidateSelect: '',
      },
      validationSchema: yup.object().shape({
        candidateSelect: yup.string().required('Please select a candidate.'),
      }),
      onSubmit: this.handleFormSubmit,
      ref: this.setFormikRef,
    };
  }

  setFormikRef = (ref: Formik) => {
    this.formikForm = ref;
  };

  handleFormSubmit = (values: any) => {
    this.props.onNextClick(values.candidateSelect);
  };

  getSelectItems = () => {
    return this.props.candidateList
      ? this.props.candidateList.map(candidate => ({
          label: `${candidate.first_name} ${candidate.last_name}`,
          value: candidate.id.toString(),
        }))
      : [];
  };

  getClassName() {
    return classNames({
      'modal-contribute-committed': true,
      [this.props.className || '']: this.props.className,
    });
  }
}

export default ModalContributeCandidate;
