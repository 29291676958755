// @flow
import React from 'react';
import moment from 'moment';
import Link from '../Link/Link';
import './CardRssItem.scss';

type Props = {
  link: string,
  description: string,
  title: string,
  date: string,
  source: string,
};

const CardRssItem = (props: Props) => {
  const month = moment(props.date)
    .format('MMM')
    .toUpperCase();
  const date = moment(props.date).format('Do, HH:mm A');

  return (
    <div className="row card-rss-item">
      <div className="col-12 px-0 mx-0">
        <div className="row card-rss-item__header">
          <div className="col-9">
            <h6>
              <Link href={props.link} title={props.title} isExternal={true}>
                <span dangerouslySetInnerHTML={{ __html: props.title }} />
              </Link>
            </h6>
            <p className="card-rss-item__feed-source">{props.source}</p>
          </div>
          <div className="col-3 text-right">
            <span className="card-rss-item__date">
              {month} {date}
            </span>
          </div>
        </div>
        <div className="row align-items-center">
          <div
            className="col-12"
            dangerouslySetInnerHTML={{ __html: props.description }}
          />
        </div>
      </div>
    </div>
  );
};

export default CardRssItem;
