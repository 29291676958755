// @flow
import { createAction } from 'redux-actions';
import {
  ACCOUNT_GROUPS_ERROR,
  ACCOUNT_GROUPS_LOADING,
  ACCOUNT_GROUPS_RESET,
  ACCOUNT_GROUPS_SUCCESS,
  ACCOUNT_GROUPS_SET_AS_FIRST,
} from './accountGroups.constants';
import { instance as rorApiProvider } from '../../../services/RoRApiProvider';

export const accountGroupsError = createAction(ACCOUNT_GROUPS_ERROR);

export const accountGroupsLoading = createAction(ACCOUNT_GROUPS_LOADING);

export const accountGroupsSuccess = createAction(ACCOUNT_GROUPS_SUCCESS);

export const accountGroupsReset = createAction(ACCOUNT_GROUPS_RESET);

export const accountGroupsSetAsFirst = createAction(
  ACCOUNT_GROUPS_SET_AS_FIRST,
);

export const getAccountGroups = () => {
  return async (dispatch: Function) => {
    try {
      dispatch(accountGroupsLoading());

      const response = await rorApiProvider.accountGroups();

      dispatch(accountGroupsSuccess(response));
    } catch (error) {
      dispatch(accountGroupsError(error));
    }
  };
};
