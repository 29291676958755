// @flow
import { createAction } from 'redux-actions';
import {
  GROUP_ADD_MODERATORS_ERROR,
  GROUP_ADD_MODERATORS_LOADING,
  GROUP_ADD_MODERATORS_RESET,
  GROUP_ADD_MODERATORS_SUCCESS,
} from './groupAddModerators.constants';
import { instance as rorGroupsApiProvider } from '../../../services/RoRGroupsApiProvider';
import type { PostGroupAddModeratorsPayload } from '../../../services/RoRGroupsApiProvider';

export const groupAddModeratorsError = createAction(GROUP_ADD_MODERATORS_ERROR);

export const groupAddModeratorsLoading = createAction(
  GROUP_ADD_MODERATORS_LOADING,
);

export const groupAddModeratorsSuccess = createAction(
  GROUP_ADD_MODERATORS_SUCCESS,
);

export const groupAddModeratorsReset = createAction(GROUP_ADD_MODERATORS_RESET);

export const addModeratorsToGroup = (
  payload: PostGroupAddModeratorsPayload,
) => {
  return async (dispatch: Function) => {
    try {
      dispatch(groupAddModeratorsLoading());
      const response = await rorGroupsApiProvider.groupAddModerators(payload);

      dispatch(groupAddModeratorsSuccess(response));
    } catch (error) {
      dispatch(groupAddModeratorsError(error));
    }
  };
};
