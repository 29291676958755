// @flow
import makeDefaultServerCallReducer from '../../utilities/makeDefaultServerCallReducer';
import {
  RACE_THOUGHT_ADD_ERROR,
  RACE_THOUGHT_ADD_LOADING,
  RACE_THOUGHT_ADD_SUCCESS,
  RACE_THOUGHT_ADD_RESET,
} from './raceThoughtAdd.constants';

const initialState = {
  isLoading: false,
  error: null,
  data: null,
};

export const reducer = makeDefaultServerCallReducer({
  loadingActions: [RACE_THOUGHT_ADD_LOADING],
  successActions: [RACE_THOUGHT_ADD_SUCCESS],
  errorActions: [RACE_THOUGHT_ADD_ERROR],
  resetActions: [RACE_THOUGHT_ADD_RESET],
  initialState,
});
