import {
  userFollow,
  userFollowReset,
  userUnFollow,
} from './userFollow.actions';
import type { DefaultResponse } from '../../../services/RoRApiProvider';

export const mapStateToProps = state => {
  return {
    userFollowData: state.userFollow.data,
    userFollowError: state.userFollow.error,
    userFollowIsLoading: state.userFollow.isLoading,
  };
};

export const mapDispatchToProps = dispatch => {
  return {
    userFollow(payload) {
      dispatch(userFollow(payload));
    },
    userFollowReset() {
      dispatch(userFollowReset());
    },
    userUnFollow(payload) {
      dispatch(userUnFollow(payload));
    },
  };
};

export type UserFollowMapStateToProps = {
  userFollowData: DefaultResponse,
  userFollowError: any,
  userFollowIsLoading: boolean,
};

export type UserFollowMapDispatchToProps = {
  userFollow: () => void,
  userUnFollow: () => void,
  userFollowReset: () => void,
};

export default {
  mapStateToProps,
  mapDispatchToProps,
};
