// @flow
import React from 'react';
import { connect } from 'react-redux';
import { message } from 'antd';
import account from '../../redux/modules/account/account.containers';
import accountDetails from '../../redux/modules/accountDetails/accountDetails.containers';
import groupDetails from '../../redux/modules/groupDetails/groupDetails.containers';
import groupPosts from '../../redux/modules/groupPosts/groupPosts.containers';
import PostListWithFilters from '../../components/PostListWithFilters/PostListWithFilters';
import type { PostProps } from '../../components/Post/Post';
import type { GroupPost } from '../../services/RoRGroupsApiProvider';
import ModalNewPost from '../../components/ModalNewPost/ModalNewPost';
import { URL_GROUP_POSTS_PAGE } from '../../config/urls';
import Button from '../../components/Button/Button';
import type { AccountDetailsMapStateToProps } from '../../redux/modules/accountDetails/accountDetails.containers';
import type { GroupDetailsMapStateToProps } from '../../redux/modules/groupDetails/groupDetails.containers';
import './GroupPostsPage.scss';
import type { AccountMapStateToProps } from '../../redux/modules/account/account.containers';
import { isCandidate, isCitizen } from '../../utilities/authorization';
import isPrytanyVerified from '../../utilities/isPrytanyVerified';

type Props = AccountMapStateToProps &
  AccountDetailsMapStateToProps &
  GroupDetailsMapStateToProps & {
    match: any,
    location: any,
    history: any,
    groupPostsData: any,
    groupPostsError: any,
    groupPostsIsLoading: any,
    getPostsForGroup: Function,
    groupPostsReset: Function,
  };

type State = {
  newPostModalOpen: boolean,
};

class GroupPostsPage extends React.Component<Props, State> {
  constructor(props: Props) {
    super(props);

    this.state = {
      newPostModalOpen: false,
    };
  }

  componentWillMount() {
    this.props.groupPostsReset();
  }

  componentDidMount() {
    if (typeof window !== 'undefined' && this.props.groupDetailsData) {
      this.fetchGroupPosts();
    }
  }

  componentDidUpdate(prevProps) {
    if (!prevProps.groupDetailsData && this.props.groupDetailsData) {
      this.fetchGroupPosts();
    }
  }

  render() {
    return this.props.groupDetailsData ? (
      <div className="group-posts-page container-fluid info">
        {this.renderNewPostButton()}
        <PostListWithFilters
          posts={this.parsePosts()}
          showContentFilter={false}
        />
        <ModalNewPost
          isOpen={this.state.newPostModalOpen}
          groupId={
            this.props.groupDetailsData && this.props.groupDetailsData.group.id
          }
          groupName={
            this.props.groupDetailsData &&
            this.props.groupDetailsData.group.name
          }
          onCloseRequest={this.toggleNewPostModalVisibility}
          onSuccess={this.handleNewPostSuccess}
          onError={this.handleNewPostError}
        />
      </div>
    ) : null;
  }

  renderNewPostButton() {
    return (
      <div className="row fix-width">
        <div className="col-8 col-sm-6 col-md-3 col-lg-2 offset-4 offset-sm-6 offset-md-9 offset-lg-10 pt-4 pb-2">
          {this.props.groupDetailsData.group.current_user.is_member ? (
            <Button
              onClick={this.toggleNewPostModalVisibility}
              buttonType="primary"
              type="button"
              className="mb-3 group-posts-page__new-post-button"
              size="small">
              New Post
            </Button>
          ) : null}
        </div>
      </div>
    );
  }

  fetchGroupPosts() {
    message.loading('Updating posts...', 3);
    this.props.getPostsForGroup({
      groupId: this.props.groupDetailsData.group.id,
    });
  }

  parsePosts() {
    const posts: GroupPost[] =
      (this.props.groupPostsData && this.props.groupPostsData.posts) || [];

    return posts.map(
      (post: GroupPost): PostProps => {
        const owner = post.owner;

        const postProps = {
          key: post.id,
          id: post.id,
          userId: owner.id,
          title: post.title,
          shortDescription: post.short_description,
          commentCount: post.comment_count,
          likeCount: post.like_count,
          shareCount: post.share_count,
          currentUserHasLiked: post.has_liked,
          commentable: post.commentable,
          postImage: post.post_image,
          createdAt: post.created_at,
          userIsCandidate: isCandidate(owner),
          userVerifiedLevel: owner.verified_level,
          userVerifiedDetail: owner.verified_detail,
          userAvatar: owner.profile_image,
          userFullName: `${owner.first_name} ${owner.last_name}`.trim(),
        };

        return postProps;
      },
    );
  }

  toggleNewPostModalVisibility = () => {
    this.setState((prevState: State) => ({
      newPostModalOpen: !prevState.newPostModalOpen,
    }));
  };

  handleNewPostSuccess = (newPostData: any) => {
    this.fetchGroupPosts();
    this.setState(
      () => ({ newPostModalOpen: false }),
      () => {
        message.success(
          <SuccessMessage
            history={this.props.history}
            location={this.props.location}
            groupId={this.props.match.params.groupId}
          />,
          10,
        );
      },
    );
  };

  handleNewPostError = (newPostError: any) => {
    message.error(
      newPostError.localMessage ||
        'An error has occurred and the post was not created.',
    );
  };
}

const SuccessMessage = ({
  history,
  location,
  groupId,
}: {
  history: any,
  location: any,
  groupId: number,
}) => {
  const groupPostsUrl = URL_GROUP_POSTS_PAGE.replace(':groupId', groupId);

  function handleClick() {
    history.push(groupPostsUrl);
  }

  return (
    <React.Fragment>
      <span>A new post was created. </span>
      {location.pathname !== groupPostsUrl && (
        <Button
          buttonType="link"
          onClick={handleClick}
          size="small"
          className="group-page__message-link">
          View post.
        </Button>
      )}
    </React.Fragment>
  );
};

const mapStateToProps = (state, ownProps) => {
  return {
    ...account.mapStateToProps(state),
    ...accountDetails.mapStateToProps(state),
    ...groupDetails.mapStateToProps(state),
    ...groupPosts.mapStateToProps(state),
    ...ownProps,
  };
};

const mapDispatchToProps = dispatch => {
  return {
    ...groupDetails.mapDispatchToProps(dispatch),
    ...groupPosts.mapDispatchToProps(dispatch),
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(GroupPostsPage);
