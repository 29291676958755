// @flow
import makeDefaultServerCallReducer from '../../utilities/makeDefaultServerCallReducer';
import {
  GROUP_MEMBER_CONTRIBUTIONS_LOADING,
  GROUP_MEMBER_CONTRIBUTIONS_SUCCESS,
  GROUP_MEMBER_CONTRIBUTIONS_ERROR,
  GROUP_MEMBER_CONTRIBUTIONS_RESET,
} from './groupMemberContributions.constants';
const initialState = {
  isLoading: false,
  error: null,
  data: null,
};

export const reducer = makeDefaultServerCallReducer({
  loadingActions: [GROUP_MEMBER_CONTRIBUTIONS_LOADING],
  successActions: [GROUP_MEMBER_CONTRIBUTIONS_SUCCESS],
  errorActions: [GROUP_MEMBER_CONTRIBUTIONS_ERROR],
  resetActions: [GROUP_MEMBER_CONTRIBUTIONS_RESET],
  initialState,
});
