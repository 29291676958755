// @flow
import makeDefaultServerCallReducer from '../../utilities/makeDefaultServerCallReducer';
import {
  USER_DETAILS_ERROR,
  USER_DETAILS_LOADING,
  USER_DETAILS_SUCCESS,
  USER_DETAILS_RESET,
  USER_DETAILS_SET_AS_FOLLOWED,
  USER_DETAILS_SET_AS_UNFOLLOWED,
  USER_DETAILS_ADD_CONTRIBUTION,
} from './userDetails.constants';
import _get from 'lodash/get';
import _cloneDeep from 'lodash/cloneDeep';

const initialState = {
  isLoading: false,
  error: null,
  data: null,
};

const setAsFollowed = (state, action) => {
  if (
    _get(state, 'data.user') &&
    _get(state, 'data.user.id') === action.payload.user.id
  ) {
    const data = _cloneDeep(state.data);

    data.user.current_user.is_following = true;
    data.user.followers_count += 1;

    return {
      ...state,
      data,
    };
  }

  return state;
};

const setAsUnfollowed = (state, action) => {
  if (
    _get(state, 'data.user') &&
    _get(state, 'data.user.id') === action.payload.user.id
  ) {
    const data = _cloneDeep(state.data);

    data.user.current_user.is_following = false;
    data.user.followers_count -= 1;

    return {
      ...state,
      data,
    };
  }

  return state;
};

const addContribution = (state, action) => {
  const { payload } = action;
  const user = _get(state, 'data.user');

  if (user && user.id === payload.userId) {
    const data = _cloneDeep(state.data);

    if (payload.contributionType === 'direct') {
      data.user.donations.contributed += payload.amountInCents;
    } else if (payload.contributionType === 'commit') {
      data.user.donations.committed += payload.amountInCents;
    } else if (payload.contributionType === 'pledge') {
      data.user.donations.pledged += payload.amountInCents;
    }

    return {
      ...state,
      data,
    };
  }

  return state;
};

export const reducer = makeDefaultServerCallReducer({
  loadingActions: [USER_DETAILS_LOADING],
  successActions: [USER_DETAILS_SUCCESS],
  errorActions: [USER_DETAILS_ERROR],
  resetActions: [USER_DETAILS_RESET],
  otherActions: [
    { name: USER_DETAILS_SET_AS_FOLLOWED, callback: setAsFollowed },
    { name: USER_DETAILS_SET_AS_UNFOLLOWED, callback: setAsUnfollowed },
    { name: USER_DETAILS_ADD_CONTRIBUTION, callback: addContribution },
  ],
  initialState,
});
