// @flow
import { createAction } from 'redux-actions';
import {
  POST_COMMENT_ADD_ERROR,
  POST_COMMENT_ADD_LOADING,
  POST_COMMENT_ADD_SUCCESS,
  POST_COMMENT_ADD_RESET,
} from './postCommentAdd.constants';
import { postCommentsSetAsFirst } from '../postComments/postComments.actions';
import { instance as rorPostsApiProvider } from '../../../services/RoRPostsApiProvider';
import type {
  PostNewPostCommentPayload,
  PostNewPostPayload,
} from '../../../services/RoRPostsApiProvider';

export const postCommentAddError = createAction(POST_COMMENT_ADD_ERROR);

export const postCommentAddLoading = createAction(POST_COMMENT_ADD_LOADING);

export const postCommentAddSuccess = createAction(POST_COMMENT_ADD_SUCCESS);

export const postCommentAddReset = createAction(POST_COMMENT_ADD_RESET);

export const postCommentAdd = (payload: PostNewPostCommentPayload) => {
  return async (dispatch: Function) => {
    try {
      dispatch(postCommentAddLoading());

      const response = await rorPostsApiProvider.newPostComment(payload);

      dispatch(postCommentAddSuccess(response));
      dispatch(postCommentsSetAsFirst(response.comment));
    } catch (error) {
      dispatch(postCommentAddError(error));
    }
  };
};
