// @flow
import { createAction } from 'redux-actions';
import {
  ACCOUNT_FOLLOWINGS_ERROR,
  ACCOUNT_FOLLOWINGS_LOADING,
  ACCOUNT_FOLLOWINGS_RESET,
  ACCOUNT_FOLLOWINGS_SUCCESS,
  ACCOUNT_FOLLOWINGS_ADD,
  ACCOUNT_FOLLOWINGS_REMOVE,
} from './accountFollowings.constants';
import { instance as rorApiProvider } from '../../../services/RoRApiProvider';

export const accountFollowingsError = createAction(ACCOUNT_FOLLOWINGS_ERROR);

export const accountFollowingsLoading = createAction(
  ACCOUNT_FOLLOWINGS_LOADING,
);

export const accountFollowingsSuccess = createAction(
  ACCOUNT_FOLLOWINGS_SUCCESS,
);

export const accountFollowingsReset = createAction(ACCOUNT_FOLLOWINGS_RESET);

export const accountFollowingsAdd = createAction(ACCOUNT_FOLLOWINGS_ADD);

export const accountFollowingsRemove = createAction(ACCOUNT_FOLLOWINGS_REMOVE);

export const getAccountFollowings = () => {
  return async (dispatch: Function) => {
    try {
      dispatch(accountFollowingsLoading());

      const response = await rorApiProvider.accountFollowings();

      dispatch(accountFollowingsSuccess(response));
    } catch (error) {
      dispatch(accountFollowingsError(error));
    }
  };
};
