// @flow
import axios, { AxiosResponse, AxiosError } from 'axios';
import RoRApiResponseTransformer from '../utilities/RoRApiResponseTransformer';
import RoRApiProvider from './RoRApiProvider';
import type { DefaultResponse, GeoState } from './RoRApiProvider';
import IdScanType from '../constants/IdScanType';
import { clientStore } from '../redux/clientStore';
import type { RaceDetails } from './RoRRacesApiProvider';
import type { IdScanTypeOption } from '../constants/IdScanType';

class RoRUsersApiProvider extends RoRApiProvider {
  constructor() {
    super();
  }

  userList(payload: GetUserListPayload): Promise<GetUserListResponse> {
    const requestConfig = {
      headers: {
        'Content-Type': 'application/json',
        ...this.getAuthenticationHeaders(),
      },
      params: {
        page: payload.page || 1,
        limit: payload.limit || 10,
        name: payload.name,
        exclude_group: payload.exclude_group,
        role: payload.role,
      },
    };

    return this.axios
      .get(`apis/users`, requestConfig)
      .then((response: AxiosResponse) =>
        RoRApiResponseTransformer.parseResponse(response, true),
      )
      .catch((error: AxiosError) =>
        Promise.reject(RoRApiResponseTransformer.parseError(error)),
      );
  }

  // TODO: logic to get All the account posts even if the number is bigger than 100
  accountPosts(): Promise<GetUserPostsResponse> {
    return this.userPosts({
      userId: this.getUserAccountData().user.id,
      limit: 100,
    });
  }

  userPosts(payload: GetUserPostsPayload): Promise<GetUserPostsResponse> {
    const requestConfig = {
      headers: {
        'Content-Type': 'application/json',
        ...this.getAuthenticationHeaders(),
      },
      params: {
        limit: payload.limit,
        page: payload.page,
      },
    };

    return this.axios
      .get(`apis/users/${payload.userId}/posts`, requestConfig)
      .then((response: AxiosResponse) =>
        RoRApiResponseTransformer.parseResponse(response),
      )
      .catch((error: AxiosError) =>
        Promise.reject(RoRApiResponseTransformer.parseError(error)),
      );
  }

  accountDetails(
    payload?: GetUserDetailsPayload,
  ): Promise<GetUserDetailsResponse> {
    const requestPayload = payload
      ? payload
      : { userId: clientStore.getState().account.data.user.id };
    return this.userDetails(requestPayload);
  }

  userDetails(payload: GetUserDetailsPayload): Promise<GetUserDetailsResponse> {
    const requestConfig = {
      headers: {
        'Content-Type': 'application/json',
        ...this.getAuthenticationHeaders({
          customToken: payload.customToken,
          customEmail: payload.customEmail,
        }),
      },
    };

    return this.axios
      .get(`apis/users/${payload.userId}`, requestConfig)
      .then((response: AxiosResponse) =>
        RoRApiResponseTransformer.parseResponse(response),
      )
      .catch((error: AxiosError) =>
        Promise.reject(RoRApiResponseTransformer.parseError(error)),
      );
  }

  userRSSFeed(payload: GetUserRSSFeedPayload): Promise<GetUserRSSFeedResponse> {
    const requestConfig = {
      headers: {
        'Content-Type': 'application/json',
        ...this.getAuthenticationHeaders(),
      },
    };

    return this.axios
      .get(`apis/candidates/${payload.userId}/rss_feed`, requestConfig)
      .then((response: AxiosResponse) =>
        RoRApiResponseTransformer.parseResponse(response),
      )
      .catch((error: AxiosError) =>
        Promise.reject(RoRApiResponseTransformer.parseError(error)),
      );
  }

  accountInboxMessageList(
    payload: GetUserInboxMessageListPayload,
  ): Promise<GetUserInboxMessageListResponse> {
    const requestConfig = {
      headers: {
        'Content-Type': 'application/json',
        ...this.getAuthenticationHeaders(),
      },
      params: {
        page: payload.page || 1,
        limit: payload.limit || 20,
      },
    };

    return this.axios
      .get('apis/messages', requestConfig)
      .then((response: AxiosResponse) =>
        RoRApiResponseTransformer.parseResponse(response, true),
      )
      .catch((error: AxiosError) =>
        Promise.reject(RoRApiResponseTransformer.parseError(error)),
      );
  }

  accountInboxMessageDetails(
    payload: GetUserInboxMessageDetailsPayload,
  ): Promise<GetUserInboxMessageDetailsResponse> {
    const requestConfig = {
      headers: {
        'Content-Type': 'application/json',
        ...this.getAuthenticationHeaders(),
      },
      params: {
        page: payload.page || 1,
      },
    };

    return this.axios
      .get(`apis/messages/${payload.messageId}`, requestConfig)
      .then((response: AxiosResponse) =>
        RoRApiResponseTransformer.parseResponse(response, true),
      )
      .catch((error: AxiosError) =>
        Promise.reject(RoRApiResponseTransformer.parseError(error)),
      );
  }

  accountInboxMessageDelete(
    payload: GetUserInboxMessageDeletePayload,
  ): Promise<DefaultResponse> {
    const requestConfig = {
      headers: {
        'Content-Type': 'application/json',
        ...this.getAuthenticationHeaders(),
      },
    };

    return this.axios
      .delete(`apis/messages/${payload.messageId}`, requestConfig)
      .then((response: AxiosResponse) =>
        RoRApiResponseTransformer.parseResponse(response, true),
      )
      .catch((error: AxiosError) =>
        Promise.reject(RoRApiResponseTransformer.parseError(error)),
      );
  }

  accountProfile(
    payload?: GetUserProfilePayload = {},
  ): Promise<GetUserProfileResponse> {
    const requestConfig = {
      headers: {
        'Content-Type': 'application/json',
        ...this.getAuthenticationHeaders({
          customToken: payload.customToken,
          customEmail: payload.customEmail,
        }),
      },
    };

    return this.axios
      .get('apis/users/profile', requestConfig)
      .then((response: AxiosResponse) =>
        RoRApiResponseTransformer.parseResponse(response),
      )
      .catch((error: AxiosError) =>
        Promise.reject(RoRApiResponseTransformer.parseError(error)),
      );
  }

  accountProfileUpdate(
    payload: PutUserProfileUpdatePayload,
  ): Promise<GetUserProfileResponse> {
    const requestConfig = {
      headers: {
        'Content-Type': 'multipart/form-data',
        ...this.getAuthenticationHeaders({
          customToken: payload.customToken,
          customEmail: payload.customEmail,
        }),
      },
    };
    const formData = new FormData();

    Object.keys(payload).forEach((key: string) => {
      let value: any;

      if (payload[key] instanceof File || Array.isArray(payload[key])) {
        value = payload[key];
      } else {
        value = payload[key].toString();
      }

      if (key === 'entity_interests_attributes' && value !== null) {
        value.forEach((interest, index) => {
          formData.append(
            `user[entity_interests_attributes][${index}][interest_id]`,
            interest.interest_id,
          );

          if (interest._destroy) {
            formData.append(
              `user[entity_interests_attributes][${index}][_destroy]`,
              interest._destroy,
            );
          }
        });
      } else if (value !== null) {
        formData.append(`user[${key}]`, value);
      }
    });

    return this.axios
      .put('apis/users/profile', formData, requestConfig)
      .then((response: AxiosResponse) =>
        RoRApiResponseTransformer.parseResponse(response),
      )
      .catch((error: AxiosError) =>
        Promise.reject(RoRApiResponseTransformer.parseError(error)),
      );
  }

  accountFeed(payload: GetUserFeedPayload): Promise<GetUserFeedResponse> {
    let params: GetUserFeedPayload = {
      userId: payload.userId,
      page: payload.page || 1,
      limit: payload.limit || 20,
    };

    if (payload.feed_type) {
      params.feed_type = payload.feed_type;
    }

    if (payload.role) {
      params.role = payload.role;
    }

    const requestConfig = {
      headers: {
        'Content-Type': 'application/json',
        ...this.getAuthenticationHeaders(),
      },
      params,
    };

    return this.axios
      .get('apis/feeds', requestConfig)
      .then((response: AxiosResponse) =>
        RoRApiResponseTransformer.parseResponse(response, true),
      )
      .catch((error: AxiosError) =>
        Promise.reject(RoRApiResponseTransformer.parseError(error)),
      );
  }

  userProfileFeed(payload: GetUserFeedPayload): Promise<GetUserFeedResponse> {
    const requestConfig = {
      headers: {
        'Content-Type': 'application/json',
        ...this.getAuthenticationHeaders(),
      },
      params: {
        page: payload.page || 1,
        limit: payload.limit || 20,
        user_id: payload.userId,
      },
    };

    return this.axios
      .get('apis/feeds', requestConfig)
      .then((response: AxiosResponse) =>
        RoRApiResponseTransformer.parseResponse(response, true),
      )
      .catch((error: AxiosError) =>
        Promise.reject(RoRApiResponseTransformer.parseError(error)),
      );
  }

  followUser(payload: PostFollowUserPayload): Promise<PostFollowUserResponse> {
    const requestConfig = {
      headers: {
        'Content-Type': 'application/json',
        ...this.getAuthenticationHeaders(),
      },
    };

    return this.axios
      .post(`apis/users/${payload.userId}/follow`, {}, requestConfig)
      .then((response: AxiosResponse) =>
        RoRApiResponseTransformer.parseResponse(response),
      )
      .catch((error: AxiosError) =>
        Promise.reject(RoRApiResponseTransformer.parseError(error)),
      );
  }

  accountContenders(payload: { userId: number }): Promise<ContenderDetails[]> {
    const requestConfig = {
      headers: {
        'Content-Type': 'application/json',
        ...this.getAuthenticationHeaders(),
      },
    };

    return this.axios
      .get(`apis/candidates/${payload.userId}/contenders`, requestConfig)
      .then((response: AxiosResponse) =>
        RoRApiResponseTransformer.parseResponse(response),
      )
      .catch((error: AxiosError) =>
        Promise.reject(RoRApiResponseTransformer.parseError(error)),
      );
  }

  // TODO: update with new response structure
  unfollowUser(
    payload: PostUnFollowUserPayload,
  ): Promise<PostFollowUserResponse> {
    const requestConfig = {
      headers: {
        'Content-Type': 'application/json',
        ...this.getAuthenticationHeaders(),
      },
    };

    return this.axios
      .post(`apis/users/${payload.userId}/unfollow`, {}, requestConfig)
      .then((response: AxiosResponse) =>
        RoRApiResponseTransformer.parseResponse(response),
      )
      .catch((error: AxiosError) =>
        Promise.reject(RoRApiResponseTransformer.parseError(error)),
      );
  }

  candidateSubInterests(
    payload: GetSubInterestsPayload,
  ): Promise<GetSubInterestsResponse> {
    const requestConfig = {
      headers: {
        'Content-Type': 'application/json',
        ...this.getAuthenticationHeaders({
          customToken: payload.customToken,
          customEmail: payload.customEmail,
        }),
      },
    };

    return this.axios
      .get(`apis/candidates/${payload.userId}/interests`, requestConfig)
      .then((response: AxiosResponse) =>
        RoRApiResponseTransformer.parseResponse(response),
      )
      .catch((error: AxiosError) =>
        Promise.reject(RoRApiResponseTransformer.parseError(error)),
      );
  }

  candidateCommitments(
    payload: GetCandidateCommitmentsPayload,
  ): Promise<GetCandidateCommitmentsResponse> {
    const requestConfig = {
      headers: {
        'Content-Type': 'application/json',
        ...this.getAuthenticationHeaders(),
      },
      params: {
        page: payload.page || 1,
        limit: payload.limit || 20,
      },
    };

    return this.axios
      .get(`apis/candidates/${payload.userId}/commitments`, requestConfig)
      .then((response: AxiosResponse) =>
        RoRApiResponseTransformer.parseResponse(response, true),
      )
      .catch((error: AxiosError) =>
        Promise.reject(RoRApiResponseTransformer.parseError(error)),
      );
  }

  accountCandidateCommitments(payload: {
    page?: number,
    limit?: number,
  }): Promise<GetCandidateCommitmentsResponse> {
    return this.candidateCommitments({
      userId: clientStore.getState().account.data.user.id,
      page: payload.page,
      limit: payload.limit,
    });
  }

  candidateDirectContributions(
    payload: GetCandidateDirectContributionsPayload,
  ): Promise<GetCandidateDirectContributionsResponse> {
    const requestConfig = {
      headers: {
        'Content-Type': 'application/json',
        ...this.getAuthenticationHeaders(),
      },
      params: {
        page: payload.page || 1,
        limit: payload.limit || 20,
      },
    };

    return this.axios
      .get(`apis/candidates/${payload.userId}/contributions`, requestConfig)
      .then((response: AxiosResponse) =>
        RoRApiResponseTransformer.parseResponse(response, true),
      )
      .catch((error: AxiosError) =>
        Promise.reject(RoRApiResponseTransformer.parseError(error)),
      );
  }

  accountCandidateDirectContributions(payload: {
    page?: number,
    limit?: number,
  }): Promise<GetCandidateDirectContributionsResponse> {
    return this.candidateDirectContributions({
      userId: clientStore.getState().account.data.user.id,
      page: payload.page,
      limit: payload.limit,
    });
  }

  candidatePledges(
    payload: GetCandidatePledgesPayload,
  ): Promise<GetCandidatePledgesResponse> {
    const requestConfig = {
      headers: {
        'Content-Type': 'application/json',
        ...this.getAuthenticationHeaders(),
      },
      params: {
        page: payload.page || 1,
        limit: payload.limit || 20,
      },
    };

    return this.axios
      .get(`apis/candidates/${payload.userId}/pledges`, requestConfig)
      .then((response: AxiosResponse) =>
        RoRApiResponseTransformer.parseResponse(response, true),
      )
      .catch((error: AxiosError) =>
        Promise.reject(RoRApiResponseTransformer.parseError(error)),
      );
  }

  accountCandidatePledges(payload: {
    page?: number,
    limit?: number,
  }): Promise<GetCandidatePledgesResponse> {
    return this.candidatePledges({
      userId: clientStore.getState().account.data.user.id,
      page: payload.page || 1,
      limit: payload.limit || 20,
    });
  }

  accountRaces(payload: {
    page?: number,
    limit?: number,
  }): Promise<GetAccountRacesResponse> {
    const requestConfig = {
      headers: {
        'Content-Type': 'application/json',
        ...this.getAuthenticationHeaders(),
      },
      params: {
        page: payload.page || 1,
        limit: payload.limit || 20,
      },
    };

    return this.axios
      .get(
        `/apis/users/${clientStore.getState().account.data.user.id}/races`,
        requestConfig,
      )
      .then((response: AxiosResponse) =>
        RoRApiResponseTransformer.parseResponse(response, true),
      )
      .catch((error: AxiosError) =>
        Promise.reject(RoRApiResponseTransformer.parseError(error)),
      );
  }

  verifyAccountWithIDScan(
    payload: PostIDScanVerifyPayload,
  ): Promise<DefaultResponse> {
    const { userId, scanType, customEmail, customToken, ...restPayload } = payload;
    const requestConfig = {
      headers: {
        'Content-Type': 'application/json',
        ...this.getAuthenticationHeaders({
          customToken: customToken,
          customEmail: customEmail,
        }),
      },
    };

    let endpoint: string;

    if (scanType === IdScanType.DRIVERS_LICENSE) {
      endpoint = 'id_verification';
    } else {
      endpoint = 'id_ocr_verification';
    }

    return this.axios
      .post(
        `apis/users/${userId}/${endpoint}`,
        { verify: restPayload },
        requestConfig,
      )
      .then((response: AxiosResponse) =>
        RoRApiResponseTransformer.parseResponse(response, true),
      )
      .catch((error: AxiosError) =>
        Promise.reject(RoRApiResponseTransformer.parseError(error)),
      );
  }

  getSsaFormData(
    payload: PostGetSsaFormDataPayload,
  ): Promise<PostGetSsaFormDataResponse> {
    const requestConfig = {
      headers: {
        'Content-Type': 'multipart/form-data',
        ...this.getAuthenticationHeaders({
          customToken: payload.customToken,
          customEmail: payload.customEmail,
        }),
      },
    };
    const formData = new FormData();

    Object.keys(payload).forEach((key: string) => {
      if (payload[key] !== null) {
        formData.append(`ssa[${key}]`, payload[key].toString());
      }
    });

    return this.axios
      .post(`apis/users/ssa_documents`, formData, requestConfig)
      .then((response: AxiosResponse) =>
        RoRApiResponseTransformer.parseResponse(response),
      )
      .catch((error: AxiosError) =>
        Promise.reject(RoRApiResponseTransformer.parseError(error)),
      );
  }

  verifyAccountWithSSA(
    payload: PostSsaVerifyPayload,
  ): Promise<DefaultResponse> {
    const requestConfig = {
      headers: {
        'Content-Type': 'multipart/form-data',
        ...this.getAuthenticationHeaders({
          customToken: payload.customToken,
          customEmail: payload.customEmail,
        }),
      },
    };
    const formData = new FormData();

    Object.keys(payload).forEach((key: string) => {
      let value: any;

      if (payload[key] instanceof File || Array.isArray(payload[key])) {
        value = payload[key];
      } else {
        value = payload[key].toString();
      }

      if (value !== null) {
        formData.append(`ssa[${key}]`, value);
      }
    });

    return this.axios
      .post(`apis/users/ssa_documents/upload_ssa`, formData, requestConfig)
      .then((response: AxiosResponse) =>
        RoRApiResponseTransformer.parseResponse(response, true),
      )
      .catch((error: AxiosError) =>
        Promise.reject(RoRApiResponseTransformer.parseError(error)),
      );
  }

  contactPrytanyMessage(
    payload: ContactPrytanyPayload,
  ): Promise<DefaultResponse> {
    const requestConfig = {
      headers: {
        'Content-Type': 'application/json',
        ...this.getAuthenticationHeaders(),
      },
    };

    return this.axios
      .post('/apis/users/contact_us', payload, requestConfig)
      .then((response: AxiosResponse) =>
        RoRApiResponseTransformer.parseResponse(response, true),
      )
      .catch((error: AxiosError) =>
        Promise.reject(RoRApiResponseTransformer.parseError(error)),
      );
  }
}

export type UserVerifiedLevel = 'not_verified' | 'silver' | 'gold';

export type UserListView = {
  id: number,
  first_name: string,
  last_name: string,
  profile_image: string,
  is_public: boolean,
  verified: boolean,
  ssa_verified: SSAVerifiedStatus,
  role: {
    id: number,
    name: string,
    code: string,
    admin: boolean,
  },
  verified_level: UserVerifiedLevel,
  verified_detail?: string
};

export type UserListItem = {
  donations: {
    contributed: number,
    committed: number,
    pledged: number,
  },
  exploratory_committee_profile: boolean,
  state: string,
} & UserListView;

export type GetUserListPayload = {
  limit?: number,
  page?: number,
  exclude_group?: number,
  include_political_parties?: boolean,
  role?: number | string,
  name?: string,
};

export type GetUserListResponse = DefaultResponse & {
  data: {
    users: UserListItem[],
  },
};

export type PostFeedItem = {
  created_at: string,
  details: {
    post: UserPost,
  },
  owner: UserListView,
  type: 'new_post',
};

export type GroupFeedItem = {
  created_at: string,
  details: {
    group: {
      id: number,
      name: string,
    },
  },
  owner: UserListView,
  type: 'group_join',
};

export type RaceStandFeedItem = {
  created_at: string,
  details: {
    race: {
      contenders: UserListView[],
      id: number,
      name: string,
    },
  },
  owner: UserListView,
  type: 'race_stand',
};

export type RaceThoughtFeedItem = {
  created_at: string,
  details: {
    race_thought: {
      id: number,
      race_id: number,
      thought: string,
    },
  },
  owner: UserListView,
  type: 'new_race_thought',
};

export type ContributionFeedItem = {
  type: 'contribution',
  created_at: string,
  owner: UserListView,
  details: {
    donation: {
      id: number,
      total_amount_in_cents: number,
      candidate: UserListView,
    },
  },
};

export type CommitmentFeedItem = {
  type: 'commitment',
  created_at: string,
  owner: UserListView,
  details: {
    committed_donation: {
      id: number,
      total_amount_in_cents: number,
      period_in_months: number,
      frequency_in_months: number,
      candidate: UserListView,
    },
  },
};

export type PledgeFeedItem = {
  type: 'pledge',
  created_at: string,
  owner: UserListView,
  details: {
    pledge: {
      id: number,
      total_amount_in_cents: number,
      condition: 'increase' | 'decrease' | 'maintain',
      sub_interest_name: string,
      sub_interest_id: number,
      period_in_months: number,
      candidate: UserListView,
    },
  },
};

export type RSSFeedItem = {
  type: 'rss',
  created_at: string,
  details: {
    rss: {
      link: string,
      title: string,
      description: string,
    },
  },
  owner: UserListView,
};

export type PostShareFeedItem = {
  type: 'post_share',
  created_at: string,
  details: {
    post: {
      comment_count: number,
      commentable: boolean,
      created_at: string,
      has_liked: boolean,
      id: number,
      like_count: number,
      parent_owner: UserListView,
      post_image: string,
      share_count: number,
      short_description: string,
      title: string,
    },
  },
  owner: UserListView,
};

export type FeedItem =
  | PostFeedItem
  | GroupFeedItem
  | RaceThoughtFeedItem
  | RaceStandFeedItem
  | ContributionFeedItem
  | CommitmentFeedItem
  | PledgeFeedItem
  | RSSFeedItem
  | PostShareFeedItem;

export type Feed = FeedItem[];

export type GetUserFeedPayload = {
  userId: number,
  page?: number,
  limit?: number,
  feed_type?:
    | 'new_post'
    | 'contribution'
    | 'group_join'
    | 'race'
    | 'google_alert',
  role?: 'citizen' | 'candidate',
};

export type GetUserFeedResponse = DefaultResponse & {
  data: {
    feeds: Feed[],
  },
};

export type UserPost = {
  id: number,
  title: string,
  short_description: string,
  commentable: boolean,
  created_at: string,
  like_count: number,
  share_count: number,
  comment_count: number,
  has_liked: boolean,
  post_image: string,
};

export type GetUserPostsPayload = {
  userId: number,
  limit?: number,
  page?: number,
};

export type GetUserPostsResponse = DefaultResponse & {
  posts: UserPost[],
};

export type GetUserDetailsPayload = {
  userId: number,
  customToken?: string,
  customEmail?: string,
};

export type SSAVerifiedStatus =
  | 'not_uploaded'
  | 'pending'
  | 'completed'
  | 'rejected';

export type UserDetails = {
  created_at: string,
  followers_count: number,
  likes_count: number,
  is_active: boolean,
  is_lobbyist: boolean,
  followers_count: number,
  likes_count: number,
  contributions_count: 6,
  race: { id: number, name: string },
  interests: { id: number, name: string }[],
  donations: {
    contributed: number,
    committed: number,
    pledged: number,
  },
  rss_feed?: string,
  biography: string,
  current_user: {
    is_following: boolean,
  },
} & UserListView;

export type GetUserRSSFeedPayload = {
  userId: number,
};

export type UserRSSFeedEntry = {
  id: string,
  title: string,
  link: {
    href: string,
  },
  published: string,
  updated: string,
  content: string,
  author: {
    name: string | null,
  },
};

export type GetUserRSSFeedResponse = {
  feed: {
    xmlns: string,
    'xmlns:idx': string,
    id: string,
    title: string,
    link: {
      href: string,
      rel: string,
    },
    updated: string,
    entry: UserRSSFeedEntry | UserRSSFeedEntry[],
  },
};

export type GetUserInboxMessageListPayload = {
  page?: number,
  limit?: number,
};

export type UserInboxGroupInvitationMessageListItem = {
  id: number,
  content_type: 'group_invitation',
  created_at: string,
  is_read: boolean,
  sender: UserListView,
  details: {
    group: {
      id: number,
      name: string,
      members_count: number,
      created_at: string,
      group_image: string,
    },
  },
};

export type GetUserInboxMessageListResponse = DefaultResponse & {
  data: {
    messages: UserInboxGroupInvitationMessageListItem[],
  },
};

export type UserInboxGroupInvitationMessageDetails = {
  id: number,
  content_type: 'group_invitation' | 'conversation' | 'conversation_thread',
  body: string,
  created_at: string,
  sender: UserListView,
  details: {
    group: {
      id: number,
      name: string,
      members_count: number,
      created_at: string,
      group_image: string,
    },
  },
};

export type UserInboxMessageThreadDetails = {
  id: number,
  content_type: 'group_invitation' | 'conversation' | 'conversation_thread',
  messages: {
    body: string,
    is_sent: boolean,
    sent_at: string,
  }[],
  created_at: string,
  sender: {
    id: number,
    first_name: string,
    last_name: string,
    profile_image: string,
    is_public: boolean,
    role: {
      id: number,
      name: string,
      code: string,
      admin: boolean,
    },
  },
  details: {
    group: {
      id: number,
      name: string,
      members_count: number,
      created_at: string,
      group_image: string,
    },
  },
};

export type GetUserInboxMessageDetailsPayload = {
  messageId: number,
  page?: number,
  limit?: number,
};

export type GetUserInboxMessageDetailsResponse = DefaultResponse & {
  data: {
    message:
      | UserInboxGroupInvitationMessageDetails
      | UserInboxMessageThreadDetails,
  },
};

export type GetUserInboxMessageDeletePayload = {
  messageId: number,
};

export type ContactPrytanyPayload = {
  contact_us: {
    name: string,
    email: string,
    message: string,
  },
};

export type ContenderDetails = {
  id: number,
  rank: number,
  first_name: string,
  last_name: string,
  profile_image: string,
  is_public: boolean,
  role: {
    id: number,
    name: string,
    code: string,
    admin: boolean,
  },
  donations: {
    contributed: number,
    committed: number,
    pledged: number,
  },
};

export type GetUserDetailsResponse = {
  user: UserDetails,
};

export type UserRole = {
  id: number,
  name: string,
  code: string,
  admin: boolean,
};

export type UserProfile = {
  id: number,
  email: string,
  first_name: string,
  last_name: string,
  dob: string,
  telephone: number | string,
  address1: string,
  address2: string,
  city: string,
  zip_code: string,
  personalized_url: string,
  biography: string,
  public_profile: boolean,
  profile_image: string,
  is_active: boolean,
  role: UserRole,
  state: {
    id: number,
    name: string,
    code: string,
  },
  employer: string,
  occupation: string,
  is_lobbyist: boolean,
  interests: { id: number, name: string }[],
  followings: { id: number, name: string }[],
  candidates: { id: number, name: string }[],
  groups: { id: number, name: string }[],
  bankingUrl?: string,
};

export type GetUserProfileResponse = DefaultResponse & {
  data: {
    user: UserProfile,
  },
};

export type GetUserProfilePayload = {
  userId?: string,
  customToken?: string,
  customEmail?: string,
};

export type PutUserProfileUpdatePayload = {
  first_name?: string,
  last_name?: string,
  email?: string,
  dob?: string,
  password?: string,
  telephone?: string,
  address1?: string,
  address2?: string,
  biography?: string,
  lobbyist?: boolean,
  profile_image?: File,
  entity_interests_attributes?: { interest_id: number, _destroy?: true }[],
  public_profile?: boolean,
  //the following are required for mobile requests
  userId?: string,
  customToken?: string,
  customEmail?: string,
};

// TODO: update this response
type PostFollowUserResponse = {
  follower: {
    id: string,
    name: string,
    profile_image: string,
  },
};

export type PostFollowUserPayload = {
  userId: string,
};

export type PostUnFollowUserPayload = {
  userId: string,
};

export type GetSubInterestsPayload = {
  userId: number,
  customToken?: string,
  customEmail?: string,
};

export type GetSubInterestsResponse = {
  candidate: {
    interests: {
      interests: {
        id: number,
        name: string,
        sub_interests: {
          id: number,
          name: string,
        }[],
      }[],
    },
  },
};

export type GetCandidateCommitmentsPayload = {
  userId: number,
  page?: number,
  limit?: number,
};

export type CandidateCommitment = {
  id: number,
  amount: number,
  created_at: string,
  period_in_months: number,
  contributor: UserListView & {
    state: GeoState,
  },
};

export type GetCandidateCommitmentsResponse = DefaultResponse & {
  data: {
    committed: CandidateCommitment[],
  },
};

export type GetCandidateDirectContributionsPayload = {
  userId: number,
  page?: number,
  limit?: number,
};

export type CandidateDirectContribution = {
  id: number,
  amount: number,
  created_at: string,
  contributor: UserListView & {
    state: GeoState,
  },
};

export type GetCandidateDirectContributionsResponse = DefaultResponse & {
  data: {
    contributed: CandidateDirectContribution[],
  },
};

export type GetCandidatePledgesPayload = {
  userId: number,
  page?: number,
  limit?: number,
};

export type CandidatePledge = {
  id: number,
  amount: number,
  created_at: string,
  due_date: string,
  condition: 'increase' | 'decrease' | 'maintain',
  contributor: UserListView & {
    state: GeoState,
  },
};

export type GetCandidatePledgesResponse = DefaultResponse & {
  data: {
    pledged: CandidatePledge[],
  },
};

export type GetAccountRacesResponse = DefaultResponse & {
  races: {
    id: number,
    name: string,
    due_date: string,
  }[],
};

export type PostIDScanVerifyPayload = {
  userId: number,
  image_data: string,
  scanType: IdScanTypeOption,
  customToken?: string,
  customEmail?: string,
};

export type PostGetSsaFormDataPayload = {
  ssn: string,
  legal_first_name: string,
  customToken?: string,
  customEmail?: string,
};

export type PostGetSsaFormDataResponse = {
  ssa_document: {
    printed_name: string,
    dob: string,
    consent: string,
    reasons: string,
    company_name: string,
    company_address: string,
    company_agent: string,
  },
};

export type PostSsaVerifyPayload = {
  ssa_document: string,
  stripe_source_id: string,
  customToken?: string,
  customEmail?: string,
};

export default RoRUsersApiProvider;
export const instance = new RoRUsersApiProvider();
