import {
  accountRacesReset as reset,
  getAccountRaces as getRaces,
} from './accountRaces.actions';

export const mapStateToProps = state => {
  return {
    accountRacesData: state.accountRaces.data,
    accountRacesError: state.accountRaces.error,
    accountRacesIsLoading: state.accountRaces.isLoading,
  };
};

export const mapDispatchToProps = dispatch => {
  return {
    getAccountRaces(payload: { limit?: number, page?: number }) {
      dispatch(getRaces(payload));
    },
    accountRacesReset() {
      dispatch(reset());
    },
  };
};

export type AccountRacesMapStateToProps = {
  accountRacesData: any,
  accountRacesError: any,
  accountRacesIsLoading: boolean,
};

export type AccountRacesMapDispatchToProps = {
  getAccountRaces: Function,
  accountRacesReset: Function,
};

export default {
  mapStateToProps,
  mapDispatchToProps,
};
