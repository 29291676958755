// @flow
import _remove from 'lodash/remove';
import _get from 'lodash/get';
import _cloneDeep from 'lodash/cloneDeep';
import makeDefaultServerCallReducer from '../../utilities/makeDefaultServerCallReducer';
import {
  GROUP_MEMBER_PENDING_REQUESTS_ERROR,
  GROUP_MEMBER_PENDING_REQUESTS_LOADING,
  GROUP_MEMBER_PENDING_REQUESTS_SUCCESS,
  GROUP_MEMBER_PENDING_REQUESTS_RESET,
  GROUP_MEMBER_PENDING_REQUESTS_REMOVE_ITEM,
  GROUP_MEMBER_PENDING_REQUESTS_REMOVE_ITEMS_FOR_ROLE,
  GROUP_MEMBER_PENDING_REQUESTS_NEXT_PAGE,
} from './groupMemberPendingRequests.constants';

const initialState = {
  isLoading: false,
  error: null,
  data: null,
};

const removeItem = (state, action) => {
  if (state.data) {
    const data = _cloneDeep(state.data);

    Object.keys(data).forEach(roleCode => {
      if (_get(data, `${roleCode}.data.requests`)) {
        _remove(
          data[roleCode].data.requests,
          item => item.id === action.payload.userId,
        );
      }
    });

    return {
      ...state,
      data,
    };
  }

  return state;
};

const removeItemForRole = (state, action) => {
  if (state.data) {
    const data = _cloneDeep(state.data);

    if (data[action.payload.roleCode]) {
      data[action.payload.roleCode].data.requests = [];
    }

    return {
      ...state,
      data,
    };
  }

  return state;
};

const setNextPageData = (state, action) => {
  const payload = action.payload;

  if (state.data && state.data[payload.roleCode]) {
    const stateData = _cloneDeep(state.data);
    const actionData = payload.data;
    const { data: newFeed, ...rest } = actionData;

    Object.assign(stateData[payload.roleCode], rest, {
      data: {
        requests: stateData[payload.roleCode].data.requests.concat(
          newFeed.requests || [],
        ),
      },
    });

    return {
      ...state,
      isLoading: false,
      data: { ...stateData },
    };
  }

  return {
    ...state,
    isLoading: false,
  };
};

export const reducer = makeDefaultServerCallReducer({
  loadingActions: [GROUP_MEMBER_PENDING_REQUESTS_LOADING],
  successActions: [GROUP_MEMBER_PENDING_REQUESTS_SUCCESS],
  errorActions: [GROUP_MEMBER_PENDING_REQUESTS_ERROR],
  resetActions: [GROUP_MEMBER_PENDING_REQUESTS_RESET],
  otherActions: [
    { name: GROUP_MEMBER_PENDING_REQUESTS_REMOVE_ITEM, callback: removeItem },
    {
      name: GROUP_MEMBER_PENDING_REQUESTS_REMOVE_ITEMS_FOR_ROLE,
      callback: removeItemForRole,
    },
    {
      name: GROUP_MEMBER_PENDING_REQUESTS_NEXT_PAGE,
      callback: setNextPageData,
    },
  ],
  initialState,
});
