// @flow
import React from 'react';
import classNames from 'classnames';
import Modal from '../Modal/Modal';
import ContributeNewACH from '../ContributeNewACH/ContributeNewACH';

type Props = {
  isOpen: boolean,
  onAfterOpen?: Function,
  onCloseRequest: () => void,
  className?: string,
  onSuccess: Function,
  onError?: (error: any) => void,
};

class ModalContributeNewACH extends React.Component<Props, {}> {
  render() {
    const {
      isOpen,
      onAfterOpen,
      onCloseRequest,
      onSuccess,
      onError,
      className,
      ...rest
    } = this.props;

    return (
      <Modal
        contentLabel="Contribute to candidate"
        isOpen={isOpen}
        onAfterOpen={onAfterOpen}
        onCloseRequest={onCloseRequest}
        className={this.getClassName()}
        noPaddingContent
        size="small"
        {...rest}>
        {isOpen ? (
          <ContributeNewACH
            onSuccess={onSuccess}
            onError={onError}
            onCancel={onCloseRequest}
          />
        ) : null}
      </Modal>
    );
  }

  getClassName() {
    return classNames({
      'modal-contribute-new-ach': true,
      [this.props.className || '']: this.props.className,
    });
  }
}

export default ModalContributeNewACH;
