// @flow
import * as React from 'react';
import { connect } from 'react-redux';
import withRouter from 'react-router-dom/withRouter';
import Linkify from 'react-linkify';
import postLike from '../../redux/modules/postLike/postLike.containers';
import postRemove from '../../redux/modules/postRemove/postRemove.containers';
import {
  PostLikeMapStateToProps,
  PostLikeMapDispatchToProps,
} from '../../redux/modules/postLike/postLike.containers';
import {
  PostRemoveMapStateToProps,
  PostRemoveMapDispatchToProps,
} from '../../redux/modules/postRemove/postRemove.containers';
import moment from 'moment';
import Avatar from '../Avatar/Avatar';
import './Post.scss';
import { URL_POST_PAGE, URL_USER_PUBLIC_PROFILE } from '../../config/urls';
import Button from '../Button/Button';
import Link from '../Link/Link';
import ModalPostShare from '../ModalPostShare/ModalPostShare';
import type { UserListView, UserVerifiedLevel } from '../../services/RoRUsersApiProvider';
import _get from 'lodash/get';

export type PostProps = PostLikeMapDispatchToProps &
  PostLikeMapStateToProps &
  PostRemoveMapStateToProps &
  PostRemoveMapDispatchToProps & {
    sharedBy?: UserListView,
    commentCount: number,
    commentable: boolean,
    createdAt: string,
    currentUserHasLiked: boolean,
    id: number,
    likeCount: number,
    postImage: string,
    shareCount: number,
    shortDescription: string,
    title: string,
    userFullName: string,
    userAvatar: string,
    userIsCandidate: boolean,
    userId: number,
    userIsOwner?: boolean,
    userVerifiedLevel: UserVerifiedLevel,
    userVerifiedDetail?: string,
  };

type State = {
  enableLoadingButtons: boolean,
  isShareModalOpen: boolean,
};

class Post extends React.Component<PostProps, State> {
  constructor(props: PostProps) {
    super(props);

    (this: any).handleDeletePostClick = this.handleDeletePostClick.bind(this);
    this.state = {
      enableLoadingButtons: false,
      isShareModalOpen: false,
    };
  }

  componentDidUpdate(prevProps: PostProps) {
    if (
      this.state.enableLoadingButtons &&
      (!this.props.postLikeIsLoading && prevProps.postLikeIsLoading)
    ) {
      this.setState(() => ({ enableLoadingButtons: false }));
    }
  }

  // TODO: add post details link
  render() {
    const date = moment(this.props.createdAt);
    const month = date.format('MMM').toUpperCase();
    const dayHour = date.format('Do, hh:mm A');
    const postUrl = URL_POST_PAGE.replace(':postId', this.props.id);
    const userProfileUrl = URL_USER_PUBLIC_PROFILE.replace(
      ':id',
      this.props.userId,
    );
    const fullWidthTextContent = !this.props.postImage;
    const fullWidthImageContent = !(
      this.props.title || this.props.shortDescription
    );
    const shortDescription = (
      <Linkify component={Link} properties={{ isExternal: true }}>
        {this.props.shortDescription}
      </Linkify>
    );

    return (
      <div className="row post">
        <ModalPostShare
          isOpen={this.state.isShareModalOpen}
          onCloseRequest={this.toggleShareModalVisibility}
          postId={this.props.id}
        />
        {!fullWidthTextContent && (
          <div
            className={`${
              fullWidthImageContent
                ? 'post__content full_width_img_post'
                : 'col-md-4'
            } col-12 px-0`}>
            {fullWidthImageContent && (
              <div className="row post__inner-content">
                {this.renderSharedBy()}
                <div className="col-7 col-lg-6 post__content-name">
                  <div className="row">
                    <div className="col-3 col-sm-2">
                      <Link
                        href={userProfileUrl}
                        title={this.props.userFullName}
                        type="dark">
                        <Avatar
                          type="tiny"
                          source={this.props.userAvatar}
                          isCandidate={this.props.userIsCandidate}
                        />
                      </Link>
                    </div>
                    <div className="col-9 col-sm-10 pl-3 pl-sm-2 pt-0 pt-md-2">
                      <Link
                        href={userProfileUrl}
                        title={this.props.userFullName}
                        type="dark">
                        <span className="pl-0 pl-md-2">
                          {this.props.userFullName}
                        </span>
                      </Link>
                      <br />
                      <span className="post__content-date pl-0 pl-md-2 pt-0 d-block d-md-none">
                        {`${month} ${dayHour}`}
                        {this.renderDeleteButton()}
                      </span>
                    </div>
                  </div>
                </div>
                <div
                  className={`col-5 col-lg-6 text-right post__content-date ${
                    this.props.userIsOwner
                      ? 'post__content-date--with-button'
                      : ''
                  } d-none d-md-block`}>
                  {`${month} ${dayHour}`}
                  {this.renderDeleteButton()}
                </div>
              </div>
            )}

            <Link href={postUrl} title={this.props.title}>
              <img src={this.props.postImage} className="post__image" />
            </Link>
          </div>
        )}

        {!fullWidthImageContent && (
          <div
            className={`${
              fullWidthTextContent ? '' : 'col-md-8'
            } col-12 post__content pb-4`}>
            <div className="row post__inner-content">
              {this.renderSharedBy()}
              <div className="col-7 col-lg-6 post__content-name">
                <div className="row">
                  <div className="col-3 col-sm-2">
                    <Link
                      href={userProfileUrl}
                      title={this.props.userFullName}
                      type="dark">
                      <Avatar
                        type="tiny"
                        source={this.props.userAvatar}
                        isCandidate={this.props.userIsCandidate}
                        verifiedLevel={this.props.userVerifiedLevel}
                        verifiedDetail={this.props.userVerifiedDetail}
                      />
                    </Link>
                  </div>
                  <div className="col-9 col-sm-10 pl-3 pl-sm-2 pt-0 pt-md-2">
                    <Link
                      href={userProfileUrl}
                      title={this.props.userFullName}
                      type="dark">
                      <span className="pl-0 pl-md-2">
                        {this.props.userFullName}
                      </span>
                    </Link>
                    <br />
                    <span className="post__content-date pl-0 pl-md-2 pt-0 d-block d-md-none">
                      {`${month} ${dayHour}`}
                      {this.renderDeleteButton()}
                    </span>
                  </div>
                </div>
              </div>
              <div
                className={`col-5 col-lg-6 text-right post__content-date ${
                  this.props.userIsOwner
                    ? 'post__content-date--with-button'
                    : ''
                } d-none d-md-block`}>
                {`${month} ${dayHour}`}
                {this.renderDeleteButton()}
              </div>
              {postUrl && (
                <div className="col-12 pb-2 pr-3 pr-md-5">
                  <Link href={postUrl} title={this.props.title}>
                    <h2>{this.props.title}</h2>
                  </Link>
                </div>
              )}
              {this.props.shortDescription && (
                <div className="col-12 post__content-text f-nunito pr-3 pr-md-5">
                  {!this.props.title && (
                    <Link href={postUrl}>
                      <p>{shortDescription}</p>
                    </Link>
                  )}
                  {this.props.title && <p>{shortDescription}</p>}
                  <div className="gradient" />
                </div>
              )}
              <div className="col-12 counters px-0">
                <div className="row">
                  <div className="col-4 px-0 pb-2 text-center">
                    <Button
                      block={false}
                      buttonType="light"
                      size="small"
                      loading={
                        this.state.enableLoadingButtons &&
                        this.props.postLikeIsLoading
                      }
                      noBorder={true}
                      onClick={
                        this.props.currentUserHasLiked
                          ? this.handleUnlikePostClick
                          : this.handleLikePostClick
                      }
                      spinnerProps={{ type: 'dark' }}>
                      <img
                        className={
                          this.props.currentUserHasLiked
                            ? 'post__like-icon'
                            : ''
                        }
                        src={`/images/${
                          this.props.currentUserHasLiked
                            ? 'icon_heart_solid.svg'
                            : 'icon_heart.png'
                        }`}
                        alt=""
                      />
                      <span>{this.props.likeCount}</span>
                    </Button>
                  </div>
                  <div className="col-4 px-0 pb-2 text-center">
                    <Button
                      block={false}
                      buttonType="light"
                      size="small"
                      noBorder={true}
                      onClick={this.handleCommentButtonClick}>
                      <img src="/images/icon_msg.png" alt="" />
                      <span>{this.props.commentCount}</span>
                    </Button>
                  </div>
                  <div className="col-4 px-0 pb-2 text-center">
                    <Button
                      block={false}
                      buttonType="light"
                      size="small"
                      noBorder={true}
                      onClick={this.toggleShareModalVisibility}>
                      <img src="/images/icon_share.png" alt="" />
                      <span>{this.props.shareCount}</span>
                    </Button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        )}
      </div>
    );
  }

  renderDeleteButton() {
    let content = null;

    if (this.props.userIsOwner) {
      content = (
        <React.Fragment>
          <Button
            buttonType="link"
            type="button"
            size="small"
            loading={this.props.postRemoveIsLoading}
            className="post__delete-button"
            onClick={this.handleDeletePostClick}>
            <img src="/images/icon_delete.svg" alt="" className="img-fluid" />
          </Button>
        </React.Fragment>
      );
    }

    return content;
  }

  renderSharedBy() {
    const sharedByFullName = `${_get(
      this.props.sharedBy,
      'first_name',
      '',
    )} ${_get(this.props.sharedBy, 'last_name', '')}`.trim();
    return this.props.sharedBy ? (
      <div className="col-12 d-flex align-items-center pb-3 px-2">
        <div>
          <Link href={'#'} title={sharedByFullName} type="dark">
            <span className="pl-0 pl-md-2">{sharedByFullName}</span>
          </Link>
          <span className="card-header-component__text-after-user">
            &nbsp; Shared:
          </span>
        </div>
      </div>
    ) : null;
  }

  toggleShareModalVisibility = () => {
    this.setState((prevState: State) => ({
      isShareModalOpen: !prevState.isShareModalOpen,
    }));
  };

  handleLikePostClick = () => {
    this.setState(
      () => ({ enableLoadingButtons: true }),
      () => {
        this.props.postLike({ postId: this.props.id });
      },
    );
  };

  handleUnlikePostClick = () => {
    this.setState(
      () => ({ enableLoadingButtons: true }),
      () => {
        this.props.postUnlike({ postId: this.props.id });
      },
    );
  };

  handleDeletePostClick() {
    this.props.deletePost({ postId: this.props.id });
  }

  handleCommentButtonClick = () => {
    this.props.history.push(
      `${URL_POST_PAGE.replace(':postId', this.props.id)}?focusMessageInput=1`,
    );
  };
}

const mapStateToProps = (state, ownProps) => {
  return {
    ...postLike.mapStateToProps(state),
    ...postRemove.mapStateToProps(state),
    ...ownProps,
  };
};

const mapDispatchToProps = dispatch => {
  return {
    ...postLike.mapDispatchToProps(dispatch),
    ...postRemove.mapDispatchToProps(dispatch),
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(withRouter(Post));
