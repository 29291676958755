// @flow
import Axios, { AxiosResponse, AxiosError } from 'axios';
import RoRApiResponseTransformer from '../utilities/RoRApiResponseTransformer';
import { clientStore } from '../redux/clientStore';
import type { UserListView } from './RoRUsersApiProvider';

class RoRApiProvider {
  axios: Axios;
  host: string =
    process.env.RAZZLE_ROR_API_HOST || 'https://staging.prytany.com/';

  constructor() {
    this.axios = Axios.create({});

    this.axios.defaults.baseURL = this.host;
    this.axios.defaults.headers.common['Accept'] = 'application/prytany.v1';
  }

  getUserAccountData() {
    return clientStore.getState().account.data;
  }

  getAuthenticationHeaders(
    options?: { customToken?: string, customEmail?: string } = {},
  ) {
    const data = this.getUserAccountData();

    if ((data && data.user) || (options.customEmail && options.customToken)) {
      return {
        'X-User-Email': options.customEmail || data.user.email,
        'X-User-Token': options.customToken || data.user.authentication_token,
      };
    }

    return {};
  }

  login(payload: PostLoginPayload): Promise<PostLoginResponse> {
    const requestPayload = {
      user: Object.assign({}, payload, { device_type: 'web' }),
    };
    const requestConfig = { headers: { 'Content-Type': 'application/json' } };

    return this.axios
      .post('/apis/users/login', requestPayload, requestConfig)
      .then((response: AxiosResponse) =>
        RoRApiResponseTransformer.parseResponse(response),
      )
      .catch((error: AxiosError) =>
        Promise.reject(RoRApiResponseTransformer.parseError(error)),
      );
  }

  sendOtpCode(payload: PostSendOtpCodePayload): Promise<DefaultResponse> {
    const requestConfig = { headers: { 'Content-Type': 'application/json' } };

    return this.axios
      .post('/apis/users/send_signup_otp', payload, requestConfig)
      .then((response: AxiosResponse) =>
        RoRApiResponseTransformer.parseResponse(response, true),
      )
      .catch((error: AxiosError) =>
        Promise.reject(RoRApiResponseTransformer.parseError(error)),
      );
  }

  verifyOtpCode(payload: PostVerifyOtpCode): Promise<DefaultResponse> {
    const requestConfig = { headers: { 'Content-Type': 'application/json' } };
    const requestPayload = { verify: payload };

    return this.axios
      .post('/apis/users/verify_email', requestPayload, requestConfig)
      .then((response: AxiosResponse) =>
        RoRApiResponseTransformer.parseResponse(response, true),
      )
      .catch((error: AxiosError) =>
        Promise.reject(RoRApiResponseTransformer.parseError(error)),
      );
  }

  logout(): Promise<DefaultResponse> {
    const requestConfig = {
      headers: {
        'Content-Type': 'application/json',
        ...this.getAuthenticationHeaders(),
      },
    };

    return this.axios
      .delete('/apis/users/logout', requestConfig)
      .then((response: AxiosResponse) =>
        RoRApiResponseTransformer.parseResponse(response),
      )
      .catch((error: AxiosError) =>
        Promise.reject(RoRApiResponseTransformer.parseError(error)),
      );
  }

  signUp(
    payload: PostSignUpPayload | CampaignManagerSignUpPayload,
  ): Promise<PostSignUpResponse> {
    const requestConfig = {
      headers: { 'Content-Type': 'multipart/form-data' },
    };
    const formData = new FormData();

    Object.keys(payload).forEach((key: string) => {
      let value: any = null;

      if (payload[key] instanceof File || Array.isArray(payload[key])) {
        value = payload[key];
      } else if (payload[key]) {
        value = payload[key].toString();
      }

      if (key === 'entity_interests_attributes' && value !== null) {
        value.forEach((interest, index) => {
          formData.append(
            `user[entity_interests_attributes][${index}][interest_id]`,
            interest.interest_id,
          );
        });
      } else if (key === 'notify_groups' && value !== null) {
        value.forEach((group, index) => {
          formData.append(
            `user[notify_groups][${index}][group_id]`,
            group.group_id,
          );
        });
      } else if (key === 'cm_invitations_attributes' && value !== null) {
        value.forEach((manager, index) => {
          formData.append(
            `user[cm_invitations_attributes][${index}][email]`,
            manager.email,
          );
        });
      } else if (value !== null && value !== '') {
        formData.append(`user[${key}]`, value);
      }
    });

    return this.axios
      .post('/apis/users/signup', formData, requestConfig)
      .then((response: AxiosResponse) =>
        RoRApiResponseTransformer.parseResponse(response),
      )
      .catch((error: AxiosError) =>
        Promise.reject(RoRApiResponseTransformer.parseError(error)),
      );
  }

  resetPassword(payload: {
    user: { email: string },
  }): Promise<DefaultResponse> {
    const requestConfig = {
      'Content-Type': 'application/json',
    };

    return this.axios
      .post('/apis/users/reset_password', payload, requestConfig)
      .then((response: AxiosResponse) =>
        RoRApiResponseTransformer.parseResponse(response),
      )
      .catch((error: AxiosError) =>
        Promise.reject(RoRApiResponseTransformer.parseError(error)),
      );
  }

  deactivateAccount(): Promise<DefaultResponse> {
    const requestConfig = {
      headers: {
        'Content-Type': 'application/json',
        ...this.getAuthenticationHeaders(),
      },
    };
    return this.axios
      .post('/apis/users/deactivate_user', {}, requestConfig)
      .then((response: AxiosResponse) =>
        RoRApiResponseTransformer.parseResponse(response),
      )
      .catch((error: AxiosError) =>
        Promise.reject(RoRApiResponseTransformer.parseError(error)),
      );
  }

  listBasedOnTypes(
    payload: GetListBasedOnTypesPayload,
  ): Promise<GetListBasedOnTypesResponse> {
    const requestConfig = {
      headers: {
        'Content-Type': 'application/json',
      },
      params: {
        type: payload.type,
        limit: payload.limit || 20,
        page: payload.page || 1,
      },
    };

    return this.axios
      .get('/apis/lists', requestConfig)
      .then((response: AxiosResponse) =>
        RoRApiResponseTransformer.parseResponse(response),
      )
      .catch((error: AxiosError) =>
        Promise.reject(RoRApiResponseTransformer.parseError(error)),
      );
  }

  listGroups(payload: GetGroupsPayload): Promise<GetGroupsResponse> {
    const requestConfig = {
      headers: {
        'Content-Type': 'application/json',
      },
      params: payload,
    };

    return this.axios
      .get('/apis/groups', requestConfig)
      .then((response: AxiosResponse) =>
        RoRApiResponseTransformer.parseResponse(response),
      )
      .catch((error: AxiosError) =>
        Promise.reject(RoRApiResponseTransformer.parseError(error)),
      );
  }

  pledgeInterests(
    payload: GetPledgeInterestsPayload,
  ): Promise<GetPledgeInterestsResponse> {
    const requestConfig = {
      headers: {
        'Content-Type': 'application/json',
        ...this.getAuthenticationHeaders(),
      },
      params: payload,
    };

    return this.axios
      .get('/apis/pledge/interests', requestConfig)
      .then((response: AxiosResponse) =>
        RoRApiResponseTransformer.parseResponse(response),
      )
      .catch((error: AxiosError) =>
        Promise.reject(RoRApiResponseTransformer.parseError(error)),
      );
  }

  // TODO: logic to get All the account groups even if the number is bigger than 100
  accountGroups(): Promise<GetUserGroupsResponse> {
    return this.userGroups({
      userId: clientStore.getState().account.data.user.id,
      limit: 100,
    });
  }

  userGroups(payload: GetUserGroupsPayload): Promise<GetUserGroupsResponse> {
    const requestConfig = {
      headers: {
        'Content-Type': 'application/json',
        ...this.getAuthenticationHeaders(),
      },
      params: {
        limit: payload.limit,
        page: payload.page,
      },
    };

    return this.axios
      .get(`apis/users/${payload.userId}/groups`, requestConfig)
      .then((response: AxiosResponse) =>
        RoRApiResponseTransformer.parseResponse(response),
      )
      .catch((error: AxiosError) =>
        Promise.reject(RoRApiResponseTransformer.parseError(error)),
      );
  }

  // TODO: logic to get All the account followings even if the number is bigger than 100
  accountFollowings(): Promise<GetUserFollowingsResponse> {
    return this.userFollowings({
      userId: clientStore.getState().account.data.user.id,
      limit: 100,
    });
  }

  userFollowings(
    payload: GetUserFollowingsPayload,
  ): Promise<GetUserFollowingsResponse> {
    const requestConfig = {
      headers: {
        'Content-Type': 'application/json',
        ...this.getAuthenticationHeaders(),
      },
      params: {
        limit: payload.limit,
        page: payload.page,
      },
    };

    return this.axios
      .get(`apis/users/${payload.userId}/followings`, requestConfig)
      .then((response: AxiosResponse) =>
        RoRApiResponseTransformer.parseResponse(response),
      )
      .catch((error: AxiosError) =>
        Promise.reject(RoRApiResponseTransformer.parseError(error)),
      );
  }

  // TODO: logic to get All the account followings even if the number is bigger than 100
  accountFollowers(): Promise<GetUserFollowersResponse> {
    return this.userFollowers({
      userId: clientStore.getState().account.data.user.id,
      limit: 100,
    });
  }

  userFollowers(
    payload: GetUserFollowersPayload,
  ): Promise<GetUserFollowersResponse> {
    const requestConfig = {
      headers: {
        'Content-Type': 'application/json',
        ...this.getAuthenticationHeaders(),
      },
      params: {
        limit: payload.limit,
        page: payload.page,
      },
    };

    return this.axios
      .get(`apis/users/${payload.userId}/followers`, requestConfig)
      .then((response: AxiosResponse) =>
        RoRApiResponseTransformer.parseResponse(response),
      )
      .catch((error: AxiosError) =>
        Promise.reject(RoRApiResponseTransformer.parseError(error)),
      );
  }
}

export type PostLoginPayload = {
  email: string,
  password: string,
  remember_me: boolean,
};

export type PostLoginResponse = {
  user: AuthenticatedUser,
};

export type AuthenticatedUser = {
  id: number,
  authentication_token: string,
  email: string,
  first_name: string,
  last_name: string,
  role: {
    id: number,
    name: string,
    code: string,
    admin: boolean,
  },
};

export type PostSendOtpCodePayload = {
  email: string,
};

export type PostVerifyOtpCode = {
  email: string,
  otp: string,
};

export type DefaultResponse = {
  success: boolean,
  message: string,
  status_code: string,
  total_count?: number,
  total_pages?: number,
  current_page?: number,
  next_page?: number,
};

export type PostSignUpPayload = {
  email: string,
  password: string,
  role_id: number,
  first_name: string,
  last_name: string,
  dob: string,
  telephone: string,
  state_id: number,
  address1: string,
  address2: string,
  city: string,
  zip: string,
  biography: string,
  public_profile?: boolean,
  entity_interests_attributes?: { interest_id: number }[],
  notify_groups?: { group_id: number }[],
  cm_invitations_attributes?: { email: string }[],
  fec_number?: string,
  middle_initial?: string,
  'g-recaptcha-response': string,
  invited_by_group_id: string,
};

export type CampaignManagerSignUpPayload = {
  email: string,
  password: string,
  role_id: 3,
  first_name: string,
  last_name: string,
};

export type PostSignUpResponse = {
  user: {
    id: number,
    email: string,
    authentication_token: string,
    first_name: string,
    last_name: string,
  },
};

type GetListBasedOnTypesPayload = {
  type: 'interests' | 'roles' | 'states',
  limit?: number,
  page?: number,
};

type GetListBasedOnTypesResponse = DefaultResponse & {
  data: {
    ['interests' | 'roles' | 'states']: {
      id: number,
      code?: string,
      name: string,
    }[],
  },
};

// TODO: define payload
type GetPledgeInterestsPayload = {};

type GetPledgeInterestsResponse = DefaultResponse & {
  data: {
    interests: [
      {
        id: number,
        name: string,
        sub_interests: [{ id: number, name: string }],
      },
    ],
  },
};

type GetGroupsPayload = {
  interest: string,
};

type GetGroupsResponse = DefaultResponse & {
  data: {
    groups: {
      id: number,
      name: string,
    },
  },
};

type GetUserGroupsPayload = {
  userId: number,
  limit?: number,
  page?: number,
};

type GetUserGroupsResponse = DefaultResponse & {
  groups: {
    id: number,
    name: string,
    code: string,
    group_image: string,
  }[],
};

type GetUserFollowingsPayload = {
  userId: number,
  limit?: number,
  page?: number,
};

type GetUserFollowingsResponse = DefaultResponse & {
  followings: any[],
};

type GetUserFollowersPayload = {
  userId: number,
  limit?: number,
  page?: number,
};

export type Follower = UserListView & {
  state: GeoState,
  shared_interests: number,
  donations: {
    commited: number,
    contributed: number,
    pledged: number,
  },
};

type GetUserFollowersResponse = DefaultResponse & {
  followers: Follower[],
};

export type GeoState = {
  id: number,
  name: string,
  code: string,
};

export default RoRApiProvider;
export const instance = new RoRApiProvider();
