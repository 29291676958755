import {
  CONTACT_PRYTANY_MESSAGE_SUCCESS,
  CONTACT_PRYTANY_MESSAGE_LOADING,
  CONTACT_PRYTANY_MESSAGE_ERROR,
  CONTACT_PRYTANY_MESSAGE_RESET,
} from './contactPrytanyMessage.constants';

const initialState = {
  isLoading: false,
  error: null,
  data: null,
};

export const reducer = (state = initialState, action) => {
  switch (action.type) {
    case CONTACT_PRYTANY_MESSAGE_LOADING:
      return {
        ...state,
        isLoading: true,
        error: null,
      };
    case CONTACT_PRYTANY_MESSAGE_SUCCESS:
      return {
        ...state,
        isLoading: false,
        error: null,
        data: action.payload,
      };
    case CONTACT_PRYTANY_MESSAGE_ERROR:
      return {
        ...state,
        isLoading: false,
        error: action.payload,
        data: null,
      };
    case CONTACT_PRYTANY_MESSAGE_RESET:
      return {
        ...state,
        isLoading: false,
        error: null,
        data: null,
      };
    default:
      return state;
  }
};
