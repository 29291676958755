// @flow
import _get from 'lodash/get';
import { message } from 'antd';
import { ROR_ERROR_CODE_INVALID_CREDENTIALS } from '../../utilities/RoRApiResponseTransformer';
import { accountLogout } from '../modules/account/account.actions';

const credentialsMiddleware = (store: any) => (next: any) => (action: any) => {
  const errorCode = _get(action, 'payload.code');
  const isAccountLoading = _get(store.getState(), 'account.isLoading');
  const accountData = _get(store.getState(), 'account.data');

  let result = next(action);

  // TODO: once the issue with redux hot reloading is fixed, remove the condition !module.hot
  if (
    // !module.hot &&
    errorCode === ROR_ERROR_CODE_INVALID_CREDENTIALS &&
    accountData &&
    !isAccountLoading
  ) {
    if (typeof window !== 'undefined') {
      message.warning('Your session has expired, please login again.');
    }

    store.dispatch(accountLogout());
  }

  return result;
};

export default credentialsMiddleware;
