// @flow
import { createAction } from 'redux-actions';
import {
  SEARCH_ERROR,
  SEARCH_LOADING,
  SEARCH_RESET,
  SEARCH_SUCCESS,
} from './search.constants';
import { instance as rorApiProvider } from '../../../services/RoRApiProvider';
import { instance as usersApiProvider } from '../../../services/RoRUsersApiProvider';
import { instance as groupsApiProvider } from '../../../services/RoRGroupsApiProvider';
import { instance as racesApiProvider } from '../../../services/RoRRacesApiProvider';
import _keyBy from 'lodash/keyBy';
import _at from 'lodash/at';
import SearchType from '../../../constants/SearchType';
import RoleCode from '../../../constants/RoleCode';

export const searchError = createAction(SEARCH_ERROR);

export const searchLoading = createAction(SEARCH_LOADING);

export const searchSuccess = createAction(SEARCH_SUCCESS);

export const searchReset = createAction(SEARCH_RESET);

let roleIds: { [$Values<typeof RoleCode>]: number } | null = null;

const fetchRoleIds = async () => {
  //$FlowFixMe
  const { roles } = await rorApiProvider.listBasedOnTypes({ type: 'roles' });
  return roles.reduce((acc, role) => ({ ...acc, [role.code]: role.id }), {});
};

export const search = (payload: SearchPayload) => {
  return async (dispatch: Function) => {
    try {
      dispatch(searchReset());
      dispatch(searchLoading());

      //fetch roles ids on first call and store them in a module scoped variable
      roleIds = roleIds || (await fetchRoleIds());

      let data = [];
      const commonRequestPayload = {
        limit: payload.limit || 50,
        page: payload.page || 1,
      };

      if (payload.searchType === SearchType.CANDIDATES) {
        data = await usersApiProvider.userList({
          name: payload.search,
          role: roleIds[RoleCode.CANDIDATE],
          ...commonRequestPayload,
        });
      } else if (payload.searchType === SearchType.CITIZENS) {
        data = await usersApiProvider.userList({
          name: payload.search,
          role: roleIds[RoleCode.CITIZEN],
          ...commonRequestPayload,
        });
      } else if (payload.searchType === SearchType.POLITICAL_PARTIES) {
        data = await usersApiProvider.userList({
          name: payload.search,
          role: roleIds[RoleCode.POLITICAL_PARTY],
          limit: 50,
        });
      } else if (payload.searchType === SearchType.GROUPS) {
        data = await groupsApiProvider.searchGroups({
          name: payload.search,
          ...commonRequestPayload,
        });
      } else if (payload.searchType === SearchType.RACES) {
        data = await racesApiProvider.listRaces({
          name: payload.search,
          ...commonRequestPayload,
        });
      } else if (payload.searchType === SearchType.PACS) {
        //  PACs
        const pacRoleIds = _at(
          roleIds,
          RoleCode.TRADITIONAL_PAC,
          RoleCode.LEADERSHIP_PAC,
          RoleCode.SUPER_PAC,
          RoleCode.HYBRID_PAC,
        );
        data = await usersApiProvider.userList({
          name: payload.search,
          role: pacRoleIds.join(','),
          limit: 50,
        });
      }

      dispatch(searchSuccess(data));
    } catch (error) {
      console.log(error);
      dispatch(searchError(error));
    }
  };
};

export type SearchPayload = {
  search: string,
  searchType: string,
  limit?: number,
  page?: number,
};
