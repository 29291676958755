import {
  accountProfileReset as reset,
  getAccountProfile as getProfile,
} from './accountProfile.actions';
import type {
  GetUserProfilePayload,
  UserProfile,
} from '../../../services/RoRUsersApiProvider';

export const mapStateToProps = state => {
  return {
    accountProfileData: state.accountProfile.data,
    accountProfileError: state.accountProfile.error,
    accountProfileIsLoading: state.accountProfile.isLoading,
  };
};

export const mapDispatchToProps = dispatch => {
  return {
    getAccountProfile(payload?: GetUserProfilePayload) {
      dispatch(getProfile(payload));
    },
    accountProfileReset() {
      dispatch(reset());
    },
  };
};

export type AccountProfileMapStateToProps = {
  accountProfileData: { user: UserProfile },
  accountProfileError: any,
  accountProfileIsLoading: boolean,
};

export type AccountProfileMapDispatchToProps = {
  getAccountProfile: (payload?: GetUserProfilePayload) => void,
  accountProfileReset: Function,
};

export default {
  mapStateToProps,
  mapDispatchToProps,
};
