// @flow
import makeDefaultServerCallReducer from '../../utilities/makeDefaultServerCallReducer';
import {
  CONTRIBUTION_PLEDGE_CLAIM_ERROR,
  CONTRIBUTION_PLEDGE_CLAIM_LOADING,
  CONTRIBUTION_PLEDGE_CLAIM_SUCCESS,
  CONTRIBUTION_PLEDGE_CLAIM_RESET,
} from './contributionPledgeClaim.constants';

const initialState = {
  isLoading: false,
  error: null,
  data: null,
};

export const reducer = makeDefaultServerCallReducer({
  loadingActions: [CONTRIBUTION_PLEDGE_CLAIM_LOADING],
  successActions: [CONTRIBUTION_PLEDGE_CLAIM_SUCCESS],
  errorActions: [CONTRIBUTION_PLEDGE_CLAIM_ERROR],
  resetActions: [CONTRIBUTION_PLEDGE_CLAIM_RESET],
  initialState,
});
