// @flow
import {
  groupInviteByContact as inviteByContact,
  groupInviteByContactReset as reset,
} from './groupInviteByContact.actions';
import type { PostInviteByContactPayload } from '../../../services/RoRGroupsApiProvider';
import type { DefaultResponse } from '../../../services/RoRApiProvider';

const mapStateToProps = (state: any): GroupInviteByContactMapStateToProps => {
  return {
    groupInviteByContactData: state.groupInviteByContact.data,
    groupInviteByContactError: state.groupInviteByContact.error,
    groupInviteByContactIsLoading: state.groupInviteByContact.isLoading,
  };
};

const mapDispatchToProps = (
  dispatch: Function,
): GroupInviteByContactMapDispatchToProps => {
  return {
    groupInviteByContact(payload: PostInviteByContactPayload) {
      dispatch(inviteByContact(payload));
    },
    groupInviteByContactReset() {
      dispatch(reset());
    },
  };
};

export type GroupInviteByContactMapStateToProps = {
  groupInviteByContactData: DefaultResponse,
  groupInviteByContactError: any,
  groupInviteByContactIsLoading: boolean,
};

export type GroupInviteByContactMapDispatchToProps = {
  groupInviteByContact: (payload: PostInviteByContactPayload) => void,
  groupInviteByContactReset: () => void,
};

export default {
  mapStateToProps,
  mapDispatchToProps,
};
