// @flow
import React from 'react';
import './SingleRowMenu.scss';
import Button from '../Button/Button';
import classNames from 'classnames';

type MenuItem = {
  key: string,
  image?: string,
  text: string,
  onClick: Function,
  className?: string,
};

type Props = {
  content: MenuItem[],
  noBorder?: boolean,
  className?: string,
};

const SingleRowMenu = (props: Props) => {
  return (
    <menu className={getClassName(props)}>
      <ul>
        {props.content.map((item, index) => (
          <li key={item.key} className={item.className || ''}>
            <Button
              buttonType="link"
              className="fs-13 fw-600"
              type="button"
              onClick={() => item.onClick({ item, index })}>
              {item.image ? (
                <img
                  className="single-row-menu__button-image"
                  src={item.image}
                  alt=""
                />
              ) : null}
              {item.text}
            </Button>
          </li>
        ))}
      </ul>
    </menu>
  );
};

function getClassName(props: Props) {
  return classNames({
    'single-row-menu': true,
    'single-row-menu--no-border': props.noBorder,
    [props.className || '']: props.className,
  });
}

SingleRowMenu.defaultProps = {
  content: [],
};

export default SingleRowMenu;
