// @flow
import React from 'react';
import { connect } from 'react-redux';
import { message } from 'antd';
import Avatar from '../../components/Avatar/Avatar';
import Currency from '../../components/Currency/Currency';
import GroupList from '../../components/GroupList/GroupList';
import ModalNewGroup from '../../components/ModalNewGroup/ModalNewGroup';
import Table from '../../components/Table/Table';
import account from '../../redux/modules/account/account.containers';
import accountFollowers from '../../redux/modules/accountFollowers/accountFollowers.containers';
import accountGroups from '../../redux/modules/accountGroups/accountGroups.containers';
import withDisplayDimensions from '../../hoc/withDisplayDimensions';
import type { WithDisplayDimensionsOutputProps } from '../../hoc/withDisplayDimensions';
import './DashboardGroupsPage.scss';
import { URL_DASHBOARD_PEOPLE_GROUPS_PAGE } from '../../config/urls';
import Button from '../../components/Button/Button';
import type { AccountMapStateToProps } from '../../redux/modules/account/account.containers';
import {
  allowedGroupType,
  isCandidate,
  isCitizen,
} from '../../utilities/authorization';
import type { Follower } from '../../services/RoRApiProvider';
import isPrytanyVerified from '../../utilities/isPrytanyVerified';

type Props = AccountMapStateToProps &
  WithDisplayDimensionsOutputProps & {
    accountFollowersData: any,
    accountFollowersError: any,
    accountFollowersIsLoading: boolean,
    accountGroupsData: any,
    accountGroupsError: any,
    accountGroupsIsLoading: boolean,
    getAccountFollowers: Function,
    getAccountGroups: Function,
    history: any,
    location: any,
  };

type State = {
  newGroupModalOpen: boolean,
  displayCreateGroupButton: boolean,
  tableSelectedRows: any[],
  tableSelectedRowKeys: number[],
};

class DashboardGroupsPage extends React.Component<Props, State> {
  constructor(props) {
    super(props);

    this.state = {
      newGroupModalOpen: false,
      displayCreateGroupButton: false,
      tableSelectedRows: [],
      tableSelectedRowKeys: [],
    };
  }

  componentDidMount() {
    if (typeof window !== 'undefined') {
      this.props.getAccountGroups();
      this.props.getAccountFollowers();
      message.loading('Updating Groups and Followers...', 3);
    }
  }

  componentDidUpdate() {
    if (
      !this.state.displayCreateGroupButton &&
      !this.props.accountFollowersIsLoading &&
      (!this.props.accountFollowersData ||
        (this.props.accountFollowersData &&
          !this.props.accountFollowersData.followers.length))
    ) {
      this.setState(() => ({ displayCreateGroupButton: true }));
    }
  }

  render() {
    const { user } = this.props.accountData || {};
    return (
      <div className="dashboard-groups-page container-fluid">
        <div className="row fix-width">
          <div className="col-md-3">
            <GroupList
              groupType={allowedGroupType(user)}
              addNewGroupButton={true}
              groups={
                (this.props.accountGroupsData &&
                  this.props.accountGroupsData.groups) ||
                []
              }
              onCreateGroupButtonClick={this.toggleNewGroupModalVisibility}
            />
          </div>
          <div className="col-md-9 col-12">
            <div className="dashboard-page__table-wrapper">
              <div className="row">
                <div className="col-md-3 col-12">
                  <h3>Followers</h3>
                </div>
                <div className="col-md-9 col-12 text-md-right">
                  <h4>
                    Select constituents to add to a group or to send
                    communication
                  </h4>
                </div>
              </div>
              <Table {...this.getTableProps()} />
            </div>
          </div>
        </div>
        <ModalNewGroup
          preselectedUsers={this.state.tableSelectedRows}
          isOpen={this.state.newGroupModalOpen}
          onCloseRequest={this.toggleNewGroupModalVisibility}
          onSuccess={this.handleNewGroupSuccess}
          onError={this.handleNewGroupError}
        />
      </div>
    );
  }

  getTableProps() {
    const rowSelection = {
      onChange: this.handleTableRowSelection,
      getCheckboxProps: (record: any) => ({
        disabled: false,
        name: record.key,
      }),
      selectedRowKeys: this.state.tableSelectedRowKeys,
    };

    return {
      rowSelection,
      dataSource: this.getTableData(),
      columns: this.getTableColumns(),
      pagination: false,
      loading: this.props.accountFollowersIsLoading,
    };
  }

  getTableData() {
    const followers = this.props.accountFollowersData
      ? this.props.accountFollowersData.followers
      : [];

    return followers.map((follower: any) => ({
      key: follower.id,
      name: `${follower.first_name} ${follower.last_name}`.trim(),
      state: 'TBD',
      sharedInterests: follower.shared_interests || 0,
      contributed: follower.donations.contributed || 0,
      follower,
    }));
  }

  getTableColumns() {
    let columns = [];

    if (this.props.isMobile) {
      columns = [
        {
          title: 'NAME',
          dataIndex: 'name',
          key: 'name',
          defaultSortOrder: 'ascend',
          render: (name: string, item: { follower: Follower }) => {
            const { follower } = item;
            return (
              <React.Fragment>
                <Avatar
                  isCandidate={isCandidate(follower)}
                  verifiedLevel={follower.verified_level}
                  verifiedDetail={follower.verified_detail}
                  className="dashboard-page__table-cell__name-avatar"
                  source={follower.profile_image}
                  type="tiny"
                />
                <span className="dashboard-page__table-cell__name-text">
                  {name}
                </span>
              </React.Fragment>
            );
          },
          sorter: (a: any, b: any) => a.name.localeCompare(b.name),
        },
        {
          title: 'STATE',
          dataIndex: 'state',
          key: 'state',
          sorter: (a: any, b: any) => a.state.localeCompare(b.state),
        },
      ];
    } else {
      columns = [
        {
          title: 'NAME',
          dataIndex: 'name',
          key: 'name',
          defaultSortOrder: 'ascend',
          render: (name: string, item: { follower: Follower }) => {
            const { follower } = item;
            return (
              <React.Fragment>
                <Avatar
                  isCandidate={isCandidate(follower)}
                  verifiedLevel={follower.verified_level}
                  verifiedDetail={follower.verified_detail}
                  className="dashboard-page__table-cell__name-avatar"
                  source={follower.profile_image}
                  type="tiny"
                />
                <span className="dashboard-page__table-cell__name-text">
                  {name}
                </span>
              </React.Fragment>
            );
          },
          sorter: (a: any, b: any) => a.name.localeCompare(b.name),
        },
        {
          title: 'STATE',
          dataIndex: 'state',
          key: 'state',
          sorter: (a: any, b: any) => a.state.localeCompare(b.state),
        },
        {
          title: 'SHARED INTERESTS',
          dataIndex: 'sharedInterests',
          key: 'sharedInterests',
          sorter: (a: any, b: any) => a.sharedInterests - b.sharedInterests,
        },
        {
          title: 'CONTRIBUTED',
          dataIndex: 'contributed',
          key: 'contributed',
          sorter: (a: any, b: any) => a.contributed - b.contributed,
          render: (amount: number) => <Currency amount={amount / 100} />,
        },
      ];
    }

    return columns;
  }

  handleTableRowSelection = (
    selectedRowKeys: number[],
    selectedRows: any[],
  ) => {
    this.setState(() => ({
      tableSelectedRows: selectedRows,
      tableSelectedRowKeys: selectedRowKeys,
      displayCreateGroupButton: selectedRows.length !== 0,
    }));
  };

  toggleNewGroupModalVisibility = () => {
    this.setState((prevState: State) => ({
      newGroupModalOpen: !prevState.newGroupModalOpen,
    }));
  };

  handleNewGroupSuccess = (newGroupData: any) => {
    this.setState(
      () => ({ newGroupModalOpen: false }),
      () => {
        message.success(
          <SuccessMessage
            history={this.props.history}
            location={this.props.location}
          />,
          10,
        );
      },
    );
  };

  handleNewGroupError = (newGroupError: any) => {
    message.error(
      newGroupError.localMessage ||
        'An error has occurred and the group was not created.',
    );
  };
}

const SuccessMessage = ({
  history,
  location,
}: {
  history: any,
  location: any,
}) => {
  function handleClick() {
    history.push(URL_DASHBOARD_PEOPLE_GROUPS_PAGE);
  }

  return (
    <React.Fragment>
      <span>A new group was created. </span>
      {location.pathname !== URL_DASHBOARD_PEOPLE_GROUPS_PAGE && (
        <Button
          buttonType="link"
          onClick={handleClick}
          size="small"
          className="dashboard-page__message-link">
          Go to groups section
        </Button>
      )}
    </React.Fragment>
  );
};

const mapStateToProps = (state, ownProps) => {
  return {
    ...account.mapStateToProps(state),
    ...accountGroups.mapStateToProps(state),
    ...accountFollowers.mapStateToProps(state),
    ...ownProps,
  };
};

const mapDispatchToProps = dispatch => {
  return {
    ...accountFollowers.mapDispatchToProps(dispatch),
    ...accountGroups.mapDispatchToProps(dispatch),
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(withDisplayDimensions(DashboardGroupsPage));
