// @flow
import React from 'react';
import classNames from 'classnames';
import Modal from '../Modal/Modal';
import GroupRename from '../GroupRename/GroupRename';

type Props = {
  isOpen: boolean,
  onCloseRequest: Function,
  className?: string,
};

class ModalRenameGroup extends React.Component<Props, {}> {
  render() {
    const { isOpen, onCloseRequest, className, ...rest } = this.props;

    return (
      <Modal
        contentLabel="Rename Group"
        isOpen={isOpen}
        onCloseRequest={onCloseRequest}
        className={this.getClassName()}
        noPaddingContent
        size="small"
        {...rest}>
        {isOpen ? (
          <GroupRename
            onSuccess={this.handleSuccess}
            onCancel={this.props.onCloseRequest}
          />
        ) : null}
      </Modal>
    );
  }

  getClassName() {
    return classNames({
      'modal-rename-group': true,
      [this.props.className || '']: this.props.className,
    });
  }

  handleSuccess = () => {
    if (this.props.onCloseRequest) {
      this.props.onCloseRequest();
    }
  };
}

export default ModalRenameGroup;
