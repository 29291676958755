// @flow
import makeDefaultServerCallReducer from '../../utilities/makeDefaultServerCallReducer';
import {
  USER_CANDIDATE_SUB_INTERESTS_ERROR,
  USER_CANDIDATE_SUB_INTERESTS_LOADING,
  USER_CANDIDATE_SUB_INTERESTS_SUCCESS,
  USER_CANDIDATE_SUB_INTERESTS_RESET,
} from './userCandidateSubInterests.constants';

const initialState = {
  isLoading: false,
  error: null,
  data: null,
};

export const reducer = makeDefaultServerCallReducer({
  loadingActions: [USER_CANDIDATE_SUB_INTERESTS_LOADING],
  successActions: [USER_CANDIDATE_SUB_INTERESTS_SUCCESS],
  errorActions: [USER_CANDIDATE_SUB_INTERESTS_ERROR],
  resetActions: [USER_CANDIDATE_SUB_INTERESTS_RESET],
  initialState,
});
