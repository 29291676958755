// @flow
import React, { Component, Fragment } from 'react';
import Modal from '../Modal/Modal';
import type { ModalProps } from '../Modal/Modal';
import classNames from 'classnames';
import './ModalDeactivateAccount.scss';
import Button from '../Button/Button';
import type {
  AccountMapDispatchToProps,
  AccountMapStateToProps,
} from '../../redux/modules/account/account.containers';
import account from '../../redux/modules/account/account.containers';
import { connect } from 'react-redux';

type Props = ModalProps &
  AccountMapStateToProps &
  AccountMapDispatchToProps & {};

type State = {};

class ModalDeactivateAccount extends Component<Props, State> {
  state = {};

  render() {
    const { isOpen, ...modalProps } = this.props;
    return (
      <Modal
        isOpen={isOpen}
        {...modalProps}
        className={this.getClassName()}
        head={'Confirm Deactivation'}
        noPaddingContent
        size="micro">
        {isOpen ? this.renderContent() : null}
      </Modal>
    );
  }

  renderContent() {
    return (
      <Fragment>
        <div className="modal-deactivate-account__body">
          <div className="row">
            <div className="col-12">
              <div className="modal-deactivate-account__message-wrapper">
                <p>
                  Deactivating your account means others can no longer view your
                  profile, or find it in their search results. You will also not
                  be able to contribute to candidates.
                </p>
              </div>
            </div>
          </div>
        </div>
        <div className="modal-deactivate-account__footer">
          <div className="row">
            <div className="col-4 text-right">
              <Button
                loading={this.props.accountIsLoading}
                type="button"
                onClick={this.handleCloseRequest}
                buttonType="link"
                noBorder
                className="modal-new-group__cancel-button">
                Cancel
              </Button>
            </div>
            <div className="col-8">
              <Button
                loading={this.props.accountIsLoading}
                onClick={this.handleDeactivateAccount}
                buttonType="primary"
                noBorder
                type="button">
                Deactivate my account
              </Button>
            </div>
          </div>
        </div>
      </Fragment>
    );
  }

  getClassName() {
    return classNames({
      'modal-deactivate-account': true,
      [this.props.className || '']: this.props.className,
    });
  }

  handleCloseRequest = () => {
    if (this.props.onCloseRequest) {
      this.props.onCloseRequest();
    }
  };

  handleDeactivateAccount = () => {
    this.props.accountDeactivate();
  };
}

const mapStateToProps = (state, ownProps) => {
  return {
    ...account.mapStateToProps(state),
    ...ownProps,
  };
};

const mapDispatchToProps = dispatch => {
  return {
    ...account.mapDispatchToProps(dispatch),
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(ModalDeactivateAccount);
