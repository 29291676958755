// @flow
import makeDefaultServerCallReducer from '../../utilities/makeDefaultServerCallReducer';
import {
  SEARCH_GROUPS_ERROR,
  SEARCH_GROUPS_LOADING,
  SEARCH_GROUPS_SUCCESS,
  SEARCH_GROUPS_RESET,
} from './searchGroups.constants';

const initialState = {
  isLoading: false,
  error: null,
  data: null,
};

export const reducer = makeDefaultServerCallReducer({
  loadingActions: [SEARCH_GROUPS_LOADING],
  successActions: [SEARCH_GROUPS_SUCCESS],
  errorActions: [SEARCH_GROUPS_ERROR],
  resetActions: [SEARCH_GROUPS_RESET],
  initialState,
});
