import { postAdd, postAddReset as reset } from './postAdd.actions';
import type { DefaultResponse } from '../../../services/RoRApiProvider';

export const mapStateToProps = state => {
  return {
    postAddData: state.postAdd.data,
    postAddError: state.postAdd.error,
    postAddIsLoading: state.postAdd.isLoading,
  };
};

export const mapDispatchToProps = dispatch => {
  return {
    createPost(payload) {
      dispatch(postAdd(payload));
    },
    postAddReset() {
      dispatch(reset());
    },
  };
};

export default {
  mapStateToProps,
  mapDispatchToProps,
};

export type PostAddMapStateToProps = {
  postAddData: DefaultResponse,
  postAddError: any,
  postAddIsLoading: boolean,
};

export type PostAddMapDispatchToProps = {
  createPost: () => void,
  postAddReset: () => void,
};
