// @flow
import { createAction } from 'redux-actions';
import {
  ACCOUNT_VERIFY_ERROR,
  ACCOUNT_VERIFY_LOADING,
  ACCOUNT_VERIFY_RESET,
  ACCOUNT_VERIFY_SUCCESS,
} from './accountVerify.constants';
import { instance as rorUsersApiProvider } from '../../../services/RoRUsersApiProvider';
import type {
  PostIDScanVerifyPayload,
  PostSsaVerifyPayload,
} from '../../../services/RoRUsersApiProvider';
import type { IdScanTypeOption } from '../../../constants/IdScanType';
import VerificationType from '../../../constants/VerificationType';

export const accountVerifyError = createAction(ACCOUNT_VERIFY_ERROR);

export const accountVerifyLoading = createAction(ACCOUNT_VERIFY_LOADING);

export const accountVerifySuccess = createAction(ACCOUNT_VERIFY_SUCCESS);

export const accountVerifyReset = createAction(ACCOUNT_VERIFY_RESET);

export const verifyAccount = (payload: VerifyAccountActionPayload) => {
  return async (dispatch: Function) => {
    try {
      dispatch(accountVerifyLoading());
      let response;

      if (payload.type === VerificationType.IDScan) {
        const { type, ...rest } = payload;
        response = await rorUsersApiProvider.verifyAccountWithIDScan({
          ...rest,
        });
      } else {
        const { type, ...rest } = payload;

        response = await rorUsersApiProvider.verifyAccountWithSSA({ ...rest });
      }

      dispatch(accountVerifySuccess(response));
    } catch (error) {
      console.error(error);
      dispatch(accountVerifyError(error));
    }
  };
};

export type VerifyAccountActionPayload =
  | IDScanVerificationActionPayload
  | SSAVerificationActionPayload;

export type IDScanVerificationActionPayload = PostIDScanVerifyPayload & {
  type: 'IDScan',
  scanType: IdScanTypeOption,
};

export type SSAVerificationActionPayload = PostSsaVerifyPayload & {
  type: 'SSA',
};
