// @flow
import { createAction } from 'redux-actions';
import {
  USER_PROFILE_FOLLOWERS_ERROR,
  USER_PROFILE_FOLLOWERS_LOADING,
  USER_PROFILE_FOLLOWERS_RESET,
  USER_PROFILE_FOLLOWERS_SUCCESS,
} from './userProfileFollowers.constants';
import { instance as rorProfileApiProvider } from '../../../services/RoRProfileApiProvider';

export const userProfileFollowersError = createAction(
  USER_PROFILE_FOLLOWERS_ERROR,
);

export const userProfileFollowersLoading = createAction(
  USER_PROFILE_FOLLOWERS_LOADING,
);

export const userProfileFollowersSuccess = createAction(
  USER_PROFILE_FOLLOWERS_SUCCESS,
);

export const userProfileFollowersReset = createAction(
  USER_PROFILE_FOLLOWERS_RESET,
);

export const getUserProfileFollowers = (payload: any) => {
  return async (dispatch: Function) => {
    try {
      dispatch(userProfileFollowersLoading());

      const response = await rorProfileApiProvider.getFollowers(payload);

      dispatch(userProfileFollowersSuccess(response));
    } catch (error) {
      dispatch(userProfileFollowersError(error));
    }
  };
};
