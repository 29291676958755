// @flow
import { createAction } from 'redux-actions';
import {
  GROUP_UPDATE_ERROR,
  GROUP_UPDATE_LOADING,
  GROUP_UPDATE_RESET,
  GROUP_UPDATE_SUCCESS,
} from './groupUpdate.constants';
import { instance as rorGroupsApiProvider } from '../../../services/RoRGroupsApiProvider';
import type { PutGroupUpdatePayload } from '../../../services/RoRGroupsApiProvider';
import { groupDetailsUpdateGroup } from '../groupDetails/groupDetails.actions';

export const groupUpdateError = createAction(GROUP_UPDATE_ERROR);

export const groupUpdateLoading = createAction(GROUP_UPDATE_LOADING);

export const groupUpdateSuccess = createAction(GROUP_UPDATE_SUCCESS);

export const groupUpdateReset = createAction(GROUP_UPDATE_RESET);

export const updateGroup = (payload: PutGroupUpdatePayload) => {
  return async (dispatch: Function) => {
    try {
      dispatch(groupUpdateLoading());
      const response = await rorGroupsApiProvider.groupUpdate(payload);

      dispatch(groupUpdateSuccess(response));
      dispatch(groupDetailsUpdateGroup(response));
    } catch (error) {
      dispatch(groupUpdateError(error));
    }
  };
};
