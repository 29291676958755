import { sendMessage, sendMessageReset as reset } from './sendMessage.actions';
import type { DefaultResponse } from '../../../services/RoRApiProvider';

export const mapStateToProps = state => {
  return {
    sendMessageData: state.sendMessage.data,
    sendMessageError: state.sendMessage.error,
    sendMessageIsLoading: state.sendMessage.isLoading,
  };
};

export const mapDispatchToProps = dispatch => {
  return {
    sendMessage(payload) {
      dispatch(sendMessage(payload));
    },
    sendMessageReset() {
      dispatch(reset());
    },
  };
};

export default {
  mapStateToProps,
  mapDispatchToProps,
};

export type SendMessageMapStateToProps = {
  sendMessageData: DefaultResponse,
  sendMessageError: any,
  sendMessageIsLoading: boolean,
};

export type SendMessageMapDispatchToProps = {
  sendMessage: () => void,
  sendMessageReset: () => void,
};
