// @flow
import React from 'react';
import { connect } from 'react-redux';
import { message, Popconfirm } from 'antd';
import Button from '../Button/Button';
import groupDelete from '../../redux/modules/groupDelete/groupDelete.containers';
import groupDetails from '../../redux/modules/groupDetails/groupDetails.containers';
import type { GroupDetailsMapStateToProps } from '../../redux/modules/groupDetails/groupDetails.containers';
import withDisplayDimensions from '../../hoc/withDisplayDimensions';
import type { WithDisplayDimensionsOutputProps } from '../../hoc/withDisplayDimensions';
import type {
  GroupDeleteMapDispatchToProps,
  GroupDeleteMapStateToProps,
} from '../../redux/modules/groupDelete/groupDelete.containers';

type Props = GroupDetailsMapStateToProps &
  GroupDeleteMapDispatchToProps &
  GroupDeleteMapStateToProps &
  WithDisplayDimensionsOutputProps & {
    onSuccess?: () => void,
    onCancel?: () => void,
    onError?: (error: any) => void,
  };

class GroupDelete extends React.Component<Props, {}> {
  constructor(props: Props) {
    super(props);

    this.props.groupDeleteReset();
  }

  componentDidUpdate(prevProps: Props) {
    if (!prevProps.groupDeleteData && this.props.groupDeleteData) {
      message.success('Group was successfully removed.');

      if (this.props.onSuccess) {
        this.props.onSuccess(this.props.groupDeleteData);
      }
    }

    if (!prevProps.groupDeleteError && this.props.groupDeleteError) {
      const error =
        this.props.groupDeleteError.localMessage ||
        "An error has occurred and the group wasn't deleted";

      message.error(error);

      if (this.props.onError) {
        this.props.onError(error);
      }
    }
  }

  render() {
    return (
      <div className="group-delete">
        <div className="row fix-width">
          <div className="col-12">
            <p className="my-5">
              Are you sure you want to remove this group? This action can't be
              undone.
            </p>
          </div>
          <div className="col-12">
            <div className="group-delete__footer row justify-content-md-end py-5">
              <Button
                onClick={this.handleCancel}
                buttonType="outline"
                type="button"
                className="col-4 col-md-3 col-lg-2 mx-2 mb-3 mb-md-0"
                size={this.props.isMobile ? 'small' : 'normal'}
                disabled={this.props.groupDeleteIsLoading}>
                Cancel
              </Button>
              <Popconfirm
                title="You're about to remove this group. Are you sure?"
                onConfirm={this.handleDeleteGroupConfirm}
                okText="Yes"
                cancelText="No">
                <Button
                  buttonType="primary"
                  type="button"
                  className="col-4 col-md-4 col-lg-3 mx-2"
                  size={this.props.isMobile ? 'small' : 'normal'}
                  loading={this.props.groupDeleteIsLoading}>
                  Remove group
                </Button>
              </Popconfirm>
            </div>
          </div>
        </div>
      </div>
    );
  }

  handleDeleteGroupConfirm = () => {
    this.props.groupDelete({ groupId: this.props.groupDetailsData.group.id });
  };

  handleCancel = () => {
    if (this.props.onCancel) {
      this.props.onCancel();
    }
  };
}

const mapStateToProps = (state, ownProps) => {
  return {
    ...groupDetails.mapStateToProps(state),
    ...groupDelete.mapStateToProps(state),
    ...ownProps,
  };
};

const mapDispatchToProps = dispatch => {
  return {
    ...groupDetails.mapDispatchToProps(dispatch),
    ...groupDelete.mapDispatchToProps(dispatch),
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(withDisplayDimensions(GroupDelete));
