// @flow
import makeDefaultServerCallReducer from '../../utilities/makeDefaultServerCallReducer';
import {
  RACE_UNFOLLOW_ERROR,
  RACE_UNFOLLOW_LOADING,
  RACE_UNFOLLOW_SUCCESS,
  RACE_UNFOLLOW_RESET,
} from './raceUnfollow.constants';

const initialState = {
  isLoading: false,
  error: null,
  data: null,
};

export const reducer = makeDefaultServerCallReducer({
  loadingActions: [RACE_UNFOLLOW_LOADING],
  successActions: [RACE_UNFOLLOW_SUCCESS],
  errorActions: [RACE_UNFOLLOW_ERROR],
  resetActions: [RACE_UNFOLLOW_RESET],
  initialState,
});
