// @flow
import makeDefaultServerCallReducer from '../../utilities/makeDefaultServerCallReducer';
import {
  USER_PROFILE_FOLLOWING_SUCCESS,
  USER_PROFILE_FOLLOWING_RESET,
  USER_PROFILE_FOLLOWING_LOADING,
  USER_PROFILE_FOLLOWING_ERROR,
} from './userProfileFollowing.constants';

const initialState = {
  isLoading: false,
  error: null,
  data: null,
};

export const reducer = makeDefaultServerCallReducer({
  loadingActions: [USER_PROFILE_FOLLOWING_LOADING],
  successActions: [USER_PROFILE_FOLLOWING_SUCCESS],
  errorActions: [USER_PROFILE_FOLLOWING_ERROR],
  resetActions: [USER_PROFILE_FOLLOWING_RESET],
  initialState,
});
