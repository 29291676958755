// @flow
import { createAction } from 'redux-actions';
import {
  CANDIDATES_LIST_ERROR,
  CANDIDATES_LIST_SUCCESS,
  CANDIDATES_LIST_LOADING,
  CANDIDATES_LIST_RESET,
} from './listCandidates.constants';
import { instance as rorUsersApiProvider } from '../../../services/RoRUsersApiProvider';
import type { GetUserListPayload } from '../../../services/RoRUsersApiProvider';

export const candidatesListError = createAction(CANDIDATES_LIST_ERROR);

export const candidatesListLoading = createAction(CANDIDATES_LIST_LOADING);

export const candidatesListSuccess = createAction(CANDIDATES_LIST_SUCCESS);

export const candidatesListReset = createAction(CANDIDATES_LIST_RESET);

export const getCandidatesList = (payload: GetUserListPayload) => {
  return async (dispatch: Function) => {
    // TODO: Improve security: should save the token on the react server.
    // TODO: Use rememberMe
    try {
      dispatch(candidatesListReset());

      dispatch(candidatesListLoading());

      const role = payload.include_political_parties ? '2,5' : 2;
      const response = await rorUsersApiProvider.userList({ ...payload, role });

      dispatch(candidatesListSuccess(response));
    } catch (error) {
      dispatch(candidatesListError(error));
    }
  };
};
