// @flow
import { createAction } from 'redux-actions';
import {
  GROUP_DELETE_ERROR,
  GROUP_DELETE_LOADING,
  GROUP_DELETE_RESET,
  GROUP_DELETE_SUCCESS,
} from './groupDelete.constants';
import { instance as rorGroupsApiProvider } from '../../../services/RoRGroupsApiProvider';
import type { DeleteGroupDeletePayload } from '../../../services/RoRGroupsApiProvider';

export const groupDeleteError = createAction(GROUP_DELETE_ERROR);

export const groupDeleteLoading = createAction(GROUP_DELETE_LOADING);

export const groupDeleteSuccess = createAction(GROUP_DELETE_SUCCESS);

export const groupDeleteReset = createAction(GROUP_DELETE_RESET);

export const deleteGroup = (payload: DeleteGroupDeletePayload) => {
  return async (dispatch: Function) => {
    try {
      dispatch(groupDeleteLoading());
      const response = await rorGroupsApiProvider.groupDelete(payload);

      dispatch(groupDeleteSuccess(response));
    } catch (error) {
      dispatch(groupDeleteError(error));
    }
  };
};
