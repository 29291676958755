export const CONTRIBUTION_DETAILS_LOADING =
  '@@prytany/global/CONTRIBUTION_DETAILS_LOADING';
export const CONTRIBUTION_DETAILS_SUCCESS =
  '@@prytany/global/CONTRIBUTION_DETAILS_SUCCESS';
export const CONTRIBUTION_DETAILS_ERROR =
  '@@prytany/global/CONTRIBUTION_DETAILS_ERROR';
export const CONTRIBUTION_DETAILS_RESET =
  '@@prytany/global/CONTRIBUTION_DETAILS_RESET';
export const CONTRIBUTION_DETAILS_CHANGE_PLEDGE_CLAIM_STATUS =
  '@@prytany/global/CONTRIBUTION_DETAILS_CHANGE_PLEDGE_CLAIM_STATUS';
