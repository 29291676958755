// @flow
import _get from 'lodash/get';
import _cloneDeep from 'lodash/cloneDeep';
import {
  ACCOUNT_FOLLOWINGS_SUCCESS,
  ACCOUNT_FOLLOWINGS_RESET,
  ACCOUNT_FOLLOWINGS_LOADING,
  ACCOUNT_FOLLOWINGS_ERROR,
  ACCOUNT_FOLLOWINGS_ADD,
  ACCOUNT_FOLLOWINGS_REMOVE,
} from './accountFollowings.constants';
import makeDefaultServerCallReducer from '../../utilities/makeDefaultServerCallReducer';
import _remove from 'lodash/remove';

const initialState = {
  isLoading: false,
  error: null,
  data: null,
};

const accountAddFollowing = (state, action) => {
  const { payload } = action;
  const { user } = payload;

  const newFollowing = {
    id: user.id,
    role: user.role,
    first_name: user.first_name,
    is_public: user.is_public,
    last_name: user.last_name,
    profile_image: user.profile_image,
  };

  const followings = _get(state, 'data.followings', []);
  let data = _cloneDeep(state.data) || {};

  followings.push(newFollowing);
  data.followings = followings;

  return {
    ...state,
    data,
  };
};

const accountRemoveFollowing = (state, action) => {
  if (_get(state, 'data.followings')) {
    const data = _cloneDeep(state.data);

    _remove(data.followings, item => item.id === action.payload.user.id);

    return {
      ...state,
      data,
    };
  }

  return state;
};

export const reducer = makeDefaultServerCallReducer({
  loadingActions: [ACCOUNT_FOLLOWINGS_LOADING],
  successActions: [ACCOUNT_FOLLOWINGS_SUCCESS],
  errorActions: [ACCOUNT_FOLLOWINGS_ERROR],
  resetActions: [ACCOUNT_FOLLOWINGS_RESET],
  otherActions: [
    { name: ACCOUNT_FOLLOWINGS_ADD, callback: accountAddFollowing },
    { name: ACCOUNT_FOLLOWINGS_REMOVE, callback: accountRemoveFollowing },
  ],
  initialState,
});
