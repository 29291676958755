// @flow
import { createAction } from 'redux-actions';
import {
  CONTRIBUTION_PLEDGE_DONATION_MAKE_ERROR,
  CONTRIBUTION_PLEDGE_DONATION_MAKE_LOADING,
  CONTRIBUTION_PLEDGE_DONATION_MAKE_RESET,
  CONTRIBUTION_PLEDGE_DONATION_MAKE_SUCCESS,
} from './contributionPledgeDonationMake.constants';
import { instance as rorContributionsApiProvider } from '../../../services/RoRContributionsApiProvider';
import type { PostMakePledgeDonationPayload } from '../../../services/RoRContributionsApiProvider';
import { userDetailsAddContribution } from '../userDetails/userDetails.actions';
import { groupDetailsAddContribution } from '../groupDetails/groupDetails.actions';

export const contributionPledgeDonationMakeError = createAction(
  CONTRIBUTION_PLEDGE_DONATION_MAKE_ERROR,
);

export const contributionPledgeDonationMakeLoading = createAction(
  CONTRIBUTION_PLEDGE_DONATION_MAKE_LOADING,
);

export const contributionPledgeDonationMakeSuccess = createAction(
  CONTRIBUTION_PLEDGE_DONATION_MAKE_SUCCESS,
);

export const contributionPledgeDonationMakeReset = createAction(
  CONTRIBUTION_PLEDGE_DONATION_MAKE_RESET,
);

export const makePledgeDonation = (payload: PostMakePledgeDonationPayload) => {
  return async (dispatch: Function) => {
    try {
      dispatch(contributionPledgeDonationMakeLoading());

      const response = await rorContributionsApiProvider.makePledgeDonation(
        payload,
      );

      dispatch(contributionPledgeDonationMakeSuccess(response));
      dispatch(
        userDetailsAddContribution({
          userId: payload.candidate_id,
          amountInCents: payload.amount_in_cents,
          contributionType: 'pledge',
        }),
      );

      if (payload.group_id) {
        dispatch(
          groupDetailsAddContribution({
            groupId: payload.group_id,
            amountInCents: payload.amount_in_cents,
            contributionType: 'pledge',
          }),
        );
      }
    } catch (error) {
      dispatch(contributionPledgeDonationMakeError(error));
    }
  };
};
