// @flow
const IdScanType = Object.freeze({
  DRIVERS_LICENSE: 'drivers_license',
  US_PASSPORT: 'us_passport',
  GREENCARD: 'greencard'
});

export type IdScanTypeOption = 'drivers_license' | 'us_passport' | 'greencard';

export default IdScanType;
