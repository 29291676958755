// @flow
import { createAction } from 'redux-actions';
import {
  CONTRIBUTION_ADD_NEW_ACH_ERROR,
  CONTRIBUTION_ADD_NEW_ACH_LOADING,
  CONTRIBUTION_ADD_NEW_ACH_RESET,
  CONTRIBUTION_ADD_NEW_ACH_SUCCESS,
} from './contributionAddNewAch.constants';
import { accountPaymentMethodsList } from '../accountPaymentMethods/accountPaymentMethods.actions';
import { instance as rorContributionsApiProvider } from '../../../services/RoRContributionsApiProvider';
import type { PostAddNewAchPayload } from '../../../services/RoRContributionsApiProvider';

export const contributionAddNewAchError = createAction(
  CONTRIBUTION_ADD_NEW_ACH_ERROR,
);

export const contributionAddNewAchLoading = createAction(
  CONTRIBUTION_ADD_NEW_ACH_LOADING,
);

export const contributionAddNewAchSuccess = createAction(
  CONTRIBUTION_ADD_NEW_ACH_SUCCESS,
);

export const contributionAddNewAchReset = createAction(
  CONTRIBUTION_ADD_NEW_ACH_RESET,
);

export const addNewAch = (payload: PostAddNewAchPayload) => {
  return async (dispatch: Function) => {
    try {
      dispatch(contributionAddNewAchLoading());

      const response = await rorContributionsApiProvider.addNewAch(payload);

      dispatch(contributionAddNewAchSuccess({ success: true, ...response }));
      dispatch(accountPaymentMethodsList());
    } catch (error) {
      dispatch(contributionAddNewAchError(error));
    }
  };
};
