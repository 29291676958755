// @flow
import { createAction } from 'redux-actions';
import {
  CONTACT_PRYTANY_MESSAGE_RESET,
  CONTACT_PRYTANY_MESSAGE_LOADING,
  CONTACT_PRYTANY_MESSAGE_SUCCESS,
  CONTACT_PRYTANY_MESSAGE_ERROR,
} from './contactPrytanyMessage.constants';
import type ContactPrytanyPayload from '../../../services/RoRUsersApiProvider';
import { instance as rorApiProvider } from '../../../services/RoRUsersApiProvider';

export const contactPrytanyMessageError = createAction(
  CONTACT_PRYTANY_MESSAGE_ERROR,
);

export const contactPrytanyMessageLoading = createAction(
  CONTACT_PRYTANY_MESSAGE_LOADING,
);

export const contactPrytanyMessageSuccess = createAction(
  CONTACT_PRYTANY_MESSAGE_SUCCESS,
);

export const contactPrytanyMessageReset = createAction(
  CONTACT_PRYTANY_MESSAGE_RESET,
);

export const contactPrytanyMessage = (payload: ContactPrytanyPayload) => {
  return async (dispatch: Function) => {
    try {
      dispatch(contactPrytanyMessageReset());

      dispatch(contactPrytanyMessageLoading());

      const response = await rorApiProvider.contactPrytanyMessage(payload);

      dispatch(contactPrytanyMessageSuccess(response));
    } catch (error) {
      dispatch(contactPrytanyMessageError(error));
    }
  };
};
