// @flow
import React from 'react';
import moment from 'moment';
import { Popconfirm } from 'antd';
import Avatar from '../Avatar/Avatar';
import Button from '../Button/Button';
import Link from '../Link/Link';
import { URL_GROUP_PAGE, URL_USER_PUBLIC_PROFILE } from '../../config/urls';
import './InboxMessage.scss';
import { isCandidate, isCitizen } from '../../utilities/authorization';
import isPrytanyVerified from '../../utilities/isPrytanyVerified';
import type { UserListView } from '../../services/RoRUsersApiProvider';

type Props = {
  sender: UserListView,
  date: string,
  details: { [key: string]: any },
  type: string,
  onRemoveButtonClick: () => void,
  onJoinGroupButtonClick: () => void,
  joinGroupLoading?: boolean,
  removeLoading?: boolean,
  body: string,
};

const InboxMessage = (props: Props) => {
  const { sender, date, body, details } = props;
  const messageMoment = moment(date);
  const senderProfileUrl = URL_USER_PUBLIC_PROFILE.replace(':id', sender.id);
  const groupUrl = details.group
    ? URL_GROUP_PAGE.replace(':groupId', details.group.id)
    : '';

  return (
    <div className="inbox-message">
      <div className="inbox-message__head">
        <Link href={senderProfileUrl}>
          <Avatar
            source={sender.profile_image}
            type="x-small"
            isCandidate={isCandidate(sender)}
            verifiedLevel={sender.verified_level}
            verifiedDetail={sender.verified_detail}
          />
        </Link>
        <div className="inbox-message__head-name-date">
          <div>
            <Link className="inbox-message__head-name" href={senderProfileUrl}>
              {`${sender.first_name} ${sender.last_name}`}
            </Link>
          </div>
          <span className="inbox-message__head-date">
            {messageMoment.format('MMM').toUpperCase()}{' '}
            {messageMoment.format('Do, HH:mm A')}
          </span>
        </div>
        <div className="inbox-message__head-remove">
          <Popconfirm
            title="Are you sure you want to delete this message?"
            onConfirm={props.onRemoveButtonClick}
            okText="Yes"
            cancelText="No">
            <Button
              buttonType="link"
              type="button"
              loading={props.removeLoading}
              spinnerProps={{ type: 'dark' }}>
              <img src="/images/icon_trash.png" alt="" />
            </Button>
          </Popconfirm>
        </div>
      </div>
      <hr className="mt-4" />
      <div className="inbox-message__body">
        {details.group && <h2>Invitation: {details.group.name}</h2>}
        <p>{body}</p>

        {groupUrl && (
          <div className="inbox-message__body-card">
            <Link href={groupUrl}>
              <Avatar
                source={details.group.group_image}
                type="x-small"
                isCandidate={false}
              />
            </Link>
            <div className="inbox-message__body-card-description">
              <div>
                <Link className="inbox-message__body-card-name" href={groupUrl}>
                  {details.group.name}
                </Link>
              </div>
              <span className="inbox-message__body-card-member-count">
                {details.group.members_count > 1 ? 'Members' : 'Member'}
              </span>
            </div>
            <Button
              type="button"
              buttonType="outline-inverted"
              size="small"
              onClick={props.onJoinGroupButtonClick}
              loading={props.joinGroupLoading}>
              Join group
            </Button>
          </div>
        )}
      </div>
    </div>
  );
};

InboxMessage.defaultProps = {
  joinGroupLoading: false,
  removeLoading: false,
};

export default InboxMessage;
