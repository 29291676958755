// @flow
import React from 'react';
import classNames from 'classnames';
import './SideTabPanel.scss';

type Tab = { key: string, content: any };
type Props = {
  tabs: Tab[],
  onTabSelected?: Function,
  defaultSelectedTab: string,
};
type State = {
  selectedTab: string | null,
};

class SideTabPanel extends React.Component<Props, State> {
  constructor(props: Props) {
    super(props);

    this.state = {
      selectedTab: this.props.defaultSelectedTab || null,
    };
  }

  render() {
    return (
      <div className="side-tab-panel">
        {this.props.tabs.map((tab: Tab, index: number) => (
          <div
            className={this.getTabClassName(tab)}
            onClick={() => this.handleTabClick(tab)}
            key={index}>
            {tab.content}
          </div>
        ))}
      </div>
    );
  }

  getTabClassName(tab: Tab) {
    return classNames({
      'side-tab-panel__tab': true,
      'side-tab-panel__tab--active': tab.key === this.state.selectedTab,
    });
  }

  handleTabClick = (tab: Tab) => {
    if (this.state.selectedTab !== tab.key) {
      this.setState(
        () => ({ selectedTab: tab.key }),
        () => {
          if (this.props.onTabSelected) {
            this.props.onTabSelected(tab.key);
          }
        },
      );
    }
  };
}

function handleTabSelected(tabKey: string, callback: Function) {}

export default SideTabPanel;
