// @flow
import makeDefaultServerCallReducer from '../../utilities/makeDefaultServerCallReducer';
import {
  GROUP_DETAILS_ERROR,
  GROUP_DETAILS_LOADING,
  GROUP_DETAILS_SUCCESS,
  GROUP_DETAILS_RESET,
  GROUP_DETAILS_RENAME_GROUP,
  GROUP_DETAILS_UPDATE_GROUP,
  GROUP_DETAILS_ADD_CONTRIBUTION,
} from './groupDetails.constants';
import _cloneDeep from 'lodash/cloneDeep';
import _get from 'lodash/get';

const initialState = {
  isLoading: false,
  error: null,
  data: null,
};

const renameGroup = (state, action) => {
  if (state.data) {
    const data = _cloneDeep(state.data);

    data.group = { ...data.group, name: action.payload.group.name };

    return {
      ...state,
      data,
    };
  }

  return state;
};

const updateGroupDetails = (state, action) => {
  if (state.data) {
    const data = _cloneDeep(action.payload);

    return {
      ...state,
      data,
    };
  }

  return state;
};

const addContribution = (state, action) => {
  const { payload } = action;
  const group = _get(state, 'data.group');

  if (group && group.id === payload.groupId) {
    const data = _cloneDeep(state.data);

    if (payload.contributionType === 'direct') {
      data.group.donations.contributed += payload.amountInCents;
    } else if (payload.contributionType === 'commit') {
      data.group.donations.committed += payload.amountInCents;
    } else if (payload.contributionType === 'pledge') {
      data.group.donations.pledged += payload.amountInCents;
    }

    return {
      ...state,
      data,
    };
  }

  return state;
};

export const reducer = makeDefaultServerCallReducer({
  loadingActions: [GROUP_DETAILS_LOADING],
  successActions: [GROUP_DETAILS_SUCCESS],
  errorActions: [GROUP_DETAILS_ERROR],
  resetActions: [GROUP_DETAILS_RESET],
  otherActions: [
    { name: GROUP_DETAILS_RENAME_GROUP, callback: renameGroup },
    { name: GROUP_DETAILS_UPDATE_GROUP, callback: updateGroupDetails },
    { name: GROUP_DETAILS_ADD_CONTRIBUTION, callback: addContribution },
  ],
  initialState,
});
