// @flow
import React from 'react';
import { connect } from 'react-redux';
import Button from '../Button/Button';
import Loading from '../Loading/Loading';
import Link from '../Link/Link';
import Avatar from '../Avatar/Avatar';
import Table from '../Table/Table';
import accountDetails from '../../redux/modules/accountDetails/accountDetails.containers';
import groupMembers from '../../redux/modules/groupMembers/groupMembers.containers';
import groupModerators from '../../redux/modules/groupModerators/groupModerators.containers';
import groupAddModerators from '../../redux/modules/groupAddModerators/groupAddModerators.containers';
import groupRemoveModerator from '../../redux/modules/groupRemoveModerator/groupRemoveModerator.containers';
import groupDetails from '../../redux/modules/groupDetails/groupDetails.containers';
import type {
  GroupDetailsMapDispatchToProps,
  GroupDetailsMapStateToProps,
} from '../../redux/modules/groupDetails/groupDetails.containers';
import { message, Popconfirm } from 'antd';
import { URL_USER_PUBLIC_PROFILE } from '../../config/urls';
import ListWithAvatars from '../ListWithAvatars/ListWithAvatars';
import type {
  GroupModeratorsMapDispatchToProps,
  GroupModeratorsMapStateToProps,
} from '../../redux/modules/groupModerators/groupModerators.containers';
import type {
  GroupMembersMapDispatchToProps,
  GroupMembersMapStateToProps,
} from '../../redux/modules/groupMembers/groupMembers.containers';
import type { AccountDetailsMapStateToProps } from '../../redux/modules/accountDetails/accountDetails.containers';
import type {
  GroupAddModeratorsMapDispatchToProps,
  GroupAddModeratorsMapStateToProps,
} from '../../redux/modules/groupAddModerators/groupAddModerators.containers';
import './GroupEditModerators.scss';
import type {
  GroupRemoveModeratorMapDispatchToProps,
  GroupRemoveModeratorMapStateToProps,
} from '../../redux/modules/groupRemoveModerator/groupRemoveModerator.containers';
import { isCitizen } from '../../utilities/authorization';
import isPrytanyVerified from '../../utilities/isPrytanyVerified';

type Props = GroupDetailsMapDispatchToProps &
  GroupDetailsMapStateToProps &
  GroupModeratorsMapStateToProps &
  GroupModeratorsMapDispatchToProps &
  GroupMembersMapStateToProps &
  GroupMembersMapDispatchToProps &
  AccountDetailsMapStateToProps &
  GroupAddModeratorsMapStateToProps &
  GroupAddModeratorsMapDispatchToProps &
  GroupRemoveModeratorMapDispatchToProps &
  GroupRemoveModeratorMapStateToProps & {
    onModeratorsAssigned?: (response: any) => void,
    onModeratorRemoved?: (response: any) => void,
    onCancel?: () => void,
    onError?: (error: any) => void,
  };

type State = {
  tableSelectedRows: any[],
  tableSelectedRowKeys: number[],
  searchNameValue: string,
  removingModeratorId: number | null,
};

const MODERATORS_PAGE_SIZE = 10;
const MEMBERS_PAGE_SIZE = 10;

class GroupEditModerators extends React.Component<Props, State> {
  constructor(props: Props) {
    super(props);

    this.state = {
      tableSelectedRows: [],
      tableSelectedRowKeys: [],
      searchNameValue: '',
      removingModeratorId: null,
    };

    this.props.groupAddModeratorsReset();
    this.props.groupRemoveModeratorReset();
    this.props.groupModeratorsReset();
    this.props.groupMembersReset();
  }

  componentDidMount() {
    // TODO: implement pagination for moderators
    this.fetchModerators();
    this.fetchMembers();
  }

  componentDidUpdate(prevProps: Props) {
    if (
      !prevProps.groupAddModeratorsData &&
      this.props.groupAddModeratorsData
    ) {
      message.success('Moderators were successfully added to group.', 3);

      if (this.props.onModeratorsAssigned) {
        this.props.onModeratorsAssigned(this.props.groupAddModeratorsData);
      }
    }

    if (
      !prevProps.groupRemoveModeratorData &&
      this.props.groupRemoveModeratorData
    ) {
      message.success('Moderator was successfully removed from group.', 3);

      this.fetchMembers();

      if (this.props.onModeratorRemoved) {
        this.props.onModeratorRemoved(this.props.groupRemoveModeratorData);
      }
    }

    this.handleServiceErrors(prevProps);
  }

  render() {
    return (
      <div className="group-edit-moderators">
        <Loading loading={!this.props.groupDetailsData} type="spinner">
          {this.renderContent()}
        </Loading>
      </div>
    );
  }

  renderContent() {
    return (
      <div className="row fix-width">
        {this.renderModeratorsList()}
        {this.renderMembersTable()}
        <div className="col-12 col-md-8 offset-md-2">
          <div className="row justify-content-center align-items-center py-5">
            <Button
              onClick={this.handleCancel}
              buttonType="outline"
              type="button"
              size="normal"
              className="mx-2 px-3"
              disabled={this.props.groupRenameIsLoading}>
              Cancel
            </Button>
            <Button
              buttonType="primary"
              type="button"
              size="normal"
              className="mx-2 px-3"
              loading={this.props.groupRenameIsLoading}
              disabled={!this.state.tableSelectedRows.length}
              onClick={this.handleAssignButtonClick}>
              Assign selected
            </Button>
          </div>
        </div>
      </div>
    );
  }

  renderModeratorsList() {
    return this.props.groupModeratorsData ? (
      <div className="col-12 col-md-8 offset-md-2 pt-5">
        <ListWithAvatars
          className="group-edit-moderators__moderators-list"
          title="Moderators"
          emptyListMessage="This group doesn't have moderators"
          items={this.props.groupModeratorsData.data.moderators.map(user => ({
            id: user.id,
            name: `${user.first_name} ${user.last_name}`.trim(),
            href: URL_USER_PUBLIC_PROFILE.replace(':id', user.id),
            image: user.profile_image,
            badge: false,
            renderItem: this.renderModeratorItem,
            role: user.role.code,
            verifiedLevel: user.verified_level,
            verifiedDetail: user.verified_detail,
          }))}
          buttonBottomText={
            this.props.groupModeratorsData.next_page ? 'VIEW MORE' : ''
          }
          onButtonBottomClick={() =>
            this.props.getModeratorsForGroupNextPage({
              groupId: this.getGroupId(),
              limit: MODERATORS_PAGE_SIZE,
            })
          }
          buttonBottomProps={{ loading: this.props.groupModeratorsIsLoading }}
          fixedHeight={120}
        />
      </div>
    ) : null;
  }

  renderModeratorItem = (item: any) => {
    return (
      <div key={item.id} className="row fix-width align-items-center pb-2">
        <div className="col-2 d-flex justify-content-start">
          <Link href={item.href}>
            <Avatar
              source={item.image}
              type="x-small"
              isCandidate={item.badge}
            />
          </Link>
        </div>
        <div className="col-7 d-flex justify-content-start px-0">
          <p className="my-0">
            <Link href={item.href}>{item.name}</Link>
          </p>
        </div>
        <div className="col-3 d-flex justify-content-end align-items-center">
          {item.id !== this.props.accountDetailsData.user.id ? (
            <Popconfirm
              title="Are you sure you want to remove this user from the moderators list?"
              onConfirm={() => this.handleRemoveModerator(item)}
              okText="Yes"
              cancelText="No">
              <Button
                buttonType="link"
                type="button"
                loading={this.state.removingModeratorId === item.id}
                spinnerProps={{ type: 'dark' }}>
                <img src="/images/icon_trash-red.png" alt="" />
              </Button>
            </Popconfirm>
          ) : null}
        </div>
      </div>
    );
  };

  renderMembersTable() {
    return this.props.groupMembersData ? (
      <div className="col-12 col-md-8 offset-md-2">
        <h6 className="group-edit-moderators__header">Assign moderators</h6>
        <Table {...this.getTableProps()} />
      </div>
    ) : null;
  }

  handleServiceErrors(prevProps: Props) {
    let error;

    if (
      !prevProps.groupRemoveModeratorError &&
      this.props.groupRemoveModeratorError
    ) {
      error =
        this.props.groupRemoveModeratorError.localMessage ||
        'An error has occurred and we were unable to remove the moderator.';
    }

    if (
      !prevProps.groupAddModeratorsError &&
      this.props.groupAddModeratorsError
    ) {
      error =
        this.props.groupAddModeratorsError.localMessage ||
        'An error has occurred and we were unable to add moderators to this group.';
    }

    if (error) {
      message.error(error, 3);

      if (this.props.onError) {
        this.props.onError(error);
      }
    }
  }

  handleRemoveModerator = (item: any) => {
    this.setState(
      () => ({
        removingModeratorId: item.id,
      }),
      () => {
        this.props.removeModeratorFromGroup({
          userId: item.id,
          groupId: this.getGroupId(),
        });
      },
    );
  };

  handleCancel = () => {
    if (this.props.onCancel) {
      this.props.onCancel();
    }
  };

  handleAssignButtonClick = () => {
    this.props.addModeratorsToGroup({
      userIds: this.state.tableSelectedRows.map(row => row.key),
      groupId: this.getGroupId(),
    });
  };

  getTableProps() {
    const tableProps: any = {
      dataSource: this.getTableData(),
      columns: this.getTableColumns(),
      pagination:
        this.props.groupMembersData.total_pages > 1
          ? {
              total:
                this.props.groupMembersData.total_pages * MEMBERS_PAGE_SIZE,
              current: this.props.groupMembersData.current_page,
              pageSize: MEMBERS_PAGE_SIZE,
            }
          : false,
      loading: this.props.groupMembersIsLoading,
      onChange: this.handleTableChange,
      rowSelection: {
        onChange: this.handleTableRowSelection,
        getCheckboxProps: (record: any) => ({
          disabled: record.key === this.props.accountDetailsData.user.id,
          name: record.key,
        }),
        selectedRowKeys: this.state.tableSelectedRowKeys,
      },
      locale: {
        emptyText:
          'There are no available users to assign as moderators of the group',
      },
    };

    return tableProps;
  }

  getTableColumns() {
    return [
      {
        title: 'NAME',
        dataIndex: 'name',
        key: 'name',
        defaultSortOrder: 'ascend',
        render: (text: string, item: any) => {
          return (
            <React.Fragment>
              <Avatar
                isCandidate={item.role === 'candidate'}
                className="table-cell__name-avatar"
                source={item.profileImage}
                type="tiny"
              />
              <span>{text}</span>
            </React.Fragment>
          );
        },
        sorter: (a: any, b: any) => a.name.localeCompare(b.name),
      },
      {
        title: 'STATE',
        dataIndex: 'state',
        key: 'state',
        sorter: (a: any, b: any) => a.state.localeCompare(b.state),
      },
    ];
  }

  getTableData() {
    const members = this.props.groupMembersData
      ? this.props.groupMembersData.data.members
      : [];
    let tableData = [];

    members.forEach(member => {
      tableData.push({
        key: member.id,
        name: `${member.first_name} ${member.last_name}`.trim(),
        state: member.state ? member.state.name : '',
        profileImage: member.profile_image,
        role: member.role.code,
      });
    });

    return tableData;
  }

  getGroupId() {
    return this.props.groupDetailsData.group.id;
  }

  fetchModerators(page: number = 1) {
    this.props.getModeratorsForGroup({
      groupId: this.getGroupId(),
      limit: MODERATORS_PAGE_SIZE,
      page,
    });
  }

  fetchMembers(page: number = 1) {
    this.props.getMembersForGroup({
      groupId: this.getGroupId(),
      limit: MEMBERS_PAGE_SIZE,
      name: this.state.searchNameValue,
      includeModerators: false,
      page,
    });
  }

  handleTableRowSelection = (
    selectedRowKeys: number[],
    selectedRows: any[],
  ) => {
    this.setState(() => ({
      tableSelectedRows: selectedRows,
      tableSelectedRowKeys: selectedRowKeys,
    }));
  };

  handleTableChange = (pagination: any, filters: any, sorter: any) => {
    // TODO: add sorting logic once the server supports it
    this.fetchMembers(pagination.current);
  };
}

const mapStateToProps = (state, ownProps) => {
  return {
    ...accountDetails.mapStateToProps(state),
    ...groupAddModerators.mapStateToProps(state),
    ...groupRemoveModerator.mapStateToProps(state),
    ...groupDetails.mapStateToProps(state),
    ...groupModerators.mapStateToProps(state),
    ...groupMembers.mapStateToProps(state),
    ...ownProps,
  };
};

const mapDispatchToProps = dispatch => {
  return {
    ...groupDetails.mapDispatchToProps(dispatch),
    ...groupAddModerators.mapDispatchToProps(dispatch),
    ...groupRemoveModerator.mapDispatchToProps(dispatch),
    ...groupMembers.mapDispatchToProps(dispatch),
    ...groupModerators.mapDispatchToProps(dispatch),
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(GroupEditModerators);
