// @flow
import makeDefaultServerCallReducer from '../../utilities/makeDefaultServerCallReducer';
import {
  LIST_STATES_ERROR,
  LIST_STATES_LOADING,
  LIST_STATES_SUCCESS,
  LIST_STATES_RESET,
} from './listStates.constants';

const initialState = {
  isLoading: false,
  error: null,
  data: null,
};

export const reducer = makeDefaultServerCallReducer({
  loadingActions: [LIST_STATES_LOADING],
  successActions: [LIST_STATES_SUCCESS],
  errorActions: [LIST_STATES_ERROR],
  resetActions: [LIST_STATES_RESET],
  initialState,
});
