// @flow
import React from 'react';
import { StripeProvider } from 'react-stripe-elements';
import { connect } from 'react-redux';
import _get from 'lodash/get';
import classNames from 'classnames';
import { message } from 'antd';
import Button from '../Button/Button';
import Currency from '../Currency/Currency';
import Input from '../Input/Input';
import ContributePaymentCreditCard from './ContributePaymentCreditCard';
import contributionDirectDonationMake from '../../redux/modules/contributionDirectDonationMake/contributionDirectDonationMake.containers';
import contributionCommittedDonationMake from '../../redux/modules/contributionCommittedDonationMake/contributionCommittedDonationMake.containers';
import contributionPledgeDonationMake from '../../redux/modules/contributionPledgeDonationMake/contributionPledgeDonationMake.containers';
import type {
  ContributionDirectDonationMakeDispatchToProps,
  ContributionDirectDonationMakeMapStateToProps,
} from '../../redux/modules/contributionDirectDonationMake/contributionDirectDonationMake.containers';
import type {
  ContributionCommittedDonationMakeDispatchToProps,
  ContributionCommittedDonationMakeMapStateToProps,
} from '../../redux/modules/contributionCommittedDonationMake/contributionCommittedDonationMake.containers';
import type {
  ContributionPledgeDonationMakeDispatchToProps,
  ContributionPledgeDonationMakeMapStateToProps,
} from '../../redux/modules/contributionPledgeDonationMake/contributionPledgeDonationMake.containers';
import type { UserProfile } from '../../services/RoRUsersApiProvider';
import withDisplayDimensions from '../../hoc/withDisplayDimensions';
import type { WithDisplayDimensionsOutputProps } from '../../hoc/withDisplayDimensions';
import './ContributePayment.scss';
import {
  CONTRIBUTION_CREDIT_CARD_EXTRA_CHARGE,
  CONTRIBUTION_ACH_FREE,
} from '../../constants/forms';
import { receivingContributionLimit } from '../../utilities/authorization';
import formatCurrency from '../../utilities/formatCurrency';

type Props = ContributionDirectDonationMakeDispatchToProps &
  ContributionDirectDonationMakeMapStateToProps &
  ContributionCommittedDonationMakeMapStateToProps &
  ContributionCommittedDonationMakeDispatchToProps &
  ContributionPledgeDonationMakeDispatchToProps &
  ContributionPledgeDonationMakeMapStateToProps &
  WithDisplayDimensionsOutputProps & {
    contributePayload?: {
      typeOfContribution: 'direct' | 'committed' | 'pledge',
      formValues: {
        public: string,
        total: number,
      },
      userDetails: UserProfile,
    },
    onCloseRequest: Function,
    onSuccess?: (data: any) => void,
    onError?: (error: string) => void,
    customPaymentToken?: string,
    customPaymentEmail?: string,
    defaultAmount?: number,
    onNewStripeSource?: (data: any) => void,
  };

type State = {
  amount: string,
  isLoadingSource: boolean,
};

const stripeAPIKey =
  process.env.RAZZLE_STRIPE_API_KEY || 'pk_test_5QDgOcr1u7KDPpNSql2cUiLi';
const defaultErrorMessage =
  'An error has occurred and your contribution has been canceled';

export const CONTRIBUTE_MIN_AMOUNT = 1;

class ContributePayment extends React.Component<Props, State> {
  paymentCreditCardRef: any = null;

  constructor(props: Props) {
    super(props);

    this.state = {
      amount: (
        this.props.defaultAmount ||
        _get(this.props, 'contributePayload.formValues.total', '0')
      ).toString(),
      isLoadingSource: false,
    };

    this.props.contributionDirectDonationMakeReset();
    this.props.contributionCommittedDonationMakeReset();
    this.props.contributionPledgeDonationMakeReset();
  }

  componentDidUpdate(prevProps: Props) {
    const total = _get(
      this.props,
      'contributePayload.formValues.total',
      '0',
    ).toString();
    const prevTotal = _get(
      this.props,
      'contributePayload.formValues.total',
      '0',
    ).toString();
    let newState = {};

    if (total !== this.state.amount && total !== prevTotal) {
      newState.amount = total;
    }

    if (Object.keys(newState).length) {
      this.setState(() => newState);
    }

    this.handleContributionSuccess(prevProps);
    this.handleContributionErrors(prevProps);
  }

  render() {
    return (
      <React.Fragment>
        {this.renderHeader()}
        <div className="row pb-0 pt-0 fix-width">
          {this.renderMainForm()}
          {this.renderBalance()}
        </div>
      </React.Fragment>
    );
  }

  renderHeader() {
    return (
      <div className="contribute-payment__head">
        <Button
          className="contribute-payment__button-change"
          size="small"
          type="button"
          buttonType="link"
          onClick={this.props.onCloseRequest}>
          &lt; Back
        </Button>
        <h4 className="modal-component__title contribute-payment__title">
          {this.props.contributePayload
            ? 'Payment'
            : 'Add a new payment method'}
        </h4>
        <div style={{ padding: '40px' }} />
      </div>
    );
  }

  renderMainForm() {
    const numericAmount = parseFloat(this.state.amount);
    const maxContribution = this.getMaxContribution();
    return (
      <div className="col-12 col-md-8 contribute-payment__form-wrapper pt-3 pt-md-5 pb-3 pt-md-5">
        <div className="row">
          {this.props.contributePayload ? (
            <div className="col-12 col-md-4 pl-lg-5">
              <span className="contribute-payment__label">Amount to pay</span>
              <div className="row pb-4">
                <div className="col-3 col-md-2 pr-3 d-flex text-left align-items-center">
                  <span className="contribute-payment__total-text-sign">$</span>
                </div>
                <div className="col-7 col-md-8 pl-0 d-flex align-items-center">
                  <Input
                    className="mb-0"
                    label=""
                    placeholder=""
                    name="amount"
                    type="number"
                    value={this.state.amount}
                    onChange={this.handleInputAmountChange}
                    useFormik={false}
                    min={CONTRIBUTE_MIN_AMOUNT}
                    max={maxContribution}
                    error={
                      numericAmount < CONTRIBUTE_MIN_AMOUNT ||
                      numericAmount > maxContribution
                        ? `You can contribute between ${formatCurrency(
                            CONTRIBUTE_MIN_AMOUNT,
                          )} and ${formatCurrency(maxContribution)}`
                        : ''
                    }
                  />
                </div>
                <div className="col-12">
                  <p className="contribute-payment__label--micro">
                    {CONTRIBUTION_CREDIT_CARD_EXTRA_CHARGE}{' '}
                    <span className="contribute-payment__label--micro-strong">
                      {CONTRIBUTION_ACH_FREE}
                    </span>
                  </p>
                </div>
              </div>
            </div>
          ) : null}
          <div className="col-12 col-md-8 px-lg-5 pt-1 pt-md-0">
            <StripeProvider apiKey={stripeAPIKey}>
              <ContributePaymentCreditCard
                ref={this.setCreditCardRef}
                onSourceCreated={this.handleSourceCreated}
                amount={parseFloat(this.state.amount)}
                onSourceError={this.handleSourceError}
              />
            </StripeProvider>
          </div>
        </div>
      </div>
    );
  }

  renderBalance() {
    const amount = parseFloat(this.state.amount);

    return (
      <div className="col-12 col-md-4 px-lg-5 contribute-payment__balance">
        <div className="row">
          <div className="col-7">
            <span className="d-block fw-500 mb-1 pb-5 fs-16 pt-2 f-green">
              Total
            </span>
          </div>
          <div className="col-5 text-right">
            {this.props.contributePayload ? (
              <Currency
                amount={amount}
                className="fw-500 mb-1 pb-5 fs-22 f-green"
              />
            ) : null}
          </div>
        </div>
        <div className="row pt-md-5 pb-3">
          <div className="col-6 col-md-4 pr-1">
            <Button
              type="button"
              buttonType="outline"
              block
              noBorder
              onClick={this.close}
              disabled={this.isLoadingContribution()}
              size={this.props.isMobile ? 'small' : 'normal'}>
              Cancel
            </Button>
          </div>
          <div className="col-6 col-lg-8 pl-1">
            <Button
              type="submit"
              buttonType="primary"
              block
              noBorder
              onClick={this.handleConfirmButtonClick}
              disabled={!amount}
              loading={this.isLoadingContribution()}
              size={this.props.isMobile ? 'small' : 'normal'}>
              Confirm
            </Button>
          </div>
        </div>
      </div>
    );
  }

  setCreditCardRef = (ref: any) => {
    this.paymentCreditCardRef = ref;
  };

  getMaxContribution = () => {
    const { contributePayload } = this.props;
    return contributePayload
      ? receivingContributionLimit(this.props.contributePayload.userDetails)
      : Infinity;
  };

  handleContributionSuccess(prevProps: Props) {
    if (
      !prevProps.contributionDirectDonationMakeData &&
      this.props.contributionDirectDonationMakeData
    ) {
      message.success(
        `
        You have successfully made a direct contribution to ${
          this.props.contributePayload.userDetails.first_name
        }
        ${this.props.contributePayload.userDetails.last_name}
      `,
        5,
      );

      this.callSuccessCallback({
        ...this.props.contributionDirectDonationMakeData,
        type: 'direct',
      });
      this.close();
    }

    if (
      !prevProps.contributionCommittedDonationMakeData &&
      this.props.contributionCommittedDonationMakeData
    ) {
      message.success(
        `
        You have successfully made a committed contribution to ${
          this.props.contributePayload.userDetails.first_name
        }
        ${this.props.contributePayload.userDetails.last_name}
      `,
        5,
      );

      this.callSuccessCallback({
        ...this.props.contributionCommittedDonationMakeData,
        type: 'committed',
      });
      this.close();
    }

    if (
      !prevProps.contributionPledgeDonationMakeData &&
      this.props.contributionPledgeDonationMakeData
    ) {
      message.success(
        `
        You have successfully made a pledge contribution to ${
          this.props.contributePayload.userDetails.first_name
        }
        ${this.props.contributePayload.userDetails.last_name}
      `,
        5,
      );

      this.callSuccessCallback({
        ...this.props.contributionPledgeDonationMakeData,
        type: 'pledge',
      });
      this.close();
    }
  }

  handleContributionErrors(prevProps: Props) {
    if (
      (!prevProps.contributionDirectDonationMakeError &&
        this.props.contributionDirectDonationMakeError) ||
      (!prevProps.contributionCommittedDonationMakeError &&
        this.props.contributionCommittedDonationMakeError) ||
      (!prevProps.contributionPledgeDonationMakeError &&
        this.props.contributionPledgeDonationMakeError)
    ) {
      const error =
        _get(this.props, 'contributionDirectDonationMakeError.localMessage') ||
        _get(
          this.props,
          'contributionCommittedDonationMakeError.localMessage',
        ) ||
        _get(this.props, 'contributionPledgeDonationMakeError.localMessage') ||
        defaultErrorMessage;

      message.error(error, 5);
      this.callErrorCallback(error);
      this.close();
    }
  }

  getClassName() {
    return classNames({
      'contribute-payment': true,
      [this.props.className || '']: this.props.className,
    });
  }

  handleInputAmountChange = ({ value }: { value: string, name: string }) => {
    if (parseFloat(value) >= 0) {
      this.setState(() => ({ amount: value }));
    }
  };

  handleConfirmButtonClick = () => {
    this.setState(
      () => ({ isLoadingSource: true }),
      () => {
        this.paymentCreditCardRef.getStripeSource();
      },
    );
  };

  handleSourceCreated = (stripeResponse: any) => {
    this.setState(
      () => ({ isLoadingSource: false }),
      () => {
        if (stripeResponse && stripeResponse.id) {
          const typeOfContribution = _get(
            this.props,
            'contributePayload.typeOfContribution',
          );

          if (this.props.onNewStripeSource) {
            this.props.onNewStripeSource({
              actionType: 'created_source',
              stripeResponse,
            });
          }

          if (typeOfContribution === 'direct') {
            this.handleDirectContributionRequest(stripeResponse);
          } else if (typeOfContribution === 'committed') {
            this.handleCommittedContributionRequest(stripeResponse);
          } else if (typeOfContribution === 'pledge') {
            this.handlePledgeContributionRequest(stripeResponse);
          }
        } else {
          message.error(defaultErrorMessage, 5);
        }
      },
    );
  };

  handleSourceError = () => {
    this.setState(() => ({ isLoadingSource: false }));
  };

  handleDirectContributionRequest(stripeResponse: any) {
    const payload = {
      amount_in_cents: parseInt(parseFloat(this.state.amount) * 100),
      candidate_id: _get(this.props, 'contributePayload.userDetails.id'),
      public: _get(this.props, 'contributePayload.formValues.public') === '1',
      stripe_source_id: stripeResponse.id,
      customToken: this.props.customPaymentToken,
      customEmail: this.props.customPaymentEmail,
    };

    let groupId = _get(this.props, 'contributePayload.groupId');
    if (groupId) payload.group_id = groupId;

    this.props.makeDirectDonation(payload);
  }

  handleCommittedContributionRequest(stripeResponse: any) {
    const timeSpan = _get(this.props, 'contributePayload.formValues.timeSpan');
    const paidOutFrequency = _get(
      this.props,
      'contributePayload.formValues.paidOutFrequency',
    );
    let payload = {
      amount_in_cents: parseInt(parseFloat(this.state.amount) * 100),
      candidate_id: _get(this.props, 'contributePayload.userDetails.id'),
      public: _get(this.props, 'contributePayload.formValues.public') === '1',
      stripe_source_id: stripeResponse.id,
      period_in_months: timeSpan,
      frequency_in_months: paidOutFrequency,
      customToken: this.props.customPaymentToken,
      customEmail: this.props.customPaymentEmail,
    };

    let groupId = _get(this.props, 'contributePayload.groupId');
    if (groupId) payload.group_id = groupId;

    this.props.makeCommittedDonation(payload);
  }

  handlePledgeContributionRequest(stripeResponse: any) {
    const timeSpan = _get(this.props, 'contributePayload.formValues.timeSpan');
    const subInterestId = _get(
      this.props,
      'contributePayload.formValues.subInterests',
    );
    const ratingCondition = _get(
      this.props,
      'contributePayload.formValues.ratingCondition',
    );
    let payload = {
      amount_in_cents: parseInt(parseFloat(this.state.amount) * 100),
      candidate_id: _get(this.props, 'contributePayload.userDetails.id'),
      public: _get(this.props, 'contributePayload.formValues.public') === '1',
      stripe_source_id: stripeResponse.id,
      period_in_months: parseInt(timeSpan),
      condition: ratingCondition,
      sub_interest_id: subInterestId,
      customToken: this.props.customPaymentToken,
      customEmail: this.props.customPaymentEmail,
    };

    let groupId = _get(this.props, 'contributePayload.groupId');
    if (groupId) payload.group_id = groupId;

    this.props.makePledgeDonation(payload);
  }

  close = () => {
    if (this.props.onCloseRequest) {
      this.props.onCloseRequest();
    }
  };

  callSuccessCallback(payload: any) {
    if (this.props.onSuccess) {
      this.props.onSuccess(payload);
    }
  }

  callErrorCallback(error: string) {
    if (this.props.onError) {
      this.props.onError(error);
    }
  }

  isLoadingContribution() {
    return (
      this.state.isLoadingSource ||
      this.props.contributionDirectDonationMakeIsLoading ||
      this.props.contributionCommittedDonationMakeIsLoading
    );
  }
}

const mapStateToProps = (state, ownProps) => {
  return {
    ...contributionDirectDonationMake.mapStateToProps(state),
    ...contributionCommittedDonationMake.mapStateToProps(state),
    ...contributionPledgeDonationMake.mapStateToProps(state),
    ...ownProps,
  };
};

const mapDispatchToProps = dispatch => {
  return {
    ...contributionDirectDonationMake.mapDispatchToProps(dispatch),
    ...contributionCommittedDonationMake.mapDispatchToProps(dispatch),
    ...contributionPledgeDonationMake.mapDispatchToProps(dispatch),
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(withDisplayDimensions(ContributePayment));
