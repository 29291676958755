// @flow
import { createAction } from 'redux-actions';
import {
  GROUP_MEMBER_PENDING_REQUESTS_ERROR,
  GROUP_MEMBER_PENDING_REQUESTS_LOADING,
  GROUP_MEMBER_PENDING_REQUESTS_NEXT_PAGE,
  GROUP_MEMBER_PENDING_REQUESTS_REMOVE_ITEM,
  GROUP_MEMBER_PENDING_REQUESTS_REMOVE_ITEMS_FOR_ROLE,
  GROUP_MEMBER_PENDING_REQUESTS_RESET,
  GROUP_MEMBER_PENDING_REQUESTS_SUCCESS,
} from './groupMemberPendingRequests.constants';
import { instance as rorGroupsApiProvider } from '../../../services/RoRGroupsApiProvider';
import { clientStore } from '../../../redux/clientStore';
import _get from 'lodash/get';

export const groupMemberPendingRequestsError = createAction(
  GROUP_MEMBER_PENDING_REQUESTS_ERROR,
);

export const groupMemberPendingRequestsLoading = createAction(
  GROUP_MEMBER_PENDING_REQUESTS_LOADING,
);

export const groupMemberPendingRequestsSuccess = createAction(
  GROUP_MEMBER_PENDING_REQUESTS_SUCCESS,
);

export const groupMemberPendingRequestsReset = createAction(
  GROUP_MEMBER_PENDING_REQUESTS_RESET,
);

export const groupMemberPendingRequestsRemoveItem = createAction(
  GROUP_MEMBER_PENDING_REQUESTS_REMOVE_ITEM,
);

export const groupMemberPendingRequestsNextPage = createAction(
  GROUP_MEMBER_PENDING_REQUESTS_NEXT_PAGE,
);

export const groupMemberPendingRequestsRemoveItemsForRole = createAction(
  GROUP_MEMBER_PENDING_REQUESTS_REMOVE_ITEMS_FOR_ROLE,
);

export const getGroupMemberPendingRequests = (
  payload: GroupMemberPendingRequestsPayload,
) => {
  return async (dispatch: Function) => {
    try {
      dispatch(groupMemberPendingRequestsLoading());

      if (payload.roles) {
        let promises = [];
        let data = {};

        payload.roles.forEach(role => {
          promises.push(
            rorGroupsApiProvider
              .groupMemberPendingRequests({
                groupId: payload.groupId,
                page: payload.page,
                limit: payload.limit,
                role: role.id,
              })
              .then(response => Promise.resolve({ [role.code]: response })),
          );
        });

        const responses = await Promise.all(promises);

        responses.forEach(responseByCode => {
          Object.assign(data, responseByCode);
        });

        dispatch(groupMemberPendingRequestsSuccess(data));
      } else {
        const response = await rorGroupsApiProvider.groupMemberPendingRequests({
          groupId: payload.groupId,
          page: payload.page,
          limit: payload.limit,
        });

        dispatch(groupMemberPendingRequestsSuccess({ all: response }));
      }
    } catch (error) {
      dispatch(groupMemberPendingRequestsError(error));
    }
  };
};

export const getGroupMemberPendingRequestsNextPage = (
  payload: GroupMemberPendingRequestsNextPagePayload,
) => {
  return async (dispatch: Function) => {
    const data = _get(
      clientStore.getState(),
      `groupMemberPendingRequests.data[${payload.roleCode}]`,
      {},
    );
    const nextPage = data.next_page;

    if (nextPage) {
      dispatch(groupMemberPendingRequestsLoading());

      const response = await rorGroupsApiProvider.groupMemberPendingRequests({
        groupId: payload.groupId,
        page: nextPage,
        limit: payload.limit,
        role: payload.roleId,
      });

      dispatch(
        groupMemberPendingRequestsNextPage({ data: response, ...payload }),
      );
    }
  };
};

export type GroupMemberPendingRequestsPayload = {
  page?: number,
  limit?: number,
  groupId: number,
  roles?: { code: string, id: number }[],
};

export type GroupMemberPendingRequestsNextPagePayload = {
  limit?: number,
  groupId: number,
  roleCode: 'all' | 'citizen' | 'candidate',
  roleId: number,
};
