export const USER_PROFILE_FEED_LOADING =
  '@@prytany/global/USER_PROFILE_FEED_LOADING';
export const USER_PROFILE_FEED_SUCCESS =
  '@@prytany/global/USER_PROFILE_FEED_SUCCESS';
export const USER_PROFILE_FEED_ERROR =
  '@@prytany/global/USER_PROFILE_FEED_ERROR';
export const USER_PROFILE_FEED_RESET =
  '@@prytany/global/USER_PROFILE_FEED_RESET';
export const USER_PROFILE_FEED_LIKE_POST =
  '@@prytany/global/USER_PROFILE_FEED_LIKE_POST';
export const USER_PROFILE_FEED_UNLIKE_POST =
  '@@prytany/global/USER_PROFILE_FEED_UNLIKE_POST';
export const USER_PROFILE_FEED_SHARE_POST =
  '@@prytany/global/USER_PROFILE_FEED_SHARE_POST';
export const USER_PROFILE_FEED_NEXT_PAGE =
  '@@prytany/global/USER_PROFILE_FEED_NEXT_PAGE';
