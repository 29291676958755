// @flow
import * as React from 'react';
import * as _omitBy from 'lodash/omitBy';
import * as _uniqBy from 'lodash/uniqBy';
import * as _get from 'lodash/get';
import { connect } from 'react-redux';
import { message } from 'antd';
import { Formik, FormikComputedProps } from 'formik';
import * as yup from 'yup';
import type { GroupDetails } from '../../services/RoRGroupsApiProvider';
import type { GroupDetailsMapStateToProps } from '../../redux/modules/groupDetails/groupDetails.containers';
import type {
  GroupModeratorsMapDispatchToProps,
  GroupModeratorsMapStateToProps,
} from '../../redux/modules/groupModerators/groupModerators.containers';
import type {
  GroupUpdateMapStateToProps,
  GroupUpdateMapDispatchToProps,
} from '../../redux/modules/groupUpdate/groupUpdate.containers';
import groupDetails from '../../redux/modules/groupDetails/groupDetails.containers';
import Badge from '../../components/Badge/Badge';
import Button from '../Button/Button';
import TextArea from '../TextArea/TextArea';
import SelectableBadges from '../SelectableBadges/SelectableBadges';
import listInterests from '../../redux/modules/listInterests/listInterests.containers';
import groupUpdate from '../../redux/modules/groupUpdate/groupUpdate.containers';
import { Scrollbars } from 'react-custom-scrollbars';
import './EditGroupTab.scss';

type Props = GroupDetailsMapStateToProps &
  GroupModeratorsMapDispatchToProps &
  GroupModeratorsMapStateToProps &
  GroupUpdateMapStateToProps &
  GroupUpdateMapDispatchToProps & {
    groupDetailsUpdateError: any,
    groupDetailsUpdateData: { group: GroupDetails } | null,
    groupDetailsUpdateReset: Function,
    groupDetailsUpdateIsLoading: boolean,
    listInterestsData: {
      interests: { id: number, name: string }[],
      next_page: number | '',
    },
    listInterestsError: any,
    listInterestsIsLoading: boolean,
    updateGroupDetails: Function,
    getListInterests: Function,
    getListInterestsNextPage: Function,
    match: any,
    location: any,
    history: any,
  };

type State = {
  editDescription: boolean,
  editInterests: boolean,
};

class EditGroupTab extends React.Component<Props, State> {
  constructor(props) {
    super(props);

    this.state = {
      editDescription: false,
      editInterests: false,
    };

    this.props.getListInterests();
  }

  componentDidUpdate(prevProps: Props, prevState: State) {
    if (
      !prevProps.groupDetailsUpdateError &&
      this.props.groupDetailsUpdateError
    ) {
      message.error(
        this.props.groupDetailsUpdateError.localMessage ||
          'An error occurred and the group details were not updated.',
      );
    }

    if (
      !prevProps.groupDetailsUpdateData &&
      this.props.groupDetailsUpdateData
    ) {
      message.success('The group was successfully updated.', 5);
      this.setState(
        () => ({
          editDescription: false,
          editInterests: false,
        }),
        () => this.props.updateGroupDetailsReset(),
      );
    }
  }

  render() {
    return (
      <div className="pb-5 edit-group-tab">
        <Formik {...this.getDescriptionFormikProps()}>
          {props => this.renderDescriptionInnerForm(props)}
        </Formik>
        <Formik {...this.getInterestsFormikProps()}>
          {props => this.renderInterestsInnerForm(props)}
        </Formik>
      </div>
    );
  }

  getDescriptionFormikProps() {
    const group = this.props.groupDetailsData.group;
    return {
      initialValues: {
        description: group.description,
      },
      validationSchema: yup.object().shape({
        description: yup.string().required('This field is required'),
      }),
      onSubmit: this.submitGroupForm,
    };
  }

  getInterestsFormikProps() {
    const group = this.props.groupDetailsData.group;

    return {
      initialValues: {
        interests: group.interests.map(interest => interest.id.toString()),
      },
      onSubmit: this.submitGroupForm,
    };
  }

  getInterestItems() {
    const group = this.props.groupDetailsData.group;

    const currentInterests = group.interests.map(interest => ({
      label: interest.name,
      value: interest.id.toString(),
    }));
    const interests = _get(this.props, 'listInterestsData.interests', []).map(
      interest => ({ label: interest.name, value: interest.id.toString() }),
    );

    return _uniqBy(
      currentInterests.concat(interests),
      interest => interest.value,
    );
  }

  renderDescriptionInnerForm(props: FormikComputedProps) {
    const { touched, errors, handleSubmit } = props;
    const group = this.props.groupDetailsData.group;
    const isModerator = this.isCurrentUserGroupModerator();

    return (
      <div className="col-12">
        <form className="row" onSubmit={handleSubmit}>
          <div className="col-8 pb-4 px-0">
            <h6 className="fs-18">Description</h6>
          </div>
          <div className="col-4 pb-1 text-right px-0">
            {isModerator && (
              <Button
                className="edit-profile-tab__button"
                type="button"
                buttonType="outline"
                size="small"
                disabled={this.props.groupDetailsIsLoading}
                onClick={this.toggleEditGroupDescription}>
                {this.state.editDescription ? 'Cancel' : 'Edit'}
              </Button>
            )}
          </div>
          <div className="mb-3 col-12 px-0">
            {this.state.editDescription ? (
              <TextArea
                label=""
                placeholder="Description..."
                name="description"
                error={
                  touched.description && errors.description
                    ? errors.description
                    : ''
                }
              />
            ) : (
              <p className="fs-16 pb-5">{group.description}</p>
            )}
          </div>
          <div className="col-12 px-0 d-flex justify-content-end">
            {this.state.editDescription ? (
              <Button
                className="edit-profile-tab__button"
                type="submit"
                buttonType="primary"
                size="small"
                loading={this.props.groupDetailsUpdateIsLoading}>
                Submit
              </Button>
            ) : null}
          </div>
        </form>
      </div>
    );
  }

  renderInterestsInnerForm(props: FormikComputedProps) {
    const { touched, errors, handleSubmit } = props;
    const group = this.props.groupDetailsData.group;
    const isModerator = this.isCurrentUserGroupModerator();

    return (
      <div className="col-12">
        <form className="row" onSubmit={handleSubmit}>
          <div className="col-8 pb-4 px-0">
            <h6 className="fs-18">Interests</h6>
          </div>
          <div className="col-4 pb-1 text-right px-0">
            {isModerator && (
              <Button
                className="edit-profile-tab__button"
                type="button"
                buttonType="outline"
                size="small"
                disabled={this.props.groupDetailsIsLoading}
                onClick={this.toggleEditGroupInterests}>
                {this.state.editInterests ? 'Cancel' : 'Edit'}
              </Button>
            )}
          </div>
          <div className="col-12 pb-4 px-0">
            <div className="d-flex justify-content-left flex-wrap">
              {!this.state.editInterests
                ? group.interests.map(interest => (
                    <Badge
                      href="#"
                      name={interest.name}
                      title={interest.name}
                      key={interest.id}
                    />
                  ))
                : null}
              {this.state.editInterests ? (
                <Scrollbars universal style={{ height: 180 }}>
                  <SelectableBadges
                    className="d-flex justify-content-lg-between justify-content-center flex-wrap"
                    items={this.getInterestItems()}
                    name="interests"
                    error={
                      touched.interests && errors.interests
                        ? errors.interests
                        : ''
                    }
                  />
                </Scrollbars>
              ) : null}
              <div className="col-12 px-0 d-flex justify-content-end align-items-center">
                {this.state.editInterests ? (
                  <React.Fragment>
                    {this.props.listInterestsData &&
                    this.props.listInterestsData.next_page ? (
                      <Button
                        className="link-violet"
                        type="button"
                        buttonType="link"
                        size="small"
                        onClick={() => this.props.getListInterestsNextPage()}
                        loading={this.props.listInterestsIsLoading}
                        spinnerProps={{ type: 'dark' }}>
                        SEE MORE &gt;
                      </Button>
                    ) : null}
                    <Button
                      className="edit-profile-tab__button"
                      type="submit"
                      buttonType="primary"
                      size="small"
                      loading={this.props.groupDetailsUpdateIsLoading}>
                      Submit
                    </Button>
                  </React.Fragment>
                ) : null}
              </div>
            </div>
          </div>
        </form>
      </div>
    );
  }

  isCurrentUserGroupModerator = () => {
    const groupModerators = _get(
      this.props.groupModeratorsData,
      'data.moderators',
      false,
    );
    const accountID = this.props.accountDetailsData.user.id;
    let returnValue = false;
    for (let i = 0; i < groupModerators.length; i += 1) {
      if (groupModerators[i].id === accountID) {
        returnValue = true;
      } else {
        returnValue = false;
      }
    }

    return returnValue;
  };

  toggleEditGroupDescription = () => {
    this.setState((prevState: State) => ({
      editDescription: !prevState.editDescription,
    }));
  };

  toggleEditGroupInterests = () => {
    this.setState((prevState: State) => ({
      editInterests: !prevState.editInterests,
    }));
  };

  getInterestsPayload(newInterestIds) {
    const previousInterestIds = this.props.groupDetailsData.group.interests.map(
      interest => interest.id.toString(),
    );
    let interestToRemove = [];

    previousInterestIds.forEach(interestId => {
      if (newInterestIds.indexOf(interestId) === -1) {
        interestToRemove.push({
          interest_id: parseInt(interestId),
          _destroy: true,
        });
      }
    });

    return newInterestIds
      .map(interestId => ({ interest_id: parseInt(interestId) }))
      .concat(interestToRemove);
  }

  getInterestsPayload(newInterestIds) {
    const previousInterestIds = this.props.groupDetailsData.group.interests.map(
      interest => interest.id.toString(),
    );
    let interestToRemove = [];

    previousInterestIds.forEach(interestId => {
      if (newInterestIds.indexOf(interestId) === -1) {
        interestToRemove.push({
          interest_id: parseInt(interestId),
          _destroy: true,
        });
      }
    });

    return newInterestIds
      .map(interestId => ({ interest_id: parseInt(interestId) }))
      .concat(interestToRemove);
  }

  submitGroupForm = (formikValues: any) => {
    const group = this.props.groupDetailsData.group;
    const payload = _omitBy({ ...formikValues }, (key, value) => {
      return (
        (!value && value !== 0) ||
        ['description', 'interests'].indexOf(key) !== -1
      );
    });

    if (formikValues.interests) {
      payload.entity_interests_attributes = this.getInterestsPayload(
        formikValues.interests,
      );
    }

    if (formikValues.interests) {
      payload.entity_interests_attributes = this.getInterestsPayload(
        formikValues.interests,
      );
    }
    this.props.updateGroupDetails({ ...payload, groupId: group.id });
  };
}

const mapStateToProps = (state, ownProps) => {
  return {
    ...groupDetails.mapStateToProps(state),
    ...listInterests.mapStateToProps(state),
    ...groupUpdate.mapStateToProps(state),
    ownProps,
  };
};

const mapDispatchToProps = dispatch => {
  return {
    ...groupDetails.mapDispatchToProps(dispatch),
    ...listInterests.mapDispatchToProps(dispatch),
    ...groupUpdate.mapDispatchToProps(dispatch),
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(EditGroupTab);
