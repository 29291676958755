import {
  accountInboxMessageDeleteReset as reset,
  deleteAccountInboxMessage as deleteMessage,
} from './accountInboxMessageDelete.actions';
import type { GetUserInboxMessageDeletePayload } from '../../../services/RoRUsersApiProvider';
import type { DefaultResponse } from '../../../services/RoRApiProvider';

export const mapStateToProps = state => {
  return {
    accountInboxMessageDeleteData: state.accountInboxMessageDelete.data,
    accountInboxMessageDeleteError: state.accountInboxMessageDelete.error,
    accountInboxMessageDeleteIsLoading:
      state.accountInboxMessageDelete.isLoading,
  };
};

export const mapDispatchToProps = dispatch => {
  return {
    deleteAccountInboxMessage(payload: GetUserInboxMessageDeletePayload) {
      dispatch(deleteMessage(payload));
    },
    accountInboxMessageDeleteReset() {
      dispatch(reset());
    },
  };
};

export type AccountInboxMessageDeleteMapStateToProps = {
  accountInboxMessageDeleteData: DefaultResponse,
  accountInboxMessageDeleteError: any,
  accountInboxMessageDeleteIsLoading: boolean,
};

export type AccountInboxMessageDeleteMapDispatchToProps = {
  deleteAccountInboxMessage: (
    payload: GetUserInboxMessageDeletePayload,
  ) => void,
  accountInboxMessageDeleteReset: () => void,
};

export default {
  mapStateToProps,
  mapDispatchToProps,
};
