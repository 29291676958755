// @flow
import React from 'react';
import _get from 'lodash/get';
import { message } from 'antd';
import { connect } from 'react-redux';
import Button from '../../components/Button/Button';
import BreadCrumb from '../../components/BreadCrumb/BreadCrumb';
import RankingList from '../../components/RankingList/RankingList';
import RaceHeader from '../../components/RaceHeader/RaceHeader';
import TextArea from '../../components/TextArea/TextArea';
import FeedItem from '../../components/FeedItem/FeedItem';
import SingleRowMenu from '../../components/SingleRowMenu/SingleRowMenu';
import {
  URL_FEED_PAGE,
  URL_GROUP_PAGE,
  URL_RACE_ACTIVITY_PAGE,
  URL_RACE_ACTIVITY_RANKING_PAGE,
  URL_USER_PUBLIC_PROFILE,
} from '../../config/urls';
import raceDetails from '../../redux/modules/raceDetails/raceDetails.containers';
import raceFeed from '../../redux/modules/raceFeed/raceFeed.containers';
import raceGroups from '../../redux/modules/raceGroups/raceGroups.containers';
import raceThoughtAdd from '../../redux/modules/raceThoughtAdd/raceThoughtAdd.containers';
import type {
  RaceFeedMapDispatchToProps,
  RaceFeedMapStateToProps,
} from '../../redux/modules/raceFeed/raceFeed.containers';
import type {
  RaceDetailsMapStateToProps,
  RaceDetailsMapDispatchToProps,
} from '../../redux/modules/raceDetails/raceDetails.containers';
import './RaceActivityPage.scss';
import type {
  RaceGroupsMapDispatchToProps,
  RaceGroupsMapStateToProps,
} from '../../redux/modules/raceGroups/raceGroups.containers';
import ListWithAvatars from '../../components/ListWithAvatars/ListWithAvatars';
import type {
  RaceThoughtAddMapDispatchToProps,
  RaceThoughtAddMapStateToProps,
} from '../../redux/modules/raceThoughtAdd/raceThoughtAdd.containers';
import withDisplayDimensions from '../../hoc/withDisplayDimensions';
import type { WithDisplayDimensionsOutputProps } from '../../hoc/withDisplayDimensions';
import Loading from '../../components/Loading/Loading';

type Props = WithDisplayDimensionsOutputProps &
  RaceDetailsMapStateToProps &
  RaceDetailsMapDispatchToProps &
  RaceFeedMapDispatchToProps &
  RaceFeedMapStateToProps &
  RaceGroupsMapDispatchToProps &
  RaceGroupsMapStateToProps &
  RaceThoughtAddMapDispatchToProps &
  RaceThoughtAddMapStateToProps & {
    children: any,
    location: any,
    history: any,
  };

type State = {
  mobileSubSection: 'feed' | 'ranking' | 'groups',
  maxRankingItemsToDisplay: number,
};

class RaceActivityPage extends React.Component<Props, State> {
  constructor(props: Props) {
    super(props);

    this.state = {
      mobileSubSection: 'feed',
      maxRankingItemsToDisplay: 3,
    };
  }

  componentWillMount() {
    const raceId = this.getRaceId();
    message.loading('Loading Race Activity...', 3);
    this.props.raceDetailsReset();
    this.props.raceFeedReset();
    this.props.raceGroupsReset();
    this.props.raceThoughtAddReset();
    this.props.getDetailsForRace({ raceId });
    this.props.getFeedForRace({ raceId });
    this.props.getGroupsForRace({ raceId });
  }

  componentDidUpdate(prevProps: Props) {
    if (prevProps.isMobile && !this.props.isMobile) {
      this.setState(() => ({ mobileSubSection: 'feed' }));
    }
  }

  render() {
    return (
      <section className="race-activity-page container-fluid px-0 content">
        {this.renderContent()}
      </section>
    );
  }

  renderContent() {
    return !this.isLoading() ? (
      <Loading loading={this.isLoading()} type="spinner">
        {this.renderHeader()}
        <div className="race-activity-page__content">
          <div className="row fix-width">
            {this.renderFeed()}
            <div className="col-12 col-md-4 px-4 race-activity-page__ranking-groups">
              {this.renderRanking()}
              {this.renderGroups()}
            </div>
          </div>
        </div>
      </Loading>
    ) : null;
  }

  renderHeader() {
    return (
      <React.Fragment>
        {this.props.isMobile && this.state.mobileSubSection !== 'feed' ? (
          <Button
            buttonType="link"
            type="button"
            onClick={this.handleBackToMobileFeed}>
            &larr; Back
          </Button>
        ) : null}
        {!this.props.isMobile || this.state.mobileSubSection === 'feed' ? (
          <React.Fragment>
            <div className="row fix-width-1290">
              <div className="col-12 pt-3 pt-md-4">
                <BreadCrumb links={this.getBreadCrumbLinks()} />
              </div>
            </div>
            <RaceHeader className="race-activity-page__header" />
          </React.Fragment>
        ) : null}
        {this.renderMobileMenu()}
      </React.Fragment>
    );
  }

  renderMobileMenu() {
    return this.props.isMobile ? (
      <SingleRowMenu
        content={[
          {
            key: 'ranking',
            image: '/images/icon_stars.png',
            text: 'RANKING',
            onClick: this.handleMobileMenuClick,
          },
          {
            key: 'groups',
            image: '/images/icon_groups-1.png',
            text: 'GROUPS',
            onClick: this.handleMobileMenuClick,
          },
        ]}
      />
    ) : null;
  }

  renderFeed() {
    const feed = _get(this.props, 'raceFeedData.data.feeds', []);

    // TODO: add load more button
    return !this.props.isMobile || this.state.mobileSubSection === 'feed' ? (
      <div className="col-12 col-md-8 px-0 pr-md-5">
        <div className="row race-activity-page__thoughts no-gutters">
          <TextArea
            className="col-md-12"
            placeholder="Share your thoughts"
            name="thoughts"
            useFormik={false}
            error={''}
            addonButtonContent="Post now"
            addonButtonProps={{
              size: 'small',
              onClick: this.handleThoughtSendClick,
              loading: this.props.raceThoughtAddIsLoading,
            }}
            addonButtonResetInputOnClick={true}
            disabled={this.props.raceThoughtAddIsLoading}
          />
        </div>
        <div className="row fix-width no-gutters race-activity-page__feed">
          <div className="col-12">
            {feed.map((feedItem, index) => (
              <FeedItem
                key={index}
                feedItem={feedItem}
                onButtonClick={this.handleFeedItemButtonClick}
              />
            ))}
          </div>
        </div>
      </div>
    ) : null;
  }

  renderRanking() {
    const candidates = _get(
      this.props,
      'raceDetailsData.candidates.contenders',
      [],
    );
    const numberOfHiddenCandidates =
      candidates.length - this.state.maxRankingItemsToDisplay;

    return !this.props.isMobile || this.state.mobileSubSection === 'ranking' ? (
      <RankingList
        items={this.getRankingListItems(candidates)}
        title="Candidates ranking"
        buttonTopText="SEE ALL"
        buttonTopLink={URL_RACE_ACTIVITY_RANKING_PAGE.replace(
          ':raceId',
          this.getRaceId(),
        )}
        buttonBottomText={
          numberOfHiddenCandidates > 0
            ? `${numberOfHiddenCandidates} More`
            : null
        }
        onButtonBottomClick={this.handleRankingListBottomButtonClick}
      />
    ) : null;
  }

  renderGroups() {
    const groups = _get(this.props, 'raceGroupsData.data.groups', []);

    return !this.props.isMobile || this.state.mobileSubSection === 'groups' ? (
      <ListWithAvatars
        title="Groups"
        emptyListMessage="This race doesn't have any related group."
        items={groups.map(group => ({
          id: group.id,
          name: group.name,
          href: URL_GROUP_PAGE.replace(':groupId', group.id),
          image: group.group_image,
          badge: false,
        }))}
      />
    ) : null;
  }

  getRaceId() {
    return parseInt(this.props.match.params.raceId);
  }

  getBreadCrumbLinks() {
    return [
      {
        href: URL_FEED_PAGE,
        content: 'FEED',
        active: false,
        title: 'Your feed page',
      },
      {
        href: URL_RACE_ACTIVITY_PAGE.replace(':raceId', this.getRaceId()),
        content: 'RACE ACTIVITY',
        active: true,
        title: `${this.props.raceDetailsData.race.name}'s race`,
      },
    ];
  }

  getRankingListItems(candidates: any[]) {
    let items = [];
    const max =
      candidates.length < this.state.maxRankingItemsToDisplay
        ? candidates.length
        : this.state.maxRankingItemsToDisplay;

    for (let i = 0; i < max; i += 1) {
      const candidate = candidates[i];

      items.push({
        key: candidate.id,
        rank: candidate.rank,
        name: `${candidate.first_name} ${candidate.last_name}`,
        href: URL_USER_PUBLIC_PROFILE.replace(':id', candidate.id),
        image: candidate.profile_image,
        badge: true,
      });
    }

    return items;
  }

  handleRankingListBottomButtonClick = () => {
    this.setState(prevState => ({
      maxRankingItemsToDisplay: prevState.maxRankingItemsToDisplay + 3,
    }));
  };

  handleMobileMenuClick = (data: { item: any, index: number }) => {
    this.setState(() => ({ mobileSubSection: data.item.key }));
  };

  handleBackToMobileFeed = () => {
    this.setState(() => ({ mobileSubSection: 'feed' }));
  };

  handleThoughtSendClick = data => {
    if (data.value) {
      this.props.createRaceThought({
        raceId: this.getRaceId(),
        race_thought: {
          thought: data.value,
        },
      });
    }
  };

  handleFeedItemButtonClick = feedItem => {
    console.log(feedItem);
  };

  isLoading() {
    return !(this.props.raceDetailsData && this.props.raceFeedData);
  }
}

const mapStateToProps = (state, ownProps) => {
  return {
    ...raceDetails.mapStateToProps(state),
    ...raceFeed.mapStateToProps(state),
    ...raceGroups.mapStateToProps(state),
    ...raceThoughtAdd.mapStateToProps(state),
    ...ownProps,
  };
};

const mapDispatchToProps = dispatch => {
  return {
    ...raceDetails.mapDispatchToProps(dispatch),
    ...raceFeed.mapDispatchToProps(dispatch),
    ...raceGroups.mapDispatchToProps(dispatch),
    ...raceThoughtAdd.mapDispatchToProps(dispatch),
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(withDisplayDimensions(RaceActivityPage));
