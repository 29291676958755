// @flow
import makeDefaultServerCallReducer from '../../utilities/makeDefaultServerCallReducer';
import {
  GROUP_POSTS_ERROR,
  GROUP_POSTS_LOADING,
  GROUP_POSTS_SUCCESS,
  GROUP_POSTS_RESET,
  GROUP_POSTS_LIKE_POST,
  GROUP_POSTS_UNLIKE_POST,
  GROUP_POSTS_SHARE_POST,
} from './groupPosts.constants';
import _cloneDeep from 'lodash/cloneDeep';
import _find from 'lodash/find';
import _get from 'lodash/get';

const initialState = {
  isLoading: false,
  error: null,
  data: null,
};

const shareGroupPost = (state, action) => {
  if (state.data) {
    const data = _cloneDeep(state.data);

    if (_get(data, 'posts')) {
      let postToUpdate = _find(
        data.posts,
        post => post.id === action.payload.postId,
      );

      if (postToUpdate) {
        postToUpdate.share_count += 1;
      }
    }

    return {
      ...state,
      data,
    };
  }

  return state;
};

const likeGroupPost = (state, action) => {
  // Once the like is successful we have to grab the id of the post
  // and update both the like_count and has_like attribute in all state nodes
  // (race_feed *and* owner user_posts, group_posts *and* owner user_posts
  // or user_posts).

  if (state.data) {
    const data = _cloneDeep(state.data);

    if (_get(data, 'posts')) {
      let postToUpdate = _find(
        data.posts,
        post => post.id === action.payload.postId,
      );

      if (postToUpdate) {
        postToUpdate.like_count += 1;
        postToUpdate.has_liked = true;
      }
    }

    return {
      ...state,
      data,
    };
  }

  return state;
};

const unLikeGroupPost = (state, action) => {
  if (state.data) {
    const data = _cloneDeep(state.data);

    if (_get(data, 'posts')) {
      let postToUpdate = _find(
        data.posts,
        post => post.id === action.payload.postId,
      );

      if (postToUpdate) {
        postToUpdate.like_count -= 1;
        postToUpdate.has_liked = false;
      }
    }

    return {
      ...state,
      data,
    };
  }

  return state;
};

export const reducer = makeDefaultServerCallReducer({
  loadingActions: [GROUP_POSTS_LOADING],
  successActions: [GROUP_POSTS_SUCCESS],
  errorActions: [GROUP_POSTS_ERROR],
  resetActions: [GROUP_POSTS_RESET],
  otherActions: [
    { name: GROUP_POSTS_LIKE_POST, callback: likeGroupPost },
    { name: GROUP_POSTS_UNLIKE_POST, callback: unLikeGroupPost },
    { name: GROUP_POSTS_SHARE_POST, callback: shareGroupPost },
  ],
  initialState,
});
