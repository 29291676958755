// @flow
import React from 'react';
import _get from 'lodash/get';
import _throttle from 'lodash/throttle';
import { connect } from 'react-redux';
import { message, Popconfirm } from 'antd';
import Avatar from '../../components/Avatar/Avatar';
import Button from '../../components/Button/Button';
import Currency from '../../components/Currency/Currency';
import Table from '../../components/Table/Table';
import ListWithAvatars from '../../components/ListWithAvatars/ListWithAvatars';
import accountDetails from '../../redux/modules/accountDetails/accountDetails.containers';
import groupDetails from '../../redux/modules/groupDetails/groupDetails.containers';
import groupMembers from '../../redux/modules/groupMembers/groupMembers.containers';
import groupApproveRequest from '../../redux/modules/groupApproveRequest/groupApproveRequest.containers';
import groupDenyRequest from '../../redux/modules/groupDenyRequest/groupDenyRequest.containers';
import groupMemberPendingRequests from '../../redux/modules/groupMemberPendingRequests/groupMemberPendingRequests.containers';
import withDisplayDimensions from '../../hoc/withDisplayDimensions';
import listRoles from '../../redux/modules/listRoles/listRoles.containers';
import groupRemoveMember from '../../redux/modules/groupRemoveMember/groupRemoveMember.containers';
import type { AccountDetailsMapStateToProps } from '../../redux/modules/accountDetails/accountDetails.containers';
import type {
  GroupMembersMapDispatchToProps,
  GroupMembersMapStateToProps,
} from '../../redux/modules/groupMembers/groupMembers.containers';
import type {
  GroupMemberPendingRequestsMapDispatchToProps,
  GroupMemberPendingRequestsMapStateToProps,
} from '../../redux/modules/groupMemberPendingRequests/groupMemberPendingRequests.containers';
import type {
  ListRolesMapDispatchToProps,
  ListRolesMapStateToProps,
} from '../../redux/modules/listRoles/listRoles.containers';
import type {
  GroupDetailsMapDispatchToProps,
  GroupDetailsMapStateToProps,
} from '../../redux/modules/groupDetails/groupDetails.containers';
import ListItemPendingRequest from '../../components/ListItemPendingRequest/ListItemPendingRequest';
import Input from '../../components/Input/Input';
import type {
  GroupApproveRequestMapDispatchToProps,
  GroupApproveRequestMapStateToProps,
} from '../../redux/modules/groupApproveRequest/groupApproveRequest.containers';
import type {
  GroupDenyRequestMapDispatchToProps,
  GroupDenyRequestMapStateToProps,
} from '../../redux/modules/groupDenyRequest/groupDenyRequest.containers';
import type { WithDisplayDimensionsOutputProps } from '../../hoc/withDisplayDimensions';
import type { GroupRemoveMemberMapStateToProps } from '../../redux/modules/groupRemoveMember/groupRemoveMember.containers';
import './GroupMembersPage.scss';
import type { GroupMember } from '../../services/RoRGroupsApiProvider';
import ModalGroupMemberContributions from '../../components/ModalGroupMemberContributions/ModalGroupMemberContributions';
import type { UserListView } from '../../services/RoRUsersApiProvider';
import isPrytanyVerified from '../../utilities/isPrytanyVerified';
import RoleCode from '../../constants/RoleCode';

const MEMBERS_PAGE_SIZE = 10;
const MEMBERS_REQUEST_PAGE_SIZE = 10;

type Props = AccountDetailsMapStateToProps &
  GroupMembersMapDispatchToProps &
  GroupMembersMapStateToProps &
  GroupMemberPendingRequestsMapStateToProps &
  GroupMemberPendingRequestsMapDispatchToProps &
  GroupApproveRequestMapStateToProps &
  GroupApproveRequestMapDispatchToProps &
  GroupDenyRequestMapDispatchToProps &
  GroupDenyRequestMapStateToProps &
  GroupDetailsMapStateToProps &
  GroupDetailsMapDispatchToProps &
  ListRolesMapDispatchToProps &
  ListRolesMapStateToProps &
  GroupRemoveMemberMapStateToProps &
  GroupDenyRequestMapDispatchToProps &
  WithDisplayDimensionsOutputProps & {
    match: any,
    location: any,
    history: any,
  };

type State = {
  tableSelectedRows: any[],
  tableSelectedRowKeys: number[],
  hoveredRowKey: number | null,
  searchNameValue: string,
  mobileSubSection: 'members' | 'pendingRequests',
  showingContributionDetailsForMember?: GroupMember,
};

class GroupMembersPage extends React.Component<Props, State> {
  constructor(props: Props) {
    super(props);

    this.state = {
      tableSelectedRows: [],
      tableSelectedRowKeys: [],
      searchNameValue: '',
      hoveredRowKey: null,
      mobileSubSection: 'members',
      showingContributionDetailsForMember: undefined,
    };

    this.props.listRolesReset();
    this.props.groupMembersReset();
    this.props.groupMemberPendingRequestsReset();
  }

  componentDidMount() {
    if (typeof window !== 'undefined') {
      message.loading('Updating members...', 3);

      this.fetchMembers();
    }
  }

  componentDidUpdate(prevProps: Props) {
    if (
      this.props.groupDetailsData &&
      !this.props.listRolesData &&
      !this.props.listRolesIsLoading
    ) {
      this.props.getListRoles();
    }

    if (
      !prevProps.listRolesData &&
      this.props.listRolesData &&
      this.isModerator()
    ) {
      this.fetchMemberRequests();
    }

    if (
      prevProps.groupApproveRequestIsLoading &&
      !this.props.groupApproveRequestIsLoading
    ) {
      this.fetchMembers();
    }

    if (!prevProps.groupRemoveMemberData && this.props.groupRemoveMemberData) {
      this.handleRemoveMemberSuccess();
    } else if (
      !prevProps.groupRemoveMemberError &&
      this.props.groupRemoveMemberError
    ) {
      this.handleRemoveMemberError();
    }
  }

  render() {
    return this.props.groupDetailsData ? (
      <div className="group-members-page container-fluid">
        {this.renderTopBar()}
        {this.renderMobileMemberRequestsCTA()}
        <div className="row fix-width no-gutters">
          {this.renderSideBar()}
          {this.renderMembersTable()}
        </div>
        {this.state.showingContributionDetailsForMember && (
          <ModalGroupMemberContributions
            isOpen={true}
            groupId={this.getGroupId()}
            groupMember={this.state.showingContributionDetailsForMember}
            onCloseRequest={() =>
              this.setState({ showingContributionDetailsForMember: undefined })
            }
          />
        )}
      </div>
    ) : null;
  }

  renderTopBar() {
    return !this.props.isMobile ? (
      <div className="row fix-width">
        <div className="col-12 offset-md-3 col-md-9 pr-md-4 pt-4 pb-2">
          <div className="row no-gutters">
            <div className="col-7 col-lg-8 px-0">
              <Input
                name="searchMember"
                type="text"
                size="small"
                useFormik={false}
                icon="/images/icon_lens.png"
                onChange={_throttle(this.handleSearchMember, 750)}
              />
            </div>
          </div>
        </div>
      </div>
    ) : null;
  }

  renderMobileMemberRequestsCTA() {
    const candidateRequestsNumber = _get(
      this.props,
      'groupMemberPendingRequestsData.candidate.data.requests.length',
      0,
    );
    const citizenRequestsNumber = _get(
      this.props,
      'groupMemberPendingRequestsData.citizen.data.requests.length',
      0,
    );
    const requestsNumber = candidateRequestsNumber + citizenRequestsNumber;

    return this.props.isMobile &&
      requestsNumber &&
      this.state.mobileSubSection !== 'pendingRequests' &&
      this.isModerator() ? (
      <div className="row fix-width">
        <div
          className="group-members-page__mobile-member-request-cta"
          onClick={this.handleMobileMemberRequestCTAClick}>
          <div className="member-request-cta__left-wrapper">
            <img
              src="/images/icon_user-add.png"
              alt=""
              className="member-request-cta__icon-user"
            />
            <span>
              {requestsNumber} Pending{' '}
              {requestsNumber === 1 ? 'request' : 'requests'}
            </span>
          </div>
          <img
            src="/images/menumob-arrow-right.png"
            alt=""
            className="member-request-cta__icon-arrow"
          />
        </div>
      </div>
    ) : null;
  }

  renderSideBar() {
    const candidates = _get(
      this.props,
      'groupMemberPendingRequestsData.candidate.data.requests',
    );
    const citizens = _get(
      this.props,
      'groupMemberPendingRequestsData.citizen.data.requests',
    );
    const candidateNextPage = _get(
      this.props,
      'groupMemberPendingRequestsData.candidate.next_page',
    );
    const citizensNextPage = _get(
      this.props,
      'groupMemberPendingRequestsData.citizen.next_page',
    );

    return !this.props.isMobile ||
      (this.props.isMobile &&
        this.state.mobileSubSection === 'pendingRequests') ? (
      <div className="col-12 col-md-3 pr-md-4 group-members-page__side-bar">
        {candidates && citizens && this.isModerator() ? (
          <React.Fragment>
            <h6 className="fs-18 pb-4">Pending Requests</h6>
            {!candidates.length && !citizens.length ? (
              <p>There are no pending requests for this group</p>
            ) : null}
            {candidates.length ? (
              <ListWithAvatars
                title="CANDIDATES"
                emptyListMessage=""
                buttonTop={
                  candidates.length ? this.renderListButtonTop('candidate') : ''
                }
                items={candidates.map(user => ({
                  ...user,
                  renderItem: this.renderPendingRequestItem,
                }))}
                buttonBottomText={candidateNextPage ? 'VIEW MORE' : ''}
                onButtonBottomClick={() =>
                  this.fetchMemberRequestNextPage('candidate')
                }
                buttonBottomProps={{
                  loading: this.props.groupMemberPendingRequestsIsLoading,
                }}
              />
            ) : null}

            {citizens.length ? (
              <ListWithAvatars
                title="CONSTITUENTS"
                emptyListMessage="T"
                buttonTop={
                  citizens.length ? this.renderListButtonTop('citizen') : ''
                }
                items={citizens.map(user => ({
                  ...user,
                  renderItem: this.renderPendingRequestItem,
                }))}
                buttonBottomText={citizensNextPage ? 'VIEW MORE' : ''}
                onButtonBottomClick={() =>
                  this.fetchMemberRequestNextPage('citizen')
                }
                buttonBottomProps={{
                  loading: this.props.groupMemberPendingRequestsIsLoading,
                }}
              />
            ) : null}
          </React.Fragment>
        ) : null}
      </div>
    ) : null;
  }

  renderPendingRequestItem = item => {
    const { user, isLoading } = item;
    return (
      <ListItemPendingRequest
        user={user}
        isLoading={isLoading}
        key={item.id}
        onApproveButtonClick={() => this.handleApproveRequest(user)}
        onDenyButtonClick={() => this.handleDenyRequest(user)}
      />
    );
  };

  renderListButtonTop = (type: 'candidate' | 'citizen') => {
    const onConfirm = () => this.handleApproveAllRequests(type);
    return (
      <Popconfirm
        title="Are you sure you want to approve all the pending request?"
        onConfirm={onConfirm}
        okText="Yes"
        cancelText="No">
        <Button size="small" buttonType="link" className="link-violet">
          APPROVE ALL
        </Button>
      </Popconfirm>
    );
  };

  renderMembersTable() {
    return this.props.groupMembersData &&
      (!this.props.isMobile ||
        (this.props.isMobile && this.state.mobileSubSection === 'members')) ? (
      <div className="col-12 col-md-9">
        <div className="group-members-page__table-wrapper">
          <Table {...this.getTableProps()} />
        </div>
      </div>
    ) : null;
  }

  renderRemoveMemberButton(data) {
    return (
      <Popconfirm
        title="Are you sure you want to remove this member from the group?"
        onConfirm={() => this.handleRemoveMember(data)}
        okText="Yes"
        cancelText="No">
        <Button buttonType="link" type="button">
          <img src="/images/icon_trash-red.png" alt="" />
        </Button>
      </Popconfirm>
    );
  }

  renderMemberTotalContribution = data => {
    return (
      <Button
        buttonType="link"
        type="button"
        size="small"
        onClick={() => this.handleShowMemberContributions(data)}>
        <Currency amount={data.contributed} />
      </Button>
    );
  };

  fetchMembers(page: number = 1) {
    this.props.getMembersForGroup({
      groupId: this.getGroupId(),
      limit: MEMBERS_PAGE_SIZE,
      name: this.state.searchNameValue,
      page,
    });
  }

  fetchMemberRequestNextPage = (roleCode: 'citizen' | 'candidate') => {
    this.props.getGroupMemberPendingRequestsNextPage({
      groupId: this.getGroupId(),
      limit: MEMBERS_REQUEST_PAGE_SIZE,
      roleCode,
    });
  };

  isModerator() {
    return _get(
      this.props,
      'groupDetailsData.group.current_user.is_moderator',
      false,
    );
  }

  getGroupId() {
    return parseInt(this.props.match.params.groupId);
  }

  fetchMemberRequests(page = 1) {
    const roles = [];
    const groupId = this.getGroupId();

    this.props.listRolesData.roles.forEach(role => {
      if (role.code === 'citizen' || role.code === 'candidate') {
        roles.push({ id: role.id, code: role.code });
      }
    });

    this.props.getMemberPendingRequestsForGroup({
      groupId,
      limit: MEMBERS_REQUEST_PAGE_SIZE,
      roles,
      page,
    });
  }

  getTableProps() {
    const tableProps: any = {
      dataSource: this.getTableData(),
      columns: this.getTableColumns(),
      pagination:
        this.props.groupMembersData.total_pages > 1
          ? {
              total:
                this.props.groupMembersData.total_pages * MEMBERS_PAGE_SIZE,
              current: this.props.groupMembersData.current_page,
              pageSize: MEMBERS_PAGE_SIZE,
            }
          : false,
      loading: this.props.groupMembersIsLoading,
      onRow: record => ({
        onMouseEnter: () =>
          this.setState(() => ({ hoveredRowKey: record.key })),
        onMouseLeave: () => this.setState(() => ({ hoveredRowKey: null })),
      }),
      onChange: this.handleTableChange,
    };

    if (this.isModerator()) {
      tableProps.rowSelection = {
        onChange: this.handleTableRowSelection,
        getCheckboxProps: (record: any) => ({
          disabled: false,
          name: record.key,
        }),
        selectedRowKeys: this.state.tableSelectedRowKeys,
      };
    }

    return tableProps;
  }

  getTableData() {
    const members = this.props.groupMembersData
      ? this.props.groupMembersData.data.members
      : [];
    let tableData = [];
    members.forEach(member => {
      if (
        this.props.accountDetailsData &&
        this.props.accountDetailsData.user.id === member.id
      ) {
        return;
      }

      tableData.push({
        key: member.id,
        name: `${member.first_name} ${member.last_name}`.trim(),
        state: member.state ? member.state.name : '',
        profileImage: member.profile_image,
        sharedInterests: member.shared_interests || 0,
        contributed: (member.donations.contributed || 0) / 100,
        role: member.role.code,
        verifiedLevel: member.verified_level,
        verifiedDetail: member.verified_detail,
        removeButton: this.state.hoveredRowKey === member.id,
      });
    });

    return tableData;
  }

  handleTableRowSelection = (
    selectedRowKeys: number[],
    selectedRows: any[],
  ) => {
    this.setState(() => ({
      tableSelectedRows: selectedRows,
      tableSelectedRowKeys: selectedRowKeys,
    }));
  };

  handleMobileBackClick = () => {
    this.setState(() => ({ mobileSubSection: 'members' }));
  };

  handleMobileMemberRequestCTAClick = () => {
    this.setState(() => ({ mobileSubSection: 'pendingRequests' }));
  };

  handleTableChange = (pagination: any, filters: any, sorter: any) => {
    // TODO: add sorting logic once the server supports it
    this.fetchMembers(pagination.current);
  };

  handleSearchMember = (inputData: { name: string, value: string }) => {
    this.setState(
      () => ({ searchNameValue: inputData.value }),
      () => {
        this.fetchMembers();
      },
    );
  };

  handleApproveAllRequests = (type: 'citizen' | 'candidate') => {
    const role = this.props.listRolesData.roles.find(role => {
      return role.code === type;
    });

    this.props.groupApproveRequest({
      groupId: this.getGroupId(),
      roleId: role.id,
      roleCode: role.code,
    });
    this.fetchMembers();
  };

  handleApproveRequest = (user: UserListView) => {
    const requests = _get(
      this.props,
      `groupMemberPendingRequestsData.${user.role.code}.data.requests`,
    );
    const nextPage = _get(
      this.props,
      `groupMemberPendingRequestsData.${user.role.code}.next_page`,
    );

    this.props.groupApproveRequest({
      groupId: this.getGroupId(),
      userId: user.id,
    });

    if (requests.length <= 2 && nextPage) {
      this.fetchMemberRequestNextPage(
        ((user.role.code: any): 'citizen' | 'candidate'),
      );
    }
  };

  handleDenyRequest = (user: UserListView) => {
    const requests = _get(
      this.props,
      `groupMemberPendingRequestsData.${user.role.code}.data.requests`,
    );
    const nextPage = _get(
      this.props,
      `groupMemberPendingRequestsData.${user.role.code}.next_page`,
    );

    this.props.groupDenyRequest({
      groupId: this.getGroupId(),
      userId: user.id,
    });

    if (requests.length <= 2 && nextPage) {
      this.fetchMemberRequestNextPage(
        ((user.role.code: any): 'citizen' | 'candidate'),
      );
    }
  };

  handleRemoveMember = (data: any) => {
    this.props.removeMemberFromGroup({
      groupId: this.getGroupId(),
      userId: data.key,
    });
  };

  handleRemoveMemberSuccess = () => {
    message.success('User was successfully removed from group.');
    this.props.groupRemoveMemberReset();
  };

  handleRemoveMemberError() {
    message.error('Something went wrong. Please try again later.');
    this.props.groupRemoveMemberReset();
  }

  handleShowMemberContributions = (data: { key: number }) => {
    const groupMember = this.props.groupMembersData.data.members.find(
      member => member.id === data.key,
    );
    this.setState({ showingContributionDetailsForMember: groupMember });
  };

  getTableColumns() {
    let columns = [];

    if (this.props.isMobile) {
      columns = [
        {
          title: 'NAME',
          dataIndex: 'name',
          key: 'name',
          sorter: (a: any, b: any) => a.name.localeCompare(b.name),
        },
        {
          title: 'CONTRIBUTED',
          dataIndex: 'contributed',
          key: 'contributed',
          sorter: (a: any, b: any) => a.contributed - b.contributed,
          render: (amount: number, data) =>
            this.renderMemberTotalContribution(data),
        },
      ];
    } else {
      columns = [
        {
          title: 'NAME',
          dataIndex: 'name',
          key: 'name',
          defaultSortOrder: 'ascend',
          render: (text: string, item: any) => {
            return (
              <React.Fragment>
                <Avatar
                  isCandidate={item.role === 'candidate'}
                  verifiedLevel={item.verifiedLevel}
                  verifiedDetail={item.verifiedDetail}
                  className="dashboard-page__table-cell__name-avatar"
                  source={item.profileImage}
                  type="tiny"
                />
                <span>{text}</span>
              </React.Fragment>
            );
          },
          sorter: (a: any, b: any) => a.name.localeCompare(b.name),
        },
        {
          title: 'STATE',
          dataIndex: 'state',
          key: 'state',
          sorter: (a: any, b: any) => a.state.localeCompare(b.state),
        },
        {
          title: 'SHARED INTERESTS',
          dataIndex: 'sharedInterests',
          key: 'sharedInterests',
          sorter: (a: any, b: any) => a.sharedInterests - b.sharedInterests,
        },
        {
          title: 'CONTRIBUTED',
          dataIndex: 'contributed',
          key: 'contributed',
          sorter: (a: any, b: any) => a.contributed - b.contributed,
          render: (amount: number, data) =>
            this.renderMemberTotalContribution(data),
        },
        {
          title: '',
          dataIndex: 'removeButton',
          key: 'removeButton',
          render: (removeButton: boolean, data) => {
            return removeButton && this.isModerator() ? (
              this.renderRemoveMemberButton(data)
            ) : (
              <span className="group-members-page__table-placeholder" />
            );
          },
        },
      ];
    }

    return columns;
  }
}

const mapStateToProps = (state, ownProps) => {
  return {
    ...accountDetails.mapStateToProps(state),
    ...groupDetails.mapStateToProps(state),
    ...groupMembers.mapStateToProps(state),
    ...groupApproveRequest.mapStateToProps(state),
    ...groupMemberPendingRequests.mapStateToProps(state),
    ...groupDenyRequest.mapStateToProps(state),
    ...listRoles.mapStateToProps(state),
    ...groupRemoveMember.mapStateToProps(state),
    ...ownProps,
  };
};

const mapDispatchToProps = dispatch => {
  return {
    ...groupDetails.mapDispatchToProps(dispatch),
    ...groupMembers.mapDispatchToProps(dispatch),
    ...groupApproveRequest.mapDispatchToProps(dispatch),
    ...groupMemberPendingRequests.mapDispatchToProps(dispatch),
    ...groupDenyRequest.mapDispatchToProps(dispatch),
    ...listRoles.mapDispatchToProps(dispatch),
    ...groupRemoveMember.mapDispatchToProps(dispatch),
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(withDisplayDimensions(GroupMembersPage));
