import {
  updateGroup as update,
  groupUpdateReset as reset,
} from './groupUpdate.actions';
import type {
  PutGroupUpdatePayload,
  PutGroupUpdateResponse,
} from '../../../services/RoRGroupsApiProvider';

export const mapStateToProps = state => {
  return {
    groupDetailsUpdateData: state.groupUpdate.data,
    groupDetailsUpdateError: state.groupUpdate.error,
    groupDetailsUpdateIsLoading: state.groupUpdate.isLoading,
  };
};

export const mapDispatchToProps = dispatch => {
  return {
    updateGroupDetails(payload: PutGroupUpdatePayload) {
      dispatch(update(payload));
    },
    updateGroupDetailsReset() {
      dispatch(reset());
    },
  };
};

export type GroupUpdateMapStateToProps = {
  groupDetailsUpdateData: PutGroupUpdateResponse,
  groupDetailsUpdateError: any,
  groupDetailsUpdateIsLoading: boolean,
};

export type GroupUpdateMapDispatchToProps = {
  updateGroupDetails: (payload: PutGroupUpdatePayload) => void,
  updateGroupDetailsReset: () => void,
};

export default {
  mapStateToProps,
  mapDispatchToProps,
};
