// @flow
import React from 'react';
import { connect } from 'react-redux';
import { message } from 'antd';
import account from '../../redux/modules/account/account.containers';
import accountDetails from '../../redux/modules/accountDetails/accountDetails.containers';
import accountPosts from '../../redux/modules/accountPosts/accountPosts.containers';
import postRemove from '../../redux/modules/postRemove/postRemove.containers';
import PostListWithFilters from '../../components/PostListWithFilters/PostListWithFilters';
import type { UserPost } from '../../services/RoRUsersApiProvider';
import type { PostProps } from '../../components/Post/Post';
import type { AccountDetailsMapStateToProps } from '../../redux/modules/accountDetails/accountDetails.containers';
import type { AccountMapStateToProps } from '../../redux/modules/account/account.containers';
import {
  PostRemoveMapStateToProps,
  PostRemoveMapDispatchToProps,
} from '../../redux/modules/postRemove/postRemove.containers';
import './DashboardPostsPage.scss';

type Props = AccountDetailsMapStateToProps &
  AccountMapStateToProps &
  PostRemoveMapStateToProps &
  PostRemoveMapDispatchToProps & {
    accountPostsData: any,
    accountPostsError: any,
    accountPostsIsLoading: any,
    getAccountPosts: Function,
  };

class DashboardPostsPage extends React.Component<Props, {}> {
  constructor(props: Props) {
    super(props);
  }

  componentDidMount() {
    if (typeof window !== 'undefined') {
      this.props.getAccountPosts();
      message.loading('Updating posts...', 3);
    }
  }

  componentDidUpdate(prevProps: Props, prevState: State) {
    if (!prevProps.postRemoveError && this.props.postRemoveError) {
      message.error(
        this.props.postRemoveError.localMessage ||
          'An error has occurred while deleting the post.',
      );
    }

    if (!prevProps.postRemoveData && this.props.postRemoveData) {
      message.success(
        this.props.postRemoveData.localMessage || 'Post successfully deleted.',
      );
      this.props.getAccountPosts();
    }
  }

  render() {
    return (
      <div className="dashboard-posts-page container-fluid">
        <PostListWithFilters
          posts={this.parsePosts()}
          userId={this.props.accountData.user.id}
        />
      </div>
    );
  }

  parsePosts() {
    const posts: UserPost[] =
      (this.props.accountPostsData && this.props.accountPostsData.posts) || [];

    return posts.map(
      (post: UserPost): PostProps => {
        const account =
          (this.props.accountData && this.props.accountData.user) || {};
        const accountDetails =
          (this.props.accountDetailsData &&
            this.props.accountDetailsData.user) ||
          {};

        const postProps = {
          key: post.id,
          id: post.id,
          userId: account.id,
          title: post.title,
          shortDescription: post.short_description,
          commentCount: post.comment_count,
          likeCount: post.like_count,
          shareCount: post.share_count,
          currentUserHasLiked: post.has_liked,
          commentable: post.commentable,
          postImage: post.post_image,
          createdAt: post.created_at,
          userIsCandidate: account.role && account.role.code === 'candidate',
          userAvatar: accountDetails.profile_image,
          userFullName: `${account.first_name} ${account.last_name}`.trim(),
        };

        return postProps;
      },
    );
  }
}

const mapStateToProps = (state, ownProps) => {
  return {
    ...account.mapStateToProps(state),
    ...accountDetails.mapStateToProps(state),
    ...accountPosts.mapStateToProps(state),
    ...postRemove.mapStateToProps(state),
    ...ownProps,
  };
};

const mapDispatchToProps = dispatch => {
  return {
    ...accountPosts.mapDispatchToProps(dispatch),
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(DashboardPostsPage);
