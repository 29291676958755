export const USER_DETAILS_SUCCESS = '@@prytany/global/USER_DETAILS_SUCCESS';
export const USER_DETAILS_LOADING = '@@prytany/global/USER_DETAILS_LOADING';
export const USER_DETAILS_ERROR = '@@prytany/global/USER_DETAILS_ERROR';
export const USER_DETAILS_RESET = '@@prytany/global/USER_DETAILS_RESET';
export const USER_DETAILS_SET_AS_FOLLOWED =
  '@@prytany/global/USER_DETAILS_SET_AS_FOLLOWED';
export const USER_DETAILS_SET_AS_UNFOLLOWED =
  '@@prytany/global/USER_DETAILS_SET_AS_UNFOLLOWED';
export const USER_DETAILS_ADD_CONTRIBUTION =
  '@@prytany/global/USER_DETAILS_ADD_CONTRIBUTION';
