import {
  userProfileFollowingReset as reset,
  getUserProfileFollowing,
} from './userProfileFollowing.actions';
import type { GetFollowingPayload } from '../../../services/RoRProfileApiProvider';

export const mapStateToProps = state => {
  return {
    profileFollowingData: state.userProfileFollowing.data,
    profileFollowingError: state.userProfileFollowing.error,
    profileFollowingIsLoading: state.userProfileFollowing.isLoading,
  };
};

export const mapDispatchToProps = dispatch => {
  return {
    getUserProfileFollowing(payload: GetFollowingPayload) {
      dispatch(getUserProfileFollowing(payload));
    },
    profileFollowingReset() {
      dispatch(reset());
    },
  };
};

export type UserProfileFollowingMapStateToProps = {
  profileFollowingData: any,
  profileFollowingError: any,
  profileFollowingIsLoading: boolean,
};

export type UserProfileFollowingMapDispatchToProps = {
  profileFollowingProfile: Function,
  profileFollowingReset: Function,
};

export default {
  mapStateToProps,
  mapDispatchToProps,
};
