// @flow
import React from 'react';
import ContributeDirect from '../../components/ContributeDirect/ContributeDirect';
import MobileContributionsTypePage from './MobileContributionsTypePage';

const MobileContributionsDirectPage = () => {
  return (
    <MobileContributionsTypePage>
      {({ mobileToken, mobileEmail, mobileGroupId, ...rest }) => (
        <ContributeDirect
          customPaymentToken={mobileToken}
          customPaymentEmail={mobileEmail}
          groupId={mobileGroupId}
          {...rest}
        />
      )}
    </MobileContributionsTypePage>
  );
};

export default MobileContributionsDirectPage;
