import React from 'react';
import Route from 'react-router-dom/Route';
import Redirect from 'react-router-dom/Redirect';
import Switch from 'react-router-dom/Switch';
import withRouter from 'react-router-dom/withRouter';
import withAnalytics, { initAnalytics } from 'react-with-analytics';
import HomePage from './pages/HomePage/HomePage';
import AboutPage from './pages/AboutPage/AboutPage';
import PrytanyTVPage from './pages/PrytanyTVPage/PrytanyTVPage';
import PrivacyPolicyPage from './pages/PrivacyPolicyPage/PrivacyPolicyPage';
import CodeOfConductPage from './pages/CodeOfConductPage/CodeOfConductPage';
import UserProfilePage from './pages/UserProfilePage/UserProfilePage';
import UserAgreementPage from './pages/UserAgreementPage/UserAgreementPage';
import CampaignManagerSignupPage from './pages/CampaignManagerSignupPage/CampaignManagerSignUpPage';
import DashboardPage from './pages/DashboardPage/DashboardPage';
import FrequentAskedQuestionsPage from './pages/FrequentAskedQuestionsPage/FrequentAskedQuestionsPage';
import FeedPage from './pages/FeedPage/FeedPage';
import GroupPage from './pages/GroupPage/GroupPage';
import LoginPage from './pages/LoginPage/LoginPage';
import MyAccountPage from './pages/MyAccountPage/MyAccountPage';
import PostPage from './pages/PostPage/PostPage';
import RaceActivityPage from './pages/RaceActivityPage/RaceActivityPage';
import RaceActivityRankingPage from './pages/RaceActivityRankingPage/RaceActivityRankingPage';
import SignUpPage from './pages/SignUpPage/SignUpPage';
import PrivateRoute from './components/PrivateRoute/PrivateRoute';
import Header from './components/Header/Header';
import Footer from './components/Footer/Footer';
import { DisplayDimensionsContext } from './context/displayDimensionsContext';
import './styles/global.scss';
import {
  URL_ABOUT_PAGE,
  URL_PRIVACY_POLICY_PAGE,
  URL_CODE_OF_CONDUCT_PAGE,
  URL_PRIVACY_POLICY_FAQ,
  URL_CONTRIBUTION_RULES,
  URL_LOGIN_PAGE,
  URL_MY_ACCOUNT_PAGE,
  URL_SEARCH_PAGE,
  URL_SIGN_UP_PAGE,
  URL_DASHBOARD_PAGE,
  URL_USER_PUBLIC_PROFILE,
  URL_GROUP_PAGE,
  URL_POST_PAGE,
  URL_FEED_PAGE,
  URL_PRYTANY_TV_PAGE,
  URL_RACE_ACTIVITY_PAGE,
  URL_RACE_ACTIVITY_RANKING_PAGE,
  URL_USER_AGREEMENT,
  URL_MOBILE_CONTRIBUTIONS,
  URL_CONTACT_US_PAGE,
  URL_FORGOT_PASSWORD_PAGE,
  URL_INBOX_PAGE,
  URL_MANAGER_SIGN_UP_PAGE,
  URL_VERIFY_ACCOUNT_PAGE,
  URL_MOBILE_WHAT_IS_PRYTANY,
} from './config/urls';
import MobileContributionsPage from './pages/MobileContributionsPage/MobileContributionsPage';
import SearchPage from './pages/SearchPage/SearchPage';
import ContactUsPage from './pages/ContactUsPage/ContactUsPage';
import InboxPage from './pages/InboxPage/InboxPage';
import ForgotPasswordPage from './pages/ForgotPasswordPage/ForgotPasswordPage';
import NotFoundPage from './pages/NotFoundPage/NotFoundPage';
import VerifyAccountPage from './pages/VerifyAccountPage/VerifyAccountPage';
import ContributionRulesPage from './pages/ContributionRulesPage/ContributionRulesPage';

if (process.env.RAZZLE_GA_ID) {
  initAnalytics(process.env.RAZZLE_GA_ID);
}

// TODO: set 404 page
// TODO: Create loadable routes
const Root = () => (
  <Switch>
    <Route
      path={URL_MOBILE_CONTRIBUTIONS}
      component={MobileContributionsPage}
    />{' '}
    {/* only used by iOS app */}
    <React.Fragment>
      <Header />
      <Switch>
        <Route exact path="/" component={HomePage} />
        <Redirect from={URL_MOBILE_WHAT_IS_PRYTANY} to="/" push />
        <Route exact path={URL_ABOUT_PAGE} component={AboutPage} />
        <Route
          exact
          path={URL_CODE_OF_CONDUCT_PAGE}
          component={CodeOfConductPage}
        />
        <Route
          exact
          path={URL_PRIVACY_POLICY_PAGE}
          component={PrivacyPolicyPage}
        />
        <Route exact path={URL_CONTACT_US_PAGE} component={ContactUsPage} />
        <Route exact path={URL_POST_PAGE} component={PostPage} />
        <PrivateRoute exact path={URL_SEARCH_PAGE} component={SearchPage} />
        <PrivateRoute path={URL_GROUP_PAGE} component={GroupPage} />
        <PrivateRoute path={URL_DASHBOARD_PAGE} component={DashboardPage} />
        <PrivateRoute path={URL_FEED_PAGE} component={FeedPage} />
        <PrivateRoute path={URL_PRYTANY_TV_PAGE} component={PrytanyTVPage} />
        <Route exact path={URL_LOGIN_PAGE} component={LoginPage} />
        <Route
          exact
          path={URL_FORGOT_PASSWORD_PAGE}
          component={ForgotPasswordPage}
        />
        <PrivateRoute
          path={URL_USER_PUBLIC_PROFILE}
          component={UserProfilePage}
        />
        <PrivateRoute path={URL_MY_ACCOUNT_PAGE} component={MyAccountPage} />
        <PrivateRoute exact path={URL_INBOX_PAGE} component={InboxPage} />
        <PrivateRoute
          exact
          path={URL_RACE_ACTIVITY_PAGE}
          component={RaceActivityPage}
        />
        <PrivateRoute
          exact
          path={URL_RACE_ACTIVITY_RANKING_PAGE}
          component={RaceActivityRankingPage}
        />
        <PrivateRoute
          exact
          path={URL_VERIFY_ACCOUNT_PAGE}
          urlToken={true}
          component={VerifyAccountPage}
        />
        <Route exact path={URL_SIGN_UP_PAGE} component={SignUpPage} />
        <Route exact path={URL_USER_AGREEMENT} component={UserAgreementPage} />
        <Route
          path={URL_MANAGER_SIGN_UP_PAGE}
          component={CampaignManagerSignupPage}
        />
        <Route
          exact
          path={URL_PRIVACY_POLICY_FAQ}
          component={FrequentAskedQuestionsPage}
        />
        <Route
          exact
          path={URL_CONTRIBUTION_RULES}
          component={ContributionRulesPage}
        />
        <Route component={NotFoundPage} />
      </Switch>
      <Footer />
    </React.Fragment>
  </Switch>
);

const AppRouter = withRouter(withAnalytics(Root));

type State = {
  displayWidth: number,
  displayHeight: number,
};

class App extends React.Component<any, State> {
  constructor(props: any) {
    super(props);

    this.state = {
      displayWidth:
        typeof document !== 'undefined'
          ? document.documentElement.clientWidth
          : 1920,
      displayHeight:
        typeof document !== 'undefined'
          ? document.documentElement.clientHeight
          : 1080,
    };
  }

  componentDidMount() {
    if (typeof window !== 'undefined') {
      window.addEventListener('resize', this.handleWindowResize);
    }
  }

  componentWillUnmount() {
    if (typeof window !== 'undefined') {
      window.removeEventListener('resize', this.handleWindowResize);
    }
  }

  render() {
    return (
      <DisplayDimensionsContext.Provider
        value={{
          displayWidth: this.state.displayWidth,
          displayHeight: this.state.displayHeight,
        }}>
        <AppRouter />
      </DisplayDimensionsContext.Provider>
    );
  }

  handleWindowResize = () => {
    this.setState(() => ({
      displayWidth: window.innerWidth,
      displayHeight: window.innerHeight,
    }));
  };
}

export default App;
