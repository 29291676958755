export const PASSWORD_REGEX = /^(?=(.*[a-z].*){1,})(?=(.*[A-Z].*){1,})(?=.*\d.*)(?=.*\W.*)[a-zA-Z0-9\S]{8,}$/;
export const PASSWORD_DESCRIPTIVE_TEXT =
  'Password must be a minimum of 8 characters long and contain at least one number, one uppercase character, one lowercase character, and one special character such as !, #, &, @';
export const CONTRIBUTION_CREDIT_CARD_EXTRA_CHARGE =
  '* Contributions made using a credit card carry a service fee of 2.9% + $0.30.';
export const CONTRIBUTION_ACH_FREE =
  'There are no fees when making contributions through ACH transfers.';
export const ONLY_ONE_LETTER_REGEX = /([A-Za-z]){1}/;
export const ONLY_ONE_LETTER_DESCRIPTIVE_TEXT = 'Only one letter allowed';
export const INVITE_USERS_MAIL =
  "mailto:?subject=You've been invited to join Prytany&body=:user has invited you to join the Prytany app. Prytany is revolutionizing the world of campaign and issue advocacy finance. As a non-partisan, political fundraising and social media platform, it serves as a one stop shop for users and candidates alike.%0D%0A Join us on Android, iOS, or Web.";
