// @flow
import React from 'react';
import moment from 'moment';
import _get from 'lodash/get';
import queryString from 'query-string';
import { message, Popconfirm } from 'antd';
import { connect } from 'react-redux';
import Linkify from 'react-linkify';
import postDetails from '../../redux/modules/postDetails/postDetails.containers';
import Avatar from '../../components/Avatar/Avatar';
import Input from '../../components/Input/Input';
import Loading from '../../components/Loading/Loading';
import PostCommentComponent from '../../components/PostComment/PostComment';
import Link from '../../components/Link/Link';
import { URL_FEED_PAGE } from '../../config/urls';
import account from '../../redux/modules/account/account.containers';
import accountDetails from '../../redux/modules/accountDetails/accountDetails.containers';
import postComments from '../../redux/modules/postComments/postComments.containers';
import postCommentAdd from '../../redux/modules/postCommentAdd/postCommentAdd.containers';
import postLike from '../../redux/modules/postLike/postLike.containers';
import postRemove from '../../redux/modules/postRemove/postRemove.containers';
import postFlag from '../../redux/modules/postFlag/postFlag.containers';
import type { PostComment } from '../../services/RoRPostsApiProvider';
import type {
  AccountDetailsMapDispatchToProps,
  AccountDetailsMapStateToProps,
} from '../../redux/modules/accountDetails/accountDetails.containers';
import './PostPage.scss';
import type {
  PostDetailsMapDispatchToProps,
  PostDetailsMapStateToProps,
} from '../../redux/modules/postDetails/postDetails.containers';
import Button from '../../components/Button/Button';
import type {
  PostLikeMapDispatchToProps,
  PostLikeMapStateToProps,
} from '../../redux/modules/postLike/postLike.containers';
import {
  PostRemoveMapStateToProps,
  PostRemoveMapDispatchToProps,
} from '../../redux/modules/postRemove/postRemove.containers';
import {
  PostFlagMapStateToProps,
  PostFlagMapDispatchToProps,
} from '../../redux/modules/postFlag/postFlag.containers';
import ModalPostShare from '../../components/ModalPostShare/ModalPostShare';
import { isCandidate, isCitizen } from '../../utilities/authorization';
import isPrytanyVerified from '../../utilities/isPrytanyVerified';

type Props = AccountDetailsMapStateToProps &
  AccountDetailsMapDispatchToProps &
  PostDetailsMapDispatchToProps &
  PostDetailsMapStateToProps &
  PostLikeMapDispatchToProps &
  PostLikeMapStateToProps &
  PostRemoveMapStateToProps &
  PostRemoveMapDispatchToProps &
  PostFlagMapStateToProps &
  PostFlagMapDispatchToProps & {
    match: any,
    location: any,
    history: any,
    postCommentsData: { post_comments: PostComment[] },
    postCommentsError: any,
    postCommentsIsLoading: boolean,
    postCommentAddData: { comment: PostComment },
    postCommentAddError: any,
    postCommentAddIsLoading: boolean,
    getCommentsForPost: Function,
    postCommentsReset: Function,
    postCommentAddReset: Function,
    createPostComment: Function,
  };

type State = {
  commentInputRefAvailable: boolean,
  isShareModalOpen: boolean,
};

class PostPage extends React.Component<Props, State> {
  commentInputRef: any = null;

  constructor(props: Props) {
    super(props);

    this.state = {
      commentInputRefAvailable: false,
      isShareModalOpen: false,
    };

    const postId = parseInt(this.props.match.params.postId);
    // TODO: this request should probably be executed on the server side before rendering this view
    this.props.postDetailsReset();
    this.props.postCommentsReset();
    this.props.postCommentAddReset();
    this.props.flagPostReset();
    this.props.getDetailsForPost({ postId });
    this.props.getCommentsForPost({ postId });
    message.loading('Loading post...', 5);
  }

  componentDidUpdate(prevProps: Props, prevState: State) {
    if (this.props.postDetailsError) {
      if (this.props.postDetailsError.code === 'NOT_FOUND') {
        // TODO: redirect?
        message.error('The post you are looking for does not exists.', 5);
      } else {
        message.error(this.props.postDetailsError.localMessage, 5);
      }
    } else if (this.props.postDetailsData) {
      // TODO: evaluate if user has access to this page
    }

    if (this.props.postDetailsData) {
      if (
        this.state.commentInputRefAvailable &&
        !prevState.commentInputRefAvailable
      ) {
        const qs = queryString.parse(this.props.location.search);

        if (typeof window !== 'undefined' && qs.focusMessageInput) {
          this.commentInputRef.focus();
        }
      }
    }

    if (!prevProps.postRemoveData && this.props.postRemoveData) {
      message.success(
        this.props.postRemoveData.localMessage || 'Post successfully deleted.',
      );
      this.props.postRemoveReset();
      this.props.history.push(URL_FEED_PAGE);
    }

    if (!prevProps.postFlagData && this.props.postFlagData) {
      message.success(
        this.props.postFlagData.localMessage ||
          'Post successfully flagged as inappropriate.',
      );
      this.props.flagPostReset();
    }

    if (!prevProps.postFlagError && this.props.postFlagError) {
      message.error(
        this.props.postFlagError.localMessage ||
          'Something went wrong. Please try again later.',
      );
      this.props.flagPostReset();
    }
  }

  render() {
    return (
      <section className="post-page content">
        <Loading loading={this.props.postDetailsIsLoading} type="spinner">
          {this.renderContent()}
        </Loading>
      </section>
    );
  }

  renderContent() {
    const post = _get(this.props, 'postDetailsData.post');
    const isOwner =
      _get(this.props, 'postDetailsData.post.owner.id') ===
      _get(this.props, 'accountData.user.id');

    return post ? (
      <section className="post-page__content">
        <ModalPostShare
          isOpen={this.state.isShareModalOpen}
          onCloseRequest={this.toggleShareModalVisibility}
          postId={post.id}
        />
        <div className="container-fluid post-page__background-image">
          <Link
            className="ml-1 post-page__back-button"
            href={URL_FEED_PAGE}
            type="dark">
            <img src="/images/back-violet.png" alt="" />
            <span style={{ color: 'darkviolet' }}>Back</span>
          </Link>
        </div>
        <div className="container-fluid post-page__info">
          <div className="row fix-width">
            <div className="col-12 post-page__frame">
              {post.title && (
                <div className="row">
                  <div className="col-12 col-md-11 col-lg-9">
                    <h1>{post.title}</h1>
                  </div>
                </div>
              )}
              <div className="row pt-3 pt-md-5">
                {this.renderUser()}
                <div className="col-12 separator d-block d-md-none mb-4" />
                <div className="col-12 col-md-8 col-lg-9 post-page__article">
                  <div className="row">
                    <div className="col-11">
                      {post.post_image && (
                        <img
                          className="post-page__image"
                          src={post.post_image}
                          alt=""
                        />
                      )}
                      <p className="post-page__description">
                        <Linkify
                          component={Link}
                          properties={{ isExternal: true }}>
                          {post.description}
                        </Linkify>
                      </p>
                      {this.renderCounters()}
                      <div className="col-12 separator d-none d-md-block mt-4" />
                      <div className="col-12 post-page__comments">
                        <div className="row post-page__comments-send mt-5">
                          <div className="col-8 col-md-10 pl-0">
                            <Input
                              name="postMessage"
                              type="text"
                              size="small"
                              useFormik={false}
                              placeholder="Write a comment"
                              addonButtonContent="Send"
                              addonButtonResetInputOnClick
                              addonButtonProps={{
                                onClick: this.handleSendPostCommentButtonClick,
                                loading: this.props.postCommentAddIsLoading,
                              }}
                              ref={this.setCommentInputRef}
                            />
                          </div>
                          <div className="col-md-10 col-12">
                            {this.renderComments()}
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="col-1">
                      {isOwner ? (
                        <Popconfirm
                          title="Are you sure you want to delete your post?"
                          onConfirm={this.handleDeletePostClick}
                          okText="Yes"
                          cancelText="No">
                          <Button
                            buttonType="link"
                            type="button"
                            size="small"
                            loading={this.props.postRemoveIsLoading}
                            className="post__delete-button">
                            <img
                              src="/images/icon_delete.svg"
                              alt=""
                              className="img-fluid"
                            />
                          </Button>
                        </Popconfirm>
                      ) : (
                        <Popconfirm
                          title="Are you sure you want to flag this post?"
                          onConfirm={this.handleFlagPostClick}
                          okText="Yes"
                          cancelText="No">
                          <Button
                            buttonType="link"
                            type="button"
                            size="small"
                            loading={this.props.postFlagIsLoading}
                            className="post__delete-button">
                            <img
                              src="/images/icon_flag.png"
                              alt=""
                              className="img-fluid"
                            />
                          </Button>
                        </Popconfirm>
                      )}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    ) : null;
  }

  renderUser() {
    const post = this.props.postDetailsData.post;

    return (
      <div className="col-12 col-md-3 px-0 px-lg-3 post-page__user">
        <div className="col-12 post-page__user-name">
          <div className="row">
            <div className="col-2 col-sm-1 col-md-3">
              <Avatar
                type="tiny"
                source={post.owner.profile_image}
                isCandidate={isCandidate(post.owner)}
                verifiedLevel={post.owner.verified_level}
                verifiedDetail={post.owner.verified_detail}
              />
            </div>
            <div className="col-9 col-sm-9 col-md-8 pl-2 pl-sm-2 pt-0 pr-0">
              <span className="pl-0 pl-md-2">
                {`${post.owner.first_name} ${post.owner.last_name}`.trim()}
              </span>
              <br />
              <span className="post-page__user-date pl-0 pl-md-2 pt-0">
                {moment(post.created_at)
                  .format('MMM')
                  .toUpperCase()}
                &nbsp;
                {moment(post.created_at).format('Do, HH:mm A')}
              </span>
            </div>
          </div>
        </div>
      </div>
    );
  }

  renderCounters() {
    const post = this.props.postDetailsData.post;
    return (
      <div className="col-12 post-page__counters px-0 pt-5">
        <div className="row justify-content-center">
          <Button
            className="post-page__counter-button col-4 px-0"
            block={false}
            buttonType="light"
            size="small"
            loading={this.props.postLikeIsLoading}
            noBorder={true}
            onClick={
              post.has_liked
                ? this.handleUnlikePostClick
                : this.handleLikePostClick
            }
            spinnerProps={{ type: 'dark' }}>
            <img
              src={`/images/${
                post.has_liked ? 'icon_heart_solid.svg' : 'icon_heart.png'
              }`}
              alt=""
            />
            <span>{post.like_count}</span>
          </Button>
          <Button
            className="post-page__counter-button col-4 px-0"
            block={false}
            buttonType="light"
            size="small"
            noBorder={true}
            onClick={this.handlePostMessageButtonClick}>
            <img src="/images/icon_msg.png" alt="" />
            <span>{post.comment_count}</span>
          </Button>
          <Button
            className="post-page__counter-button col-4 px-0"
            block={false}
            buttonType="light"
            size="small"
            noBorder={true}
            onClick={this.toggleShareModalVisibility}>
            <img src="/images/icon_share.png" alt="" />
            <span>{post.share_count}</span>
          </Button>
        </div>
      </div>
    );
  }

  renderComments() {
    const comments =
      (this.props.postCommentsData &&
        this.props.postCommentsData.post_comments) ||
      [];

    return comments.map((comment: PostComment) => (
      <PostCommentComponent key={comment.id} comment={comment} />
    ));
  }

  setCommentInputRef = (ref: any) => {
    if (ref) {
      this.commentInputRef = ref;
      setTimeout(() => {
        this.setState(() => ({ commentInputRefAvailable: true }));
      }, 500);
    }
  };

  handleSendPostCommentButtonClick = ({
    value,
  }: {
    name: string,
    value: string,
  }) => {
    this.props.createPostComment({
      comment: value,
      postId: this.props.postDetailsData.post.id,
    });
  };

  handleLikePostClick = () => {
    this.props.postLike({ postId: this.props.postDetailsData.post.id });
  };

  handleUnlikePostClick = () => {
    this.props.postUnlike({ postId: this.props.postDetailsData.post.id });
  };

  handlePostMessageButtonClick = () => {
    this.commentInputRef.focus();
  };

  toggleShareModalVisibility = () => {
    this.setState((prevState: State) => ({
      isShareModalOpen: !prevState.isShareModalOpen,
    }));
  };

  handleDeletePostClick = () => {
    this.props.deletePost({ postId: this.props.postDetailsData.post.id });
    this.props.postDetailsReset();
  };

  handleFlagPostClick = () => {
    this.props.flagPost({ postId: this.props.postDetailsData.post.id });
  };
}

const mapStateToProps = (state, ownProps) => {
  return {
    ...account.mapStateToProps(state),
    ...accountDetails.mapStateToProps(state),
    ...postDetails.mapStateToProps(state),
    ...postCommentAdd.mapStateToProps(state),
    ...postComments.mapStateToProps(state),
    ...postLike.mapStateToProps(state),
    ...postRemove.mapStateToProps(state),
    ...postFlag.mapStateToProps(state),
    ...ownProps,
  };
};

const mapDispatchToProps = dispatch => {
  return {
    ...accountDetails.mapDispatchToProps(dispatch),
    ...postDetails.mapDispatchToProps(dispatch),
    ...postCommentAdd.mapDispatchToProps(dispatch),
    ...postComments.mapDispatchToProps(dispatch),
    ...postLike.mapDispatchToProps(dispatch),
    ...postRemove.mapDispatchToProps(dispatch),
    ...postFlag.mapDispatchToProps(dispatch),
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(PostPage);
