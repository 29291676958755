//@flow
import * as React from 'react';

import './EditCollaboratorsTab.scss';
import AddCollaboratorsForm from '../AddCollaboratorsForm/AddCollaboratorsForm';
import CollaboratorsList from '../CollaboratorsList/CollaboratorsList';
import type {
  mapStateToProps as collaboratorsMapStateToProps,
  mapDispatchToProps as collaboratorsMapDispatchToProps,
} from '../../redux/modules/collaborators/collaborators.containers';
import type {
  mapStateToProps as collaboratorsDeleteMapStateToProps,
  mapDispatchToProps as collaboratorsDeleteMapDispatchToProps,
} from '../../redux/modules/collaboratorsDelete/collaboratorsDelete.containers';
import type {
  mapStateToProps as collaboratorsInviteMapStateToProps,
  mapDispatchToProps as collaboratorsInviteMapDispatchToProps,
} from '../../redux/modules/collaboratorsInvite/collaboratorsInvite.containers';

type Props = collaboratorsMapStateToProps &
  collaboratorsMapDispatchToProps &
  collaboratorsDeleteMapStateToProps &
  collaboratorsDeleteMapDispatchToProps &
  collaboratorsInviteMapStateToProps &
  collaboratorsInviteMapDispatchToProps;

class EditCollaboratorsTab extends React.Component<Props, {}> {
  constructor(props: Props) {
    super(props);
    (this: any).handleFormSubmit = this.handleFormSubmit.bind(this);
  }

  render() {
    return (
      <div className="edit-collaborators row fix-width no-gutters">
        <div className="col-12 col-md-4 sidebar">
          <AddCollaboratorsForm
            onSubmit={this.handleFormSubmit}
            isLoading={this.props.collaboratorsInviteIsLoading}
          />
        </div>
        <div className="col-12 col-md-8 edit-collaborators__list">
          <CollaboratorsList
            collaboratorsListData={this.props.collaboratorsListData}
            listIsLoading={this.props.collaboratorsListIsLoading}
          />
        </div>
      </div>
    );
  }

  handleFormSubmit(formData: any) {
    this.props.collaboratorsInvite(formData);
  }
}

export default EditCollaboratorsTab;
