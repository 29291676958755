// @flow
import { createAction } from 'redux-actions';
import {
  USER_LIST_ERROR,
  USER_LIST_LOADING,
  USER_LIST_RESET,
  USER_LIST_SUCCESS,
} from './userList.constants';
import { instance as rorUsersApiProvider } from '../../../services/RoRUsersApiProvider';
import type { GetUserListPayload } from '../../../services/RoRUsersApiProvider';

export const userListError = createAction(USER_LIST_ERROR);

export const userListLoading = createAction(USER_LIST_LOADING);

export const userListSuccess = createAction(USER_LIST_SUCCESS);

export const userListReset = createAction(USER_LIST_RESET);

export const getUserList = (payload: GetUserListPayload) => {
  return async (dispatch: Function) => {
    try {
      dispatch(userListLoading());

      const response = await rorUsersApiProvider.userList(payload);

      dispatch(userListSuccess(response));
    } catch (error) {
      dispatch(userListError(error));
    }
  };
};
