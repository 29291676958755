// @flow
import React from 'react';
import * as yup from 'yup';
import { Formik, FormikComputedProps } from 'formik';
import { connect } from 'react-redux';
import Avatar from '../Avatar/Avatar';
import Button from '../Button/Button';
import Input from '../Input/Input';
import Toggle from '../Toggle/Toggle';
import account from '../../redux/modules/account/account.containers';
import accountDetails from '../../redux/modules/accountDetails/accountDetails.containers';
import type { AccountDetailsMapStateToProps } from '../../redux/modules/accountDetails/accountDetails.containers';
import type { UserDetails } from '../../services/RoRUsersApiProvider';
import withDisplayDimensions from '../../hoc/withDisplayDimensions';
import type { WithDisplayDimensionsOutputProps } from '../../hoc/withDisplayDimensions';
import { CONTRIBUTE_MIN_AMOUNT } from '../ContributePayment/ContributePayment';
import ContributePaymentMethodSelector from '../ContributePaymentMethodSelector/ContributePaymentMethodSelector';
import contributionDirectDonationMake from '../../redux/modules/contributionDirectDonationMake/contributionDirectDonationMake.containers';
import type {
  ContributionDirectDonationMakeDispatchToProps,
  ContributionDirectDonationMakeMapStateToProps,
} from '../../redux/modules/contributionDirectDonationMake/contributionDirectDonationMake.containers';
import './ContributeDirect.scss';
import { message } from 'antd';
import {
  CONTRIBUTION_CREDIT_CARD_EXTRA_CHARGE,
  CONTRIBUTION_ACH_FREE,
} from '../../constants/forms';
import { receivingContributionLimit } from '../../utilities/authorization';
import formatCurrency from '../../utilities/formatCurrency';

type Props = AccountDetailsMapStateToProps &
  WithDisplayDimensionsOutputProps &
  ContributionDirectDonationMakeMapStateToProps &
  ContributionDirectDonationMakeDispatchToProps & {
    userDetails: UserDetails,
    onPaymentRequest: Function,
    onGoBackRequest: Function,
    onAddNewBankAccountRequest?: Function,
    loadStoredPaymentMethods?: boolean,
    onPaymentSuccess?: (paymentData: any) => void,
    customPaymentToken?: string,
    customPaymentEmail?: string,
    groupId?: number,
  };

class ContributeDirect extends React.Component<Props, {}> {
  formikForm: any = null;

  constructor(props: Props) {
    super(props);

    this.props.contributionDirectDonationMakeReset();
  }

  componentDidUpdate(prevProps: Props) {
    if (
      !prevProps.contributionDirectDonationMakeData &&
      this.props.contributionDirectDonationMakeData
    ) {
      message.success(
        <div
          dangerouslySetInnerHTML={{
            __html: this.props.contributionDirectDonationMakeData.message,
          }}
        />,
        5,
      );

      if (this.props.onPaymentSuccess) {
        this.props.onPaymentSuccess({
          ...this.props.contributionDirectDonationMakeData,
          type: 'direct',
        });
      }
    }

    if (
      !prevProps.contributionDirectDonationMakeError &&
      this.props.contributionDirectDonationMakeError
    ) {
      message.error(
        this.props.contributionDirectDonationMakeError.localMessage ||
          'An error has occurred and your contribution has been canceled',
        5,
      );
    }
  }

  render() {
    return (
      <div className="contribute-direct__content">
        <div className="contribute-direct__head">
          <div className="contribute-direct__button-change-wrapper">
            <Button
              className="contribute-direct__button-change"
              size="small"
              type="button"
              buttonType="link"
              onClick={this.props.onGoBackRequest}>
              &lt; Change
            </Button>
          </div>
          <h4 className="contribute-direct__title">Direct Contribution</h4>
          <div className="contribute-direct__head-transparent-block" />
        </div>
        <div className="contribute-direct__body">
          <Formik {...this.getFormikProps()}>
            {props => this.renderInnerForm(props)}
          </Formik>
        </div>
      </div>
    );
  }

  renderInnerForm(props: FormikComputedProps) {
    const { touched, errors, handleSubmit, values } = props;
    return (
      <form className="contribute-direct__form" onSubmit={handleSubmit}>
        <div className="row pb-3 pb-md-5 pt-5 justify-content-center fix-width">
          <div className="col-11 col-sm-6 col-lg-3 text-center px-md-0">
            <div className="row">
              <div className="col-12 contribute-direct__avatar-wrapper">
                <Avatar
                  source={this.props.userDetails.profile_image}
                  type="large"
                  isCandidate
                />
                <h5 className="pt-2">
                  {this.props.userDetails.first_name}{' '}
                  {this.props.userDetails.last_name}
                </h5>
              </div>
            </div>
            <div className="row justify-content-between pt-3">
              <div className="col-5 d-flex text-left align-items-center">
                <span className="contribute-direct__total-text">
                  <span>Total</span>{' '}
                  <span className="contribute-direct__total-text-sign">$</span>
                </span>
              </div>
              <div className="col-7 pl-0 d-flex align-items-center">
                <Input
                  className="mb-0"
                  label=""
                  placeholder=""
                  name="total"
                  type="number"
                  error={touched.total && errors.total ? errors.total : ''}
                  min={CONTRIBUTE_MIN_AMOUNT}
                  max={this.getMaxContribution()}
                />
              </div>
            </div>
            <div className="row">
              <div className="col-12 text-left">
                <p className="contribute-direct__label">
                  Pay ${parseFloat(values.total || 0).toFixed(2)} with:
                </p>
                <ContributePaymentMethodSelector
                  error={
                    touched.paymentMethod && errors.paymentMethod
                      ? errors.paymentMethod
                      : ''
                  }
                  customToken={this.props.customPaymentToken}
                  customEmail={this.props.customPaymentEmail}
                />
                <p className="contribute-direct__label--micro">
                  {CONTRIBUTION_CREDIT_CARD_EXTRA_CHARGE}{' '}
                  <span className="contribute-direct__label--micro-strong">
                    {CONTRIBUTION_ACH_FREE}
                  </span>
                </p>
              </div>
            </div>
            {this.props.onAddNewBankAccountRequest ? (
              <div className="row justify-content-center">
                <div className="col-10 pt-3">
                  <Button
                    type="button"
                    buttonType="link"
                    block
                    noBorder
                    onClick={this.props.onAddNewBankAccountRequest}
                    size="small"
                    noTextTransform={true}>
                    Add new bank account
                  </Button>
                </div>
              </div>
            ) : null}
          </div>
        </div>
        {this.renderFooter(props)}
      </form>
    );
  }

  renderFooter(props: FormikComputedProps) {
    const { touched, values, errors } = props;
    return (
      <div className="contribute-direct__footer">
        <div className="row pt-4 pb-2">
          <div className="col-12 col-md-7 pb-3">
            <div className="d-flex pt-3 pb-2 pl-3">
              <Toggle
                name="public"
                error={touched.public && errors.public ? errors.public : ''}
              />
              <p className="modal-toggle-label text-uppercase">Public</p>
            </div>
            <hr className="mt-1 d-block d-md-none" />
          </div>
          <div className="col-4 col-md-2 pr-1">
            <Button
              type="button"
              buttonType="outline"
              block
              noBorder
              onClick={this.props.onGoBackRequest}
              size={this.props.isMobile ? 'small' : 'normal'}>
              Cancel
            </Button>
          </div>
          <div className="col-8 col-md-3 pl-1">
            <Button
              type="submit"
              buttonType="primary"
              block
              noBorder
              size={this.props.isMobile ? 'small' : 'normal'}
              loading={this.props.contributionDirectDonationMakeIsLoading}>
              Contribute now
            </Button>
          </div>
        </div>
      </div>
    );
  }

  getMaxContribution = () => {
    return receivingContributionLimit(this.props.userDetails);
  };

  getFormikProps() {
    const maxContribution = this.getMaxContribution();
    return {
      initialValues: {
        total: '0',
        paymentMethod: 'newCard',
        public: '1',
      },
      validationSchema: yup.object().shape({
        total: yup
          .number()
          .min(1, 'You should contribute at least one Dollar.')
          .max(
            maxContribution,
            `You can contribute up to ${formatCurrency(maxContribution)}`,
          ),
        paymentMethod: yup.string().required('This field is required'),
      }),
      onSubmit: this.handleFormSubmit,
      ref: this.setFormikRef,
    };
  }

  setFormikRef = (ref: Formik) => {
    this.formikForm = ref;
  };

  handleFormSubmit = (values: any) => {
    if (values.paymentMethod === 'newCard') {
      // Call for payment component
      this.props.onPaymentRequest({
        formValues: values,
        typeOfContribution: 'direct',
        userDetails: this.props.userDetails,
      });
    } else {
      // Run transaction with stored source id
      let payload: any = {
        amount_in_cents: parseInt(parseFloat(values.total) * 100),
        candidate_id: this.props.userDetails.id,
        public: values.public === '1',
        stripe_source_id: values.paymentMethod,
      };

      if (this.props.groupId) {
        payload.group_id = this.props.groupId;
      }

      if (this.props.customPaymentEmail && this.props.customPaymentToken) {
        payload.customEmail = this.props.customPaymentEmail;
        payload.customToken = this.props.customPaymentToken;
      }

      this.props.makeDirectDonation(payload);
    }
  };
}

const mapStateToProps = (state, ownProps) => {
  return {
    ...account.mapStateToProps(state),
    ...accountDetails.mapStateToProps(state),
    ...contributionDirectDonationMake.mapStateToProps(state),
    ...ownProps,
  };
};

const mapDispatchToProps = dispatch => {
  return {
    ...contributionDirectDonationMake.mapDispatchToProps(dispatch),
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(withDisplayDimensions(ContributeDirect));
