// @flow
import React from 'react';
import { connect } from 'react-redux';
import accountProfile from '../../redux/modules/accountProfile/accountProfile.containers';
import ListWithAvatars from '../../components/ListWithAvatars/ListWithAvatars';
import type { AccountProfileMapStateToProps } from '../../redux/modules/accountProfile/accountProfile.containers';

type Props = AccountProfileMapStateToProps & {};

class DashboardAboutPage extends React.Component<Props> {
  render() {
    const profile = this.props.accountProfileData.user;

    return (
      <div className="dashboard-about-page container-fluid">
        <div className="row fix-width">
          <div className="col-md-3 px-0 dashboard-about-page__sidebar">
            <div className="row pb-4 pt-4 fix-width">
              <div className="col-12">
                <ListWithAvatars
                  title="Interests"
                  emptyListMessage=""
                  displayBottomSeparator={false}
                  items={profile.interests.map(interest => ({
                    id: interest.id,
                    name: interest.name,
                    href: '#',
                  }))}
                />
              </div>
            </div>
          </div>
          <div className="col-md-9 px-0 pl-md-5 px-3 pl-md-4 pr-md-5 dashboard-page__content">
            <h3 className="fs-16 pb-3 pt-4 mt-1">Bio</h3>
            <p>{profile.biography}</p>
          </div>
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state, ownProps) => {
  return {
    ...accountProfile.mapStateToProps(state),
    ...ownProps,
  };
};

export default connect(
  mapStateToProps,
  null,
)(DashboardAboutPage);
