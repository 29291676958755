// @flow
import { combineReducers } from 'redux';
import { reducer as accountReducer } from './modules/account/account.reducers';
import { reducer as accountCandidateCommitmentsReducer } from './modules/accountCandidateCommitments/accountCandidateCommitments.reducers';
import { reducer as accountCandidatePledgesReducer } from './modules/accountCandidatePledges/accountCandidatePledges.reducers';
import { reducer as accountCandidateDirectContributionsReducer } from './modules/accountCandidateDirectContributions/accountCandidateDirectContributions.reducers';
import { reducer as accountContendersReducer } from './modules/accountContenders/accountContenders.reducers';
import { reducer as accountDetailsReducer } from './modules/accountDetails/accountDetails.reducers';
import { reducer as accountFeedReducer } from './modules/accountFeed/accountFeed.reducers';
import { reducer as accountFollowersReducer } from './modules/accountFollowers/accountFollowers.reducers';
import { reducer as accountFollowingsReducer } from './modules/accountFollowings/accountFollowings.reducers';
import { reducer as accountGroupsReducer } from './modules/accountGroups/accountGroups.reducers';
import { reducer as accountPaymentMethodsReducer } from './modules/accountPaymentMethods/accountPaymentMethods.reducers';
import { reducer as accountInboxMessageListReducer } from './modules/accountInboxMessageList/accountInboxMessageList.reducers';
import { reducer as accountInboxMessageDetailsReducer } from './modules/accountInboxMessageDetails/accountInboxMessageDetails.reducers';
import { reducer as accountInboxMessageDeleteReducer } from './modules/accountInboxMessageDelete/accountInboxMessageDelete.reducers';
import { reducer as accountPostsReducer } from './modules/accountPosts/accountPosts.reducers';
import { reducer as accountProfileReducer } from './modules/accountProfile/accountProfile.reducers';
import { reducer as accountProfileUpdateReducer } from './modules/accountProfileUpdate/accountProfileUpdate.reducers';
import { reducer as accountRacesReducer } from './modules/accountRaces/accountRaces.reducers';
import { reducer as accountSsaFormDataReducer } from './modules/accountSsaFormData/accountSsaFormData.reducers';
import { reducer as accountVerifyReducer } from './modules/accountVerify/accountVerify.reducers';
import { reducer as candidatesReducer } from './modules/listCandidates/listCandidates.reducers';
import { reducer as collaboratorsReducer } from './modules/collaborators/collaborators.reducers';
import { reducer as collaboratorsDeleteReducer } from './modules/collaboratorsDelete/collaboratorsDelete.reducers';
import { reducer as collaboratorsInviteReducer } from './modules/collaboratorsInvite/collaboratorsInvite.reducers';
import { reducer as contactPrytanyMessageReducer } from './modules/contactPrytanyMessage/contactPrytanyMessage.reducers';
import { reducer as contributionAddNewAchReducer } from './modules/contributionAddNewAch/contributionAddNewAch.reducers';
import { reducer as contributionVerifyAchReducer } from './modules/contributionVerifyAch/contributionVerifyAch.reducers';
import { reducer as contributionDirectDonationMakeReducer } from './modules/contributionDirectDonationMake/contributionDirectDonationMake.reducers';
import { reducer as contributionCommittedDonationMakeReducer } from './modules/contributionCommittedDonationMake/contributionCommittedDonationMake.reducers';
import { reducer as contributionPledgeClaimReducer } from './modules/contributionPledgeClaim/contributionPledgeClaim.reducers';
import { reducer as contributionPledgeDonationMakeReducer } from './modules/contributionPledgeDonationMake/contributionPledgeDonationMake.reducers';
import { reducer as contributionCommittedDonationCalculateReducer } from './modules/contributionCommittedDonationCalculate/contributionCommittedDonationCalculate.reducers';
import { reducer as contributionDetailsReducer } from './modules/contributionDetails/contributionDetails.reducers';
import { reducer as groupDetailsReducer } from './modules/groupDetails/groupDetails.reducers';
import { reducer as groupDeleteReducer } from './modules/groupDelete/groupDelete.reducers';
import { reducer as groupMemberPendingRequestsReducer } from './modules/groupMemberPendingRequests/groupMemberPendingRequests.reducers';
import { reducer as groupAddModeratorsReducer } from './modules/groupAddModerators/groupAddModerators.reducers';
import { reducer as groupApproveRequestReducer } from './modules/groupApproveRequest/groupApproveRequest.reducers';
import { reducer as groupDenyRequestReducer } from './modules/groupDenyRequest/groupDenyRequest.reducers';
import { reducer as groupMembersReducer } from './modules/groupMembers/groupMembers.reducers';
import { reducer as groupMemberContributionsReducer } from './modules/groupMemberContributions/groupMemberContributions.reducers';
import { reducer as groupModeratorsReducer } from './modules/groupModerators/groupModerators.reducers';
import { reducer as groupPostsReducer } from './modules/groupPosts/groupPosts.reducers';
import { reducer as groupSendInvitationReducer } from './modules/groupSendInvitation/groupSendInvitation.reducers';
import { reducer as groupInviteByContactReducer } from './modules/groupInviteByContact/groupInviteByContact.reducers';
import { reducer as groupRemoveModeratorReducer } from './modules/groupRemoveModerator/groupRemoveModerator.reducers';
import { reducer as groupRenameReducer } from './modules/groupRename/groupRename.reducers';
import { reducer as groupRequestJoinReducer } from './modules/groupRequestJoin/groupRequestJoin.reducers';
import { reducer as groupLeaveReducer } from './modules/groupLeave/groupLeave.reducers';
import { reducer as groupRemoveMemberReducer } from './modules/groupRemoveMember/groupRemoveMember.reducers';
import { reducer as groupUpdateReducer } from './modules/groupUpdate/groupUpdate.reducers';
import { reducer as listInterestsReducer } from './modules/listInterests/listInterests.reducers';
import { reducer as listGroupsReducer } from './modules/listGroups/listGroups.reducers';
import { reducer as listRacesReducer } from './modules/listRaces/listRaces.reducers';
import { reducer as listRolesReducer } from './modules/listRoles/listRoles.reducers';
import { reducer as listStatesReducer } from './modules/listStates/listStates.reducers';
import { reducer as postAddReducer } from './modules/postAdd/postAdd.reducers';
import { reducer as postDetailsReducer } from './modules/postDetails/postDetails.reducers';
import { reducer as postCommentAddReducer } from './modules/postCommentAdd/postCommentAdd.reducers';
import { reducer as postCommentsReducer } from './modules/postComments/postComments.reducers';
import { reducer as postFlagReducer } from './modules/postFlag/postFlag.reducers';
import { reducer as postLikeReducer } from './modules/postLike/postLike.reducers';
import { reducer as postShareReducer } from './modules/postShare/postShare.reducers';
import { reducer as postRemoveReducer } from './modules/postRemove/postRemove.reducers';
import { reducer as raceDetailsReducer } from './modules/raceDetails/raceDetails.reducers';
import { reducer as raceFeedReducer } from './modules/raceFeed/raceFeed.reducers';
import { reducer as raceFollowReducer } from './modules/raceFollow/raceFollow.reducers';
import { reducer as raceGroupsReducer } from './modules/raceGroups/raceGroups.reducers';
import { reducer as raceThoughtAddReducer } from './modules/raceThoughtAdd/raceThoughtAdd.reducers';
import { reducer as raceUnfollowReducer } from './modules/raceUnfollow/raceUnfollow.reducers';
import { reducer as userCandidateSubInterestsReducer } from './modules/userCandidateSubInterests/userCandidateSubInterests.reducers';
import { reducer as userDetailsReducer } from './modules/userDetails/userDetails.reducers';
import { reducer as userFollowReducer } from './modules/userFollow/userFollow.reducers';
import { reducer as userProfileContributionsReducer } from './modules/userProfileContributions/userProfileContributions.reducers';
import { reducer as userProfileFeedReducer } from './modules/userProfileFeed/userProfileFeed.reducers';
import { reducer as userProfileGroupsReducer } from './modules/userProfileGroups/userProfileGroups.reducers';
import { reducer as userProfileFollowers } from './modules/userProfileFollowers/userProfileFollowers.reducers';
import { reducer as userProfileFollowing } from './modules/userProfileFollowing/userProfileFollowing.reducers';
import { reducer as userRssFeedReducer } from './modules/userRssFeed/userRssFeed.reducers';
import { reducer as searchReducer } from './modules/search/search.reducers';
import { reducer as searchGroupsReducer } from './modules/searchGroups/searchGroups.reducers';
import { reducer as userListReducer } from './modules/userList/userList.reducers';
import { reducer as userPostsReducer } from './modules/userPosts/userPosts.reducers';
import { reducer as signUpReducer } from './modules/signUp/signUp.reducers';
import { reducer as signUpOtpVerificationReducer } from './modules/signUpOtpVerification/signUpOtpVerification.reducers';
import { reducer as sendMessageReducer } from './modules/sendMessage/sendMessage.reducers';

export const makeRootReducer = (asyncReducers: any) => {
  return combineReducers({
    account: accountReducer,
    accountCandidateCommitments: accountCandidateCommitmentsReducer,
    accountCandidateDirectContributions: accountCandidateDirectContributionsReducer,
    accountCandidatePledges: accountCandidatePledgesReducer,
    accountContenders: accountContendersReducer,
    accountDetails: accountDetailsReducer,
    accountGroups: accountGroupsReducer,
    accountFeed: accountFeedReducer,
    accountFollowers: accountFollowersReducer,
    accountFollowings: accountFollowingsReducer,
    accountInboxMessageList: accountInboxMessageListReducer,
    accountInboxMessageDetails: accountInboxMessageDetailsReducer,
    accountInboxMessageDelete: accountInboxMessageDeleteReducer,
    accountPaymentMethods: accountPaymentMethodsReducer,
    accountPosts: accountPostsReducer,
    accountProfile: accountProfileReducer,
    accountProfileUpdate: accountProfileUpdateReducer,
    accountRaces: accountRacesReducer,
    accountSsaFormData: accountSsaFormDataReducer,
    accountVerify: accountVerifyReducer,
    candidates: candidatesReducer,
    collaborators: collaboratorsReducer,
    collaboratorsDelete: collaboratorsDeleteReducer,
    collaboratorsInvite: collaboratorsInviteReducer,
    contactPrytanyMessage: contactPrytanyMessageReducer,
    contributionCommittedDonationCalculate: contributionCommittedDonationCalculateReducer,
    contributionCommittedDonationMake: contributionCommittedDonationMakeReducer,
    contributionDirectDonationMake: contributionDirectDonationMakeReducer,
    contributionAddNewAch: contributionAddNewAchReducer,
    contributionVerifyAch: contributionVerifyAchReducer,
    contributionPledgeClaim: contributionPledgeClaimReducer,
    contributionPledgeDonationMake: contributionPledgeDonationMakeReducer,
    contributionDetails: contributionDetailsReducer,
    groupAddModerators: groupAddModeratorsReducer,
    groupDetails: groupDetailsReducer,
    groupDelete: groupDeleteReducer,
    groupMembers: groupMembersReducer,
    groupMemberContributions: groupMemberContributionsReducer,
    groupModerators: groupModeratorsReducer,
    groupMemberPendingRequests: groupMemberPendingRequestsReducer,
    groupApproveRequest: groupApproveRequestReducer,
    groupDenyRequest: groupDenyRequestReducer,
    groupPosts: groupPostsReducer,
    groupRemoveModerator: groupRemoveModeratorReducer,
    groupRename: groupRenameReducer,
    groupRequestJoin: groupRequestJoinReducer,
    groupSendInvitation: groupSendInvitationReducer,
    groupInviteByContact: groupInviteByContactReducer,
    groupLeave: groupLeaveReducer,
    groupRemoveMember: groupRemoveMemberReducer,
    groupUpdate: groupUpdateReducer,
    listInterests: listInterestsReducer,
    listGroups: listGroupsReducer,
    listRaces: listRacesReducer,
    listRoles: listRolesReducer,
    listStates: listStatesReducer,
    postAdd: postAddReducer,
    postCommentAdd: postCommentAddReducer,
    postComments: postCommentsReducer,
    postDetails: postDetailsReducer,
    postFlag: postFlagReducer,
    postShare: postShareReducer,
    postLike: postLikeReducer,
    postRemove: postRemoveReducer,
    raceDetails: raceDetailsReducer,
    raceFeed: raceFeedReducer,
    raceFollow: raceFollowReducer,
    raceGroups: raceGroupsReducer,
    raceThoughtAdd: raceThoughtAddReducer,
    raceUnfollow: raceUnfollowReducer,
    search: searchReducer,
    searchGroups: searchGroupsReducer,
    sendMessage: sendMessageReducer,
    signUp: signUpReducer,
    signUpOtpVerification: signUpOtpVerificationReducer,
    userCandidateSubInterests: userCandidateSubInterestsReducer,
    userDetails: userDetailsReducer,
    userFollow: userFollowReducer,
    userProfileContributions: userProfileContributionsReducer,
    userProfileFeed: userProfileFeedReducer,
    userProfileGroups: userProfileGroupsReducer,
    userProfileFollowers: userProfileFollowers,
    userProfileFollowing: userProfileFollowing,
    userRssFeed: userRssFeedReducer,
    userList: userListReducer,
    userPosts: userPostsReducer,
    ...asyncReducers,
  });
};

export const injectReducer = (store: any) => ({
  key,
  reducer,
}: {
  key: string,
  reducer: any,
}) => {
  if (Object.hasOwnProperty.call(store.asyncReducers, key)) return;

  store.asyncReducers[key] = reducer;
  store.replaceReducer(makeRootReducer(store.asyncReducers));
};

export default makeRootReducer;
