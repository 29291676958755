// @flow
import Axios, { AxiosResponse, AxiosError } from 'axios';
import _omit from 'lodash/omit';
import RoRApiResponseTransformer from '../utilities/RoRApiResponseTransformer';
import DefaultResponse from './RoRApiProvider';
import RoRApiProvider from './RoRApiProvider';
class RoRProfileApiProvider extends RoRApiProvider {
  constructor() {
    super();
  }

  getContributions(
    payload: GetContributionsPayload,
  ): Promise<GetContributionsResponse> {
    let url = `/apis/candidates/${payload.id}/contributions.json`;
    const requestConfig = {
      headers: {
        'Content-Type': 'application/json',
        ...this.getAuthenticationHeaders(),
      },
    };

    const extraParams = _omit(payload, 'id');
    const paramKeys = Object.keys(extraParams);

    if (paramKeys.length) {
      url = `/apis/candidates/${payload.id}/contributions?`;

      paramKeys.map((key, index) => {
        const param =
          index === paramKeys.length - 1
            ? `${key}=${extraParams[key]}`
            : `${key}=${extraParams[key]}&`;

        url = `${url}${param}`;
      });
    }

    return this.axios
      .get(url, requestConfig)
      .then((response: AxiosResponse) =>
        RoRApiResponseTransformer.parseResponse(response, true),
      )
      .catch((error: AxiosError) =>
        Promise.reject(RoRApiResponseTransformer.parseError(error)),
      );
  }

  getGroups(payload: GetGroupsPayload): Promise<GetGroupsResponse> {
    let url = `/apis/users/${payload.id}/groups`;
    const requestConfig = {
      headers: {
        'Content-Type': 'application/json',
        ...this.getAuthenticationHeaders(),
      },
    };

    const extraParams = _omit(payload, 'id');
    const paramKeys = Object.keys(extraParams);

    if (paramKeys.length) {
      url = `/apis/users/${payload.id}/groups?`;

      paramKeys.map((key, index) => {
        const param =
          index === paramKeys.length
            ? `${key}=${extraParams[key]}`
            : `${key}=${extraParams[key]}&`;

        url = `${url}${param}`;
      });
    }

    return this.axios
      .get(url, requestConfig)
      .then((response: AxiosResponse) =>
        RoRApiResponseTransformer.parseResponse(response),
      )
      .catch((error: AxiosError) =>
        Promise.reject(RoRApiResponseTransformer.parseError(error)),
      );
  }

  getFollowers(payload: GetFollowersPayload): Promise<GetFollowersResponse> {
    let url = `/apis/users/${payload.id}/followers`;
    const requestConfig = {
      headers: {
        'Content-Type': 'application/json',
        ...this.getAuthenticationHeaders(),
      },
    };

    const extraParams = _omit(payload, 'id');
    const paramKeys = Object.keys(extraParams);

    if (paramKeys.length) {
      url = `/apis/users/${payload.id}/followers?`;

      paramKeys.map((key, index) => {
        const param =
          index === paramKeys.length
            ? `${key}=${extraParams[key]}`
            : `${key}=${extraParams[key]}&`;

        url = `${url}${param}`;
      });
    }

    return this.axios
      .get(url, requestConfig)
      .then((response: AxiosResponse) =>
        RoRApiResponseTransformer.parseResponse(response),
      )
      .catch((error: AxiosError) =>
        Promise.reject(RoRApiResponseTransformer.parseError(error)),
      );
  }

  getFollowing(payload: GetFollowersPayload): Promise<GetFollowersResponse> {
    let url = `/apis/users/${payload.id}/followings`;
    const requestConfig = {
      headers: {
        'Content-Type': 'application/json',
        ...this.getAuthenticationHeaders(),
      },
    };

    const extraParams = _omit(payload, 'id');
    const paramKeys = Object.keys(extraParams);

    if (paramKeys.length) {
      url = `/apis/users/${payload.id}/followings?`;

      paramKeys.map((key, index) => {
        const param =
          index === paramKeys.length
            ? `${key}=${extraParams[key]}`
            : `${key}=${extraParams[key]}&`;

        url = `${url}${param}`;
      });
    }

    return this.axios
      .get(url, requestConfig)
      .then((response: AxiosResponse) =>
        RoRApiResponseTransformer.parseResponse(response),
      )
      .catch((error: AxiosError) =>
        Promise.reject(RoRApiResponseTransformer.parseError(error)),
      );
  }
}

export type GetContributionsPayload = {
  id: number,
  limit?: number,
  page?: number,
  sort_by?:
    | 'amount'
    | 'created_at'
    | 'contributor_name'
    | 'contributor_state_code',
  sort_order?: 'Asc' | 'Desc',
};

export type GetContributionsResponse = DefaultResponse & {
  contributed: {
    id: number,
    first_name: string,
    last_name: string,
    profile_image: string,
    is_public: boolean,
    role: {
      id: number,
      name: string,
      code: string,
      admin: boolean,
    },
    state: {
      id: number,
      name: string,
      code: string,
    }[],
  },
};

export type GetGroupsPayload = {
  id: number,
  invitee?: number,
  limit?: number,
  page?: number,
};

export type GetGroupsResponse = DefaultResponse & {
  groups: {
    id: number,
    name: string,
    code: string,
    group_image: string,
    current_user: {
      is_moderator: boolean,
      has_pending_requests: boolean,
    },
    invitee_user: {
      is_member: boolean,
      has_active_request: boolean,
      has_active_invitation: boolean,
      is_invitable: boolean,
    }[],
  },
};

export type GetFollowersPayload = {
  id: number,
  limit?: number,
  page?: number,
};

export type GetFollowersResponse = DefaultResponse & {
  followers: {
    id: number,
    first_name: string,
    last_name: string,
    profile_image: string,
    is_public: boolean,
    role: {
      id: number,
      name: string,
      code: string,
      admin: boolean,
    },
    state: {
      id: number,
      name: string,
      code: string,
    },
    shared_interests: number,
    donations: {
      contributed: number,
      committed: number,
      pledged: number,
    },
  }[],
};

export default RoRProfileApiProvider;
export const instance = new RoRProfileApiProvider();
