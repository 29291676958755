// @flow
import makeDefaultServerCallReducer from '../../utilities/makeDefaultServerCallReducer';
import {
  CONTRIBUTION_VERIFY_ACH_ERROR,
  CONTRIBUTION_VERIFY_ACH_LOADING,
  CONTRIBUTION_VERIFY_ACH_SUCCESS,
  CONTRIBUTION_VERIFY_ACH_RESET,
} from './contributionVerifyAch.constants';

const initialState = {
  isLoading: false,
  error: null,
  data: null,
};

export const reducer = makeDefaultServerCallReducer({
  loadingActions: [CONTRIBUTION_VERIFY_ACH_LOADING],
  successActions: [CONTRIBUTION_VERIFY_ACH_SUCCESS],
  errorActions: [CONTRIBUTION_VERIFY_ACH_ERROR],
  resetActions: [CONTRIBUTION_VERIFY_ACH_RESET],
  initialState,
});
