// @flow
import { createAction } from 'redux-actions';
import {
  CONTRIBUTION_DIRECT_DONATION_MAKE_ERROR,
  CONTRIBUTION_DIRECT_DONATION_MAKE_LOADING,
  CONTRIBUTION_DIRECT_DONATION_MAKE_RESET,
  CONTRIBUTION_DIRECT_DONATION_MAKE_SUCCESS,
} from './contributionDirectDonationMake.constants';
import { instance as rorContributionsApiProvider } from '../../../services/RoRContributionsApiProvider';
import type { PostMakeDirectDonationPayload } from '../../../services/RoRContributionsApiProvider';
import { userDetailsAddContribution } from '../userDetails/userDetails.actions';
import { groupDetailsAddContribution } from '../groupDetails/groupDetails.actions';

export const contributionDirectDonationMakeError = createAction(
  CONTRIBUTION_DIRECT_DONATION_MAKE_ERROR,
);

export const contributionDirectDonationMakeLoading = createAction(
  CONTRIBUTION_DIRECT_DONATION_MAKE_LOADING,
);

export const contributionDirectDonationMakeSuccess = createAction(
  CONTRIBUTION_DIRECT_DONATION_MAKE_SUCCESS,
);

export const contributionDirectDonationMakeReset = createAction(
  CONTRIBUTION_DIRECT_DONATION_MAKE_RESET,
);

export const makeDirectDonation = (payload: PostMakeDirectDonationPayload) => {
  return async (dispatch: Function) => {
    try {
      dispatch(contributionDirectDonationMakeLoading());

      const response = await rorContributionsApiProvider.makeDirectDonation(
        payload,
      );

      dispatch(contributionDirectDonationMakeSuccess(response));
      dispatch(
        userDetailsAddContribution({
          userId: payload.candidate_id,
          amountInCents: payload.amount_in_cents,
          contributionType: 'direct',
        }),
      );

      if (payload.group_id) {
        dispatch(
          groupDetailsAddContribution({
            groupId: payload.group_id,
            amountInCents: payload.amount_in_cents,
            contributionType: 'direct',
          }),
        );
      }
    } catch (error) {
      dispatch(contributionDirectDonationMakeError(error));
    }
  };
};
