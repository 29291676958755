// @flow
import { createAction } from 'redux-actions';
import {
  USER_PROFILE_GROUPS_ERROR,
  USER_PROFILE_GROUPS_LOADING,
  USER_PROFILE_GROUPS_RESET,
  USER_PROFILE_GROUPS_SUCCESS,
} from './userProfileGroups.constants';
import { instance as rorProfileApiProvider } from '../../../services/RoRProfileApiProvider';

export const userProfileGroupsError = createAction(USER_PROFILE_GROUPS_ERROR);

export const userProfileGroupsLoading = createAction(
  USER_PROFILE_GROUPS_LOADING,
);

export const userProfileGroupsSuccess = createAction(
  USER_PROFILE_GROUPS_SUCCESS,
);

export const userProfileGroupsReset = createAction(USER_PROFILE_GROUPS_RESET);

export const getUserProfileGroups = (payload: any) => {
  return async (dispatch: Function) => {
    try {
      dispatch(userProfileGroupsLoading());

      const response = await rorProfileApiProvider.getGroups(payload);

      dispatch(userProfileGroupsSuccess(response));
    } catch (error) {
      dispatch(userProfileGroupsError(error));
    }
  };
};
