// @flow
import React from 'react';
import { Formik, FormikComputedProps } from 'formik';
import { connect } from 'react-redux';
import { message } from 'antd';
import * as yup from 'yup';
import classNames from 'classnames';
import Modal from '../Modal/Modal';
import Input from '../Input/Input';
import Button from '../Button/Button';
import contributionVerifyAch from '../../redux/modules/contributionVerifyAch/contributionVerifyAch.containers';
import type {
  ContributionVerifyAchDispatchToProps,
  ContributionVerifyAchMapStateToProps,
} from '../../redux/modules/contributionVerifyAch/contributionVerifyAch.containers';
import './ModalVerifyAch.scss';

type Props = ContributionVerifyAchDispatchToProps &
  ContributionVerifyAchMapStateToProps & {
    isOpen: boolean,
    onAfterOpen?: Function,
    onCloseRequest?: Function,
    className?: string,
    achAccountNumber: string,
    stripeSourceId: string,
    onSuccess?: () => void,
    onError?: (error: any) => void,
  };

type State = {};

type FormValues = {
  amount1: number,
  amount2: number,
};

class ModalVerifyAch extends React.Component<Props, State> {
  constructor(props: Props) {
    super(props);

    this.props.contributionVerifyAchReset();
  }

  componentDidUpdate(prevProps: Props) {
    if (
      !prevProps.contributionVerifyAchData &&
      this.props.contributionVerifyAchData
    ) {
      message.success(
        'Your account was successfully verified and is ready to be used.',
        5,
      );

      if (this.props.onSuccess) {
        this.props.onSuccess();
      }

      this.props.contributionVerifyAchReset();
    }

    if (
      !prevProps.contributionVerifyAchError &&
      this.props.contributionVerifyAchError
    ) {
      message.error(
        this.props.contributionVerifyAchError.localMessage ||
          "We're unable to process your request, try again later",
        5,
      );

      if (this.props.onError) {
        this.props.onError(this.props.contributionVerifyAchError);
      }

      this.props.contributionVerifyAchReset();
    }
  }

  render() {
    const {
      isOpen,
      onAfterOpen,
      onCloseRequest,
      className,
      ...rest
    } = this.props;

    return (
      <Modal
        contentLabel="Verify ACH"
        isOpen={isOpen}
        onAfterOpen={onAfterOpen}
        onCloseRequest={onCloseRequest}
        size="micro"
        className={this.getClassName()}
        head={<h6>Verify ACH</h6>}
        {...rest}>
        {isOpen ? (
          <Formik {...this.getFormikProps()}>
            {props => this.renderInnerForm(props)}
          </Formik>
        ) : null}
      </Modal>
    );
  }

  renderInnerForm(props: FormikComputedProps) {
    const { touched, errors, handleSubmit, values } = props;

    return (
      <form className="modal-verify-ach__inner-form" onSubmit={handleSubmit}>
        <div className="row pb-3">
          <div className="col-12 text-center pb-2">
            <span className="modal-verify-ach__inner-form-title">
              Enter the exact amount for each microdeposit made by Stripe to
              your&nbsp;
              <strong>{this.props.achAccountNumber}</strong> account:
            </span>
          </div>
        </div>
        <div className="row">
          <div className="col-6 offset-3">
            <div className="modal-verify-ach__inner-form-input">
              <Input
                className="mb-4 "
                label=""
                placeholder=""
                name="amount1"
                type="number"
                size="small"
                error={touched.amount1 && errors.amount1 ? errors.amount1 : ''}
              />
            </div>
          </div>
        </div>
        <div className="row">
          <div className="col-6 offset-3">
            <div className="modal-verify-ach__inner-form-input">
              <Input
                className="mb-4"
                label=""
                placeholder=""
                name="amount2"
                type="number"
                size="small"
                error={touched.amount2 && errors.amount2 ? errors.amount2 : ''}
              />
            </div>
          </div>
        </div>
        <div className="row">
          <div className="col-12 text-center pb-2">
            <span className="modal-verify-ach__inner-form-disclaimer">
              You can confirm these amounts later by going into "Edit Profile >
              Payment methods". Until then you won't be able to use this account
              for payments.
            </span>
          </div>
        </div>
        <div className="row pt-3 modal-verify-ach__inner-form-actions-wrapper">
          <div className="col-4 offset-2">
            <Button
              size="small"
              className="modal-verify-ach__inner-form-later-button"
              type="button"
              buttonType="link"
              block
              disabled={this.props.contributionVerifyAchIsLoading}
              onClick={this.props.onCloseRequest}>
              LATER
            </Button>
          </div>
          <div className="modal-verify-ach__inner-form-actions-background" />
          <div className="col-4">
            <Button
              size="small"
              type="submit"
              disabled={!(values.amount1 && values.amount2)}
              loading={this.props.contributionVerifyAchIsLoading}
              buttonType="primary"
              block
              noBorder>
              VERIFY
            </Button>
          </div>
        </div>
      </form>
    );
  }

  getClassName() {
    return classNames({
      'modal-verify-ach': true,
      [this.props.className || '']: this.props.className,
    });
  }

  getFormikProps() {
    return {
      onSubmit: this.handleFormSubmit,
      validationSchema: yup.object().shape(this.getValidationSchema()),
    };
  }

  getValidationSchema() {
    let schema: any = {
      amount1: yup.string().required('This field is required'),
      amount2: yup.string().required('This field is required'),
    };

    return schema;
  }

  handleFormSubmit = (values: FormValues) => {
    this.props.verifyAch({
      stripe_source_id: this.props.stripeSourceId,
      amounts_to_verify: [values.amount1, values.amount2],
    });
  };
}

const mapStateToProps = (state, ownProps) => {
  return {
    ...contributionVerifyAch.mapStateToProps(state),
    ...ownProps,
  };
};

const mapDispatchToProps = dispatch => {
  return {
    ...contributionVerifyAch.mapDispatchToProps(dispatch),
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(ModalVerifyAch);
