// @flow
import * as React from 'react';
import { connect } from 'react-redux';
import { message } from 'antd';
import _get from 'lodash/get';
import ListWithAvatars from '../ListWithAvatars/ListWithAvatars';
import GroupList from '../GroupList/GroupList';
import Feed from '../Feed/Feed';
import LoadingSpinner from '../LoadingSpinner/LoadingSpinner';
import userProfileFeed from '../../redux/modules/userProfileFeed/userProfileFeed.containers';
import userRssFeed from '../../redux/modules/userRssFeed/userRssFeed.containers';
import { instance as rorUsersApiProvider } from '../../services/RoRUsersApiProvider';
import { URL_USER_PUBLIC_PROFILE } from '../../config/urls';
import type {
  UserProfileFeedMapDispatchToProps,
  UserProfileFeedMapStateToProps,
} from '../../redux/modules/userProfileFeed/userProfileFeed.containers';
import type { FeedItem, UserDetails } from '../../services/RoRUsersApiProvider';
import type {
  UserRssFeedMapDispatchToProps,
  UserRssFeedMapStateToProps,
} from '../../redux/modules/userRssFeed/userRssFeed.containers';
import './PublicProfileActivityTab.scss';
import {
  allowedGroupType,
  isCandidate,
  isCitizen,
} from '../../utilities/authorization';
import ModalContribute from '../ModalContribute/ModalContribute';
import type { ContributionTypeType } from '../ContributeOptions/ContributeOptions';
import ContributionType from '../../constants/ContributionType';
import isPrytanyVerified from '../../utilities/isPrytanyVerified';

// TODO: update followers/following logic
type Props = UserProfileFeedMapDispatchToProps &
  UserProfileFeedMapStateToProps &
  UserRssFeedMapStateToProps &
  UserRssFeedMapDispatchToProps & {
    followers: Array<any>,
    followings: Array<any>,
    groups: Array<any>,
    user: UserDetails,
  };

type State = {
  feedItemButtonClickedIndex: number | null,
  modalContributeProps: {
    userDetails: UserDetails,
    contributionType: ContributionTypeType,
  } | null,
};

const USER_PROFILE_FEED_PAGE_SIZE = 10;

class PublicProfileActivityTab extends React.Component<Props, State> {
  constructor(props: Props) {
    super(props);

    this.state = {
      feedItemButtonClickedIndex: null,
      modalContributeProps: null,
    };

    this.props.userProfileFeedReset();
    this.props.userRssFeedReset();
  }

  componentDidMount() {
    const { user } = this.props;
    this.props.getUserProfileFeed({
      userId: user.id,
      limit: USER_PROFILE_FEED_PAGE_SIZE,
    });

    if (isCandidate(user)) {
      this.props.getUserRssFeed({ userId: user.id });
    }
  }

  render() {
    return (
      <React.Fragment>
        {isCandidate(this.props.user)
          ? this.renderCandidate()
          : this.renderCitizen()}
        {this.state.modalContributeProps && (
          <ModalContribute
            isOpen={true}
            onCloseRequest={this.handleCloseAllModals}
            contributionType={this.state.modalContributeProps.contributionType}
            userDetails={this.state.modalContributeProps.userDetails}
            onGoBackRequest={this.handleCloseAllModals}
            onSuccess={this.handleCloseAllModals}
          />
        )}
      </React.Fragment>
    );
  }

  renderCandidate() {
    return (
      <div className="row fix-width public-profile-activity">
        <div className="col-md-9 px-0 pr-md-5">{this.renderFeed()}</div>
        <div className="col-md-3 px-3 sidebar">
          <div className="row d-none d-md-flex">
            <div className="col-12 px-0 sidebar-widget-list">
              <ListWithAvatars
                title="Followers"
                emptyListMessage="This user doesn't have any followers"
                items={this.props.followers.map(follower => ({
                  id: follower.id,
                  name: `${follower.first_name} ${follower.last_name}`.trim(),
                  href: URL_USER_PUBLIC_PROFILE.replace(':id', follower.id),
                  image: follower.profile_image,
                  badge: isCandidate(follower),
                  verifiedLevel: follower.verified_level,
                  verifiedDetail: follower.verified_detail,
                }))}
              />
            </div>
          </div>
        </div>
      </div>
    );
  }

  renderCitizen() {
    return (
      <div className="row fix-width public-profile-activity">
        <div className="col-md-3 px-3 sidebar d-none d-md-block">
          <div className="row group-list d-md-flex pt-5">
            <div className="col-12 sidebar-widget-list pl-2">
              <GroupList
                groupType={allowedGroupType(this.props.user)}
                groups={this.props.groups}
              />
            </div>
            <div className="col-12 sidebar-widget-list">
              <ListWithAvatars
                title="Followers"
                emptyListMessage="This user doesn't have any followers."
                items={this.props.followers.map(follower => ({
                  id: follower.id,
                  name: `${follower.first_name} ${follower.last_name}`.trim(),
                  href: URL_USER_PUBLIC_PROFILE.replace(':id', follower.id),
                  image: follower.profile_image,
                  badge: isCandidate(follower),
                  verifiedLevel: follower.verified_level,
                  verifiedDetail: follower.verified_detail,
                }))}
              />
            </div>
          </div>
        </div>
        <div className="col-md-9 px-0 pl-md-5">{this.renderFeed()}</div>
      </div>
    );
  }

  renderFeed() {
    const prytanyFeed = _get(this.props, 'userProfileFeedData.data.feeds', []);
    const rssFeedData = _get(this.props, 'userRssFeedData.feed.entry');
    let rssFeed = [];

    if (rssFeedData) {
      rssFeed = (Array.isArray(rssFeedData) ? rssFeedData : [rssFeedData]).map(
        rssFeedItem => {
          return {
            type: 'rss',
            created_at: rssFeedItem.published,
            details: {
              rss: {
                title: rssFeedItem.title,
                description: rssFeedItem.content,
                link: rssFeedItem.link.href,
              },
            },
            owner: {
              role: {},
            },
          };
        },
      );
    }
    let feedItems = [];

    if (prytanyFeed.length) {
      feedItems = prytanyFeed;
    } else if (rssFeed.length) {
      feedItems = rssFeed;
    }

    return (
      <React.Fragment>
        <Feed
          feedItems={feedItems}
          onScrollEndReached={this.handleFeedScrollEndReached}
          onFeedItemButtonClick={(feedItem, index) =>
            this.handleFeedItemButtonClick(feedItem, index)
          }
          activeFeedItemIndex={this.state.feedItemButtonClickedIndex}
          className="px-4"
        />
        <div className="d-flex justify-content-center">
          <LoadingSpinner
            type="dark"
            visible={this.props.userProfileFeedIsLoading}
          />
        </div>
      </React.Fragment>
    );
  }

  handleFeedItemButtonClick = (feedItem: FeedItem, index: number) => {
    let contribution;
    let contributionType;

    if (feedItem.type === 'contribution') {
      contribution = feedItem.details.donation;
      contributionType = ContributionType.DIRECT;
    }

    if (feedItem.type === 'commitment') {
      contribution = feedItem.details.committed_donation;
      contributionType = ContributionType.COMMITTED;
    }

    if (feedItem.type === 'pledge') {
      contribution = feedItem.details.pledge;
      contributionType = ContributionType.PLEDGE;
    }

    // We need to handle this with an internal state, since the user details reducer is already in use in this page.
    try {
      this.setState(
        () => ({ feedItemButtonClickedIndex: index }),
        async () => {
          const response = await rorUsersApiProvider.userDetails({
            userId: contribution.candidate.id,
          });

          this.setState(() => ({
            modalContributeProps: {
              userDetails: response.user,
              contributionType: contributionType,
            },
            //$FlowFixMe
          }));
        },
      );
    } catch (e) {
      this.setState(() => ({ feedItemButtonClickedIndex: index }));
      message.error("We're unable to process your request.");
    }
  };

  handleFeedScrollEndReached = () => {
    if (!this.props.userProfileFeedIsLoading) {
      this.props.getUserProfileFeedNextPage({
        limit: USER_PROFILE_FEED_PAGE_SIZE,
        userId: this.props.user.id,
      });
    }
  };

  handleCloseAllModals = () => {
    this.setState(() => ({
      feedItemButtonClickedIndex: null,
      modalContributeProps: null,
    }));
  };
}

const mapStateToProps = (state, ownProps) => {
  return {
    ...userProfileFeed.mapStateToProps(state),
    ...userRssFeed.mapStateToProps(state),
    ...ownProps,
  };
};

const mapDispatchToProps = dispatch => {
  return {
    ...userProfileFeed.mapDispatchToProps(dispatch),
    ...userRssFeed.mapDispatchToProps(dispatch),
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(PublicProfileActivityTab);
