// @flow
import { createAction } from 'redux-actions';
import {
  POST_LIKE_ERROR,
  POST_LIKE_LOADING,
  POST_LIKE_RESET,
  POST_LIKE_SUCCESS,
  POST_UNLIKE_ERROR,
  POST_UNLIKE_LOADING,
  POST_UNLIKE_RESET,
  POST_UNLIKE_SUCCESS,
} from './postLike.constants';
import { instance as rorPostsApiProvider } from '../../../services/RoRPostsApiProvider';
import type { LikePostPayload } from '../../../services/RoRPostsApiProvider';
import {
  accountPostLikePost,
  accountPostUnLikePost,
} from '../accountPosts/accountPosts.actions';
import {
  accountFeedLikePost,
  accountFeedUnlikePost,
} from '../accountFeed/accountFeed.actions';
import {
  groupPostsLikePost,
  groupPostsUnLikePost,
} from '../groupPosts/groupPosts.actions';
import {
  postDetailsLikePost,
  postDetailsUnlikePost,
} from '../postDetails/postDetails.actions';
import {
  raceFeedLikePost,
  raceFeedUnLikePost,
} from '../raceFeed/raceFeed.actions';
import {
  userPostsLikePost,
  userPostsUnLikePost,
} from '../userPosts/userPosts.actions';
import {
  userProfileFeedLikePost,
  userProfileFeedUnlikePost,
} from '../userProfileFeed/userProfileFeed.actions';

export const postLikeError = createAction(POST_LIKE_ERROR);

export const postLikeLoading = createAction(POST_LIKE_LOADING);

export const postLikeSuccess = createAction(POST_LIKE_SUCCESS);

export const postLikeReset = createAction(POST_LIKE_RESET);

export const postUnLikeError = createAction(POST_UNLIKE_ERROR);

export const postUnLikeLoading = createAction(POST_UNLIKE_LOADING);

export const postUnLikeSuccess = createAction(POST_UNLIKE_SUCCESS);

export const postUnLikeReset = createAction(POST_UNLIKE_RESET);

export const postLike = (payload: LikePostPayload) => {
  return async (dispatch: Function) => {
    try {
      let response = {};
      dispatch(postLikeLoading());

      if (payload.postId) {
        response = await rorPostsApiProvider.likePost(payload);
        dispatch(accountPostLikePost(payload));
        dispatch(accountFeedLikePost(payload));
        dispatch(groupPostsLikePost(payload));
        dispatch(raceFeedLikePost(payload));
        dispatch(userPostsLikePost(payload));
        dispatch(userProfileFeedLikePost(payload));
        dispatch(postDetailsLikePost(payload));
      } else {
        return dispatch(postLikeError(new Error('postId is required.')));
      }

      dispatch(postLikeSuccess(response));
    } catch (error) {
      dispatch(postLikeError(error));
    }
  };
};

export const postUnLike = (payload: LikePostPayload) => {
  return async (dispatch: Function) => {
    try {
      let response = {};
      dispatch(postUnLikeLoading());

      if (payload.postId) {
        response = await rorPostsApiProvider.unLikePost(payload);
        dispatch(accountPostUnLikePost(payload));
        dispatch(accountFeedUnlikePost(payload));
        dispatch(groupPostsUnLikePost(payload));
        dispatch(raceFeedUnLikePost(payload));
        dispatch(userPostsUnLikePost(payload));
        dispatch(userProfileFeedUnlikePost(payload));
        dispatch(postDetailsUnlikePost(payload));
      } else {
        return dispatch(postUnLikeError(new Error('postId is required.')));
      }

      dispatch(postUnLikeSuccess(response));
    } catch (error) {
      dispatch(postUnLikeError(error));
    }
  };
};
