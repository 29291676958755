// @flow
import { createAction } from 'redux-actions';
import {
  ACCOUNT_FOLLOWERS_ERROR,
  ACCOUNT_FOLLOWERS_LOADING,
  ACCOUNT_FOLLOWERS_RESET,
  ACCOUNT_FOLLOWERS_SUCCESS,
} from './accountFollowers.constants';
import { instance as rorApiProvider } from '../../../services/RoRApiProvider';

export const accountFollowersError = createAction(ACCOUNT_FOLLOWERS_ERROR);

export const accountFollowersLoading = createAction(ACCOUNT_FOLLOWERS_LOADING);

export const accountFollowersSuccess = createAction(ACCOUNT_FOLLOWERS_SUCCESS);

export const accountFollowersReset = createAction(ACCOUNT_FOLLOWERS_RESET);

export const getAccountFollowers = () => {
  return async (dispatch: Function) => {
    try {
      dispatch(accountFollowersLoading());

      const response = await rorApiProvider.accountFollowers();

      dispatch(accountFollowersSuccess(response));
    } catch (error) {
      dispatch(accountFollowersError(error));
    }
  };
};
