// @flow
import makeDefaultServerCallReducer from '../../utilities/makeDefaultServerCallReducer';
import {
  USER_FOLLOW_SUCCESS,
  USER_FOLLOW_RESET,
  USER_FOLLOW_LOADING,
  USER_FOLLOW_ERROR,
  USER_UNFOLLOW_SUCCESS,
  USER_UNFOLLOW_RESET,
  USER_UNFOLLOW_LOADING,
  USER_UNFOLLOW_ERROR,
} from './userFollow.constants';

const initialState = {
  isLoading: false,
  error: null,
  data: null,
};

export const reducer = makeDefaultServerCallReducer({
  loadingActions: [USER_FOLLOW_LOADING, USER_UNFOLLOW_LOADING],
  successActions: [USER_FOLLOW_SUCCESS, USER_UNFOLLOW_SUCCESS],
  errorActions: [USER_FOLLOW_ERROR, USER_UNFOLLOW_ERROR],
  resetActions: [USER_FOLLOW_RESET, USER_UNFOLLOW_RESET],
  initialState,
});
