// @flow
import { createAction } from 'redux-actions';
import moment from 'moment';
import {
  RACE_THOUGHT_ADD_ERROR,
  RACE_THOUGHT_ADD_LOADING,
  RACE_THOUGHT_ADD_RESET,
  RACE_THOUGHT_ADD_SUCCESS,
} from './raceThoughtAdd.constants';
import { instance as rorRacesApiProvider } from '../../../services/RoRRacesApiProvider';
import type { PostRaceThoughtPayload } from '../../../services/RoRRacesApiProvider';
import { raceFeedSetItemAsFirst } from '../raceFeed/raceFeed.actions';

export const raceThoughtAddError = createAction(RACE_THOUGHT_ADD_ERROR);

export const raceThoughtAddLoading = createAction(RACE_THOUGHT_ADD_LOADING);

export const raceThoughtAddSuccess = createAction(RACE_THOUGHT_ADD_SUCCESS);

export const raceThoughtAddReset = createAction(RACE_THOUGHT_ADD_RESET);

export const addRaceThought = (payload: PostRaceThoughtPayload) => {
  return async (dispatch: Function) => {
    try {
      dispatch(raceThoughtAddLoading());

      const response = await rorRacesApiProvider.newRaceThought(payload);
      const { thought } = response;

      dispatch(
        raceFeedSetItemAsFirst({
          type: 'new_race_thought',
          created_at: moment().format(),
          details: {
            race_thought: {
              id: thought.id,
              thought: thought.thought,
              race_id: thought.race.id,
            },
          },
          owner: thought.owner,
        }),
      );
      dispatch(raceThoughtAddSuccess(response));
    } catch (error) {
      dispatch(raceThoughtAddError(error));
    }
  };
};
