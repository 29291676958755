// @flow
import makeDefaultServerCallReducer from '../../utilities/makeDefaultServerCallReducer';
import {
  USER_LIST_ERROR,
  USER_LIST_LOADING,
  USER_LIST_SUCCESS,
  USER_LIST_RESET,
} from './userList.constants';

const initialState = {
  isLoading: false,
  error: null,
  data: null,
};

export const reducer = makeDefaultServerCallReducer({
  loadingActions: [USER_LIST_LOADING],
  successActions: [USER_LIST_SUCCESS],
  errorActions: [USER_LIST_ERROR],
  resetActions: [USER_LIST_RESET],
  initialState,
});
