// @flow
import React from 'react';
import { withRouter } from 'react-router-dom';
import createMarkup from '../../utilities/createMarkup';

type Props = {
  children: any,
  location: any,
  history: any,
};

const MobileContributionsNewACHSubmittedPage = ({ location }: Props) => {
  const { message } = location.state || { message: 'Default message' };
  return (
    <div className="mobile-contributions-direct-page">
      <div className="row fix-width">
        <div
          className="col-12 d-flex justify-content-center align-items-center flex-column"
          style={{ height: '100vh' }}>
          <h2
            className="f-green fs-16 py-5 px-3 text-center"
            dangerouslySetInnerHTML={createMarkup(message)}
          />
        </div>
      </div>
    </div>
  );
};

export default withRouter(MobileContributionsNewACHSubmittedPage);
