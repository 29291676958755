//@flow
import * as React from 'react';
import moment from 'moment';
import * as _omitBy from 'lodash/omitBy';
import * as _uniqBy from 'lodash/uniqBy';
import * as _filter from 'lodash/filter';
import * as _get from 'lodash/get';
import { connect } from 'react-redux';
import { message } from 'antd';
import { Formik, FormikComputedProps } from 'formik';
import * as yup from 'yup';
import Avatar from '../Avatar/Avatar';
import Badge from '../Badge/Badge';
import Button from '../Button/Button';
import Link from '../Link/Link';
import ListWithAvatars from '../ListWithAvatars/ListWithAvatars';
import ModalNewGroup from '../ModalNewGroup/ModalNewGroup';
import Input from '../Input/Input';
import RadioButtons from '../RadioButtons/RadioButtons';
import Toggle from '../Toggle/Toggle';
import Select from '../../components/Select/Select';
import SelectableBadges from '../SelectableBadges/SelectableBadges';
import account from '../../redux/modules/account/account.containers';
import accountDetails from '../../redux/modules/accountDetails/accountDetails.containers';
import accountGroups from '../../redux/modules/accountGroups/accountGroups.containers';
import accountPaymentMethods from '../../redux/modules/accountPaymentMethods/accountPaymentMethods.containers';
import accountProfile from '../../redux/modules/accountProfile/accountProfile.containers';
import accountProfileUpdate from '../../redux/modules/accountProfileUpdate/accountProfileUpdate.containers';
import listInterests from '../../redux/modules/listInterests/listInterests.containers';
import listStates from '../../redux/modules/listStates/listStates.containers';
import {
  URL_CONTRIBUTION_RULES,
  URL_GROUP_PAGE,
  URL_LOGIN_PAGE,
  URL_VERIFY_ACCOUNT_PAGE,
} from '../../config/urls';
import type { UserProfile } from '../../services/RoRUsersApiProvider';
import type { AccountProfileMapStateToProps } from '../../redux/modules/accountProfile/accountProfile.containers';
import type {
  AccountPaymentMethodsMapDispatchToProps,
  AccountPaymentMethodsMapStateToProps,
} from '../../redux/modules/accountPaymentMethods/accountPaymentMethods.containers';
import type { AccountDetailsMapStateToProps } from '../../redux/modules/accountDetails/accountDetails.containers';
import './EditProfileTab.scss';
import {
  PASSWORD_DESCRIPTIVE_TEXT,
  PASSWORD_REGEX,
} from '../../constants/forms';
import ModalContributeNewACH from '../ModalContributeNewACH/ModalContributeNewACH';
import AchBankList from '../AchBankList/AchBankList';
import CreditCardsList from '../CreditCardsList/CreditCardsList';
import ModalDeactivateAccount from '../ModalDeactivateAccount/ModalDeactivateAccount';
import {
  allowedGroupType,
  isCitizen,
  isPac,
  isPoliticalParty,
} from '../../utilities/authorization';
import pluralize from 'pluralize';
import capitalize from 'lodash/capitalize';
import upperCase from 'lodash/upperCase';
import isPrytanyVerified from '../../utilities/isPrytanyVerified';

type Props = AccountProfileMapStateToProps &
  AccountDetailsMapStateToProps &
  AccountPaymentMethodsMapStateToProps &
  AccountPaymentMethodsMapDispatchToProps & {
    accountProfileUpdateData: { user: UserProfile } | null,
    accountProfileUpdateError: any,
    accountProfileUpdateIsLoading: boolean,
    accountGroupsData: any,
    accountGroupsError: any,
    accountGroupsIsLoading: boolean,
    listStatesData: any,
    listStatesError: any,
    listStatesIsLoading: boolean,
    listInterestsData: {
      interests: { id: number, name: string }[],
      next_page: number | '',
    },
    listInterestsError: any,
    listInterestsIsLoading: boolean,
    accountLogout: Function,
    updateAccountProfile: Function,
    accountProfileUpdateReset: Function,
    getListInterests: Function,
    getListInterestsNextPage: Function,
    getListStates: Function,
    history: any,
  };

type State = {
  newGroupModalOpen: boolean,
  newAchModalOpen: boolean,
  editBasicInformation: boolean,
  editContactInformation: boolean,
  editEmployerInformation: boolean,
  editInterests: boolean,
  isChangingAccount: boolean,
  deactivateAccountModalOpen: boolean,
};

class EditProfileTab extends React.Component<Props, State> {
  constructor(props: Props) {
    super(props);

    this.state = {
      newAchModalOpen: false,
      newGroupModalOpen: false,
      editBasicInformation: false,
      editContactInformation: false,
      editEmployerInformation: false,
      editInterests: false,
      isChangingAccount: false,
      deactivateAccountModalOpen: false,
    };
  }

  componentWillMount() {
    this.props.getListInterests();
    this.props.accountPaymentMethodsList();
    this.props.getListStates();
  }

  componentDidUpdate(prevProps: Props, prevState: State) {
    if (
      !prevProps.accountProfileUpdateError &&
      this.props.accountProfileUpdateError
    ) {
      message.error(
        this.props.accountProfileUpdateError.localMessage ||
          'An error has occurred and your profile was not updated.',
      );
    }

    if (this.state.isChangingAccount && this.props.accountProfileUpdateData) {
      message.success(
        'Your profile was successfully updated. Since your account data has changed, you need to login again.',
        5,
      );
      this.props.accountLogout();
      this.props.history.push(URL_LOGIN_PAGE);
      return;
    }

    if (
      !prevProps.accountProfileUpdateData &&
      this.props.accountProfileUpdateData
    ) {
      this.setState(
        () => ({
          editBasicInformation: false,
          editContactInformation: false,
          editEmployerInformation: false,
          editInterests: false,
        }),
        () => this.props.accountProfileUpdateReset(),
      );
    }
  }

  render() {
    const { groups = [] } = this.props.accountGroupsData || {};
    const { user: profile } = this.props.accountProfileData || {};

    return profile ? (
      <div className="edit-profile-tab row fix-width no-gutters">
        <div className="col-md-4 col-lg-3 sidebar">
          <div className="row pic text-center ">
            <div className="col-12">
              <Avatar
                type="big"
                source={profile.profile_image}
                editable={true}
                onFileChange={this.handleProfileImageChange}
                verifiedLevel={profile.verified_level}
                verifiedDetail={profile.verified_detail}
              />
            </div>
          </div>
          <hr />
          <div className="px-0 py-0">
            <ListWithAvatars
              title={capitalize(pluralize(allowedGroupType(profile)))}
              emptyListMessage="You are not part of any group yet."
              buttonTopText={`CREATE NEW ${upperCase(
                allowedGroupType(profile),
              )}`}
              onButtonTopClick={this.toggleNewGroupModalVisibility}
              items={groups.map(group => ({
                id: group.id,
                name: group.name,
                href: URL_GROUP_PAGE.replace(':groupId', group.id),
                image: group.group_image,
                badge: false,
              }))}
            />
          </div>
        </div>
        <div className="col-md-8 col-lg-9 px-0 pl-md-5">
          <div className="row info-card">
            <Formik {...this.getBasicInformationFormikProps()}>
              {props => this.renderBasicInformationInnerForm(props)}
            </Formik>
          </div>

          <div className="row info-card">
            <Formik {...this.getContactInformationFormikProps()}>
              {props => this.renderContactInformationInnerForm(props)}
            </Formik>
          </div>

          {profile.role.code === 'citizen' ? (
            <div className="row info-card">
              <Formik {...this.getEmployerInformationFormikProps()}>
                {props => this.renderEmployerInformationInnerForm(props)}
              </Formik>
            </div>
          ) : null}
          <div className="row info-card">
            <div className="col-8 pb-4 px-0">
              <p className="cartitle">Payment methods</p>
            </div>
            <div className="col-12">
              <div className="row">
                <div className="col-12 col-md-4 px-0">
                  <span className="edit-profile-tab__subtitle">
                    ACH&nbsp;
                    <Button
                      buttonType="link"
                      type="button"
                      size="micro"
                      onClick={this.toggleNewAchModalVisibility}>
                      ADD NEW
                    </Button>
                  </span>
                  <AchBankList {...this.getAchBankListProps()} />
                </div>
                <div className="col-12 col-md-4 px-0 offset-md-4">
                  <span className="edit-profile-tab__subtitle">
                    Credit cards&nbsp;
                    {/* TODO: setup functionality to add a new credit card from here
                    <Button buttonType="link" type="button" size="micro" onClick={() => console.log('add new payment method')}>
                      ADD NEW
                    </Button>*/}
                  </span>
                  <CreditCardsList {...this.getCreditCardsListProps()} />
                </div>
              </div>
            </div>
          </div>
          <div className="row info-card">
            <Formik {...this.getInterestsFormikProps()}>
              {props => this.renderInterestsInnerForm(props)}
            </Formik>
          </div>
        </div>
        <ModalNewGroup
          preselectedUsers={[]}
          isOpen={this.state.newGroupModalOpen}
          onCloseRequest={this.toggleNewGroupModalVisibility}
          onSuccess={this.handleNewGroupSuccess}
          onError={this.handleNewGroupError}
        />
        <ModalContributeNewACH
          isOpen={this.state.newAchModalOpen}
          onCloseRequest={this.toggleNewAchModalVisibility}
          onSuccess={this.toggleNewAchModalVisibility}
        />
        {this.state.deactivateAccountModalOpen && (
          <ModalDeactivateAccount
            isOpen={this.state.deactivateAccountModalOpen}
            onCloseRequest={this.toggleDeactivateAccountModalVisibility}
          />
        )}
      </div>
    ) : null;
  }

  renderBasicInformationInnerForm(props: FormikComputedProps) {
    const { touched, errors, handleSubmit } = props;
    const profile = this.props.accountProfileData.user;

    const ssaVerified = _get(
      this.props,
      'accountDetailsData.user.ssa_verified',
    );

    if (isPoliticalParty(profile) || isPac(profile)) {
      return (
        <div className="col-12">
          <form className="row" onSubmit={handleSubmit}>
            <div className="col-8 pb-4 px-0">
              <p className="cartitle">Basic information</p>
            </div>
            <div className="col-4 pb-1 px-0 text-right">
              <Button
                className="edit-profile-tab__button"
                type="button"
                buttonType="outline"
                size="small"
                disabled={this.props.accountProfileUpdateIsLoading}
                onClick={this.toggleEditBasicInformation}>
                {this.state.editBasicInformation ? 'Cancel' : 'Edit'}
              </Button>
            </div>
            <div className="col-12 col-md-5 pb-4 px-0">
              <p className="title">
                {isPoliticalParty(profile) ? 'Party' : 'PAC'} name
              </p>
              {!this.state.editBasicInformation ? (
                <p>{profile.first_name}</p>
              ) : null}
              {this.state.editBasicInformation ? (
                <Input
                  className="col-10 px-0 pt-1"
                  size="small"
                  name="first_name"
                  type="text"
                  isDisabled={true}
                  error={
                    touched.first_name && errors.first_name
                      ? errors.first_name
                      : ''
                  }
                />
              ) : null}
            </div>
            <div className="col-12 col-md-5 pb-4 px-0">
              <p className="title">E-mail</p>
              {!this.state.editBasicInformation ? <p>{profile.email}</p> : null}
              {this.state.editBasicInformation ? (
                <Input
                  className="col-10 px-0 pt-1"
                  size="small"
                  name="email"
                  type="email"
                  error={touched.email && errors.email ? errors.email : ''}
                />
              ) : null}
            </div>
            <div className="col-6 col-md-4 pb-4 px-0">
              <p className="title">Password</p>
              {!this.state.editBasicInformation ? <p>*********</p> : null}
              {this.state.editBasicInformation ? (
                <React.Fragment>
                  <Input
                    className="col-10 px-0 pt-1"
                    size="small"
                    placeholder="Enter a new password"
                    name="password"
                    type="password"
                    error={
                      touched.password && errors.password ? errors.password : ''
                    }
                  />
                  <Input
                    className="col-md-10 px-0 pt-1"
                    size="small"
                    placeholder="Repeat password"
                    name="passwordConfirm"
                    type="password"
                    error={
                      touched.passwordConfirm && errors.passwordConfirm
                        ? errors.passwordConfirm
                        : ''
                    }
                  />
                </React.Fragment>
              ) : null}
            </div>
            {this.state.editBasicInformation ? (
              <div className="col-12 px-0 d-flex justify-content-end">
                <Button
                  className="edit-profile-tab__button"
                  type="submit"
                  buttonType="primary"
                  size="small"
                  loading={this.props.accountProfileUpdateIsLoading}>
                  Submit
                </Button>
              </div>
            ) : null}
          </form>
        </div>
      );
    } else {
      return (
        <div className="col-12">
          <form className="row" onSubmit={handleSubmit}>
            <div className="col-8 pb-4 px-0">
              <p className="cartitle">Basic information</p>
            </div>
            <div className="col-4 pb-1 px-0 text-right">
              <Button
                className="edit-profile-tab__button"
                type="button"
                buttonType="outline"
                size="small"
                disabled={this.props.accountProfileUpdateIsLoading}
                onClick={this.toggleEditBasicInformation}>
                {this.state.editBasicInformation ? 'Cancel' : 'Edit'}
              </Button>
            </div>
            <div className="col-6 col-md-5 pb-4 px-0">
              <p className="title">First name</p>
              {!this.state.editBasicInformation ? (
                <p>{profile.first_name}</p>
              ) : null}
              {this.state.editBasicInformation ? (
                <Input
                  className="col-10 px-0 pt-1"
                  size="small"
                  name="first_name"
                  type="text"
                  isDisabled={true}
                  error={
                    touched.first_name && errors.first_name
                      ? errors.first_name
                      : ''
                  }
                />
              ) : null}
            </div>
            <div className="col-6 col-md-5 pb-4 px-0">
              <p className="title">Last name</p>
              {!this.state.editBasicInformation ? (
                <p>{profile.last_name}</p>
              ) : null}
              {this.state.editBasicInformation ? (
                <Input
                  className="col-10 px-0 pt-1"
                  size="small"
                  name="last_name"
                  type="text"
                  isDisabled={true}
                  error={
                    touched.last_name && errors.last_name
                      ? errors.last_name
                      : ''
                  }
                />
              ) : null}
            </div>
            <div className="col-12 col-md-5 pb-4 px-0">
              <p className="title">E-mail</p>
              {!this.state.editBasicInformation ? <p>{profile.email}</p> : null}
              {this.state.editBasicInformation ? (
                <Input
                  className="col-10 px-0 pt-1"
                  size="small"
                  name="email"
                  type="email"
                  error={touched.email && errors.email ? errors.email : ''}
                />
              ) : null}
            </div>
            <div className="col-6 col-md-4 pb-4 px-0">
              <p className="title">Password</p>
              {!this.state.editBasicInformation ? <p>*********</p> : null}
              {this.state.editBasicInformation ? (
                <React.Fragment>
                  <Input
                    className="col-10 px-0 pt-1"
                    size="small"
                    placeholder="Enter a new password"
                    name="password"
                    type="password"
                    error={
                      touched.password && errors.password ? errors.password : ''
                    }
                  />
                  <Input
                    className="col-md-10 px-0 pt-1"
                    size="small"
                    placeholder="Repeat password"
                    name="passwordConfirm"
                    type="password"
                    error={
                      touched.passwordConfirm && errors.passwordConfirm
                        ? errors.passwordConfirm
                        : ''
                    }
                  />
                </React.Fragment>
              ) : null}
            </div>
            <div className="col-6 col-md-3 pb-4 px-0">
              <p className="title">Date of birth</p>
              {!this.state.editBasicInformation ? <p>{profile.dob}</p> : null}
              {this.state.editBasicInformation ? (
                <Input
                  size="small"
                  name="dob"
                  type="date"
                  error={touched.dob && errors.dob ? errors.dob : ''}
                />
              ) : null}
            </div>
            <div className="col-6 col-md-6 pb-4 px-0">
              <p className="title">Gender</p>
              {!this.state.editBasicInformation ? (
                <p>{profile.gender}</p>
              ) : null}
              {this.state.editBasicInformation ? (
                <RadioButtons
                  className="col"
                  items={[
                    { label: 'Male', value: 'm' },
                    {
                      label: 'Female',
                      value: 'f',
                    },
                    { label: 'Choose to not identify.', value: 'o' },
                  ]}
                  name="gender"
                  error={touched.gender && errors.gender ? errors.gender : ''}
                />
              ) : null}
              {ssaVerified === 'not_uploaded' || ssaVerified === 'pending' ? (
                <div className="row">
                  <div className="col-6 pt-5">
                    <Link href={URL_VERIFY_ACCOUNT_PAGE} className="btn-block">
                      Account Verification
                    </Link>
                  </div>
                </div>
              ) : null}
              <div className="row">
                <div className="col-6 pt-3">
                  <Link href={URL_CONTRIBUTION_RULES} className="btn-block">
                    Contribution Rules
                  </Link>
                </div>
              </div>
              <div className="row">
                <div className="col-6 pt-3">
                  <a
                    href="#"
                    className="link btn-block"
                    onClick={e => {
                      e.preventDefault();
                      this.toggleDeactivateAccountModalVisibility();
                    }}>
                    Deactivate Account
                  </a>
                </div>
              </div>
            </div>
            <div className="col-md-6 form-group">
              <div className="row">
                <div className="col-md-6">
                  <p className="mb-2">Public profile</p>
                  <Toggle
                    disabled={!this.state.editBasicInformation}
                    name="publicProfile"
                    error={
                      touched.publicProfile && errors.publicProfile
                        ? errors.publicProfile
                        : ''
                    }
                  />
                </div>
                <div className="col-md-6">
                  <p className="mb-2">Allow Followers</p>
                  <Toggle
                    disabled={!this.state.editBasicInformation}
                    name="allowFollowers"
                    error={
                      touched.allowFollowers && errors.allowFollowers
                        ? errors.allowFollowers
                        : ''
                    }
                  />
                </div>
                <p className="f-nunito fs13 pt-3">
                  Private Mode: Your profile will not be searchable by other
                  users.
                </p>
                <p className="f-nunito fs13 pt-3">
                  Allow Followers: Other users will be allowed to follow you.
                </p>
              </div>
            </div>
            {this.state.editBasicInformation ? (
              <div className="col-12 px-0 d-flex justify-content-end">
                <Button
                  className="edit-profile-tab__button"
                  type="submit"
                  buttonType="primary"
                  size="small"
                  loading={this.props.accountProfileUpdateIsLoading}>
                  Submit
                </Button>
              </div>
            ) : null}
          </form>
        </div>
      );
    }
  }

  renderContactInformationInnerForm(props: FormikComputedProps) {
    const { touched, errors, handleSubmit, values } = props;
    const profile = this.props.accountProfileData.user;
    const states = this.props.listStatesData
      ? this.props.listStatesData.states.map(state => ({
          label: state.name,
          value: state.id.toString(),
        }))
      : [];

    return (
      <div className="col-12">
        <form className="row" onSubmit={handleSubmit}>
          <div className="col-8 pb-4 px-0">
            <p className="cartitle">Contact information</p>
          </div>
          <div className="col-4 pb-1 text-right px-0">
            <Button
              className="edit-profile-tab__button"
              type="button"
              buttonType="outline"
              size="small"
              disabled={this.props.accountProfileUpdateIsLoading}
              onClick={this.toggleEditContactInformation}>
              {this.state.editContactInformation ? 'Cancel' : 'Edit'}
            </Button>
          </div>
          <div className="col-12 col-md-5 pb-4 px-0 pb-4 px-0">
            <p className="title">Address Line 1</p>
            {!this.state.editContactInformation ? (
              <p>{profile.address1}</p>
            ) : null}
            {this.state.editContactInformation ? (
              <Input
                className="col-10 px-0 pt-1"
                size="small"
                name="address1"
                type="text"
                error={
                  touched.address1 && errors.address1 ? errors.address1 : ''
                }
              />
            ) : null}
          </div>
          <div className="col-12 col-md-5 pb-4 px-0 pb-4 px-0">
            <p className="title">Address Line 2</p>
            {!this.state.editContactInformation ? (
              <p>{profile.address2}</p>
            ) : null}
            {this.state.editContactInformation ? (
              <Input
                className="col-10 px-0 pt-1"
                size="small"
                name="address2"
                type="text"
                error={
                  touched.address1 && errors.address2 ? errors.address2 : ''
                }
              />
            ) : null}
          </div>
          <div className="col-12 col-md-5 pb-4 px-0 pb-4 px-0">
            <p className="title">City</p>
            {!this.state.editContactInformation ? <p>{profile.city}</p> : null}
            {this.state.editContactInformation ? (
              <Input
                className="col-10 px-0 pt-1"
                size="small"
                name="city"
                type="text"
                error={touched.city && errors.city ? errors.city : ''}
              />
            ) : null}
          </div>
          <div className="col-12 col-md-5 pb-4 px-0 pb-4 px-0">
            <p className="title">State</p>
            {!this.state.editContactInformation && profile.state ? (
              <p>{profile.state.name}</p>
            ) : null}
            {this.state.editContactInformation ? (
              <Select
                label={''}
                name="state_id"
                options={states}
                error={
                  touched.state_id && errors.state_id ? errors.state_id : ''
                }
              />
            ) : null}
          </div>
          <div className="col-12 col-md-5 pb-4 px-0 pb-4 px-0">
            <p className="title">Zip</p>
            {!this.state.editContactInformation ? (
              <p>{profile.zip_code}</p>
            ) : null}
            {this.state.editContactInformation ? (
              <Input
                className="col-10 px-0 pt-1"
                size="small"
                name="zip_code"
                type="text"
                error={
                  touched.zip_code && errors.zip_code ? errors.zip_code : ''
                }
              />
            ) : null}
          </div>
          <div className="col-12 col-md-5 pb-4 px-0">
            <p className="title">Telephone number</p>
            {!this.state.editContactInformation ? (
              <p>{profile.telephone}</p>
            ) : null}
            {this.state.editContactInformation ? (
              <Input
                className="col-10 px-0 pt-1"
                size="small"
                name="telephone"
                type="text"
                error={
                  touched.telephone && errors.telephone ? errors.telephone : ''
                }
              />
            ) : null}
          </div>
          {this.state.editContactInformation ? (
            <div className="col-12 px-0 d-flex justify-content-end">
              <Button
                className="edit-profile-tab__button"
                type="submit"
                buttonType="primary"
                size="small"
                loading={this.props.accountProfileUpdateIsLoading}>
                Submit
              </Button>
            </div>
          ) : null}
        </form>
      </div>
    );
  }

  renderEmployerInformationInnerForm(props: FormikComputedProps) {
    const { touched, errors, handleSubmit } = props;
    const profile = this.props.accountProfileData.user;

    return (
      <div className="col-12">
        <form className="row" onSubmit={handleSubmit}>
          <div className="col-8 pb-4 px-0">
            <p className="cartitle">Employer information</p>
          </div>
          <div className="col-4 pb-1 text-right px-0">
            <Button
              className="edit-profile-tab__button"
              type="button"
              buttonType="outline"
              size="small"
              disabled={this.props.accountProfileUpdateIsLoading}
              onClick={this.toggleEditEmployerInformation}>
              {this.state.editEmployerInformation ? 'Cancel' : 'Edit'}
            </Button>
          </div>
          <div className="col-12 col-md-5 pb-4 px-0">
            <p className="title">Employer</p>
            {!this.state.editEmployerInformation ? (
              <p>{profile.employer}</p>
            ) : null}
            {this.state.editEmployerInformation ? (
              <Input
                className="col-10 px-0 pt-1"
                size="small"
                name="employer"
                type="text"
                error={
                  touched.employer && errors.employer ? errors.employer : ''
                }
              />
            ) : null}
          </div>
          <div className="col-12 col-md-5 pb-4 px-0">
            <p className="title">Currently employed as a lobbyist</p>
            {(!this.state.editEmployerInformation &&
              profile.is_lobbyist === true) ||
            profile.is_lobbyist === 1 ? (
              <p>Yes</p>
            ) : null}
            {(!this.state.editEmployerInformation &&
              profile.is_lobbyist === false) ||
            profile.is_lobbyist === '' ? (
              <p>No</p>
            ) : null}
            {this.state.editEmployerInformation ? (
              <div>
                <Toggle
                  name="is_lobbyist"
                  label="Employed as a lobbyist?"
                  error={
                    touched.is_lobbyist && errors.is_lobbyist
                      ? errors.is_lobbyist
                      : ''
                  }
                />
              </div>
            ) : null}
          </div>
          <div className="col-12 pb-4 px-0">
            <p className="title">Occupation</p>
            {!this.state.editEmployerInformation ? (
              <p>{profile.occupation}</p>
            ) : null}
            {this.state.editEmployerInformation ? (
              <Input
                className="col-10 px-0 pt-1"
                size="small"
                name="occupation"
                type="text"
                error={
                  touched.occupation && errors.occupation
                    ? errors.occupation
                    : ''
                }
              />
            ) : null}
          </div>
          {this.state.editEmployerInformation ? (
            <div className="col-12 px-0 d-flex justify-content-end">
              <Button
                className="edit-profile-tab__button"
                type="submit"
                buttonType="primary"
                size="small"
                loading={this.props.accountProfileUpdateIsLoading}>
                Submit
              </Button>
            </div>
          ) : null}
          <div className="col-12 px-0">
            <p>
              <i>
                Federal law requires us to obtain and report your occupation and
                the name of your employer.
              </i>
            </p>
          </div>
        </form>
      </div>
    );
  }

  renderInterestsInnerForm(props: FormikComputedProps) {
    const { touched, errors, handleSubmit } = props;
    const profile = this.props.accountProfileData.user;

    return (
      <div className="col-12">
        <form className="row" onSubmit={handleSubmit}>
          <div className="col-8 pb-4 px-0">
            <p className="cartitle">Interests</p>
          </div>
          <div className="col-4 pb-1 text-right px-0">
            <Button
              className="edit-profile-tab__button"
              type="button"
              buttonType="outline"
              size="small"
              disabled={this.props.accountProfileUpdateIsLoading}
              onClick={this.toggleEditInterests}>
              {this.state.editInterests ? 'Cancel' : 'Edit'}
            </Button>
          </div>
          <div className="col-12 pb-4 px-0">
            <div className="d-flex justify-content-left flex-wrap">
              {!this.state.editInterests
                ? profile.interests.map(interest => (
                    <Badge
                      href="#"
                      name={interest.name}
                      title={interest.name}
                      key={interest.id}
                    />
                  ))
                : null}
              {this.state.editInterests ? (
                <SelectableBadges
                  className="d-flex justify-content-lg-between justify-content-center flex-wrap"
                  items={this.getInterestItems()}
                  name="interests"
                  error={
                    touched.interests && errors.interests
                      ? errors.interests
                      : ''
                  }
                />
              ) : null}
            </div>
          </div>
          {this.state.editInterests ? (
            <div className="col-12 px-0 d-flex justify-content-end">
              {this.props.listInterestsData.next_page ? (
                <Button
                  className="edit-profile-tab__button"
                  type="button"
                  buttonType="link"
                  size="small"
                  onClick={this.handleLoadMoreInterests}
                  disabled={
                    this.props.accountProfileUpdateIsLoading ||
                    this.props.listInterestsIsLoading
                  }
                  loading={this.props.listInterestsIsLoading}>
                  Load more
                </Button>
              ) : null}
              <Button
                className="edit-profile-tab__button"
                type="submit"
                buttonType="primary"
                size="small"
                loading={
                  this.props.accountProfileUpdateIsLoading ||
                  this.props.listInterestsIsLoading
                }>
                Submit
              </Button>
            </div>
          ) : null}
        </form>
      </div>
    );
  }

  getAchBankListProps() {
    return {
      loading: this.props.accountPaymentMethodsIsLoading,
      sources: _filter(
        _get(this.props.accountPaymentMethodsListData, 'stripe_sources'),
        ['source_type', 'bank_account'],
      ),
    };
  }

  getCreditCardsListProps() {
    return {
      loading: this.props.accountPaymentMethodsIsLoading,
      sources: _filter(
        _get(this.props.accountPaymentMethodsListData, 'stripe_sources'),
        ['source_type', 'card'],
      ),
    };
  }

  getBasicInformationFormikProps() {
    const profile = this.props.accountProfileData.user;
    const dob = profile.dob
      .split('/')
      .reverse()
      .join('-');
    return {
      initialValues: {
        email: profile.email,
        first_name: profile.first_name,
        last_name: profile.last_name,
        dob,
        gender: profile.gender || '',
        password: '',
        passwordConfirm: '',
        publicProfile: profile.public_profile ? '1' : '',
        allowFollowers: profile.allow_followers ? '1' : '',
      },
      validationSchema: yup.object().shape({
        email: yup
          .string()
          .required('This field is required')
          .email('This field must be an email address'),
        first_name: yup.string().required('This field is required'),
        last_name: yup.string().required('This field is required'),
        dob: yup.date().required('This field is required'),
        gender: yup.string().required('This field is required'),
        password: yup
          .string()
          .matches(PASSWORD_REGEX, PASSWORD_DESCRIPTIVE_TEXT),
        passwordConfirm: yup.string().when('password', {
          is: val => val && val.length > 0,
          then: yup
            .string()
            .required('This field is required')
            .oneOf([yup.ref('password'), null], "Passwords don't match"),
          otherwise: yup.string().notRequired(),
        }),
      }),
      onSubmit: this.submitProfileForm,
    };
  }

  getContactInformationFormikProps() {
    const profile = this.props.accountProfileData.user;
    return {
      initialValues: {
        address1: profile.address1,
        address2: profile.address2,
        city: profile.city,
        state_id: profile.state ? profile.state.id : null,
        zip_code: profile.zip_code,
        telephone: profile.telephone,
      },
      validationSchema: yup.object().shape({
        address1: yup.string().required('This field is required'),
        city: yup.string().required('This field is required'),
        state_id: yup.string().required('This field is required'),
        zip_code: yup.string().required('This field is required'),
      }),
      onSubmit: this.submitProfileForm,
    };
  }

  getEmployerInformationFormikProps() {
    const profile = this.props.accountProfileData.user;
    return {
      initialValues: {
        employer: profile.employer,
        occupation: profile.occupation,
        is_lobbyist: profile.is_lobbyist === true ? '1' : '',
      },
      validationSchema: yup.object().shape({
        employer: yup.string().required('This field is required'),
        occupation: yup.string().required('This field is required'),
        is_lobbyist: yup.string(),
      }),
      onSubmit: this.submitProfileForm,
    };
  }

  getInterestsFormikProps() {
    const profile = this.props.accountProfileData.user;

    return {
      initialValues: {
        interests: profile.interests.map(interest => interest.id.toString()),
      },
      onSubmit: this.submitProfileForm,
    };
  }

  getInterestItems() {
    const currentInterests = this.props.accountProfileData.user.interests.map(
      interest => ({ label: interest.name, value: interest.id.toString() }),
    );
    const interests = this.props.listInterestsData.interests.map(interest => ({
      label: interest.name,
      value: interest.id.toString(),
    }));

    return _uniqBy(
      currentInterests.concat(interests),
      interest => interest.value,
    );
  }

  handleProfileImageChange = (image: File) => {
    message.loading('Updating profile image', 3);
    this.props.updateAccountProfile({ profile_image: image });
  };

  toggleNewAchModalVisibility = () => {
    this.setState((prevState: State) => ({
      newAchModalOpen: !prevState.newAchModalOpen,
    }));
  };

  toggleNewGroupModalVisibility = () => {
    this.setState((prevState: State) => ({
      newGroupModalOpen: !prevState.newGroupModalOpen,
    }));
  };

  toggleDeactivateAccountModalVisibility = () => {
    this.setState((prevState: State) => ({
      deactivateAccountModalOpen: !prevState.deactivateAccountModalOpen,
    }));
  };

  toggleEditBasicInformation = () => {
    this.setState((prevState: State) => ({
      editBasicInformation: !prevState.editBasicInformation,
    }));
  };

  toggleEditContactInformation = () => {
    this.setState((prevState: State) => ({
      editContactInformation: !prevState.editContactInformation,
    }));
  };

  toggleEditEmployerInformation = () => {
    this.setState((prevState: State) => ({
      editEmployerInformation: !prevState.editEmployerInformation,
    }));
  };

  toggleEditInterests = () => {
    this.setState((prevState: State) => ({
      editInterests: !prevState.editInterests,
    }));
  };

  handleLoadMoreInterests = () => {
    this.props.getListInterestsNextPage();
  };

  handleNewGroupSuccess = (newGroupData: any) => {
    this.setState(
      () => ({ newGroupModalOpen: false }),
      () => {
        message.success('A new group was created.', 10);
      },
    );
  };

  submitProfileForm = (formikValues: any) => {
    const profile = this.props.accountProfileData.user;
    const dob = formikValues.dob
      ? moment(formikValues.dob).format('MM/DD/YYYY')
      : '';
    let payload = _omitBy({ ...formikValues, dob }, (value, key) => {
      return (
        (!value && value !== 0) ||
        [
          'passwordConfirm',
          'interests',
          'publicProfile',
          'allowFollowers',
        ].indexOf(key) !== -1
      );
    });

    payload.public_profile = formikValues.publicProfile === '1';
    payload.allow_followers = formikValues.allowFollowers === '1';

    if (formikValues.is_lobbyist) {
      payload.lobbyist = true;
      this.props.updateAccountProfile(payload);
    } else {
      payload.lobbyist = false;
      this.props.updateAccountProfile(payload);
    }
    if (formikValues.interests) {
      payload.entity_interests_attributes = this.getInterestsPayload(
        formikValues.interests,
      );
    }

    if (
      (formikValues.email && formikValues.email !== profile.email) ||
      formikValues.password
    ) {
      this.setState(
        () => ({ isChangingAccount: true }),
        () => {
          this.props.updateAccountProfile(payload);
        },
      );
    } else {
      this.props.updateAccountProfile(payload);
    }
  };

  getInterestsPayload(newInterestIds) {
    const previousInterestIds = this.props.accountProfileData.user.interests.map(
      interest => interest.id.toString(),
    );
    let interestToRemove = [];

    previousInterestIds.forEach(interestId => {
      if (newInterestIds.indexOf(interestId) === -1) {
        interestToRemove.push({
          interest_id: parseInt(interestId),
          _destroy: true,
        });
      }
    });

    return newInterestIds
      .map(interestId => ({ interest_id: parseInt(interestId) }))
      .concat(interestToRemove);
  }

  handleNewGroupError = (newGroupError: any) => {
    message.error(
      newGroupError.localMessage ||
        'An error has occurred and the group was not created.',
    );
  };
}

const mapStateToProps = (state, ownProps) => {
  return {
    ...accountDetails.mapStateToProps(state),
    ...accountGroups.mapStateToProps(state),
    ...accountPaymentMethods.mapStateToProps(state),
    ...accountProfile.mapStateToProps(state),
    ...accountProfileUpdate.mapStateToProps(state),
    ...listInterests.mapStateToProps(state),
    ...listStates.mapStateToProps(state),
    ...ownProps,
  };
};

const mapDispatchToProps = dispatch => {
  return {
    ...account.mapDispatchToProps(dispatch),
    ...accountPaymentMethods.mapDispatchToProps(dispatch),
    ...accountProfileUpdate.mapDispatchToProps(dispatch),
    ...listInterests.mapDispatchToProps(dispatch),
    ...listStates.mapDispatchToProps(dispatch),
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(EditProfileTab);
