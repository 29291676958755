// @flow
import { createAction } from 'redux-actions';
import {
  POST_DETAILS_ERROR,
  POST_DETAILS_LOADING,
  POST_DETAILS_RESET,
  POST_DETAILS_SUCCESS,
  POST_DETAILS_LIKE_POST,
  POST_DETAILS_UNLIKE_POST,
  POST_DETAILS_SHARE_POST,
} from './postDetails.constants';
import { instance as rorPostsApiProvider } from '../../../services/RoRPostsApiProvider';
import type { GetPostDetailsPayload } from '../../../services/RoRPostsApiProvider';

export const postDetailsError = createAction(POST_DETAILS_ERROR);

export const postDetailsLoading = createAction(POST_DETAILS_LOADING);

export const postDetailsSuccess = createAction(POST_DETAILS_SUCCESS);

export const postDetailsReset = createAction(POST_DETAILS_RESET);

export const postDetailsLikePost = createAction(POST_DETAILS_LIKE_POST);

export const postDetailsUnlikePost = createAction(POST_DETAILS_UNLIKE_POST);

export const postDetailsSharePost = createAction(POST_DETAILS_SHARE_POST);

export const getPostDetails = (payload: GetPostDetailsPayload) => {
  return async (dispatch: Function) => {
    try {
      dispatch(postDetailsLoading());

      const response = await rorPostsApiProvider.postDetails(payload);

      dispatch(postDetailsSuccess(response));
    } catch (error) {
      dispatch(postDetailsError(error));
    }
  };
};
