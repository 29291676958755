// @flow
import React from 'react';
import moment from 'moment';
import Avatar from '../Avatar/Avatar';
import './CardHeader.scss';
import { URL_USER_PUBLIC_PROFILE } from '../../config/urls';
import Link from '../Link/Link';
import type { UserVerifiedLevel } from '../../services/RoRUsersApiProvider';

type Props = {
  userAvatar: string,
  userIsCandidate: boolean,
  userFullName: string,
  userId: number,
  date: string,
  textAfterUser?: string,
  userVerifiedLevel?: UserVerifiedLevel,
  userVerifiedDetail?: string,
};

const CardHeader = (props: Props) => {
  const month = moment(props.date)
    .format('MMM')
    .toUpperCase();
  const date = moment(props.date).format('Do, HH:mm A');
  const userProfileUrl = URL_USER_PUBLIC_PROFILE.replace(':id', props.userId);

  return (
    <div className="row card-header-component">
      <div className="col-12 col-md-7 col-lg-6 card-header-component__name">
        <div className="row align-items-center">
          <div className="col-2 col-md-1 pr-0 pr-md-0">
            <Link href={userProfileUrl} title={props.userFullName} type="dark">
              <Avatar
                source={props.userAvatar}
                type="tiny"
                isCandidate={props.userIsCandidate}
                verifiedLevel={props.userVerifiedLevel}
                verifiedDetail={props.userVerifiedDetail}
              />
            </Link>
          </div>
          <div className="col-10 col-md-11 pl-0 pl-md-3 pt-0">
            <Link href={userProfileUrl} title={props.userFullName} type="dark">
              <span className="pl-0 pl-md-2">{props.userFullName}</span>
            </Link>
            {props.textAfterUser ? (
              <span className="card-header-component__text-after-user">
                &nbsp;{props.textAfterUser}
              </span>
            ) : null}
            <br />
            <span className="card-header-component__date pl-0 pl-md-2 pt-0 d-block d-md-none">
              {month} {date}
            </span>
          </div>
        </div>
      </div>
      <div className="col-5 col-lg-6 text-right card-header-component__date d-none d-md-block">
        {month} {date}
      </div>
    </div>
  );
};

export default CardHeader;
