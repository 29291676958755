// @flow
import React from 'react';
import classNames from 'classnames';
import _isUndefined from 'lodash';
import Button from '../Button/Button';
import type { UserVerifiedLevel as UserVerifiedLevelType } from '../../services/RoRUsersApiProvider';

import './Avatar.scss';

const UserVerifiedLevel = {
  NOT_VERIFIED: 'not_verified',
  SILVER: 'silver',
  GOLD: 'gold',
};

type Props = {
  className?: string,
  imageClassName?: string,
  type: 'big' | 'large' | 'normal' | 'medium' | 'small' | 'x-small' | 'tiny',
  editable?: boolean,
  isCandidate?: boolean,
  verifiedLevel?: UserVerifiedLevelType,
  verifiedDetail?: string,
  source: string,
  onFileChange?: Function,
};

type State = {
  file: File | null,
  newSource: string | null,
};

class Avatar extends React.Component<Props, State> {
  static defaultProps = {
    type: 'normal',
    isCandidate: false,
    verifiedLevel: UserVerifiedLevel.NOT_VERIFIED,
    verifiedDetail: null
  };

  constructor(props: Props) {
    super(props);
    this.state = { file: null, newSource: null };
  }

  render() {
    const {
      source,
      type,
      className,
      imageClassName,
      editable,
      isCandidate,
      ...rest
    } = this.props;

    return (
      <div className={this.getClassNames()} {...rest}>
        <div className={this.getImageClassNames()}>
          <img src={this.state.newSource || source} className="avatar-image" alt="" />
          {this.renderCandidateBadge()}
          {this.renderVerifiedLevel()}
        </div>
        {this.renderEditButton()}
      </div>
    );
  }

  renderVerifiedLevel() {
    const { verifiedLevel, verifiedDetail } = this.props;

    if (verifiedLevel === UserVerifiedLevel.NOT_VERIFIED) return null;

    return (
      <div className={this.getBadgeClassNames('verified', verifiedLevel)} tabIndex="0">
        <img src={`/images/icon_verified_${verifiedLevel}.svg`} />
        {verifiedDetail && <small>{verifiedDetail}</small>}
      </div>
    )
  }

  renderCandidateBadge() {
    let content = null;

    if (this.props.isCandidate) {
      content = (
        <div className={this.getBadgeClassNames('candidate')} tabIndex="0">
          <img src="/images/icon_candidate_big.png" />
        </div>
      );
    }

    return content;
  }

  renderEditButton() {
    let editButton = null;

    if (this.props.editable && !this.state.file) {
      editButton = (
        <div className="avatar__edit">
          <label className="avatar__edit-button" htmlFor="avatar__edit-input">
            <img src="/images/icon_edit.png" alt="" className="pr-2" />
            EDIT PROFILE PIC
          </label>
          <input
            accept="image/*"
            onChange={this.handleFileChange}
            type="file"
            className="avatar__edit-input"
            id="avatar__edit-input"
          />
        </div>
      );
    } else if (this.props.editable && this.state.file) {
      editButton = (
        <div className="avatar__edit">
          <div>
            <img src="/images/icon_edit.png" alt="" className="pr-2" />
          </div>
          <Button
            buttonType="link"
            className="avatar__edit-button"
            type="button"
            onClick={this.handleFileChangeConfirm}>
            UPDATE
          </Button>
          <span className="avatar__edit-button">|</span>
          <Button
            buttonType="link"
            className="avatar__edit-button"
            type="button"
            onClick={this.handleDiscardChanges}>
            CLEAR
          </Button>
        </div>
      );
    }

    return editButton;
  }

  handleFileChange = (event: any) => {
    const file = event.target.files[0];
    const newSource = URL.createObjectURL(file);
    this.setState(() => ({ file, newSource }));
  };

  handleFileChangeConfirm = () => {
    if (this.props.onFileChange) {
      this.props.onFileChange(this.state.file);
    }

    this.setState(() => ({ file: null }));
  };

  handleDiscardChanges = () => {
    this.setState(() => ({ file: null, newSource: null }));
  };

  getClassNames() {
    return classNames({
      avatar: true,
      [`avatar--${this.props.type}`]: true,
      [`avatar--${this.props.type}-with-button`]: this.props.editable,
      [this.props.className || '']: this.props.className,
    });
  }

  getImageClassNames() {
    return classNames({
      avatar__image: true,
      [this.props.imageClassName || '']: this.props.imageClassName,
    });
  }

  getBadgeClassNames(badgeType: string, verifiedLevel?: string) {
    return classNames(
      'avatar-badge',
      `avatar-badge--${badgeType}`,
      verifiedLevel ? `avatar-badge--${verifiedLevel}` : null
    );
  }
}

export default Avatar;
