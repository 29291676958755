import {
  calculateCommittedDonation as calculateDonation,
  contributionCommittedDonationCalculateReset as reset,
} from './contributionCommittedDonationCalculate.actions';
import type {
  PostCalculateCommittedDonationPayload,
  PostCalculateCommittedDonationResponse,
} from '../../../services/RoRContributionsApiProvider';

export const mapStateToProps = state => {
  return {
    contributionCommittedDonationCalculateData:
      state.contributionCommittedDonationCalculate.data,
    contributionCommittedDonationCalculateError:
      state.contributionCommittedDonationCalculate.error,
    contributionCommittedDonationCalculateIsLoading:
      state.contributionCommittedDonationCalculate.isLoading,
  };
};

export const mapDispatchToProps = dispatch => {
  return {
    calculateCommittedDonation(payload: PostCalculateCommittedDonationPayload) {
      dispatch(calculateDonation(payload));
    },
    contributionCommittedDonationCalculateReset() {
      dispatch(reset());
    },
  };
};

export type ContributionCommittedDonationCalculateMapStateToProps = {
  contributionCommittedDonationCalculateData: PostCalculateCommittedDonationResponse,
  contributionCommittedDonationCalculateError: any,
  contributionCommittedDonationCalculateIsLoading: boolean,
};

export type ContributionCommittedDonationCalculateDispatchToProps = {
  calculateCommittedDonation: (
    payload: PostCalculateCommittedDonationPayload,
  ) => void,
  contributionCommittedDonationCalculateReset: () => void,
};

export default {
  mapStateToProps,
  mapDispatchToProps,
};
