// @flow
import { createAction } from 'redux-actions';
import {
  POST_FLAG_ERROR,
  POST_FLAG_LOADING,
  POST_FLAG_RESET,
  POST_FLAG_SUCCESS,
} from './postFlag.constants';
import { instance as rorPostsApiProvider } from '../../../services/RoRPostsApiProvider';
import type { FlagPostPayload } from '../../../services/RoRPostsApiProvider';

export const postFlagError = createAction(POST_FLAG_ERROR);

export const postFlagLoading = createAction(POST_FLAG_LOADING);

export const postFlagSuccess = createAction(POST_FLAG_SUCCESS);

export const postFlagReset = createAction(POST_FLAG_RESET);

export const postFlag = (payload: FlagPostPayload) => {
  return async (dispatch: Function) => {
    try {
      let response = {};
      dispatch(postFlagLoading());

      if (payload.postId) {
        response = await rorPostsApiProvider.flagPost(payload);
      } else {
        return dispatch(postFlagError(new Error('postId is required.')));
      }

      dispatch(postFlagSuccess(response));
    } catch (error) {
      dispatch(postFlagError(error));
    }
  };
};
