// @flow
import { createAction } from 'redux-actions';
import _get from 'lodash/get';
import {
  GROUP_MODERATORS_ERROR,
  GROUP_MODERATORS_LOADING,
  GROUP_MODERATORS_RESET,
  GROUP_MODERATORS_SUCCESS,
  GROUP_MODERATORS_REMOVE_USER_FROM_LIST,
  GROUP_MODERATORS_NEXT_PAGE,
} from './groupModerators.constants';
import { instance as rorGroupsApiProvider } from '../../../services/RoRGroupsApiProvider';
import type { GetGroupModeratorsPayload } from '../../../services/RoRGroupsApiProvider';
import { clientStore } from '../../clientStore';

export const groupModeratorsError = createAction(GROUP_MODERATORS_ERROR);

export const groupModeratorsLoading = createAction(GROUP_MODERATORS_LOADING);

export const groupModeratorsSuccess = createAction(GROUP_MODERATORS_SUCCESS);

export const groupModeratorsReset = createAction(GROUP_MODERATORS_RESET);

export const groupModeratorsRemoveUserFromList = createAction(
  GROUP_MODERATORS_REMOVE_USER_FROM_LIST,
);

export const groupModeratorsNextPage = createAction(GROUP_MODERATORS_NEXT_PAGE);

export const getGroupModerators = (payload: GetGroupModeratorsPayload) => {
  return async (dispatch: Function) => {
    try {
      dispatch(groupModeratorsLoading());

      const response = await rorGroupsApiProvider.groupModerators(payload);

      dispatch(groupModeratorsSuccess(response));
    } catch (error) {
      dispatch(groupModeratorsError(error));
    }
  };
};

export const getGroupModeratorsNextPage = (
  payload: GetGroupModeratorsPayload,
) => {
  return async (dispatch: Function) => {
    try {
      const nextPage = _get(
        clientStore.getState(),
        `groupModerators.data.next_page`,
      );

      if (nextPage) {
        dispatch(groupModeratorsLoading());

        const response = await rorGroupsApiProvider.groupModerators({
          ...payload,
          page: nextPage,
        });

        dispatch(groupModeratorsNextPage(response));
      }
    } catch (error) {
      dispatch(groupModeratorsError(error));
    }
  };
};
