import { listStates, listStatesReset as reset } from './listStates.actions';
import type { GeoState } from '../../../services/RoRApiProvider';

export const mapStateToProps = state => {
  return {
    listStatesData: state.listStates.data,
    listStatesError: state.listStates.error,
    listStatesIsLoading: state.listStates.isLoading,
  };
};

export const mapDispatchToProps = dispatch => {
  return {
    getListStates() {
      dispatch(listStates());
    },
    listStatesReset() {
      dispatch(reset());
    },
  };
};

export type ListStatesMapStateToProps = {
  listStatesData: { states: GeoState[] },
  listStatesError: any,
  listStatesIsLoading: boolean,
};

export type ListStatesMapDispatchToProps = {
  getListStates: Function,
  listStatesReset: Function,
};

export default {
  mapStateToProps,
  mapDispatchToProps,
};
