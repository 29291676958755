// @flow
import React from 'react';
import _get from 'lodash/get';
import moment from 'moment';
import { message } from 'antd';
import { connect } from 'react-redux';
import classNames from 'classnames';
import Button from '../Button/Button';
import Modal from '../Modal/Modal';
import type {
  ContributionDetailsMapDispatchToProps,
  ContributionDetailsMapStateToProps,
} from '../../redux/modules/contributionDetails/contributionDetails.containers';
import contributionDetails from '../../redux/modules/contributionDetails/contributionDetails.containers';
import contributionPledgeClaim from '../../redux/modules/contributionPledgeClaim/contributionPledgeClaim.containers';
import Loading from '../Loading/Loading';
import Avatar from '../Avatar/Avatar';
import Currency from '../Currency/Currency';
import type {
  ContributionPledgeClaimDispatchToProps,
  ContributionPledgeClaimMapStateToProps,
} from '../../redux/modules/contributionPledgeClaim/contributionPledgeClaim.containers';
import './ModalContributionDetails.scss';
import type { UserVerifiedLevel } from '../../services/RoRUsersApiProvider';

type Props = ContributionDetailsMapDispatchToProps &
  ContributionDetailsMapStateToProps &
  ContributionPledgeClaimDispatchToProps &
  ContributionPledgeClaimMapStateToProps & {
    isOpen: boolean,
    onAfterOpen?: Function,
    onCloseRequest?: Function,
    className?: string,
    type: 'direct' | 'pledged' | 'committed',
    id: number,
    contributor: {
      name: string,
      profileImage: string,
      verifiedLevel: UserVerifiedLevel,
      verifiedDetail: string,
    },
  };

class ModalContributionDetails extends React.Component<Props, {}> {
  constructor(props: Props) {
    super(props);
    this.props.contributionDetailsReset();
  }

  componentDidUpdate(prevProps: Props) {
    if (this.props.isOpen && !prevProps.isOpen) {
      this.props.contributionDetailsReset();
      this.props.contributionPledgeClaimReset();
      this.props.getContributionDetails({
        id: this.props.id,
        type: this.props.type,
      });
    }

    if (
      this.props.contributionPledgeClaimData &&
      !prevProps.contributionPledgeClaimData
    ) {
      message.success(
        'A claim request was successfully generated for this pledge.',
      );
      this.props.contributionPledgeClaimReset();
    }

    if (
      this.props.contributionPledgeClaimError &&
      !prevProps.contributionPledgeClaimError
    ) {
      message.error(
        this.props.contributionPledgeClaimError.localMessage ||
          'Something went wrong. Please try again later.',
      );
      this.props.contributionPledgeClaimReset();
    }
  }

  render() {
    const {
      isOpen,
      onAfterOpen,
      onCloseRequest,
      className,
      ...rest
    } = this.props;

    return (
      <Modal
        contentLabel="Contribution details"
        isOpen={isOpen}
        onAfterOpen={onAfterOpen}
        onCloseRequest={onCloseRequest}
        className={this.getClassName()}
        noPaddingContent
        size="small"
        {...rest}>
        {isOpen ? (
          <Loading
            loading={this.props.contributionDetailsIsLoading}
            type="spinner">
            {this.renderContent()}
          </Loading>
        ) : null}
      </Modal>
    );
  }

  renderContent() {
    const details = this.getContributionDetails();
    return details ? (
      <div className="modal-contribution-details__content">
        <div className="modal-contribution-details__head" />
        <div className="modal-contribution-details__body">
          <div className="row">
            <div className="col d-flex contribution-header">
              <Avatar
                type="x-small"
                source={this.props.contributor.profileImage}
                verifiedLevel={this.props.contributor.verifiedLevel}
                verifiedDetail={this.props.contributor.verifiedDetail}
              />
              <div>{this.props.contributor.name}</div>
              {this.renderContributionType()}
              <Avatar type="x-small" source={details.profile_image} />
              <div>{details.candidate_full_name}</div>
            </div>
          </div>
          <div className="row">
            <div className="col">
              <div className="contribution-label">TOTAL:</div>
              <div className="contribution-value">
                <Currency amount={details.total_amount_in_cents / 100} />
              </div>
            </div>
          </div>
          <div className="row">
            <div className="col">
              <div className="contribution-label">DATE:</div>
              <div className="contribution-value">
                {moment(details.created_at).format('MM/DD/YYYY HH:mm')}
              </div>
            </div>
            {details.due_date /* Only visible for pledges */ ? (
              <div className="col">
                <div className="contribution-label">DUE:</div>
                <div className="contribution-value">
                  {moment(details.due_date).format('MM/DD/YYYY HH:mm')}
                </div>
              </div>
            ) : null}
            {details.interest_name &&
            details.condition /* Only visible for pledge */ ? (
              <div className="col">
                <div className="contribution-label">CONDITION:</div>
                <div className="contribution-value">
                  <img
                    className="contribution-condition-image"
                    src={`/images/${details.condition}.png`}
                  />
                  <div>{details.interest_name}</div>
                </div>
              </div>
            ) : null}
            {details.payments /* Only visible for committed */ ? (
              <div className="col">
                <div className="contribution-label">PAYMENTS:</div>
                <ul>
                  {details.payments.map((payment, index) => (
                    <li key={index} className="contribution-value">
                      {moment(payment.due_date).format('MM/DD/YYYY')}&nbsp;
                      <Currency amount={payment.amount_in_cents / 100} />
                    </li>
                  ))}
                </ul>
              </div>
            ) : null}
          </div>
          <div className="row">
            {details.claim_status /* Only visible for pledge */ ? (
              <React.Fragment>
                <div className="col">
                  <div className="contribution-label">CLAIM STATUS:</div>
                  <div
                    className={`contribution-value contribution-claim-status contribution-claim-status--${
                      details.claim_status
                    }`}>
                    {details.claim_status}
                  </div>
                </div>
                <div className="col d-flex justify-content-end align-items-end">
                  <Button
                    buttonType="primary"
                    type="button"
                    size="small"
                    onClick={this.handleClaimPledge}
                    disabled={details.claim_status !== 'pending'}
                    loading={this.props.contributionPledgeClaimIsLoading}>
                    Claim pledge
                  </Button>
                </div>
              </React.Fragment>
            ) : null}
          </div>
        </div>
      </div>
    ) : null;
  }

  renderContributionType() {
    const type = {
      pledged: 'Pledged',
      direct: 'Contributed',
      committed: 'Committed',
    };

    return <div className="contribution-type">{type[this.props.type]} to</div>;
  }

  getClassName() {
    return classNames({
      'modal-contribution-details': true,
      [this.props.className || '']: this.props.className,
    });
  }

  getContributionDetails() {
    const key = {
      pledged: 'pledge',
      direct: 'donation',
      committed: 'committed_donation',
    };

    return _get(
      this.props,
      `contributionDetailsData.${key[this.props.type]}`,
      null,
    );
  }

  handleClaimPledge = () => {
    this.props.claimPledge({
      pledgeId: this.props.contributionDetailsData.pledge.id,
    });
  };
}

const mapStateToProps = (state, ownProps) => {
  return {
    ...contributionDetails.mapStateToProps(state),
    ...contributionPledgeClaim.mapStateToProps(state),
    ...ownProps,
  };
};

const mapDispatchToProps = dispatch => {
  return {
    ...contributionDetails.mapDispatchToProps(dispatch),
    ...contributionPledgeClaim.mapDispatchToProps(dispatch),
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(ModalContributionDetails);
