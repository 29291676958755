// @flow
import React from 'react';
import moment from 'moment';
import Link from '../Link/Link';
import {
  URL_RACE_ACTIVITY_PAGE,
  URL_USER_PUBLIC_PROFILE,
} from '../../config/urls';
import type { UserListView } from '../../services/RoRUsersApiProvider';
import Avatar from '../Avatar/Avatar';
import './CardRaceStand.scss';

type Props = {
  contenders: UserListView[],
  raceName: string,
  raceId: number,
  date: string,
};

const CardRaceStand = (props: Props) => {
  const month = moment(props.date)
    .format('MMM')
    .toUpperCase();
  const date = moment(props.date).format('Do, HH:mm A');

  return (
    <div className="row card-race-stand">
      <div className="col-12 px-0 mx-0">
        <div className="row card-race-stand__header">
          <div className="col-8">
            <h6>
              Race:&nbsp;
              <Link
                href={URL_RACE_ACTIVITY_PAGE.replace(':raceId', props.raceId)}
                title={props.raceName}>
                <span className="pl-0 pl-md-2">{props.raceName}</span>
              </Link>
            </h6>
          </div>
          <div className="col-4 text-right">
            <span className="card-race-stand__date">
              {month} {date}
            </span>
          </div>
        </div>
        <div className="row align-items-center">
          {props.contenders.map((contender: UserListView, index: number) => {
            const userProfileUrl = URL_USER_PUBLIC_PROFILE.replace(
              ':id',
              contender.id,
            );
            const userFullName = `${contender.first_name} ${
              contender.last_name
            }`.trim();

            return (
              <div className="col-12 col-md-4">
                <div className="row align-items-center">
                  <div className="col-1">
                    <span className="card-race-stand__rank">{index + 1}</span>
                  </div>
                  <div className="col-2">
                    <Link
                      href={userProfileUrl}
                      title={userFullName}
                      type="dark">
                      <Avatar
                        source={contender.profile_image}
                        type="x-small"
                        isCandidate={contender.role.code === 'candidate'}
                      />
                    </Link>
                  </div>
                  <div className="col-8">
                    <Link
                      href={userProfileUrl}
                      title={userFullName}
                      type="dark">
                      <span className="pl-0 pl-md-2">{userFullName}</span>
                    </Link>
                  </div>
                </div>
              </div>
            );
          })}
        </div>
      </div>
    </div>
  );
};

export default CardRaceStand;
