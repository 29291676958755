// @flow
import type { Element, Node } from 'react';
import React from 'react';
import './DropdownMenu.scss';
import Button from '../Button/Button';
import { Dropdown, Menu } from 'antd';

type MenuItem = {
  label: Node,
  onClick?: (event: SyntheticEvent<HTMLElement>) => void,
};

type Props = {
  button: Element<typeof Button>,
  menuItems: MenuItem[],
};

const DropdownMenu = ({ button, menuItems = [] }: Props) => {
  const menu = (
    <Menu className="dropdown__menu">
      {menuItems.map(({ label, ...itemProps }, index) => (
        <Menu.Item key={index} className="item item-outline" {...itemProps}>
          {label}
        </Menu.Item>
      ))}
    </Menu>
  );

  return (
    <Dropdown overlay={menu} trigger={['click']} className="dropdown">
      {button}
    </Dropdown>
  );
};

export default DropdownMenu;
