import {
  getPostDetails,
  postDetailsReset as reset,
} from './postDetails.actions';
import type { GetPostDetailsResponse } from '../../../services/RoRPostsApiProvider';

export const mapStateToProps = state => {
  return {
    postDetailsData: state.postDetails.data,
    postDetailsError: state.postDetails.error,
    postDetailsIsLoading: state.postDetails.isLoading,
  };
};

export const mapDispatchToProps = dispatch => {
  return {
    getDetailsForPost(payload) {
      dispatch(getPostDetails(payload));
    },
    postDetailsReset() {
      dispatch(reset());
    },
  };
};

export type PostDetailsMapStateToProps = {
  postDetailsData: GetPostDetailsResponse,
  postDetailsError: any,
  postDetailsIsLoading: boolean,
};

export type PostDetailsMapDispatchToProps = {
  getDetailsForPost: () => void,
  postDetailsReset: () => void,
};

export default {
  mapStateToProps,
  mapDispatchToProps,
};
