import {
  POST_ADD_ERROR,
  POST_ADD_LOADING,
  POST_ADD_SUCCESS,
  POST_ADD_RESET,
} from './postAdd.constants';

const initialState = {
  isLoading: false,
  error: null,
  data: null,
};

export const reducer = (state = initialState, action) => {
  switch (action.type) {
    case POST_ADD_LOADING:
      return {
        ...state,
        isLoading: true,
        error: null,
        data: null,
      };
    case POST_ADD_SUCCESS:
      return {
        ...state,
        isLoading: false,
        error: null,
        data: action.payload,
      };
    case POST_ADD_ERROR:
      return {
        ...state,
        isLoading: false,
        error: action.payload,
        data: null,
      };
    case POST_ADD_RESET:
      return {
        ...state,
        isLoading: false,
        error: null,
        data: null,
      };
    default:
      return state;
  }
};
