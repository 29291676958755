// @flow
import React from 'react';
import classNames from 'classnames';
import Avatar from '../Avatar/Avatar';
import Button from '../Button/Button';
import Link from '../Link/Link';
import './ListWithAvatars.scss';
import { Scrollbars } from 'react-custom-scrollbars';
import type { UserVerifiedLevel } from '../../services/RoRUsersApiProvider';

type Props = {
  items: {
    id: number,
    href: string,
    image?: string,
    badge?: boolean,
    verifiedLevel?: UserVerifiedLevel,
    verifiedDetail?: string,
    name: string,
    renderItem?: Function,
  }[],
  className?: string,
  emptyListMessage: string,
  title?: any,
  buttonTopText?: string,
  onButtonTopClick?: Function,
  buttonBottomProps?: { [key: string]: any },
  buttonBottomText?: string,
  displayBottomSeparator?: boolean,
  onButtonBottomClick?: Function,
  buttonTop?: any,
  fixedHeight?: number | string,
};

const ListWithAvatars = (props: Props) => {
  return (
    <div className={getClassName(props.className)}>
      {renderHeader(props)}
      <div className="row">
        {props.fixedHeight ? (
          <Scrollbars
            universal
            style={{ height: props.fixedHeight }}
            renderTrackHorizontal={() => <span />}>
            {renderContent(props)}
          </Scrollbars>
        ) : (
          renderContent(props)
        )}
        {props.items && !props.items.length ? (
          <div className="col-12 pt-3">{props.emptyListMessage}</div>
        ) : null}
      </div>
      {props.displayBottomSeparator || props.buttonBottomText ? (
        <div className="row">
          <div className="col-12 list-with-avatars__more text-center">
            <hr />
            {props.buttonBottomText ? (
              <Button
                type="button"
                onClick={props.onButtonBottomClick}
                buttonType="light"
                {...props.buttonBottomProps || {}}>
                {props.buttonBottomText}
              </Button>
            ) : null}
          </div>
        </div>
      ) : null}
    </div>
  );
};

ListWithAvatars.defaultProps = {
  displayBottomSeparator: true,
};

function renderHeader(props: Props) {
  if (props.buttonTopText || props.buttonTop) {
    return (
      <div className="row">
        <div className="col-4 pt-2">
          <h6 className="group-list__header">{props.title}</h6>
        </div>
        <div className="col-8 text-right">
          {props.buttonTop ? (
            props.buttonTop
          ) : (
            <Button
              size="small"
              onClick={props.onButtonTopClick}
              buttonType="link"
              className="link-violet">
              {props.buttonTopText}
            </Button>
          )}
        </div>
      </div>
    );
  }

  return props.title ? (
    <div className="row">
      <div className="col-12">
        <h6 className="list-with-avatars__header">{props.title}</h6>
      </div>
    </div>
  ) : null;
}

function renderContent(props: Props) {
  return (
    <ul className="col-12">
      {props.items.map(item =>
        item.renderItem ? (
          item.renderItem(item)
        ) : (
          <li className="list-with-avatars__item" key={item.id}>
            <Link href={item.href}>
              <div className="row align-items-center">
                {item.image ? (
                  <div className="col-3">
                    <Avatar
                      source={item.image}
                      type="x-small"
                      isCandidate={item.badge}
                      verifiedLevel={item.verifiedLevel}
                      verifiedDetail={item.verifiedDetail}
                    />
                  </div>
                ) : null}
                <div className="col-9 px-3 px-lg-0">
                  <p>{item.name}</p>
                </div>
              </div>
            </Link>
          </li>
        ),
      )}
    </ul>
  );
}

function getClassName(className) {
  return classNames({
    'list-with-avatars': true,
    container: true,
    [className || '']: className,
  });
}

export default ListWithAvatars;
