import {
  signUpOtpVerification,
  signUpOtpVerificationReset as reset,
} from './signUpOtpVerification.actions';
import type {
  DefaultResponse,
  PostVerifyOtpCode,
} from '../../../services/RoRApiProvider';

export const mapStateToProps = state => {
  return {
    signUpOtpVerificationData: state.signUpOtpVerification.data,
    signUpOtpVerificationError: state.signUpOtpVerification.error,
    signUpOtpVerificationIsLoading: state.signUpOtpVerification.isLoading,
  };
};

export const mapDispatchToProps = dispatch => {
  return {
    verifySignUpEmail(payload: PostVerifyOtpCode) {
      dispatch(signUpOtpVerification(payload));
    },
    signUpOtpVerificationReset() {
      dispatch(reset());
    },
  };
};

export type SignUpOtpVerificationMapStateToProps = {
  signUpOtpVerificationData: DefaultResponse,
  signUpOtpVerificationError: any,
  signUpOtpVerificationIsLoading: boolean,
};

export type SignUpOtpVerificationMapDispatchToProps = {
  verifySignUpEmail: (payload: PostVerifyOtpCode) => void,
  signUpOtpVerificationReset: () => void,
};

export default {
  mapStateToProps,
  mapDispatchToProps,
};
