import {
  contactPrytanyMessage,
  contactPrytanyMessageReset as listReset,
} from './contactPrytanyMessage.actions';

export const mapStateToProps = state => {
  return {
    contactPrytanyMessageData: state.contactPrytanyMessage.data,
    contactPrytanyMessageError: state.contactPrytanyMessage.error,
    contactPrytanyMessageIsLoading: state.contactPrytanyMessage.isLoading,
  };
};

export const mapDispatchToProps = dispatch => {
  return {
    contactPrytanyMessage(payload) {
      dispatch(contactPrytanyMessage(payload));
    },
    contactPrytanyMessageReset() {
      dispatch(listReset());
    },
  };
};

export type ContactPrytanyMessageMapStateToProps = {
  contactPrytanyMessageData: any,
  contactPrytanyMessageError: any,
  contactPrytanyMessageIsLoading: boolean,
};

export type ContactPrytanyMessageMapDispatchToProps = {
  contactPrytanyMessage: Function,
  contactPrytanyMessageReset: Function,
};

export default {
  mapStateToProps,
  mapDispatchToProps,
};
