// @flow
import { createAction } from 'redux-actions';
import {
  ACCOUNT_RACES_ERROR,
  ACCOUNT_RACES_LOADING,
  ACCOUNT_RACES_RESET,
  ACCOUNT_RACES_SUCCESS,
} from './accountRaces.constants';
import { instance as rorApiProvider } from '../../../services/RoRUsersApiProvider';

export const accountRacesError = createAction(ACCOUNT_RACES_ERROR);

export const accountRacesLoading = createAction(ACCOUNT_RACES_LOADING);

export const accountRacesSuccess = createAction(ACCOUNT_RACES_SUCCESS);

export const accountRacesReset = createAction(ACCOUNT_RACES_RESET);

export const getAccountRaces = (payload: { limit?: number, page?: number }) => {
  return async (dispatch: Function) => {
    try {
      dispatch(accountRacesLoading());

      const response = await rorApiProvider.accountRaces({
        page: payload.page,
        limit: payload.limit,
      });

      dispatch(accountRacesSuccess(response));
    } catch (error) {
      dispatch(accountRacesError(error));
    }
  };
};
