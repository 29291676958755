// @flow
import React from 'react';
import { DisplayDimensionsContext } from '../context/displayDimensionsContext';

const LG_BREAKPOINT = 1199;
const MD_BREAKPOINT = 767;
const SM_BREAKPOINT = 575;

function withDisplayDimensions(WrappedComponent: any) {
  return class extends React.Component<any, {}> {
    render() {
      return (
        <DisplayDimensionsContext.Consumer>
          {context => (
            <WrappedComponent
              {...this.getCurrentDimensions({
                width: context.displayWidth,
                height: context.displayHeight,
              })}
              {...this.props}
            />
          )}
        </DisplayDimensionsContext.Consumer>
      );
    }

    getCurrentDimensions({ width, height }: { width: number, height: number }) {
      const isDisplaySM = width > SM_BREAKPOINT && width <= MD_BREAKPOINT;
      const isDisplayXS = width <= SM_BREAKPOINT;

      return {
        displayWidth: width,
        displayHeight: height,
        isDisplayLG: width > LG_BREAKPOINT,
        isDisplayMD: width > MD_BREAKPOINT && width <= LG_BREAKPOINT,
        isDisplaySM,
        isDisplayXS,
        isMobile: isDisplaySM || isDisplayXS,
      };
    }
  };
}

export type WithDisplayDimensionsOutputProps = {
  displayWidth: number,
  displayHeight: number,
  isDisplayLG: boolean,
  isDisplayMD: boolean,
  isDisplaySM: boolean,
  isDisplayXS: boolean,
  isMobile: boolean,
};

export default withDisplayDimensions;
