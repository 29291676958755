import { postShare, postShareReset } from './postShare.actions';
import type {
  SharePostPayload,
  SharePostResponse,
} from '../../../services/RoRPostsApiProvider';

export const mapStateToProps = state => {
  return {
    postShareData: state.postShare.data,
    postShareError: state.postShare.error,
    postShareIsLoading: state.postShare.isLoading,
  };
};

export const mapDispatchToProps = dispatch => {
  return {
    postShare(payload: SharePostPayload) {
      dispatch(postShare(payload));
    },
    postShareReset() {
      dispatch(postShareReset());
    },
  };
};

export type PostShareMapStateToProps = {
  postShareData: SharePostResponse,
  postShareError: any,
  postShareIsLoading: boolean,
};

export type PostShareMapDispatchToProps = {
  postShare: (payload: SharePostPayload) => void,
  postShareReset: () => void,
};

export default {
  mapStateToProps,
  mapDispatchToProps,
};
