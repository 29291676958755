// @flow
import { createAction } from 'redux-actions';
import {
  GROUP_MEMBERS_ERROR,
  GROUP_MEMBERS_LOADING,
  GROUP_MEMBERS_RESET,
  GROUP_MEMBERS_SUCCESS,
  GROUP_MEMBERS_REMOVE_USER_FROM_LIST,
} from './groupMembers.constants';
import { instance as rorGroupsApiProvider } from '../../../services/RoRGroupsApiProvider';
import type { GetGroupMembersPayload } from '../../../services/RoRGroupsApiProvider';

export const groupMembersError = createAction(GROUP_MEMBERS_ERROR);

export const groupMembersLoading = createAction(GROUP_MEMBERS_LOADING);

export const groupMembersSuccess = createAction(GROUP_MEMBERS_SUCCESS);

export const groupMembersReset = createAction(GROUP_MEMBERS_RESET);

export const groupMembersRemoveUserFromList = createAction(
  GROUP_MEMBERS_REMOVE_USER_FROM_LIST,
);

export const getGroupMembers = (payload: GetGroupMembersPayload) => {
  return async (dispatch: Function) => {
    try {
      dispatch(groupMembersLoading());

      const response = await rorGroupsApiProvider.groupMembers(payload);

      dispatch(groupMembersSuccess(response));
    } catch (error) {
      dispatch(groupMembersError(error));
    }
  };
};
