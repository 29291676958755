// @flow
import React from 'react';
import './PrytanyTVPage.scss';
import account from '../../redux/modules/account/account.containers';
import { connect } from 'react-redux';
import ReactJWPlayer from 'react-jw-player';

class PrytanyTvPage extends React.Component<Props> {
  render() {
    return (
      <div className="prytanytv">
        { this.renderTopBar() }
        { this.renderContent() }
      </div>
    );
  }

  renderTopBar() {
    return (
      <div className="container-fluid prytanytv-page__top-bar">
        <div className="row fix-width">
          <div className="col-8">
            <div className="row pt-5">
              <div className="col-9 col-lg-10 pl-4 pt-1">
                <h1 className="name">CandidateTV by Prytany</h1>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }

  renderContent() {
    return (
      <section className="content">
        <div className="container-fluid fix-width">
          <div className="row justify-content-md-center">
            <div className="col-lg-8 col-md-9 pr-8-md flex-column mb-5">
              <ReactJWPlayer
                playerId="prytanyTv"
                playerScript="https://cdn.jwplayer.com/libraries/Tao6Z0X9.js"
                file="https://live-dist.global.ssl.fastly.net/tdlive/pystream.smil/playlist.m3u8"
              />
              <img src="images/poweredbykiswe.png"/>
            </div>
          </div>
        </div>
      </section>
    );
  }
}

const mapStateToProps = (state, ownProps) => {
  return {
    ...account.mapStateToProps(state),
    ...ownProps,
  };
};

const mapDispatchToProps = dispatch => {
  return {
    ...account.mapDispatchToProps(dispatch),
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(PrytanyTvPage);
