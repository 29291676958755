import {
  renameGroup as rename,
  groupRenameReset as reset,
} from './groupRename.actions';
import type {
  PutGroupRenamePayload,
  PutGroupRenameResponse,
} from '../../../services/RoRGroupsApiProvider';

export const mapStateToProps = state => {
  return {
    groupRenameData: state.groupRename.data,
    groupRenameError: state.groupRename.error,
    groupRenameIsLoading: state.groupRename.isLoading,
  };
};

export const mapDispatchToProps = dispatch => {
  return {
    groupRename(payload: PutGroupRenamePayload) {
      dispatch(rename(payload));
    },
    groupRenameReset() {
      dispatch(reset());
    },
  };
};

export type GroupRenameMapStateToProps = {
  groupRenameData: PutGroupRenameResponse,
  groupRenameError: any,
  groupRenameIsLoading: boolean,
};

export type GroupRenameMapDispatchToProps = {
  groupRename: (payload: PutGroupRenamePayload) => void,
  groupRenameReset: () => void,
};

export default {
  mapStateToProps,
  mapDispatchToProps,
};
