// @flow
import makeDefaultServerCallReducer from '../../utilities/makeDefaultServerCallReducer';
import {
  GROUP_DENY_REQUEST_ERROR,
  GROUP_DENY_REQUEST_LOADING,
  GROUP_DENY_REQUEST_SUCCESS,
  GROUP_DENY_REQUEST_RESET,
} from './groupDenyRequest.constants';

const initialState = {
  isLoading: false,
  error: null,
  data: null,
};

export const reducer = makeDefaultServerCallReducer({
  loadingActions: [GROUP_DENY_REQUEST_LOADING],
  successActions: [GROUP_DENY_REQUEST_SUCCESS],
  errorActions: [GROUP_DENY_REQUEST_ERROR],
  resetActions: [GROUP_DENY_REQUEST_RESET],
  initialState,
});
