// @flow
import React from 'react';
import Button from '../Button/Button';
import classNames from 'classnames';
import Link from '../Link/Link';
import { URL_RACE_ACTIVITY_PAGE } from '../../config/urls';
import './RaceList.scss';

type Props = {
  races: {
    id: number,
    name: string,
    due_date: string,
  }[],
  className?: string,
};

type State = {
  showAll: boolean,
};

class RaceList extends React.Component<Props, State> {
  handleCollapseButtonClick: Function;

  constructor(props: Props) {
    super(props);
    this.handleCollapseButtonClick = this.handleCollapseButtonClick.bind(this);
    this.state = { showAll: false };
  }

  render() {
    return (
      <div className={this.getClassName()}>
        <div className="row">
          <div className="col-12">
            <h6 className="race-list__header">Current Races</h6>
          </div>
        </div>
        <div className="row">
          <ul className="col-12">{this.renderItems()}</ul>
          {this.props.races && !this.props.races.length ? (
            <div className="col-12 pt-3">There's no active races yet.</div>
          ) : null}
        </div>
        {this.renderCollapseButton()}
      </div>
    );
  }

  renderItems() {
    const raceItems =
      this.props.races.length > 4 && this.state.showAll
        ? this.props.races
        : this.props.races.slice(0, 4);

    return raceItems.map(race => (
      <li className="race-list__item" key={race.id}>
        <Link href={URL_RACE_ACTIVITY_PAGE.replace(':raceId', race.id)}>
          <div className="row align-items-center">
            <div className="col-3 px-0">
              <img src="/images/icon_group.png" alt={race.name} />
            </div>
            <div className="col-9 pl-1 pr-0">
              <p>{race.name}</p>
            </div>
          </div>
        </Link>
      </li>
    ));
  }

  renderCollapseButton() {
    let content = null;

    if (this.props.races.length > 4) {
      content = (
        <div className="row">
          <div className="col-12 race-list__more text-center">
            <hr />
            <Button
              type="button"
              buttonType="light"
              onClick={this.handleCollapseButtonClick}>
              {this.state.showAll
                ? 'Collapse'
                : `${this.props.races.length - 4} More`}
            </Button>
          </div>
        </div>
      );
    }

    return content;
  }

  handleCollapseButtonClick() {
    this.setState(() => ({ showAll: !this.state.showAll }));
  }

  getClassName() {
    return classNames({
      'race-list': true,
      [this.props.className || '']: this.props.className,
    });
  }
}

export default RaceList;
