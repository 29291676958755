// @flow
import { createAction } from 'redux-actions';
import {
  CONTRIBUTION_COMMITTED_DONATION_CALCULATE_ERROR,
  CONTRIBUTION_COMMITTED_DONATION_CALCULATE_LOADING,
  CONTRIBUTION_COMMITTED_DONATION_CALCULATE_RESET,
  CONTRIBUTION_COMMITTED_DONATION_CALCULATE_SUCCESS,
} from './contributionCommittedDonationCalculate.constants';
import { instance as rorContributionsApiProvider } from '../../../services/RoRContributionsApiProvider';
import type { PostCalculateCommittedDonationPayload } from '../../../services/RoRContributionsApiProvider';

export const contributionCommittedDonationCalculateError = createAction(
  CONTRIBUTION_COMMITTED_DONATION_CALCULATE_ERROR,
);

export const contributionCommittedDonationCalculateLoading = createAction(
  CONTRIBUTION_COMMITTED_DONATION_CALCULATE_LOADING,
);

export const contributionCommittedDonationCalculateSuccess = createAction(
  CONTRIBUTION_COMMITTED_DONATION_CALCULATE_SUCCESS,
);

export const contributionCommittedDonationCalculateReset = createAction(
  CONTRIBUTION_COMMITTED_DONATION_CALCULATE_RESET,
);

export const calculateCommittedDonation = (
  payload: PostCalculateCommittedDonationPayload,
) => {
  return async (dispatch: Function) => {
    try {
      dispatch(contributionCommittedDonationCalculateLoading());

      const response = await rorContributionsApiProvider.calculateCommittedDonation(
        payload,
      );

      dispatch(contributionCommittedDonationCalculateSuccess(response));
    } catch (error) {
      dispatch(contributionCommittedDonationCalculateError(error));
    }
  };
};
