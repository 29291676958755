//@flow
import * as React from 'react';
import { connect } from 'react-redux';
import * as yup from 'yup';
import { message } from 'antd';
import { Formik, FormikComputedProps } from 'formik';
import Input from '../../components/Input/Input';
import TextArea from '../../components/TextArea/TextArea';
import Button from '../../components/Button/Button';
import contactPrytanyMessage from '../../redux/modules/contactPrytanyMessage/contactPrytanyMessage.containers';
import type {
  ContactPrytanyMessageMapDispatchToProps,
  ContactPrytanyMessageMapStateToProps,
} from '../../redux/modules/contactPrytanyMessage/contactPrytanyMessage.containers';
import './ContactUsPage.scss';

type Props = ContactPrytanyMessageMapDispatchToProps &
  ContactPrytanyMessageMapStateToProps & {
    onFormSubmit?: Function,
    initialFormValues: FormValues,
    isLoading: boolean,
  };

type FormValues = {
  email: string,
  name: string,
  message: string,
};

class ContactUsPage extends React.Component<Props, {}> {
  formikForm: Formik;

  constructor(props: Props) {
    super(props);
  }

  componentDidUpdate() {
    if (
      this.props.contactPrytanyMessageData &&
      this.props.contactPrytanyMessageData.success
    ) {
      message.success(
        this.props.contactPrytanyMessageData.localMessage ||
          'Your message has been sent successfully.',
      );
      this.props.contactPrytanyMessageReset();
    }

    if (this.props.contactPrytanyMessageError) {
      message.error(
        this.props.contactPrytanyMessageError.localMessage ||
          'An error has occurred, your message could not be sent.',
      );
    }
  }

  render() {
    return (
      <div className="contact-us-page">
        <section className="content py-3">
          <div className="container-fluid fix-width">
            <div className="row justify-content-md-center">
              <div className="col-lg-8 col-md-9 pr-8-md">
                <h2>Contact Us</h2>
                <Formik {...this.getFormikProps()}>
                  {props => this.renderInnerForm(props)}
                </Formik>
                <hr className="mt-4" />
              </div>
            </div>
          </div>
        </section>
      </div>
    );
  }

  renderInnerForm(props: FormikComputedProps) {
    const { touched, errors, handleSubmit, values } = props;

    return (
      <form className="contact-us-form" onSubmit={handleSubmit}>
        <div>
          <Input
            label="Name"
            name="name"
            type="text"
            error={touched.name && errors.name ? errors.name : ''}
          />
        </div>
        <div>
          <Input
            label="Email Address"
            name="email"
            type="email"
            error={touched.email && errors.email ? errors.email : ''}
          />
        </div>
        <div>
          <label htmlFor="toSupport">To</label>
          <p className="form-control form-round" id="toSupport">
            Prytany Support Team
          </p>
        </div>
        <TextArea
          className="col-12"
          label="Message"
          name="message"
          error={touched.message && errors.message ? errors.message : ''}
        />
        <Button
          buttonType="primary"
          type="submit"
          disabled={
            this.props.contactPrytanyMessageIsLoading ||
            !(values.name && values.email && values.message)
          }
          loading={this.props.isLoading}>
          Send Feedback
        </Button>
      </form>
    );
  }

  setFormikRef = (ref: Formik) => {
    this.formikForm = ref;
  };

  getFormikProps() {
    return {
      initialValues: this.getFormikInitialValues(),
      validationSchema: yup.object().shape(this.getValidationSchema()),
      onSubmit: this.onFormSubmit,
      ref: this.setFormikRef,
    };
  }

  getValidationSchema() {
    return {
      name: yup.string().required('This field is required'),
      email: yup
        .string()
        .required('This field is required')
        .email('This field must be an email address'),
      message: yup.string().required('This field is required'),
    };
  }

  getFormikInitialValues() {
    return {
      name: '',
      email: '',
      message: '',
    };
  }

  onFormSubmit = (values: FormValues) => {
    this.props.contactPrytanyMessage({ contact_us: { ...values } });
  };
}

const mapStateToProps = (state, ownProps) => {
  return {
    ...contactPrytanyMessage.mapStateToProps(state),
    ...ownProps,
  };
};

const mapDispatchToProps = dispatch => {
  return {
    ...contactPrytanyMessage.mapDispatchToProps(dispatch),
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(ContactUsPage);
