// @flow
import React from 'react';
import { connect } from 'react-redux';
import { message } from 'antd';
import classNames from 'classnames';
import _get from 'lodash/get';
import _throttle from 'lodash/throttle';
import Avatar from '../Avatar/Avatar';
import Button from '../Button/Button';
import Currency from '../Currency/Currency';
import Input from '../Input/Input';
import Table from '../Table/Table';
import Modal from '../Modal/Modal';
import listCandidates from '../../redux/modules/listCandidates/listCandidates.containers';
import groupUpdate from '../../redux/modules/groupUpdate/groupUpdate.containers';
import type { GroupDetails } from '../../services/RoRGroupsApiProvider';
import type {
  CandidatesMapDispatchToProps,
  CandidatesMapStateToProps,
} from '../../redux/modules/listCandidates/listCandidates.containers';
import type {
  GroupUpdateMapDispatchToProps,
  GroupUpdateMapStateToProps,
} from '../../redux/modules/groupUpdate/groupUpdate.containers';

type Props = CandidatesMapDispatchToProps &
  CandidatesMapStateToProps &
  GroupUpdateMapDispatchToProps &
  GroupUpdateMapStateToProps & {
    isOpen: boolean,
    onAfterOpen?: Function,
    onCloseRequest?: Function,
    className?: string,
    onSuccess: Function,
    onError: (error: string) => void,
    groupDetails: GroupDetails,
  };

type State = {
  searchValue: string,
  tableSelectedRows: any[],
  tableSelectedRowKeys: number[],
  calledService: boolean,
};

const CANDIDATES_PAGE_SIZE = 6;

class ModalAddSupportingCandidates extends React.Component<Props, State> {
  constructor(props: Props) {
    super(props);

    this.state = {
      searchValue: '',
      tableSelectedRows: [],
      tableSelectedRowKeys: [],
      calledService: false,
    };

    this.props.candidatesListReset();
  }

  componentDidMount() {
    this.fetchCandidates();
  }

  componentDidUpdate(prevProps: Props) {
    if (!prevProps.isOpen && this.props.isOpen) {
      this.setState(
        () => ({ searchValue: '' }),
        () => {
          this.fetchCandidates();
        },
      );
    }

    if (
      !prevProps.groupDetailsUpdateData &&
      this.props.groupDetailsUpdateData &&
      this.state.calledService
    ) {
      this.setState(
        () => ({ calledService: false }),
        () => {
          message.success(
            'Supported candidates were successfully added to the group.',
          );

          if (this.props.onSuccess) {
            this.props.onSuccess();
          }
        },
      );
    }

    if (
      !prevProps.groupDetailsUpdateError &&
      this.props.groupDetailsUpdateError &&
      this.state.calledService
    ) {
      const error =
        this.props.groupDetailsUpdateError.localMessage ||
        'An error has occurred and the candidates were not added.';

      this.setState(
        () => ({ calledService: false }),
        () => {
          message.error(error);
          if (this.props.onError) {
            this.props.onError(error);
          }
        },
      );
    }
  }

  render() {
    const { className, onSuccess, onError, ...rest } = this.props;

    return (
      <Modal
        contentLabel="Invite people"
        className={this.getClassName()}
        noPaddingContent
        size="normal"
        {...rest}>
        {this.props.isOpen ? this.renderContent() : null}
      </Modal>
    );
  }

  renderContent() {
    return (
      <React.Fragment>
        <h2 className="modal-component__title text-center mt-3 mb-3">
          Add supporting candidates to "{this.props.groupDetails.name}"
        </h2>
        <div className="row pb-3 pt-3 fix-width modal-invite-people__body">
          <Input
            className="col-8 offset-2 px-0 mb-0"
            size="small"
            label=""
            placeholder=""
            name="search"
            type="text"
            icon="/images/icon_lens.png"
            value={this.state.searchValue}
            useFormik={false}
            onChange={_throttle(this.handleSearchUser, 750)}
          />
          <div className="col-12">
            <Table {...this.getTableProps()} />
          </div>
        </div>
        <div className="row py-3 fix-width justify-content-center align-items-center">
          <Button
            onClick={this.props.onCloseRequest}
            buttonType="outline"
            type="button"
            className="col-8 col-md-3 col-lg-2 mx-2 mb-3 mb-md-0"
            size="small"
            disabled={this.props.groupSendInvitationIsLoading}>
            Cancel
          </Button>
          <Button
            onClick={this.handleAddSupportingCandidates}
            buttonType="primary"
            type="button"
            className="col-8 col-md-3 col-lg-2 mx-2"
            size="small"
            disabled={!this.state.tableSelectedRowKeys.length}
            loading={this.props.groupSendInvitationIsLoading}>
            Add Candidates
          </Button>
        </div>
      </React.Fragment>
    );
  }

  getClassName() {
    return classNames({
      'modal-invite-people': true,
      [this.props.className || '']: this.props.className,
    });
  }

  getTableProps() {
    const currentSupportedCandidates = _get(
      this.props,
      'groupDetails.supporting_candidates',
      [],
    );
    const currentSupportedCandidateIds = currentSupportedCandidates.map(
      candidate => parseInt(candidate.id),
    );

    return {
      dataSource: this.getTableData(),
      columns: this.getTableColumns(),
      pagination:
        this.props.candidatesListData &&
        this.props.candidatesListData.total_pages > 1
          ? {
              total:
                this.props.candidatesListData.total_pages *
                CANDIDATES_PAGE_SIZE,
              current: this.props.candidatesListData.current_page,
              pageSize: CANDIDATES_PAGE_SIZE,
            }
          : false,
      loading: this.props.candidatesListIsLoading,
      onChange: this.handleTableChange,
      rowSelection: {
        onChange: this.handleTableRowSelection,
        getCheckboxProps: (record: any) => {
          const candidateId = parseInt(record.key);
          const isAlreadySupportedCandidate =
            currentSupportedCandidateIds.indexOf(candidateId) !== -1;
          return {
            disabled: isAlreadySupportedCandidate,
            name: record.key,
            checked:
              isAlreadySupportedCandidate ||
              this.state.tableSelectedRowKeys.indexOf(candidateId) !== -1,
          };
        },
        selectedRowKeys: this.state.tableSelectedRowKeys,
      },
    };
  }

  getTableData() {
    return _get(this.props, 'candidatesListData.data.users', []).map(
      candidate => {
        return {
          key: candidate.id,
          name: `${candidate.first_name} ${candidate.last_name}`.trim(),
          state: candidate.state ? candidate.state.name : '',
          profileImage: candidate.profile_image,
          contributed: (candidate.contributed || 0) / 100,
        };
      },
    );
  }

  getTableColumns() {
    return [
      {
        title: 'NAME',
        dataIndex: 'name',
        key: 'name',
        defaultSortOrder: 'ascend',
        render: (text: string, item: any) => {
          return (
            <React.Fragment>
              <Avatar
                isCandidate={item.role === 'candidate'}
                className="dashboard-page__table-cell__name-avatar"
                source={item.profileImage}
                type="tiny"
              />
              <span>{text}</span>
            </React.Fragment>
          );
        },
      },
      {
        title: 'STATE',
        dataIndex: 'state',
        key: 'state',
      },
      {
        title: 'CONTRIBUTED',
        dataIndex: 'contributed',
        key: 'contributed',
        render: (amount: number) => <Currency amount={amount} />,
      },
    ];
  }

  handleTableChange = (pagination: any, filters: any, sorter: any) => {
    // TODO: add sorting logic once the server supports it
    this.fetchCandidates(pagination.current);
  };

  fetchCandidates(page: number = 1) {
    this.props.getCandidatesList({
      page,
      include_political_parties: true,
      limit: CANDIDATES_PAGE_SIZE,
      name: this.state.searchValue,
    });
  }

  handleAddSupportingCandidates = () => {
    const payload = {
      group_candidates_attributes: this.state.tableSelectedRowKeys.map(
        candidateId => ({ candidate_id: parseInt(candidateId) }),
      ),
    };

    this.setState(
      () => ({
        calledService: true,
        tableSelectedRows: [],
        tableSelectedRowKeys: [],
      }),
      () => {
        this.props.updateGroupDetails({
          ...payload,
          groupId: this.props.groupDetails.id,
        });
      },
    );
  };

  handleSearchUser = (inputData: { name: string, value: string }) => {
    this.setState(
      () => ({ searchValue: inputData.value }),
      () => {
        this.fetchCandidates();
      },
    );
  };

  handleTableRowSelection = (
    selectedRowKeys: number[],
    selectedRows: any[],
  ) => {
    this.setState(() => ({
      tableSelectedRows: selectedRows,
      tableSelectedRowKeys: selectedRowKeys,
    }));
  };
}

const mapStateToProps = (state, ownProps) => {
  return {
    ...listCandidates.mapStateToProps(state),
    ...groupUpdate.mapStateToProps(state),
    ...ownProps,
  };
};

const mapDispatchToProps = dispatch => {
  return {
    ...listCandidates.mapDispatchToProps(dispatch),
    ...groupUpdate.mapDispatchToProps(dispatch),
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(ModalAddSupportingCandidates);
