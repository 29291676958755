// @flow
import React from 'react';
import './CodeOfConductPage.scss';

const CodeOfConductPage = () => (
  <div className="code-of-conduct">
    <section className="content">
      <div className="container-fluid fix-width">
        <div className="row justify-content-md-center">
          <div className="col-lg-8 col-md-9 pr-8-md">
            <h1>PRYTANY CODE OF CONDUCT</h1>
            <p>
              We believe that everyone should have the ability to participate in
              our democracy and to share their views on the candidates and the
              political issues facing America without worrying about abusive,
              hostile, or other behavior adverse to the free sharing of ideas.
              In order to protect the experience of people who use Prytany,
              there are some limitations on the type of content and behavior
              that we allow. These limitations are set forth in this Prytany
              Code of Conduct. If you see something on Prytany that violates
              these rules, please report it to us.
            </p>
            <p>
              The Prytany Code of Conduct (along with all incorporated
              policies), Privacy Policy, and User Agreement collectively govern
              a user's access to and use of the Prytany Platform.
            </p>
            <p>
              All individuals accessing or using the Prytany Platform must
              adhere to the policies set forth in the Prytany Code of Conduct.
              Failure to do so may result in Prytany taking one or more of the
              following enforcement actions:
            </p>
            <ul>
              <li>
                we may close, suspend, or limit your access to your Prytany
                Account or the Prytany Platform (such as limiting your ability
                to send messages or to make or receive contributions or
                pledges);
              </li>
              <li>
                we may contact your bank or credit card issuer and warn other
                users, law enforcement, or impacted third parties of your
                actions;
              </li>
              <li>we may update inaccurate information you provided us;</li>
              <li>
                we may refuse to provide you with access to the Prytany Platform
                in the future; and
              </li>
              <li>we may take legal action against you.</li>
            </ul>
            <p>
              If you attempt to evade a permanent suspension by creating new
              accounts, we will suspend your new accounts.
            </p>
            <p>
              Please note that we may need to change the Prytany Code of Conduct
              from time to time and reserve the right to do so. The most current
              version is always available on the prytany.com website.
            </p>

            <h2>Unlawful use</h2>
            <p>
              You may not use the Prytany Platform for any unlawful purposes or
              in furtherance of illegal activities. By using Prytany, you agree
              to comply with all applicable laws governing your online conduct
              and content.
            </p>
            <h2>Abusive Behavior</h2>
            <p>
              We believe in freedom of expression and open dialogue, but that
              means little as an underlying philosophy if voices are silenced
              because people are afraid to speak up. In order to ensure that
              people feel safe expressing diverse opinions and beliefs, we
              prohibit behavior that crosses the line into abuse, including
              behavior that harasses, intimidates, or uses fear to silence
              another user’s voice.
            </p>
            <p>
              Context matters when evaluating for abusive behavior and
              determining appropriate enforcement actions. Factors we may take
              into consideration include, but are not limited to whether:
            </p>
            <ul>
              <li>
                the behavior is targeted at an individual or group of people;
              </li>
              <li>
                the report has been filed by the target of the abuse or a
                bystander;
              </li>
              <li>
                the behavior is newsworthy and in the legitimate public
                interest.
              </li>
            </ul>

            <h2>Harassment and Hateful Speech or Conduct</h2>
            <p>
              <span className="conduct-underline">Harassment:</span>You may not
              engage in the targeted harassment of someone, or incite other
              people to do so. We consider abusive behavior an attempt to
              harass, intimidate, or silence someone else’s voice. While you may
              comment via your Prytany Account, you may not do so if the intent
              of the account is to engage in abusive behavior.
            </p>
            <p>
              <span className="conduct-underline">
                Hateful Speech or Conduct:
              </span>
              You may not promote violence against, threaten, attack or harass
              other people on the basis of race, ethnicity, national origin,
              sexual orientation, gender, gender identity, religious
              affiliation, age, disability, or serious disease. We also do not
              allow accounts whose primary purpose is inciting harm towards
              others on the basis of these categories.
            </p>
            <p>
              Examples of what we do not tolerate include behavior that harasses
              individuals or groups of people with:
            </p>
            <ul>
              <li>violent threats;</li>
              <li>
                wishes for the physical harm, death, or disease of individuals
                or groups;
              </li>
              <li>
                references to mass murder, violent events, or specific means of
                violence in which/with which such groups have been the primary
                targets or victims;
              </li>
              <li>behavior that incites fear about a protected group;</li>
              <li>
                repeated and/or non-consensual slurs, epithets, racist and
                sexist tropes, or other content that degrades someone.
              </li>
            </ul>

            <p>
              <span className="conduct-underline">
                Threats to expose / hack:
              </span>
              You may not threaten to expose someone’s private information. You
              also may not threaten to hack or break into someone’s digital
              information.
            </p>
            <h2>Impersonation</h2>
            <p>
              You may not impersonate individuals, groups, or organizations in a
              manner that is intended to or does mislead, confuse, or deceive
              others.{' '}
            </p>
            <h2>Spam and Security</h2>
            <p>
              We strive to protect Prytany users from technical abuse and spam.
            </p>
            <p>
              To promote a stable and secure environment on Prytany, you may not
              do, or attempt to do, any of the following while accessing or
              using Prytany:
            </p>
            <ul>
              <li>
                Access, tamper with, or use non-public areas of Prytany’s
                computer systems, or the technical delivery systems of Prytany’s
                providers.
              </li>
              <li>
                Probe, scan, or test the vulnerability of any system or network,
                or breach or circumvent any security or authentication measures.
              </li>
              <li>
                Access or search, or attempt to access or search, Prytany by any
                means (automated or otherwise) other than through our currently
                available, published interfaces that are provided by Prytany
                (and only pursuant to the applicable terms and conditions).
              </li>
              <li>
                Forge any TCP/IP packet header or any part of the header
                information in any email or posting, or in any way use Prytany
                to send altered, deceptive, or false source-identifying
                information.
              </li>
              <li>
                Interfere with or disrupt the access of any user, host or
                network, including, without limitation, sending a virus,
                overloading, flooding, spamming, mail-bombing the Prytany
                Platform, or by scripting the creation of content in such a
                manner as to interfere with or create an undue burden on
                Prytany.
              </li>
            </ul>
            <p>
              Any account engaging in the following activities may be
              temporarily locked or subject to permanent suspension. An account
              created to replace a suspended account may be permanently
              suspended.
            </p>
            <ul>
              <li>
                <strong>Malware/Phishing:</strong> You may not publish or link
                to malicious content intended to damage or disrupt another
                person’s browser or computer or to compromise a person’s
                privacy.
              </li>

              <li>
                <strong>Spam:</strong> You may not use the Prytany Platform for
                the purpose of spamming anyone. Spam is generally defined on
                Prytany as bulk or aggressive activity that attempts to
                manipulate or disrupt Prytany or the experience of users on
                Prytany to drive traffic or attention to unrelated accounts,
                products, services, or initiatives. Some of the factors that we
                take into account when determining what conduct is considered to
                be spamming include:
                <ul>
                  <li>
                    if you have sent messages to a large number of accounts in a
                    short time period, particularly by automated means;
                  </li>
                  <li>
                    if your messages consist mainly of links shared without
                    commentary;
                  </li>
                  <li>
                    if a large number of people have blocked you in response to
                    high volumes of untargeted, unsolicited, or duplicative
                    content or engagements from your account;
                  </li>
                  <li>
                    if a large number of spam complaints have been filed against
                    you;
                  </li>
                  <li>
                    if you post duplicative or substantially similar content,
                    replies, or mentions over multiple accounts or multiple
                    duplicate updates on one account, or create duplicate or
                    substantially similar accounts;
                  </li>
                  <li>
                    if you post multiple updates to a trending or popular topic
                    with an intent to subvert or manipulate the topic to drive
                    traffic or attention to unrelated accounts, products,
                    services, or initiatives;
                  </li>
                  <li>
                    if you send large numbers of unsolicited replies or
                    mentions;
                  </li>
                  <li>
                    if you add users to lists in a bulk or aggressive manner;
                  </li>
                  <li>
                    if you are randomly or aggressively engaging with users to
                    drive attention to unrelated accounts, products, services,
                    or initiatives;
                  </li>
                  <li>
                    if you repeatedly post other people’s account information as
                    your own;
                  </li>
                  <li>
                    if you post misleading, deceptive, or malicious links (e.g.,
                    affiliate links, links to malware/clickjacking pages, etc.);
                  </li>
                  <li>
                    if you create fake accounts, account interactions, or
                    impressions; and
                  </li>
                  <li>
                    if you attempt to artificially inflate account interactions.
                  </li>
                </ul>
              </li>
            </ul>
          </div>
        </div>
      </div>
    </section>
  </div>
);

export default CodeOfConductPage;
