//@flow
import React, { Fragment } from 'react';
import type { UserDetails } from '../../services/RoRUsersApiProvider';
import classNames from 'classnames';
import Modal from '../Modal/Modal';
import ContributeDirect from '../ContributeDirect/ContributeDirect';
import ContributePayment from '../ContributePayment/ContributePayment';
import ContributeNewACH from '../ContributeNewACH/ContributeNewACH';
import type { ContributionTypeType } from '../ContributeOptions/ContributeOptions';
import ContributeCommitted from '../ContributeCommitted/ContributeCommitted';
import ContributePledge from '../ContributePledge/ContributePledge';
import ContributionType from '../../constants/ContributionType';
import accountProfile from '../../redux/modules/accountProfile/accountProfile.containers';
import type {
  AccountProfileMapDispatchToProps,
  AccountProfileMapStateToProps,
} from '../../redux/modules/accountProfile/accountProfile.containers';
import { connect } from 'react-redux';
import LoadingSpinner from '../LoadingSpinner/LoadingSpinner';
import { message } from 'antd';
import isBlank from '../../utilities/isBlank';
import ContributeProfile from '../ContributeProfile/ContributeProfile';

type Props = AccountProfileMapStateToProps &
  AccountProfileMapDispatchToProps & {
    className?: string,
    isOpen: boolean,
    onAfterOpen?: Function,
    onCloseRequest: Function,
    contributionType: ContributionTypeType,
    /**
     * This is the receiver userDetails
     */
    userDetails: UserDetails,
    groupId?: number,
    onGoBackRequest: Function,
    onSuccess: Function,
  };

type State = {
  contactInfoPayload: {},
  paymentPayload: { [key: string]: any } | null,
  isAddingNewAch: boolean,
};

class ModalContribute extends React.Component<Props, State> {
  constructor(props: Props) {
    super(props);
    this.state = {
      contactInfoPayload: {},
      paymentPayload: null,
      isAddingNewAch: false,
    };
  }

  componentDidMount() {
    this.props.getAccountProfile();
  }

  componentDidUpdate() {
    if (this.props.accountProfileError) {
      message.error(
        this.props.accountProfileError.localMessage ||
          'An error has occurred and profile data was not retrieved.',
      );
      this.props.onCloseRequest();
    }
  }

  getClassName() {
    return classNames({
      'modal-contribute': true,
      [this.props.className || '']: this.props.className,
    });
  }
  handlePaymentRequest = (payload: any) => {
    this.setState(() => ({ paymentPayload: payload }));
  };

  handleResetPayment = () => {
    this.setState(() => ({ paymentPayload: null }));
  };

  handleAddNewAchRequest = () => {
    this.setState(() => ({ isAddingNewAch: true }));
  };

  handleCancelNewAchRequest = () => {
    this.setState(() => ({ isAddingNewAch: false }));
  };

  renderContent() {
    const {
      accountProfileData,
      accountProfileIsLoading,
      accountProfileError,
      contributionType,
      userDetails,
      groupId,
      onGoBackRequest,
      onCloseRequest,
      onSuccess,
    } = this.props;
    const { paymentPayload, isAddingNewAch } = this.state;

    if (accountProfileIsLoading || !accountProfileData) {
      return (
        <div className="d-flex justify-content-center py-5">
          <LoadingSpinner type="dark" visible={true} />
        </div>
      );
    }

    const { user: profile } = accountProfileData;
    const {
      address1,
      city,
      state,
      zip_code,
      dob,
      telephone,
      occupation,
      employer,
    } = profile;
    if (
      isBlank(address1) ||
      isBlank(city) ||
      isBlank(state) ||
      isBlank(zip_code) ||
      isBlank(dob) ||
      isBlank(occupation) ||
      isBlank(employer)
    ) {
      return (
        <ContributeProfile
          onGoBackRequest={onGoBackRequest}
          onSuccess={() => {
            console.log('profile was updates successfully');
          }}
        />
      );
    }

    if (paymentPayload) {
      return (
        <ContributePayment
          onCloseRequest={this.handleResetPayment}
          contributePayload={{
            ...paymentPayload,
            groupId,
          }}
          onSuccess={onCloseRequest}
        />
      );
    }

    if (isAddingNewAch) {
      return (
        <ContributeNewACH
          onSuccess={onCloseRequest}
          onCancel={this.handleCancelNewAchRequest}
        />
      );
    }

    if (contributionType === ContributionType.DIRECT) {
      return (
        <ContributeDirect
          userDetails={userDetails}
          groupId={groupId}
          onPaymentRequest={this.handlePaymentRequest}
          onAddNewBankAccountRequest={this.handleAddNewAchRequest}
          onPaymentSuccess={onSuccess}
          onGoBackRequest={onGoBackRequest}
        />
      );
    }

    if (contributionType === ContributionType.COMMITTED) {
      return (
        <ContributeCommitted
          userDetails={userDetails}
          groupId={groupId}
          onPaymentRequest={this.handlePaymentRequest}
          onAddNewBankAccountRequest={this.handleAddNewAchRequest}
          onPaymentSuccess={onSuccess}
          onGoBackRequest={onCloseRequest}
        />
      );
    }

    if (contributionType === ContributionType.PLEDGE) {
      return (
        <ContributePledge
          userDetails={userDetails}
          groupId={groupId}
          onPaymentRequest={this.handlePaymentRequest}
          onAddNewBankAccountRequest={this.handleAddNewAchRequest}
          onPaymentSuccess={onSuccess}
          onGoBackRequest={onCloseRequest}
        />
      );
    }
  }

  render() {
    const {
      isOpen,
      onAfterOpen,
      onCloseRequest,
      className,
      ...rest
    } = this.props;

    return (
      <Modal
        contentLabel="Contribute to candidate"
        isOpen={isOpen}
        onAfterOpen={onAfterOpen}
        onCloseRequest={onCloseRequest}
        className={this.getClassName()}
        noPaddingContent
        size="big"
        {...rest}>
        {isOpen && this.renderContent()}
      </Modal>
    );
  }
}

const mapStateToProps = (state, ownProps) => {
  return {
    ...accountProfile.mapStateToProps(state),
    ...ownProps,
  };
};

const mapDispatchToProps = dispatch => {
  return {
    ...accountProfile.mapDispatchToProps(dispatch),
  };
};
export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(ModalContribute);
