import { search, searchReset as reset } from './search.actions';

export const mapStateToProps = state => {
  return {
    searchData: state.search.data,
    searchError: state.search.error,
    searchIsLoading: state.search.isLoading,
  };
};

export const mapDispatchToProps = dispatch => {
  return {
    search(payload) {
      dispatch(search(payload));
    },
    searchReset() {
      dispatch(reset());
    },
  };
};

export default {
  mapStateToProps,
  mapDispatchToProps,
};

export type SearchMapStateToProps = {
  searchData: any,
  searchError: any,
  searchIsLoading: boolean,
};

export type SearchMapDispatchToProps = {
  search: Function,
  searchReset: Function,
};
