// @flow
import React from 'react';
import ContributePledge from '../../components/ContributePledge/ContributePledge';
import MobileContributionsTypePage from './MobileContributionsTypePage';

const MobileContributionsPledgePage = () => {
  return (
    <MobileContributionsTypePage>
      {({ mobileToken, mobileEmail, mobileGroupId, ...rest }) => (
        <ContributePledge
          customPaymentToken={mobileToken}
          customPaymentEmail={mobileEmail}
          groupId={mobileGroupId}
          {...rest}
        />
      )}
    </MobileContributionsTypePage>
  );
};

export default MobileContributionsPledgePage;
