// @flow
import { createAction } from 'redux-actions';
import {
  RACE_FOLLOW_ERROR,
  RACE_FOLLOW_LOADING,
  RACE_FOLLOW_RESET,
  RACE_FOLLOW_SUCCESS,
} from './raceFollow.constants';
import { instance as rorRacesApiProvider } from '../../../services/RoRRacesApiProvider';
import { raceDetailsSetAsFollowed } from '../raceDetails/raceDetails.actions';

export const raceFollowError = createAction(RACE_FOLLOW_ERROR);

export const raceFollowLoading = createAction(RACE_FOLLOW_LOADING);

export const raceFollowSuccess = createAction(RACE_FOLLOW_SUCCESS);

export const raceFollowReset = createAction(RACE_FOLLOW_RESET);

export const followRace = (payload: { raceId: number }) => {
  return async (dispatch: Function) => {
    try {
      dispatch(raceFollowLoading());

      const response = await rorRacesApiProvider.followRace(payload);

      dispatch(raceFollowSuccess(response));
      dispatch(raceDetailsSetAsFollowed());
    } catch (error) {
      dispatch(raceFollowError(error));
    }
  };
};
