// @flow
import { createAction } from 'redux-actions';
import {
  ACCOUNT_PROFILE_UPDATE_ERROR,
  ACCOUNT_PROFILE_UPDATE_LOADING,
  ACCOUNT_PROFILE_UPDATE_RESET,
  ACCOUNT_PROFILE_UPDATE_SUCCESS,
} from './accountProfileUpdate.constants';
import type { PutUserProfileUpdatePayload } from '../../../services/RoRUsersApiProvider';
import { instance as rorUsersApiProvider } from '../../../services/RoRUsersApiProvider';
import { accountProfileSuccess } from '../accountProfile/accountProfile.actions';

export const accountProfileUpdateError = createAction(
  ACCOUNT_PROFILE_UPDATE_ERROR,
);

export const accountProfileUpdateLoading = createAction(
  ACCOUNT_PROFILE_UPDATE_LOADING,
);

export const accountProfileUpdateSuccess = createAction(
  ACCOUNT_PROFILE_UPDATE_SUCCESS,
);

export const accountProfileUpdateReset = createAction(
  ACCOUNT_PROFILE_UPDATE_RESET,
);

export const updateAccountProfile = (payload: PutUserProfileUpdatePayload) => {
  return async (dispatch: Function) => {
    try {
      dispatch(accountProfileUpdateLoading());

      const response = await rorUsersApiProvider.accountProfileUpdate(payload);

      dispatch(accountProfileUpdateSuccess(response));
      dispatch(accountProfileSuccess(response));
    } catch (error) {
      dispatch(accountProfileUpdateError(error));
    }
  };
};
