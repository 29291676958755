// @flow
import React from 'react';
import './FrequentAskedQuestionsPage.scss';

const FrequentAskedQuestionsPage = () => (
  <div className="frequent-asked-questions">
    <section className="content">
      <div className="container-fluid fix-width">
        <div className="row justify-content-md-center">
          <div className="col-lg-8 col-md-9 pr-8-md">
            <h1>Frequently Asked Questions</h1>
            <p>
              Imagine an America where every voter owned his or her politics,
              where every citizen exercised her voice, and where every candidate
              listened hard to your concerns. Expanding the circle of democracy
              and participation is the story of America. Prytany will help you
              write the next chapter.
            </p>
            <p>
              Prytany is revolutionizing the world of campaign and issue
              advocacy finance. As a non-partisan, political fundraising and
              social media platform, it serves as a one stop shop for users and
              candidates alike. Founded by one Democrat, one Republican, and one
              Independent, Prytany empowers citizens to own their politics,
              raise their voices, and hold government accountable.
            </p>
            <span className="frequent-asked-questions__subtitle-wrapper">
              <h5 className="frequent-asked-questions__subtitle">
                Is Prytany free to use?
              </h5>
            </span>
            <p>
              For constituents, Prytany is completely free to use; campaigns and
              other political committees, including PACs, that enroll with
              Prytany, however, will pay a fee to Prytany. Also, when you use
              Prytany to make a contribution to a campaign or PAC and you use a
              credit card or debit card to make the contribution, you will be
              charged the standard 2.9% fee plus $0.30 per transaction charged
              by Prytany’s third-party payment processing services provider. You
              will be notified of this charge before you complete your credit
              card or debit card transaction. This charge will be in addition to
              and will not reduce the amount of your intended contribution. You
              will pay no fee or charge when your contribution is funded through
              an ACH bank account transfer.
            </p>
            <p>Our fee structure is described further below.</p>
            <span className="frequent-asked-questions__subtitle-wrapper">
              <h5 className="frequent-asked-questions__subtitle">
                Do you have to make contributions to use Prytany?
              </h5>
            </span>
            <p>
              No, Prytany is more than a platform for making contributions. It’s
              also a tool for learning about political races and enabling direct
              communication between constituents and those running for or
              holding higher office. Too often. there is a divide between those
              who are voting and those who run. Prytany bridges this divide.
              It’s a platform with a purpose, so that those who seek to lead are
              held accountable by those who vote.
            </p>
            <span className="frequent-asked-questions__subtitle-wrapper">
              <h5 className="frequent-asked-questions__subtitle">
                What is “Prytany Verified” and why might I have to provide my
                Government I.D.?
              </h5>
            </span>
            <p>
              Our primary goal is to give people confidence that the people with
              whom they’re connecting are who they say they are. It is hard to
              know that the people you are talking to on social media platforms
              are truthful about their identity. We have built a system that
              allows us to make certain that users are who they say they are.
              You may be asked to sign into our website and provide your I.D.
              once you have exceeded $1,000 in total contributions in a
              fundraising cycle or if your social activity is above average.
              Once we confirm that your account is verified with a valid ID, we
              do not keep or store any information from your ID with the
              exception of the ID identifier. We do that to ensure that an ID is
              not used twice.
            </p>
            <p>
              Finally, we have developed a proprietary and highly secure
              interface with the Social Security Administration, whereby if a
              user has above average activity on the social platform or is
              making significant contributions, they may be asked to verify
              their social security number and name match. This alone doesn’t
              verify an individual’s identity, but the combination of what we
              are doing goes a long way to eliminate fake accounts, bots, and
              foreign intervention in our electoral process. Prytany makes
              absolutely nothing on verification; there is a cost of $1 to a
              user for social security verification. We choose to leave this at
              cost, because it is important to us that people have as few
              obstacles as possible to use any part of our overall verification
              process.
            </p>
            <p>
              There is no circumstance under which we would sell or allow anyone
              access to this information. Period.
            </p>
            <span className="frequent-asked-questions__subtitle-wrapper">
              <h5 className="frequent-asked-questions__subtitle">
                What are the fees?
              </h5>
            </span>

            <p>
              Our fees are the lowest of any political contribution processor
              servicing candidates of any party and we strive for simplicity
              with our fee structure. We charge campaigns that elect to receive
              contributions made through Prytany’s payment processing service a
              3% fee payable out of your contribution. This means that if you
              make a $1.00 contribution to a candidate, that candidate’s
              campaign will pay us 3 cents.
            </p>

            <p>
              If you use a credit card to make a contribution, our third-party
              payment processing service will charge you a 2.9%+ 30 cent
              processing fee to send your contribution. Prytany is not making a
              cent on this fee. This is “at cost” for using a credit card. If
              you use a direct ACH funds transfer from your bank account, there
              is no processing fee at all.
            </p>

            <p>
              Each campaign or other political committee that enrolls with
              Prytany also agrees to pay to Prytany, within 30 days after each
              anniversary of the date such political committee’s enrollment
              date, an annual top-up fee in an amount equal to $250 minus the
              aggregate amount of fees paid to Prytany by such political
              committee during the applicable 365-day period for contributions
              received by such political committee via the Prytany Platform. The
              fees paid to Prytany by each political committee are in
              consideration of the overall benefits received by such political
              committee from its use of the Prytany Platform and are considered
              by Prytany to be commercially reasonable and fair value for such
              use. Accordingly, the FEC has advised Prytany that it is not
              making an in-kind contribution to any Enrolled Committee under 11
              CFR 100.52(d).
            </p>
            <p>
              If a campaign or PAC chooses not to “claim” their Prytany profile
              by enrolling with Prytany, you will be unable to make
              contributions to that campaign or PAC through the Prytany
              platform; instead, Prytany will treat your attempt to make a
              contribution to an unenrolled campaign or PAC as your intent to
              make such contribution if and when the unenrolled campaign or PAC
              enrolls with Prytany. The amount of such conditioned contribution
              will be withdrawn by Prytany for the benefit of the enrolled
              campaign or PAC from your designated preferred funding source as
              previously provided to Prytany if and when the unenrolled campaign
              or PAC becomes an enrolled campaign or PAC unless you change,
              alter, or cancel your intended contribution.
            </p>
            <span className="frequent-asked-questions__subtitle-wrapper">
              <h5 className="frequent-asked-questions__subtitle">
                What if my contribution is refused or refunded by the campaign
                or PAC?
              </h5>
            </span>
            <p>
              When you make a contribution, the campaign or PAC that received
              your contribution is not required to accept it and may refund it
              even if it was accepted. In that event, if the campaign or PAC has
              not transferred your contribution from its account with Prytany to
              its bank account, Prytany’s third-party payment processing service
              will cancel or reverse your contribution and will return your
              contribution, without reduction for Prytany’s 3% fee, to your
              account with Prytany. If the campaign or PAC has transferred your
              contribution to its bank account before it determined that it is
              unable to accept your contribution or elected to refund your
              contribution, the campaign or PAC will be responsible for
              returning your contribution to you. Prytany’s 3% per transaction
              fee paid by the campaign or PAC is nonrefundable once the campaign
              or PAC transfers your contribution to its bank account. Until the
              campaign or PAC transfers your contribution from its account with
              Prytany to its bank account, a hold may be placed on your
              contribution and Prytany’s 3% fee. Provided the campaign or PAC
              has not transferred your contribution to its bank account, Prytany
              will return any refused or refunded contribution to you through
              the Prytany Platform promptly after the campaign or PAC informs
              Prytany of its election not to accept or to refund your
              contribution.
            </p>
            <span className="frequent-asked-questions__subtitle-wrapper">
              <h5 className="frequent-asked-questions__subtitle">
                Does Prytany sell information on me?
              </h5>
            </span>

            <p>
              No. You will never see advertisements “targeted” at you for
              commercial goods on Prytany. Prytany will not allow independent
              developers to have access to or “buy” your information.
            </p>

            <p>
              We will, however, allow candidates to search and refine
              constituencies around policy interests. Why?
            </p>

            <p>
              Right now, we find that conversations between candidates and
              parties can be meaningless and impersonal. There are often form
              letters that try to put your first name in the text to give you a
              sense of familiarity with subject lines like “Hey” that often do
              not touch on anything you care about and then proceed to ask for
              money. Prytany wants these types of conversations to take on
              greater value and meaning to all involved. One way to do that is
              to match candidates and constituents around issues and engage.
            </p>
            <p>
              You want to share it because you disagree. Prytany isn’t one
              dimensional and neither is politics. Currently, people can make a
              contribution to a specific candidate. On Prytany, you can do that,
              but you can also contribute against a candidate by easily
              supporting an opponent or in some cases all of a particular
              candidate’s opponents. Prytany makes that easy. Voters often don’t
              just vote for a candidate, but also against a particular candidate
              and Prytany is the only platform that is built to bring that to
              campaign fundraising.
            </p>

            <span className="frequent-asked-questions__subtitle-wrapper">
              <h5 className="frequent-asked-questions__subtitle">
                What is a group?
              </h5>
            </span>
            <p>
              In addition to facilitating individual connections, Prytany has a
              unique platform whereby users can build “groups” - both public or
              private-invitation only. These groups can be informal groups of
              constituents who want to discuss specific policy issues or
              candidates, or they can be organized around an event. Those
              attending the event can form a group to facilitate a longer term
              conversation with a candidate or a record of which group members
              contributed to that candidate.
            </p>
            <span className="frequent-asked-questions__subtitle-wrapper">
              <h5 className="frequent-asked-questions__subtitle">
                What is pledging?
              </h5>
            </span>
            <p>
              Pledging is making a contribution commitment to a candidate the
              payment of which is conditional on the action of a special
              interest organization. If there is a special interest group that
              is for or against a position that you care about, then you can
              either amplify or counter their voice by making your contribution
              conditional on a candidate receiving a particular rating from that
              special interest group.
            </p>
            <p>
              Pledges may be made only to campaigns or PACs that enroll with
              Prytany.
            </p>
            <span className="frequent-asked-questions__subtitle-wrapper">
              <h5 className="frequent-asked-questions__subtitle">
                Does Prytany have a political preference?
              </h5>
            </span>
            <p>
              No. Prytany was founded by one Democrat, one Republican, and one
              independent, each with their own political positions on issues.
              They are on opposite sides on many issues — like so many other
              groups of people around the country. One thing they all agree on:
              we are becoming too isolated from and intolerant of differing
              points of view and from those who hold elected office. Prytany
              aims to close that gap and facilitate direct engagement among
              people and with the elected officials and candidates.
            </p>
            <span className="frequent-asked-questions__subtitle-wrapper">
              <h5 className="frequent-asked-questions__subtitle">
                Where did the name Prytany come from?
              </h5>
            </span>
            <p>
              The original Prytany was the rotational executive seat of power in
              the ancient Athenian Senate. The Prytany served as the “president”
              of the legislative body for a term that was 1/10th of the year.
              The Prytany was chosen at random so that no one could predict who
              would come next. This was a unique element of ancient democracy
              that ensured that all voices were heard. Our new Prytany embodies
              that spirit.
            </p>
            <span className="frequent-asked-questions__footer-info">
              3286 M Street NW | Washington, DC 20007{' '}
              <span className="frequent-asked-questions__footer-info-separator">
                ●
              </span>{' '}
              <a href="mailto:info@prytany.com">info@prytany.com</a>{' '}
              <span className="frequent-asked-questions__footer-info-separator">
                ●
              </span>{' '}
              <a href="www.prytany.com">www.prytany.com</a>
            </span>
          </div>
        </div>
      </div>
    </section>
  </div>
);

export default FrequentAskedQuestionsPage;
