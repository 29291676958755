import React from 'react';
import './UserAgreementPage.scss';

class UserAgreementPage extends React.Component {
  render() {
    return (
      <div className="user-agreement">
        <section className="content">
          <div className="container-fluid fix-width">
            <div className="row justify-content-md-center">
              <div className="col-lg-8 col-md-9 pr-8-md">
                <h1>User Agreement</h1>
                <p>Valid as of June 24, 2019</p>
                <p align="justify">
                  This User Agreement ("<strong>Agreement</strong>") is a
                  contract between you and Prytany LLC and applies to your use
                  of Prytany
                  <sup>TM</sup> and any other Prytany features, technologies,
                  and/or functionalities offered on the Prytany website, in the
                  Prytany app or through any other means (the "
                  <strong>Prytany Platform</strong>
                  ") (patent pending). The Prytany Platform is provided to you
                  subject to this Agreement as well as Prytany's Privacy Policy
                  ("
                  <strong>Privacy Policy</strong>") which is available at{' '}
                  <a href="https://www.prytany.com/privacy-policy">
                    https://www.prytany.com/privacy-policy
                  </a>{' '}
                  and is incorporated in this Agreement by this reference.
                </p>
                <ol type="I">
                  <li>
                    <p align="justify">THIRD PARTY AGREEMENTS</p>
                    <ol type="A">
                      <li>
                        <p align="justify">
                          <u>
                            Prytany LLC is Solely Responsible for the Prytany
                            Platform
                          </u>
                        </p>
                      </li>
                    </ol>
                  </li>
                </ol>
                <p align="justify">
                  You acknowledge and agree that this Agreement is between you
                  and Prytany LLC, not with any third party (including, but not
                  limited to, Apple&#174;, Google&#174;, any mobile carrier or
                  any payment processing service), and that Prytany LLC is
                  solely responsible for the Prytany Platform. The Prytany
                  mobile application works on an application linked to a
                  particular device and operating system, such as your mobile
                  device's operating system. Prytany is solely responsible for
                  providing maintenance and support services for the Prytany
                  Platform.
                </p>
                <p align="justify">
                  The Prytany Platform allows Constituents to send contributions
                  to Registered Campaigns, Registered National Party Committees
                  and Political Action Committees that have enrolled with
                  Prytany through their mobile device using Prytany's payment
                  processing service. The Prytany Platform also allows Political
                  Action Committees to send contributions to Registered Campaign
                  and Registered National Party Committees that have enrolled
                  with Prytany. If you use the Prytany Platform on your mobile
                  device, you are responsible for any fees that your wireless
                  service provider charges for SMS, data services, etc. Your
                  wireless service provider is not the provider of the Prytany
                  Platform.
                </p>
                <ol type="I">
                  <ol type="A" start="2">
                    <li>
                      <p align="justify">
                        <u>Covered Third Parties</u>
                      </p>
                    </li>
                  </ol>
                </ol>
                <p align="justify">
                  Your use of the Prytany Platform may be subject to separate
                  agreements you may enter into with your mobile device
                  operating system provider (e.g., Apple&#174;, Google&#174; or
                  Microsoft&#174;), your mobile device manufacturer (e.g.,
                  Apple, Samsung&#174;), your mobile service carrier (e.g.,
                  AT&T&#174; or Verizon&#174;), and other parties involved in
                  providing your mobile device service. Third party operating
                  system providers such as Apple, Google, and Microsoft, your
                  phone or other mobile device manufacturer, your wireless
                  carrier or other network provider, any other product or
                  service provider related to your mobile device service, and
                  Prytany's payment processing services provider are
                  collectively referred to as "
                  <strong>Covered Third Parties</strong>." You agree to comply
                  with all applicable third party terms of agreement when using
                  the Prytany Platform. Prytany LLC is not a party to those
                  agreements and has no responsibility for the products and
                  services provided by third parties. Covered Third Parties have
                  no obligation to provide maintenance or support services for
                  the Prytany Platform.
                </p>
                <p align="justify">
                  Covered Third Parties have no warranty obligations whatsoever
                  with respect to the Prytany Platform. Any claims, losses,
                  liabilities, damages, costs or expenses attributable to any
                  failure of the Prytany Platform to conform to any warranty
                  provided by Prytany LLC, if any, will be Prytany's sole
                  responsibility.
                </p>
                <p align="justify">
                  Prytany LLC, and not any Covered Third Parties, is responsible
                  for addressing any claims relating to your use of the Prytany
                  Platform, including, but not limited to: (i) product liability
                  claims; (ii) any claim that the Prytany Platform fail to
                  conform to any applicable legal or regulatory requirement;
                  (iii) claims arising under consumer protection or similar
                  legislation; and (iv) intellectual property claims. Please see
                  the Contact Us section below for how to contact Prytany LLC.
                </p>
                <ol type="I">
                  <ol type="A" start="3">
                    <li>
                      <p align="justify">
                        <u>Access to the Prytany Platform on Apple Devices</u>
                      </p>
                    </li>
                  </ol>
                </ol>
                <p align="justify">
                  If you are accessing the Prytany Platform on an Apple device,
                  you acknowledge and agree that Apple, and Apple's
                  subsidiaries, are third party beneficiaries of this Agreement,
                  and that Apple will have the right (and will be deemed to have
                  accepted the right) to enforce this Agreement against you as a
                  third party beneficiary. Such rights may also accrue to other
                  handset manufacturers and operating systems which participate
                  in the Prytany Platform.
                </p>
                <ol type="I" start="2">
                  <li>
                    <p align="justify">PRYTANY TERMS OF USE</p>
                    <ol type="A">
                      <li>
                        <p align="justify">
                          <u>Permitted Use of the Prytany Platform.</u>
                        </p>
                        <ol type="A">
                          <ol>
                            <ol type="a">
                              <ol>
                                <ol>
                                  <li>
                                    <p align="justify">
                                      Compliance with Federal Election Laws and
                                      Regulations and Prytany's Code of Conduct.
                                    </p>
                                  </li>
                                </ol>
                              </ol>
                            </ol>
                          </ol>
                        </ol>
                      </li>
                    </ol>
                  </li>
                </ol>
                <p align="justify">
                  Prytany abides by U.S. federal and state campaign laws,
                  including Title 52 of the U.S. Code, Subtitle III ("Federal
                  Campaign Finance") and Title 26 of the U.S. Code, Subtitle H
                  ("Financing of Presidential Election Campaigns"). All users of
                  Prytany agree to abide by such laws as a condition of their
                  use of the Prytany Platform.
                </p>
                <p align="justify">
                  Accordingly, the Prytany Platform allows (a) eligible
                  individuals, as defined by the U.S. Federal Election
                  Commission (the "<strong>FEC</strong>"), to make lawful
                  campaign contributions and pledges to principal campaign
                  committees ("
                  <strong>Registered Campaigns</strong>"), national party
                  committees ("
                  <strong>Registered National Party Committees</strong>") and
                  political action committees, including connected committees
                  (separate segregated funds or SSFs) and unconnected committees
                  (Super PACs, Hybrid PACs, and Leadership PACs) ("PACs"), that
                  are registered with the FEC and that have enrolled with
                  Prytany (hereinafter referred to as "
                  <strong>Enrolled Committees</strong>
                  "), and (b) Enrolled Committees to receive lawful campaign
                  contributions and pledges from individuals who are eligible to
                  make such contributions and pledges under applicable US law,
                  including the regulations of the FEC ("
                  <strong>Constituents</strong>"). The Prytany Platform,
                  however, does not permit Constituents to make contributions to
                  a Registered Campaign, a Registered National Party Committee
                  or a PAC that has not enrolled with Prytany ("
                  <strong>Unenrolled Committees</strong>
                  "). Constituents and Enrolled Committees, using the Prytany
                  Platform, also may form Groups on Prytany, may solicit
                  contributions and pledges from other Constituents, and may
                  engage in messaging among Constituents, Groups and Enrolled
                  Committees. Contributions by Constituents to Enrolled
                  Committees and by PACs to other Enrolled Committees are not
                  directed by Prytany.
                </p>
                <p align="justify">
                  Prytany LLC attempts to verify the identity of all users of
                  the Prytany Platform to ensure that they are who they say they
                  are. It is the user's responsibility, however, to determine if
                  they meet the FEC requirements to be a Constituent, a
                  Registered Campaign, a Registered National Party Committee or
                  a PAC. We do not guarantee the identity of any user of the
                  Prytany Platform, including any Constituent or any Enrolled
                  Committee. We also do not guarantee that a Constituent using
                  the Prytany Platform to make a contribution or pledge can or
                  will make the contribution or that the Enrolled Committee
                  receiving a contribution or pledge made using the Prytany
                  Platform can or will accept the contribution.
                </p>
                <p align="justify">
                  If you have any questions about whether it is permissible for
                  you to make or receive a contribution under federal law,
                  please go to{' '}
                  <a href="https://www.fec.gov/help-candidates-and-committees/candidate-taking-receipts/who-can-and-cannot-contribute/">
                    https://www.fec.gov/help-candidates-and-committees/candidate-taking-receipts/who-can-and-cannot-contribute/
                  </a>{' '}
                  for information about an individual's eligibility to make
                  contributions and pledges to Enrolled Committees or, if you
                  are an Enrolled Committee, about your obligations regarding
                  contributions and pledges made to you.
                </p>
                <p align="justify">
                  In addition to those requirements, Prytany users agree to also
                  abide of the federal campaign contribution limits set by the
                  FEC. Constituents agree not to make, and Enrolled Committees
                  agree to not knowingly accept and keep, contributions in
                  excess of these limits. The applicable limits for federal
                  elections may be found here:{' '}
                  <a href="https://www.fec.gov/help-candidates-and-committees/candidate-taking-receipts/contribution-limits">
                    https://www.fec.gov/help-candidates-and-committees/candidate-taking-receipts/contribution-limits
                  </a>
                  .
                </p>
                <p align="justify">
                  Prytany users agree also to abide by Prytany's Code of Conduct
                  which prohibits abusive behavior, harrassment, hate speech and
                  other unacceptable conduct. Please read Prytany's Code of
                  Conduct, which can be found on our home page at{' '}
                  <a href="https://www.prytany.com/code-of-conduct">
                    https://www.prytany.com/code-of-conduct
                  </a>
                  .
                </p>
                <p align="justify">
                  Violations of these conditions of use will result in the
                  suspension or cancellation of your Prytany Account.
                </p>
                <ol type="I">
                  <ol type="A">
                    <ol type="A">
                      <ol>
                        <ol type="a">
                          <ol>
                            <ol start="2">
                              <li>
                                <p align="justify">
                                  Eligibility to be a Constituent and to use the
                                  Prytany Platform
                                </p>
                              </li>
                            </ol>
                          </ol>
                        </ol>
                      </ol>
                    </ol>
                  </ol>
                </ol>
                <p align="justify">
                  If you are a Constituent, you may use the Prytany Platform to
                  make one-time or recurring campaign contributions and pledges
                  to Enrolled Committees using your mobile device or PC, to
                  solicit contributions and pledges to Registered Campaigns,
                  Registered National Party Committees and PACs from other
                  Constituents, and to share messages and other information with
                  other Constituents, with Enrolled Committees and with Groups
                  that you, other Constituents or Enrolled Committees may form
                  on Prytany. You will not be permitted to make a contribution
                  to an Unenrolled Committee, however. The Prytany Platform will
                  treat your attempt to make a contribution to an Unenrolled
                  Committee as your intent to make such contribution if and when
                  the Unenrolled Committee becomes an Enrolled Committee.
                </p>
                <p align="justify">
                  In order to be eligible to make a campaign contribution using
                  the Prytany Platform, a Constituent must be an individual and
                  make the contribution from his or her own funds. Those funds
                  may not be provided to the Constituent by another person or
                  entity for the purpose of making the contribution. The
                  Constituent must make the contribution with their own personal
                  purchase method and not with a corporate or business purchase
                  method or a method issued to another person. A Constituent
                  must be a U.S citizen or lawfully admitted permanent resident
                  (i.e., a green card holder).
                </p>
                <p align="justify">
                  If for any reason Prytany determines that a Constituent's
                  identity must be verified, Prytany may suspend a Constituent's
                  Prytany Account until that Constituent complies with Prytany's
                  identity verification requirements. Reasons for further
                  identity verification include suspicious activity involving a
                  Constituent's Prytany Account, any indication that a
                  Constituent may not be who he or she states, and any
                  indication that a Constituent may be a foreign national.
                  Prytany also will require further identity verification if an
                  Enrolled Committee or any governmental authority requests that
                  a Constituent's identity be verified. Prytany's identity
                  verification methodology requires the Constituent to log on to
                  Prytany's website and then to use the identification card
                  scanning application provided by Prytany to scan and send to
                  Prytany a picture of the Constituent's driver's license or
                  other government-issued identification, which may include the
                  Constituent's US passport if Prytany so requires.
                </p>
                <p align="justify">
                  <u>
                    Such scanning of identification cards will occur only
                    through Prytany's website and not through its mobile
                    applications.
                  </u>
                  If Prytany determines that the scanned identification card
                  appears to be valid, the Constituent's access to the Prytany
                  Platform will be reinstated. If Prytany determines that the
                  scanned identification card appears not to be valid, however,
                  the Constituent's access to the Prytany Platform will continue
                  to be suspended and the Constituent's Prytany Account may be
                  cancelled.
                </p>
                <p align="justify">
                  If a Constituent is exceedingly active on Prytany, including
                  messaging activity with other users, or using the Prytany
                  Platform to make aggregate contributions to Enrolled
                  Committees in excess of $999.99, that Constituent may be
                  required to submit his or her Social Security Number and to
                  sign and return to Prytany a completed SSA Form 89. Such steps
                  will permit Prytany to further verify such Constituent's
                  eligibility to make federal campaign contributions at minimal
                  cost to the Constituent (currently $1.00) payable to Prytany
                  to reimburse Prytany for the fee charged by the Social
                  Security Administration.
                </p>
                <p align="justify">
                  Prytany will store your personal identification information in
                  accordance with its Privacy Policy and, with respect to social
                  security numbers provided to Prytany by users, in accordance
                  with the requirements of the Social Security Administration.
                  Prytany will not store scanned images of Constituents'
                  identification cards although it will retain the unique
                  identifying barcode or other identifier for future user access
                  verification purposes and to avoid multiple accounts for the
                  same individual. Please see Prytany's Privacy Policy at{' '}
                  <a href="https://www.prytany.com/privacy-policy">
                    https://www.prytany.com/privacy-policy
                  </a>{' '}
                  for further information about Prytany's commitments to
                  protecting your privacy.
                </p>
                <ol type="I">
                  <ol type="A">
                    <ol type="A">
                      <ol>
                        <ol type="a">
                          <ol>
                            <ol start="3">
                              <li>
                                <p align="justify">
                                  Constituents under the age of 18
                                </p>
                              </li>
                            </ol>
                          </ol>
                        </ol>
                      </ol>
                    </ol>
                  </ol>
                </ol>
                <p align="justify">
                  If a Constituent is under the age of 18, additional
                  restrictions apply. If a Constituent is a minor (under the age
                  of 18), then such minor Constituent may make a contribution
                  only if:
                </p>
                <ul>
                  <li>
                    <p align="justify">
                      The decision to contribute is made knowingly and
                      voluntarily by the minor;
                    </p>
                  </li>
                  <li>
                    <p align="justify">
                      The funds, goods or services contributed are owned or
                      controlled by the minor; and
                    </p>
                  </li>
                  <li>
                    <p align="justify">
                      The contribution is not made using funds given to the
                      minor as a gift for the purpose of making the
                      contribution, and is not in any way controlled by another
                      individual.
                    </p>
                  </li>
                </ul>
                <ol type="I">
                  <ol type="A">
                    <ol type="A">
                      <ol>
                        <ol type="a">
                          <ol>
                            <ol start="4">
                              <li>
                                <p align="justify">Registered Campaigns</p>
                              </li>
                            </ol>
                          </ol>
                        </ol>
                      </ol>
                    </ol>
                  </ol>
                </ol>
                <p align="justify">
                  In order for a Registered National Party Committee to enroll
                  with Prytany and to maintain an account on Prytany, you (1)
                  must be the "national political party committee" (as defined
                  by the FEC) and registered with the FEC as the authorized
                  national committee designated by a political party as its
                  national committee to raise contributions and make
                  expenditures for the political party and (2) must accept this
                  Agreement. If you are a Registered National Party Committee
                  and enroll with Prytany (and thereby become an "Enrolled
                  Committee" for purposes of this Agreement), you may use the
                  Prytany Platform to send and receive campaign messages,
                  fundraising solicitations and other information with
                  Constituents and with Groups that you or Constituents may form
                  on Prytany. Please see Part II.I below for a description of
                  the processing and fees for contributions made to Enrolled
                  Committees using the Prytany Platform.
                </p>
                <ol type="I">
                  <ol type="A">
                    <ol type="A">
                      <ol>
                        <ol type="a">
                          <ol>
                            <ol start="5">
                              <li>
                                <p align="justify">
                                  Registered National Party Committees
                                </p>
                              </li>
                            </ol>
                          </ol>
                        </ol>
                      </ol>
                    </ol>
                  </ol>
                </ol>
                <p align="justify">
                  In order for a Registered National Party Committee to enroll
                  with Prytany and to maintain an account on Prytany, you (1)
                  must be the "national political party committee" (as defined
                  by the FEC) and registered with the FEC as the authorized
                  national committee designated by a political party as its
                  national committee to raise contributions and make
                  expenditures for the political party and (2) must accept this
                  Agreement. If you are a Registered National Party Committee
                  and enroll with Prytany (and thereby become an "Enrolled
                  Committee" for purposes of this Agreement), you may use the
                  Prytany Platform to send and receive campaign messages,
                  fundraising solicitations and other information with
                  Constituents and with Groups that you or Constituents may form
                  on Prytany. Please see Part II.I below for a description of
                  the processing and fees for contributions made to Enrolled
                  Committees using the Prytany Platform.
                </p>
                <ol type="I">
                  <ol type="A">
                    <ol type="A">
                      <ol>
                        <ol type="a">
                          <ol>
                            <ol start="6">
                              <li>
                                <p align="justify">
                                  Political Action Committees
                                </p>
                              </li>
                            </ol>
                          </ol>
                        </ol>
                      </ol>
                    </ol>
                  </ol>
                </ol>
                <p align="justify">
                  In order for a Political Action Committee or PAC to enroll
                  with Prytany and to maintain an account on Prytany, you (1)
                  must be a connected committee" (an "SSF") or an "unconnected
                  committee" (as such terms are defined by the FEC) and
                  registered with the FEC as such and (2) must accept this
                  Agreement. If you are a PAC and enroll with Prytany (and
                  thereby become an "Enrolled Committee" for purposes of this
                  Agreement), you may use the Prytany Platform to send and
                  receive messages, fundraising solicitations and other
                  information with Constituents and with Groups that you or
                  Constituents may form on Prytany and may make permitted
                  contributions to other Enrolled Committees. Please see Part
                  II.I below for a description of the processing and fees for
                  contributions made to Enrolled Committees using the Prytany
                  Platform.
                </p>
                <ol type="I">
                  <ol type="A">
                    <ol type="A">
                      <ol>
                        <ol type="a">
                          <ol>
                            <ol start="7">
                              <li>
                                <p align="justify">
                                  Prytany's Payment Processing Services Provider
                                </p>
                              </li>
                            </ol>
                          </ol>
                        </ol>
                      </ol>
                    </ol>
                  </ol>
                </ol>
                <p align="justify">
                  Contributions made and received using the Prytany Platform are
                  processed by Stripe &#169;Stripe. Stripe &#169;Stripe is a
                  licensed provider of money transfer services and all money
                  transmission is provided by Stripe &#169;Stripe, pursuant to
                  Prytany's license with Stripe &#169;Stripe.
                </p>
                <p align="justify">
                  Prytany LLC has no responsibility for the actions of Stripe
                  &#169; Stripe, any Constituent, any Enrolled Committee, or any
                  other person using Prytany.
                </p>
                <ol type="I">
                  <ol type="A">
                    <ol type="A">
                      <ol>
                        <ol type="a">
                          <ol>
                            <ol start="8">
                              <li>
                                <p align="justify">Additional Information</p>
                              </li>
                            </ol>
                          </ol>
                        </ol>
                      </ol>
                    </ol>
                  </ol>
                </ol>
                <p align="justify">
                  See
                  <a href="https://www.prytany.com/privacy-policy/">
                    https://www.prytany.com/privacy-policy/
                  </a>
                  for additional information and for disclosures that Prytany
                  LLC is required to post by certain jurisdictions.
                </p>
                <ol type="I" start="2">
                  <ol type="A">
                    <ol type="A" start="2">
                      <li>
                        <p align="justify">
                          <u>Eligibility and Account Registration</u>
                        </p>
                        <ol>
                          <ol>
                            <li>
                              <p align="justify">Constituents.</p>
                            </li>
                          </ol>
                        </ol>
                      </li>
                    </ol>
                  </ol>
                </ol>
                <p align="justify">
                  To be eligible to use the Prytany Platform to make a campaign
                  contribution or pledge to an Enrolled Committee, you must (1)
                  be an individual who satisfies the FEC requirements for
                  eligibility to make contributions to Registered Campaigns,
                  Registered National Party Committees or the intended PAC
                  recipient, (2) use the Prytany Platform in the United States,
                  and (3) if you are accessing Prytany using your mobile device,
                  use the cellular/wireless telephone number that you own. As
                  further detailed in our Privacy Policy, in order for you to
                  register as a Constituent and to create and use a Prytany
                  Account, Prytany LLC requires that you submit to Prytany
                  certain Personal Information (as defined in its Privacy
                  Policy), including but not limited to your full name, email
                  address, text-enabled cellular/wireless telephone number,
                  mailing address, date of birth, occupation, and employer. In
                  certain cases, Prytany also may require your social security
                  number and scanned images of your driver's license or other
                  government-issued identification card such as your US
                  passport. During the registration process, or when you access
                  the Prytany Platform from a mobile device, your phone's device
                  ID is also stored. You agree that the Personal Information you
                  provide to Prytany LLC upon registration and at all other
                  times will be true, accurate, current and complete, and you
                  agree to maintain and update this Personal Information with
                  Prytany LLC as necessary.
                </p>
                <ol type="I" start="2">
                  <ol type="A">
                    <ol type="A" start="2">
                      <ol>
                        <ol start="2">
                          <li>
                            <p align="justify">Registered Campaigns</p>
                          </li>
                        </ol>
                      </ol>
                    </ol>
                  </ol>
                </ol>
                <p align="justify">
                  For a Registered Campaign to be eligible to receive a campaign
                  contribution from a Constituent and PACs made using the
                  Prytany Platform, a Registered Campaign must be an Enrolled
                  Committee and must (1) be the principal campaign committee of
                  a candidate for federal office that has satisfied the FEC
                  requirements for registration and has obtained an FEC Campaign
                  Identification Number, (2) use the Prytany Platform in the
                  United States, and (3) if accessing Prytany using a mobile
                  device, use a cellular/wireless telephone number that is owned
                  by the Enrolled Committee. As further detailed in our Privacy
                  Policy, in order for a Registered Campaign to enroll with
                  Prytany (and thereby to become an Enrolled Committee for
                  purposes of this Agreement) and to create and use a Prytany
                  Account, Prytany LLC requires that an authorized officer of a
                  Registered Campaign (usually its Treasurer) submit to Prytany
                  certain Personal Information (as defined in its Privacy
                  Policy) , including but not limited to the officer's name,
                  driver's license number and social security number, the
                  Registered Campaign's email address, text-enabled
                  cellular/wireless telephone number, street address or zip
                  code, and its FEC Campaign Identification Number. During the
                  registration process, or when you access the Prytany Platform
                  from a mobile device, the Registered Campaign's phone's device
                  ID is also stored. You agree that the Personal Information you
                  provide to Prytany LLC upon registration and at all other
                  times will be true, accurate, current and complete, and you
                  agree to maintain and update this Personal Information with
                  Prytany LLC as necessary.
                </p>
                <ol type="I" start="2">
                  <ol type="A">
                    <ol type="A" start="2">
                      <ol>
                        <ol start="3">
                          <li>
                            <p align="justify">
                              Registered National Party Committees
                            </p>
                          </li>
                        </ol>
                      </ol>
                    </ol>
                  </ol>
                </ol>
                <p align="justify">
                  For a Registered National Party Committee to be eligible to
                  receive a campaign contribution from a Constituent made using
                  the Prytany Platform, a Registered National Party Committee
                  must be an Enrolled Committee and must (1) be the principal
                  national committee of a political party that has satisfied the
                  FEC requirements for registration and has obtained an FEC
                  Campaign Identification Number, (2) use the Prytany Platform
                  in the United States, and (3) if accessing Prytany using a
                  mobile device, use a cellular/wireless telephone number that
                  is owned by the Enrolled Committee. As further detailed in our
                  Privacy Policy, in order for a Registered National Party
                  Committee to enroll with Prytany (and thereby to become an
                  Enrolled Committee for the purposes of this Agreement) and to
                  create and use a Prytany Account, Prytany LLC requires that an
                  authorized officer of a Registered National Party Committee
                  submit to Prytany certain Personal Information (as defined in
                  its Privacy Policy), including but not limited to the name of
                  the authorized officer of the Registered National Party
                  Committee, his or her driver's license number and social
                  security number, the Registered National Party Committee's
                  email address, text-enabled cellular/wireless telephone
                  number, street address or zip code, and its FEC Campaign
                  Identification Number. During the registration process, or
                  when you access the Prytany Platform from a mobile device, the
                  Registered National Party Committee's phone's device ID is
                  also stored. You agree that the Personal Information you
                  provide to Prytany LLC upon registration and at all other
                  times will be true, accurate, current and complete, and you
                  agree to maintain and update this Personal Information with
                  Prytany LLC as necessary.
                </p>
                <ol type="I" start="2">
                  <ol type="A">
                    <ol type="A" start="2">
                      <ol>
                        <ol start="4">
                          <li>
                            <p align="justify">Political Action Committees</p>
                          </li>
                        </ol>
                      </ol>
                    </ol>
                  </ol>
                </ol>
                <p align="justify">
                  For a PAC to be eligible to receive a campaign contribution
                  from a Constituent made using the Prytany Platform or to make
                  campaign contributions to other Enrolled Committees using the
                  Prytany Platform, a PAC must be an Enrolled Committee and must
                  (1) be an SSF or an unconnected political action committee
                  that has satisfied the FEC requirements for registration and
                  has obtained an FEC identification number, (2) use the Prytany
                  Platform in the United States, and (3) if accessing Prytany
                  using a mobile device, use a cellular/wireless telephone
                  number that is owned by the Enrolled Committee. As further
                  detailed in our Privacy Policy, in order for a PAC to enroll
                  with Prytany (and thereby to become an Enrolled Committee for
                  the purposes of this Agreement) and to create and use a
                  Prytany Account, Prytany LLC requires that an authorized
                  officer of a PAC submit to Prytany certain Personal
                  Information (as defined in its Privacy Policy), including but
                  not limited to the name of the authorized officer of the PAC,
                  his or her driver's license number and social security number,
                  the PAC's email address, text-enabled cellular/wireless
                  telephone number, street address or zip code, and its FEC
                  identification number. During the registration process, or
                  when you access the Prytany Platform from a mobile device, the
                  PAC's phone's device ID is also stored. You agree that the
                  Personal Information you provide to Prytany LLC upon
                  registration and at all other times will be true, accurate,
                  current and complete, and you agree to maintain and update
                  this Personal Information with Prytany LLC as necessary.
                </p>
                <ol type="I" start="2">
                  <ol type="A">
                    <ol type="A" start="2">
                      <li>
                        <p align="justify">
                          <u>Types of Accounts</u>
                        </p>
                      </li>
                    </ol>
                  </ol>
                </ol>
                <p align="justify">
                  We offer two different types of Prytany Accounts: Constituent
                  Accounts, which may be opened only by Constituents, and
                  Committee Accounts, which may be opened only by a Registered
                  Committee, a Registered National Party Committee or a PAC that
                  is an Enrolled Committee. Prytany Accounts may not be used to
                  receive business, commercial or merchant transactions. An
                  individual may have only one Constituent Account and an
                  Enrolled Committee may have only one Committee Account.
                </p>
                <ol type="I" start="2">
                  <ol type="A">
                    <ol type="A" start="2">
                      <ol>
                        <ol>
                          <li>
                            <p align="justify">Constituent Accounts</p>
                          </li>
                        </ol>
                      </ol>
                    </ol>
                  </ol>
                </ol>
                <p align="justify">
                  As explained above, only eligible individuals may open a
                  Constituent Account. By opening a Constituent Account and
                  accepting the terms applicable to Constituents and Constituent
                  Accounts set forth in this Agreement, a Constituent attests
                  that he or she satisfies the FEC's eligibility requirements
                  for making political contributions to Registered Campaigns,
                  Registered National Party Committees and, if applicable, the
                  intended PAC recipient, and that he or she is not establishing
                  a Constituent Account for marketing or other commercial
                  purposes. We may restrict or suspend your access to or cancel
                  your Prytany Account and may place a hold on contributions
                  made by you if you are in breach of this Agreement.
                </p>
                <p align="justify">
                  Constituent Accounts are for use in making campaign
                  contributions and pledges to Enrolled Committees. Constituent
                  Accounts may also be used to communicate with other Prytany
                  Constituents, with Enrolled Committees, and with Groups on
                  Prytany created or selected by you or by Enrolled Committees
                  as described in this Agreement. Some features of Constituent
                  Accounts may be limited based on how you wish to use the
                  Prytany Platform, how much you elect to contribute or pledge
                  and what we know about you. We may require that you provide
                  more information in order to complete a particular campaign
                  contribution or pledge.
                </p>
                <ol type="I" start="2">
                  <ol type="A">
                    <ol type="A" start="2">
                      <ol>
                        <ol start="2">
                          <li>
                            <p align="justify">Campaign Accounts</p>
                          </li>
                        </ol>
                      </ol>
                    </ol>
                  </ol>
                </ol>
                <p align="justify">
                  As explained above, only Registered Campaigns, Registered
                  National Party Committees and PACs that are Enrolled
                  Committees may open a Committee Account. By opening a
                  Committee Account and accepting the terms set forth in this
                  Agreement applicable to Registered Campaigns, Registered
                  National Party Committees and PACs, as applicable, including
                  but not limited to the Rules and Restrictions for Committee
                  Accounts set forth below, a Registered Campaign, a Registered
                  National Party Committee or a PAC attests that it is the
                  principal campaign committee of a candidate for federal office
                  or is the national committee of a political party or is a
                  validly existing political action committee, as applicable,
                  and that it is registered as such with the FEC. We may reverse
                  or place a hold on contributions made to you if you are in
                  breach of this Agreement.
                </p>
                <ol type="I" start="2">
                  <ol type="A">
                    <ol type="A" start="2">
                      <li>
                        <p align="justify">
                          <u>Identity Authentication</u>
                        </p>
                      </li>
                    </ol>
                  </ol>
                </ol>
                <p align="justify">
                  You hereby authorize Prytany, directly or through third
                  parties, to make any inquiries we consider necessary to
                  validate your identity and/or authenticate your account
                  information and, for Committee Accounts, your FEC
                  identification number. This may include asking you for further
                  information and/or documentation about your account usage or
                  identity, or requiring you to take steps to confirm ownership
                  of your email address, wireless/cellular telephone number or
                  financial accounts, and verifying your information against
                  third party databases or through other sources. This process
                  is for internal verification purposes.
                </p>
                <ol type="I" start="2">
                  <ol type="A">
                    <ol type="A" start="2">
                      <li>
                        <p align="justify">
                          <u>Contribution History</u>
                        </p>
                      </li>
                    </ol>
                  </ol>
                </ol>
                <p align="justify">
                  You have the right to receive a statement showing all
                  contribution and pledge activity in your Prytany Account. You
                  may view your account by logging into your Prytany Account.
                </p>
                <ol type="I" start="2">
                  <ol type="A">
                    <ol type="A" start="2">
                      <li>
                        <p align="justify">
                          <u>Making Contributions and Pledges</u>
                        </p>
                        <ol>
                          <ol>
                            <li>
                              <p align="justify">
                                You Control Your Constituent Account
                              </p>
                            </li>
                          </ol>
                        </ol>
                      </li>
                    </ol>
                  </ol>
                </ol>
                <p align="justify">
                  After you have registered as a Constituent, you will be able
                  to make campaign contributions and pledges to Enrolled
                  Committees using the Prytany Platform in accordance with this
                  Agreement. Each Constituent may have only one Constituent
                  Account. You control your Constituent Account and have sole
                  power to direct your campaign contributions and pledges
                  provided you are in compliance with this Agreement.
                </p>
                <ol type="I" start="2">
                  <ol type="A">
                    <ol type="A" start="2">
                      <ol>
                        <ol start="2">
                          <li>
                            <p align="justify">
                              Types of Contributions and Pledges; Limits under
                              Federal Election Law
                            </p>
                          </li>
                        </ol>
                      </ol>
                    </ol>
                  </ol>
                </ol>
                <p align="justify">
                  A Constituent may use the Prytany Platform (i) to make a
                  one-time campaign contribution in a specified amount to an
                  Enrolled Committee, (ii) to make a commitment to an Enrolled
                  Committee that he or she will make regular, periodic
                  contributions in specified amounts and at specified times,
                  which contribution commitments may be conditioned on the
                  candidate associated with the Enrolled Committee maintaining
                  specified ratings and (iii) to pledge to an Enrolled Committee
                  that he or she intends to make one or more contributions to
                  it, which contributions may be conditioned on the candidate
                  associated with the Registered Campaign maintaining specified
                  ratings. The aggregate amount that a Constituent may
                  contribute to a particular Enrolled Committee is limited by
                  federal election law, presently $2,800 to each Registered
                  Campaign for each election, $35,500 to each Registered
                  National Party Committee during each calendar year, and $5,000
                  to PACs that are SSFs or unconnected PACs (unlimited for Super
                  PACs) during each calendar year. In addition, Leadership PACs
                  are limited to $5,000 in contributions to a Registered
                  Campaign per election. Pledges count as contributions made for
                  purposes of such limits (e.g., an outstanding $500 pledge to a
                  Registered Campaign reduces the potential amount of other
                  contributions that the pledgor may make to such Registered
                  Campaign during the election from $2,800 to $2,300).
                </p>
                <ol type="i">
                  <li>
                    <p align="justify">One-Time Contributions</p>
                  </li>
                </ol>
                <p align="justify">
                  For the purposes of this Agreement a one-time contribution is
                  defined as a one-time transfer of money from a Constituent to
                  an Enrolled Committee. A Constituent will not be permitted to
                  make one-time contributions to Unenrolled Committees using the
                  Prytany Platform; instead, the Prytany Platform will treat a
                  Constituent's attempt to make a contribution to an Unenrolled
                  Committee as such Constituent's intent to make such
                  contribution if and when the Unenrolled Committee becomes an
                  Enrolled Committee.
                </p>
                <ol type="i" start="2">
                  <li>
                    <p align="justify">Periodic Contributions</p>
                  </li>
                </ol>
                <p align="justify">
                  For the purposes of this Agreement, a periodic contribution is
                  defined as a commitment to transfer money from a Constituent
                  to an Enrolled Committee in the future at specific times and
                  in specific amounts (which may vary) pursuant to a
                  predetermined schedule. Periodic contributions will be
                  withdrawn by Prytany for the benefit of the Enrolled Committee
                  from a Constituent's designated preferred funding source as
                  previously provided to Prytany in accordance with the
                  specified schedule unless the Constituent changes, alters, or
                  cancels the scheduled contribution or the Enrolled Committee
                  withdraws or otherwise ceases to be active. A periodic
                  contribution may be conditioned on the candidate associated
                  with a Registered Campaign maintaining an active campaign.
                </p>
                <p align="justify">
                  As stated above in Part II.F.2.i, the Prytany Platform will
                  treat a Constituent's attempt to make a one-time contribution
                  to an Unenrolled Committee using the Prytany Platform as such
                  Constituent's conditional commitment to make such contribution
                  if and when the Unenrolled Committee becomes an Enrolled
                  Committee. The amount of such conditioned contribution will be
                  withdrawn by Prytany for the benefit of the Enrolled Committee
                  from a Constituent's designated preferred funding source as
                  previously provided to Prytany if and when the Unenrolled
                  Committee becomes an Enrolled Committee unless the Constituent
                  changes, alters, or cancels the intended contribution.
                </p>
                <ol type="i" start="3">
                  <li>
                    <p align="justify">Pledges</p>
                  </li>
                </ol>
                <p align="justify">
                  Pledges may be made only to Registered Campaigns that are
                  Enrolled Committees.
                </p>
                <p align="justify">
                  A pledged contribution is a promise of a future contribution
                  of money. Pledges may be conditioned on the candidate
                  associated with a Registered Campaign maintaining or achieving
                  a specified rating by a particular issue advocacy organization
                  within a specific period of time. When the candidate
                  associated with a Registered Campaign successfully maintains
                  or achieves the specified rating, the Constituent will be
                  notified and given the opportunity to make the pledged
                  contribution. Pledges must be made for a specific amount, with
                  every intention of the Constituent to pay the stated amount in
                  its entirety. All primary election pledges must be made on or
                  before the day of the primary election. Pledges are built into
                  a contributor's aggregate contribution total.
                </p>
                <ol type="I" start="2">
                  <ol type="A">
                    <ol start="5">
                      <ol>
                        <ol start="3">
                          <li>
                            <p align="justify">Conditioning Your Pledges</p>
                          </li>
                        </ol>
                      </ol>
                    </ol>
                  </ol>
                </ol>
                <p align="justify">
                  Constituents may condition their pledges to a Registered
                  Campaign by requiring that the candidate associated with a
                  Registered Campaign maintain or obtain at least a designated
                  rating by a recognized third-party organization or special
                  interest group that routinely and publicly rates candidates
                  and elected officials with respect to their views and voting
                  history on specific political issues or causes. Profiles of
                  candidates appearing on Prytany may reflect some of the
                  published ratings received by such candidates for the most
                  recent election cycle for which such ratings are available but
                  not all candidates will have ratings from all rating
                  organizations and groups. Prytany will endeavor to update such
                  published ratings when and as they become available. If a
                  Constituent requires a candidate to maintain or obtain a
                  certain rating as a condition to such candidate's Registered
                  Campaign receiving a future contribution, Prytany will
                  determine whether that candidate has or has not satisfied that
                  condition and notify the Constituent of such determination
                  before processing the conditioned contribution. Prytany,
                  however, is not responsible for the accuracy, completeness,
                  reliability, timeliness or frequency of any rating of a
                  candidate. A Constituent may change the required rating or
                  cancel a contribution commitment or pledge at any time before
                  the conditioned contribution is made. Changing a rating
                  condition or cancelling a contribution commitment or pledge
                  may affect the Constituent's rating, however. In absence of a
                  preexisting rating for a particular candidate, Prytany will
                  assign such candidate a C rating on a letter grade scale or a
                  numerical rating of a 50 on a 1 to 100 number scale.
                </p>
                <ol type="I" start="2">
                  <ol type="A">
                    <ol start="5">
                      <ol>
                        <ol start="3">
                          <li>
                            <p align="justify">
                              Pledge Limit; Constituent Ratings
                            </p>
                          </li>
                        </ol>
                      </ol>
                    </ol>
                  </ol>
                </ol>
                <p align="justify">
                  We may, at our discretion, impose limits on the number of
                  pledges a Constituent makes using the Prytany Platform as well
                  as the amount of those pledges. If we have authenticated your
                  identity and your designated preferred funding source and you
                  have honored your commitments and pledges, we may increase
                  your pledge limit. If you fail to honor your contribution
                  commitments and pledges, we may restrict your ability to make
                  contribution commitments and pledges using the Prytany
                  Platform. Prytany will assign to each Constituent a rating
                  based on such Constituent's activity on the Prytany Platform.
                  These limits and ratings may change from time to time in
                  Prytany's sole discretion.
                </p>
                <ol type="A" start="7">
                  <li>
                    <p align="justify">
                      <u>Sharing Your Contribution History</u>
                    </p>
                  </li>
                </ol>
                <p align="justify">
                  Your contribution history will not be shared with others on
                  your Prytany social activity feed unless you choose to share.
                  If you elect to share a contribution and your default audience
                  setting is public, that setting will control who sees the
                  contribution. If you make a contribution and your default
                  audience setting is private, the contribution will not be
                  shared with anyone through your Prytany social activity feed,
                  although the Registered Campaign or Registered National Party
                  Committee receiving your contribution will list your
                  contribution in its public Prytany Account and in its required
                  FEC reporting in accordance with federal election law. You can
                  change your default audience setting in the privacy section of
                  your Prytany Account settings. You can change the audience
                  setting for any contribution you have already made by
                  selecting it from your Prytany social activity feed and
                  changing the audience setting.
                </p>
                <ol type="A" start="8">
                  <li>
                    <p align="justify">
                      <u>Contribution Authorizations and Processing</u>
                    </p>
                  </li>
                </ol>
                <p align="justify">
                  When you make a contribution to an Enrolled Committee, you are
                  providing Prytany or its payment processing services provider
                  with an authorization to process your contribution using your
                  designated preferred funding source. Prytany's third-party
                  payment processing services provider will initiate a transfer
                  of funds from your designated preferred funding source to your
                  Constituent Account. Your contribution will be held as pending
                  in your Constituent Account until Prytany processes it, which
                  may not occur immediately depending on the Constituent, the
                  Enrolled Committee, or the amount of the contribution. In such
                  cases, your authorization will remain valid for up to thirty
                  (30) days although Prytany either will validate or reject your
                  contribution within ten (10) days after your Constituent
                  Account was credited with the amount of your intended
                  contribution. Once your contribution is validated by Prytany,
                  Prytany's third-party payment processing services provider
                  will initiate a transfer of funds from your Constituent
                  Account to the Committee Account of the Enrolled Committee and
                  then ultimately to the bank account registered with Prytany by
                  the Enrolled Committee. For contribution commitments and
                  pledges, we may request an authorization for what you estimate
                  to be your entire contribution amount. A Constituent may
                  cancel a contribution commitment or pledge at any time before
                  the scheduled contribution is actually transferred to the
                  Enrolled Committee's Committee Account. Once a contribution is
                  transferred to an Enrolled Committee's Committee Account, the
                  contribution cannot be reversed by the Constituent using the
                  Prytany Platform. A Constituent must contact the Enrolled
                  Committee regarding any requested refund of a contribution,
                  which is in the sole discretion of the Enrolled Committee.
                </p>
                <ol>
                  <li>
                    <p align="justify">Funding Sources</p>
                  </li>
                </ol>
                <p align="justify">
                  You may make contributions using the Prytany Platform with a
                  bank account, debit card or credit card, and may designated
                  one of these to be your preferred funding source. When you
                  make a contribution to an Enrolled Committee through the
                  Prytany Platform, Prytany or its third-party payment
                  processing services provider will withdraw funds from your
                  designated preferred funding source to make the contribution
                  in its entirety. When you use a credit card or debit card to
                  make a contribution, you will be charged the standard 2.9% fee
                  plus $0.30 per transaction charged by Prytany's third-party
                  payment processing services provider. You will be notified of
                  this charge before you complete your credit card or debit card
                  transaction. This charge will be in addition to and will not
                  reduce the amount of your intended contribution. You will pay
                  no fee or charge when your contribution is funded through an
                  ACH bank account transfer.
                </p>
                <ol start="2">
                  <li>
                    <p align="justify">Funding Source Limitations</p>
                  </li>
                </ol>
                <p align="justify">
                  In order to manage risk, Prytany may limit the funding sources
                  available for your use to fund any particular contribution.
                  For example, your funding sources for a particular
                  contribution may be limited to your bank account.
                </p>
                <p align="justify">
                  Please note that the various funding sources have different
                  dispute resolution rights and procedures in the event your
                  transaction turns out to be unsatisfactory. Your dispute
                  resolution rights are determined by the funding source used to
                  fund your transaction.
                </p>
                <ol start="3">
                  <li>
                    <p align="justify">Bank Transfers</p>
                  </li>
                </ol>
                <p align="justify">
                  When your bank account is used as your funding source, you are
                  requesting that Prytany or its payment processing services
                  provider initiate on your behalf an electronic transfer from
                  your bank account. For these transactions, Prytany or its
                  payment processing services provider will make electronic
                  transfers (via the Automated Clearing House ("
                  <strong>ACH</strong>") of NACHA - The Electronic Payment
                  Association ("<strong>NACHA</strong>")) from your bank account
                  in the amount you specify. You agree that such requests
                  constitute your authorization to Prytany or its payment
                  processing services provider to make the ACH transfer, and
                  once you have provided your authorization for the transfer,
                  you will not be able to cancel the electronic transfer and
                  Prytany or its payment processing services provider may
                  resubmit any ACH debit you authorized that is returned for
                  insufficient or uncollected funds, except as otherwise
                  provided by NACHA's ACH rules (collectively, the "
                  <strong>ACH Rules</strong>"), or applicable law.
                </p>
                <ol start="4">
                  <li>
                    <p align="justify">Debit Card Processing</p>
                  </li>
                </ol>
                <p align="justify">
                  Prytany or its payment processing services provider will
                  process your debit card-funded transactions through either the
                  debit card's ATM debit network or the Visa/MasterCard network,
                  as Prytany or its payment processing services provider may
                  elect at its discretion.
                </p>
                <ol start="5">
                  <li>
                    <p align="justify">Credit Card Information</p>
                  </li>
                </ol>
                <p align="justify">
                  If your credit card account number changes or your credit card
                  expiration date changes, we may acquire that information from
                  our payment processing services provider and update your
                  account accordingly.
                </p>
                <ol start="6">
                  <li>
                    <p align="justify">Refused and Refunded Transactions</p>
                  </li>
                </ol>
                <p align="justify">
                  When you make a contribution, the Enrolled Committee that
                  received your contribution is not required to accept it and
                  may refund it even if it was accepted. You agree that you will
                  not hold Prytany LLC liable for any damages resulting from a
                  decision by an Enrolled Committee not to accept or to refund
                  your contribution made through the Prytany Platform.
                </p>
                <p align="justify">
                  If an Enrolled Committee to which you make a contribution
                  through the Prytany Platform does not accept your contribution
                  or elects to refund your contribution, then if the Enrolled
                  Committee has not transferred your contribution from its
                  Committee Account to its bank account, Prytany's third-party
                  payment processing services provider will cancel or reverse
                  your contribution and will return your contribution, without
                  reduction for Prytany's 3% per transaction processing fee, to
                  your Constituent Account. If the Enrolled Committee has
                  transferred your contribution from its Committee Account to
                  its bank account before it determined that it is unable to
                  accept your contribution or elected to refund your
                  contribution, the Enrolled Committee will be responsible for
                  returning your contribution to you. Prytany's 3% per
                  transaction fee paid by the Enrolled Committee is
                  nonrefundable once the Enrolled Committee transfers your
                  contribution from its Committee Account to its bank account.
                </p>
                <p align="justify">
                  Until the Enrolled Committee transfers your contribution from
                  its Committee Account to its bank account, a hold may be
                  placed on your contribution and Prytany's 3% per transaction
                  fee. Provided the Enrolled Committee has not transferred your
                  contribution from its Committee Account to its bank account,
                  we will return any refused or refunded contribution to you
                  through the Prytany Platform promptly after the Enrolled
                  Committee informs Prytany of its election not to accept or to
                  refund your contribution.
                </p>
                <ol type="A" start="9">
                  <li>
                    <p align="justify">
                      <u>
                        Fees for Making Contributions and Using the Prytany
                        Platform
                      </u>
                    </p>
                  </li>
                </ol>
                <p align="justify">
                  When a credit card or debit card is used to make a
                  contribution to an Enrolled Committee, a Constituent will be
                  charged the standard 2.9% fee plus $0.30 per transaction
                  charged by Prytany's third-party payment processing services
                  provider. This charge will be in addition to and will not
                  reduce the amount of your intended contribution. There is no
                  fee or charge payable by a Constituent when his or her
                  contribution is funded through an ACH bank account transfer.
                  There also is no fee or charge payable by a Constituent when
                  his or her contribution is transferred to an Enrolled
                  Committee; instead, the Enrolled Committee receiving such
                  contribution will pay Prytany a fee equal to 3% of the
                  transferred amount. If Prytany reviews a Constituent's
                  eligibility to make federal campaign contributions by checking
                  with the Social Security Administration that the social
                  security number given to Prytany by such Constituent is
                  consistent with the records maintained by the Social Security
                  Administration, the Constituent will be charged by Prytany for
                  the fee (currently $1.00) paid by Prytany to the Social
                  Security Administration for such search.
                </p>
                <p align="justify">
                  When funds for a Constituent's contribution are transferred
                  from a Constituent Account to an Enrolled Committee's
                  Committee Account via the Prytany Platform, the Enrolled
                  Committee receiving such contribution will pay Prytany a fee
                  equal to 3% of the transferred amount. Each Enrolled Committee
                  also agrees to pay to Prytany, within 30 days after each
                  anniversary of the date such Registered Campaign, Registered
                  National Party Committee or PAC accepted this Agreement, an
                  annual top-up fee in an amount equal to $250 minus the
                  aggregate amount of fees paid to Prytany by such Enrolled
                  Committee during the applicable 365-day period for
                  contributions received by such Enrolled Committee from
                  Constituents using the Prytany Platform. The fees paid to
                  Prytany by each Enrolled Committee are in consideration of the
                  overall benefits received by such Enrolled Committee from its
                  use of the Prytany Platform and are considered by Prytany to
                  be commercially reasonable and fair value for such use.
                  Accordingly, the FEC has advised Prytany that it is not making
                  an in-kind contribution to any Enrolled Committee under 11 CFR
                  100.52(d).
                </p>
                <p align="justify">
                  These fees may change from time to time in Prytany's sole
                  discretion. You also may be subject to third party fees, such
                  as insufficient fund fees, reversal fees, or ACH insufficient
                  fund fees that a bank may charge if a contribution is
                  rejected.
                </p>
                <ol type="A" start="10">
                  <li>
                    <p align="justify">
                      <u>Payment Investigation</u>
                    </p>
                  </li>
                </ol>
                <p align="justify">
                  Payment investigation is a process by which Prytany or its
                  payment processing services provider reviews certain
                  potentially high-risk transactions. If a contribution is
                  subject to payment investigation, Prytany or its payment
                  processing services provider will place a hold on the
                  contribution and may provide notice to the Enrolled Committee.
                  Prytany or its payment processing services provider will
                  conduct a review and either clear or cancel the contribution.
                  If the contribution is cleared, Prytany or its payment
                  processing services provider will provide notice to the
                  Enrolled Committee. Otherwise, Prytany or its payment
                  processing services provider will cancel the contribution and
                  the funds will be returned to the Constituent. Prytany will
                  provide notice to the Constituent by email and/or in the
                  account history tab of his or her Prytany Account if the
                  contribution is canceled.
                </p>
                <ol type="A" start="11">
                  <li>
                    <p align="justify">
                      <u>Item Hold</u>
                    </p>
                  </li>
                </ol>
                <p align="justify">
                  Prytany or its payment processing services provider, in its
                  sole discretion, may place a hold on a contribution you make
                  using the Prytany Platform when Prytany or its payment
                  processing services provider believes there may be a high
                  level of risk associated with the transaction. If Prytany or
                  its payment processing services provider places a hold on your
                  contribution, it will show as "pending" in your Prytany
                  Account. Unless we receive a dispute, claim, chargeback, or
                  reversal on the contribution subject to the hold, Prytany or
                  its payment processing services provider will release the
                  payment hold if Prytany or its payment processing services
                  provider receives acceptable confirmation, is able to confirm
                  proper delivery, or otherwise completes its investigation.
                </p>
                <ol type="A" start="12">
                  <li>
                    <p align="justify">
                      <u>Risk of Reversals</u>
                    </p>
                  </li>
                </ol>
                <p align="justify">
                  When an Enrolled Committee receives a contribution via the
                  Prytany Platform, it is liable to the Constituent for the full
                  amount of the contribution plus any fees payable by the
                  Enrolled Committee if the contribution is later invalidated or
                  refunded for any reason. This means that, in addition to any
                  other liability, an Enrolled Committee will be responsible for
                  the amount of the contribution, plus applicable fees, if there
                  is a reversal or refund of the contribution. Each Enrolled
                  Committee agrees to allow Prytany to recover any amounts due
                  to Prytany from such Enrolled Committee. Prytany will contact
                  the Enrolled Committee to recover the funds from the Enrolled
                  Committee, and may take other legal actions to collect the
                  amount due, to the extent allowed by applicable law.
                </p>
                <p align="justify">
                  If a Constituent disputes a contribution made using the
                  Prytany Platform or files a claim for a chargeback, the debit
                  or credit card issuer or the originating bank, not Prytany,
                  will determine whether the dispute is valid and to whom
                  payment is due.
                </p>
                <ol type="A" start="13">
                  <li>
                    <p align="justify">
                      <u>Account Reviews</u>
                    </p>
                  </li>
                </ol>
                <p align="justify">
                  We review account and contribution activity at various times,
                  including when a Constituent initiates a transfer of funds.
                  This review checks for, among other things, suspicious or
                  illegal activity, and whether your account activity complies
                  with this Agreement.
                </p>
                <p align="justify">Reviews may result in:</p>
                <ol type="a" start="9">
                  <ol type="a">
                    <ol type="a">
                      <ol type="i" />
                    </ol>
                  </ol>
                </ol>
                <ul>
                  <li>
                    <p align="justify">
                      delayed, blocked or cancelled contributions;
                    </p>
                  </li>
                  <li>
                    <p align="justify">account suspension or termination;</p>
                  </li>
                  <li>
                    <p align="justify">
                      funds being seized to comply with a court order, warrant
                      or other legal process; and/or
                    </p>
                  </li>
                  <li>
                    <p align="justify">
                      funds an Enrolled Committee previously received being
                      returned by the Enrolled Committee.
                    </p>
                  </li>
                </ul>
                <p align="justify">
                  Among other reasons, we may take the above actions if a
                  Constituent knowingly or unknowingly made a contribution from
                  a stolen card or compromised bank account.
                </p>
                <p align="justify">
                  In connection with our review process, you may be required to
                  provide us with additional information and/or documentation to
                  verify your identity. We may limit or suspend your access to
                  your Prytany Account until verification is completed.
                </p>
                <ol type="A" start="14">
                  <li>
                    <p align="justify">
                      <u>Closing Your Prytany Account</u>
                    </p>
                  </li>
                </ol>
                <ol>
                  <li>
                    <p align="justify">How to Close Your Prytany Account</p>
                  </li>
                </ol>
                <p align="justify">
                  As long as there is no pending or in progress transactions,
                  you may close your Prytany Account at any time. You may close
                  your Prytany Account by logging into your account from a web
                  browser, clicking on the "Settings" tab, clicking on the
                  "Cancel my Prytany Account" link, and then following the
                  instructions.
                </p>
                <ol start="2">
                  <li>
                    <p align="justify">
                      Limitations on Closing Your Prytany Account
                    </p>
                  </li>
                </ol>
                <p align="justify">
                  You may not close your Prytany Account to evade a payment
                  investigation. If you attempt to close your Prytany Account
                  while we are conducting an investigation, we may suspend your
                  Prytany Account to protect Prytany or a third party against
                  the risk of reversals, chargebacks, claims, fees, fines,
                  penalties and other liability. You will remain liable for all
                  obligations related to your Prytany Account even after your
                  Prytany Account is closed.
                </p>
                <ol type="A" start="15">
                  <li>
                    <p align="justify">
                      <u>Termination</u>
                    </p>
                  </li>
                </ol>
                <p align="justify">
                  Upon termination of this Agreement for any reason, we have the
                  right to prohibit your access to the Prytany Platform,
                  including without limitation by deactivating your username and
                  password, and cancelling your Prytany Account, and to refuse
                  future access to the Prytany Platform by you.
                </p>
                <ol type="A" start="16">
                  <li>
                    <p align="justify">
                      <u>
                        Additional Rules and Restrictions for Committee Accounts
                      </u>
                    </p>
                  </li>
                </ol>
                <p align="justify">
                  The following Rules and Restrictions for Committee Accounts
                  apply to use by an Enrolled Committee, whether a Registered
                  Campaign, a Registered National Party Committee or a PAC, of
                  its Prytany Account under this Agreement.
                </p>
                <ol type="I" start="2">
                  <ol type="A">
                    <ol start="5">
                      <ol>
                        <ol start="3">
                          <ol>
                            <ol>
                              <li>
                                <p align="justify">Receiving Money</p>
                              </li>
                            </ol>
                          </ol>
                        </ol>
                      </ol>
                    </ol>
                  </ol>
                </ol>
                <p align="justify">
                  As provided by applicable law and regulation, there are limits
                  to the amount of contributions you can receive from a
                  particular Constituent, which also apply to contributions a
                  Constituent may send using the Prytany Platform.
                </p>
                <ol type="I" start="2">
                  <ol type="A">
                    <ol start="5">
                      <ol>
                        <ol start="3">
                          <ol>
                            <ol start="2">
                              <li>
                                <p align="justify">
                                  Representations by Registered Campaigns,
                                  Registered National Party Committees and PACs
                                  that are Enrolled Committees
                                </p>
                              </li>
                            </ol>
                          </ol>
                        </ol>
                      </ol>
                    </ol>
                  </ol>
                </ol>
                <p align="justify">
                  If you are an Enrolled Committee, you represent that you are
                  duly registered as a political campaign, national party
                  committee or a connected committee (SSF) or an unconnected
                  political action committee with the FEC and that your
                  employees, officers, representatives, and other agents
                  accessing the Prytany Platform are duly authorized to access
                  the Prytany Platform and to legally bind you to this Agreement
                  and all transactions conducted under your username and
                  password.
                </p>
                <ol type="I" start="2">
                  <ol type="A">
                    <ol start="5">
                      <ol>
                        <ol start="3">
                          <ol>
                            <ol start="3">
                              <li>
                                <p align="justify">
                                  Authority of Officers and Employees
                                </p>
                              </li>
                            </ol>
                          </ol>
                        </ol>
                      </ol>
                    </ol>
                  </ol>
                </ol>
                <p align="justify">
                  If you are an Enrolled Committee, you agree that all officers,
                  employees, agents, representatives and others having access to
                  the username and/or password shall be vested by you with the
                  authority to use the Prytany Platform and to legally bind you.
                  You shall be responsible for all actions by current and former
                  officers, employees, agents, representatives and others,
                  regardless of whether authorized by you, that access the
                  Prytany Platform using your username and password. YOU ASSUME
                  THE ENTIRE RISK FOR THE FRAUDULENT, UNAUTHORIZED OR OTHERWISE
                  IMPROPER USE OF YOUR PASSWORD. PRYTANY SHALL BE ENTITLED TO
                  RELY ON THE GENUINENESS AND AUTHORITY OF ALL INSTRUCTIONS
                  RECEIVED BY PRYTANY WHEN ACCOMPANIED BY SUCH PASSWORD, AND TO
                  ACT ON SUCH INSTRUCTIONS.
                </p>
                <ol type="A" start="17">
                  <li>
                    <p align="justify">
                      <u>Solicitations and Other Messaging</u>
                    </p>
                  </li>
                </ol>
                <p align="justify">
                  Constituents and Enrolled Committees may use the Prytany
                  Platform to solicit contributions and pledges from
                  Constituents to Registered Campaigns and Registered National
                  Party Committees and to send and receive messages to
                  Constituents and Enrolled Committees. When using the messaging
                  functionality of the Prytany Platform, Constituents and
                  Enrolled Committees must abide by Prytany's Code of Conduct
                  which prohibits abusive behavior, harrassment, hate speech and
                  other unacceptable conduct. Please read Prytany's Code of
                  Conduct, which can be found on our home page at prytany.com.
                  Solicitations of contributions by Constituents and Enrolled
                  Committees using the Prytany Platform must comply with
                  applicable federal and state election laws and the applicable
                  regulations of the FEC. Each Constituent and Enrolled
                  Committee that uses the Prytany Platform to solicit
                  contributions and pledges is solely responsible for complying
                  with such laws and regulations.
                </p>
                <ol type="A" start="18">
                  <li>
                    <p align="justify">
                      <u>Modifications</u>
                    </p>
                  </li>
                </ol>
                <p align="justify">
                  We may add, change, suspend or eliminate any or all Prytany
                  Account-related features and services at any time, with or
                  without notice, subject to applicable law. We may terminate
                  your access to any or all Prytany Platform, including your
                  Prytany Account, in the event you violate the Prytany User
                  Agreement or any other agreement in force between you and
                  Prytany or its affiliates.
                </p>
                <ol type="I" start="3">
                  <li>
                    <p align="justify">GENERAL TERMS AND CONDITIONS.</p>
                    <ol type="A">
                      <li>
                        <p align="justify">
                          <u>Notices to You</u>
                        </p>
                      </li>
                    </ol>
                  </li>
                </ol>
                <p align="justify">
                  You agree that Prytany may provide notice to you by posting it
                  on our website (including but not limited to our online
                  documents), or if we have your email address or street
                  address, by emailing it to the email address listed in your
                  account or mailing it to the street address listed in your
                  account. Such notice shall be considered to be received by you
                  within 24 hours of the time it is posted to our website or
                  emailed to you unless we receive notice that the email was not
                  delivered. If the notice is sent by mail, we will consider it
                  to have been received by you three Business Days after it is
                  sent. Prytany's Business Days include all days on which the
                  New York Federal Reserve Bank is open for business. We may
                  also provide notice when you access the Prytany Platform. You
                  will keep your contact information, including email address,
                  up-to-date in your Prytany Account.
                </p>
                <ol type="I" start="3">
                  <ol type="A" start="2">
                    <li>
                      <p align="justify">
                        <u>Business Days</u>
                      </p>
                    </li>
                  </ol>
                </ol>
                <p align="justify">
                  "Business Days" means Monday through Friday, excluding
                  Holidays. "Holidays" means New Year's Day (January 1),
                  Birthday of Martin Luther King, Jr. (the third Monday in
                  January), Washington's Birthday (the third Monday in
                  February), Memorial Day (the last Monday in May), Independence
                  Day (July 4), Labor Day (the first Monday in September),
                  Columbus Day (the second Monday in October), Veterans Day
                  (November 11), Thanksgiving Day (the fourth Thursday in
                  November) and Christmas Day (December 25). If a Holiday falls
                  on a Saturday, Prytany shall observe the Holiday on the prior
                  Friday. If the Holiday falls on a Sunday, Prytany shall
                  observe the Holiday on the following Monday.
                </p>
                <ol type="I" start="3">
                  <ol type="A" start="3">
                    <li>
                      <p align="justify">
                        <u>Legal Notices to Prytany</u>
                      </p>
                    </li>
                  </ol>
                </ol>
                <p align="justify">
                  Any legal notice to Prytany must be sent by postal mail to:
                  Prytany LLC, Attention: Legal Department, 786 Stephanie
                  Circle, Great Falls, Virginia 22066. You may contact Prytany
                  for any non-legal purpose via the Contact Us information below
                  or by email to{' '}
                  <a href="mailto:support@Prytany.com.">support@Prytany.com.</a>{' '}
                  You consent to all notices being sent to you by Prytany using
                  the most recent email address and/or physical mailing address
                  that you provided to Prytany.
                </p>
                <ol type="I" start="3">
                  <ol type="A" start="4">
                    <li>
                      <p align="justify">
                        <u>Candidate Information on Prytany</u>
                      </p>
                    </li>
                  </ol>
                </ol>
                <p align="justify">
                  The Prytany Platform may enable candidate searches. Prytany
                  does not warrant that candidate descriptions, ratings and
                  reviews or any other content on Prytany is accurate, complete,
                  reliable or current. Information about candidates is provided
                  for informational purposes only and does not constitute an
                  endorsement by Prytany of any candidate, campaign, cause,
                  party or other organization. Prytany is not soliciting
                  contributions on behalf of any candidate, cause or party. The
                  Prytany Platform is available to any contributor, candidate,
                  campaign and national political party and PAC to the extent
                  authorized by the FEC and provided such user satisfies
                  Prytany's requirements for use of the Prytany Platform,
                  accepts this Agreement, and abides by Prytany's Code of
                  Conduct. Prytany reserves the right, however, to withhold
                  access to all or any function of the Prytany Platform by any
                  person or entity for any reason.
                </p>
                <ol type="I" start="3">
                  <ol type="A" start="5">
                    <li>
                      <p align="justify">
                        <u>Intellectual Property</u>
                      </p>
                    </li>
                  </ol>
                </ol>
                <p align="justify">
                  "Prytany.com," "Prytany," and all related logos, products and
                  services described in our website and mobile applications are
                  either trademarks or registered trademarks of Prytany or its
                  licensors. You may not copy, imitate or use them without
                  Prytany's prior written consent. In addition, all page
                  headers, custom graphics, button icons, and scripts are
                  service marks, trademarks, and/or trade dress of Prytany. You
                  may not copy, imitate, or use them without our prior written
                  consent. You may not alter, modify or change these Prytany
                  trademarks in any way, use them in a manner that is
                  disparaging to Prytany or the Prytany Platform or display them
                  in any manner that implies Prytany's sponsorship or
                  endorsement. All right, title and interest in and to the
                  Prytany Platform is the exclusive property of Prytany and its
                  licensors.
                </p>
                <p align="justify">
                  Certain other product or service names, brand names and trade
                  names may be trademarks of their respective owners.
                </p>
                <ol type="I" start="3">
                  <ol type="A" start="6">
                    <li>
                      <p align="justify">
                        <u>Privacy</u>
                      </p>
                    </li>
                  </ol>
                </ol>
                <p align="justify">
                  Protecting your privacy is very important to us. Please review
                  our Privacy Policy at{' '}
                  <a href="https://www.prytany.com/privacy-policy">
                    https://www.prytany.com/privacy-policy
                  </a>
                  , which is incorporated into this User Agreement by this
                  reference, in order to better understand our commitment to
                  maintaining your privacy, as well as our use and disclosure of
                  your information.
                </p>
                <ol type="I" start="3">
                  <ol type="A" start="7">
                    <li>
                      <p align="justify">
                        <u>Children's Privacy</u>
                      </p>
                    </li>
                  </ol>
                </ol>
                <p align="justify">
                  Protecting the privacy of young children is especially
                  important. For that reason, we do not knowingly collect or
                  solicit personal information from anyone under the age of 13
                  or knowingly allow anyone under 13 to register for the Prytany
                  Platform. If you are under 13, please do not send any
                  information about yourself to us, including your name,
                  address, telephone number, or email address. No one under age
                  13 is allowed to provide any personal or payment information
                  to us through the Prytany Platform. If we learn that we have
                  collected personal information from a child under age 13, we
                  will delete that information as quickly as possible. If you
                  believe that we might have any information from or about a
                  child under 13, please contact us at the information provided
                  below in the "Contact Us" section.
                </p>
                <ol type="I" start="3">
                  <ol type="A" start="8">
                    <li>
                      <p align="justify">
                        <u>
                          Loss or Theft of Account Information, PIN, and Mobile
                          Device
                        </u>
                      </p>
                    </li>
                  </ol>
                </ol>
                <p align="justify">
                  If you believe that any of your Prytany Account registration
                  information, PIN or mobile device containing the Prytany App
                  has been lost or stolen, or if your Prytany Account history
                  shows transfers that you did not make, immediately contact
                  Prytany via the Contact Us information below or by email to{' '}
                  <a href="mailto:support@Prytany.com.">support@prytany.com.</a>
                </p>
                <ol type="I" start="3">
                  <ol type="A" start="9">
                    <li>
                      <p align="justify">
                        <u>
                          Resolution Procedures for Unauthorized Contributions
                          and Other Errors
                        </u>
                      </p>
                    </li>
                  </ol>
                </ol>
                <p align="justify">
                  The following terms and conditions apply with respect to all
                  uses of the Prytany Platform.
                </p>
                <ol type="I" start="3">
                  <ol type="A">
                    <ol>
                      <li>
                        <p align="justify">
                          Unauthorized Contributions and Other Errors
                        </p>
                      </li>
                    </ol>
                  </ol>
                </ol>
                <p align="justify">
                  When an Unauthorized Contribution (defined below) or Other
                  Error (defined below) occurs in your account, including
                  Unauthorized Contributions that occur because your Prytany
                  mobile-activated phone has been lost or stolen, Prytany will
                  assist you in notifying the Registered Campaign, Registered
                  National Party Committee or PAC of such Unauthorized
                  Contribution or Other Error and requesting that the full
                  amount of your Unauthorized Contribution or Other Error be
                  refunded to you as long as you follow the procedures discussed
                  below. As provided below in Section III.P (No Liability of
                  Prytany for Contribution Failures), Section III.Q (Disclaimers
                  of Warranty; Damages Exclusions) and Section III.R
                  (Limitations on Liability), Prytany's liability for
                  Unauthorized Contributions and Other Errors, and to users of
                  Prytany in general, is limited to the fullest extent permitted
                  by applicable law.
                </p>
                <p align="justify">
                  An <strong>"Unauthorized </strong>
                  <strong>Contribution</strong> <strong>"</strong> is a type of
                  error that occurs when money is sent from your funding source
                  that you did not authorize and that did not benefit you. For
                  example, if someone steals your password, uses the password to
                  access your account, and sends a contribution from your
                  funding source, an Unauthorized Contribution has occurred.
                  However, if you give someone access to your account (for
                  example, by giving them your login information) and they
                  conduct transactions without your knowledge or permission, you
                  are responsible for any resulting use. Such transactions are
                  not considered Unauthorized Contributions.
                </p>
                <p align="justify">
                  In addition, <strong>"Other Errors"</strong> occur when
                  contributions are incorrectly recorded in your account.
                  Routine inquiries about your contributions or the status of a
                  pending contribution are not considered Unauthorized
                  Contributions or Other Errors unless you expressly notify us
                  of an Unauthorized Contribution or Other Error in connection
                  with the contribution. Requests for information for tax or
                  other recordkeeping purposes and requests for duplicate
                  documentation also are not deemed to be Unauthorized
                  Contributions or Other Errors. You may request documentation
                  or information regarding your Prytany Account to determine
                  whether an Unauthorized Contribution or Other Error exists by
                  contacting us through the "Contact Us" link on the Prytany
                  website.
                </p>
                <ol type="I" start="3">
                  <ol type="A">
                    <ol start="2">
                      <li>
                        <p align="justify">Notification Requirements</p>
                      </li>
                    </ol>
                  </ol>
                </ol>
                <p align="justify">
                  You should immediately notify Prytany if you believe:
                </p>
                <ol type="I" start="3">
                  <ol type="A">
                    <ol />
                  </ol>
                </ol>
                <ul>
                  <li>
                    <p align="justify">
                      There has been an Unauthorized Contribution, unauthorized
                      access to your Prytany Account, or the occurrence of an
                      Other Error;
                    </p>
                  </li>
                  <li>
                    <p align="justify">
                      There is an error in your contribution history or your
                      contribution confirmation sent to you by email;
                    </p>
                  </li>
                  <li>
                    <p align="justify">Your password has been compromised;</p>
                  </li>
                  <li>
                    <p align="justify">
                      Your mobile device has been lost, stolen or deactivated;
                      or
                    </p>
                  </li>
                  <li>
                    <p align="justify">
                      You need more information about a contribution listed in
                      your contribution history or contribution confirmation
                      email.
                    </p>
                  </li>
                </ul>
                <p align="justify">
                  You should regularly log into your Prytany Account and review
                  your contribution history to ensure that there has not been an
                  Unauthorized Contribution or Other Error. Prytany will also
                  send an email to your primary email address on file in order
                  to notify you of each contribution you make. You should review
                  those emails to ensure that each contribution was authorized
                  and is accurate.
                </p>
                <p align="justify">
                  For Unauthorized Contributions or Other Errors in your Prytany
                  Account, notify us as follows:
                </p>
                <ul>
                  <li>
                    <p align="justify">
                      By contacting to us as directed the "Contact Us" tab
                      below; or
                    </p>
                  </li>
                  <li>
                    <p align="justify">
                      By emailing us at{' '}
                      <a href="mailto:support@prytany.com">
                        support@prytany.com
                      </a>
                      .
                    </p>
                  </li>
                </ul>
                <p align="justify">
                  When you notify us, provide us with all of the following
                  information:
                </p>
                <ul>
                  <li>
                    <p align="justify">
                      Your name, and the email address and phone number
                      registered to your Prytany Account;
                    </p>
                  </li>
                  <li>
                    <p align="justify">
                      A description of any suspected Unauthorized Contribution
                      or Other Error and an explanation of why you believe it is
                      incorrect or why you need more information to identify the
                      transaction; and
                    </p>
                  </li>
                  <li>
                    <p align="justify">
                      The dollar amount of any suspected Unauthorized
                      Contribution or Other Error.
                    </p>
                  </li>
                </ul>
                <p align="justify">
                  During the course of our investigation, we may request
                  additional information from you.
                </p>
                <ol type="I" start="3">
                  <ol type="A">
                    <ol start="3">
                      <li>
                        <p align="justify">
                          Prytany Actions After Receipt of your Notification
                        </p>
                      </li>
                    </ol>
                  </ol>
                </ol>
                <p align="justify">
                  Once you notify us of any suspected Unauthorized Contribution
                  or Other Error, or we otherwise learn of one, we will do the
                  following:
                </p>
                <ol type="I" start="3">
                  <ol type="A">
                    <ol />
                  </ol>
                </ol>
                <ul>
                  <li>
                    <p align="justify">
                      We will conduct an investigation to determine whether
                      there has been an Unauthorized Contribution or Other
                      Error.
                    </p>
                  </li>
                  <li>
                    <p align="justify">
                      We will complete our investigation within 10 Business Days
                      of the date we received your notification of the suspected
                      Unauthorized Contribution or Other Error. If your account
                      is new (the first contribution from your account was less
                      than 30 Business Days from the date you notify us), we may
                      take up to 20 Business Days to complete this
                      investigation. If we need more time, we may take up to 45
                      days to complete our investigation (or up to 90 days for
                      new accounts). We will inform you of our decision within 3
                      Business Days after completing our investigation.
                    </p>
                  </li>
                  <li>
                    <p align="justify">
                      If we decide that there was not an Unauthorized
                      Contribution or Other Error, we will include an
                      explanation of our decision in our email or other
                      communications to you. You may request copies of the
                      documents that we used in our investigation.
                    </p>
                  </li>
                </ul>
                <ol type="I" start="3">
                  <ol type="A" start="10">
                    <li>
                      <p align="justify">
                        <u>Restricted Activities</u>
                      </p>
                    </li>
                  </ol>
                </ol>
                <p align="justify">
                  In connection with your use of our website, your Prytany
                  Account, or the Prytany Platform, or in the course of your
                  interactions with Prytany, another user of Prytany or a third
                  party, you will not:
                </p>
                <ol type="i" start="2">
                  <ol type="a" />
                </ol>
                <ul>
                  <li>
                    <p align="justify">
                      breach this Agreement or any other Agreement that you have
                      entered into with Prytany;
                    </p>
                  </li>
                  <li>
                    <p align="justify">
                      violate Prytany's Code of Conduct or other policy,
                      including its ban on abusive behavior, harrassment, hate
                      speech, and other unacceptable conduct;
                    </p>
                  </li>
                  <li>
                    <p align="justify">
                      violate any law, statute, ordinance, or regulation (for
                      example, those governing Federal Campaign Finance and the
                      Financing of Presidential Election Campaigns);
                    </p>
                  </li>
                  <li>
                    <p align="justify">
                      infringe Prytany's or any third party's copyright, patent,
                      trademark, trade secret or other intellectual property
                      rights, or rights of publicity or privacy;
                    </p>
                  </li>
                  <li>
                    <p align="justify">
                      act in a manner that is defamatory, trade libelous,
                      threatening or harassing, or engage in any hate speech;
                    </p>
                  </li>
                  <li>
                    <p align="justify">
                      provide false, inaccurate or misleading Personal
                      Information;
                    </p>
                  </li>
                  <li>
                    <p align="justify">
                      create more than one Prytany account for yourself by,
                      among other methods, using a name that is not yours, using
                      temporary email address(es) or phone number(s), or
                      providing any other falsified Personal Information;
                    </p>
                  </li>
                  <li>
                    <p align="justify">
                      send what we reasonably believe to be potentially
                      fraudulent funds;
                    </p>
                  </li>
                  <li>
                    <p align="justify">
                      refuse to cooperate in an investigation or provide
                      confirmation of your identity or any Personal Information
                      you provide to us;
                    </p>
                  </li>
                  <li>
                    <p align="justify">use an anonymizing proxy;</p>
                  </li>
                  <li>
                    <p align="justify">
                      control an account that is linked to another account that
                      has engaged in any of these restricted activities;
                    </p>
                  </li>
                  <li>
                    <p align="justify">
                      control or possess more than one account without
                      authorization from Prytany;
                    </p>
                  </li>
                  <li>
                    <p align="justify">
                      conduct your activities or use the Prytany Platform in a
                      manner that results in or may result in complaints,
                      disputes, claims, reversals, chargebacks, fees, fines,
                      penalties and other liability to Prytany, another user of
                      Prytany, a third party or you;
                    </p>
                  </li>
                  <li>
                    <p align="justify">
                      use your Prytany Account or the Prytany Platform in a
                      manner that Prytany, Visa, MasterCard, American Express or
                      Discover reasonably believe to be an abuse of the credit
                      card system or a violation of credit card association
                      rules;
                    </p>
                  </li>
                  <li>
                    <p align="justify">
                      disclose or distribute another Prytany user's Personal
                      Information to a third party, or use the information for
                      marketing purposes unless you receive the user's express
                      consent to do so;
                    </p>
                  </li>
                  <li>
                    <p align="justify">
                      take any action that imposes an unreasonable or
                      disproportionately large load on our infrastructure;
                    </p>
                  </li>
                  <li>
                    <p align="justify">
                      facilitate any viruses, Trojan horses, worms or other
                      computer programming routines that may damage,
                      detrimentally interfere with, surreptitiously intercept or
                      expropriate any system, data or information;
                    </p>
                  </li>
                  <li>
                    <p align="justify">
                      use any robot, spider, other automatic device, or manual
                      process to monitor or copy our website without our prior
                      written permission;
                    </p>
                  </li>
                  <li>
                    <p align="justify">
                      use any device, software or routine to bypass our robot
                      exclusion headers, or interfere or attempt to interfere,
                      with our website or the Prytany Platform;
                    </p>
                  </li>
                  <li>
                    <p align="justify">
                      take any action that may cause us to lose any of the
                      services from our internet services providers, payment
                      processors, or other suppliers; or
                    </p>
                  </li>
                  <li>
                    <p align="justify">
                      use the Prytany Platform to test credit card behaviors.
                    </p>
                  </li>
                </ul>
                <ol type="A" start="3">
                  <ol type="A" start="11">
                    <li>
                      <p align="justify">
                        <u>Legal Compliance</u>
                      </p>
                    </li>
                  </ol>
                </ol>
                <p align="justify">
                  You agree you will not use the Prytany Platform to violate any
                  law, statute, ordinance, or regulation. You are solely
                  responsible for ensuring that your use of the Prytany Platform
                  is in conformance with applicable federal, state and local
                  laws and regulations.
                </p>
                <ol type="A" start="3">
                  <ol type="A" start="12">
                    <li>
                      <p align="justify">
                        <u>Your Liability</u>
                      </p>
                    </li>
                  </ol>
                </ol>
                <p align="justify">
                  If Prytany incurs any damages because you breach this
                  Agreement, violate our Code of Conduct (which may be found on
                  the home page of our website at{' '}
                  <a href="https://www.prytany.com/code-of-conduct">
                    https://www.prytany.com/code-of-conduct
                  </a>
                  ) or other policies, break any laws, or otherwise cause
                  Prytany to suffer any damages or incur any expenses then we
                  may take legal action against you to recover our losses,
                  investigation costs, fines, or legal fees we may incur. You
                  are responsible for all reversals, chargebacks, claims, fees,
                  fines, penalties and other liability incurred by Prytany, a
                  Prytany user, or a third party caused by or arising out of
                  your breach of this Agreement, and/or your use of the Prytany
                  Platform. You agree to reimburse Prytany, a user, or a third
                  party for any and all such liability.
                </p>
                <p align="justify">
                  You acknowledge that you are responsible for the accuracy of
                  all contributions made using the Prytany Platform, including
                  but not limited to the accuracy of the amount paid and the
                  recipient. Prytany shall not be responsible or in any way held
                  liable due to inaccurate payment of contributions, including
                  but not limited to making a contribution in the wrong amount
                  or to the wrong Registered Campaign, Registered National Party
                  Committee or PAC.
                </p>
                <ol type="A" start="3">
                  <ol type="A" start="13">
                    <li>
                      <p align="justify">
                        <u>Actions by Prytany</u>
                      </p>
                    </li>
                  </ol>
                </ol>
                <p align="justify">
                  If we have reason to believe that you have engaged in any
                  restricted activities, made excessive or unexplainable
                  transactions, violated any parts of this Agreement, violated
                  Prytany's Code of Conduct, or provided any incorrect
                  information, we may take various actions to protect Prytany,
                  another Prytany user, a third party, or you. The actions we
                  may take include but are not limited to the following:
                </p>
                <ol type="i">
                  <ol type="a">
                    <ol type="i" />
                  </ol>
                </ol>
                <ul>
                  <li>
                    <p align="justify">
                      we may close, suspend, or limit your access to your
                      Prytany Account or the Prytany Platform (such as limiting
                      your ability to send messages or to make or receive
                      contributions or pledges);
                    </p>
                  </li>
                  <li>
                    <p align="justify">
                      we may contact your bank or credit card issuer and warn
                      other users, law enforcement, or impacted third parties of
                      your actions;
                    </p>
                  </li>
                  <li>
                    <p align="justify">
                      we may update inaccurate information you provided us;
                    </p>
                  </li>
                  <li>
                    <p align="justify">
                      we may refuse to provide you with access to the Prytany
                      Platform in the future; and
                    </p>
                  </li>
                  <li>
                    <p align="justify">we may take legal action against you.</p>
                  </li>
                </ul>
                <ol type="A" start="3">
                  <ol type="A" start="14">
                    <li>
                      <p align="justify">
                        <u>
                          Account Closure, Termination of Account, or Limited
                          Account Access
                        </u>
                      </p>
                    </li>
                  </ol>
                </ol>
                <p align="justify">
                  Prytany, in its sole discretion, reserves the right to
                  terminate this Agreement, access to its website, and access to
                  the Prytany Platform for any reason and at any time with or
                  without notice to you.
                </p>
                <p align="justify">
                  If we limit or close your Prytany Account or terminate your
                  use of the Prytany Platform for any reason, you may contact us
                  and request restoration of access if appropriate. However, if
                  we deem you violated this Agreement, including Prytany's Code
                  of Conduct, such restoration is at our sole discretion.
                </p>
                <p align="justify">
                  You may stop using the Prytany Platform at any time and may
                  close your Prytany Account by contacting us.
                </p>
                <ol type="A" start="3">
                  <ol type="A" start="15">
                    <li>
                      <p align="justify">
                        <u>Disputes with Prytany</u>
                      </p>
                      <ol>
                        <li>
                          <p align="justify">Notice of Dispute</p>
                        </li>
                      </ol>
                    </li>
                  </ol>
                </ol>
                <p align="justify">
                  If a dispute arises between you and Prytany, our goal is to
                  learn about and address your concerns and, if we are unable to
                  do so to your satisfaction, to provide you with a neutral and
                  cost effective means of resolving the dispute quickly.
                  Disputes between you and Prytany regarding your use of the
                  Prytany Platform may be reported by emailing us at the
                  following email address:{' '}
                  <a href="mailto:support@prytany.com">support@prytany.com</a>.
                </p>
                <ol type="A" start="3">
                  <ol type="A" start="15">
                    <ol start="2">
                      <li>
                        <p align="justify">
                          Law and Forum for Disputes; Arbitration
                        </p>
                      </li>
                    </ol>
                  </ol>
                </ol>
                <p align="justify">
                  This User Agreement shall be governed in all respects by the
                  laws of the District of Columbia, without regard to conflict
                  of law provisions, except to the extent that federal law
                  applies.
                </p>
                <p align="justify">
                  ANY CLAIMS ARISING OUT OF, RELATING TO, OR CONNECTED WITH THIS
                  USER AGREEMENT MUST BE ASSERTED INDIVIDUALLY IN BINDING
                  ARBITRATION CONDUCTED BY A SINGLE ARBITRATOR WITH EXPERIENCE
                  IN CONSUMER ONLINE PAYMENT SERVICES DISPUTES ADMINISTERED BY
                  THE AMERICAN ARBITRATION ASSOCIATION ("<strong>AAA</strong>")
                  IN ACCORDANCE WITH ITS COMMERCIAL ARBITRATION RULES AND THE
                  AAA SUPPLEMENTARY PROCEDURES FOR CONSUMER-RELATED DISPUTES.
                  The forum for arbitration shall be in the city closest to your
                  residence having a federal district courthouse. The arbitrator
                  shall not conduct any form of class or collective arbitration
                  nor join or consolidate claims by or for individuals. To the
                  extent allowed by applicable law, the Arbitrator, and not any
                  federal, state, or local court or agency, shall have exclusive
                  authority to resolve any dispute relating to the
                  interpretation, applicability, enforceability or formation of
                  this User Agreement including, but not limited to, any claim
                  that all or any part of this User Agreement is void or
                  voidable. Judgment on the award rendered by the arbitrator may
                  be entered in any court of competent jurisdiction. For any
                  non-frivolous claim, Prytany will pay the costs of the
                  arbitration (but not your attorney fees), up to $3,000.
                </p>
                <p align="justify">
                  This User Agreement and each of its parts evidence a
                  transaction involving interstate commerce, and the United
                  States Arbitration Act shall apply in all cases and govern the
                  interpretation and enforcement of the arbitration rules and
                  arbitration proceedings.
                </p>
                <p align="justify">
                  There are only two exceptions to this agreement to arbitrate.
                  First, if we reasonably believe that you have in any manner
                  violated or threatened to violate our intellectual property
                  rights, we may seek injunctive or other appropriate relief in
                  any court of competent jurisdiction. Second, any claim of $500
                  or less may, at the option of the claiming party, be resolved
                  in small claims court in Washington, D.C., if the claim and
                  the parties are within the jurisdiction of the small claims
                  court. For these two exceptions, you agree to submit to the
                  personal jurisdiction of the courts located within the
                  District of Columbia for the purpose of litigating such claims
                  or disputes.
                </p>
                <ol type="A" start="3">
                  <ol type="A" start="15">
                    <ol start="3">
                      <li>
                        <p align="justify">
                          Waiver of Right to Jury; Class Action Waiver
                        </p>
                      </li>
                    </ol>
                  </ol>
                </ol>
                <p align="justify">
                  TO THE EXTENT ALLOWED BY LAW, YOU AGREE TO IRREVOCABLY WAIVE
                  ANY RIGHT YOU MAY HAVE TO A TRIAL BY JURY OR OTHER COURT TRIAL
                  (OTHER THAN SMALL CLAIMS COURT) OR TO SERVE AS A
                  REPRESENTATIVE, AS A PRIVATE ATTORNEY GENERAL, OR IN ANY OTHER
                  REPRESENTATIVE CAPACITY, OR TO PARTICIPATE AS A MEMBER OF A
                  CLASS OF CLAIMANTS, IN ANY LAWSUIT, ARBITRATION OR OTHER
                  PROCEEDING FILED AGAINST US AND/OR RELATED THIRD PARTIES.
                </p>
                <ol type="A" start="3">
                  <ol type="A" start="16">
                    <li>
                      <p align="justify">
                        <u>No Liability of Prytany for Contribution Failures</u>
                      </p>
                    </li>
                  </ol>
                </ol>
                <p align="justify">
                  Prytany will not be liable if a contribution made using the
                  Prytany Platform is not completed or made on time or in the
                  correct amount except to the extent required by law. Prytany
                  will not be liable:
                </p>
                <ul>
                  <ul>
                    <ul>
                      <li>
                        <p align="justify">
                          If, through no fault of Prytany's, you do not have
                          enough money in your bank account or sufficient credit
                          remaining on your credit card to make the intended
                          contribution;
                        </p>
                      </li>
                      <li>
                        <p align="justify">
                          If the funds in your bank account are subject to legal
                          process or other encumbrance restricting their use;
                        </p>
                      </li>
                      <li>
                        <p align="justify">
                          If the Prytany Platform was not working properly
                          through no fault of Prytany;
                        </p>
                      </li>
                      <li>
                        <p align="justify">
                          If the failure results from a failure of the financial
                          institution that issued your credit card or debit
                          card, or Prytany is unable to access your credit card;
                        </p>
                      </li>
                      <li>
                        <p align="justify">
                          If you provided inaccurate or incomplete information
                          regarding the contribution;
                        </p>
                      </li>
                      <li>
                        <p align="justify">
                          If the contribution appears suspicious, fraudulent, or
                          unauthorized, and Prytany cannot confirm that it is a
                          legitimate contribution, or if your Prytany Account or
                          the Committee Account of the Registered Campaign,
                          Registered National Party Committee or PAC receiving
                          the contribution is under investigation by Prytany or
                          if the contribution is or appears to be prohibited by
                          any applicable law or rules.
                        </p>
                      </li>
                    </ul>
                  </ul>
                </ul>
                <ol type="I" start="3">
                  <ol type="A" start="17">
                    <li>
                      <p align="justify">
                        <u>Disclaimers of Warranty; Damages Exclusions</u>
                      </p>
                    </li>
                  </ol>
                </ol>
                <p align="justify">
                  Unless otherwise prohibited by law, you assume all
                  responsibility for your use of the Prytany Platform and use it
                  at your own risk. To the fullest extent permissible under
                  applicable law, all such representations, warranties,
                  guarantees and conditions are disclaimed, including, but not
                  limited to, any implied warranties of merchantability, fitness
                  for a particular purpose, title, noninfringement of
                  intellectual property rights, or other terms which might
                  otherwise be implied by statute, common law or in equity.
                  Prytany LLC does not warrant that the Prytany Platform will be
                  uninterrupted or error-free, that defects will be corrected,
                  or that the Prytany Platform, or the servers that process
                  information for the Prytany Platform, are free of viruses,
                  bugs or other harmful components. On behalf of Prytany LLC,
                  Covered Third Parties, and each of our respective affiliates,
                  vendors, agents and suppliers, Prytany LLC makes the following
                  disclaimers set forth in this section: the Prytany Platform is
                  provided on an{' '}
                  <strong>"as is", "as available" and "with all faults"</strong>{' '}
                  basis, without any representations, warranties, guarantees, or
                  conditions of any kind, express, implied or statutory,
                  including, but not limited to, any warranty as to the use or
                  operation of the Prytany Platform, or the information, content
                  or other materials related to the Prytany Platform, whether
                  provided by Prytany LLC, any third party, or any of the
                  Covered Third Parties. Neither Prytany LLC, nor any third
                  party, nor any of the Covered Third Parties warrant nor make
                  any representations regarding the use or the results of the
                  use of the Prytany Platform in terms of correctness, accuracy,
                  timeliness, reliability, or otherwise. You assume the entire
                  cost of all necessary maintenance, repair, or correction to
                  any equipment you use in accessing the Prytany Platform,
                  including, but not limited to, your mobile phone or other
                  device.
                </p>
                <p align="justify">
                  PRYTANY LLC IS NOT LIABLE FOR THE CONSEQUENCES OF YOU CHOOSING
                  TO SHARE ANY CONTRIBUTION OR PLEDGE DETAILS ON SOCIAL MEDIA OR
                  WITHIN THE PRYTANY PLATFORM, AND YOU AGREE TO HOLD PRYTANY LLC
                  HARMLESS AND INDEMNIFY PRYTANY LLC FROM ANY LIABILITY arising
                  from the actions or inactions of any external social media
                  network in connection with the permissions you grant to the
                  external social media network.
                </p>
                <p align="justify">
                  NONE OF PRYTANY LLC, ANY THIRD PARTY (INCLUDING, WITHOUT
                  LIMITATION, THE COVERED THIRD PARTIES, OR ANY OF THEIR
                  RESPECTIVE AFFILIATES, VENDORS, AGENTS OR SUPPLIERS) WILL BE
                  LIABLE FOR, AND YOU AGREE NOT TO SEEK AGAINST ANY OF THE
                  FOREGOING, ANY DAMAGES OF ANY KIND ARISING FROM THE USE OF THE
                  PRYTANY PLATFORM, INCLUDING, BUT NOT LIMITED TO, INDIRECT,
                  SPECIAL, INCIDENTAL, PUNITIVE, EXEMPLARY, CONSEQUENTIAL
                  DAMAGES OR DAMAGES RESULTING FROM THE USE OF PRYTANY PLATFORM,
                  LOSS OF USE OF THE PRYTANY PLATFORM, LOST DATA, LOST PROFITS,
                  OR BUSINESS INTERRUPTION ARISING OUT OF OR IN ANY WAY
                  CONNECTED WITH THE USE OF THE PRYTANY PLATFORM, ANY DELAYS IN
                  THE PRYTANY PLATFORM, OR THE INABILITY TO USE THE PRYTANY
                  PLATFORM, OR ANY PORTION THEREOF, WHETHER BASED ON CONTRACT,
                  TORT, NEGLIGENCE, STRICT LIABILITY OR OTHERWISE, EVEN IF ALL
                  OR ANY OF US HAVE BEEN ADVISED OF THE POSSIBILITY OF SUCH
                  DAMAGES AND EVEN IF ANY REMEDY FAILS OF ITS ESSENTIAL PURPOSE.
                </p>
                <p align="justify">
                  Prytany LLC does not have any control over any Registered
                  Campaign, Registered National Party Committee or PAC that
                  receives contributions through the Prytany Platform or the
                  associated candidates, and Prytany LLC cannot ensure that any
                  Registered Campaign, Registered National Party Committee or
                  PAC to which you have made a contribution will actually accept
                  the contribution or that the associated candidate will
                  maintain any rating or otherwise take or not take any action.
                </p>
                <p align="justify">
                  Prytany LLC will make reasonable efforts to ensure that
                  requests for electronic debits and credits involving bank
                  accounts, credit cards, and check issuances are processed in a
                  timely manner but Prytany LLC makes no representations or
                  warranties regarding the amount of time needed to complete
                  processing because Prytany is dependent upon many factors
                  outside of our control, such as delays in the banking system
                  or the U.S. mail service.
                </p>
                <p align="justify">
                  SOME STATES OR OTHER JURISDICTIONS DO NOT ALLOW THE DISCLAIMER
                  OF IMPLIED WARRANTIES OR THE EXCLUSION OF CERTAIN DAMAGES, SO
                  THE ABOVE DISCLAIMERS AND EXCLUSIONS MAY NOT APPLY TO YOU. YOU
                  MAY ALSO HAVE OTHER RIGHTS THAT VARY FROM STATE TO STATE AND
                  JURISDICTION TO JURISDICTION.
                </p>
                <ol type="I" start="3">
                  <ol type="A" start="18">
                    <li>
                      <p align="justify">
                        <u>Limitations on Liability</u>
                      </p>
                    </li>
                  </ol>
                </ol>
                <p align="justify">
                  Except as otherwise EXPRESSLY provided in this Agreement, and
                  to the extent permissible under applicable law, Prytany LLC's
                  cumulative liability to you for any claims or damages arising
                  out of or related to your use of the Prytany Platform for
                  which Prytany is found liable shall not exceed the amount of
                  the fees you paid to Prytany LLC (ie., excluding third party
                  fees you paid) for your use of the Prytany Platform during the
                  twelve months immediately preceding the month in which your
                  claim occurred.
                </p>
                <p align="justify">
                  These limitations on liability apply to: anything related to
                  the Prytany Platform or any application or content made
                  available through the Prytany Platform; and claims for breach
                  of contract, breach of warranty, guarantee or condition,
                  strict liability, negligence, or other tort to the extent
                  permitted by applicable law. These limitations on liability
                  also apply even if a refund of the contribution does not fully
                  compensate you for any losses; or Prytany LLC, any third party
                  (including, without limitation, any Covered Third Party) knew
                  or should have known about the possibility of the damages.
                </p>
                <p align="justify">
                  These limitations on liability will apply to the maximum
                  extent permitted by applicable law, even if any remedy fails
                  of its essential purpose.
                </p>
                <p align="justify">
                  SOME STATES OR OTHER JURISDICTIONS DO NOT ALLOW THE LIMITATION
                  OF LIABILITY SO THE FOREGOING LIMITATIONS MAY NOT APPLY TO
                  YOU. YOU MAY ALSO HAVE OTHER RIGHTS THAT VARY FROM STATE TO
                  STATE AND JURISDICTION TO JURISDICTION.
                </p>
                <ol type="I" start="3">
                  <ol type="A" start="19">
                    <li>
                      <p align="justify">
                        <u>License Grant</u>
                      </p>
                    </li>
                  </ol>
                </ol>
                <p align="justify">
                  Certain of the features of the Prytany Platform require the
                  use of software and software applications provided to you by
                  Prytany LLC (collectively "<strong>Software</strong>").
                  Prytany LLC and its licensors grant you a limited,
                  nonexclusive license to use Prytany LLC's Software in the
                  United States that we provide to you solely in accordance with
                  this Agreement and any user documentation we may provide,
                  including all updates, upgrades, new versions and replacements
                  of the Software (all of which become part of the "Software")
                  for your personal use only in accordance with this Agreement.
                  If the Software will be downloaded to a mobile device, this
                  license extends to your use of the Software on a device that
                  you own or control, as long as your use is permitted by the
                  usage rules set forth for your particular device (for example,
                  the Apple App Store Terms of Use). You may not rent, lease or
                  otherwise transfer your rights in the Software to a third
                  party. You must comply with the implementation and use
                  requirements for the Software contained in this Agreement or
                  in any Prytany Platform documentation we provide to you. If
                  you do not comply with such implementation and use
                  requirements, you will be liable for all resulting damages
                  suffered by you, Prytany LLC or any third parties. You agree
                  not to alter, reproduce, adapt, distribute, display, publish,
                  reverse engineer, translate, disassemble, decompile or
                  otherwise attempt to create any source code which is derived
                  from the software. You acknowledge that all rights, title and
                  interest to the Software are owned by Prytany LLC. Your rights
                  to use the Software cease immediately upon termination of this
                  Agreement and you must delete all of your copies of the
                  Software.
                </p>
                <ol type="I" start="3">
                  <ol type="A" start="20">
                    <li>
                      <p align="justify">
                        <u>Indemnification and Release of Prytany</u>
                      </p>
                    </li>
                  </ol>
                </ol>
                <p align="justify">
                  You agree to defend, indemnify and hold harmless Prytany LLC
                  and its affiliates, officers, directors and employees from any
                  claim or demand (including any damages, losses, expenses and
                  attorney's fees resulting therefrom) made or incurred by any
                  third party due to or arising out of your breach of this
                  Agreement and/or your use of the Prytany Platform.
                </p>
                <p align="justify">
                  If you have a dispute with one or more Registered Campaigns or
                  Registered National Party Committees relating to your
                  contributions, Prytany LLC is not responsible for any such
                  dispute and you hereby release Prytany LLC (and our officers,
                  directors, agents, joint ventures and employees) from any and
                  all claims, demands and damages (actual and consequential) of
                  every kind and nature arising out of or in any way connected
                  with such disputes.
                </p>
                <ol type="I" start="3">
                  <ol type="A" start="21">
                    <li>
                      <p align="justify">
                        <u>Assumption of Rights</u>
                      </p>
                    </li>
                  </ol>
                </ol>
                <p align="justify">
                  If Prytany LLC makes a payment to you for a claim, reversal or
                  chargeback that you file with us against a recipient of your
                  contribution, you agree that Prytany LLC assumes your rights
                  against the recipient and third parties related to the
                  contribution, and may pursue those rights directly or on your
                  behalf, in Prytany LLC's discretion.
                </p>
                <ol type="I" start="3">
                  <ol type="A" start="22">
                    <li>
                      <p align="justify">
                        <u>Modification of Terms</u>
                      </p>
                    </li>
                  </ol>
                </ol>
                <p align="justify">
                  We may amend this Agreement at any time by posting a revised
                  version on our website. The revised version will be effective
                  at the time we post it unless it contains material changes. If
                  we make changes to our Agreements with you that either reduce
                  your rights or increase your responsibilities, we will provide
                  21 days' notice to you before the changes become effective. By
                  using the Prytany Platform after a new Agreement has been
                  posted, you agree to the revised Agreement.
                </p>
                <ol type="I" start="3">
                  <ol type="A" start="23">
                    <li>
                      <p align="justify">
                        <u>Survival</u>
                      </p>
                    </li>
                  </ol>
                </ol>
                <p align="justify">
                  In the event of termination of this Agreement or the Prytany
                  Platform, the terms in this Agreement that by their nature are
                  continuing shall survive such termination, including but not
                  limited to the disclaimers and limitations of liabilities.
                </p>
                <ol type="I" start="3">
                  <ol type="A" start="24">
                    <li>
                      <p align="justify">
                        <u>Force Majeure</u>
                      </p>
                    </li>
                  </ol>
                </ol>
                <p align="justify">
                  We shall not be liable for any delay or failure in the
                  performance or in delivery or shipment of materials, or for
                  any damages suffered by you by reason of such delay or
                  failures, directly or indirectly caused by or in any manner
                  arising from or connected with acts of God, acts of public
                  enemies, riots, strikes, acts of governmental agencies, labor
                  difficulties, failure of our power, telecommunications or
                  other suppliers, delays in securing or shortages of raw
                  materials, breakdown or destruction of any system or
                  equipment, or any other cause or causes beyond our control,
                  whether or not similar to those enumerated herein.
                </p>
                <ol type="I" start="3">
                  <ol type="A" start="25">
                    <li>
                      <p align="justify">
                        <u>Miscellaneous</u>
                      </p>
                    </li>
                  </ol>
                </ol>
                <p align="justify">
                  This Agreement and other documents (including but not limited
                  to the Privacy Policy) referenced in or linked to this
                  Agreement, which are hereby incorporated herein and made a
                  part of this Agreement by this reference, contain yours and
                  our entire Agreement regarding your use of the Prytany
                  Platform. If any provision of this Agreement is deemed to be
                  illegal or unenforceable, such provision shall be enforced to
                  the extent possible, and any remaining illegality or
                  unenforceability will not affect the validity or
                  enforceability of any other provisions of this Agreement,
                  which together will be construed as if such illegal or
                  unenforceable provision had not been included in this
                  Agreement. Any legal action arising out of your use of the
                  Prytany Platform must be brought within one year after the
                  cause of action has arisen. The section headings in this
                  Agreement are for convenience of reference only and are not to
                  be considered as parts, provisions or interpretations of this
                  Agreement. You may not transfer or assign any rights or
                  obligations you have under this Agreement without Prytany's
                  prior written consent. Prytany reserves the right to transfer
                  or assign this Agreement or any right or obligation under this
                  Agreement at any time. Our failure to act with respect to a
                  breach by you or others does not waive our right to act with
                  respect to that breach or subsequent or similar breaches.
                </p>
                <ol type="I" start="3">
                  <ol type="A" start="26">
                    <li>
                      <p align="justify">
                        <u>Independent Contractors</u>
                      </p>
                    </li>
                  </ol>
                </ol>
                <p align="justify">
                  The parties agree they are independent contractors to each
                  other in performing their respective obligations hereunder.
                  Nothing in this Agreement or in the working relationship being
                  established and developed hereunder shall be deemed or is
                  intended to be deemed, nor shall it cause, the parties to be
                  treated as partners, joint venturers, or otherwise as joint
                  associates for profit.
                </p>
                <h2>HOW TO CONTACT US</h2>
                <p>
                  If you have questions or concerns regarding this Agreement or
                  your Prytany Account, or any feedback that you would like us
                  to consider, please contact us at{' '}
                  <a href="https://www.prytany.com/contact-us">
                    https://www.prytany.com/contact-us
                  </a>
                  . You may also write to us at Prytany LLC, 786 Stephanie
                  Circle, Great Falls, Virginia 22066.
                </p>
                <div className="mobileStore">
                  <div className="appleAppStoreIcon">
                    <img src="images/app-ios-badge.png" alt="" />
                  </div>
                  <div className="androidAppStoreIcon">
                    <img src="images/app-android-badge.png" alt="" />
                  </div>
                </div>
                <p>
                  Prytany LLC presently uses the payment processing services of
                  Stripe, a licensed provider of money transfer services (NMLS
                  ID: 1280479). All money transmission is provided by Prytany
                  LLC pursuant to its licenses with Stripe &#169; 2018 Stripe.
                </p>
                <p>Copyright &#169; Prytany LLC 2018. Patent pending.</p>
              </div>
            </div>
          </div>
        </section>
      </div>
    );
  }
}

export default UserAgreementPage;
