import {
  ACCOUNT_FEED_SUCCESS,
  ACCOUNT_FEED_RESET,
  ACCOUNT_FEED_LOADING,
  ACCOUNT_FEED_ERROR,
  ACCOUNT_FEED_LIKE_POST,
  ACCOUNT_FEED_UNLIKE_POST,
  ACCOUNT_FEED_NEXT_PAGE,
  ACCOUNT_FEED_SHARE_POST,
} from './accountFeed.constants';
import makeDefaultServerCallReducer from '../../utilities/makeDefaultServerCallReducer';
import _cloneDeep from 'lodash/cloneDeep';
import _get from 'lodash/get';
import _find from 'lodash/find';

const initialState = {
  isLoading: false,
  error: null,
  data: null,
};

const shareFeedPost = (state, action) => {
  if (state.data && state.data.data) {
    const data = _cloneDeep(state.data);

    if (data.data.feeds) {
      let postToUpdate = _find(
        data.data.feeds,
        feedItem =>
          feedItem.type === 'new_post' &&
          feedItem.details.post.id === action.payload.postId,
      );

      if (postToUpdate) {
        postToUpdate.details.post.share_count += 1;
      }
    }

    return {
      ...state,
      data,
    };
  }

  return state;
};

const likeFeedPost = (state, action) => {
  // Once the like is successful we have to grab the id of the post
  // and update both the like_count and has_like attribute

  if (state.data && state.data.data) {
    const data = _cloneDeep(state.data);

    if (data.data.feeds) {
      let postToUpdate = _find(
        data.data.feeds,
        feedItem =>
          feedItem.type === 'new_post' &&
          feedItem.details.post.id === action.payload.postId,
      );

      if (postToUpdate) {
        postToUpdate.details.post.like_count += 1;
        postToUpdate.details.post.has_liked = true;
      }
    }

    return {
      ...state,
      data,
    };
  }

  return state;
};

const unlikeFeedPost = (state, action) => {
  if (state.data && state.data.data) {
    const data = _cloneDeep(state.data);

    if (data.data.feeds) {
      let postToUpdate = _find(
        data.data.feeds,
        feedItem =>
          feedItem.type === 'new_post' &&
          feedItem.details.post.id === action.payload.postId,
      );

      if (postToUpdate) {
        postToUpdate.details.post.like_count -= 1;
        postToUpdate.details.post.has_liked = false;
      }
    }

    return {
      ...state,
      data,
    };
  }

  return state;
};

const setNextPageData = (state, action) => {
  const { payload } = action;

  if (state.data && state.data.data) {
    const data = _cloneDeep(state.data);

    if (data.data.feeds) {
      const feeds = (data.data.feeds || []).concat(payload.data.feeds || []);

      return {
        ...state,
        data: {
          ...payload,
          data: {
            feeds,
          },
        },
        isLoading: false,
      };
    }
  }

  return state;
};

export const reducer = makeDefaultServerCallReducer({
  loadingActions: [ACCOUNT_FEED_LOADING],
  successActions: [ACCOUNT_FEED_SUCCESS],
  errorActions: [ACCOUNT_FEED_ERROR],
  resetActions: [ACCOUNT_FEED_RESET],
  otherActions: [
    { name: ACCOUNT_FEED_LIKE_POST, callback: likeFeedPost },
    { name: ACCOUNT_FEED_UNLIKE_POST, callback: unlikeFeedPost },
    { name: ACCOUNT_FEED_SHARE_POST, callback: shareFeedPost },
    { name: ACCOUNT_FEED_NEXT_PAGE, callback: setNextPageData },
  ],
  initialState,
});
