// @flow
import { createAction } from 'redux-actions';
import {
  USER_DETAILS_ERROR,
  USER_DETAILS_LOADING,
  USER_DETAILS_RESET,
  USER_DETAILS_SUCCESS,
  USER_DETAILS_SET_AS_FOLLOWED,
  USER_DETAILS_SET_AS_UNFOLLOWED,
  USER_DETAILS_ADD_CONTRIBUTION,
} from './userDetails.constants';
import { instance as rorUsersApiProvider } from '../../../services/RoRUsersApiProvider';
import type { GetUserDetailsPayload } from '../../../services/RoRUsersApiProvider';

export const userDetailsError = createAction(USER_DETAILS_ERROR);

export const userDetailsLoading = createAction(USER_DETAILS_LOADING);

export const userDetailsSuccess = createAction(USER_DETAILS_SUCCESS);

export const userDetailsReset = createAction(USER_DETAILS_RESET);

export const userDetailsSetAsFollowed = createAction(
  USER_DETAILS_SET_AS_FOLLOWED,
);

export const userDetailsSetAsUnfollowed = createAction(
  USER_DETAILS_SET_AS_UNFOLLOWED,
);

export const userDetailsAddContribution = createAction(
  USER_DETAILS_ADD_CONTRIBUTION,
);

export const getUserDetails = (payload: GetUserDetailsPayload) => {
  return async (dispatch: Function) => {
    let response;

    try {
      dispatch(userDetailsLoading());

      response = await rorUsersApiProvider.userDetails(payload);
      dispatch(userDetailsSuccess(response));
    } catch (error) {
      dispatch(userDetailsError(error));
    }
  };
};
