// @flow
import React from 'react';
import Link from '../Link/Link';
import CardHeader from '../CardHeader/CardHeader';
import './CardGroupJoin.scss';
import { URL_GROUP_PAGE } from '../../config/urls';
import type { UserVerifiedLevel } from '../../services/RoRUsersApiProvider';

type Props = {
  userAvatar: string,
  userIsCandidate: boolean,
  userVerifiedLevel: UserVerifiedLevel,
  userVerifiedDetail: string,
  userFullName: string,
  userId: number,
  groupName: string,
  groupId: number,
  date: string,
};

const CardGroupJoin = (props: Props) => {
  const groupUrl = URL_GROUP_PAGE.replace(':groupId', props.groupId);

  return (
    <div className="row card-group-join">
      <div className="col-12 px-0 mx-0">
        <CardHeader
          userId={props.userId}
          userAvatar={props.userAvatar}
          userIsCandidate={props.userIsCandidate}
          userVerifiedLevel={props.userVerifiedLevel}
          userVerifiedDetail={props.userVerifiedDetail}
          userFullName={props.userFullName}
          date={props.date}
        />
        <div className="row align-items-center">
          <div className="col-2 px-1 text-center pt-2">
            <img src="/images/icon_groups.png" alt="" className="img-fluid" />
          </div>
          <div className="col-10 pr-4">
            <p className="f-nunito fs-18 mb-0">
              Has joined "
              <Link href={groupUrl} title={props.groupName}>
                {props.groupName}
              </Link>
              " Group
            </p>
          </div>
        </div>
      </div>
    </div>
  );
};

export default CardGroupJoin;
