// @flow
import Axios, { AxiosResponse, AxiosError } from 'axios';
import RoRApiResponseTransformer from '../utilities/RoRApiResponseTransformer';
import DefaultResponse from './RoRApiProvider';
import RoRApiProvider from './RoRApiProvider';
class RoRCollaboratorsApiProvider extends RoRApiProvider {
  constructor() {
    super();
  }

  getCollaboratorsList(): Promise<collaboratorsListResponse> {
    const requestConfig = {
      headers: {
        'Content-Type': 'application/json',
        ...this.getAuthenticationHeaders(),
      },
    };

    return this.axios
      .get('apis/collaborators/', requestConfig)
      .then((response: AxiosResponse) =>
        RoRApiResponseTransformer.parseResponse(response),
      )
      .catch((error: AxiosError) =>
        Promise.reject(RoRApiResponseTransformer.parseError(error)),
      );
  }

  inviteCollaborators(payload: {
    emails: string,
  }): Promise<collaboratorsListResponse> {
    const requestConfig = {
      headers: {
        'Content-Type': 'application/json',
        ...this.getAuthenticationHeaders(),
      },
    };

    const formData: inviteCollaboratorsPayload = {
      user: {
        cm_invitations_attributes: [],
      },
    };

    payload.emails
      .split(',')
      .map(email => formData.user.cm_invitations_attributes.push({ email }));

    return this.axios
      .post('apis/collaborators', formData, requestConfig)
      .then((response: AxiosResponse) =>
        RoRApiResponseTransformer.parseResponse(response),
      )
      .catch((error: AxiosError) =>
        Promise.reject(RoRApiResponseTransformer.parseError(error)),
      );
  }

  deleteCollaborator(
    payload: deleteCollaboratorPayload,
  ): Promise<DefaultResponse> {
    const requestConfig = {
      headers: {
        'Content-Type': 'application/json',
        ...this.getAuthenticationHeaders(),
      },
    };

    return this.axios
      .delete(`apis/collaborators/${payload.id}`, requestConfig)
      .then((response: AxiosResponse) =>
        RoRApiResponseTransformer.parseResponse(response),
      )
      .catch((error: AxiosError) =>
        Promise.reject(RoRApiResponseTransformer.parseError(error)),
      );
  }
}

export type inviteCollaboratorsPayload = {
  user: {
    cm_invitations_attributes: {
      email: string,
    }[],
  },
};

export type deleteCollaboratorPayload = {
  id: number,
};

export type collaboratorsListResponse = {
  data: {
    collaborators: {
      id: number,
      email: string,
      status: string,
    }[],
  },
};

export default RoRCollaboratorsApiProvider;
export const instance = new RoRCollaboratorsApiProvider();
