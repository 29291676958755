import {
  accountContendersList,
  accountContendersListReset as listReset,
} from './accountContenders.actions';

export const mapStateToProps = state => {
  return {
    accountContendersListData: state.accountContenders.data,
    accountContendersListError: state.accountContenders.error,
    accountContendersListIsLoading: state.accountContenders.isLoading,
  };
};

export const mapDispatchToProps = dispatch => {
  return {
    accountContendersList(payload) {
      dispatch(accountContendersList(payload));
    },
    accountContendersListReset() {
      dispatch(listReset());
    },
  };
};

export type AccountContendersMapStateToProps = {
  accountContendersData: any,
  accountContendersError: any,
  accountContendersIsLoading: boolean,
};

export type AccountContendersMapDispatchToProps = {
  accountContendersList: Function,
  accountContendersListReset: Function,
};
export default {
  mapStateToProps,
  mapDispatchToProps,
};
