import {
  getAccountCandidateCommitments,
  accountCandidateCommitmentsReset as reset,
} from './accountCandidateCommitments.actions';
import type { GetCandidateCommitmentsResponse } from '../../../services/RoRUsersApiProvider';

export const mapStateToProps = state => {
  return {
    accountCandidateCommitmentsData: state.accountCandidateCommitments.data,
    accountCandidateCommitmentsError: state.accountCandidateCommitments.error,
    accountCandidateCommitmentsIsLoading:
      state.accountCandidateCommitments.isLoading,
  };
};

export const mapDispatchToProps = dispatch => {
  return {
    getAccountCandidateCommitments(payload) {
      dispatch(getAccountCandidateCommitments(payload));
    },
    accountCandidateCommitmentsReset() {
      dispatch(reset());
    },
  };
};

export type AccountCandidateCommitmentsMapStateToProps = {
  accountCandidateCommitmentsData: GetCandidateCommitmentsResponse,
  accountCandidateCommitmentsError: any,
  accountCandidateCommitmentsIsLoading: boolean,
};

export type AccountCandidateCommitmentsMapDispatchToProps = {
  getAccountCandidateCommitments: Function,
  accountCandidateCommitmentsReset: Function,
};

export default {
  mapStateToProps,
  mapDispatchToProps,
};
