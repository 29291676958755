// @flow
import * as React from 'react';
import './PublicProfileAboutTab.scss';

type Props = {
  name: string,
  biography: string,
};

const PublicProfileAboutTab = (props: Props) => (
  <div className="about content fix-width">
    <div className="row">
      <div className="col-8 content--centered">
        <h3 className="mb-3">{props.name}</h3>
        <p>{props.biography}</p>
      </div>
    </div>
  </div>
);

export default PublicProfileAboutTab;
