// @flow
import React from 'react';
import { connect } from 'react-redux';
import { message } from 'antd';
import { injectStripe, Elements, StripeProvider } from 'react-stripe-elements';
import Button from '../Button/Button';
import Input from '../Input/Input';
import Select from '../Select/Select';
import account from '../../redux/modules/account/account.containers';
import contributionAddNewAch from '../../redux/modules/contributionAddNewAch/contributionAddNewAch.containers';
import type { AccountMapStateToProps } from '../../redux/modules/account/account.containers';
import type {
  ContributionAddNewAchMapStateToProps,
  ContributionAddNewAchDispatchToProps,
} from '../../redux/modules/contributionAddNewAch/contributionAddNewAch.containers';
import './ContributeNewACH.scss';

type Props = AccountMapStateToProps &
  ContributionAddNewAchMapStateToProps &
  ContributionAddNewAchDispatchToProps & {
    onSuccess?: Function,
    onError?: (error: any) => void,
    onCancel: () => void,
    customPaymentToken?: string,
    customPaymentEmail?: string,
  };

const stripeAPIKey =
  process.env.RAZZLE_STRIPE_API_KEY || 'pk_test_5QDgOcr1u7KDPpNSql2cUiLi';
const defaultErrorMessage =
  "We're unable to process your request. Try again later.";

class ContributeNewACH extends React.Component<Props> {
  constructor(props: Props) {
    super(props);

    this.props.contributionAddNewAchReset();
  }

  componentDidUpdate(prevProps: Props) {
    if (
      !prevProps.contributionAddNewAchData &&
      this.props.contributionAddNewAchData
    ) {
      const messageText = `Your bank account was successfully submitted and is pending for verification. 
        You'll receive two micro-deposits, which are part of the validation process, in around 2 workdays.
        Please visit your profile page once you receive these transactions to verify your ACH account.`;

      message.success(messageText, 10);

      if (this.props.onSuccess) {
        this.props.onSuccess(messageText);
      }
    }

    if (
      !prevProps.contributionAddNewAchError &&
      this.props.contributionAddNewAchError
    ) {
      const error = this.props.contributionAddNewAchError.localMessage;

      message.error(error || defaultErrorMessage, 5);

      if (this.props.onError) {
        this.props.onError(error);
      }
    }
  }

  render() {
    return (
      <div className="contribute-new-ach">
        <div className="contribute-new-ach__head">
          <div className="row">
            <div className="col-12">
              <h4 className="contribute-new-ach__title">New ACH</h4>
            </div>
          </div>
        </div>
        <div className="row">
          <div className="col-12">
            <StripeProvider apiKey={stripeAPIKey}>
              <Elements>
                <StripeACHForm
                  onTokenCreated={this.handleTokenCreated}
                  onTokenError={this.handleError}
                  onCancel={this.props.onCancel}
                  accountData={this.props.accountData}
                  accountDataError={this.props.accountDataError}
                  accountDataIsLoading={this.props.accountDataIsLoading}
                  isSubmittingSource={this.props.contributionAddNewAchIsLoading}
                />
              </Elements>
            </StripeProvider>
          </div>
        </div>
      </div>
    );
  }

  handleTokenCreated = (token: any) => {
    const {
      customPaymentToken: customToken,
      customPaymentEmail: customEmail,
    } = this.props;
    this.props.addNewAch({
      bank_account_source: token.id,
      customToken,
      customEmail,
    });
  };

  handleError = (error: any) => {
    message.error((error && error.message) || defaultErrorMessage, 5);

    if (this.props.onError) {
      this.props.onError(error);
    }
  };
}

type ACHFormProps = AccountMapStateToProps & {
  stripe: any,
  onTokenCreated: Function,
  onTokenError?: (error: any) => void,
  onCancel: () => void,
  isSubmittingSource: boolean,
};

type ACHFormState = {
  routingNumber: string,
  accountNumber: string,
  accountHolderName: string,
  accountType: 'individual' | 'company',
  stripeErrors: {
    code: string,
    doc_url: string,
    message: string,
    param: string,
    type: string,
  } | null,
  isCreatingSource: boolean,
  formTouched: boolean,
};

class ACHForm extends React.Component<ACHFormProps, ACHFormState> {
  constructor(props) {
    super(props);

    this.state = {
      routingNumber: '',
      accountNumber: '',
      accountHolderName: '',
      accountType: 'individual',
      isCreatingSource: false,
      stripeErrors: null,
      formTouched: false,
    };
  }

  render() {
    return (
      <div className="ach-form">
        <div className="ach-form__body">
          <div className="row pb-3">
            <Input
              className="col-12 col-md-6"
              name="routingNumber"
              label="ABA/Routing Number"
              useFormik={false}
              onChange={this.handleInputChange}
              useOnlyBorderedErrors
              type="text"
              error={this.getInputError('routing_number')}
            />
            <Input
              className="col-12 col-md-6"
              name="accountNumber"
              label="Bank account number"
              useFormik={false}
              onChange={this.handleInputChange}
              useOnlyBorderedErrors
              type="text"
              error={this.getInputError('account_number')}
            />
          </div>
          <div className="row pb-3">
            <Select
              className="col-12 col-md-6"
              label="Account type"
              placeholder=""
              name="accountType"
              useFormik={false}
              value={this.state.accountType}
              onChange={this.handleInputChange}
              options={[
                { label: 'Individual', value: 'individual' },
                { label: 'Company', value: 'company' },
              ]}
              useOnlyBorderedErrors
              error={this.getInputError('account_holder_type')}
            />
            <Input
              className="col-12 col-md-6"
              name="accountHolderName"
              label="Account holder name"
              useFormik={false}
              onChange={this.handleInputChange}
              useOnlyBorderedErrors
              type="text"
              error={this.getInputError('account_holder_name')}
            />
          </div>
        </div>
        <div className="ach-form__footer">
          <div className="row justify-content-center">
            <div className="col-4 col-md-3">
              <Button
                disabled={
                  this.state.isCreatingSource || this.props.isSubmittingSource
                }
                type="button"
                onClick={this.props.onCancel}
                buttonType="link"
                noBorder
                className="modal-new-group__cancel-button">
                Cancel
              </Button>
            </div>
            <div className="col-4 col-md-3">
              <Button
                loading={
                  this.state.isCreatingSource || this.props.isSubmittingSource
                }
                type="button"
                onClick={this.generateSource}
                buttonType="primary"
                block
                noBorder>
                Create
              </Button>
            </div>
          </div>
          {this.renderError()}
        </div>
      </div>
    );
  }

  renderError() {
    const { stripeErrors, formTouched } = this.state;
    let error = '';

    if (this.hasEmptyFields() && formTouched) {
      error = 'Please fill up all the required fields';
    } else if (stripeErrors && stripeErrors.message) {
      error = stripeErrors.message;
    }

    return error ? (
      <div className="row">
        <div className="col-12 col-md-11">
          <span className="ach-form__error">{error}</span>
        </div>
      </div>
    ) : null;
  }

  hasEmptyFields() {
    const {
      routingNumber,
      accountHolderName,
      accountNumber,
      accountType,
    } = this.state;
    return (
      !routingNumber || !accountHolderName || !accountNumber || !accountType
    );
  }

  getInputError(stripeFiledName: string) {
    const { stripeErrors, formTouched } = this.state;
    const fieldNameMap = {
      routing_number: 'routingNumber',
      account_number: 'accountNumber',
      account_holder_name: 'accountHolderName',
      account_holder_type: 'accountType',
    };

    return (stripeErrors &&
      stripeErrors.param.indexOf(stripeFiledName) !== -1) ||
      (formTouched && !this.state[fieldNameMap[stripeFiledName]])
      ? 'error'
      : '';
  }

  handleInputChange = (data: any) => {
    this.setState(() => ({ [data.name]: data.value }));
  };

  generateSource = () => {
    this.setState(
      () => ({ stripeErrors: null, formTouched: true }),
      async () => {
        if (this.hasEmptyFields()) return;

        this.setState(() => ({ isCreatingSource: true }));

        try {
          const {
            token,
            error: tokenError,
          } = await this.props.stripe.createToken('bank_account', {
            country: 'US',
            currency: 'usd',
            routing_number: this.state.routingNumber,
            account_number: this.state.accountNumber,
            account_holder_name: this.state.accountHolderName,
            account_holder_type: this.state.accountType,
          });

          if (tokenError) {
            return this.setStripeErrors(tokenError);
          }

          this.props.onTokenCreated(token);
          this.setState(() => ({ isCreatingSource: false }));
        } catch (e) {
          console.log(e);
          this.setStripeErrors();
        }
      },
    );
  };

  setStripeErrors(stripeErrors?: any = null) {
    this.setState(
      () => ({
        isCreatingSource: false,
        stripeErrors: stripeErrors,
      }),
      () => {
        if (this.props.onTokenError) {
          this.props.onTokenError(this.state.stripeErrors);
        }
      },
    );
  }
}

const mapStateToProps = (state, ownProps) => {
  return {
    ...account.mapStateToProps(state),
    ...contributionAddNewAch.mapStateToProps(state),
    ...ownProps,
  };
};

const mapDispatchToProps = dispatch => {
  return {
    ...contributionAddNewAch.mapDispatchToProps(dispatch),
  };
};

const StripeACHForm = injectStripe(ACHForm);

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(ContributeNewACH);
