//@flow
const regex = /^\(?([0-9]{3})\)?[-.]?([0-9]{3})[-.]?([0-9]{4})$/i;

export const normalizePhoneNumber = (value: string) => {
  if (!isValidPhoneNumber(value)) {
    throw 'Invalid phone number';
  }
  return value.trim().replace(/[-.]/g, '');
};

/**
 * Only supports US phone numbers
 *
 * @param value
 * @returns {boolean}
 */
const isValidPhoneNumber = (value: string) => {
  return regex.test((value || '').trim());
};

export default isValidPhoneNumber;
