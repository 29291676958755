import { getRaceGroups, raceGroupsReset as reset } from './raceGroups.actions';
import type { RaceGroup } from '../../../services/RoRRacesApiProvider';

export const mapStateToProps = state => {
  return {
    raceGroupsData: state.raceGroups.data,
    raceGroupsError: state.raceGroups.error,
    raceGroupsIsLoading: state.raceGroups.isLoading,
  };
};

export const mapDispatchToProps = dispatch => {
  return {
    getGroupsForRace(payload) {
      dispatch(getRaceGroups(payload));
    },
    raceGroupsReset() {
      dispatch(reset());
    },
  };
};

export type RaceGroupsMapStateToProps = {
  raceGroupsData: { groups: RaceGroup } | null,
  raceGroupsError: any,
  raceGroupsIsLoading: boolean,
};

export type RaceGroupsMapDispatchToProps = {
  getGroupsForRace: Function,
  raceGroupsReset: Function,
};

export default {
  mapStateToProps,
  mapDispatchToProps,
};
