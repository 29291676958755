// @flow
import makeDefaultServerCallReducer from '../../utilities/makeDefaultServerCallReducer';
import {
  GROUP_ADD_MODERATORS_ERROR,
  GROUP_ADD_MODERATORS_LOADING,
  GROUP_ADD_MODERATORS_SUCCESS,
  GROUP_ADD_MODERATORS_RESET,
} from './groupAddModerators.constants';

const initialState = {
  isLoading: false,
  error: null,
  data: null,
};

export const reducer = makeDefaultServerCallReducer({
  loadingActions: [GROUP_ADD_MODERATORS_LOADING],
  successActions: [GROUP_ADD_MODERATORS_SUCCESS],
  errorActions: [GROUP_ADD_MODERATORS_ERROR],
  resetActions: [GROUP_ADD_MODERATORS_RESET],
  initialState,
});
