import {
  accountPostsReset as reset,
  getAccountPosts as getPosts,
} from './accountPosts.actions';

export const mapStateToProps = state => {
  return {
    accountPostsData: state.accountPosts.data,
    accountPostsError: state.accountPosts.error,
    accountPostsIsLoading: state.accountPosts.isLoading,
  };
};

export const mapDispatchToProps = dispatch => {
  return {
    getAccountPosts() {
      dispatch(getPosts());
    },
    accountPostsReset() {
      dispatch(reset());
    },
  };
};

export default {
  mapStateToProps,
  mapDispatchToProps,
};
