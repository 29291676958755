const stripeClientId =
  process.env.RAZZLE_STRIPE_CLIENT_ID || 'ca_De9Y8FlF4pyRJhjZTwLS4gQ7k2x6P0fU';

export const URL_LOGIN_PAGE = '/login';
export const URL_FORGOT_PASSWORD_PAGE = '/reset-password';
export const URL_MY_ACCOUNT_PAGE = '/my-account';
export const URL_MY_ACCOUNT_MAIN_PAGE = '/my-account/main';
// export const URL_MY_ACCOUNT_POSTS__PAGE = '/my-account/posts';
export const URL_MY_ACCOUNT_COLLABORATORS_PAGE = '/my-account/collaborators';
export const URL_INBOX_PAGE = '/inbox';
export const URL_ABOUT_PAGE = '/about';
export const URL_PRIVACY_POLICY_FAQ = '/faq';
export const URL_CODE_OF_CONDUCT_PAGE = '/code-of-conduct';
export const URL_CONTACT_US_PAGE = '/contact-us';
export const URL_SIGN_UP_PAGE = '/signup';
export const URL_MANAGER_SIGN_UP_PAGE = '/signup/campaign-manager/:tokenId';
export const URL_PRIVACY_POLICY_PAGE = '/privacy-policy';
export const URL_SEARCH_PAGE = '/search';
export const URL_DASHBOARD_PAGE = '/dashboard';
export const URL_DASHBOARD_POSTS_PAGE = '/dashboard/posts';
export const URL_DASHBOARD_PEOPLE_GROUPS_PAGE = '/dashboard/people-groups';
export const URL_DASHBOARD_CONTENDERS_PAGE = '/dashboard/contenders';
export const URL_DASHBOARD_ABOUT_PAGE = '/dashboard/about';
export const URL_DASHBOARD_CONTRIBUTIONS_PAGE = '/dashboard/contributions';
export const URL_FEED_PAGE = '/feed';
export const URL_GROUP_PAGE = '/groups/:groupId';
export const URL_GROUP_POSTS_PAGE = `${URL_GROUP_PAGE}/posts`;
export const URL_GROUP_MEMBERS_PAGE = `${URL_GROUP_PAGE}/members`;
export const URL_GROUP_ABOUT_PAGE = `${URL_GROUP_PAGE}/about`;
export const URL_GROUP_CANDIDATES_PAGE = `${URL_GROUP_PAGE}/candidates`;
export const URL_POST_PAGE = '/posts/:postId';
export const URL_PRYTANY_TV_PAGE = '/prytanytv';
export const URL_RACE_ACTIVITY_PAGE = '/race/:raceId/activity';
export const URL_RACE_ACTIVITY_RANKING_PAGE =
  '/race/:raceId/listCandidates-ranking';
export const URL_USER_PUBLIC_PROFILE = '/user-profile/:id';
export const URL_USER_AGREEMENT = '/user-agreement';
export const URL_MOBILE_CONTRIBUTIONS = '/mobile-contributions/:userId';
export const URL_MOBILE_CONTRIBUTIONS_DIRECT = `${URL_MOBILE_CONTRIBUTIONS}/direct`;
export const URL_MOBILE_CONTRIBUTIONS_COMMITTED = `${URL_MOBILE_CONTRIBUTIONS}/committed`;
export const URL_MOBILE_CONTRIBUTIONS_OPTIONS = `${URL_MOBILE_CONTRIBUTIONS}/options`;
export const URL_MOBILE_CONTRIBUTIONS_PLEDGE = `${URL_MOBILE_CONTRIBUTIONS}/pledge`;
export const URL_MOBILE_CONTRIBUTIONS_PAYMENT_COMPLETED = `${URL_MOBILE_CONTRIBUTIONS}/completed`;
export const URL_MOBILE_CONTRIBUTIONS_NEW_ACH_SUBMITTED = `${URL_MOBILE_CONTRIBUTIONS}/new-ach-submitted`;
export const URL_VERIFY_ACCOUNT_PAGE = '/get-prytany-verified';
export const URL_MOBILE_WHAT_IS_PRYTANY = '/what-is-prytany';
export const URL_CONTRIBUTION_RULES = '/contribution-rules';
