// @flow
import { createAction } from 'redux-actions';
import {
  COLLABORATORS_LIST_ERROR,
  COLLABORATORS_LIST_SUCCESS,
  COLLABORATORS_LIST_LOADING,
  COLLABORATORS_LIST_RESET,
  COLLABORATORS_INVITE_LOADING,
  COLLABORATORS_INVITE_SUCCESS,
  COLLABORATORS_INVITE_ERROR,
  COLLABORATORS_INVITE_RESET,
  COLLABORATORS_DELETE_SUCCESS,
  COLLABORATORS_DELETE_ERROR,
  COLLABORATORS_DELETE_LOADING,
  COLLABORATORS_DELETE_RESET,
} from './collaborators.constants';
import { instance as rorApiProvider } from '../../../services/RoRCollaboratorsApiProvider';

export const collaboratorsListError = createAction(COLLABORATORS_LIST_ERROR);

export const collaboratorsListLoading = createAction(
  COLLABORATORS_LIST_LOADING,
);

export const collaboratorsListSuccess = createAction(
  COLLABORATORS_LIST_SUCCESS,
);

export const collaboratorsListReset = createAction(COLLABORATORS_LIST_RESET);

export const collaboratorsList = () => {
  return async (dispatch: Function) => {
    try {
      dispatch(collaboratorsListReset());

      dispatch(collaboratorsListLoading());

      const response = await rorApiProvider.getCollaboratorsList();

      dispatch(collaboratorsListSuccess(response));
    } catch (error) {
      dispatch(collaboratorsListError(error));
    }
  };
};
