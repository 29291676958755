// @flow
import React from 'react';
import Modal from '../Modal/Modal';
import { List } from 'antd';
import './ModalListUserInterests.scss';

type Props = {
  isOpen: boolean,
  userInterestsList: Array<Object>,
  onCloseRequest?: Function,
  className?: string,
};

type FormValues = {
  toUser: string,
  message: string,
};

const ModalListUserInterests = (props: Props) => {
  const { className, userInterestsList, isOpen, ...rest } = props;
  return (
    <div className={className}>
      <Modal contentLabel="User Interests List" isOpen={isOpen} {...rest}>
        <div className="col-12 text-center pb-2">
          <h4>Interests List</h4>
        </div>
        <div>
          <List
            bordered
            dataSource={userInterestsList}
            renderItem={item => (
              <List.Item className="modal-interests__list-item">
                <div className="modal-interests__icon-container">
                  <img
                    src="/images/icon-check.png"
                    className="modal-interests__icon"
                  />
                </div>{' '}
                {item.name}
              </List.Item>
            )}
          />
        </div>
      </Modal>
    </div>
  );
};

export default ModalListUserInterests;
