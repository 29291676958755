// @flow
import React from 'react';
import Link from '../Link/Link';
import Button from '../Button/Button';
import CardHeader from '../CardHeader/CardHeader';
import { URL_USER_PUBLIC_PROFILE } from '../../config/urls';
import Avatar from '../Avatar/Avatar';
import Currency from '../Currency/Currency';
import type { UserVerifiedLevel } from '../../services/RoRUsersApiProvider';
import './CardCommitment.scss';

type Props = {
  userAvatar: string,
  userIsCandidate: boolean,
  userVerifiedLevel: UserVerifiedLevel,
  userVerifiedDetail: string,
  userFullName: string,
  userId: number,
  candidateName: string,
  candidateId: number,
  candidateAvatar: string,
  amount: number,
  date: string,
  onContributeButtonClick: Function,
  frequency: number,
  period: number,
  buttonChildren?: any,
  buttonProps?: { [key: string]: any },
  showButton: boolean,
};

const CardCommitment = (props: Props) => {
  const candidateUrl = URL_USER_PUBLIC_PROFILE.replace(
    ':id',
    props.candidateId,
  );

  return (
    <div className="row card-commitment">
      <div className="col-12 px-0 mx-0">
        <CardHeader
          userId={props.userId}
          userAvatar={props.userAvatar}
          userIsCandidate={props.userIsCandidate}
          userVerifiedLevel={props.userVerifiedLevel}
          userVerifiedDetail={props.userVerifiedDetail}
          userFullName={props.userFullName}
          date={props.date}
          textAfterUser="Committed:"
        />
        <div className="row align-items-center">
          <div className="col-2 px-1 text-center pt-2">
            <img
              src="/images/icon_contribute_commited.png"
              alt=""
              className="img-fluid"
            />
          </div>
          <div className="col-10 col-md-7 col-lg-8 pr-4">
            <div className="row align-items-center">
              <div className="col-2 px-md-0 pt-2 pt-md-0">
                <Avatar
                  type="x-small"
                  source={props.candidateAvatar}
                  isCandidate={false}
                />
              </div>
              <div className="col-10 col-10 px-lg-2 px-xl-1">
                <div className="d-inline-flex align-items-center">
                  <Link
                    className="f-nunito fs-18 mb-0"
                    href={candidateUrl}
                    title={props.candidateName}>
                    {props.candidateName}
                  </Link>
                  <img
                    src="/images/icon_candidate_big.png"
                    className="card-commitment__badge"
                    alt=""
                  />
                </div>
                <div className="d-block d-md-inline-block pl-md-2">
                  <Currency amount={props.amount} showDecimals={false} />
                </div>
              </div>
            </div>
            <div className="row align-items-center card-commitment__condition-details">
              <img src="/images/icon_calendar.png" alt="" />
              <span>
                {props.frequency}{' '}
                {props.frequency > 1 ? 'times' : 'single time'} in &nbsp;
                {props.period} {props.period > 1 ? 'Months' : 'Month'}
              </span>
            </div>
          </div>
          {props.showButton && (
            <div className="col-12 col-md-3 col-lg-2  text-center text-md-right pl-0">
              <Button
                buttonType="primary"
                type="button"
                onClick={props.onContributeButtonClick}
                size="small"
                {...props.buttonProps}>
                {props.buttonChildren}
              </Button>
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

CardCommitment.defaultProps = {
  buttonChildren: 'Contribute',
  buttonProps: {},
};

export default CardCommitment;
