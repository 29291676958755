// @flow
import { createAction } from 'redux-actions';
import {
  COLLABORATORS_DELETE_LOADING,
  COLLABORATORS_DELETE_SUCCESS,
  COLLABORATORS_DELETE_ERROR,
  COLLABORATORS_DELETE_RESET,
} from './collaboratorsDelete.constants';
import { collaboratorsList } from '../collaborators/collaborators.actions';
import { instance as rorApiProvider } from '../../../services/RoRCollaboratorsApiProvider';

export const collaboratorsDeleteLoading = createAction(
  COLLABORATORS_DELETE_LOADING,
);

export const collaboratorsDeleteSuccess = createAction(
  COLLABORATORS_DELETE_SUCCESS,
);

export const collaboratorsDeleteError = createAction(
  COLLABORATORS_DELETE_ERROR,
);

export const collaboratorsDeleteReset = createAction(
  COLLABORATORS_DELETE_RESET,
);

export const collaboratorsDelete = (payload: { id: number }) => {
  return async (dispatch: Function) => {
    try {
      dispatch(collaboratorsDeleteReset());

      dispatch(collaboratorsDeleteLoading());

      const response = await rorApiProvider.deleteCollaborator(payload);

      dispatch(collaboratorsDeleteSuccess(response));
      dispatch(collaboratorsList());
    } catch (error) {
      dispatch(collaboratorsDeleteError(error));
    }
  };
};
