import {
  removeMemberFromGroup as removeMember,
  groupRemoveMemberReset as reset,
} from './groupRemoveMember.actions';
import type { DeleteGroupRemoveMemberPayload } from '../../../services/RoRGroupsApiProvider';
import type { DefaultResponse } from '../../../services/RoRApiProvider';

export const mapStateToProps = state => {
  return {
    groupRemoveMemberData: state.groupRemoveMember.data,
    groupRemoveMemberError: state.groupRemoveMember.error,
    groupRemoveMemberIsLoading: state.groupRemoveMember.isLoading,
  };
};

export const mapDispatchToProps = dispatch => {
  return {
    removeMemberFromGroup(payload: DeleteGroupRemoveMemberPayload) {
      dispatch(removeMember(payload));
    },
    groupRemoveMemberReset() {
      dispatch(reset());
    },
  };
};

export type GroupRemoveMemberMapStateToProps = {
  groupRemoveMemberData: DefaultResponse,
  groupRemoveMemberError: any,
  groupRemoveMemberIsLoading: boolean,
};

export type GroupRemoveMemberMapDispatchToProps = {
  removeMemberFromGroup: (payload: DeleteGroupRemoveMemberPayload) => void,
  groupRemoveMemberReset: () => void,
};

export default {
  mapStateToProps,
  mapDispatchToProps,
};
