import {
  accountFeedReset as reset,
  getAccountFeed as getFeed,
  getAccountFeedNextPage as getNextPage,
} from './accountFeed.actions';
import type {
  GetUserFeedPayload,
  GetUserFeedResponse,
} from '../../../services/RoRUsersApiProvider';

export const mapStateToProps = state => {
  return {
    accountFeedData: state.accountFeed.data,
    accountFeedError: state.accountFeed.error,
    accountFeedIsLoading: state.accountFeed.isLoading,
  };
};

export const mapDispatchToProps = dispatch => {
  return {
    getAccountFeed(payload: GetUserFeedPayload) {
      dispatch(getFeed(payload));
    },
    getAccountFeedNextPage(payload: { limit?: number }) {
      dispatch(getNextPage(payload));
    },
    accountFeedReset() {
      dispatch(reset());
    },
  };
};

export type AccountFeedMapStateToProps = {
  accountFeedData: GetUserFeedResponse,
  accountFeedError: any,
  accountFeedIsLoading: boolean,
};

export type AccountFeedMapDispatchToProps = {
  getAccountFeed: (payload: GetUserFeedPayload) => void,
  getAccountFeedNextPage: (payload: { limit?: number }) => void,
  accountFeedReset: () => void,
};

export default {
  mapStateToProps,
  mapDispatchToProps,
};
