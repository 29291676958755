// @flow
import * as React from 'react';
import Button from '../Button/Button';
import type { SignupStep } from '../../pages/SignUpPage/SignUpPage';
import RoleCode from '../../constants/RoleCode';
import withPreventDefault from '../../utilities/withPreventDefault';

type Props = {
  onBackButtonClick?: Function,
  steps: SignupStep[],
  currentStep: SignupStep,
  signUpRole: string,
};

const SignUpHeader = ({
  signUpRole,
  steps,
  currentStep,
  onBackButtonClick,
}: Props) => {
  const handleGoBack = withPreventDefault(() => {
    onBackButtonClick && onBackButtonClick();
  });

  return (
    <React.Fragment>
      <h1>Create an account</h1>
      <Button
        buttonType="link"
        type="button"
        onClick={handleGoBack}
        className="pr-2">
        <b>{'<'} Back</b>
      </Button>
      <span className="pr-2">|</span>{' '}
      <b>Step {steps.indexOf(currentStep) + 1}</b> of {steps.length}
      <hr />
    </React.Fragment>
  );
};

export default SignUpHeader;
