// @flow
import { createAction } from 'redux-actions';
import {
  ACCOUNT_CONTENDERS_LIST_ERROR,
  ACCOUNT_CONTENDERS_LIST_SUCCESS,
  ACCOUNT_CONTENDERS_LIST_LOADING,
  ACCOUNT_CONTENDERS_LIST_RESET,
} from './accountContenders.constants';
import { instance as rorApiProvider } from '../../../services/RoRUsersApiProvider';

export const accountContendersListError = createAction(
  ACCOUNT_CONTENDERS_LIST_ERROR,
);

export const accountContendersListLoading = createAction(
  ACCOUNT_CONTENDERS_LIST_LOADING,
);

export const accountContendersListSuccess = createAction(
  ACCOUNT_CONTENDERS_LIST_SUCCESS,
);

export const accountContendersListReset = createAction(
  ACCOUNT_CONTENDERS_LIST_RESET,
);

export const accountContendersList = (payload: { userId: number }) => {
  return async (dispatch: Function) => {
    // TODO: Improve security: should save the token on the react server.
    // TODO: Use rememberMe
    try {
      dispatch(accountContendersListReset());

      dispatch(accountContendersListLoading());

      const response = await rorApiProvider.accountContenders(payload);

      dispatch(accountContendersListSuccess(response));
    } catch (error) {
      dispatch(accountContendersListError(error));
    }
  };
};
