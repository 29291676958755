//@flow
import * as React from 'react';
import classNames from 'classnames';

import './Flyout.scss';

type Props = {
  children: any,
  className?: string,
  trigger: any,
  hoverable?: boolean,
  isExpanded?: boolean,
  displayArrow?: boolean,
  displayIndicator?: boolean,
};

type State = {
  expanded: boolean,
};

class Flyout extends React.Component<Props, State> {
  contentRef: any = null;

  static defaultProps = {
    hoverable: true,
    isExpanded: false,
    displayArrow: true,
    displayIndicator: true,
  };

  constructor(props: Props) {
    super(props);
    this.state = { expanded: this.props.isExpanded || false };
  }

  componentDidMount() {
    if (typeof window !== 'undefined' && this.props.isExpanded) {
      document.addEventListener('click', this.handleDocumentClick);
    }
  }

  componentDidUpdate(prevProps: Props, prevState: State) {
    if (
      this.props.isExpanded !== prevProps.isExpanded &&
      this.props.isExpanded !== this.state.expanded
    ) {
      this.setState(() => ({ expanded: this.props.isExpanded }));
    }

    if (this.state.expanded !== prevState.expanded && this.state.expanded) {
      if (typeof window !== 'undefined') {
        document.addEventListener('click', this.handleDocumentClick);
      }
    } else if (
      this.state.expanded !== prevState.expanded &&
      !this.state.expanded
    ) {
      if (typeof window !== 'undefined') {
        document.removeEventListener('click', this.handleDocumentClick);
      }
    }
  }

  componentWillUnmount() {
    if (typeof window !== 'undefined') {
      document.removeEventListener('click', this.handleDocumentClick);
    }
  }

  render() {
    return (
      <div
        className={this.getClassName()}
        onMouseEnter={this.handleExpand}
        onMouseLeave={this.handleCollapse}>
        {this.renderTrigger()}
        {this.renderContent()}
      </div>
    );
  }

  renderTrigger() {
    return (
      <div className="fly-out__trigger" onClick={this.handleIndicatorClick}>
        {this.props.trigger}
        {this.props.displayIndicator ? (
          <div className="fly-out__trigger-indicator" />
        ) : null}
      </div>
    );
  }

  renderContent() {
    let content = null;

    if (this.state.expanded) {
      content = (
        <div
          className="fly-out__content"
          onMouseLeave={this.handleCollapse}
          ref={this.setContentRef}>
          {this.props.displayArrow ? (
            <div className="fly-out__content-arrow" />
          ) : null}
          {this.props.children}
        </div>
      );
    }

    return content;
  }

  setContentRef = (ref: any) => {
    this.contentRef = ref;
  };

  handleCollapse = () => {
    if (this.props.hoverable) {
      setTimeout(() => this.setState(() => ({ expanded: false })), 500);
    }
  };

  handleExpand = () => {
    if (this.props.hoverable) {
      this.setState(() => ({ expanded: true }));
    }
  };

  getClassName() {
    return classNames({
      'fly-out': true,
      [this.props.className || '']: this.props.className,
    });
  }

  handleIndicatorClick = () => {
    if (!this.props.hoverable) {
      this.setState((prevState: State) => ({ expanded: !prevState.expanded }));
    }
  };

  handleDocumentClick = (e: any) => {
    if (
      this.contentRef &&
      !this.contentRef.isEqualNode(e.target) &&
      !this.props.isExpanded
    ) {
      this.setState(() => ({ expanded: false }));
    }
  };
}

export default Flyout;
