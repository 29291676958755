import React from 'react';
import { withRouter } from 'react-router';

// This component is used to force React Router to bring us to the top of the
// page whenever we switch routes within the client.
// See here for solution details: https://reacttraining.com/react-router/web/guides/scroll-restoration
class ScrollToTop extends React.Component {
  componentDidUpdate(prevProps) {
    if (this.props.location !== prevProps.location) {
      window.scrollTo(0, 0);
    }
  }

  render() {
    return this.props.children;
  }
}

export default withRouter(ScrollToTop);
