// @flow
import { createAction } from 'redux-actions';
import {
  POST_SHARE_ERROR,
  POST_SHARE_LOADING,
  POST_SHARE_RESET,
  POST_SHARE_SUCCESS,
} from './postShare.constants';
import { instance as rorPostsApiProvider } from '../../../services/RoRPostsApiProvider';
import type { SharePostPayload } from '../../../services/RoRPostsApiProvider';
import { accountPostsSharePost } from '../accountPosts/accountPosts.actions';
import { groupPostsSharePost } from '../groupPosts/groupPosts.actions';
import { raceFeedSharePost } from '../raceFeed/raceFeed.actions';
import { userPostsSharePost } from '../userPosts/userPosts.actions';
import { postDetailsSharePost } from '../postDetails/postDetails.actions';
import { userProfileFeedSharePost } from '../userProfileFeed/userProfileFeed.actions';
import { accountFeedSharePost } from '../accountFeed/accountFeed.actions';

export const postShareError = createAction(POST_SHARE_ERROR);

export const postShareLoading = createAction(POST_SHARE_LOADING);

export const postShareSuccess = createAction(POST_SHARE_SUCCESS);

export const postShareReset = createAction(POST_SHARE_RESET);

export const postShare = (payload: SharePostPayload) => {
  return async (dispatch: Function) => {
    try {
      let response = {};
      dispatch(postShareLoading());

      if (payload.postId) {
        response = await rorPostsApiProvider.sharePost(payload);
        dispatch(accountPostsSharePost(payload));
        dispatch(groupPostsSharePost(payload));
        dispatch(raceFeedSharePost(payload));
        dispatch(userPostsSharePost(payload));
        dispatch(postDetailsSharePost(payload));
        dispatch(userProfileFeedSharePost(payload));
        dispatch(accountFeedSharePost(payload));
      } else {
        return dispatch(postShareError(new Error('postId is required.')));
      }

      dispatch(postShareSuccess(response));
    } catch (error) {
      dispatch(postShareError(error));
    }
  };
};
