// @flow
import * as React from 'react';
import { Field } from 'formik';
import classNames from 'classnames';

import './SelectableBadges.scss';

type Props = {
  className?: string,
  inputClassName?: string,
  error?: string,
  name: string,
  items: Array<{ label: string, value: string }>,
  onChange?: Function,
};

const SelectableBadges = (props: Props) => {
  return (
    <div className={getClassName(props)}>
      <Field
        name={props.name}
        render={({ field, form }) =>
          props.items.map(item => {
            const inputId = `badge-${props.name}-${item.value}`;
            const inputRef = React.createRef();
            return (
              <a
                href="#"
                onClick={e => handleBadgeClick(e, inputRef)}
                className={getBadgeClassName({ props, field, item })}
                key={item.value}>
                <input
                  ref={inputRef}
                  onBlur={field.onBlur}
                  name={props.name}
                  value={item.value}
                  id={inputId}
                  type="checkbox"
                  className="badge__checkbox-input"
                  checked={field.value.includes(item.value)}
                  onChange={() =>
                    handleInputChange({
                      props,
                      item,
                      field,
                      form,
                      callback: props.onChange,
                    })
                  }
                />
                <span className="badge-text">{item.label}</span>
              </a>
            );
          })
        }
      />
      {props.error ? (
        <span className="selectable-badges__error-message">{props.error}</span>
      ) : null}
    </div>
  );
};

function handleBadgeClick(e, inputRef: any) {
  e.preventDefault();
  inputRef.current.click();
  inputRef.current.blur();
}

function handleInputChange({ props, item, field, form, callback }) {
  let nextValue;

  if (field.value.includes(item.value)) {
    nextValue = field.value.filter(value => value !== item.value);
    form.setFieldValue(props.name, nextValue);
  } else {
    nextValue = field.value.concat(item.value);
    form.setFieldValue(props.name, nextValue);
  }

  if (callback) {
    callback(nextValue);
  }
}

function getClassName(props) {
  return classNames({
    'selectable-badges': true,
    [props.className || '']: props.className,
  });
}

function getBadgeClassName({ props, item, field }) {
  const isSelected = field.value.includes(item.value);
  // Some of these classes are inherited from bootstrap. Do not rename.
  return classNames({
    badge: true,
    'badge-pill': true,
    'badge-light': !isSelected,
    'badge-primary': isSelected,
    'badge--error': props.error,
  });
}

export default SelectableBadges;
