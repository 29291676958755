// @flow
import React from 'react';
import Redirect from 'react-router-dom/Redirect';
import Switch from 'react-router-dom/Switch';
import {
  URL_MOBILE_CONTRIBUTIONS_COMMITTED,
  URL_MOBILE_CONTRIBUTIONS_DIRECT,
  URL_MOBILE_CONTRIBUTIONS_NEW_ACH_SUBMITTED,
  URL_MOBILE_CONTRIBUTIONS_OPTIONS,
  URL_MOBILE_CONTRIBUTIONS_PAYMENT_COMPLETED,
  URL_MOBILE_CONTRIBUTIONS_PLEDGE,
} from '../../config/urls';
import MobileContributionsCommittedPage from './MobileContributionsCommittedPage';
import MobileContributionsDirectPage from './MobileContributionsDirectPage';
import MobileContributionsOptionsPage from './MobileContributionsOptionsPage';
import MobileContributionsPledgePage from './MobileContributionsPledgePage';
import Route from 'react-router-dom/Route';
import MobileContributionsPaymentCompletedPage from './MobileContributionsPaymentCompletedPage';
import './MobileContributionsPage.scss';
import MobileContributionsNewACHSubmittedPage from './MobileContributionsNewACHSubmittedPage';
import { MobileContributionsContextProvider } from '../../context/mobileContributionsContext';

type Props = {
  children: any,
  location: any,
  history: any,
};
type State = {};

class MobileContributionsPage extends React.Component<Props, State> {
  subSections: { path: string, component: any }[];

  constructor(props: Props) {
    super(props);

    this.subSections = [
      {
        path: URL_MOBILE_CONTRIBUTIONS_COMMITTED,
        component: MobileContributionsCommittedPage,
      },
      {
        path: URL_MOBILE_CONTRIBUTIONS_DIRECT,
        component: MobileContributionsDirectPage,
      },
      {
        path: URL_MOBILE_CONTRIBUTIONS_OPTIONS,
        component: MobileContributionsOptionsPage,
      },
      {
        path: URL_MOBILE_CONTRIBUTIONS_PLEDGE,
        component: MobileContributionsPledgePage,
      },
      {
        path: URL_MOBILE_CONTRIBUTIONS_PAYMENT_COMPLETED,
        component: MobileContributionsPaymentCompletedPage,
      },
      {
        path: URL_MOBILE_CONTRIBUTIONS_NEW_ACH_SUBMITTED,
        component: MobileContributionsNewACHSubmittedPage,
      },
    ];
  }

  // TODO: maybe we should display a 404 page instead of redirecting to homepage
  render() {
    const routes = this.subSections.map((subSection: any) => {
      return (
        <Route
          exact
          key={subSection.path}
          path={subSection.path}
          component={subSection.component}
        />
      );
    });

    routes.push(
      <Redirect
        key="/"
        to={{
          pathname: '/',
          state: { from: URL_MOBILE_CONTRIBUTIONS_OPTIONS },
        }}
      />,
    );

    return (
      <div className="mobile-contributions-page">
        <MobileContributionsContextProvider>
          <Switch>{routes}</Switch>
        </MobileContributionsContextProvider>
      </div>
    );
  }
}

export default MobileContributionsPage;
