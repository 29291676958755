import {
  SIGN_UP_ERROR,
  SIGN_UP_LOADING,
  SIGN_UP_SUCCESS,
  SIGN_UP_RESET,
} from './signUp.constants';

const initialState = {
  isLoading: false,
  error: null,
  data: null,
};

export const reducer = (state = initialState, action) => {
  switch (action.type) {
    case SIGN_UP_LOADING:
      return {
        ...state,
        isLoading: true,
        error: null,
        data: null,
      };
    case SIGN_UP_SUCCESS:
      return {
        ...state,
        isLoading: false,
        error: null,
        data: action.payload,
      };
    case SIGN_UP_ERROR:
      return {
        ...state,
        isLoading: false,
        error: action.payload,
        data: null,
      };
    case SIGN_UP_RESET:
      return {
        ...state,
        isLoading: false,
        error: null,
        data: null,
      };
    default:
      return state;
  }
};
