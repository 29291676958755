// @flow
import React from 'react';
import uuid from 'uuid/v4';
import { Table as AntdTable } from 'antd';
import './Table.scss';

class Table extends React.Component<any> {
  id: string;
  tableWrapperElement: HTMLElement | null;
  headingListeners: {
    target: HTMLElement,
    callback: Function,
    type: string,
  }[] = [];

  constructor(props: any) {
    super(props);
    this.id = `table_${uuid()}`;
  }

  componentDidMount() {
    const { columns } = this.props;
    this.tableWrapperElement = document.getElementById(this.id);

    if (columns && columns.length) {
      const sortableIndices = [];

      columns.forEach((column, index) => {
        if (column.sorter) {
          sortableIndices.push(index);
        }
      });

      this.addHeadingListeners(sortableIndices);
    }
  }

  componentWillUnmount() {
    this.removeHeadingListeners();
  }

  render() {
    return (
      <div className="table-component">
        <AntdTable {...Object.assign({}, this.props, { id: this.id })} />
      </div>
    );
  }

  addHeadingListeners(sortableIndices: number[]) {
    if (this.tableWrapperElement) {
      this.tableWrapperElement
        .querySelectorAll('thead > tr > th')
        .forEach((heading: any, index) => {
          if (sortableIndices.indexOf(index) !== -1) {
            const callback = () => this.handleHeadingClick(heading);

            this.headingListeners.push({
              target: heading,
              callback,
              type: 'click',
            });

            heading.addEventListener('click', callback);
          }
        });
    }
  }

  handleHeadingClick = (heading: HTMLElement) => {
    const isSortingUp: boolean =
      heading.querySelector('.ant-table-column-sorter-up.on') !== null;
    let sorter: any;

    if (isSortingUp) {
      sorter = heading.querySelector('.ant-table-column-sorter-down');
    } else {
      sorter = heading.querySelector('.ant-table-column-sorter-up');
    }

    if (sorter) {
      sorter.click();
    }
  };

  removeHeadingListeners() {
    this.headingListeners.forEach(listener => {
      listener.target.removeEventListener(listener.type, listener.callback);
    });
  }
}

export default Table;
