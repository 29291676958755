// @flow
import { createAction } from 'redux-actions';
import {
  USER_RSS_FEED_ERROR,
  USER_RSS_FEED_LOADING,
  USER_RSS_FEED_SUCCESS,
  USER_RSS_FEED_RESET,
} from './userRssFeed.constants';
import { instance as rorUsersApiProvider } from '../../../services/RoRUsersApiProvider';
import type { GetUserRSSFeedPayload } from '../../../services/RoRUsersApiProvider';

export const userRssFeedError = createAction(USER_RSS_FEED_ERROR);

export const userRssFeedLoading = createAction(USER_RSS_FEED_LOADING);

export const userRssFeedSuccess = createAction(USER_RSS_FEED_SUCCESS);

export const userRssFeedReset = createAction(USER_RSS_FEED_RESET);

export const getUserRssFeed = (payload: GetUserRSSFeedPayload) => {
  return async (dispatch: Function) => {
    try {
      dispatch(userRssFeedLoading());

      const response = await rorUsersApiProvider.userRSSFeed(payload);

      dispatch(userRssFeedSuccess(response));
    } catch (error) {
      dispatch(userRssFeedError(error));
    }
  };
};
