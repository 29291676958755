import React from 'react';
import { hydrate } from 'react-dom';
import { Provider } from 'react-redux';
import { PersistGate } from 'redux-persist/integration/react';
import BrowserRouter from 'react-router-dom/BrowserRouter';
import objectFitImages from 'object-fit-images';
import { clientPersistor, clientStore } from './redux/clientStore';
import App from './App';
import ScrollToTop from './components/ScrollToTop/ScrollToTop';

objectFitImages();

hydrate(
  <Provider store={clientStore}>
    <PersistGate loading={null} persistor={clientPersistor}>
      <BrowserRouter>
        <ScrollToTop>
          <App />
        </ScrollToTop>
      </BrowserRouter>
    </PersistGate>
  </Provider>,
  document.getElementById('root'),
);

// TODO: fix issue with redux hot reloading which is sending a new copy of the store
if (module.hot) {
  module.hot.accept();
}
