import React from 'react';
import Button from '../Button/Button';
import './ContributeOptions.scss';
import ContributionType from '../../constants/ContributionType';

export type ContributionTypeType = 'direct' | 'committed' | 'pledge';

type ModalContributeContentProps = {
  allowPledge: boolean,
  onContributionTypeSelected: (contributionType: ContributionTypeType) => void,
};

const ContributeOptions = ({
  allowPledge,
  onContributionTypeSelected,
}: ModalContributeContentProps) => {
  return (
    <React.Fragment>
      <h2 className="modal-component__title text-center mt-3 mt-md-5 mb-3">
        How would you like to make your Contribution?
      </h2>
      <div className="row pb-5 pt-4 fix-width">
        <div className="col-12">
          <div className="row fix-width">
            <div className="col-md text-center contribute-options__option px-0 px-md-3 px-lg-5 py-3">
              <div className="row">
                <div className="col-4 col-md-12">
                  <img
                    src="/images/icon_contribute_direct.png"
                    alt=""
                    className="img-fluid"
                  />
                  <h3 className="contribute-options__option-name">Direct</h3>
                </div>
                <div className="col-8 col-md-12 pl-1 pr-3 contribute-options__description">
                  <p className="f-nunito text-left text-md-center">
                    Make a one-time contribution that will go to your candidate
                    right now.
                  </p>
                </div>
              </div>
              <div className="row justify-content-center">
                <Button
                  className="col-4 col-md-6"
                  type="button"
                  buttonType="primary-inverted"
                  onClick={() =>
                    onContributionTypeSelected(ContributionType.DIRECT)
                  }>
                  Select
                </Button>
              </div>
              <hr className="d-block d-md-none" />
            </div>
            <div className="col-md text-center contribute-options__option px-0 px-md-3 px-lg-5 py-3">
              <div className="row">
                <div className="col-4 col-md-12">
                  <img
                    src="/images/icon_contribute_commited.png"
                    alt=""
                    className="img-fluid"
                  />
                  <h3 className="contribute-options__option-name">Commited</h3>
                </div>
                <div className="col-8 col-md-12 pl-1 pr-3 contribute-options__description">
                  <p className="f-nunito text-left text-md-center">
                    Schedule multiple contributions over a period of time.
                    Future commitments are cancelled if your candidate drops
                    out.
                  </p>
                </div>
              </div>
              <div className="row justify-content-center">
                <Button
                  className="col-4 col-md-6"
                  type="button"
                  buttonType="primary-inverted"
                  onClick={() =>
                    onContributionTypeSelected(ContributionType.COMMITTED)
                  }>
                  Select
                </Button>
              </div>
              <hr className="d-block d-md-none" />
            </div>
            {allowPledge && (
              <div className="col-md text-center contribute-options__option px-0 px-md-3 px-lg-5 py-3">
                <div className="row">
                  <div className="col-4 col-md-12">
                    <img
                      src="/images/icon_contribute_pledge.png"
                      alt=""
                      className="img-fluid"
                    />
                    <h3 className="contribute-options__option-name">Pledge</h3>
                  </div>
                  <div className="col-8 col-md-12 pl-1 pr-3 contribute-options__description">
                    <p className="f-nunito text-left text-md-center">
                      Commit a contribution to a candidate to amplify or counter
                      a special interest.
                    </p>
                  </div>
                </div>
                <div className="row justify-content-center">
                  <Button
                    className="col-4 col-md-6"
                    type="button"
                    buttonType="primary-inverted"
                    onClick={() =>
                      onContributionTypeSelected(ContributionType.PLEDGE)
                    }>
                    Select
                  </Button>
                </div>
              </div>
            )}
          </div>
        </div>
      </div>
    </React.Fragment>
  );
};

export default ContributeOptions;
