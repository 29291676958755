// @flow
import _get from 'lodash/get';
import _cloneDeep from 'lodash/cloneDeep';
import makeDefaultServerCallReducer from '../../utilities/makeDefaultServerCallReducer';
import {
  ACCOUNT_INBOX_MESSAGE_LIST_ERROR,
  ACCOUNT_INBOX_MESSAGE_LIST_LOADING,
  ACCOUNT_INBOX_MESSAGE_LIST_SUCCESS,
  ACCOUNT_INBOX_MESSAGE_LIST_RESET,
  ACCOUNT_INBOX_MESSAGE_LIST_REMOVE_ITEM,
  ACCOUNT_INBOX_MESSAGE_LIST_MARK_AS_READ_ITEM,
} from './accountInboxMessageList.constants';

const initialState = {
  isLoading: false,
  error: null,
  data: null,
};

const removeItem = (state, action) => {
  const payload = action.payload;

  if (_get(state, 'data.data.messages.length')) {
    const data = _cloneDeep(state.data);
    let messages = [];

    data.data.messages.forEach(message => {
      if (message.id !== payload.messageId) {
        messages.push(message);
      }
    });

    data.data.messages = messages;

    return {
      ...state,
      data,
    };
  }

  return {
    ...state,
    isLoading: false,
  };
};

const markAsReadItem = (state, action) => {
  const payload = action.payload;

  if (_get(state, 'data.data.messages.length')) {
    const data = _cloneDeep(state.data);
    const messageToMark = data.data.messages.find(
      (message: any) => message.id === payload.messageId,
    );

    messageToMark.is_read = true;

    return {
      ...state,
      data,
    };
  }

  return state;
};

export const reducer = makeDefaultServerCallReducer({
  loadingActions: [ACCOUNT_INBOX_MESSAGE_LIST_LOADING],
  successActions: [ACCOUNT_INBOX_MESSAGE_LIST_SUCCESS],
  errorActions: [ACCOUNT_INBOX_MESSAGE_LIST_ERROR],
  resetActions: [ACCOUNT_INBOX_MESSAGE_LIST_RESET],
  otherActions: [
    { name: ACCOUNT_INBOX_MESSAGE_LIST_REMOVE_ITEM, callback: removeItem },
    {
      name: ACCOUNT_INBOX_MESSAGE_LIST_MARK_AS_READ_ITEM,
      callback: markAsReadItem,
    },
  ],
  initialState,
});
