// @flow
import { createAction } from 'redux-actions';
import {
  USER_PROFILE_FOLLOWING_ERROR,
  USER_PROFILE_FOLLOWING_LOADING,
  USER_PROFILE_FOLLOWING_RESET,
  USER_PROFILE_FOLLOWING_SUCCESS,
} from './userProfileFollowing.constants';
import { instance as rorProfileApiProvider } from '../../../services/RoRProfileApiProvider';

export const userProfileFollowingError = createAction(
  USER_PROFILE_FOLLOWING_ERROR,
);

export const userProfileFollowingLoading = createAction(
  USER_PROFILE_FOLLOWING_LOADING,
);

export const userProfileFollowingSuccess = createAction(
  USER_PROFILE_FOLLOWING_SUCCESS,
);

export const userProfileFollowingReset = createAction(
  USER_PROFILE_FOLLOWING_RESET,
);

export const getUserProfileFollowing = (payload: any) => {
  return async (dispatch: Function) => {
    try {
      dispatch(userProfileFollowingLoading());

      const response = await rorProfileApiProvider.getFollowing(payload);

      dispatch(userProfileFollowingSuccess(response));
    } catch (error) {
      dispatch(userProfileFollowingError(error));
    }
  };
};
