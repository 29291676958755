import {
  accountFollowingsReset as reset,
  getAccountFollowings as getFollowings,
} from './accountFollowings.actions';

export const mapStateToProps = state => {
  return {
    accountFollowingsData: state.accountFollowings.data,
    accountFollowingsError: state.accountFollowings.error,
    accountFollowingsIsLoading: state.accountFollowings.isLoading,
  };
};

export const mapDispatchToProps = dispatch => {
  return {
    getAccountFollowings() {
      dispatch(getFollowings());
    },
    accountFollowingsReset() {
      dispatch(reset());
    },
  };
};

export default {
  mapStateToProps,
  mapDispatchToProps,
};
