// @flow
import React from 'react';
import { connect } from 'react-redux';
import _get from 'lodash/get';
import { Formik, FormikComputedProps } from 'formik';
import * as yup from 'yup';
import Button from '../Button/Button';
import Input from '../Input/Input';
import groupRename from '../../redux/modules/groupRename/groupRename.containers';
import groupDetails from '../../redux/modules/groupDetails/groupDetails.containers';
import type {
  GroupDetailsMapDispatchToProps,
  GroupDetailsMapStateToProps,
} from '../../redux/modules/groupDetails/groupDetails.containers';
import type {
  GroupRenameMapDispatchToProps,
  GroupRenameMapStateToProps,
} from '../../redux/modules/groupRename/groupRename.containers';
import { message } from 'antd';
import withDisplayDimensions from '../../hoc/withDisplayDimensions';
import type { WithDisplayDimensionsOutputProps } from '../../hoc/withDisplayDimensions';

type Props = GroupDetailsMapDispatchToProps &
  GroupDetailsMapStateToProps &
  GroupRenameMapDispatchToProps &
  GroupRenameMapStateToProps &
  WithDisplayDimensionsOutputProps & {
    onSuccess?: () => void,
    onCancel?: () => void,
    onError?: (error: any) => void,
  };

class GroupRename extends React.Component<Props, {}> {
  constructor(props: Props) {
    super(props);

    this.props.groupRenameReset();
  }

  componentDidUpdate(prevProps: Props) {
    if (!prevProps.groupRenameData && this.props.groupRenameData) {
      message.success('Group was successfully renamed.');

      if (this.props.onSuccess) {
        this.props.onSuccess(this.props.groupRenameData);
      }
    }

    if (!prevProps.groupRenameError && this.props.groupRenameError) {
      const error =
        this.props.groupRenameError.localMessage ||
        "An error has occurred and the group wasn't updated";

      message.error(error);

      if (this.props.onError) {
        this.props.onError(error);
      }
    }
  }

  render() {
    return (
      <div className="group-rename">
        <div className="row fix-width">
          <div className="col-12">
            <Formik {...this.getFormikProps()}>
              {formikProps => this.renderInnerForm(formikProps)}
            </Formik>
          </div>
        </div>
      </div>
    );
  }

  renderInnerForm(formikProps: FormikComputedProps) {
    const { touched, errors, handleSubmit } = formikProps;

    return (
      <form className="row fix-width" onSubmit={handleSubmit}>
        <Input
          className="col-12 px-2 pt-4"
          label="Name"
          size="small"
          name="name"
          type="text"
          error={touched.name && errors.name ? errors.name : ''}
        />
        <div className="col-12">
          <div className="group-rename__footer row justify-content-md-end py-5">
            <Button
              onClick={this.handleCancel}
              buttonType="outline"
              type="button"
              className="col-4 col-md-4 col-lg-2 mx-2 mb-3 mb-md-0"
              size={this.props.isMobile ? 'small' : 'normal'}
              disabled={this.props.groupRenameIsLoading}>
              Cancel
            </Button>
            <Button
              buttonType="primary"
              type="submit"
              className="col-4 col-md-4 col-lg-2 mx-2"
              size={this.props.isMobile ? 'small' : 'normal'}
              loading={this.props.groupRenameIsLoading}>
              Change
            </Button>
          </div>
        </div>
      </form>
    );
  }

  getFormikProps() {
    return {
      initialValues: {
        name: _get(this.props, 'groupDetailsData.group.name'),
      },
      validationSchema: yup.object().shape({
        name: yup.string().required('This field is required'),
      }),
      onSubmit: this.onSubmit,
    };
  }

  onSubmit = (values: { name: string }) => {
    this.props.groupRename({
      name: values.name,
      groupId: this.props.groupDetailsData.group.id,
    });
  };

  handleCancel = () => {
    if (this.props.onCancel) {
      this.props.onCancel();
    }
  };
}

const mapStateToProps = (state, ownProps) => {
  return {
    ...groupDetails.mapStateToProps(state),
    ...groupRename.mapStateToProps(state),
    ...ownProps,
  };
};

const mapDispatchToProps = dispatch => {
  return {
    ...groupDetails.mapDispatchToProps(dispatch),
    ...groupRename.mapDispatchToProps(dispatch),
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(withDisplayDimensions(GroupRename));
