// @flow
import Axios, { AxiosResponse, AxiosError } from 'axios';
import RoRApiResponseTransformer from '../utilities/RoRApiResponseTransformer';
import RoRApiProvider from './RoRApiProvider';
import type { DefaultResponse } from './RoRApiProvider';

class RoRInboxMessagesApiProvider extends RoRApiProvider {
  constructor() {
    super();
  }

  sendMessage(payload: PostNewMessagePayload): Promise<PostNewPostResponse> {
    const requestConfig = {
      headers: {
        'Content-Type': 'multipart/form-data',
        ...this.getAuthenticationHeaders(),
      },
    };
    const formData = new FormData();

    Object.keys(payload).forEach((key: string) => {
      const value = payload[key];

      if (value !== null) {
        formData.append(`message[${key}]`, value);
      }
    });

    return this.axios
      .post('/apis/messages', formData, requestConfig)
      .then((response: AxiosResponse) =>
        RoRApiResponseTransformer.parseResponse(response),
      )
      .catch((error: AxiosError) =>
        Promise.reject(RoRApiResponseTransformer.parseError(error)),
      );
  }
}

export type PostNewMessagePayload = {
  message: {
    body: string,
    receiver_id: string,
  },
};

export default RoRInboxMessagesApiProvider;
export const instance = new RoRInboxMessagesApiProvider();
