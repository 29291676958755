import {
  makeDirectDonation as makeDonation,
  contributionDirectDonationMakeReset as reset,
} from './contributionDirectDonationMake.actions';
import type { PostMakeDirectDonationPayload } from '../../../services/RoRContributionsApiProvider';
import type { DefaultResponse } from '../../../services/RoRApiProvider';

export const mapStateToProps = state => {
  return {
    contributionDirectDonationMakeData:
      state.contributionDirectDonationMake.data,
    contributionDirectDonationMakeError:
      state.contributionDirectDonationMake.error,
    contributionDirectDonationMakeIsLoading:
      state.contributionDirectDonationMake.isLoading,
  };
};

export const mapDispatchToProps = dispatch => {
  return {
    makeDirectDonation(payload: PostMakeDirectDonationPayload) {
      dispatch(makeDonation(payload));
    },
    contributionDirectDonationMakeReset() {
      dispatch(reset());
    },
  };
};

export type ContributionDirectDonationMakeMapStateToProps = {
  contributionDirectDonationMakeData: DefaultResponse,
  contributionDirectDonationMakeError: any,
  contributionDirectDonationMakeIsLoading: boolean,
};

export type ContributionDirectDonationMakeDispatchToProps = {
  makeDirectDonation: (payload: PostMakeDirectDonationPayload) => void,
  contributionDirectDonationMakeReset: () => void,
};

export default {
  mapStateToProps,
  mapDispatchToProps,
};
