// @flow
import _get from 'lodash/get';
import { createAction } from 'redux-actions';
import {
  LIST_INTERESTS_ERROR,
  LIST_INTERESTS_LOADING,
  LIST_INTERESTS_SUCCESS,
  LIST_INTERESTS_RESET,
  LIST_INTERESTS_NEXT_PAGE,
} from './listInterests.constants';
import { instance as rorApiProvider } from '../../../services/RoRApiProvider';
import { clientStore } from '../../../redux/clientStore';

export const listInterestsError = createAction(LIST_INTERESTS_ERROR);

export const listInterestsLoading = createAction(LIST_INTERESTS_LOADING);

export const listInterestsSuccess = createAction(LIST_INTERESTS_SUCCESS);

export const listInterestsNextPage = createAction(LIST_INTERESTS_NEXT_PAGE);

export const listInterestsReset = createAction(LIST_INTERESTS_RESET);

export const listInterests = () => {
  return async (dispatch: Function) => {
    try {
      dispatch(listInterestsLoading());

      const response = await rorApiProvider.listBasedOnTypes({
        type: 'interests',
      });

      dispatch(listInterestsSuccess(response));
    } catch (error) {
      dispatch(listInterestsError(error));
    }
  };
};

export const listInterestsGetNextPage = () => {
  return async (dispatch: Function) => {
    const data = _get(clientStore.getState(), 'listInterests.data', {});
    const nextPage = data.next_page;

    if (nextPage) {
      dispatch(listInterestsLoading());

      const response = await rorApiProvider.listBasedOnTypes({
        type: 'interests',
        page: nextPage,
      });

      dispatch(listInterestsNextPage(response));
    }
  };
};
