import {
  getAccountInboxMessageList as getInboxList,
  accountInboxMessageListReset as reset,
} from './accountInboxMessageList.actions';
import type {
  GetUserInboxMessageListPayload,
  GetUserInboxMessageListResponse,
} from '../../../services/RoRUsersApiProvider';

export const mapStateToProps = state => {
  return {
    accountInboxMessageListData: state.accountInboxMessageList.data,
    accountInboxMessageListError: state.accountInboxMessageList.error,
    accountInboxMessageListIsLoading: state.accountInboxMessageList.isLoading,
  };
};

export const mapDispatchToProps = dispatch => {
  return {
    getAccountInboxMessageList(payload: GetUserInboxMessageListPayload) {
      dispatch(getInboxList(payload));
    },
    accountInboxMessageListReset() {
      dispatch(reset());
    },
  };
};

export type AccountInboxMessageListMapStateToProps = {
  accountInboxMessageListData: GetUserInboxMessageListResponse,
  accountInboxMessageListError: any,
  accountInboxMessageListIsLoading: boolean,
};

export type AccountInboxMessageListMapDispatchToProps = {
  getAccountInboxMessageList: (payload: GetUserInboxMessageListPayload) => void,
  accountInboxMessageListReset: () => void,
};

export default {
  mapStateToProps,
  mapDispatchToProps,
};
