// @flow
import {
  ACCOUNT_FOLLOWERS_SUCCESS,
  ACCOUNT_FOLLOWERS_RESET,
  ACCOUNT_FOLLOWERS_LOADING,
  ACCOUNT_FOLLOWERS_ERROR,
} from './accountFollowers.constants';
import makeDefaultServerCallReducer from '../../utilities/makeDefaultServerCallReducer';

const initialState = {
  isLoading: false,
  error: null,
  data: null,
};

export const reducer = makeDefaultServerCallReducer({
  loadingActions: [ACCOUNT_FOLLOWERS_LOADING],
  successActions: [ACCOUNT_FOLLOWERS_SUCCESS],
  errorActions: [ACCOUNT_FOLLOWERS_ERROR],
  resetActions: [ACCOUNT_FOLLOWERS_RESET],
  initialState,
});
