// @flow
import * as React from 'react';
import UserRoleBadge from '../UserRoleBadge/UserRoleBadge';
import './UserRoleSelector.scss';
import classNames from 'classnames';
import RoleCode from '../../constants/RoleCode';

const { CITIZEN, CANDIDATE } = RoleCode;

type role = 'candidate' | 'citizen' | 'political_affiliation';

type Props = {
  handleSelectRole?: Function,
  initialRole: role,
};

type State = {
  selectedRole: role,
};

class UserRoleSelector extends React.Component<Props, State> {
  constructor(props: Props) {
    super(props);

    this.state = {
      selectedRole: this.props.initialRole,
    };
  }

  render() {
    return (
      <div className="user-role-selector">
        <div className="row">
          <div className="col-12 pt-3">
            <p>
              <b>Type of User</b>
            </p>
          </div>
        </div>
        <div className="row d-flex justify-content-center">
          <div className="col-md-4 col-sm-12 px-0 mb-3">
            <UserRoleBadge
              role="citizen"
              className={classNames('mx-auto', {
                active: this.state.selectedRole === CITIZEN,
              })}
              onClick={() => this.handleSelectRole(CITIZEN)}
            />
          </div>
          <div className="col-md-4 col-sm-12 px-0">
            <UserRoleBadge
              role="candidate"
              className={classNames('mx-auto', {
                active: this.state.selectedRole === CANDIDATE,
              })}
              onClick={() => this.handleSelectRole(CANDIDATE)}
            />
          </div>
          <div className="col-md-4 col-sm-12 px-0">
            <UserRoleBadge
              role="political_affiliation"
              className={classNames('mx-auto', { active: false })}
            />
          </div>
        </div>
      </div>
    );
  }

  handleSelectRole = (role: role) => {
    this.setState(
      () => ({ selectedRole: role }),
      () => {
        if (this.props.handleSelectRole) {
          this.props.handleSelectRole(this.state.selectedRole);
        }
      },
    );
  };
}

export default UserRoleSelector;
