// @flow
import makeDefaultServerCallReducer from '../../utilities/makeDefaultServerCallReducer';
import {
  POST_LIKE_SUCCESS,
  POST_LIKE_RESET,
  POST_LIKE_LOADING,
  POST_LIKE_ERROR,
  POST_UNLIKE_SUCCESS,
  POST_UNLIKE_RESET,
  POST_UNLIKE_LOADING,
  POST_UNLIKE_ERROR,
} from './postLike.constants';

const initialState = {
  isLoading: false,
  error: null,
  data: null,
};

export const reducer = makeDefaultServerCallReducer({
  loadingActions: [POST_LIKE_LOADING, POST_UNLIKE_LOADING],
  successActions: [POST_LIKE_SUCCESS, POST_UNLIKE_SUCCESS],
  errorActions: [POST_LIKE_ERROR, POST_UNLIKE_ERROR],
  resetActions: [POST_LIKE_RESET, POST_UNLIKE_RESET],
  initialState,
});
