// @flow
import React from 'react';
import { Popconfirm } from 'antd';
import Avatar from '../Avatar/Avatar';
import Button from '../Button/Button';
import Link from '../Link/Link';
import './ListItemPendingRequest.scss';
import type { UserListView } from '../../services/RoRUsersApiProvider';
import { URL_USER_PUBLIC_PROFILE } from '../../config/urls';
import { isCandidate, isCitizen } from '../../utilities/authorization';
import fullName from '../../utilities/fullName';
import isPrytanyVerified from '../../utilities/isPrytanyVerified';

type Props = {
  user: UserListView,
  onApproveButtonClick: Function,
  onDenyButtonClick: Function,
  isLoading: boolean,
};

type State = {
  displayControls: boolean,
  isPopConfirmOpen: boolean,
};

class ListItemPendingRequest extends React.Component<Props, State> {
  constructor(props: Props) {
    super(props);

    this.state = {
      displayControls: false,
      isPopConfirmOpen: false,
    };
  }

  render() {
    const { user } = this.props;
    const userProfileUrl = URL_USER_PUBLIC_PROFILE.replace(':id', user.id);
    return (
      <li
        className="list-item-pending-request"
        onMouseEnter={this.handleMouseEnter}
        onMouseLeave={this.handleMouseLeave}>
        <div className="row fix-width align-items-center">
          {user.profile_image ? (
            <div className="col-3">
              <Link href={userProfileUrl}>
                <Avatar
                  source={user.profile_image}
                  type="x-small"
                  isCandidate={isCandidate(user)}
                  verifiedLevel={user.verified_level}
                  verifiedDetail={user.verified_detail}
                />
              </Link>
            </div>
          ) : null}
          <div className="col-6 px-0">
            <p>
              <Link href={userProfileUrl}>{fullName(user)}</Link>
            </p>
          </div>
          {this.state.displayControls ? (
            <div className="col-3 d-flex justify-content-center align-items-center">
              <Popconfirm
                title="Are you sure you want to delete this request?"
                onConfirm={this.handleDeny}
                okText="Yes"
                cancelText="No"
                onVisibleChange={this.handlePopConfirmVisibleChange}>
                <Button buttonType="link" type="button">
                  <img src="/images/icon_trash.png" alt="" />
                </Button>
              </Popconfirm>
              <Popconfirm
                title="Are you sure you want to approve this request?"
                onConfirm={this.handleApprove}
                okText="Yes"
                cancelText="No"
                onVisibleChange={this.handlePopConfirmVisibleChange}>
                <Button buttonType="link" type="button">
                  <img src="/images/icon_mark.png" alt="" />
                </Button>
              </Popconfirm>
            </div>
          ) : null}
        </div>
      </li>
    );
  }

  handleMouseEnter = () => {
    this.setState(() => ({ displayControls: true }));
  };

  handleMouseLeave = () => {
    if (!this.state.isPopConfirmOpen) {
      this.setState(() => ({ displayControls: false }));
    }
  };

  handleDeny = () => {
    if (this.props.onDenyButtonClick) {
      const {
        onApproveButtonClick,
        onDenyButtonClick,
        isLoading,
        ...rest
      } = this.props;

      this.props.onDenyButtonClick(rest);
    }
  };

  handleApprove = () => {
    if (this.props.onApproveButtonClick) {
      const {
        onApproveButtonClick,
        onDenyButtonClick,
        isLoading,
        ...rest
      } = this.props;

      this.props.onApproveButtonClick(rest);
    }
  };

  handlePopConfirmVisibleChange = (visible: boolean) => {
    this.setState((prevState: State) => ({
      isPopConfirmOpen: visible,
      displayControls: visible ? prevState.displayControls : false,
    }));
  };
}

export default ListItemPendingRequest;
