//@flow
import React from 'react';
import Route from 'react-router-dom/Route';
import _get from 'lodash/get';
import moment from 'moment';
import { connect } from 'react-redux';
import { message } from 'antd';
import CampaignOverview from '../../components/CampaignOverview/CampaignOverview';
import withDisplayDimensions from '../../hoc/withDisplayDimensions';
import TabPanel from '../../components/TabPanel/TabPanel';
import EditProfileTab from '../../components/EditProfileTab/EditProfileTab';
import ModalFirstTimeTutorial from '../../components/ModalFirstTimeTutorial/ModalFirstTimeTutorial';
import {
  URL_MY_ACCOUNT_COLLABORATORS_PAGE,
  URL_MY_ACCOUNT_MAIN_PAGE,
  URL_SIGN_UP_PAGE,
} from '../../config/urls';
import account from '../../redux/modules/account/account.containers';
import accountDetails from '../../redux/modules/accountDetails/accountDetails.containers';
import accountGroups from '../../redux/modules/accountGroups/accountGroups.containers';
import accountProfile from '../../redux/modules/accountProfile/accountProfile.containers';
import type { WithDisplayDimensionsOutputProps } from '../../hoc/withDisplayDimensions';
import type {
  AccountMapDispatchToProps,
  AccountMapStateToProps,
} from '../../redux/modules/account/account.containers';
import type {
  AccountProfileMapDispatchToProps,
  AccountProfileMapStateToProps,
} from '../../redux/modules/accountProfile/accountProfile.containers';
import type {
  AccountDetailsMapDispatchToProps,
  AccountDetailsMapStateToProps,
} from '../../redux/modules/accountDetails/accountDetails.containers';
import type {
  collaboratorsMapStateToProps,
  collaboratorsMapDispatchToProps,
} from '../../redux/modules/collaborators/collaborators.containers';
import type {
  collaboratorsInviteMapStateToProps,
  collaboratorsInviteMapDispatchToProps,
} from '../../redux/modules/collaboratorsInvite/collaboratorsInvite.containers';
import type {
  collaboratorsDeleteMapStateToProps,
  collaboratorsDeleteMapDispatchToProps,
} from '../../redux/modules/collaboratorsDelete/collaboratorsDelete.containers';
import collaborators from '../../redux/modules/collaborators/collaborators.containers';
import collaboratorsDelete from '../../redux/modules/collaboratorsDelete/collaboratorsDelete.containers';
import collaboratorsInvite from '../../redux/modules/collaboratorsInvite/collaboratorsInvite.containers';
import './MyAccount.scss';
import EditCollaboratorsTab from '../../components/EditCollaboratosTab/EditCollaboratorsTab';
import Loading from '../../components/Loading/Loading';
import {
  isCandidate,
  isPac,
  isPoliticalParty,
} from '../../utilities/authorization';
import type { UserProfile } from '../../services/RoRUsersApiProvider';

type Props = AccountMapDispatchToProps &
  AccountMapStateToProps &
  AccountProfileMapStateToProps &
  AccountProfileMapDispatchToProps &
  AccountDetailsMapStateToProps &
  AccountDetailsMapDispatchToProps &
  collaboratorsMapStateToProps &
  collaboratorsMapDispatchToProps &
  collaboratorsDeleteMapStateToProps &
  collaboratorsDeleteMapDispatchToProps &
  collaboratorsInviteMapStateToProps &
  collaboratorsInviteMapDispatchToProps &
  WithDisplayDimensionsOutputProps & {
    children: any,
    location: any,
    history: any,
    accountGroupsData: any,
    accountGroupsError: any,
    accountGroupsIsLoading: boolean,
    getAccountGroups: Function,
  };

type State = {
  initialTabIndex: number | null,
  modalOpened: 'tutorial' | null,
};

// TODO: some subsections should not be accessed by citizens.
// TODO: redirect when subsection url is not valid
class MyAccountPage extends React.Component<Props, State> {
  subSections: {
    label: string,
    getPath: () => string,
    component: any,
    accessibleByUser: (user: UserProfile) => boolean,
  }[];

  constructor(props: Props) {
    super(props);

    this.subSections = [
      {
        label: 'Main',
        getPath: () => URL_MY_ACCOUNT_MAIN_PAGE,
        component: () => <EditProfileTab {...this.props} />,
        accessibleByUser: (user: UserProfile) => true,
      },
      // TODO: posts section
      //{ label: 'Posts', path: URL_MY_ACCOUNT_POSTS__PAGE, component: () => <div>Posts</div> },
      {
        label: 'Banking Info',
        getPath: () => {
          return (
            (this.props.accountProfileData &&
              this.props.accountProfileData.user.bankingUrl) ||
            '#'
          );
        },
        component: false,
        regLink: true,
        accessibleByUser: (user: UserProfile) => {
          return isCandidate(user) || isPoliticalParty(user) || isPac(user);
        },
      },
      {
        label: 'Campaign Staff',
        getPath: () => URL_MY_ACCOUNT_COLLABORATORS_PAGE,
        component: () => <EditCollaboratorsTab {...this.props} />,
        accessibleByUser: (user: UserProfile) => {
          return isCandidate(user);
        },
      },
    ];

    let initialTabIndex = null;

    this.subSections.forEach((subSection: any, index: number) => {
      if (subSection.getPath() === this.props.location.pathname) {
        initialTabIndex = index;
      }
    });

    this.state = {
      initialTabIndex,
      modalOpened: null,
    };
  }

  componentWillMount() {
    // TODO: this request should probably be executed on the server side before rendering this view
    this.props.getAccountProfile();
    this.props.getAccountDetails();
    this.props.getAccountGroups();
    if (_get(this.props, 'accountDetailsData.user.role.name') === 'Candidate') {
      this.props.collaboratorsList();
    }
  }

  componentDidMount() {
    message.loading('Loading profile...', 3);
    if (
      _get(this.props, 'accountData.user.sign_in_count') === 1 &&
      _get(this.props, 'location.state.from') === URL_SIGN_UP_PAGE &&
      !this.props.isMobile
    ) {
      this.setState(() => ({ modalOpened: 'tutorial' }));
    }
  }

  componentDidUpdate() {
    const error =
      this.props.accountDetailsError ||
      this.props.accountProfileError ||
      this.props.collaboratorsListError ||
      this.props.collaboratorsInviteError;

    if (error) {
      message.error(
        error.localMessage ||
          'An error has occurred and profile data was not retrieved.',
      );
    }
  }

  render() {
    const { user } = this.props.accountData || {};
    return (
      <section className="my-account content">
        <ModalFirstTimeTutorial
          isOpen={this.state.modalOpened === 'tutorial'}
          onCloseRequest={this.handleCloseAllModals}
        />
        <Loading loading={this.isLoading()} type="spinner">
          <CampaignOverview {...this.getCampaignOverviewProps()} />
          {this.subSections.filter(subSection =>
            subSection.accessibleByUser(user),
          ).length > 1 && <TabPanel {...this.getTabPanelProps()} />}
          <div className="container-fluid my-account__content">
            {this.renderTabs()}
          </div>
        </Loading>
      </section>
    );
  }

  renderTabs() {
    let content = null;

    if (!this.isLoading()) {
      content = this.subSections.map((subSection: any, index) => (
        <Route
          exact
          key={index}
          path={subSection.getPath()}
          component={subSection.component}
        />
      ));
    }

    return content;
  }

  getCampaignOverviewProps() {
    const { user } = this.props.accountData || {};
    const { userDetails } = this.props.accountDetailsData || {};
    return { user, userDetails };
  }

  getTabPanelProps() {
    const { user } = this.props.accountData || {};
    const items: any[] = this.subSections
      .filter(subSection => subSection.accessibleByUser(user))
      .map((subSection: any) => {
        return {
          label: subSection.label,
          path: subSection.getPath(),
          regLink: subSection.regLink || false,
        };
      });

    return {
      items,
      className: 'my-account__tab-panel',
      initialActiveIndex: this.state.initialTabIndex || 0,
      onTabChange: this.handleTabChange,
    };
  }

  handleTabChange = (tabIndex: number) => {
    this.props.history.push(this.subSections[tabIndex].getPath());
  };

  handleCloseAllModals = () => {
    this.setState(() => ({
      modalOpened: null,
    }));
  };

  isLoading() {
    const {
      accountDetailsIsLoading,
      accountProfileIsLoading,
      accountGroupsIsLoading,
    } = this.props;

    return (
      accountDetailsIsLoading ||
      accountProfileIsLoading ||
      accountGroupsIsLoading
    );
  }
}

const mapStateToProps = (state, ownProps) => {
  return {
    ...account.mapStateToProps(state),
    ...accountDetails.mapStateToProps(state),
    ...accountProfile.mapStateToProps(state),
    ...accountGroups.mapStateToProps(state),
    ...collaborators.mapStateToProps(state),
    ...collaboratorsDelete.mapStateToProps(state),
    ...collaboratorsInvite.mapStateToProps(state),
    ...ownProps,
  };
};

const mapDispatchToProps = dispatch => {
  return {
    ...account.mapDispatchToProps(dispatch),
    ...accountDetails.mapDispatchToProps(dispatch),
    ...accountProfile.mapDispatchToProps(dispatch),
    ...accountGroups.mapDispatchToProps(dispatch),
    ...collaborators.mapDispatchToProps(dispatch),
    ...collaboratorsDelete.mapDispatchToProps(dispatch),
    ...collaboratorsInvite.mapDispatchToProps(dispatch),
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(withDisplayDimensions(MyAccountPage));
