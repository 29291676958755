import {
  getUserDetails,
  userDetailsReset as reset,
} from './userDetails.actions';
import type {
  GetUserDetailsPayload,
  GetUserDetailsResponse,
} from '../../../services/RoRUsersApiProvider';

export const mapStateToProps = state => {
  return {
    userDetailsData: state.userDetails.data,
    userDetailsError: state.userDetails.error,
    userDetailsIsLoading: state.userDetails.isLoading,
  };
};

export const mapDispatchToProps = dispatch => {
  return {
    getDetailsForUser(payload: GetUserDetailsPayload) {
      dispatch(getUserDetails(payload));
    },
    userDetailsReset() {
      dispatch(reset());
    },
  };
};

export type UserDetailsMapStateToProps = {
  userDetailsData: GetUserDetailsResponse,
  userDetailsError: any,
  userDetailsIsLoading: boolean,
};

export type UserDetailsMapDispatchToProps = {
  getDetailsForUser: Function,
  userDetailsReset: Function,
};

export default {
  mapStateToProps,
  mapDispatchToProps,
};
