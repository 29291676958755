// @flow
import { createAction } from 'redux-actions';
import {
  CONTRIBUTION_VERIFY_ACH_ERROR,
  CONTRIBUTION_VERIFY_ACH_LOADING,
  CONTRIBUTION_VERIFY_ACH_RESET,
  CONTRIBUTION_VERIFY_ACH_SUCCESS,
} from './contributionVerifyAch.constants';
import { instance as rorContributionsApiProvider } from '../../../services/RoRContributionsApiProvider';
import { accountPaymentMethodsList } from '../accountPaymentMethods/accountPaymentMethods.actions';
import type { PostVerifyAchPayload } from '../../../services/RoRContributionsApiProvider';

export const contributionVerifyAchError = createAction(
  CONTRIBUTION_VERIFY_ACH_ERROR,
);

export const contributionVerifyAchLoading = createAction(
  CONTRIBUTION_VERIFY_ACH_LOADING,
);

export const contributionVerifyAchSuccess = createAction(
  CONTRIBUTION_VERIFY_ACH_SUCCESS,
);

export const contributionVerifyAchReset = createAction(
  CONTRIBUTION_VERIFY_ACH_RESET,
);

export const verifyAch = (payload: PostVerifyAchPayload) => {
  return async (dispatch: Function) => {
    try {
      dispatch(contributionVerifyAchLoading());

      const response = await rorContributionsApiProvider.verifyAch(payload);

      dispatch(contributionVerifyAchSuccess({ success: true, ...response }));
      dispatch(accountPaymentMethodsList());
    } catch (error) {
      dispatch(contributionVerifyAchError(error));
    }
  };
};
