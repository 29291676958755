// @flow
import _cloneDeep from 'lodash/cloneDeep';
import {
  ACCOUNT_GROUPS_SUCCESS,
  ACCOUNT_GROUPS_RESET,
  ACCOUNT_GROUPS_LOADING,
  ACCOUNT_GROUPS_ERROR,
  ACCOUNT_GROUPS_SET_AS_FIRST,
} from './accountGroups.constants';
import makeDefaultServerCallReducer from '../../utilities/makeDefaultServerCallReducer';

const initialState = {
  isLoading: false,
  error: null,
  data: null,
};

const setGroupAsFirst = (state, action) => {
  console.log(state, action.payload);
  if (state.data && state.data.groups) {
    const data = _cloneDeep(state.data);

    data.groups.unshift(action.payload);

    console.log({
      ...state,
      data,
    });

    return {
      ...state,
      data,
    };
  }

  return {
    ...state,
    data: {
      groups: [action.payload],
    },
  };
};

export const reducer = makeDefaultServerCallReducer({
  loadingActions: [ACCOUNT_GROUPS_LOADING],
  successActions: [ACCOUNT_GROUPS_SUCCESS],
  errorActions: [ACCOUNT_GROUPS_ERROR],
  resetActions: [ACCOUNT_GROUPS_RESET],
  otherActions: [
    { name: ACCOUNT_GROUPS_SET_AS_FIRST, callback: setGroupAsFirst },
  ],
  initialState,
});
