import {
  collaboratorsInvite,
  collaboratorsInviteReset as inviteReset,
} from './collaboratorsInvite.actions';
import type { UserProfile } from '../../../services/RoRUsersApiProvider';

export const mapStateToProps = state => {
  return {
    collaboratorsInviteData: state.collaborators.error,
    collaboratorsInviteIsLoading: state.collaborators.isLoading,
    collaboratorsInviteError: state.collaborators.error,
  };
};

export const mapDispatchToProps = dispatch => {
  return {
    collaboratorsInvite(payload) {
      dispatch(collaboratorsInvite(payload));
    },
    collaboratorsInviteReset() {
      dispatch(inviteReset());
    },
  };
};

export type collaboratorsInviteMapStateToProps = {
  collaboratorsInviteError: any,
  collaboratorsInviteIsLoading: boolean,
};

export type collaboratorsInviteMapDispatchToProps = {
  collaboratorsInvite: Function,
  collaboratorsInviteReset: Function,
};

export default {
  mapStateToProps,
  mapDispatchToProps,
};
