import {
  accountPaymentMethodsList,
  accountPaymentMethodsListReset as listReset,
} from './accountPaymentMethods.actions';

export const mapStateToProps = state => {
  return {
    accountPaymentMethodsListData: state.accountPaymentMethods.data,
    accountPaymentMethodsListError: state.accountPaymentMethods.error,
    accountPaymentMethodsListIsLoading: state.accountPaymentMethods.isLoading,
  };
};

export const mapDispatchToProps = dispatch => {
  return {
    accountPaymentMethodsList(payload) {
      dispatch(accountPaymentMethodsList(payload));
    },
    accountPaymentMethodsListReset() {
      dispatch(listReset());
    },
  };
};

export type AccountPaymentMethodsMapStateToProps = {
  accountPaymentMethodsListData: any,
  accountPaymentMethodsListError: any,
  accountPaymentMethodsListIsLoading: boolean,
};

export type AccountPaymentMethodsMapDispatchToProps = {
  accountPaymentMethodsList: Function,
  accountPaymentMethodsListReset: Function,
};
export default {
  mapStateToProps,
  mapDispatchToProps,
};
