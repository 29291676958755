//@flow
import React from 'react';
import { connect } from 'react-redux';
import * as _get from 'lodash/get';
import moment from 'moment';
import { message } from 'antd';
import { StickyContainer, Sticky } from 'react-sticky';
import Route from 'react-router-dom/Route';
import Button from '../../components/Button/Button';
import Loading from '../../components/Loading/Loading';
import UserOverview from '../../components/UserOverview/UserOverview';
import TabPanelActionable from '../../components/TabPanelActionable/TabPanelActionable';
import ModalContributeOptions from '../../components/ModalContributeOptions/ModalContributeOptions';
import ModalNewMessage from '../../components/ModalNewMessage/ModalNewMessage';
import ModalListUserInterests from '../../components/ModalListUserInterests/ModalListUserInterests';
import PublicProfileActivityTab from '../../components/PublicProfileActivityTab/PublicProfileActivityTab';
import PublicProfileContributionsTab from '../../components/PublicProfileContributionsTab/PublicProfileContributions';
import PublicProfileAboutTab from '../../components/PublicProfileAboutTab/PublicProfileAboutTab';
import { URL_USER_PUBLIC_PROFILE } from '../../config/urls';
import account from '../../redux/modules/account/account.containers';
import type { AccountMapStateToProps } from '../../redux/modules/account/account.containers';
import userProfileContributions from '../../redux/modules/userProfileContributions/userProfileContributions.containers';
import type {
  UserProfileContributionsMapStateToProps,
  UserProfileContributionsMapDispatchToProps,
} from '../../redux/modules/userProfileContributions/userProfileContributions.containers';
import userProfileGroups from '../../redux/modules/userProfileGroups/userProfileGroups.containers';
import userProfileFollowers from '../../redux/modules/userProfileFollowers/userProfileFollowers.containers';
import userProfileFollowing from '../../redux/modules/userProfileFollowing/userProfileFollowing.containers';
import type {
  UserProfileGroupsMapDispatchToProps,
  UserProfileGroupsMapStateToProps,
} from '../../redux/modules/userProfileGroups/userProfileGroups.containers';
import type {
  UserProfileFollowersMapStateToProps,
  UserProfileFollowersMapDispatchToProps,
} from '../../redux/modules/userProfileFollowers/userProfileFollowers.containers';
import type {
  UserProfileFollowingMapStateToProps,
  UserProfileFollowingMapDispatchToProps,
} from '../../redux/modules/userProfileFollowing/userProfileFollowing.containers';
import userDetails from '../../redux/modules/userDetails/userDetails.containers';
import type {
  UserDetailsMapStateToProps,
  UserDetailsMapDispatchToProps,
} from '../../redux/modules/userDetails/userDetails.containers';

import ModalInvitePerson from '../../components/ModalInvitePerson/ModalInvitePerson';
import './UserProfilePage.scss';
import {
  canAcceptPledge,
  canDonate,
  isCandidate,
  isCitizen,
  isPac,
  isPoliticalParty,
} from '../../utilities/authorization';
import fullName from '../../utilities/fullName';
import ModalContribute from '../../components/ModalContribute/ModalContribute';
import type { ContributionTypeType } from '../../components/ContributeOptions/ContributeOptions';

type Props = AccountMapStateToProps &
  UserProfileContributionsMapStateToProps &
  UserProfileContributionsMapDispatchToProps &
  UserProfileGroupsMapStateToProps &
  UserProfileGroupsMapDispatchToProps &
  UserProfileFollowersMapStateToProps &
  UserProfileFollowersMapDispatchToProps &
  UserProfileFollowingMapStateToProps &
  UserProfileFollowingMapDispatchToProps &
  UserDetailsMapStateToProps &
  UserDetailsMapDispatchToProps & {
    children: any,
    location: any,
    history: any,
    match: any,
    invitePeopleModalOpen: boolean,
  };

type State = {
  contributeOptionsModalOpen: boolean,
  contributeModalOpen: boolean,
  contributionType: ContributionTypeType,
  initialTabIndex: number | null,
  invitePersonModalOpen: boolean,
  directMessageModalOpen: boolean,
  interestsModalVisibility: boolean,
};

type ModalNames = 'contributeOptions' | 'contribute';

class UserProfilePage extends React.Component<Props, State> {
  subSections: {
    label: string,
    pathKey: string,
    Component: any,
    propsGetter: Function,
  }[];

  constructor(props: Props) {
    super(props);

    this.subSections = [
      {
        label: 'Activity',
        pathKey: 'activity',
        Component: PublicProfileActivityTab,
        propsGetter: this.getActivityTabProps.bind(this),
      },
      {
        label: 'Contributions',
        pathKey: 'contributions',
        Component: PublicProfileContributionsTab,
        propsGetter: this.getContributionsTabProps.bind(this),
      },
      {
        label: 'About',
        pathKey: 'about',
        Component: PublicProfileAboutTab,
        propsGetter: this.getPublicProfileAboutTabProps.bind(this),
      },
    ];

    let initialTabIndex = null;

    this.subSections.forEach((subSection: any, index: number) => {
      if (this.props.location.pathname.indexOf(subSection.pathKey) !== -1) {
        initialTabIndex = index;
      }
    });

    this.state = {
      contributeOptionsModalOpen: false,
      contributeModalOpen: false,
      contributionType: null,
      invitePersonModalOpen: false,
      initialTabIndex,
      directMessageModalOpen: false,
      interestsModalVisibility: false,
    };

    this.fetchData(this.props.match.params.id);
  }

  componentWillUpdate(nextProps) {
    if (nextProps.match.params.id !== this.props.match.params.id) {
      this.fetchData(nextProps.match.params.id);
    }
  }

  fetchData(userId: number) {
    this.props.userDetailsReset();
    this.props.profileGroupsReset();
    this.props.profileFollowersReset();
    this.props.profileFollowingReset();
    this.props.getDetailsForUser({ userId });
    this.props.getUserProfileContributions({ id: userId });
    //TODO: add pagination to this data
    this.props.getDetailsForUser({ userId });
    this.props.getUserProfileGroups({ id: userId });
    this.props.getUserProfileFollowers({ id: userId });
    this.props.getUserProfileFollowing({ id: userId });
  }

  render() {
    return (
      <section className="user-profile-page content">
        <Loading loading={this.isLoadingContent()} type="spinner">
          {this.renderContent()}
        </Loading>
      </section>
    );
  }

  renderContent() {
    let content = null;

    if (!this.isLoadingContent()) {
      content = (
        <StickyContainer className="user-profile-page__sticky-container">
          <UserOverview {...this.getUserOverviewProps()} />
          <Sticky>
            {stickyProps => (
              <TabPanelActionable {...this.getTabPanelProps(stickyProps)} />
            )}
          </Sticky>
          <div
            className={`container-fluid user-profile-page__content ${
              _get(this.props.userDetailsData, 'user.role.name') === 'Citizen'
                ? 'user-profile-page__content--citizen'
                : ''
            } px-0`}>
            <Route
              exact
              key={'initial-tab'}
              path={`${URL_USER_PUBLIC_PROFILE}`}
              render={() => (
                <PublicProfileActivityTab {...this.getActivityTabProps()} />
              )}
            />
            {this.subSections.map((subSection: any) => {
              return (
                <Route
                  exact
                  key={subSection.pathKey}
                  path={`${URL_USER_PUBLIC_PROFILE}/${subSection.pathKey}`}
                  render={props => (
                    <subSection.Component
                      {...props}
                      {...subSection.propsGetter()}
                    />
                  )}
                />
              );
            })}
            {this.state.contributeOptionsModalOpen && (
              <ModalContributeOptions
                isOpen={true}
                allowPledge={canAcceptPledge(this.props.userDetailsData.user)}
                onContributionTypeSelected={this.handleContributionTypeSelected}
                onCloseRequest={this.handleCloseAllModals}
              />
            )}
            {this.state.contributeModalOpen && (
              <ModalContribute
                isOpen={true}
                onCloseRequest={this.handleCloseAllModals}
                contributionType={this.state.contributionType}
                userDetails={this.props.userDetailsData.user}
                onGoBackRequest={this.handleGoBackToContributeOptionsModal}
                onSuccess={this.handleCloseAllModals}
              />
            )}
            {this.state.invitePersonModalOpen && (
              <ModalInvitePerson
                isOpen={true}
                onCloseRequest={this.toggleInvitePersonModalVisibility}
                onSuccess={this.handleSendInvitationsSuccess}
                onError={this.handleSendInvitationsError}
                profileID={this.props.match.params.id}
              />
            )}
            {this.state.directMessageModalOpen && (
              <ModalNewMessage
                isOpen={true}
                preSelectedUser={{
                  value: this.props.userDetailsData.user.id,
                  label: `${this.props.userDetailsData.user.first_name} ${
                    this.props.userDetailsData.user.last_name
                  }`,
                }}
                onCloseRequest={this.toggleDirectMessageModalVisibility}
                onSuccess={this.handleSendMessageSuccess}
                onError={this.handleSendMessageError}
              />
            )}
            {this.state.interestsModalVisibility && (
              <ModalListUserInterests
                isOpen={true}
                onCloseRequest={this.toggleInterestModalVisibility}
                userInterestsList={this.props.userDetailsData.user.interests}
              />
            )}
          </div>
        </StickyContainer>
      );
    }

    return content;
  }

  getUserOverviewProps() {
    const { user: loggedInUser } = this.props.accountData;
    //this is the profile owner
    const { user } = this.props.userDetailsData;
    const isLoggedInUserProfile = user.id === loggedInUser.id;

    let campaignTotal = 0;
    let actionButton;

    if (
      canDonate(loggedInUser) &&
      (isCandidate(user) || isPoliticalParty(user) || isPac(user)) &&
      !isLoggedInUserProfile
    ) {
      actionButton = (
        <Button
          onClick={this.handleOpenContributeOptionsModal}
          className="mb-3 user-overview__contribute"
          buttonType="primary">
          Contribute
        </Button>
      );
    } else if (isCitizen(user) && !isLoggedInUserProfile) {
      actionButton = (
        <Button
          onClick={this.toggleInvitePersonModalVisibility}
          className="mb-3 user-overview__contribute"
          buttonType="primary">
          Invite to group
        </Button>
      );
    } else {
      actionButton = null;
    }

    const messageButton = !isLoggedInUserProfile ? (
      <Button
        onClick={this.toggleDirectMessageModalVisibility}
        className="mb-3 user-overview__contribute"
        buttonType="primary">
        Message
      </Button>
    ) : null;

    const showInterestsButton = (
      <Button
        onClick={this.toggleInterestModalVisibility}
        className="user-overview__show-interests"
        buttonType="link"
        size="small">
        show interests
      </Button>
    );
    const secondaryContent = isCandidate(user) ? (
      <h4 className="fs-18">
        {user.followers_count} Follower{user.followers_count === 1 ? '' : 's'}{' '}
        {user.likes_count} Like{user.likes_count === 1 ? '' : 's'}
      </h4>
    ) : (
      ''
    );
    const politicalParty = isCandidate(user) ? (
      <h4 className="fs-18">
        {user.party} ({user.incumbant_status})
      </h4>
    ) : (
      ''
    );

    Object.keys(user.donations).map(
      key => (campaignTotal += user.donations[key]),
    );

    return {
      actionButton: actionButton,
      campaignTotal,
      createdAt: moment(user.created_at).format('MMM YYYY'),
      followers: user.followers_count,
      likes: user.likes_count,
      name: fullName(user),
      profile_image: user.profile_image,
      race: _get(user, 'race.name'),
      race_id: _get(user, 'race.id'),
      isFollowing: _get(user, 'current_user.is_following'),
      role: user.role.name,
      isPac: isPac(user),
      secondaryContent,
      politicalParty,
      userId: this.props.match.params.id,
      contributionsCount: user.contributions_count,
      isActive: user.is_active,
      isExploratory: user.exploratory_committee_profile,
      allowFollowers: user.allow_followers,
      messageButton,
      showInterestsButton,
      user: user,
    };
  }

  getTabPanelProps(stickyProps) {
    const items: string[] = this.subSections.map(
      (subSection: any) => subSection.label,
    );
    const { user: loggedInUser } = this.props.accountData;

    //this is the profile owner
    const { user } = this.props.userDetailsData;
    const { contributed, committed, pledged } = user.donations;
    // const role = _get(user, 'role.name');

    return {
      items,
      canDonate: canDonate(loggedInUser) && isCandidate(user) && user.race,
      className: 'container-fluid px-0',
      contributed: contributed / 100,
      committed: committed / 100,
      initialActiveIndex: this.state.initialTabIndex || 0,
      name: `${user.first_name} ${user.last_name}`,
      onActionButtonClick: this.handleOpenContributeOptionsModal,
      pledged: pledged / 100,
      profile_image: user.profile_image,
      onTabChange: this.handleTabChange,
      centered: true,
      isSticky: isCandidate(user) && stickyProps.isSticky,
      role: _get(user, 'role.name'),
    };
  }

  getActivityTabProps() {
    const { user } = this.props.userDetailsData || {};
    return {
      followers: _get(this.props, 'profileFollowersData.followers', []),
      followings: _get(this.props, 'profileFollowingData.followings', []),
      groups: _get(this.props, 'profileGroupsData.groups', []),
      user,
    };
  }

  getContributionsTabProps() {
    return {
      fetchAfterMount: false,
      userId: this.props.match.params.id,
    };
  }

  getPublicProfileAboutTabProps() {
    const user = _get(this.props, 'userDetailsData.user');
    return {
      name: `${user.first_name} ${user.last_name}`,
      biography: user.biography,
    };
  }

  handleTabChange = (tabIndex: number) => {
    this.props.history.push(
      `${URL_USER_PUBLIC_PROFILE.replace(':id', this.props.match.params.id)}/${
        this.subSections[tabIndex].pathKey
      }`,
    );
  };

  handleOpenContributeOptionsModal = () => {
    this.setModalState(['contributeOptions']);
  };

  handleContributionTypeSelected = (type: ContributionTypeType) => {
    this.setModalState(['contribute'], { contributionType: type });
  };

  handleCloseAllModals = () => {
    this.setModalState([], { contributionType: null });
  };

  handleGoBackToContributeOptionsModal = () => {
    this.setModalState(['contributeOptions']);
  };

  toggleInvitePersonModalVisibility = () => {
    this.setState((prevState: State) => ({
      invitePersonModalOpen: !prevState.invitePersonModalOpen,
    }));
  };

  handleSendInvitationsSuccess = () => {
    message.success('Users were successfully invited.');
  };

  handleSendInvitationsError = (error: string) => {
    message.error(error);
  };

  toggleDirectMessageModalVisibility = () => {
    this.setState((prevState: State) => ({
      directMessageModalOpen: !prevState.directMessageModalOpen,
    }));
  };

  handleSendMessageSuccess = () => {
    message.success('Message sent successfully!');
    this.toggleDirectMessageModalVisibility();
  };

  handleSendMessageError = () => {
    message.error('Something went wrong. Please try after some time!');
  };

  toggleInterestModalVisibility = () => {
    this.setState((prevState: State) => ({
      interestsModalVisibility: !prevState.interestsModalVisibility,
    }));
  };

  setModalState(
    activeModals: ModalNames[],
    others: { [key: string]: any } = {},
  ) {
    const modalStateKeys = [
      'contributeOptionsModalOpen',
      'contributeModalOpen',
    ];
    let state = { ...others };

    modalStateKeys.forEach((key: string) => {
      state[key] = activeModals.indexOf(key.replace('ModalOpen', '')) !== -1;
    });

    this.setState(() => state);
  }

  isLoadingContent() {
    //this is the profile owner
    const { user } = this.props.userDetailsData || {};
    if (isCandidate(user) || isPoliticalParty(user) || isPac(user)) {
      return !(
        this.props.userDetailsData &&
        this.props.profileContributionsData &&
        this.props.profileGroupsData &&
        this.props.profileFollowersData &&
        this.props.profileFollowingData
      );
    } else if (isCitizen(user)) {
      return !(
        this.props.userDetailsData &&
        this.props.profileGroupsData &&
        this.props.profileFollowingData
      );
    } else {
      return true;
    }
  }
}

const mapStateToProps = (state, ownProps) => {
  return {
    ...account.mapStateToProps(state),
    ...userProfileContributions.mapStateToProps(state),
    ...userProfileFollowers.mapStateToProps(state),
    ...userProfileFollowing.mapStateToProps(state),
    ...userProfileGroups.mapStateToProps(state),
    ...userDetails.mapStateToProps(state),
    ...ownProps,
  };
};

const mapDispatchToProps = dispatch => {
  return {
    ...userProfileContributions.mapDispatchToProps(dispatch),
    ...userProfileFollowers.mapDispatchToProps(dispatch),
    ...userProfileFollowing.mapDispatchToProps(dispatch),
    ...userProfileGroups.mapDispatchToProps(dispatch),
    ...userDetails.mapDispatchToProps(dispatch),
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(UserProfilePage);
