// @flow
import makeDefaultServerCallReducer from '../../utilities/makeDefaultServerCallReducer';
import {
  CONTRIBUTION_COMMITTED_DONATION_MAKE_ERROR,
  CONTRIBUTION_COMMITTED_DONATION_MAKE_LOADING,
  CONTRIBUTION_COMMITTED_DONATION_MAKE_SUCCESS,
  CONTRIBUTION_COMMITTED_DONATION_MAKE_RESET,
} from './contributionCommittedDonationMake.constants';

const initialState = {
  isLoading: false,
  error: null,
  data: null,
};

export const reducer = makeDefaultServerCallReducer({
  loadingActions: [CONTRIBUTION_COMMITTED_DONATION_MAKE_LOADING],
  successActions: [CONTRIBUTION_COMMITTED_DONATION_MAKE_SUCCESS],
  errorActions: [CONTRIBUTION_COMMITTED_DONATION_MAKE_ERROR],
  resetActions: [CONTRIBUTION_COMMITTED_DONATION_MAKE_RESET],
  initialState,
});
