import React from 'react';
import { Link } from 'react-router-dom';
import './Footer.scss';

// TODO: alt texts
// TODO: add missing link href values
const Footer = () => {
  return (
    <footer className="footer">
      <div className="container-fluid  fix-width">
        <ul>
          <li>
            <Link to="/about">About us</Link>
          </li>
          <li>
            <Link to="/contact-us">Contact us</Link>
          </li>
          <li>
            <Link to="/privacy-policy">Privacy Policy</Link>
          </li>
          <li>
            <Link to="/code-of-conduct">Code of Conduct</Link>
          </li>
          <li>
            <Link to="/user-agreement">Legal</Link>
          </li>
          <li>
            <Link to="/faq">Frequently Asked Questions</Link>
          </li>
        </ul>
      </div>
    </footer>
  );
};

export default Footer;
