// @flow
import { createAction } from 'redux-actions';
import {
  USER_CANDIDATE_SUB_INTERESTS_ERROR,
  USER_CANDIDATE_SUB_INTERESTS_LOADING,
  USER_CANDIDATE_SUB_INTERESTS_RESET,
  USER_CANDIDATE_SUB_INTERESTS_SUCCESS,
} from './userCandidateSubInterests.constants';
import { instance as rorUsersApiProvider } from '../../../services/RoRUsersApiProvider';
import type { GetSubInterestsPayload } from '../../../services/RoRUsersApiProvider';

export const userCandidateSubInterestsError = createAction(
  USER_CANDIDATE_SUB_INTERESTS_ERROR,
);

export const userCandidateSubInterestsLoading = createAction(
  USER_CANDIDATE_SUB_INTERESTS_LOADING,
);

export const userCandidateSubInterestsSuccess = createAction(
  USER_CANDIDATE_SUB_INTERESTS_SUCCESS,
);

export const userCandidateSubInterestsReset = createAction(
  USER_CANDIDATE_SUB_INTERESTS_RESET,
);

export const getUserCandidateSubInterests = (
  payload: GetSubInterestsPayload,
) => {
  return async (dispatch: Function) => {
    try {
      dispatch(userCandidateSubInterestsLoading());

      const response = await rorUsersApiProvider.candidateSubInterests(payload);

      dispatch(userCandidateSubInterestsSuccess(response));
    } catch (error) {
      dispatch(userCandidateSubInterestsError(error));
    }
  };
};
