// @flow
import { createAction } from 'redux-actions';
import {
  COLLABORATORS_INVITE_LOADING,
  COLLABORATORS_INVITE_SUCCESS,
  COLLABORATORS_INVITE_ERROR,
  COLLABORATORS_INVITE_RESET,
} from './collaboratorsInvite.constants';
import { collaboratorsList } from '../collaborators/collaborators.actions';
import { instance as rorApiProvider } from '../../../services/RoRCollaboratorsApiProvider';

export const collaboratorsInviteLoading = createAction(
  COLLABORATORS_INVITE_LOADING,
);

export const collaboratorsInviteSuccess = createAction(
  COLLABORATORS_INVITE_SUCCESS,
);

export const collaboratorsInviteError = createAction(
  COLLABORATORS_INVITE_ERROR,
);

export const collaboratorsInviteReset = createAction(
  COLLABORATORS_INVITE_RESET,
);

export const collaboratorsInvite = (payload: any) => {
  return async (dispatch: Function) => {
    try {
      dispatch(collaboratorsInviteReset());

      dispatch(collaboratorsInviteLoading());

      const response = await rorApiProvider.inviteCollaborators(payload);

      dispatch(collaboratorsInviteSuccess(response));
      dispatch(collaboratorsList());
    } catch (error) {
      dispatch(collaboratorsInviteError(error));
    }
  };
};
