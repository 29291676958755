// @flow
import makeDefaultServerCallReducer from '../../utilities/makeDefaultServerCallReducer';
import {
  GROUP_UPDATE_ERROR,
  GROUP_UPDATE_LOADING,
  GROUP_UPDATE_SUCCESS,
  GROUP_UPDATE_RESET,
} from './groupUpdate.constants';

const initialState = {
  isLoading: false,
  error: null,
  data: null,
};

export const reducer = makeDefaultServerCallReducer({
  loadingActions: [GROUP_UPDATE_LOADING],
  successActions: [GROUP_UPDATE_SUCCESS],
  errorActions: [GROUP_UPDATE_ERROR],
  resetActions: [GROUP_UPDATE_RESET],
  initialState,
});
