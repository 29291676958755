// @flow
import React from 'react';
import _get from 'lodash/get';
import TabPanel from '../TabPanel/TabPanel';
import Button from '../Button/Button';
import Flyout from '../Flyout/Flyout';
import type { GroupDetails } from '../../services/RoRGroupsApiProvider';
import './GroupsTopMenu.scss';
import { Icon, Popconfirm } from 'antd';
import Currency from '../Currency/Currency';
import DropdownMenu from '../DropdownMenu/DropdownMenu';
import type { AuthenticatedUser } from '../../services/RoRApiProvider';
import { canDonate, canJoinGroup } from '../../utilities/authorization';

type EditGroupOptions = 'rename' | 'moderators' | 'delete' | 'leave';

type Props = {
  accountData: { user: AuthenticatedUser },
  groupDetails: GroupDetails,
  initialTabIndex: number | null,
  onTabChange?: Function,
  availableTabs: { label: string, path: string, component: any }[],
  onEditButtonClick: Function,
  onEditOptionClick: (key: EditGroupOptions) => void,
  onCloseEditFlyoutRequest: Function,
  isEditFlyoutOpen: boolean,
  groupRequestJoin: Function,
  leaveFromGroup: Function,
  groupRequestJoinIsLoading: boolean,
  groupLeaveIsLoading: boolean,
  groupDetailsIsLoading: boolean,
  openSelectCandidateModal: Function,
  onInviteExistingUsers: () => void,
  onInviteByContact: () => void,
  onCopyLinkToInvitation: () => void,
};

const GroupsTopMenu = (props: Props) => {
  // TODO: use account to enable edit button
  const { accountData, groupDetails } = props;
  if (!accountData || !groupDetails) {
    return null;
  }

  const tabItems: string[] = props.availableTabs.map(
    (subSection: any) => subSection.label,
  );

  const { user } = accountData;

  const {
    is_moderator: isModerator = false,
    is_member: isMember = false,
    has_active_request: hasActiveRequest = false,
  } = groupDetails.current_user || {};

  return (
    <div className="container-fluid groups-top-menu">
      <div className="row fix-width groups-top-menu__content">
        <div className="col-md-3 col-12 px-0">
          <img
            src={groupDetails.group_image}
            alt={groupDetails.name}
            className="groups-top-menu__group-image"
          />
        </div>
        <div className="col-12 col-md-9 pt-3">
          <div className="row fix-width">
            <div className="col-md-7 col-12">
              <div className="groups-top-menu__left-text">
                <div className="d-flex align-items-center">
                  <h1>{groupDetails.name}</h1>
                  {isModerator ? (
                    <Flyout
                      trigger={
                        <Button
                          buttonType="link"
                          size="small"
                          className="groups-top-menu__edit-button"
                          onClick={props.onEditButtonClick}>
                          <img src="/images/icon_edit.png" alt="" />
                        </Button>
                      }
                      displayArrow={false}
                      displayIndicator={false}
                      hoverable={false}
                      isExpanded={props.isEditFlyoutOpen}>
                      <EditGroupFlyout
                        onOptionSelected={optionKey =>
                          handleEditOptionSelected(optionKey, props)
                        }
                        onCloseEditFlyoutRequest={
                          props.onCloseEditFlyoutRequest
                        }
                      />
                    </Flyout>
                  ) : isMember ? (
                    <Popconfirm
                      title="Are you sure you want to leave this group?"
                      onConfirm={props.leaveFromGroup}
                      okText="Yes"
                      cancelText="No">
                      <Button
                        loading={
                          props.groupLeaveIsLoading ||
                          props.groupDetailsIsLoading
                        }
                        buttonType="link"
                        type="button"
                        size="small">
                        <img src="/images/icon_exit.svg" alt="" />
                      </Button>
                    </Popconfirm>
                  ) : null}
                </div>
                <h2>{groupDetails.description}</h2>
                <h5>
                  Hosted by{' '}
                  {`${groupDetails.owner.first_name} ${
                    groupDetails.owner.last_name
                  }`.trim()}
                </h5>
              </div>
            </div>
            <div className="col-md-5 col-12">
              <div className="groups-top-menu__right-text">
                <div className="text-md-right groups-top-menu__right-text-block">
                  <strong>{groupDetails.members_count}</strong>{' '}
                  {groupDetails.members_count > 1 ? 'Members' : 'Member'}
                </div>
                <div className="text-md-right groups-top-menu__right-text-block">
                  Prytany Number
                  <div>
                    <strong>{groupDetails.prytany_number}</strong>
                  </div>
                </div>
              </div>
            </div>

            <div className="col-12">
              <div className="mt-3 mb-3 groups-top-menu__actions">
                <div className="row no-gutters">
                  {(isMember || isModerator) && (
                    <div className="col-4 col-lg-3 px-0">
                      <DropdownMenu
                        button={
                          <Button
                            buttonType="outline"
                            type="button"
                            size="small"
                            noTextTransform>
                            Invite <Icon type="down" />
                          </Button>
                        }
                        menuItems={[
                          {
                            label: 'Select from a List of Users',
                            onClick: props.onInviteExistingUsers,
                          },
                          {
                            label: 'Invite via Email/Phone Number',
                            onClick: props.onInviteByContact,
                          },
                          {
                            label: 'Copy a Link to Invitation',
                            onClick: props.onCopyLinkToInvitation,
                          },
                        ]}
                      />
                    </div>
                  )}
                </div>
              </div>
            </div>
            <div className="col-12 px-0">
              <div className="row no-gutters">
                <div className="mb-md-0 mb-3 col-12 col-md-9 groups-top-menu__contributions">
                  <div className="row ">
                    <div className="col-4">
                      <span className="groups-top-menu__contribution-type">
                        Contributed
                      </span>
                      <Currency
                        amount={groupDetails.donations.contributed / 100}
                        showDecimals={true}
                      />
                    </div>
                    <div className="col-4">
                      <span className="groups-top-menu__contribution-type">
                        Committed
                      </span>
                      <Currency
                        amount={groupDetails.donations.committed / 100}
                        showDecimals={true}
                      />
                    </div>
                    <div className="col-4">
                      <span className="groups-top-menu__contribution-type">
                        Pledged
                      </span>
                      <Currency
                        amount={groupDetails.donations.pledged / 100}
                        showDecimals={true}
                      />
                    </div>
                  </div>
                </div>
                {isMember && canDonate(user) && (
                  <Button
                    onClick={props.openSelectCandidateModal}
                    loading={props.groupDetailsIsLoading}
                    buttonType="primary"
                    type="button"
                    className="col-md-3 col-4 offset-md-0 offset-8 px-0">
                    Contribute
                  </Button>
                )}
                {!isMember && canJoinGroup(user) && (
                  <Button
                    onClick={props.groupRequestJoin}
                    loading={
                      props.groupRequestJoinIsLoading ||
                      props.groupDetailsIsLoading
                    }
                    buttonType="primary"
                    type="button"
                    disabled={hasActiveRequest}
                    className="offset-md-9 col-md-3 px-0">
                    {hasActiveRequest ? 'Request pending' : 'Join Group'}
                  </Button>
                )}
              </div>
            </div>
            <div className="col-12 pl-md-0">
              <TabPanel
                className="groups-top-menu__tab-panel"
                items={tabItems}
                onTabChange={index => onTabChange(index, props)}
                initialActiveIndex={props.initialTabIndex}
                noPadding
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

function handleEditOptionSelected(option: EditGroupOptions, props: Props) {
  if (option === 'leave') {
    props.onCloseEditFlyoutRequest();
    props.leaveFromGroup();
  } else {
    props.onCloseEditFlyoutRequest();
    props.onEditOptionClick(option);
  }
}

type EditGroupFlyoutProps = {
  onOptionSelected: (key: EditGroupOptions) => void,
  onCloseEditFlyoutRequest: Function,
};

const EditGroupFlyout = (props: EditGroupFlyoutProps) => {
  return (
    <div className="edit-group-flyout">
      <Button
        className="edit-group-flyout__close-button"
        buttonType="link"
        size="small"
        onClick={() => props.onCloseEditFlyoutRequest()}>
        <img src="/images/icon_close-small.png" alt="" />
      </Button>
      <EditGroupMenu onOptionSelected={props.onOptionSelected} />
    </div>
  );
};

export const EditGroupMenu = (props: {
  onOptionSelected: (key: EditGroupOptions) => void,
}) => {
  const options = [
    { label: 'Rename', key: 'rename' },
    { label: 'Moderators', key: 'moderators' },
    { label: 'Delete group', key: 'delete' },
  ];

  return (
    <ul className="edit-group-menu">
      {options.map(item => (
        <li className="edit-group-menu__list-item" key={item.key}>
          <Button
            className="edit-group-menu__option-button"
            buttonType="link"
            size="small"
            onClick={() => props.onOptionSelected(item.key)}>
            {item.label}
          </Button>
        </li>
      ))}
      <li className="edit-group-menu__list-item">
        <Popconfirm
          title="Are you sure you want to leave this group?"
          onConfirm={() => props.onOptionSelected('leave')}
          okText="Yes"
          cancelText="No">
          <Button
            className="edit-group-menu__option-button"
            buttonType="link"
            size="small">
            Leave group
          </Button>
        </Popconfirm>
      </li>
    </ul>
  );
};

function onTabChange(index: number, props: Props) {
  if (props.onTabChange) {
    props.onTabChange({ index, ...props.availableTabs[index] });
  }
}

export default GroupsTopMenu;
