// @flow
import { createAction } from 'redux-actions';

import { instance as rorGroupsApiProvider } from '../../../services/RoRGroupsApiProvider';
import type { GetGroupMemberContributionsPayload } from '../../../services/RoRGroupsApiProvider';
import {
  GROUP_MEMBER_CONTRIBUTIONS_ERROR,
  GROUP_MEMBER_CONTRIBUTIONS_LOADING,
  GROUP_MEMBER_CONTRIBUTIONS_RESET,
  GROUP_MEMBER_CONTRIBUTIONS_SUCCESS,
} from './groupMemberContributions.constants';

export const groupMemberContributionsLoading = createAction(
  GROUP_MEMBER_CONTRIBUTIONS_LOADING,
);

export const groupMemberContributionsSuccess = createAction(
  GROUP_MEMBER_CONTRIBUTIONS_SUCCESS,
);

export const groupMemberContributionsError = createAction(
  GROUP_MEMBER_CONTRIBUTIONS_ERROR,
);

export const groupMemberContributionsReset = createAction(
  GROUP_MEMBER_CONTRIBUTIONS_RESET,
);

export const getGroupMemberContributions = (
  payload: GetGroupMemberContributionsPayload,
) => {
  return async (dispatch: Function) => {
    try {
      dispatch(groupMemberContributionsLoading());

      const response = await rorGroupsApiProvider.groupMemberContributions(
        payload,
      );

      dispatch(groupMemberContributionsSuccess(response));
    } catch (error) {
      dispatch(groupMemberContributionsError(error));
    }
  };
};
