// @flow
import React from 'react';
import withRouter from 'react-router-dom/withRouter';
import _get from 'lodash/get';
import Redirect from 'react-router-dom/Redirect';
import { connect } from 'react-redux';
import { Icon, message } from 'antd';
import Button from '../../components/Button/Button';
import Link from '../../components/Link/Link';
import SignUpHeader from '../../components/SignUpHeader/SignUpHeader';
import UserRoleSelector from '../../components/UserRoleSelector/UserRoleSelector';
import SignUpAccountBasicsForm from '../../components/SignUpAccountBasicsForm/SignUpAccountBasicsForm';
import SignUpAccountProfileForm from '../../components/SignUpAccountProfileForm/SignUpAccountProfileForm';
import SignUpTwoFactorForm from '../../components/SignUpTwoFactorForm/SignUpTwoFactorForm';
import type { AccountMapStateToProps } from '../../redux/modules/account/account.containers';
import account from '../../redux/modules/account/account.containers';
import type {
  SignUpMapDispatchToProps,
  SignUpMapStateToProps,
} from '../../redux/modules/signUp/signUp.containers';
import signUp from '../../redux/modules/signUp/signUp.containers';
import type {
  ListRolesMapDispatchToProps,
  ListRolesMapStateToProps,
} from '../../redux/modules/listRoles/listRoles.containers';
import listRoles from '../../redux/modules/listRoles/listRoles.containers';
import listStates from '../../redux/modules/listStates/listStates.containers';
import {
  URL_LOGIN_PAGE,
  URL_MY_ACCOUNT_MAIN_PAGE,
  URL_SIGN_UP_PAGE,
} from '../../config/urls';
import { instance as rorApiProvider } from '../../services/RoRApiProvider';
import './SignUpPage.scss';
import RoleCode from '../../constants/RoleCode';
import { isFirst, isLast, next, previous } from '../../utilities/formStepUtils';
import type {
  SignUpOtpVerificationMapDispatchToProps,
  SignUpOtpVerificationMapStateToProps,
} from '../../redux/modules/signUpOtpVerification/signUpOtpVerification.containers';
import signUpOtpVerification from '../../redux/modules/signUpOtpVerification/signUpOtpVerification.containers';

const { CITIZEN, CANDIDATE } = RoleCode;

// Define steps as constants
const {
  SELECT_ROLE = 'Select Role',
  ACCOUNT_BASICS = 'Account Basics',
  TWO_FACTOR = 'Two Factor',
  PROFILE = 'Profile',
} = {};

const stepsByRoleCode = {
  [CITIZEN]: [SELECT_ROLE, ACCOUNT_BASICS, PROFILE, TWO_FACTOR],
  [CANDIDATE]: [SELECT_ROLE, ACCOUNT_BASICS, PROFILE, TWO_FACTOR],
};

export type SignupStep =
  | 'Select Role'
  | 'Account Basics'
  | 'Two Factor'
  | 'Profile';

type State = {
  selectedRole: 'citizen' | 'candidate',
  steps: SignupStep[],
  currentStep: SignupStep,
  accountBasicsFormValues: any,
  accountProfileFormValues: any,
  displayCandidateSuccessScreen: boolean,
  verifiedEmail: string | null,
  invitedByGroupId?: number,
};

type Props = AccountMapStateToProps &
  SignUpMapDispatchToProps &
  SignUpMapStateToProps &
  ListRolesMapStateToProps &
  ListRolesMapDispatchToProps &
  SignUpOtpVerificationMapStateToProps &
  SignUpOtpVerificationMapDispatchToProps & {
    location: any,
    history: any,
    listStatesData: any,
    listStatesError: any,
    listStatesIsLoading: boolean,
  };

class SignUpPage extends React.Component<Props, State> {
  accountBasicsForm: SignUpAccountBasicsForm;
  twoFactorForm: SignUpTwoFactorForm;
  accountProfileForm: SignUpAccountProfileForm;

  constructor(props: Props) {
    super(props);

    this.state = {
      selectedRole: CITIZEN,
      steps: stepsByRoleCode[CITIZEN],
      currentStep: SELECT_ROLE,
      accountBasicsFormValues: null,
      accountProfileFormValues: null,
      displayCandidateSuccessScreen: false,
      verifiedEmail: null,
      invitedByGroupId: undefined,
    };

    this.props.signUpOtpVerificationReset();
    this.props.signUpReset();
    this.props.getListRoles();
  }

  componentDidMount() {
    const groupId = new URLSearchParams(this.props.location.search).get(
      'group_id',
    );
    this.setState({ invitedByGroupId: +groupId });
  }

  componentDidUpdate(prevProps: Props, prevState: State) {
    const { currentStep: prevStep } = prevState;
    const { currentStep } = this.state;
    if (prevStep !== currentStep) {
      if (currentStep === TWO_FACTOR) {
        const { verifiedEmail } = this.state;
        const currentEmail = _get(this.state, 'accountBasicsFormValues.email');
        if (!verifiedEmail || verifiedEmail !== currentEmail) {
          rorApiProvider
            .sendOtpCode({
              email: currentEmail,
            })
            .catch(error => {
              message.error(
                error.localMessage ||
                  'Something went wrong. Please try again later.',
              );
            });
        }
      }
    }

    if (
      !prevProps.signUpOtpVerificationData &&
      this.props.signUpOtpVerificationData
    ) {
      this.setState(prevState => {
        const { steps, currentStep } = prevState;
        if (isLast(steps, currentStep)) {
          this.signUp();
          return {};
        }
        return {
          verifiedEmail: _get(this.state, 'accountBasicsFormValues.email'),
          currentStep: next(steps, currentStep),
        };
      });
    }

    if (!prevProps.signUpData && this.props.signUpData) {
      if (this.state.invitedByGroupId) {
        message.success(
          'Your account was successfully created. You must log into your account to access the group you’ve been invited to.',
          5,
        );
      } else {
        message.success('Your account was successfully created.', 5);
      }

      if (
        this.state.selectedRole === CANDIDATE &&
        !this.state.displayCandidateSuccessScreen
      ) {
        this.setState(() => ({ displayCandidateSuccessScreen: true }));
      } else {
        this.props.history.push(URL_LOGIN_PAGE);
      }
    }
  }

  render() {
    return (
      <React.Fragment>
        {!this.props.accountData ? (
          this.renderContent()
        ) : (
          <Redirect
            to={{
              pathname: this.props.accountError
                ? URL_LOGIN_PAGE
                : URL_MY_ACCOUNT_MAIN_PAGE,
              state: { from: URL_SIGN_UP_PAGE },
            }}
          />
        )}
      </React.Fragment>
    );
  }

  renderContent() {
    return (
      <div className="signup-page">
        <section className="signup-page__content">
          <div className="container-fluid fix-width">
            <div className="row justify-content-md-center">
              {this.state.displayCandidateSuccessScreen
                ? this.renderCandidateSuccessScreen()
                : this.renderFormScreen()}
            </div>
          </div>
        </section>
      </div>
    );
  }

  renderFormScreen() {
    const { steps, currentStep, selectedRole } = this.state;

    return (
      <div className="col">
        <SignUpHeader
          onBackButtonClick={this.handleBackClick}
          steps={steps}
          currentStep={currentStep}
          signUpRole={selectedRole}
        />
        {currentStep === SELECT_ROLE ? (
          <UserRoleSelector
            initialRole={selectedRole}
            handleSelectRole={this.handleSelectRole}
          />
        ) : null}
        {currentStep === ACCOUNT_BASICS ? (
          <SignUpAccountBasicsForm {...this.getAccountBasicsFormProps()} />
        ) : null}
        {currentStep === TWO_FACTOR ? (
          <div className="row">
            <div className="col-md-8 col-12 offset-md-2 mb-5">
              <SignUpTwoFactorForm {...this.getTwoFactorFormProps()} />
            </div>
          </div>
        ) : null}
        {currentStep === PROFILE ? (
          <SignUpAccountProfileForm {...this.getProfileFormProps()} />
        ) : null}
        {currentStep === ACCOUNT_BASICS && (
          <div className="fec-disclaimer">
            {selectedRole === CITIZEN && (
              <p>
                <Icon type="info-circle" className="info-circle mr-1" />
                Any individual who is permitted under applicable law and the
                regulations of the Federal Election Commission to lawfully make
                a political contribution to a candidate running for federal
                elected office in the United States of America. Your legal name
                and information are required to ensure any political
                contributions you choose to make on Prytany are accurately
                reported as required by the Federal Election Commission.
              </p>
            )}
            {selectedRole === CANDIDATE && (
              <p>
                <Icon type="info-circle" className="info-circle mr-1" />
                Any individual who is permitted under applicable law and the
                regulations of the Federal Election Commission to lawfully
                accept a political contribution for a federal elected office in
                the United States of America.
              </p>
            )}
          </div>
        )}
        {this.renderServiceErrors()}
        {this.renderPageControls()}
      </div>
    );
  }

  renderCandidateSuccessScreen() {
    return (
      <div class="col">
        <h1>Thank you for registering with Prytany!</h1>
        <p className="pt-4">
          Your account is currently being reviewed and Prytany may contact you
          if further verification is needed and&nbsp; you will be notified when
          your account has been approved. If you have any questions,&nbsp;
          please email us at{' '}
          <Link href="mailto:support@prytany.com" isExternal={true}>
            support@prytany.com
          </Link>
        </p>
        <div className="row no-gutters justify-content-center py-5">
          <div className="col-12 col-sm-6 col-md-3 col-lg-2">
            <Link href={URL_LOGIN_PAGE} type="button" className="btn-block">
              GO TO LOGIN
            </Link>
          </div>
        </div>
      </div>
    );
  }

  renderPageControls() {
    const {
      listRolesError,
      signUpOtpVerificationIsLoading,
      signUpIsLoading,
    } = this.props;
    const { steps, currentStep } = this.state;

    return (
      <div className="row d-flex py-5 mx-0 justify-content-center">
        <div className="col-6 col-md-2  pr-1 pr-md-2 pl-md-0 ">
          <Button
            buttonType="outline"
            block
            noBorder
            onClick={this.handleBackClick}
            type="button">
            {isFirst(steps, currentStep) ? 'Cancel' : 'Back'}
          </Button>
        </div>
        <div className="col-6 col-md-2 pl-1 pl-md-2  pr-md-0 ">
          <Button
            loading={signUpOtpVerificationIsLoading || signUpIsLoading}
            disabled={!!listRolesError}
            block
            buttonType="primary"
            type="button"
            onClick={this.handleNextStepClick}>
            {currentStep === TWO_FACTOR
              ? 'Verify'
              : isLast(steps, currentStep)
              ? 'Save and confirm'
              : 'Next'}
          </Button>
        </div>
      </div>
    );
  }

  renderServiceErrors() {
    const {
      listRolesError,
      signUpOtpVerificationError,
      listStatesError,
      signUpError,
    } = this.props;

    const listErrors =
      listRolesError || listStatesError
        ? 'A problem has occurred with our server. Please reload this page.'
        : null;

    const error =
      listErrors ||
      (signUpOtpVerificationError && 'Invalid verification code') ||
      (signUpError && signUpError.localMessage);

    return (
      error && (
        <div className="signup-page__service-error">
          <span className="signup-page__service-error-message">{error}</span>
        </div>
      )
    );
  }

  getAccountBasicsFormProps() {
    return {
      ref: (ref: any) => {
        this.accountBasicsForm = ref;
      },
      userRole: this.state.selectedRole,
      onFormSubmit: this.handleValidBasicFormSubmit,
      initialFormValues: this.state.accountBasicsFormValues,
    };
  }

  getTwoFactorFormProps() {
    return {
      ref: (ref: any) => {
        this.twoFactorForm = ref;
      },
      onFormSubmit: this.handleValidTwoFactorFormSubmit,
    };
  }

  getProfileFormProps() {
    return {
      ref: (ref: any) => {
        this.accountProfileForm =
          ref && ref.getWrappedInstance ? ref.getWrappedInstance() : ref;
      },
      userRole: this.state.selectedRole,
      onFormSubmit: this.handleValidProfileFormSubmit,
      initialFormValues: this.state.accountProfileFormValues,
    };
  }

  handleNextStepClick = () => {
    this.setState(prevState => {
      const { steps, currentStep } = prevState;

      switch (currentStep) {
        case SELECT_ROLE:
          return {
            currentStep: next(steps, currentStep),
          };
        case ACCOUNT_BASICS:
          this.accountBasicsForm.submitForm();
          return {};
        case PROFILE:
          this.accountProfileForm.submitForm();
          return {};
        case TWO_FACTOR:
          this.twoFactorForm.submitForm();
          return {};
        default:
          return {};
      }
    });
  };

  handleBackClick = () => {
    const { steps, currentStep } = this.state;
    if (isFirst(steps, currentStep)) {
      this.props.history.goBack();
      return;
    }

    this.setState(prevState => {
      const { currentStep, steps } = prevState;
      const newState: any = { currentStep: previous(steps, currentStep) };
      if (
        currentStep === PROFILE &&
        prevState.accountProfileFormValues &&
        prevState.accountProfileFormValues.profilePicture
      ) {
        newState.accountProfileFormValues = {
          ...prevState.accountProfileFormValues,
          profilePicture: null,
        };
      }

      if (
        currentStep === ACCOUNT_BASICS &&
        prevState.accountBasicsFormValues &&
        prevState.accountBasicsFormValues.accountIDPicture
      ) {
        newState.accountBasicsFormValues = {
          ...prevState.accountBasicsFormValues,
          accountIDPicture: null,
        };
      }

      return newState;
    });
  };

  handleValidBasicFormSubmit = async ({ values }: any) => {
    this.setState(prevState => {
      const { currentStep, steps } = prevState;
      return {
        accountBasicsFormValues: values,
        currentStep: next(steps, currentStep),
      };
    });
  };

  handleValidProfileFormSubmit = ({ values }: any) => {
    this.setState(prevState => {
      const { currentStep, steps } = prevState;
      return {
        accountProfileFormValues: values,
        currentStep: next(steps, currentStep),
      };
    });
  };

  handleValidTwoFactorFormSubmit = async ({ values }: any) => {
    const { otpCode } = values;
    this.props.verifySignUpEmail({
      email: _get(this.state, 'accountBasicsFormValues.email'),
      otp: otpCode,
    });
  };

  signUp = () => {
    const userRoleId = this.props.listRolesData.roles
      .find((role: any) => role.code === this.state.selectedRole)
      .id.toString();
    const payload = {
      userRoleId,
      ...this.state.accountProfileFormValues,
      ...this.state.accountBasicsFormValues,
      invitedByGroupId: this.state.invitedByGroupId,
    };
    this.props.signUp(payload);
  };

  handleSelectRole = (role: 'citizen' | 'candidate') => {
    this.setState(() => ({
      selectedRole: role,
      steps: stepsByRoleCode[role],
    }));
  };
}

const mapStateToProps = (state, ownProps) => {
  return {
    ...signUpOtpVerification.mapStateToProps(state),
    ...signUp.mapStateToProps(state),
    ...account.mapStateToProps(state),
    ...listRoles.mapStateToProps(state),
    ...listStates.mapStateToProps(state),
    ...ownProps,
  };
};

const mapDispatchToProps = dispatch => {
  return {
    ...signUpOtpVerification.mapDispatchToProps(dispatch),
    ...signUp.mapDispatchToProps(dispatch),
    ...listRoles.mapDispatchToProps(dispatch),
  };
};

export default withRouter(
  connect(
    mapStateToProps,
    mapDispatchToProps,
  )(SignUpPage),
);
