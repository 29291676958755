import {
  groupApproveRequest as approveRequest,
  groupApproveRequestReset as reset,
} from './groupApproveRequest.actions';
import type { DefaultResponse } from '../../../services/RoRApiProvider';

export const mapStateToProps = state => {
  return {
    groupApproveRequestData: state.groupApproveRequest.data,
    groupApproveRequestError: state.groupApproveRequest.error,
    groupApproveRequestIsLoading: state.groupApproveRequest.isLoading,
  };
};

export const mapDispatchToProps = dispatch => {
  return {
    groupApproveRequest(payload) {
      dispatch(approveRequest(payload));
    },
    groupApproveRequestReset() {
      dispatch(reset());
    },
  };
};

export type GroupApproveRequestMapStateToProps = {
  groupApproveRequestData: DefaultResponse,
  groupApproveRequestError: any,
  groupApproveRequestIsLoading: boolean,
};

export type GroupApproveRequestMapDispatchToProps = {
  groupApproveRequest: Function,
  groupApproveRequestReset: Function,
};

export default {
  mapStateToProps,
  mapDispatchToProps,
};
