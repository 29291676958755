//@flow
import React from 'react';
import _get from 'lodash/get';
import _isEmpty from 'lodash/isEmpty';
import _isNull from 'lodash/isNull';
import classNames from 'classnames';
import { connect } from 'react-redux';
import { Icon, message } from 'antd';
import { saveAs } from 'file-saver';
import withDisplayDimensions from '../../hoc/withDisplayDimensions';
import account from '../../redux/modules/account/account.containers';
import accountDetails from '../../redux/modules/accountDetails/accountDetails.containers';
import accountVerify from '../../redux/modules/accountVerify/accountVerify.containers';
import accountProfile from '../../redux/modules/accountProfile/accountProfile.containers';
import accountSsaFormData from '../../redux/modules/accountSsaFormData/accountSsaFormData.containers';
import type { WithDisplayDimensionsOutputProps } from '../../hoc/withDisplayDimensions';
import type {
  AccountMapDispatchToProps,
  AccountMapStateToProps,
} from '../../redux/modules/account/account.containers';
import type {
  AccountProfileMapDispatchToProps,
  AccountProfileMapStateToProps,
} from '../../redux/modules/accountProfile/accountProfile.containers';
import type {
  AccountDetailsMapDispatchToProps,
  AccountDetailsMapStateToProps,
} from '../../redux/modules/accountDetails/accountDetails.containers';
import type {
  AccountSsaFormDataMapDispatchToProps,
  AccountSsaFormDataMapStateToProps,
} from '../../redux/modules/accountSsaFormData/accountSsaFormData.containers';
import Loading from '../../components/Loading/Loading';
import Button from '../../components/Button/Button';
import FileInput from '../../components/FileInput/FileInput';
import RadioButtons from '../../components/RadioButtons/RadioButtons';
import Link from '../../components/Link/Link';
import { URL_MY_ACCOUNT_MAIN_PAGE } from '../../config/urls';
import type {
  AccountVerifyMapDispatchToProps,
  AccountVerifyMapStateToProps,
} from '../../redux/modules/accountVerify/accountVerify.containers';
import './VerifyAccountPage.scss';
import ContributePaymentMethodSelector from '../../components/ContributePaymentMethodSelector/ContributePaymentMethodSelector';
import ModalContributePayment from '../../components/ModalContributePayment/ModalContributePayment';
import Input from '../../components/Input/Input';
import ssaFormHtml from '../../constants/ssaFormHtml/ssa-form-html';
import queryString from 'query-string';
import * as yup from 'yup';
import { Formik, FormikProps } from 'formik';
import Select from '../../components/Select/Select';
import isBlank from '../../utilities/isBlank';
import listStates from '../../redux/modules/listStates/listStates.containers';
import type {
  ListStatesMapDispatchToProps,
  ListStatesMapStateToProps,
} from '../../redux/modules/listStates/listStates.containers';
import accountProfileUpdate from '../../redux/modules/accountProfileUpdate/accountProfileUpdate.containers';
import type {
  AccountProfileUpdateMapDispatchToProps,
  AccountProfileUpdateMapStateToProps,
} from '../../redux/modules/accountProfileUpdate/accountProfileUpdate.containers';
import isPresent from '../../utilities/isPresent';
import IdScanType from '../../constants/IdScanType';
import type { IdScanTypeOption } from '../../constants/IdScanType';
import VerificationType from '../../constants/VerificationType';
import type { VerificationTypeOption } from '../../constants/VerificationType';

type Props = AccountMapDispatchToProps &
  AccountMapStateToProps &
  AccountProfileMapStateToProps &
  AccountProfileMapDispatchToProps &
  AccountProfileUpdateMapStateToProps &
  AccountProfileUpdateMapDispatchToProps &
  AccountDetailsMapStateToProps &
  AccountDetailsMapDispatchToProps &
  AccountVerifyMapDispatchToProps &
  AccountVerifyMapStateToProps &
  WithDisplayDimensionsOutputProps &
  AccountSsaFormDataMapDispatchToProps &
  AccountSsaFormDataMapStateToProps &
  ListStatesMapStateToProps &
  ListStatesMapDispatchToProps & {
    children: any,
    location: any,
    history: any,
  };

const SSAStatus = Object.freeze({
  NOT_UPLOADED: 'not_uploaded',
  PENDING: 'pending',
  COMPLETED: 'completed',
  REJECTED: 'rejected',
});

type State = {
  verificationType: VerificationTypeOption,
  idScanType: IdScanTypeOption | null,
  currentMobileStep: number,
  idScanFileString: string | null,
  ssaFileString: string | null,
  ssaLegalFirstName: string,
  ssaSocialSecurityNumber: string,
  paymentMethod: string,
  displayPaymentModal: boolean,
  newSavedPaymentMethod: any,
};

const SSA_PAYMENT_AMOUNT_USD = 1;

class VerifyAccountPage extends React.Component<Props, State> {
  mobilePayload = {};

  constructor(props: Props) {
    super(props);

    this.state = {
      verificationType: VerificationType.IDScan,
      idScanType: null,
      currentMobileStep: 1,
      idScanFileString: null,
      ssaFileString: null,
      ssaLegalFirstName: '',
      ssaSocialSecurityNumber: '',
      paymentMethod: '',
      displayPaymentModal: false,
      newSavedPaymentMethod: null,
    };
    const mobileToken = queryString.parse(this.props.location.search).token;
    const mobileEmail = queryString.parse(this.props.location.search).email;
    const mobileUserId = queryString.parse(this.props.location.search).id;

    if (mobileToken && mobileEmail && mobileUserId) {
      this.mobilePayload = {
        customToken: mobileToken,
        customEmail: mobileEmail,
        userId: mobileUserId,
      };
    }

    this.props.accountVerifyReset();
    this.props.accountSsaFormDataReset();
    if (!_isEmpty(this.mobilePayload)) {
      this.props.getAccountDetails(this.mobilePayload);
      this.props.getAccountProfile(this.mobilePayload);
    } else {
      this.props.getAccountDetails();
      this.props.getAccountProfile();
    }
  }

  componentDidMount() {
    this.props.getListStates();
  }

  componentDidUpdate(prevProps: Props, prevState: State) {
    if (this.props.isMobile !== prevProps.isMobile) {
      this.setState(() => ({
        currentMobileStep: 1,
        idScanFileString: null,
        ssaFileString: null,
      }));
      this.props.accountSsaFormDataReset();
    }

    if (
      this.state.paymentMethod === 'newCard' &&
      prevState.paymentMethod !== 'newCard'
    ) {
      this.setState(() => ({ displayPaymentModal: true }));
    }

    if (this.state.verificationType !== prevState.verificationType) {
      this.setState(() => ({ idScanFileString: null, ssaFileString: null }));
      this.props.accountSsaFormDataReset();
    }

    if (!prevProps.accountVerifyData && this.props.accountVerifyData) {
      const newUrl = this.mobilePayload.customToken
        ? '/'
        : URL_MY_ACCOUNT_MAIN_PAGE;
      message.success('Your account is now pending for verification.', 5);

      this.props.history.replace(newUrl);
      this.props.accountVerifyReset();
      this.props.accountSsaFormDataReset();
    }

    if (
      !prevProps.accountProfileUpdateError &&
      this.props.accountProfileUpdateError
    ) {
      message.error(
        this.props.accountProfileUpdateError.localMessage ||
          'An error has occurred and your profile was not updated.',
      );
    }

    if (
      !prevProps.accountProfileUpdateData &&
      this.props.accountProfileUpdateData
    ) {
      this.props.accountProfileUpdateReset();
      this.props.getSsaFormData({
        legal_first_name: this.state.ssaLegalFirstName,
        ssn: this.state.ssaSocialSecurityNumber,
        ...this.mobilePayload,
      });
    }

    if (
      !prevProps.accountSsaFormDataData &&
      this.props.accountSsaFormDataData
    ) {
      this.props.accountSsaFormDataReset();
      saveAs(this.getSsaFormBlob(), 'ssa_webform.html');
    }

    if (!prevProps.accountVerifyError && this.props.accountVerifyError) {
      const error =
        this.state.verificationType === VerificationType.SSA
          ? this.props.accountVerifyError.localMessage
          : 'We were not able to verify your identity. Try with a different file.';
      message.error(error, 5);
      this.props.accountVerifyReset();
    }
  }

  render() {
    return this.props.accountDetailsData ? (
      <section className="verify-account-page content">
        <Loading loading={this.isLoading()} type="spinner">
          {this.renderHeader()}
          {this.renderContent()}
        </Loading>
      </section>
    ) : null;
  }

  renderHeader() {
    const ssaVerified = _get(
      this.props,
      'accountDetailsData.user.ssa_verified',
    );

    return (
      <div className="verify-account-page__header container-fluid">
        <div className="row fix-width no-gutters">
          <div className="col-sm-12 col-md-5 col-xl-6 px-3 pt-4 px-md-0 pt-md-3">
            {!this.props.isMobile ? (
              <Link
                className="verify-account-page__back-button"
                href={URL_MY_ACCOUNT_MAIN_PAGE}>
                &lt; Back
              </Link>
            ) : null}
            <h1 className="name">Become Prytany Verified</h1>
            {ssaVerified === 'pending' ? (
              <p>
                <strong>
                  Your account is now pending for verification. If you have made
                  a mistake you can submit again the required files.
                </strong>
              </p>
            ) : (
              <React.Fragment>
                <p>
                  Give your Candidates full confidence in your contributions.
                </p>
                <p>
                  Becoming Prytany Verified is secure, easy, and guarantees
                  continued transparency within our community.
                </p>
              </React.Fragment>
            )}
            {this.props.isMobile ? (
              <span className="verify-account-page__header-mobile-step">
                <strong>Step {this.state.currentMobileStep}</strong> of 2
              </span>
            ) : null}
          </div>
        </div>
      </div>
    );
  }

  renderContent() {
    const { user } = this.props.accountDetailsData;

    return user ? (
      <div className="container-fluid verify-account-page__content">
        <div className="row fix-width no-gutters pt-3">
          {this.renderMethodSelector()}
          <div className="offset-lg-1 col-md-8 col-lg-8 px-0 pl-md-5 pr-md-3 d-flex">
            <div className="row">
              <div className="col-12">
                {this.renderIDScan()}
                {this.renderSSA()}
              </div>
            </div>
          </div>
        </div>
        <div className="d-flex py-5 justify-content-center align-items-center">
          {!this.props.isMobile || this.state.currentMobileStep === 1 ? (
            <Link
              onClick={this.handleVerify}
              size="small"
              href={URL_MY_ACCOUNT_MAIN_PAGE}
              className="verify-account-page__cancel-button d-block"
              disabled={this.props.accountVerifyIsLoading}>
              Cancel
            </Link>
          ) : null}
          {this.props.isMobile && this.state.currentMobileStep === 2 ? (
            <Button
              onClick={() => this.handleGoToMobileStep(1)}
              buttonType="outline"
              size="small"
              type="button"
              className="verify-account-page__mobile-back-button"
              disabled={this.props.accountVerifyIsLoading}>
              Back
            </Button>
          ) : null}
          {this.props.isMobile && this.state.currentMobileStep === 1 ? (
            <Button
              onClick={() => this.handleGoToMobileStep(2)}
              buttonType="primary"
              size="small"
              type="button"
              className="verify-account-page__mobile-next-button"
              disabled={this.props.accountVerifyIsLoading}>
              Next
            </Button>
          ) : null}
          {!this.props.isMobile || this.state.currentMobileStep === 2 ? (
            <Button
              onClick={this.handleVerify}
              buttonType="primary"
              size="small"
              type="button"
              className="verify-account-page__submit-button"
              loading={this.props.accountVerifyIsLoading}
              disabled={
                (this.state.verificationType === VerificationType.IDScan &&
                  ((user.verified && user.passport_verified && user.greencard_verified) || !this.state.idScanFileString)) ||
                (this.state.verificationType === VerificationType.SSA &&
                  (user.ssa_verified === SSAStatus.COMPLETED ||
                    user.ss === SSAStatus.REJECTED ||
                    !this.state.ssaFileString ||
                    !this.state.paymentMethod))
              }>
              Submit
            </Button>
          ) : null}
        </div>
      </div>
    ) : null;
  }

  renderMethodSelector() {
    return !this.props.isMobile || this.state.currentMobileStep === 1 ? (
      <div className="col-12 col-md-4 col-lg-3">
        <span className="form-control-label mb-3">Select a method:</span>
        <Button
          className={classNames({
            'verify-account-page__option-button': true,
            'verify-account-page__option-button--active':
              this.state.verificationType === VerificationType.IDScan,
          })}
          onClick={() =>
            this.handleVerificationTypeChange({
              value: VerificationType.IDScan,
            })
          }
          buttonType="outline"
          type="button"
          disabled={this.props.accountVerifyIsLoading}>
          <img
            className="verify-account-page__option-button-image"
            src="images/icon-idscan.png"
          />
          <span className="verify-account-page__option-button-text">
            IDScan
            <small>Verify via driver's license, US Passport or greencard.</small>
          </span>
        </Button>
        <Button
          className={classNames({
            'verify-account-page__option-button mt-3': true,
            'verify-account-page__option-button--active':
              this.state.verificationType === VerificationType.SSA,
          })}
          onClick={() =>
            this.handleVerificationTypeChange({ value: VerificationType.SSA })
          }
          buttonType="outline"
          type="button"
          disabled={this.props.accountVerifyIsLoading}>
          <img
            className="verify-account-page__option-button-image"
            src="images/icon-ssa.png"
          />
          <span className="verify-account-page__option-button-text">
            Social Security No.
            <small>Verify using your social security number.</small>
          </span>
        </Button>
      </div>
    ) : null;
  }

  renderIDScan() {
    const { user } = this.props.accountDetailsData;
    let items = [];
    if (!user.verified) { items.push({ label: 'Driver\'s License', value: IdScanType.DRIVERS_LICENSE }); }
    if (!user.passport_verified) { items.push({ label: 'US Passport', value: IdScanType.US_PASSPORT });}
    if (!user.greencard_verified) { items.push({ label: 'Greencard', value: IdScanType.GREENCARD }); }
    return (!this.props.isMobile || this.state.currentMobileStep === 2) &&
      this.state.verificationType === VerificationType.IDScan ? (
      <div className="verify-account-page__method-wrapper">
        <h4>Verify with IDScan</h4>
        <p className="mb-0">
          IDScan is a third-party service that uses the name and address on your ID
          to confirm your identity.
        </p>
        {items.length === 0 ? (
          <p className="text-success mt-4">
            Your account has been successfully verified with IDScan
          </p>
        ) : (
          <React.Fragment>
            <p>
              Note: The name and address associated with your Prytany account must
              match your ID.
            </p>
            <RadioButtons
              items={items}
              name="IdScanType"
              className="mb-4"
              value={this.state.idScanType}
              onChange={this.handleChangeIdScanType}
              useFormik={false}
            />
            {!_isNull(this.state.idScanType) && (
              <React.Fragment>
                {this.renderIdScanUploadInstruction()}
                <FileInput
                  className="py-3 mb-4"
                  onChange={this.handleIDScanFileChange}
                />
                <p className="fs-12">
                  To ensure a successful verification, make sure the photo
                  is clear and legible.
                </p>
              </React.Fragment>
            )}
          </React.Fragment>
        )}
      </div>
    ) : null;
  }

  renderIdScanUploadInstruction() {
    const {idScanType} = this.state;

    if (_isNull(idScanType)) return null;

    let message = '';

    switch (idScanType) {
      case IdScanType.DRIVERS_LICENSE:
        message = 'the BACK of your driver\'s license';
        break;
      case IdScanType.US_PASSPORT:
        message = 'the passport page containing your name and address';
        break;
      case IdScanType.GREENCARD:
        message = 'the BACK of the greencard page containing your name and address';
        break;
    }

    return <p className="mb-3 fw-600">Upload an image of {message}:</p>;
  }

  renderSSA() {
    const { listStatesData, listStatesIsLoading } = this.props;
    const stateOptions = listStatesData
      ? listStatesData.states.map(state => ({
          label: state.name,
          value: state.id.toString(),
        }))
      : [];

    const otherPaymentSources = this.state.newSavedPaymentMethod
      ? [this.state.newSavedPaymentMethod]
      : [];

    const { user } = this.props.accountDetailsData;
    return (!this.props.isMobile || this.state.currentMobileStep === 2) &&
      this.state.verificationType === VerificationType.SSA ? (
      <div className="verify-account-page__method-wrapper">
        <h4>Social Security Verification</h4>
        {user.ssa_verified === SSAStatus.PENDING && (
          <p className="text-warning">
            Your previous submission is being reviewed.
          </p>
        )}
        {user.ssa_verified === SSAStatus.COMPLETED && (
          <p className="text-success">
            Your account has been successfully verified with SSA
          </p>
        )}
        {user.ssa_verified === SSAStatus.REJECTED && (
          <p>
            Your previous submission has been rejected. Please contact us at{' '}
            <a href="mailto:support@prytany.com.">support@prytany.com.</a>
          </p>
        )}
        {user.ssa_verified !== SSAStatus.COMPLETED &&
          user.ssa_verified !== SSAStatus.REJECTED && (
            <React.Fragment>
              <p className="verify-account-page__step-description mt-4">
                <strong>{this.props.isMobile ? 'A:' : 'Step 1:'}</strong>{' '}
                Complete the form below and generate SSA-89 document.
              </p>
              <Formik {...this.getSsaFormikConfigProps()}>
                {({
                  initialValues,
                  touched,
                  errors,
                  isValid,
                  handleSubmit,
                }: FormikProps) => (
                  <form onSubmit={handleSubmit}>
                    <div className="row no-gutters">
                      <div className="col-12 col-md-6 pr-md-2">
                        <Input
                          label={
                            <label className="form-control-label">
                              Legal First Name{' '}
                              <span className="required-asterisk">*</span>
                            </label>
                          }
                          name="legalFirstName"
                          type="text"
                          error={
                            touched.legalFirstName && errors.legalFirstName
                              ? errors.legalFirstName
                              : ''
                          }
                        />
                      </div>
                      <div className="col-12 col-md-6 pr-md-2">
                        <Input
                          label={
                            <label className="form-control-label">
                              Social Security Number{' '}
                              <span className="required-asterisk">*</span>
                            </label>
                          }
                          name="ssn"
                          type="text"
                          error={touched.ssn && errors.ssn ? errors.ssn : ''}
                        />
                      </div>
                      {[
                        initialValues.address1,
                        initialValues.city,
                        initialValues.state_id,
                        initialValues.zip_code,
                      ].some(isBlank) && (
                        <React.Fragment>
                          <div className="col-12 col-md-6 pr-md-2">
                            <Input
                              label={
                                <label
                                  htmlFor="address1"
                                  className="form-control-label">
                                  Address Line 1{' '}
                                  <span className="required-asterisk">*</span>
                                </label>
                              }
                              name="address1"
                              type="text"
                              error={
                                touched.address1 && errors.address1
                                  ? errors.address1
                                  : ''
                              }
                            />
                          </div>
                          <div className="col-12 col-md-6 pr-md-2">
                            <Input
                              className="pt-1"
                              label={
                                <label
                                  htmlFor="address2"
                                  className="form-control-label">
                                  Address Line 2
                                </label>
                              }
                              name="address2"
                              type="text"
                              error={
                                touched.address1 && errors.address2
                                  ? errors.address2
                                  : ''
                              }
                            />
                          </div>
                          <div className="col-12 col-md-6 pr-md-2">
                            <Input
                              className="pt-1"
                              label={
                                <label
                                  htmlFor="city"
                                  className="form-control-label">
                                  City{' '}
                                  <span className="required-asterisk">*</span>
                                </label>
                              }
                              name="city"
                              type="text"
                              error={
                                touched.city && errors.city ? errors.city : ''
                              }
                            />
                          </div>
                          <div className="col-12 col-md-3 pr-md-2">
                            <Select
                              className="mt-n1"
                              label={
                                <label
                                  htmlFor="state_id"
                                  className="form-control-label">
                                  State{' '}
                                  <span className="required-asterisk">*</span>
                                </label>
                              }
                              name="state_id"
                              options={stateOptions}
                              disabled={listStatesIsLoading}
                              error={
                                touched.state_id && errors.state_id
                                  ? errors.state_id
                                  : ''
                              }
                            />
                          </div>
                          <div className="col-12 col-md-3 pr-md-2">
                            <Input
                              className="pt-1"
                              label={
                                <label
                                  htmlFor="zip_code"
                                  className="form-control-label">
                                  Zip{' '}
                                  <span className="required-asterisk">*</span>
                                </label>
                              }
                              name="zip_code"
                              type="text"
                              error={
                                touched.zip_code && errors.zip_code
                                  ? errors.zip_code
                                  : ''
                              }
                            />
                          </div>
                        </React.Fragment>
                      )}
                      {isBlank(initialValues.dob) && (
                        <div className="col-12 col-md-6 pr-md-2">
                          <Input
                            className="pt-1"
                            label={
                              <label
                                htmlFor="dob"
                                className="form-control-label">
                                Date of Birth{' '}
                                <span className="required-asterisk">*</span>
                              </label>
                            }
                            name="dob"
                            type="date"
                            maxDate={new Date()}
                            placeholder="MM/DD/YYYY"
                            error={touched.dob && errors.dob ? errors.dob : ''}
                          />
                        </div>
                      )}
                      {isBlank(initialValues.telephone) && (
                        <div className="col-12 col-md-6 pr-md-2">
                          <Input
                            className="pt-1"
                            label={
                              <label
                                htmlFor="telephone"
                                className="form-control-label">
                                Telephone Number{' '}
                                <span className="required-asterisk">*</span>
                              </label>
                            }
                            name="telephone"
                            type="text"
                            error={
                              touched.telephone && errors.telephone
                                ? errors.telephone
                                : ''
                            }
                          />
                        </div>
                      )}
                      <div className="col-12 mt-2 mb-3">
                        <Button
                          type="submit"
                          buttonType="primary-inverted"
                          disabled={!isValid}
                          loading={
                            this.props.accountSsaFormDataIsLoading ||
                            this.props.accountProfileUpdateIsLoading
                          }>
                          Generate Form
                        </Button>
                      </div>
                    </div>
                  </form>
                )}
              </Formik>
              <hr />
              <div className="row">
                <div className="col-12">
                  <p className="verify-account-page__step-description">
                    <strong>{this.props.isMobile ? 'B' : 'Step 2'}:</strong>{' '}
                    Sign the SSA-89 form in <strong>blue or black ink</strong>{' '}
                    and upload for verification.
                  </p>
                </div>
                <div className="col-12">
                  <FileInput
                    className="py-3 mb-3"
                    onChange={this.handleSSAFileChange}
                  />
                </div>
              </div>
              <p className="fs-11 verify-account-page__disclaimer-text">
                <Icon type="info-circle" className="info-circle mr-1" />
                This form is sent to a secure, encrypted database and checked
                against Social Security Administration records.
              </p>
              <hr />
              <p className="verify-account-page__step-description">
                <strong>{this.props.isMobile ? 'C' : 'Step 3'}:</strong> Pay a{' '}
                {parseFloat(SSA_PAYMENT_AMOUNT_USD).toFixed(2)} Service fee
                required by the Social Security Administration.
              </p>
              <div className="row">
                <div className="col-12 col-md-6">
                  <ContributePaymentMethodSelector
                    error=""
                    customToken={this.mobilePayload.customToken}
                    customEmail={this.mobilePayload.customEmail}
                    useFormik={false}
                    onChange={this.handlePaymentMethodChange}
                    value={this.state.paymentMethod}
                    otherSources={otherPaymentSources}
                  />
                  <ModalContributePayment
                    isOpen={this.state.displayPaymentModal}
                    onCloseRequest={this.handleClosePaymentModal}
                    onNewStripeSource={this.handleGetNewPaymentMethod}
                    defaultAmount={SSA_PAYMENT_AMOUNT_USD}
                  />
                </div>
                <div className="col-12">
                  <p className="fs-11 verify-account-page__disclaimer-text mt-1">
                    <Icon type="info-circle" className="info-circle mr-1" />
                    You may also be charged a $0.33 transaction fee depending on
                    the payment method you select.
                  </p>
                </div>
              </div>
            </React.Fragment>
          )}
      </div>
    ) : null;
  }

  isLoading() {
    const {
      accountDetailsIsLoading,
      accountProfileIsLoading,
      accountGroupsIsLoading,
      listStatesIsLoading,
    } = this.props;

    return (
      accountDetailsIsLoading ||
      accountProfileIsLoading ||
      accountGroupsIsLoading ||
      listStatesIsLoading
    );
  }

  getSsaFormikConfigProps = () => {
    const { user: profile = {} } = this.props.accountProfileData || {};
    const {
      address1,
      address2,
      city,
      state,
      zip_code,
      dob,
      telephone,
    } = profile;
    return {
      initialValues: {
        legalFirstName: '',
        ssn: '',
        address1,
        address2,
        city,
        state_id: state ? state.id.toString() : null,
        zip_code,
        dob,
        telephone,
      },

      validationSchema: yup.object().shape({
        legalFirstName: yup
          .string()
          .ensure()
          .trim()
          .required('This field is required'),
        ssn: yup
          .string()
          .ensure()
          .trim()
          .required('This field is required')
          .max(9, 'Maximum 9 digits allowed')
          .matches(/^[0-9]+$/, 'Only numbers are allowed'),
        address1: yup
          .string()
          .ensure()
          .trim()
          .required('This field is required'),
        city: yup
          .string()
          .ensure()
          .trim()
          .required('This field is required'),
        state_id: yup
          .string()
          .ensure()
          .trim()
          .required('This field is required'),
        zip_code: yup
          .string()
          .ensure()
          .trim()
          .required('This field is required')
          .min(4, 'Minimum 4 characters.')
          .max(10, 'Maximum 10 characters allowed'),
        dob: yup
          .string()
          .ensure()
          .trim()
          .required('This field is required'),
        telephone: yup
          .string()
          .ensure()
          .trim()
          .required('This field is required'),
      }),
      onSubmit: this.handleGenerateSsaFormSignature,
    };
  };

  handleChangeIdScanType = ({ value }) => {
    this.setState({
      idScanType: value
    });
  }

  handleGenerateSsaFormSignature = values => {
    const { legalFirstName, ssn, ...profileValues } = values;
    this.setState({
      ssaLegalFirstName: legalFirstName,
      ssaSocialSecurityNumber: ssn,
    });
    if (Object.keys(profileValues).length > 0) {
      this.props.updateAccountProfile({
        ...profileValues,
        ...this.mobilePayload,
      });
    } else {
      this.props.getSsaFormData({
        legal_first_name: legalFirstName,
        ssn,
        ...this.mobilePayload,
      });
    }
  };

  getSsaFormBlob() {
    const profile = _get(this.props, 'accountProfileData.user', {});
    const ssaFormData = _get(
      this.props,
      'accountSsaFormDataData.ssa_document',
      {},
    );
    const state = _get(profile, 'state.name');

    const fileContent = ssaFormHtml({
      socialSecurityNumber: this.state.ssaSocialSecurityNumber || '',
      legalName: ssaFormData.printed_name || '',
      consent: ssaFormData.consent,
      companyAddress: ssaFormData.company_address || '',
      companyAgent: ssaFormData.company_agent || '',
      companyName: ssaFormData.company_name || '',
      reasons: ssaFormData.reasons || '',
      dob: ssaFormData.dob || '',
      location: `${profile.city} / ${state} / ${profile.zip_code}`,
      address: [profile.address1, profile.address2].filter(isPresent).join(' '),
      phoneNumber: profile.telephone || '',
    });
    const blob = new Blob([fileContent], { type: 'text/html' });

    return window.URL.createObjectURL(blob);
  }

  handleVerificationTypeChange = ({ value }: { value: 'IDScan' | 'SSA' }) => {
    this.setState(() => ({
      verificationType: value,
      idScanFileString: null,
      ssaFileString: null,
    }));
  };

  handleGoToMobileStep = (stepNumber: number) => {
    this.setState(() => ({
      currentMobileStep: stepNumber,
      idScanFileString: null,
      ssaFileString: null,
    }));
  };

  handlePaymentMethodChange = event => {
    this.setState(() => ({ paymentMethod: event.value }));
  };

  handleClosePaymentModal = () =>
    this.setState({ displayPaymentModal: false, paymentMethod: '' });

  handleGetNewPaymentMethod = event => {
    const newSavedPaymentMethod = {
      credit_card_number: `XXXX-XXXX-XXXX-${_get(
        event,
        'stripeResponse.card.last4',
      )}`,
      credit_card_type: _get(event, 'stripeResponse.card.brand'),
      exp_month: _get(event, 'stripeResponse.card.exp_month'),
      exp_year: _get(event, 'stripeResponse.card.exp_year'),
      source_type: 'card',
      status: _get(event, 'stripeResponse.status'),
      stripe_source_id: _get(event, 'stripeResponse.id'),
    };

    this.setState(() => ({
      newSavedPaymentMethod,
      displayPaymentModal: false,
      paymentMethod: newSavedPaymentMethod.stripe_source_id,
    }));
  };

  handleVerify = () => {
    const { user } = this.props.accountDetailsData;
    if (
      this.state.verificationType === VerificationType.IDScan &&
      this.state.idScanFileString
    ) {
      this.props.verifyAccount({
        type: VerificationType.IDScan,
        scanType: this.state.idScanType,
        userId: user.id,
        image_data: this.state.idScanFileString,
        ...this.mobilePayload,
      });
    } else if (
      this.state.verificationType === VerificationType.SSA &&
      this.state.ssaFileString
    ) {
      this.props.verifyAccount({
        type: VerificationType.SSA,
        ssa_document: this.state.ssaFileString,
        stripe_source_id: this.state.paymentMethod,
        ...this.mobilePayload,
      });
    }
  };

  handleSSAFileChange = (file: FileList) => {
    if (file && file.length) {
      this.getBase64(file[0]).then((fileBase64: any) => {
        this.setState(() => ({
          ssaFileString: fileBase64.split('base64,')[1],
        }));
      });
    }
  };

  handleIDScanFileChange = (file: FileList) => {
    if (file && file.length) {
      this.getBase64(file[0]).then((imageBase64: any) => {
        this.setState(() => ({
          idScanFileString: imageBase64.split('base64,')[1],
        }));
      });
    }
  };

  getBase64(file: File) {
    return new Promise((resolve, reject) => {
      if (!file) {
        return reject();
      }

      const reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = () => resolve(reader.result);
      reader.onerror = error => reject(error);
    });
  }
}

const mapStateToProps = (state, ownProps) => {
  return {
    ...account.mapStateToProps(state),
    ...accountDetails.mapStateToProps(state),
    ...accountProfile.mapStateToProps(state),
    ...accountProfileUpdate.mapStateToProps(state),
    ...accountVerify.mapStateToProps(state),
    ...accountSsaFormData.mapStateToProps(state),
    ...listStates.mapStateToProps(state),
    ...ownProps,
  };
};

const mapDispatchToProps = dispatch => {
  return {
    ...account.mapDispatchToProps(dispatch),
    ...accountDetails.mapDispatchToProps(dispatch),
    ...accountProfile.mapDispatchToProps(dispatch),
    ...accountProfileUpdate.mapDispatchToProps(dispatch),
    ...accountVerify.mapDispatchToProps(dispatch),
    ...accountSsaFormData.mapDispatchToProps(dispatch),
    ...listStates.mapDispatchToProps(dispatch),
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(withDisplayDimensions(VerifyAccountPage));
