import {
  contributionDetailsReset as reset,
  getContributionDetails as getDetails,
} from './contributionDetails.actions';
import type { GetContributionDetailsPayload } from './contributionDetails.actions';
import type {
  GetCommittedDonationDetailsResponse,
  GetDirectDonationDetailsPayload, GetDirectDonationDetailsResponse,
  GetPledgeDonationDetailsPayload, GetPledgeDonationDetailsResponse,
} from '../../../services/RoRContributionsApiProvider';

export const mapStateToProps = state => {
  return {
    contributionDetailsData: state.contributionDetails.data,
    contributionDetailsError: state.contributionDetails.error,
    contributionDetailsIsLoading: state.contributionDetails.isLoading,
  };
};

export const mapDispatchToProps = dispatch => {
  return {
    getContributionDetails(payload: GetContributionDetailsPayload) {
      dispatch(getDetails(payload));
    },
    contributionDetailsReset() {
      dispatch(reset());
    },
  };
};

export type ContributionDetailsMapStateToProps = {
  contributionDetailsData:
    | GetCommittedDonationDetailsResponse
    | GetDirectDonationDetailsResponse
    | GetPledgeDonationDetailsResponse
    | null,
  contributionDetailsError: any,
  contributionDetailsIsLoading: boolean,
};

export type ContributionDetailsMapDispatchToProps = {
  getContributionDetails: (payload: GetContributionDetailsPayload) => void,
  contributionDetailsReset: () => void,
};

export default {
  mapStateToProps,
  mapDispatchToProps,
};
