// @flow
import _cloneDeep from 'lodash/cloneDeep';
import {
  ACCOUNT_POSTS_SUCCESS,
  ACCOUNT_POSTS_RESET,
  ACCOUNT_POSTS_LOADING,
  ACCOUNT_POSTS_ERROR,
  ACCOUNT_POSTS_SET_AS_FIRST,
  ACCOUNT_POSTS_LIKE_POST,
  ACCOUNT_POSTS_UNLIKE_POST,
  ACCOUNT_POSTS_SHARE_POST,
} from './accountPosts.constants';
import makeDefaultServerCallReducer from '../../utilities/makeDefaultServerCallReducer';
import _get from 'lodash/get';
import _find from 'lodash/find';

const initialState = {
  isLoading: false,
  error: null,
  data: null,
};

const shareAccountPost = (state, action) => {
  if (state.data) {
    const data = _cloneDeep(state.data);

    if (_get(data, 'posts')) {
      let postToUpdate = _find(
        data.posts,
        post => post.id === action.payload.postId,
      );

      if (postToUpdate) {
        postToUpdate.share_count += 1;
      }
    }

    return {
      ...state,
      data,
    };
  }

  return state;
};

const likeAccountPost = (state, action) => {
  // Once the like is successful we have to grab the id of the post
  // and update both the like_count and has_like attribute in all state nodes
  // (race_feed *and* owner user_posts, group_posts *and* owner user_posts
  // or user_posts).

  if (state.data) {
    const data = _cloneDeep(state.data);

    if (_get(data, 'posts')) {
      let postToUpdate = _find(
        data.posts,
        post => post.id === action.payload.postId,
      );

      if (postToUpdate) {
        postToUpdate.like_count += 1;
        postToUpdate.has_liked = true;
      }
    }

    return {
      ...state,
      data,
    };
  }

  return state;
};

const unLikeAccountPost = (state, action) => {
  if (state.data) {
    const data = _cloneDeep(state.data);

    if (_get(data, 'posts')) {
      let postToUpdate = _find(
        data.posts,
        post => post.id === action.payload.postId,
      );

      if (postToUpdate) {
        postToUpdate.like_count -= 1;
        postToUpdate.has_liked = false;
      }
    }

    return {
      ...state,
      data,
    };
  }

  return state;
};

const setPostAsFirst = (state, action) => {
  if (state.data && state.data.posts) {
    const data = _cloneDeep(state.data);

    data.posts.unshift(action.payload);

    return {
      ...state,
      data,
    };
  }

  return {
    ...state,
    data: {
      posts: [action.payload],
    },
  };
};

export const reducer = makeDefaultServerCallReducer({
  loadingActions: [ACCOUNT_POSTS_LOADING],
  successActions: [ACCOUNT_POSTS_SUCCESS],
  errorActions: [ACCOUNT_POSTS_ERROR],
  resetActions: [ACCOUNT_POSTS_RESET],
  otherActions: [
    { name: ACCOUNT_POSTS_SET_AS_FIRST, callback: setPostAsFirst },
    { name: ACCOUNT_POSTS_LIKE_POST, callback: likeAccountPost },
    { name: ACCOUNT_POSTS_UNLIKE_POST, callback: unLikeAccountPost },
    { name: ACCOUNT_POSTS_SHARE_POST, callback: shareAccountPost },
  ],
  initialState,
});
