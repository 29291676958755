import React from 'react';
import _get from 'lodash/get';
import { connect } from 'react-redux';
import accountPaymentMethods from '../../redux/modules/accountPaymentMethods/accountPaymentMethods.containers';
import type {
  AccountPaymentMethodsMapDispatchToProps,
  AccountPaymentMethodsMapStateToProps,
} from '../../redux/modules/accountPaymentMethods/accountPaymentMethods.containers';
import Select from '../Select/Select';
import LoadingSpinner from '../LoadingSpinner/LoadingSpinner';
import './ContributePaymentMethodSelector.scss';

type Props = AccountPaymentMethodsMapDispatchToProps &
  AccountPaymentMethodsMapStateToProps & {
    loadStoredPaymentMethods?: boolean,
    otherSources?: any[],
    useFormik?: boolean,
    name?: string,
    error?: string,
    customToken?: string,
    customEmail?: string,
  };

class ContributePaymentMethodSelector extends React.Component<Props> {
  static defaultProps = {
    loadStoredPaymentMethods: true,
    useFormik: true,
    name: 'paymentMethod',
    error: '',
  };

  constructor(props: Props) {
    super(props);

    if (this.props.loadStoredPaymentMethods) {
      this.props.accountPaymentMethodsListReset();
    }
  }

  componentDidMount() {
    if (this.props.loadStoredPaymentMethods) {
      const { customToken, customEmail } = this.props;
      let payload = {};

      if (customToken && customEmail) {
        payload = { customToken, customEmail };
      }

      this.props.accountPaymentMethodsList(payload);
    }
  }

  render() {
    const {
      options,
      accountPaymentMethodsListIsLoading,
      accountPaymentMethodsListData,
      accountPaymentMethodsListReset,
      accountPaymentMethodsList,
      accountPaymentMethodsListError,
      loadStoredPaymentMethods,
      customToken,
      customEmail,
      otherSources,
      ...rest
    } = this.props;
    return (
      <div className="contribute-payment-method-selector">
        <Select
          label=""
          size="small"
          options={this.getSelectPaymentMethodOptions()}
          disabled={accountPaymentMethodsListIsLoading}
          {...rest}
        />
        <LoadingSpinner
          type="dark"
          visible={accountPaymentMethodsListIsLoading}
          size="small"
        />
      </div>
    );
  }

  getSelectPaymentMethodOptions() {
    const storedSources = _get(
      this.props,
      'accountPaymentMethodsListData.stripe_sources',
      [],
    );
    const validStoredSources = [];

    storedSources.concat(this.props.otherSources || []).forEach(source => {
      if (source.source_type === 'card' && source.status === 'chargeable') {
        validStoredSources.push({
          label: `${source.credit_card_type} Credit Card - ${
            source.credit_card_number
          }`,
          value: source.stripe_source_id,
        });
      } else if (
        source.source_type === 'bank_account' &&
        source.status === 'verified'
      ) {
        validStoredSources.push({
          label: `Bank Account - ${source.account_number}`,
          value: source.stripe_source_id,
        });
      }
    });

    return [{ label: 'Credit card (other)', value: 'newCard' }].concat(
      validStoredSources,
    );
  }
}

const mapStateToProps = (state, ownProps) => {
  return {
    ...accountPaymentMethods.mapStateToProps(state),
    ...ownProps,
  };
};

const mapDispatchToProps = dispatch => {
  return {
    ...accountPaymentMethods.mapDispatchToProps(dispatch),
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(ContributePaymentMethodSelector);
