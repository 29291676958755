// @flow
import React from 'react';
import Link from '../../components/Link/Link';
import { URL_FEED_PAGE, URL_LOGIN_PAGE } from '../../config/urls';
import './NotFoundPage.scss';

//TODO: update once we have final designs
const NotFoundPage = () => (
  <section className="not-found content">
    <div className="container-fluid fix-width">
      <div className="row justify-content-md-center">
        <div className="col-lg-8 col-md-9 pr-8-md">
          <h1>PAGE NOT FOUND</h1>
          <p>The page you are trying to reach doesn't exist.</p>
          <p>
            Navigate go back to <Link href={URL_FEED_PAGE}>Activity</Link> or{' '}
            <Link href={URL_LOGIN_PAGE}>Login</Link>.
          </p>
        </div>
      </div>
    </div>
  </section>
);

export default NotFoundPage;
