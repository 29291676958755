// @flow
import makeDefaultServerCallReducer from '../../utilities/makeDefaultServerCallReducer';
import {
  POST_FLAG_SUCCESS,
  POST_FLAG_RESET,
  POST_FLAG_LOADING,
  POST_FLAG_ERROR,
} from './postFlag.constants';

const initialState = {
  isLoading: false,
  error: null,
  data: null,
};

export const reducer = makeDefaultServerCallReducer({
  loadingActions: [POST_FLAG_LOADING],
  successActions: [POST_FLAG_SUCCESS],
  errorActions: [POST_FLAG_ERROR],
  resetActions: [POST_FLAG_RESET],
  initialState,
});
