// @flow
import { createAction } from 'redux-actions';
import {
  GROUP_POSTS_ERROR,
  GROUP_POSTS_LOADING,
  GROUP_POSTS_RESET,
  GROUP_POSTS_SUCCESS,
  GROUP_POSTS_LIKE_POST,
  GROUP_POSTS_UNLIKE_POST,
  GROUP_POSTS_SHARE_POST,
} from './groupPosts.constants';
import { instance as rorGroupsApiProvider } from '../../../services/RoRGroupsApiProvider';
import type { GetGroupPostsPayload } from '../../../services/RoRGroupsApiProvider';

export const groupPostsError = createAction(GROUP_POSTS_ERROR);

export const groupPostsLoading = createAction(GROUP_POSTS_LOADING);

export const groupPostsSuccess = createAction(GROUP_POSTS_SUCCESS);

export const groupPostsReset = createAction(GROUP_POSTS_RESET);

export const groupPostsLikePost = createAction(GROUP_POSTS_LIKE_POST);

export const groupPostsUnLikePost = createAction(GROUP_POSTS_UNLIKE_POST);

export const groupPostsSharePost = createAction(GROUP_POSTS_SHARE_POST);

export const getGroupPosts = (payload: GetGroupPostsPayload) => {
  return async (dispatch: Function) => {
    try {
      dispatch(groupPostsLoading());

      const response = await rorGroupsApiProvider.groupPosts(payload);

      dispatch(groupPostsSuccess(response));
    } catch (error) {
      dispatch(groupPostsError(error));
    }
  };
};
