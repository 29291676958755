import {
  makeCommittedDonation as makeDonation,
  contributionCommittedDonationMakeReset as reset,
} from './contributionCommittedDonationMake.actions';
import type { PostMakeCommittedDonationPayload } from '../../../services/RoRContributionsApiProvider';
import type { DefaultResponse } from '../../../services/RoRApiProvider';

export const mapStateToProps = state => {
  return {
    contributionCommittedDonationMakeData:
      state.contributionCommittedDonationMake.data,
    contributionCommittedDonationMakeError:
      state.contributionCommittedDonationMake.error,
    contributionCommittedDonationMakeIsLoading:
      state.contributionCommittedDonationMake.isLoading,
  };
};

export const mapDispatchToProps = dispatch => {
  return {
    makeCommittedDonation(payload: PostMakeCommittedDonationPayload) {
      dispatch(makeDonation(payload));
    },
    contributionCommittedDonationMakeReset() {
      dispatch(reset());
    },
  };
};

export type ContributionCommittedDonationMakeMapStateToProps = {
  contributionCommittedDonationMakeData: DefaultResponse,
  contributionCommittedDonationMakeError: any,
  contributionCommittedDonationMakeIsLoading: boolean,
};

export type ContributionCommittedDonationMakeDispatchToProps = {
  makeCommittedDonation: (payload: PostMakeCommittedDonationPayload) => void,
  contributionCommittedDonationMakeReset: () => void,
};

export default {
  mapStateToProps,
  mapDispatchToProps,
};
