// @flow
import React from 'react';
import numeral from 'numeral';
import classNames from 'classnames';

type Props = {
  amount: number,
  format?: string,
  showDecimals: boolean,
  className?: string,
};

const Currency = ({ amount, format, showDecimals, className }: Props) => {
  return (
    <div className={getClassName(className)}>
      <span>
        {numeral(amount).format(format)}&nbsp;
        {showDecimals && (
          <sup>
            {numeral(amount)
              .format('.00')
              .substr(1)}
          </sup>
        )}
      </span>
    </div>
  );
};

function getClassName(className) {
  return classNames({
    currency: true,
    [className || '']: className,
  });
}

Currency.defaultProps = {
  format: '$ 0,0',
  showDecimals: true,
};

export default Currency;
