import {
  getUserCandidateSubInterests,
  userCandidateSubInterestsReset as reset,
} from './userCandidateSubInterests.actions';
import type {
  GetSubInterestsPayload,
  GetSubInterestsResponse,
} from '../../../services/RoRUsersApiProvider';

export const mapStateToProps = state => {
  return {
    userCandidateSubInterestsData: state.userCandidateSubInterests.data,
    userCandidateSubInterestsError: state.userCandidateSubInterests.error,
    userCandidateSubInterestsIsLoading:
      state.userCandidateSubInterests.isLoading,
  };
};

export const mapDispatchToProps = dispatch => {
  return {
    getCandidateSubInterestsForUser(payload: GetSubInterestsPayload) {
      dispatch(getUserCandidateSubInterests(payload));
    },
    userCandidateSubInterestsReset() {
      dispatch(reset());
    },
  };
};

export type UserCandidateSubInterestsMapStateToProps = {
  userCandidateSubInterestsData: GetSubInterestsResponse,
  userCandidateSubInterestsError: any,
  userCandidateSubInterestsIsLoading: boolean,
};

export type UserCandidateSubInterestsMapDispatchToProps = {
  getCandidateSubInterestsForUser: Function,
  userCandidateSubInterestsReset: Function,
};

export default {
  mapStateToProps,
  mapDispatchToProps,
};
