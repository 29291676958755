// @flow
import React from 'react';
import classNames from 'classnames';
import './LoadingSpinner.scss';

type Props = {
  className?: string,
  type: 'light' | 'dark',
  visible: boolean,
  size?: 'default' | 'small',
};

const LoadingSpinner = (props: Props) => {
  return <div className={getClassName(props)} />;
};

function getClassName(props: Props) {
  return classNames({
    ['loading-spinner']: true,
    ['loading-spinner--visible']: props.visible,
    ['loading-spinner--light']: props.type === 'light',
    ['loading-spinner--dark']: props.type === 'dark',
    [`loading-spinner--size-${props.size || ''}`]: true,
    [props.className || '']: props.className,
  });
}

LoadingSpinner.defaultProps = {
  type: 'light',
  size: 'default',
};

export default LoadingSpinner;
