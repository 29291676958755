// @flow
import { createAction } from 'redux-actions';
import {
  ACCOUNT_PAYMENT_METHODS_LIST_ERROR,
  ACCOUNT_PAYMENT_METHODS_LIST_SUCCESS,
  ACCOUNT_PAYMENT_METHODS_LIST_LOADING,
  ACCOUNT_PAYMENT_METHODS_LIST_RESET,
} from './accountPaymentMethods.constants';
import { instance as rorContributionsApiProvider } from '../../../services/RoRContributionsApiProvider';
import type { GetStripeSourcesPayload } from '../../../services/RoRContributionsApiProvider';

export const accountPaymentMethodsListError = createAction(
  ACCOUNT_PAYMENT_METHODS_LIST_ERROR,
);

export const accountPaymentMethodsListLoading = createAction(
  ACCOUNT_PAYMENT_METHODS_LIST_LOADING,
);

export const accountPaymentMethodsListSuccess = createAction(
  ACCOUNT_PAYMENT_METHODS_LIST_SUCCESS,
);

export const accountPaymentMethodsListReset = createAction(
  ACCOUNT_PAYMENT_METHODS_LIST_RESET,
);

export const accountPaymentMethodsList = (
  payload?: GetStripeSourcesPayload,
) => {
  return async (dispatch: Function) => {
    try {
      dispatch(accountPaymentMethodsListReset());

      dispatch(accountPaymentMethodsListLoading());

      const response = await rorContributionsApiProvider.getStripeSources(
        payload,
      );

      dispatch(accountPaymentMethodsListSuccess(response));
    } catch (error) {
      dispatch(accountPaymentMethodsListError(error));
    }
  };
};
