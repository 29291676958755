//@flow
import * as yup from 'yup';

const schema = yup.string().email();

export const normalizeEmail = (value: string) => {
  if (!isValidEmail(value)) {
    throw 'Invalid email';
  }
  return value.trim().toLowerCase();
};

const isValidEmail = (value: string) => {
  return schema.isValidSync((value || '').trim());
};

export default isValidEmail;
