import {
  getAccountCandidatePledges,
  accountCandidatePledgesReset as reset,
} from './accountCandidatePledges.actions';
import type { GetCandidatePledgesResponse } from '../../../services/RoRUsersApiProvider';

export const mapStateToProps = state => {
  return {
    accountCandidatePledgesData: state.accountCandidatePledges.data,
    accountCandidatePledgesError: state.accountCandidatePledges.error,
    accountCandidatePledgesIsLoading: state.accountCandidatePledges.isLoading,
  };
};

export const mapDispatchToProps = dispatch => {
  return {
    getAccountCandidatePledges(payload) {
      dispatch(getAccountCandidatePledges(payload));
    },
    accountCandidatePledgesReset() {
      dispatch(reset());
    },
  };
};

export type AccountCandidatePledgesMapStateToProps = {
  accountCandidatePledgesData: GetCandidatePledgesResponse,
  accountCandidatePledgesError: any,
  accountCandidatePledgesIsLoading: boolean,
};

export type AccountCandidatePledgesMapDispatchToProps = {
  getAccountCandidatePledges: Function,
  accountCandidatePledgesReset: Function,
};

export default {
  mapStateToProps,
  mapDispatchToProps,
};
