// @flow
import {
  accountDetailsReset as reset,
  getAccountDetails as getDetails,
} from './accountDetails.actions';
import type { UserDetails } from '../../../services/RoRUsersApiProvider';
import type { GetUserDetailsPayload } from '../../../services/RoRUsersApiProvider';

export const mapStateToProps = state => {
  return {
    accountDetailsData: state.accountDetails.data,
    accountDetailsError: state.accountDetails.error,
    accountDetailsIsLoading: state.accountDetails.isLoading,
  };
};

export const mapDispatchToProps = dispatch => {
  return {
    getAccountDetails(payload?: GetUserDetailsPayload) {
      dispatch(getDetails(payload));
    },
    accountDetailsReset() {
      dispatch(reset());
    },
  };
};

export type AccountDetailsMapStateToProps = {
  accountDetailsData: { user: UserDetails } | null,
  accountDetailsError: any,
  accountDetailsIsLoading: boolean,
};

export type AccountDetailsMapDispatchToProps = {
  getAccountDetails: (payload?: GetUserDetailsPayload) => void,
  accountDetailsReset: Function,
};

export default {
  mapStateToProps,
  mapDispatchToProps,
};
