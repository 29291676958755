// @flow
import { createAction } from 'redux-actions';
import {
  POST_COMMENTS_ERROR,
  POST_COMMENTS_LOADING,
  POST_COMMENTS_RESET,
  POST_COMMENTS_SUCCESS,
  POST_COMMENTS_SET_AS_FIRST,
} from './postComments.constants';
import { instance as rorPostsApiProvider } from '../../../services/RoRPostsApiProvider';
import type { GetPostCommentsPayload } from '../../../services/RoRPostsApiProvider';

export const postCommentsError = createAction(POST_COMMENTS_ERROR);

export const postCommentsLoading = createAction(POST_COMMENTS_LOADING);

export const postCommentsSuccess = createAction(POST_COMMENTS_SUCCESS);

export const postCommentsReset = createAction(POST_COMMENTS_RESET);

export const postCommentsSetAsFirst = createAction(POST_COMMENTS_SET_AS_FIRST);

export const getPostComments = (payload: GetPostCommentsPayload) => {
  return async (dispatch: Function) => {
    try {
      dispatch(postCommentsLoading());

      const response = await rorPostsApiProvider.postComments(payload);

      dispatch(postCommentsSuccess(response));
    } catch (error) {
      dispatch(postCommentsError(error));
    }
  };
};

export const getPostCommentsNextPage = () => {};
