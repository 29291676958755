// @flow
import { createAction } from 'redux-actions';
import {
  GROUP_REQUEST_JOIN_ERROR,
  GROUP_REQUEST_JOIN_LOADING,
  GROUP_REQUEST_JOIN_RESET,
  GROUP_REQUEST_JOIN_SUCCESS,
} from './groupRequestJoin.constants';
import { instance as rorGroupsApiProvider } from '../../../services/RoRGroupsApiProvider';
import type { PostGroupRequestJoinPayload } from '../../../services/RoRGroupsApiProvider';
import { getGroupDetails } from '../groupDetails/groupDetails.actions';

export const groupRequestJoinError = createAction(GROUP_REQUEST_JOIN_ERROR);

export const groupRequestJoinLoading = createAction(GROUP_REQUEST_JOIN_LOADING);

export const groupRequestJoinSuccess = createAction(GROUP_REQUEST_JOIN_SUCCESS);

export const groupRequestJoinReset = createAction(GROUP_REQUEST_JOIN_RESET);

export const requestJoinGroup = (payload: PostGroupRequestJoinPayload) => {
  return async (dispatch: Function) => {
    try {
      dispatch(groupRequestJoinLoading());
      const response = await rorGroupsApiProvider.groupRequestJoin(payload);

      dispatch(groupRequestJoinSuccess(response));
      dispatch(getGroupDetails(payload));
    } catch (error) {
      dispatch(groupRequestJoinError(error));
    }
  };
};
