import {
  postCommentAdd,
  postCommentAddReset as reset,
} from './postCommentAdd.actions';

export const mapStateToProps = state => {
  return {
    postCommentAddData: state.postCommentAdd.data,
    postCommentAddError: state.postCommentAdd.error,
    postCommentAddIsLoading: state.postCommentAdd.isLoading,
  };
};

export const mapDispatchToProps = dispatch => {
  return {
    createPostComment(payload) {
      dispatch(postCommentAdd(payload));
    },
    postCommentAddReset() {
      dispatch(reset());
    },
  };
};

export default {
  mapStateToProps,
  mapDispatchToProps,
};
