// @flow
import React from 'react';
import Route from 'react-router-dom/Route';
import withRouter from 'react-router-dom/withRouter';
import queryString from 'query-string';
import { connect } from 'react-redux';
import Redirect from 'react-router-dom/Redirect';
import account from '../../redux/modules/account/account.containers';
import { URL_LOGIN_PAGE } from '../../config/urls';
import type {
  AccountMapDispatchToProps,
  AccountMapStateToProps,
} from '../../redux/modules/account/account.containers';

type Props = AccountMapStateToProps &
  AccountMapDispatchToProps & {
    component: any,
    urlToken?: boolean,
  };

// TODO: add flow for props types
class PrivateRoute extends React.Component<Props> {
  render() {
    const {
      component: Component,
      accountData,
      accountError,
      accountIsLoading,
      ...rest
    } = this.props;

    return (
      <Route
        {...rest}
        render={props =>
          this.isLoggedIn() ? (
            <Component {...props} />
          ) : (
            <Redirect
              to={{
                pathname: URL_LOGIN_PAGE,
                state: { from: props.location },
              }}
            />
          )
        }
      />
    );
  }

  // TODO: update logic to detect if the user is logged in or not
  // TODO: update once the server store detects user login properly
  isLoggedIn() {
    const search = queryString.parse(this.props.location.search);
    return (
      typeof window === 'undefined' ||
      ((this.props.accountData && this.props.accountData.user) ||
        (this.props.urlToken && search.token && search.email && search.id))
    );
  }
}

const mapStateToProps = (state, ownProps) => {
  return {
    ...account.mapStateToProps(state),
    ...ownProps,
  };
};

const mapDispatchToProps = dispatch => {
  return {
    ...account.mapDispatchToProps(dispatch),
  };
};

export default withRouter(
  connect(
    mapStateToProps,
    mapDispatchToProps,
  )(PrivateRoute),
);
