// @flow
import React from 'react';
import { connect } from 'react-redux';
import { Formik, FormikComputedProps } from 'formik';
import * as yup from 'yup';
import Link from '../Link/Link';
import Button from '../Button/Button';
import Input from '../Input/Input';
import Checkboxes from '../Checkboxes/Checkboxes';
import { URL_SIGN_UP_PAGE, URL_PRIVACY_POLICY_PAGE } from '../../config/urls';
import type {
  AccountMapDispatchToProps,
  AccountMapStateToProps,
} from '../../redux/modules/account/account.containers';
import account from '../../redux/modules/account/account.containers';
import './LoginForm.scss';

type Props = AccountMapStateToProps & AccountMapDispatchToProps & {};

type FormValues = {
  email: string,
  password: string,
  rememberMe: string[],
};

class LoginForm extends React.Component<Props> {
  render() {
    return (
      <div className="login-form">
        <h1>Welcome back to Prytany</h1>

        <Formik {...this.getFormikProps()}>
          {props => (
            <LoginInnerForm
              {...props}
              isLoading={this.props.accountIsLoading}
              loginServiceError={
                this.props.accountError
                  ? this.props.accountError.serverMessage
                  : ''
              }
            />
          )}
        </Formik>
      </div>
    );
  }

  getFormikProps() {
    return {
      initialValues: {
        email: '',
        password: '',
        rememberMe: [],
      },
      validationSchema: yup.object().shape({
        email: yup
          .string()
          .required('This field is required')
          .email('This field must be an email address'),
        password: yup.string().required('This field is required'),
      }),
      onSubmit: this.onFormSubmit,
    };
  }

  onFormSubmit = (values: FormValues) => {
    const { email, password, rememberMe } = values;
    this.props.doLogin({
      email,
      password,
      remember_me: rememberMe.length !== 0,
    });
  };
}

const LoginInnerForm = (
  props: FormikComputedProps & {
    isLoading: boolean,
    loginServiceError: string | null,
  },
) => {
  const {
    touched,
    errors,
    handleSubmit,
    isSubmitting,
    isLoading,
    setSubmitting,
    loginServiceError,
  } = props;

  if (isSubmitting && !isLoading) {
    setSubmitting(false);
  }

  return (
    <form onSubmit={handleSubmit}>
      <Input
        name="email"
        label="Email Address"
        type="email"
        error={touched.email && errors.email ? errors.email : ''}
      />
      <Input
        name="password"
        label="Password"
        type="password"
        error={touched.password && errors.password ? errors.password : ''}
      />
      <div className="form-group">
        <div className="row mx-1 pt-4 pb-4 options">
          <Checkboxes
            className="col-6 px-0"
            items={[{ label: 'Remember me', value: '1' }]}
            name="rememberMe"
          />
          <div className="col-6 px-0 text-right">
            <Link href="/reset-password" className="link-violet">
              Forgot password
            </Link>
          </div>
        </div>
      </div>
      <div className="mx-0">
        <div className="col-6">
          <Button
            loading={isLoading}
            type="submit"
            block={true}
            buttonType="primary">
            Login
          </Button>
        </div>
        <div className="hr">
          <hr />
        </div>
        <div className="LoginSignUpTextPrompt">
          <p>
            If you are not yet registered, click on{' '}
            <strong>
              <a href={URL_SIGN_UP_PAGE} className="link-violet">
                Sign up
              </a>
            </strong>
          </p>
        </div>
        <div className="login-form__service-error">
          {loginServiceError && (
            <span className="login-form__service-error-message">
              {loginServiceError}
            </span>
          )}
        </div>
        <div className="col-12 terms px-0">
          <Link href={URL_PRIVACY_POLICY_PAGE} className="link-violet">
            Terms and conditions & Privacy and Policy
          </Link>
        </div>
      </div>
      <div />
    </form>
  );
};

const mapStateToProps = (state, ownProps) => {
  return {
    ...account.mapStateToProps(state),
    ...ownProps,
  };
};

const mapDispatchToProps = dispatch => {
  return {
    ...account.mapDispatchToProps(dispatch),
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(LoginForm);
