// @flow
import React from 'react';
import ContributeCommitted from '../../components/ContributeCommitted/ContributeCommitted';
import MobileContributionsTypePage from './MobileContributionsTypePage';

const MobileContributionsCommittedPage = () => {
  return (
    <MobileContributionsTypePage>
      {({ mobileToken, mobileEmail, mobileGroupId, ...rest }) => (
        <ContributeCommitted
          customPaymentToken={mobileToken}
          customPaymentEmail={mobileEmail}
          groupId={mobileGroupId}
          {...rest}
        />
      )}
    </MobileContributionsTypePage>
  );
};

export default MobileContributionsCommittedPage;
