//@flow
import React, { Component } from 'react';
import './ModalGroupMemberContributions.scss';
import type { ModalProps } from '../Modal/Modal';
import Modal from '../Modal/Modal';
import classNames from 'classnames';
import type { GroupMember } from '../../services/RoRGroupsApiProvider';
import Avatar from '../Avatar/Avatar';
import Table from '../Table/Table';
import { connect } from 'react-redux';
import {
  mapStateToProps,
  mapDispatchToProps,
} from '../../redux/modules/groupMemberContributions/groupMemberContributions.containers';
import type {
  GroupMemberContributionsMapDispatchToProps,
  GroupMemberContributionsMapStateToProps,
} from '../../redux/modules/groupMemberContributions/groupMemberContributions.containers';
import Currency from '../Currency/Currency';
import RoleCode from '../../constants/RoleCode';
import isPrytanyVerified from '../../utilities/isPrytanyVerified';

type Props = ModalProps &
  GroupMemberContributionsMapStateToProps &
  GroupMemberContributionsMapDispatchToProps & {
    groupId: number,
    groupMember: GroupMember,
  };

class ModalGroupMemberContributions extends Component<Props, {}> {
  componentDidMount() {
    this.props.groupMemberContributionsReset();
    this.props.getGroupMemberContributions({
      groupId: this.props.groupId,
      memberId: this.props.groupMember.id,
    });
  }

  render() {
    const { ...modalProps } = this.props;
    return (
      <Modal
        head="Contribution Details"
        contentLabel="Invite by Email or Phone Number"
        className={this.getClassName()}
        size="micro"
        {...modalProps}>
        {modalProps.isOpen ? this.renderContent() : null}
      </Modal>
    );
  }

  renderContent() {
    const { groupMember: member } = this.props;
    return (
      <div>
        <div className="row mb-3">
          <div className="col-12">
            <Avatar
              isCandidate={member.role.code === RoleCode.CANDIDATE}
              verifiedLevel={member.verified_level}
              verifiedDetail={member.verified_detail}
              className="dashboard-page__table-cell__name-avatar"
              source={member.profile_image}
              type="tiny"
            />
            <span className="fw-600">
              {`${member.first_name} ${member.last_name}`.trim()}
            </span>
          </div>
        </div>
        <div className="row mb-3">
          <div className="col-3">
            <span className="text-muted">STATE</span>
            <br />
            <span className="fw-600">{member.state.code}</span>
          </div>
          <div className="col-5">
            <span className="text-muted">SHARED INTERESTS</span>
            <br />
            <span className="fw-600">{member.shared_interests}</span>
          </div>
          <div className="col-4">
            <span className="text-muted">TOTAL</span>
            <br />
            <span className="fw-600">
              <Currency
                amount={(member.donations.contributed || 0) / 100}
              />
            </span>
          </div>
        </div>
        <div className="row">
          <div className="col-12">
            <Table {...this.getTableProps()} />
          </div>
        </div>
      </div>
    );
  }

  getClassName() {
    return classNames({
      'modal-group-member-details': true,
      [this.props.className || '']: this.props.className,
    });
  }

  getTableProps() {
    const tableProps: any = {
      dataSource: this.getTableData(),
      columns: this.getTableColumns(),
      loading: this.props.groupMemberContributionsIsLoading,
      pagination: false,
    };

    return tableProps;
  }

  getTableData = () => {
    if (!this.props.groupMemberContributionsData) {
      return [];
    }
    const {
      groupMemberContributionsData: {
        contributions: { direct },
      },
    } = this.props;

    return direct.map(donation => {
      const {
        id: key,
        candidate_full_name: candidateName,
        amount_in_cents,
      } = donation;
      return { key, candidateName, amount: (amount_in_cents || 0) / 100 };
    });
  };

  getTableColumns = () => {
    return [
      {
        title: 'TRANSACTIONS',
        dataIndex: 'candidateName',
        key: 'candidateName',
        sorter: (a: any, b: any) => a.name.localeCompare(b.name),
        render: (text, data) => `Contributed to ${data.candidateName}`,
      },
      {
        title: 'AMOUNT',
        dataIndex: 'amount',
        key: 'amount',
        sorter: (a: any, b: any) => a.amount - b.amount,
        render: (text, data) => <Currency amount={data.amount} />,
      },
    ];
  };
}

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(ModalGroupMemberContributions);
