// @flow
import { createAction } from 'redux-actions';
import {
  SEND_MESSAGE_ERROR,
  SEND_MESSAGE_LOADING,
  SEND_MESSAGE_SUCCESS,
  SEND_MESSAGE_RESET,
} from './sendMessage.constants';

import { instance as rorInboxMessagesApiProvider } from '../../../services/RoRInboxMessagesApiProvider';
import type { PostNewMessagePayload } from '../../../services/RoRInboxMessagesApiProvider';

export const sendMessageError = createAction(SEND_MESSAGE_ERROR);

export const sendMessageLoading = createAction(SEND_MESSAGE_LOADING);

export const sendMessageSuccess = createAction(SEND_MESSAGE_SUCCESS);

export const sendMessageReset = createAction(SEND_MESSAGE_RESET);

export const sendMessage = (payload: NewMessagePayload) => {
  return async (dispatch: Function) => {
    try {
      const apiPayload = parseSendMessagePayload(payload);

      dispatch(sendMessageLoading());

      const response = await rorInboxMessagesApiProvider.sendMessage(
        apiPayload,
      );

      dispatch(sendMessageSuccess(response));
    } catch (error) {
      dispatch(sendMessageError(error));
    }
  };
};

function parseSendMessagePayload(payload: NewMessagePayload) {
  const parsedPayload: NewMessagePayload = {
    receiver_id: payload.receiver_id,
    body: payload.message,
  };
  return parsedPayload;
}

type NewMessagePayload = {
  receiver_id: string,
  message: string,
};
