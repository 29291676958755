// @flow
import * as React from 'react';
import { Field } from 'formik';
import classNames from 'classnames';
import './Select.scss';

type Props = {
  className?: string,
  inputClassName?: string,
  placeholder?: string,
  useOnlyBorderedErrors?: boolean,
  name: string,
  label: any,
  options: Array<{ label: string, value: string, disabled?: boolean }>,
  error?: string,
  size?: 'normal' | 'small',
  useFormik?: boolean,
  disabled?: boolean,
};

const Select = (props: Props) => {
  const { useFormik, ...rest } = props;
  return useFormik ? <SelectFormik {...rest} /> : <SelectIsolated {...rest} />;
};

Select.defaultProps = {
  size: 'normal',
  useFormik: true,
  placeholder: '',
  useOnlyBorderedErrors: false,
  disabled: false,
};

const SelectFormik = (props: Props) => {
  const inputId = `select-${props.name}`;

  return (
    <div className={getClassName(props)}>
      <SelectLabel id={inputId} label={props.label} />
      <Field
        name={props.name}
        render={({ form, field }) => {
          return (
            <select
              id={inputId}
              className={getCustomInputClassName(props)}
              onChange={e =>
                handleChange({ name: props.name, value: e.target.value, form })
              }
              onBlur={field.onBlur}
              disabled={props.disabled}>
              <option value="" selected={!field.value}>
                {props.placeholder || ''}
              </option>
              {props.options.map((option, index) => (
                <option
                  key={index}
                  disabled={option.disabled || false}
                  value={option.value}
                  selected={field.value === option.value}>
                  {option.label}
                </option>
              ))}
            </select>
          );
        }}
      />
      {!props.useOnlyBorderedErrors && props.error ? (
        <span className="select__error-message">{props.error}</span>
      ) : null}
    </div>
  );
};

type SelectIsolatedProps = Props & { onChange?: Function, value?: string };
type SelectIsolatedState = { value: string };

class SelectIsolated extends React.Component<
  SelectIsolatedProps,
  SelectIsolatedState,
> {
  inputId: string;

  constructor(props) {
    super(props);

    this.inputId = `select-${props.name}`;
    this.state = {
      value: this.props.value || '',
    };
  }

  componentDidUpdate(prevProps) {
    if (
      prevProps.value !== this.props.value &&
      this.state.value !== this.props.value
    ) {
      this.setState(() => ({ value: this.props.value }));
    }
  }

  render() {
    const {
      className,
      onChange,
      label,
      options,
      useOnlyBorderedErrors,
      ...rest
    } = this.props;

    return (
      <div className={getClassName(this.props)}>
        <SelectLabel id={this.inputId} label={label} />
        <select
          value={this.state.value}
          id={this.inputId}
          className={getCustomInputClassName(this.props)}
          onChange={this.handleChange}
          {...rest}>
          <option value="">{this.props.placeholder || ''}</option>
          {options.map((option, index) => (
            <option
              key={index}
              value={option.value}
              disabled={option.disabled || false}>
              {option.label}
            </option>
          ))}
        </select>
      </div>
    );
  }

  handleChange = (e: any) => {
    const value = e.target.value;

    this.setState(
      () => ({ value }),
      () => {
        if (this.props.onChange) {
          this.props.onChange({
            name: this.props.name,
            value: this.state.value,
          });
        }
      },
    );
  };
}

const SelectLabel = ({ label, id }: { label: any, id: string }) => {
  if (typeof label === 'string') {
    return <label htmlFor={id}>{label}</label>;
  }

  return <div className="select__custom-label">{label}</div>;
};

function handleChange({
  name,
  value,
  form,
}: {
  name: string,
  value: string,
  form: any,
}) {
  form.setFieldValue(name, value);
}

function getClassName(props) {
  return classNames({
    select: true,
    [props.className || '']: props.className,
  });
}

function getCustomInputClassName(props: Props) {
  return classNames({
    select__input: true,
    [`select__input--${props.size || ''}`]: true,
    'custom-select': true,
    'select__input--error': props.error,
    [props.inputClassName || '']: props.inputClassName,
  });
}

export default Select;
