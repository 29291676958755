// @flow
import React from 'react';
import { Formik, FormikComputedProps } from 'formik';
import { connect } from 'react-redux';
import _get from 'lodash/get';
import * as yup from 'yup';
import classNames from 'classnames';
import Modal from '../Modal/Modal';
import Input from '../Input/Input';
import FileInput from '../FileInput/FileInput';
import Toggle from '../Toggle/Toggle';
import TextArea from '../TextArea/TextArea';
import Select from '../Select/Select';
import Button from '../Button/Button';
import sendMessage from '../../redux/modules/sendMessage/sendMessage.containers';
import userList from '../../redux/modules/userList/userList.containers';
import type {
  UserListMapStateToProps,
  UserListMapDispatchToProps,
} from '../../redux/modules/userList/userList.containers';
import './ModalNewMessage.scss';
import SelectOptions from 'react-select';

type Props = UserListMapStateToProps &
  UserListMapDispatchToProps & {
    isOpen: boolean,
    onAfterOpen?: Function,
    onCloseRequest?: Function,
    className?: string,
    sendMessage: Function,
    sendMessageReset: Function,
    onSuccess: Function,
    onError: Function,
  };

type FormValues = {
  toUser: string,
  message: string,
};

class ModalSendMessage extends React.Component<Props, State> {
  formikForm: Formik;
  fileInput: any;

  constructor(props: Props) {
    super(props);

    this.state = {
      toUser: props.preSelectedUser ? props.preSelectedUser : null,
    };
  }

  componentDidMount() {
    this.props.sendMessageReset(); //#todo redux function
  }

  componentDidUpdate(prevProps: Props) {
    if (!prevProps.isOpen && this.props.isOpen) {
      this.props.sendMessageReset();
    }

    if (!prevProps.sendMessageData && this.props.sendMessageData) {
      this.props.onSuccess(this.props.sendMessageData);
    }

    if (!prevProps.sendMessageError && this.props.sendMessageError) {
      this.props.onError(this.props.sendMessageError);
    }
  }

  render() {
    const {
      isOpen,
      onAfterOpen,
      onCloseRequest,
      className,
      sendMessageData,
      sendMessagIsLoading,
      sendMessageError,
      sendMessageReset,
      sendMessage,
      ...rest
    } = this.props;

    return (
      <Modal
        contentLabel="Send a new message"
        isOpen={isOpen}
        onAfterOpen={onAfterOpen}
        onCloseRequest={onCloseRequest}
        className={this.getClassName()}
        {...rest}>
        {isOpen ? (
          <Formik {...this.getFormikProps()}>
            {props => this.renderInnerForm(props)}
          </Formik>
        ) : null}
      </Modal>
    );
  }

  handleInputChange = (event: any) => {
    this.setState({ toUser: event.target.value });
    if (event.target.value) {
      this.props.getUserList({ name: event.target.value });
    }
  };

  handleInputChangeToUser = (inputValue: any, actionMeta: any) => {
    if (inputValue.length >= 1 && inputValue.trim() !== '') {
      this.props.getUserList({ name: inputValue });
    }
  };

  renderInnerForm = (props: FormikComputedProps) => {
    const getUserListDropDown = () => {
      let userList = [],
        APIusersList = _get(this.props, 'userListData.data.users', {});
      [...APIusersList].forEach(function(userInfo) {
        var userDetails = {
          value: userInfo.id,
          label: userInfo.first_name + ' ' + userInfo.last_name,
        };
        userList.push(userDetails);
      });
      return userList;
    };

    const { touched, errors, handleSubmit, setFieldValue } = props;

    const setSelectedRecipient = value => {
      this.setState({ toUser: value.slice(-1).pop() });
    };

    const setReceiverToFormik = function(value, { action, removedValue }) {
      const optionsList = getUserListDropDown();
      const orderOptions = values => {
        return values
          .filter(v => v.isFixed)
          .concat(values.filter(v => !v.isFixed));
      };

      switch (action) {
        case 'remove-value':
        case 'pop-value':
          if (removedValue.isFixed) {
            return;
          }
          break;
        case 'clear':
          value = optionsList.filter(v => v.isFixed);
          break;
      }
      value = orderOptions(value);
      setSelectedRecipient(value);
      setFieldValue('toUser', value.slice(-1).pop());
    };

    return (
      <form className="modal-new-message__inner-form" onSubmit={handleSubmit}>
        <div className="row pb-3">
          <div className="col-12 text-center pb-2 d-block d-md-none">
            <h5>Send Message</h5>
          </div>
          <div className="col-12">
            <div className="row">
              <div className="col-12 pl-md-2 pl-lg-3">
                <div className="mb-4">
                  {this.props.preSelectedUser ? (
                    <p className="modal-new-message__to-field">
                      <span className="modal-new-message__to">To: </span>
                      {this.props.preSelectedUser.label}
                    </p>
                  ) : (
                    <SelectOptions
                      placeholder="To:"
                      isMulti
                      name="toUser"
                      options={getUserListDropDown()}
                      className="basic-multi-select"
                      classNamePrefix="select"
                      onChange={setReceiverToFormik}
                      onInputChange={this.handleInputChangeToUser}
                      size="small"
                      value={this.state.toUser ? this.state.toUser : ''}
                    />
                  )}
                  {errors.toUser && touched.toUser && (
                    <span class="text-area__error-message">
                      {errors.toUser}
                    </span>
                  )}
                </div>
                <TextArea
                  label=""
                  placeholder="Write a Message..."
                  name="message"
                  error={
                    touched.message && errors.message ? errors.message : ''
                  }
                />
              </div>
            </div>
          </div>
        </div>
        <div className="row">
          <div className="col-md-8" />
          <div className="col-md-4 text-right order-4 float-right">
            <div className="row">
              <div className="col-6 px-1 pl-3">
                <Button
                  size="small"
                  loading={this.props.sendMessageIsLoading}
                  type="button"
                  onClick={this.handleCloseRequest}
                  buttonType="outline"
                  block>
                  Cancel
                </Button>
              </div>
              <div className="col-6 pl-1 pr-3">
                <Button
                  size="small"
                  loading={this.props.sendMessageIsLoading}
                  type="button"
                  onClick={this.submitForm}
                  buttonType="primary"
                  block
                  noBorder>
                  Send Message
                </Button>
              </div>
            </div>
          </div>
        </div>
      </form>
    );
  };

  getClassName() {
    return classNames({
      'modal-new-message': true,
      [this.props.className || '']: this.props.className,
    });
  }

  getFormikProps() {
    return {
      initialValues: this.getFormikInitialValues(),
      validationSchema: yup.object().shape(this.getValidationSchema()),
      onSubmit: this.handleFormSubmit,
      ref: this.setFormikRef,
    };
  }

  getValidationSchema() {
    let schema: any = {
      toUser: yup.object().required('This field is required'),
      message: yup
        .string()
        .trim()
        .required('This field is required'),
    };
    return schema;
  }

  getFormikInitialValues() {
    return {
      toUser: this.state.toUser,
      message: '',
    };
  }

  setFormikRef = (ref: Formik) => {
    this.formikForm = ref;
  };

  handleCloseRequest = () => {
    if (this.props.onCloseRequest) {
      this.props.onCloseRequest();
    }
  };

  submitForm = () => {
    // Manually triggering touch before sending the form
    this.formikForm.setTouched({
      toUser: true,
      message: true,
    });
    this.formikForm.submitForm();
  };

  handleFormSubmit = (values: FormValues) => {
    this.props.sendMessage({ receiver_id: this.state.toUser.value, ...values });
  };
}

const mapStateToProps = (state, ownProps) => {
  return {
    ...sendMessage.mapStateToProps(state),
    ...userList.mapStateToProps(state),
    ...ownProps,
  };
};

const mapDispatchToProps = dispatch => {
  return {
    ...sendMessage.mapDispatchToProps(dispatch),
    ...userList.mapDispatchToProps(dispatch),
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(ModalSendMessage);
