import {
  accountGroupsReset as reset,
  getAccountGroups as getGroups,
  accountGroupsSetAsFirst as setAsFirst,
} from './accountGroups.actions';

export const mapStateToProps = state => {
  return {
    accountGroupsData: state.accountGroups.data,
    accountGroupsError: state.accountGroups.error,
    accountGroupsIsLoading: state.accountGroups.isLoading,
  };
};

export const mapDispatchToProps = dispatch => {
  return {
    getAccountGroups() {
      dispatch(getGroups());
    },
    accountGroupsReset() {
      dispatch(reset());
    },
    accountGroupsSetAsFirst(payload) {
      dispatch(setAsFirst(payload));
    },
  };
};

export default {
  mapStateToProps,
  mapDispatchToProps,
};
