// @flow
import React from 'react';
import Button from '../Button/Button';
import classNames from 'classnames';
import Link from '../Link/Link';
import { URL_GROUP_POSTS_PAGE } from '../../config/urls';
import './GroupList.scss';
import GroupType from '../../constants/GroupType';
import { Icon } from 'antd';
import pluralize from 'pluralize';

type Props = {
  groupType: 'group' | 'campaign',
  groups: {
    id: number,
    name: string,
    code: string,
    group_image: string,
  }[],
  className?: string,
  onCreateGroupButtonClick?: Function,
  addNewGroupButton?: boolean,
  className?: string,
};

const GroupList = (props: Props) => {
  return (
    <div className={getClassName(props.className)}>
      {renderHeader(props)}
      <div className="row">
        <ul className="col-12">
          {props.groups.map(group => (
            <li className="group-list__item" key={group.code}>
              <Link href={URL_GROUP_POSTS_PAGE.replace(':groupId', group.id)}>
                <div className="row align-items-center">
                  <div className="col-3">
                    <img src={group.group_image} alt={group.name} />
                  </div>
                  <div className="col-9 px-4 px-lg-0">
                    <p>{group.name}</p>
                  </div>
                </div>
              </Link>
            </li>
          ))}
        </ul>
        {props.groups && !props.groups.length ? (
          <div className="col-12 pt-3">You are not part of any group yet.</div>
        ) : null}
      </div>
      <div className="row">
        <div className="col-12 group-list__more text-center">
          <hr />
          {props.groups.length > 4 ? (
            <Button type="button" buttonType="light">{`${props.groups.length -
              4} More`}</Button>
          ) : null}
        </div>
      </div>
    </div>
  );
};

function renderHeader(props: Props) {
  let content = null;

  if (props.addNewGroupButton) {
    content = (
      <div className="d-flex justify-content-between align-items-center">
        <h6 className="group-list__header mt-1 text-capitalize">
          {pluralize(props.groupType)}
        </h6>
        <Button
          size="small"
          onClick={() => handleNewGroupButtonClick(props)}
          buttonType="link"
          className="link-violet text-uppercase">
          <Icon type="plus" className="mr-1" />
          New {props.groupType}
        </Button>
      </div>
    );
  } else {
    content = (
      <div className="row">
        <div className="col-12">
          <h6 className="group-list__header text-capitalize">
            {pluralize(props.groupType)}
          </h6>
        </div>
      </div>
    );
  }

  return content;
}

function getClassName(className) {
  return classNames({
    'group-list': true,
    container: true,
    [className || '']: className,
  });
}

function handleNewGroupButtonClick(props: Props) {
  if (props.onCreateGroupButtonClick) {
    props.onCreateGroupButtonClick();
  }
}

export default GroupList;
