// @flow
import React from 'react';
import { connect } from 'react-redux';
import Link from '../../components/Link/Link';
import { URL_SIGN_UP_PAGE, URL_FEED_PAGE } from '../../config/urls';
import iphoneImage from '../../../public/images/iphone.png';
import iphoneMobile from '../../../public/images/iphone_mobile.png';
import deviceImage from '../../../public/images/hiw_section1_device.png';
import getInvolvedImage from '../../../public/images/hiw_getenvolved.png';

import UserRoleBadge from '../../components/UserRoleBadge/UserRoleBadge';

import './HomePage.scss';
import account from '../../redux/modules/account/account.containers';
import type { AccountMapStateToProps } from '../../redux/modules/account/account.containers';

type Props = AccountMapStateToProps & {};

const HomePage = (props: Props) => {
  const getStartedUrl =
    props.accountData && props.accountData.user
      ? URL_FEED_PAGE
      : URL_SIGN_UP_PAGE;
  return (
    <section className="howitworks content">
      <div className="container-fluid module0">
        <div className="fix-width-1366">
          <div className="row hero">
            <div className="col">
              <div className="col-4 pr-0 pl-0 pl-sm-2">
                <h1>
                  OWN YOUR
                  <br />
                  POLITICS
                </h1>
                <h3 className="f-nunito fs-18 fw-600">Be the difference </h3>
                <h3 className="f-nunito fs-18 fw-600">
                  Connect with, learn about, and support all your candidates and
                  issues in one place.{' '}
                </h3>
              </div>
              <div className="col-sm-3 col-md-2 px-0 howitworks__cta">
                <Link href={getStartedUrl} type="button" className="btn-block">
                  Get Started
                </Link>
              </div>
            </div>
            <div className="iphone">
              <img src={iphoneImage} className="d-none d-sm-block" alt="" />
              <img
                src={iphoneMobile}
                className="d-block d-sm-none d-md-none"
                alt=""
              />
            </div>
          </div>
        </div>
      </div>
      <div className="container-fluid module1">
        <div className="row fix-width">
          <div className="col-md-6 col-lg-5 pr-0 pl-0 txt">
            <h2>Connecting verified constituents and candidates</h2>
            <h3 className="f-nunito fs-16">
              Support issues and candidates you care about. Learn about all the
              candidates. If you decide to contribute, control when and how
              much. Pledge contributions to support policies that matter. Find
              like-minded individuals.
            </h3>
            <div className="col-12 col-md-6 px-0 howitworks__cta">
              <Link href={getStartedUrl} type="button" className="btn-block">
                Get Started
              </Link>
            </div>
          </div>
          <div className="device">
            <img src={deviceImage} alt="" />
          </div>
        </div>
      </div>
      <div className="container module3">
        <div className="row fix-width">
          <div className="col-md-6 get-evolved order-1 order-md-1 pb-5 pb-md-0 pt-4 pt-md-0 radius-involved">
            <img src={getInvolvedImage} alt="" className="img-fluid" />
          </div>
          <div className="col-md-6 px-4 order-2 px-md-5 pt-4 pt-sm-3 pt-md-4 pt-lg-5 mb-3 mb-md-0 order-1 order-md-2 bottom involved radius-involved">
            <h3>
              Get involved now by making contributions and having your voice
              matter.
            </h3>
            <p>
              You can make contributions of up to $2,800 per election (primary
              and general are separate elections) in support of a candidate or
              against a specific candidate by supporting an opponent.
            </p>
            <p>
              You can contribute up to $35,500 per calendar year to any national
              party committee.
            </p>

            <p>
              You can make contributions to causes and issues by contributing to
              the National party that supports your position.
            </p>
            <div className="col-md-6 col-lg-5 px-0 pr-md-1 pr-md-2 pl-md-0 pb-3 pt-3">
              <Link
                href={getStartedUrl}
                id="btn-overide"
                type="button"
                className="btn btn-block no-border btn-involve">
                Get Started
              </Link>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

const mapStateToProps = (state, ownProps) => {
  return {
    ...account.mapStateToProps(state),
    ...ownProps,
  };
};

export default connect(
  mapStateToProps,
  null,
)(HomePage);
