// @flow
import { createAction } from 'redux-actions';
import {
  ACCOUNT_INBOX_MESSAGE_LIST_ERROR,
  ACCOUNT_INBOX_MESSAGE_LIST_LOADING,
  ACCOUNT_INBOX_MESSAGE_LIST_REMOVE_ITEM,
  ACCOUNT_INBOX_MESSAGE_LIST_RESET,
  ACCOUNT_INBOX_MESSAGE_LIST_SUCCESS,
  ACCOUNT_INBOX_MESSAGE_LIST_MARK_AS_READ_ITEM,
} from './accountInboxMessageList.constants';
import { instance as rorUsersApiProvider } from '../../../services/RoRUsersApiProvider';
import { clientStore } from '../../../redux/clientStore';
import _get from 'lodash/get';
import type { GetUserInboxMessageListPayload } from '../../../services/RoRUsersApiProvider';

export const accountInboxMessageListError = createAction(
  ACCOUNT_INBOX_MESSAGE_LIST_ERROR,
);

export const accountInboxMessageListLoading = createAction(
  ACCOUNT_INBOX_MESSAGE_LIST_LOADING,
);

export const accountInboxMessageListSuccess = createAction(
  ACCOUNT_INBOX_MESSAGE_LIST_SUCCESS,
);

export const accountInboxMessageListReset = createAction(
  ACCOUNT_INBOX_MESSAGE_LIST_RESET,
);

export const accountInboxMessageListRemoveItem = createAction(
  ACCOUNT_INBOX_MESSAGE_LIST_REMOVE_ITEM,
);

export const accountInboxMessageListMarkAsReadItem = createAction(
  ACCOUNT_INBOX_MESSAGE_LIST_MARK_AS_READ_ITEM,
);

export const getAccountInboxMessageList = (
  payload: GetUserInboxMessageListPayload,
) => {
  return async (dispatch: Function) => {
    try {
      dispatch(accountInboxMessageListLoading());

      const response = await rorUsersApiProvider.accountInboxMessageList({
        page: payload.page,
        limit: payload.limit,
      });

      dispatch(accountInboxMessageListSuccess(response));
    } catch (error) {
      dispatch(accountInboxMessageListError(error));
    }
  };
};
