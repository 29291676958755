// @flow
import React from 'react';
import Route from 'react-router-dom/Route';
import _get from 'lodash/get';
import { message } from 'antd';
import { connect } from 'react-redux';
import DashboardContributionsPage from './DashboardContributionsPage';
import DashboardPostsPage from './DashboardPostsPage';
import DashboardAboutPage from './DashboardAboutPage';
import DashboardContendersPage from './DashboardContendersPage';
import DashboardGroupsPage from './DashboardGroupsPage';
import ModalNewPost from '../../components/ModalNewPost/ModalNewPost';
import TabPanel from '../../components/TabPanel/TabPanel';
import Button from '../../components/Button/Button';
import {
  URL_DASHBOARD_ABOUT_PAGE,
  URL_DASHBOARD_CONTENDERS_PAGE,
  URL_DASHBOARD_CONTRIBUTIONS_PAGE,
  URL_DASHBOARD_PEOPLE_GROUPS_PAGE,
  URL_DASHBOARD_POSTS_PAGE,
} from '../../config/urls';
import DashboardTopMenu from '../../components/DashboardTopMenu/DashboardTopMenu';
import Redirect from 'react-router-dom/Redirect';
import account from '../../redux/modules/account/account.containers';
import accountContenders from '../../redux/modules/accountContenders/accountContenders.containers';
import accountDetails from '../../redux/modules/accountDetails/accountDetails.containers';
import accountProfile from '../../redux/modules/accountProfile/accountProfile.containers';
import type {
  AccountProfileMapDispatchToProps,
  AccountProfileMapStateToProps,
} from '../../redux/modules/accountProfile/accountProfile.containers';
import type {
  AccountDetailsMapDispatchToProps,
  AccountDetailsMapStateToProps,
} from '../../redux/modules/accountDetails/accountDetails.containers';
import type {
  AccountContendersMapDispatchToProps,
  AccountContendersMapStateToProps,
} from '../../redux/modules/accountContenders/accountContenders.containers';
import type { AccountMapStateToProps } from '../../redux/modules/account/account.containers';
import './DashboardPage.scss';
import {
  hasDashboard,
  isCandidate,
  allowedGroupType,
} from '../../utilities/authorization';
import pluralize from 'pluralize'

type Props = AccountProfileMapDispatchToProps &
  AccountProfileMapStateToProps &
  AccountContendersMapStateToProps &
  AccountContendersMapDispatchToProps &
  AccountDetailsMapStateToProps &
  AccountDetailsMapDispatchToProps &
  AccountMapStateToProps & {
    children: any,
    location: any,
    history: any,
  };

type State = {
  initialTabIndex: number | null,
  newPostModalOpen: boolean,
};

class DashboardPage extends React.Component<Props, State> {
  subSections: { label: string, path: string, component: any }[];

  constructor(props: Props) {
    super(props);

    const user = _get(props, 'accountData.user');

    this.subSections = [
      {
        label: 'Contributions',
        path: URL_DASHBOARD_CONTRIBUTIONS_PAGE,
        component: DashboardContributionsPage,
      },
      {
        label: 'Posts',
        path: URL_DASHBOARD_POSTS_PAGE,
        component: DashboardPostsPage,
      },
      {
        label: `People & ${pluralize(allowedGroupType(user))}`,
        path: URL_DASHBOARD_PEOPLE_GROUPS_PAGE,
        component: DashboardGroupsPage,
      },
      ...(isCandidate(user)
        ? [
            {
              label: 'Contenders',
              path: URL_DASHBOARD_CONTENDERS_PAGE,
              component: DashboardContendersPage,
            },
          ]
        : []),
      {
        label: 'About',
        path: URL_DASHBOARD_ABOUT_PAGE,
        component: DashboardAboutPage,
      },
    ];

    let initialTabIndex = null;

    this.subSections.forEach((subSection: any, index: number) => {
      if (subSection.path === this.props.location.pathname) {
        initialTabIndex = index;
      }
    });

    this.state = {
      initialTabIndex,
      newPostModalOpen: false,
    };
  }

  componentWillMount() {
    // TODO: this request should probably be executed on the server side before rendering this view
    this.props.getAccountDetails();
    this.props.getAccountProfile();
  }

  render() {
    const { user } = this.props.accountData || {};
    return user && hasDashboard(user) && this.state.initialTabIndex !== null
      ? this.renderContent()
      : this.renderRedirectToHome();
  }

  renderContent() {
    return this.props.accountDetailsData && this.props.accountProfileData ? (
      <div className="dashboard-page content">
        <section className="dashboard-page__content">
          <DashboardTopMenu {...this.getDashboardTopMenuProps()} />
          <TabPanel {...this.getTabPanelProps()} />
          {this.subSections.map((subSection: any) => (
            <Route
              exact
              key={subSection.path}
              path={subSection.path}
              component={subSection.component}
            />
          ))}
          <ModalNewPost
            isOpen={this.state.newPostModalOpen}
            onCloseRequest={this.toggleNewPostModalVisibility}
            onSuccess={this.handleNewPostSuccess}
            onError={this.handleNewPostError}
          />
        </section>
      </div>
    ) : null;
  }

  // TODO: we might want to move the user to a 404 page
  renderRedirectToHome() {
    return (
      <Redirect
        to={{
          pathname: '/',
          state: { from: URL_DASHBOARD_CONTRIBUTIONS_PAGE },
        }}
      />
    );
  }

  getTabPanelProps() {
    const items: string[] = this.subSections.map(
      (subSection: any) => subSection.label,
    );

    return {
      items,
      initialActiveIndex: this.state.initialTabIndex || 0,
      onTabChange: this.handleTabChange,
    };
  }

  getDashboardTopMenuProps() {
    return {
      user: _get(this.props, 'accountData.user'),
      userDetails: _get(this.props, 'accountDetailsData.user'),
      onNewPostButtonClick: this.toggleNewPostModalVisibility,
    };
  }

  handleTabChange = (tabIndex: number) => {
    this.props.history.push(this.subSections[tabIndex].path);
  };

  toggleNewPostModalVisibility = () => {
    this.setState((prevState: State) => ({
      newPostModalOpen: !prevState.newPostModalOpen,
    }));
  };

  handleNewPostSuccess = (newPostData: any) => {
    this.setState(
      () => ({ newPostModalOpen: false }),
      () => {
        message.success(
          <SuccessMessage
            history={this.props.history}
            location={this.props.location}
          />,
          10,
        );
      },
    );
  };

  handleNewPostError = (newPostError: any) => {
    message.error(
      newPostError.localMessage ||
        'An error has occurred and the post was not created.',
    );
  };
}

const SuccessMessage = ({
  history,
  location,
}: {
  history: any,
  location: any,
}) => {
  function handleClick() {
    history.push(URL_DASHBOARD_POSTS_PAGE);
  }

  return (
    <React.Fragment>
      <span>A new post was created. </span>
      {location.pathname !== URL_DASHBOARD_POSTS_PAGE && (
        <Button
          buttonType="link"
          onClick={handleClick}
          size="small"
          className="dashboard-page__message-link">
          Go to post section
        </Button>
      )}
    </React.Fragment>
  );
};

const mapStateToProps = (state, ownProps) => {
  return {
    ...account.mapStateToProps(state),
    ...accountContenders.mapStateToProps(state),
    ...accountDetails.mapStateToProps(state),
    ...accountProfile.mapStateToProps(state),
    ...ownProps,
  };
};

const mapDispatchToProps = dispatch => {
  return {
    ...accountContenders.mapDispatchToProps(dispatch),
    ...accountDetails.mapDispatchToProps(dispatch),
    ...accountProfile.mapDispatchToProps(dispatch),
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(DashboardPage);
