//@flow
import * as React from 'react';
import { connect } from 'react-redux';
import collaboratorsDelete from '../../redux/modules/collaboratorsDelete/collaboratorsDelete.containers';
import type {
  collaboratorsDeleteMapStateToProps,
  collaboratorsDeleteMapDispatchToProps,
} from '../../redux/modules/collaboratorsDelete/collaboratorsDelete.containers';
import LoadingSpinner from '../LoadingSpinner/LoadingSpinner';
import Avatar from '../Avatar/Avatar';
import Button from '../Button/Button';
import './CollaboratorsList.scss';

type Props = collaboratorsDeleteMapStateToProps &
  collaboratorsDeleteMapDispatchToProps & {
    listIsLoading: boolean,
    collaboratorsListData: {
      collaborators: {
        id: number,
        email: string,
        status: 'pending' | 'active',
      }[],
    },
  };

class CollaboratorsList extends React.Component<Props> {
  constructor(props: Props) {
    super(props);
    (this: any).handleDeleteCollaborator = this.handleDeleteCollaborator.bind(
      this,
    );
  }

  render() {
    const { listIsLoading, collaboratorsListData } = this.props;

    if (listIsLoading) {
      return <LoadingSpinner visible={listIsLoading} />;
    } else if (!listIsLoading && collaboratorsListData) {
      return (
        <ul className="collaborators-list">
          {collaboratorsListData.collaborators.length ? (
            collaboratorsListData.collaborators.map((collaborator, index) => (
              <li key={index} className="collaborators-list__item">
                <div>
                  <Avatar
                    type="small"
                    source="/images/icon_generic_avatar.svg"
                    className="collaborators-list__avatar"
                  />
                  {collaborator.email} -{' '}
                  <span className="collaborators-list__status">
                    {collaborator.status}
                  </span>
                </div>
                <Button
                  buttonType="link"
                  noBorder={true}
                  onClick={this.handleDeleteCollaborator.bind(
                    this,
                    collaborator.id,
                  )}
                  loading={this.props.collaboratorsDeleteIsLoading}>
                  <img
                    src="/images/icon_delete.svg"
                    alt=""
                    className="img-fluid"
                  />
                </Button>
              </li>
            ))
          ) : (
            <li className="text-center">
              You don't have any collaborators yet.
            </li>
          )}
        </ul>
      );
    } else {
      return (
        <p className="collaborators-list collaborators-list--empty">
          You have no collaborators yet
        </p>
      );
    }
  }

  handleDeleteCollaborator(id: number) {
    this.props.collaboratorsDelete({ id });
  }
}

const mapStateToProps = (state, ownProps) => {
  return {
    ...collaboratorsDelete.mapStateToProps(state),
    ...ownProps,
  };
};

const mapDispatchToProps = dispatch => {
  return {
    ...collaboratorsDelete.mapDispatchToProps(dispatch),
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(CollaboratorsList);
