import {
  accountFollowersReset as reset,
  getAccountFollowers as getFollowers,
} from './accountFollowers.actions';

export const mapStateToProps = state => {
  return {
    accountFollowersData: state.accountFollowers.data,
    accountFollowersError: state.accountFollowers.error,
    accountFollowersIsLoading: state.accountFollowers.isLoading,
  };
};

export const mapDispatchToProps = dispatch => {
  return {
    getAccountFollowers() {
      dispatch(getFollowers());
    },
    accountFollowersReset() {
      dispatch(reset());
    },
  };
};

export default {
  mapStateToProps,
  mapDispatchToProps,
};
