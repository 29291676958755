import { getGroupPosts, groupPostsReset as reset } from './groupPosts.actions';

export const mapStateToProps = state => {
  return {
    groupPostsData: state.groupPosts.data,
    groupPostsError: state.groupPosts.error,
    groupPostsIsLoading: state.groupPosts.isLoading,
  };
};

export const mapDispatchToProps = dispatch => {
  return {
    getPostsForGroup(payload) {
      dispatch(getGroupPosts(payload));
    },
    groupPostsReset() {
      dispatch(reset());
    },
  };
};

export default {
  mapStateToProps,
  mapDispatchToProps,
};
