//@flow
import React, { Fragment } from 'react';
import _get from 'lodash/get';
import type {
  AccountMapDispatchToProps,
  AccountMapStateToProps,
} from '../../redux/modules/account/account.containers';
import type {
  AccountDetailsMapDispatchToProps,
  AccountDetailsMapStateToProps,
} from '../../redux/modules/accountDetails/accountDetails.containers';
import {
  URL_LOGIN_PAGE,
  URL_MY_ACCOUNT_MAIN_PAGE,
  URL_DASHBOARD_CONTRIBUTIONS_PAGE,
  URL_FEED_PAGE,
  URL_SEARCH_PAGE,
  URL_USER_PUBLIC_PROFILE,
} from '../../config/urls';
import Link from '../Link/Link';
import Button from '../Button/Button';
import './Header.scss';
import { hasDashboard } from '../../utilities/authorization';

type State = {
  expanded: boolean,
};

type Props = AccountMapStateToProps &
  AccountMapDispatchToProps &
  AccountDetailsMapDispatchToProps &
  AccountDetailsMapStateToProps & {
    location: any,
    logout: Function,
  };

class MenuMobile extends React.Component<Props, State> {
  constructor(props: Props) {
    super(props);

    this.state = { expanded: false };
  }

  render() {
    const { user } = this.props.accountData || {};
    return (
      <div>
        <div className="d-flex justify-content-around">
          <div className="toggle">
            <button
              onClick={this.handleExpand}
              className="menu-mobile__button"
              type="button">
              <img src="/images/hamburger.png" alt="" />
            </button>
          </div>
          <div className="logo">
            <Link
              href={
                !user
                  ? '/'
                  : hasDashboard(user)
                  ? URL_DASHBOARD_CONTRIBUTIONS_PAGE
                  : URL_FEED_PAGE
              }>
              <img src="/images/logo.png" alt="" />
            </Link>
          </div>
          {user ? (
            <div className="magnifier">
              <Link href={URL_SEARCH_PAGE} type="secondary">
                <span className="d-inline-block mr-2">Search</span>
                <img src="/images/mag-menu.png" alt="" />
              </Link>
            </div>
          ) : (
            <div style={{ width: '30px', height: '10px' }} />
          )}
        </div>
        {this.renderMenuContent()}
      </div>
    );
  }

  renderMenuContent() {
    if (!this.state.expanded) {
      return null;
    }

    const { location } = this.props;
    const { user } = this.props.accountData || {};

    return (
      <div className="menu-mobile">
        {this.renderProfile()}
        <ul>
          {user && (
            <Fragment>
              {hasDashboard(user) && (
                <li>
                  <Link
                    href={URL_DASHBOARD_CONTRIBUTIONS_PAGE}
                    type="secondary"
                    className={this.getLinkClassName(
                      location.pathname,
                      URL_DASHBOARD_CONTRIBUTIONS_PAGE,
                    )}>
                    Dashboard
                  </Link>
                </li>
              )}
              <li>
                <Link
                  href={URL_FEED_PAGE}
                  type="secondary"
                  className={this.getLinkClassName(
                    location.pathname,
                    URL_FEED_PAGE,
                  )}>
                  {hasDashboard(user) ? 'Activity' : 'Feed'}
                </Link>
              </li>
            </Fragment>
          )}
          <li>
            <Link
              href="/"
              type="secondary"
              className={this.getLinkClassName(location.pathname, '/')}>
              How it works
            </Link>
          </li>
          {user && (
            <Fragment>
              <li>
                <Link
                  href={URL_MY_ACCOUNT_MAIN_PAGE}
                  type="secondary"
                  className={this.getLinkClassName(
                    location.pathname,
                    URL_MY_ACCOUNT_MAIN_PAGE,
                  )}>
                  My Account
                </Link>
              </li>
              <li>
                <Link
                  href={URL_USER_PUBLIC_PROFILE.replace(':id', user.id)}
                  type="secondary"
                  className={this.getLinkClassName(
                    location.pathname,
                    URL_USER_PUBLIC_PROFILE,
                  )}>
                  My Profile
                </Link>
              </li>
              <li className="signout">
                <Button
                  loading={this.props.accountIsLoading}
                  buttonType="link"
                  noBorder={true}
                  onClick={this.props.logout}
                  type="submit">
                  Logout
                </Button>
              </li>
            </Fragment>
          )}
          {!user && (
            <li>
              <Link
                href={URL_LOGIN_PAGE}
                className={this.getLinkClassName(
                  location.pathname,
                  URL_LOGIN_PAGE,
                )}>
                Login
              </Link>
            </li>
          )}
        </ul>
      </div>
    );
  }

  renderProfile() {
    const accountData = this.props.accountData;

    return (
      accountData && (
        <div className="row profile">
          <div className="col-3 pt-3">
            <div className="menu-mobile__image-container">
              <img
                src={accountData.profilePicture || '/images/icon_citizen.png'}
                alt=""
                width="55"
                height="55"
              />
            </div>
          </div>
          <div className="col-9">
            <h1 className="pt-4 pb-1 mb-0">{`${accountData.user.first_name} ${
              accountData.user.last_name
            }`}</h1>
            <Link href={URL_MY_ACCOUNT_MAIN_PAGE}>EDIT PROFILE</Link>
            <p className="details pt-2 mb-0">
              {_get(this.props, 'accountDetailsData.user.followers_count')}{' '}
              followers |{' '}
              {_get(this.props, 'accountDetailsData.user.likes_count')} Likes
            </p>
          </div>
        </div>
      )
    );
  }

  getLinkClassName(currentUrl: string, linkUrl: string) {
    return currentUrl === linkUrl ? 'header__link--active' : '';
  }

  handleExpand = () => {
    this.setState(() => ({ expanded: !this.state.expanded }));
  };
}

export default MenuMobile;
