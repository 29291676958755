// @flow
import makeDefaultServerCallReducer from '../../utilities/makeDefaultServerCallReducer';
import {
  GROUP_INVITE_BY_CONTACT_ERROR,
  GROUP_INVITE_BY_CONTACT_LOADING,
  GROUP_INVITE_BY_CONTACT_RESET,
  GROUP_INVITE_BY_CONTACT_SUCCESS,
} from './groupInviteByContact.constatnts';

const initialState = {
  isLoading: false,
  error: null,
  data: null,
};

export const reducer = makeDefaultServerCallReducer({
  loadingActions: [GROUP_INVITE_BY_CONTACT_LOADING],
  successActions: [GROUP_INVITE_BY_CONTACT_SUCCESS],
  errorActions: [GROUP_INVITE_BY_CONTACT_ERROR],
  resetActions: [GROUP_INVITE_BY_CONTACT_RESET],
  initialState,
});
