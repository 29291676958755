// @flow
import { createAction } from 'redux-actions';
import {
  POST_REMOVE_ERROR,
  POST_REMOVE_LOADING,
  POST_REMOVE_SUCCESS,
  POST_REMOVE_RESET,
} from './postRemove.constants';
import { instance as rorPostsApiProvider } from '../../../services/RoRPostsApiProvider';
import type { GetPostDetailsPayload } from '../../../services/RoRPostsApiProvider';

export const postRemoveError = createAction(POST_REMOVE_ERROR);

export const postRemoveLoading = createAction(POST_REMOVE_LOADING);

export const postRemoveSuccess = createAction(POST_REMOVE_SUCCESS);

export const postRemoveReset = createAction(POST_REMOVE_RESET);

export const postRemove = (payload: GetPostDetailsPayload) => {
  return async (dispatch: Function) => {
    try {
      dispatch(postRemoveLoading());

      const response = await rorPostsApiProvider.deletePost(payload);

      dispatch(postRemoveSuccess(response));
    } catch (error) {
      dispatch(postRemoveError(error));
    }
  };
};
