// @flow
import {
  groupSendInvitation as sendInvitation,
  groupSendInvitationReset as reset,
} from './groupSendInvitation.actions';
import type {
  PostInvitePeoplePayload,
  PostInvitePeopleResponse,
} from '../../../services/RoRGroupsApiProvider';

export const mapStateToProps = (state: any) => {
  return {
    groupSendInvitationData: state.groupSendInvitation.data,
    groupSendInvitationError: state.groupSendInvitation.error,
    groupSendInvitationIsLoading: state.groupSendInvitation.isLoading,
  };
};

export const mapDispatchToProps = (dispatch: Function) => {
  return {
    groupSendInvitation(payload: PostInvitePeoplePayload) {
      dispatch(sendInvitation(payload));
    },
    groupSendInvitationReset() {
      dispatch(reset());
    },
  };
};

export type GroupSendInvitationMapStateToProps = {
  groupSendInvitationData: PostInvitePeopleResponse,
  groupSendInvitationError: any,
  groupSendInvitationIsLoading: boolean,
};

export type GroupSendInvitationMapDispatchToProps = {
  groupSendInvitation: (payload: PostInvitePeoplePayload) => void,
  groupSendInvitationReset: () => void,
};

export default {
  mapStateToProps,
  mapDispatchToProps,
};
