import React, { Component } from 'react';
import type {
  UserDetailsMapDispatchToProps,
  UserDetailsMapStateToProps,
} from '../../redux/modules/userDetails/userDetails.containers';
import userDetails from '../../redux/modules/userDetails/userDetails.containers';
import type { withMobileContributionsContextProps } from '../../context/mobileContributionsContext';
import { withMobileContributionsContext } from '../../context/mobileContributionsContext';
import { connect } from 'react-redux';
import LoadingSpinner from '../../components/LoadingSpinner/LoadingSpinner';
import ContributePayment from '../../components/ContributePayment/ContributePayment';
import ContributeNewACH from '../../components/ContributeNewACH/ContributeNewACH';
import {
  URL_MOBILE_CONTRIBUTIONS_NEW_ACH_SUBMITTED,
  URL_MOBILE_CONTRIBUTIONS_OPTIONS,
  URL_MOBILE_CONTRIBUTIONS_PAYMENT_COMPLETED,
} from '../../config/urls';

import type { ReactNode } from 'react';
import type { UserDetails } from '../../services/RoRUsersApiProvider';
import { withRouter } from 'react-router-dom';

type Props = UserDetailsMapStateToProps &
  UserDetailsMapDispatchToProps &
  withMobileContributionsContextProps & {
    children: ({
      mobileToken: string,
      mobileEmail: string,
      mobileGroupId: string,
      userDetails: UserDetails,
      onPaymentRequest: Function,
      onPaymentSuccess: Function,
      onAddNewBankAccountRequest: Function,
      onGoBackRequest: Function,
    }) => ReactNode,
    location: any,
    history: any,
  };

type State = {
  paymentPayload: { [key: string]: any } | null,
  isAddingNewAch: boolean,
};

class MobileContributionsTypePage extends Component<Props, State> {
  constructor(props: Props) {
    super(props);

    this.state = {
      paymentPayload: null,
      isAddingNewAch: false,
    };
  }

  componentWillMount() {
    this.props.userDetailsReset();
  }

  componentDidMount() {
    const { mobileToken, mobileEmail, mobileUserId } = this.props;

    this.props.getDetailsForUser({
      userId: mobileUserId,
      customToken: mobileToken,
      customEmail: mobileEmail,
    });
  }

  render() {
    return (
      <div className="mobile-contributions-direct-page">
        <div className="row no-gutters">
          <div className="col-12">
            {this.props.userDetailsIsLoading
              ? this.renderLoading()
              : this.renderContent()}
            {this.props.userDetailsError ? (
              <div className="mobile-contributions-page__error mobile-contributions-page__center">
                {this.props.userDetailsError.localMessage ||
                  "An error has occurred and we can't process your request."}
              </div>
            ) : null}
          </div>
        </div>
      </div>
    );
  }

  renderLoading() {
    return (
      <div className="mobile-contributions-page__center">
        <LoadingSpinner type="dark" visible={true} />
      </div>
    );
  }

  renderContent() {
    const { mobileToken, mobileEmail, mobileGroupId, children } = this.props;

    if (!this.props.userDetailsData) {
      return null;
    }

    if (this.state.paymentPayload) {
      return (
        <ContributePayment
          onCloseRequest={this.handleResetPayment}
          contributePayload={{
            ...this.state.paymentPayload,
            groupId: mobileGroupId,
          }}
          onSuccess={this.handleContributionSuccess}
          customPaymentToken={mobileToken}
          customPaymentEmail={mobileEmail}
        />
      );
    }

    if (this.state.isAddingNewAch) {
      return (
        <ContributeNewACH
          onSuccess={this.handleNewAchSubmissionSuccess}
          onCancel={this.handleCancelNewAchRequest}
          customPaymentToken={mobileToken}
          customPaymentEmail={mobileEmail}
        />
      );
    }

    return children({
      mobileToken,
      mobileEmail,
      mobileGroupId,
      userDetails: this.props.userDetailsData.user,
      onPaymentRequest: this.handlePaymentRequest,
      onPaymentSuccess: this.handleContributionSuccess,
      onAddNewBankAccountRequest: this.handleAddNewAchRequest,
      onGoBackRequest: this.handleGoBackRequest,
    });
  }

  handleAddNewAchRequest = () => {
    this.setState(() => ({ isAddingNewAch: true }));
  };

  handleCancelNewAchRequest = () => {
    this.setState(() => ({ isAddingNewAch: false }));
  };

  handleNewAchSubmissionSuccess = message => {
    this.props.history.replace(
      `${URL_MOBILE_CONTRIBUTIONS_NEW_ACH_SUBMITTED.replace(
        ':userId',
        this.props.mobileUserId,
      )}`,
      { message },
    );
  };

  handlePaymentRequest = (payload: any) => {
    this.setState(() => ({ paymentPayload: payload }));
  };

  handleResetPayment = () => {
    this.setState(() => ({ paymentPayload: null }));
  };

  handleGoBackRequest = () => {
    const qs = this.props.location.search;
    this.props.history.push(
      `${URL_MOBILE_CONTRIBUTIONS_OPTIONS.replace(
        ':userId',
        this.props.mobileUserId,
      )}${qs}`,
    );
  };

  handleContributionSuccess = ({ message }) => {
    const qs = this.props.location.search;
    this.props.history.replace(
      `${URL_MOBILE_CONTRIBUTIONS_PAYMENT_COMPLETED.replace(
        ':userId',
        this.props.mobileUserId,
      )}${qs}`,
      { message },
    );
  };
}

const mapStateToProps = (state, ownProps) => {
  return {
    ...userDetails.mapStateToProps(state),
    ...ownProps,
  };
};

const mapDispatchToProps = dispatch => {
  return {
    ...userDetails.mapDispatchToProps(dispatch),
  };
};

export default withMobileContributionsContext(
  withRouter(
    connect(
      mapStateToProps,
      mapDispatchToProps,
    )(MobileContributionsTypePage),
  ),
);
