// @flow
import makeDefaultServerCallReducer from '../../utilities/makeDefaultServerCallReducer';
import {
  SEARCH_ERROR,
  SEARCH_LOADING,
  SEARCH_SUCCESS,
  SEARCH_RESET,
} from './search.constants';

const initialState = {
  isLoading: false,
  error: null,
  data: null,
};

export const reducer = makeDefaultServerCallReducer({
  loadingActions: [SEARCH_LOADING],
  successActions: [SEARCH_SUCCESS],
  errorActions: [SEARCH_ERROR],
  resetActions: [SEARCH_RESET],
  initialState,
});
