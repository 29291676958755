// @flow
import makeDefaultServerCallReducer from '../../utilities/makeDefaultServerCallReducer';
import {
  USER_RSS_FEED_ERROR,
  USER_RSS_FEED_LOADING,
  USER_RSS_FEED_SUCCESS,
  USER_RSS_FEED_RESET,
} from './userRssFeed.constants';

const initialState = {
  isLoading: false,
  error: null,
  data: null,
};

export const reducer = makeDefaultServerCallReducer({
  loadingActions: [USER_RSS_FEED_LOADING],
  successActions: [USER_RSS_FEED_SUCCESS],
  errorActions: [USER_RSS_FEED_ERROR],
  resetActions: [USER_RSS_FEED_RESET],
  initialState,
});
