import { getUserList, userListReset as reset } from './userList.actions';
import type {
  GetUserListPayload,
  GetUserListResponse,
} from '../../../services/RoRUsersApiProvider';

export const mapStateToProps = state => {
  return {
    userListData: state.userList.data,
    userListError: state.userList.error,
    userListIsLoading: state.userList.isLoading,
  };
};

export const mapDispatchToProps = dispatch => {
  return {
    getUserList(payload) {
      dispatch(getUserList(payload));
    },
    userListReset() {
      dispatch(reset());
    },
  };
};

export type UserListMapStateToProps = {
  userListData: GetUserListResponse,
  userListError: any,
  userListIsLoading: boolean,
};

export type UserListMapDispatchToProps = {
  getUserList: (payload: GetUserListPayload) => void,
  userListReset: () => void,
};

export default {
  mapStateToProps,
  mapDispatchToProps,
};
