// @flow
import React from 'react';
import classNames from 'classnames';
import Avatar from '../Avatar/Avatar';
import { URL_USER_PUBLIC_PROFILE } from '../../config/urls';
import Button from '../Button/Button';
import Link from '../Link/Link';
import Currency from '../Currency/Currency';
import './CardContender.scss';

type Props = {
  id: number,
  first_name: string,
  last_name: string,
  donations: {
    contributed: number,
    committed: number,
    pledged: number,
  },
  profile_image: string,
  rank: number,
  className?: string,
  onContributeClick?: Function,
  contributeButton?: boolean,
};

const CardContender = (props: Props) => {
  const {
    className,
    profile_image,
    rank,
    first_name,
    last_name,
    donations,
    id,
  } = props;
  const userProfileUrl = URL_USER_PUBLIC_PROFILE.replace(':id', id);

  return (
    <div className={getClassName(className)}>
      <div className="row pb-3">
        <div className="col-4 px-md-2 px-2 px-sm-4">
          <Link href={userProfileUrl}>
            <Avatar type="medium" source={profile_image} isCandidate={true} />
          </Link>
        </div>
        <div className="col-8 pl-2">
          <div className="row">
            <div className="col-8">
              <h3 className="pt-3 pt-md-0 pb-1 mb-0">{rank}</h3>
            </div>
            {props.contributeButton ? (
              <div className="col-4 d-flex justify-content-end">
                <Button
                  buttonType="primary"
                  type="button"
                  size="small"
                  className="card-contender__button"
                  onClick={() => handleButtonClick(props)}>
                  <span className="plus">+ </span>
                  $1<sup> 00</sup>
                </Button>
              </div>
            ) : null}
          </div>
          <h4>
            <Link href={userProfileUrl}>{`${first_name} ${last_name}`}</Link>
          </h4>
        </div>
      </div>
      <div className="row">
        <div className="col-4 text-center px-0">
          <p className="mb-0">Contributed</p>
          <Currency
            amount={donations.contributed / 100}
            format="$0.0a a"
            showDecimals={false}
            className="card-contender__amount"
          />
        </div>
        <div className="col-4 text-center px-0">
          <p className="mb-0">Committed</p>
          <Currency
            amount={donations.committed / 100}
            format="$0.0a a"
            showDecimals={false}
            className="card-contender__amount"
          />
        </div>
        <div className="col-4 text-center px-0">
          <p className="mb-0">Pledged</p>
          <Currency
            amount={donations.pledged / 100}
            format="$0.0a a"
            showDecimals={false}
            className="card-contender__amount"
          />
        </div>
      </div>
    </div>
  );
};

CardContender.defaultProps = {
  contributeButton: true,
};

function handleButtonClick(props: Props) {
  if (props.onContributeClick) {
    props.onContributeClick({ userId: props.id });
  }
}

function getClassName(className) {
  return classNames({
    'card-contender': true,
    [className || '']: className,
  });
}

export default CardContender;
