// @flow
import { createAction } from 'redux-actions';
import {
  RACE_GROUPS_ERROR,
  RACE_GROUPS_LOADING,
  RACE_GROUPS_RESET,
  RACE_GROUPS_SUCCESS,
} from './raceGroups.constants';
import { instance as rorRacesApiProvider } from '../../../services/RoRRacesApiProvider';
import type { GetRaceGroupsPayload } from '../../../services/RoRRacesApiProvider';

export const raceGroupsError = createAction(RACE_GROUPS_ERROR);

export const raceGroupsLoading = createAction(RACE_GROUPS_LOADING);

export const raceGroupsSuccess = createAction(RACE_GROUPS_SUCCESS);

export const raceGroupsReset = createAction(RACE_GROUPS_RESET);

export const getRaceGroups = (payload: GetRaceGroupsPayload) => {
  return async (dispatch: Function) => {
    try {
      dispatch(raceGroupsLoading());

      const response = await rorRacesApiProvider.raceGroups(payload);

      dispatch(raceGroupsSuccess(response));
    } catch (error) {
      dispatch(raceGroupsError(error));
    }
  };
};
