// @flow
import * as React from 'react';
import Loading from '../Loading/Loading';
import ModalVerifyAch from '../ModalVerifyAch/ModalVerifyAch';
import Button from '../Button/Button';

import './AchBankList.scss';

type Props = {
  loading: boolean,
  sources: {
    stripe_source_id: string,
    source_type: string,
    bank_name: string,
    account_number: string,
    status: string,
  }[],
};

type State = {
  modalVerifyAchOpen: boolean,
  selectedAccount: {
    achAccountNumber: string,
    stripeSourceId: string,
  } | null,
};

class AchBankList extends React.Component<Props, State> {
  constructor(props: Props) {
    super(props);
    this.state = {
      modalVerifyAchOpen: false,
      selectedAccount: null,
    };
  }

  render() {
    return (
      <React.Fragment>
        <Loading loading={this.props.loading} type="spinner">
          <ul className="ach-bank-list">{this.renderBankAccounts()}</ul>
        </Loading>
        <ModalVerifyAch
          isOpen={this.state.modalVerifyAchOpen}
          achAccountNumber={
            this.state.selectedAccount
              ? this.state.selectedAccount.achAccountNumber
              : ''
          }
          stripeSourceId={
            this.state.selectedAccount
              ? this.state.selectedAccount.stripeSourceId
              : ''
          }
          onCloseRequest={this.toggleVerifyAchModalVisibility}
          onSuccess={this.toggleVerifyAchModalVisibility}
        />
      </React.Fragment>
    );
  }

  renderBankAccounts() {
    if (this.props.sources.length) {
      return this.props.sources.map((source, key) => {
        return (
          <li key={key} className="ach-bank-list__item">
            <div className="ach-bank-list__item-line">
              {source.bank_name} {source.status === 'new' ? '(pending)' : null}
            </div>
            <div className="ach-bank-list__item-line">
              {source.account_number}
              {source.status === 'new' ? (
                <Button {...this.getVerifyButtonProps(source)}>Verify</Button>
              ) : null}
            </div>
          </li>
        );
      });
    } else {
      return (
        <li className="ach-bank-list__item ach-bank-list__empty-message">
          You have no bank accounts added yet
        </li>
      );
    }
  }

  getVerifyButtonProps(source: any) {
    return {
      buttonType: 'link',
      className: 'ach-bank-list__item-button',
      onClick: this.toggleVerifyAchModalVisibility.bind(this, source),
      size: 'micro',
      type: 'button',
    };
  }

  toggleVerifyAchModalVisibility = (source: any) => {
    this.setState((prevState: State) =>
      prevState.modalVerifyAchOpen
        ? {
            modalVerifyAchOpen: false,
            selectedAccount: null,
          }
        : {
            modalVerifyAchOpen: true,
            selectedAccount: {
              achAccountNumber: source.account_number,
              stripeSourceId: source.stripe_source_id,
            },
          },
    );
  };
}

export default AchBankList;
