import {
  collaboratorsList,
  collaboratorsListReset as listReset,
} from './collaborators.actions';

export const mapStateToProps = state => {
  return {
    collaboratorsListData: state.collaborators.data,
    collaboratorsListError: state.collaborators.error,
    collaboratorsListIsLoading: state.collaborators.isLoading,
  };
};

export const mapDispatchToProps = dispatch => {
  return {
    collaboratorsList(payload) {
      dispatch(collaboratorsList(payload));
    },
    collaboratorsListReset() {
      dispatch(listReset());
    },
  };
};

export type collaboratorsMapStateToProps = {
  collaboratorsData: any,
  collaboratorsError: any,
  collaboratorsIsLoading: boolean,
};

export type collaboratorsMapDispatchToProps = {
  collaboratorsList: Function,
  collaboratorsListReset: Function,
};

export default {
  mapStateToProps,
  mapDispatchToProps,
};
