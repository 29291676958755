// @flow
import React from 'react';
import classNames from 'classnames';
import Modal from '../Modal/Modal';
import type { UserProfile } from '../../services/RoRUsersApiProvider';
import ContributePayment from '../ContributePayment/ContributePayment';

type Props = {
  contributePayload?: {
    typeOfContribution: 'direct' | 'committed' | 'pledge',
    formValues: {
      public: string,
      total: number,
      paymentMethod: 'newCard',
    },
    userDetails: UserProfile,
  },
  isOpen: boolean,
  onAfterOpen?: Function,
  onCloseRequest?: Function,
  onNewStripeSource?: (data: any) => void,
  className?: string,
  defaultAmount?: number,
};

class ModalContributePayment extends React.Component<Props, {}> {
  render() {
    const {
      isOpen,
      onCloseRequest,
      contributePayload,
      defaultAmount,
      onNewStripeSource,
      className,
      ...rest
    } = this.props;

    // TODO: setup stripe keys for prod and test based on the node env
    return (
      <Modal
        contentLabel="Load funds"
        size="big"
        isOpen={isOpen}
        onCloseRequest={onCloseRequest}
        className={this.getClassName()}
        noPaddingContent
        {...rest}>
        {isOpen ? (
          <ContributePayment
            onCloseRequest={onCloseRequest}
            contributePayload={contributePayload}
            defaultAmount={defaultAmount}
            onNewStripeSource={onNewStripeSource}
          />
        ) : null}
      </Modal>
    );
  }

  getClassName() {
    return classNames({
      'modal-contribute-payment': true,
      [this.props.className || '']: this.props.className,
    });
  }
}

export default ModalContributePayment;
