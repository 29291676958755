import {
  ACCOUNT_SSA_FORM_DATA_SUCCESS,
  ACCOUNT_SSA_FORM_DATA_RESET,
  ACCOUNT_SSA_FORM_DATA_LOADING,
  ACCOUNT_SSA_FORM_DATA_ERROR,
} from './accountSsaFormData.constants';
import makeDefaultServerCallReducer from '../../utilities/makeDefaultServerCallReducer';

const initialState = {
  isLoading: false,
  error: null,
  data: null,
};

export const reducer = makeDefaultServerCallReducer({
  loadingActions: [ACCOUNT_SSA_FORM_DATA_LOADING],
  successActions: [ACCOUNT_SSA_FORM_DATA_SUCCESS],
  errorActions: [ACCOUNT_SSA_FORM_DATA_ERROR],
  resetActions: [ACCOUNT_SSA_FORM_DATA_RESET],
  initialState,
});
