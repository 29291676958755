// @flow
import React from 'react';
import classNames from 'classnames';
import FeedItemComponent from '../../components/FeedItem/FeedItem';
import { Scrollbars } from 'react-custom-scrollbars';
import type { FeedItem } from '../../services/RoRUsersApiProvider';
import withDisplayDimensions from '../../hoc/withDisplayDimensions';
import type { WithDisplayDimensionsOutputProps } from '../../hoc/withDisplayDimensions';

type Props = WithDisplayDimensionsOutputProps & {
  feedItems: FeedItem[],
  onScrollEndReached: () => void,
  onFeedItemButtonClick: (feedItem: FeedItem, index: number) => void,
  activeFeedItemIndex: boolean,
  className?: string,
  userId?: boolean,
};

type State = {
  scrollbarReady: boolean,
};

class Feed extends React.Component<Props, State> {
  scrollbarRef: any = null;
  pageFooterNode: any = null;
  feedContainerRef: any = null;

  constructor(props: Props) {
    super(props);

    this.state = {
      scrollbarReady: false,
    };
  }

  componentDidMount() {
    if (typeof window !== 'undefined') {
      this.pageFooterNode = window.document.querySelector('footer.footer');
      window.addEventListener('scroll', this.handleWindowScroll);
    }
  }

  componentDidUpdate(prevProps: Props, prevState: State) {
    // TODO: enable if we want to disable the scrollbar until the whole page is scrolled to the end
    // if (this.state.scrollbarReady && !prevState.scrollbarReady && !this.props.isMobile) {
    //   this.disableFeedScrollbar();
    // }
  }

  componentWillUnmount() {
    if (typeof window !== 'undefined') {
      window.removeEventListener('scroll', this.handleWindowScroll);
    }
  }

  // render() {
  //   return this.props.isMobile ? this.renderContent() : (
  //     <Scrollbars
  //       universal
  //       style={{ height: '100%' }}
  //       ref={this.setScrollbarRef}
  //       onScrollFrame={this.handleFeedListScroll}
  //     >
  //       {this.renderContent()}
  //     </Scrollbars>
  //   );
  // }

  render() {
    return (
      <div
        className={classNames({
          feed: true,
          [this.props.className || '']: this.props.className,
        })}
        ref={ref => (this.feedContainerRef = ref)}>
        {this.props.feedItems.map((feedItem: FeedItem, index: number) => (
          <FeedItemComponent
            key={index}
            feedItem={feedItem}
            userId={this.props.userId}
            onButtonClick={feedItem =>
              this.props.onFeedItemButtonClick(feedItem, index)
            }
            buttonProps={{
              loading: index === this.props.activeFeedItemIndex,
            }}
          />
        ))}
      </div>
    );
  }

  handleWindowScroll = () => {
    // TODO: enable if we want to disable the scrollbar until the whole page is scrolled to the end
    // if (window.scrollY >= window.document.body.clientHeight) {
    //   this.enableFeedScroll();
    // } else if (window.scrollY < window.document.body.clientHeight) {
    //   this.disableFeedScrollbar()
    // }

    const footerHeight = this.pageFooterNode
      ? this.pageFooterNode.clientHeight
      : 0;
    //$FlowFixMe
    if (
      window.innerHeight + window.scrollY >=
      document.body.scrollHeight - footerHeight
    ) {
      this.props.onScrollEndReached();
    }
  };

  // disableFeedScrollbar() {
  //   this.feedContainerRef.parentNode.style.overflow = 'hidden';
  //   this.feedContainerRef.parentNode.style.paddingRight = '15px';
  // }

  // enableFeedScroll() {
  //   this.feedContainerRef.parentNode.style.overflow = 'scroll';
  //   this.feedContainerRef.parentNode.style.paddingRight = '0';
  // }

  // setScrollbarRef = (ref: any) => {
  //   if (ref) {
  //     this.setState(() => ({ scrollbarReady: true }), () => {
  //       this.scrollbarRef = ref;
  //     });
  //   }
  // };

  // handleFeedListScroll = (values: any) => {
  //   const { top } = values;
  //
  //   if (top === 1) {
  //     this.props.onScrollEndReached();
  //   }
  // };
}

export default withDisplayDimensions(Feed);
