// @flow
import makeDefaultServerCallReducer from '../../utilities/makeDefaultServerCallReducer';
import {
  RACE_FOLLOW_ERROR,
  RACE_FOLLOW_LOADING,
  RACE_FOLLOW_SUCCESS,
  RACE_FOLLOW_RESET,
} from './raceFollow.constants';

const initialState = {
  isLoading: false,
  error: null,
  data: null,
};

export const reducer = makeDefaultServerCallReducer({
  loadingActions: [RACE_FOLLOW_LOADING],
  successActions: [RACE_FOLLOW_SUCCESS],
  errorActions: [RACE_FOLLOW_ERROR],
  resetActions: [RACE_FOLLOW_RESET],
  initialState,
});
