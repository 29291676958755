// @flow
import React from 'react';
import Link from '../Link/Link';
import './Badge.scss';

type Props = {
  href: string,
  name: string,
  title?: string,
};

const Badge = (props: Props) => {
  return (
    <Link href={props.href} title={props.title || ''}>
      <span className="badge badge-pill badge-grey mr-3">{props.name}</span>
    </Link>
  );
};

export default Badge;
