import {
  ACCOUNT_PROFILE_SUCCESS,
  ACCOUNT_PROFILE_RESET,
  ACCOUNT_PROFILE_LOADING,
  ACCOUNT_PROFILE_ERROR,
} from './accountProfile.constants';
import makeDefaultServerCallReducer from '../../utilities/makeDefaultServerCallReducer';

const initialState = {
  isLoading: false,
  error: null,
  data: null,
};

export const reducer = makeDefaultServerCallReducer({
  loadingActions: [ACCOUNT_PROFILE_LOADING],
  successActions: [ACCOUNT_PROFILE_SUCCESS],
  errorActions: [ACCOUNT_PROFILE_ERROR],
  resetActions: [ACCOUNT_PROFILE_RESET],
  initialState,
});
