// @flow
import React from 'react';
import moment from 'moment';
import { connect } from 'react-redux';
import classNames from 'classnames';
import FollowButton from '../FollowButton/FollowButton';
import raceDetails from '../../redux/modules/raceDetails/raceDetails.containers';
import raceFollow from '../../redux/modules/raceFollow/raceFollow.containers';
import raceUnfollow from '../../redux/modules/raceUnfollow/raceUnfollow.containers';
import type {
  RaceUnfollowMapDispatchToProps,
  RaceUnfollowMapStateToProps,
} from '../../redux/modules/raceUnfollow/raceUnfollow.containers';
import type {
  RaceFollowMapDispatchToProps,
  RaceFollowMapStateToProps,
} from '../../redux/modules/raceFollow/raceFollow.containers';
import type { RaceDetailsMapStateToProps } from '../../redux/modules/raceDetails/raceDetails.containers';
import './RaceHeader.scss';

type Props = RaceUnfollowMapDispatchToProps &
  RaceUnfollowMapStateToProps &
  RaceFollowMapDispatchToProps &
  RaceFollowMapStateToProps &
  RaceDetailsMapStateToProps &
  RaceDetailsMapStateToProps & {
    className?: string,
  };

class RaceHeader extends React.Component<Props, any> {
  render() {
    const race = this.props.raceDetailsData && this.props.raceDetailsData.race;

    return race ? (
      <div className={this.getClassName()}>
        <div className="col-12 col-md-9">
          <div className="row pt-3 pt-md-4 pt-lg-4">
            <div className="col-12 col-lg-9 pl-0 pl-md-3 pl-lg-2 pl-xl-0 pt-2">
              <h1 className="race-header__race-name pr-sm-2">
                {race.name} {moment(race.due_date).format('YYYY')}
              </h1>
              <span className="d-none d-sm-block pb-3">
                Election due {moment(race.due_date).format('MMM Do YYYY')}.
              </span>
            </div>
            <div className="col-12 col-lg-3 pt-3 pt-md-4 pt-lg-3 px-0">
              <FollowButton
                isFollowing={
                  this.props.raceDetailsData.race.current_user.is_following
                }
                onClick={this.handleToggleFollowRace}
                loading={
                  this.props.raceFollowIsLoading ||
                  this.props.raceUnfollowIsLoading
                }
              />
            </div>
          </div>
        </div>
        <div className="col-12 col-md-3 pt-1 pt-md-3 mt-4 pr-0">
          <div className="row">
            <div className="race-header__counter col text-left text-md-right pt-0 pl-0 pl-md-3">
              <span>
                <strong>{race.candidates_count}</strong> Candidates
              </span>
              <span>
                <strong>{race.followers_count}</strong> Followers
              </span>
            </div>
          </div>
        </div>
      </div>
    ) : null;
  }

  getClassName() {
    return classNames({
      'race-header row fix-width': true,
      [this.props.className || '']: this.props.className,
    });
  }

  handleToggleFollowRace = () => {
    const race = this.props.raceDetailsData.race;

    if (race.current_user.is_following) {
      this.props.unfollowRace({ raceId: race.id });
    } else {
      this.props.followRace({ raceId: race.id });
    }
  };
}

const mapStateToProps = (state, ownProps) => {
  return {
    ...raceDetails.mapStateToProps(state),
    ...raceFollow.mapStateToProps(state),
    ...raceUnfollow.mapStateToProps(state),
    ...ownProps,
  };
};

const mapDispatchToProps = dispatch => {
  return {
    ...raceFollow.mapDispatchToProps(dispatch),
    ...raceUnfollow.mapDispatchToProps(dispatch),
  };
};
export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(RaceHeader);
