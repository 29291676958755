// @flow
import makeDefaultServerCallReducer from '../../utilities/makeDefaultServerCallReducer';
import {
  LIST_GROUPS_ERROR,
  LIST_GROUPS_LOADING,
  LIST_GROUPS_SUCCESS,
  LIST_GROUPS_RESET,
} from './listGroups.constants';

const initialState = {
  isLoading: false,
  error: null,
  data: null,
};

export const reducer = makeDefaultServerCallReducer({
  loadingActions: [LIST_GROUPS_LOADING],
  successActions: [LIST_GROUPS_SUCCESS],
  errorActions: [LIST_GROUPS_ERROR],
  resetActions: [LIST_GROUPS_RESET],
  initialState,
});
