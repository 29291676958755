// @flow
import * as React from 'react';
import classNames from 'classnames';
import LoadingSpinner from '../LoadingSpinner/LoadingSpinner';

import './Button.scss';

export type Props = {
  buttonType?:
    | 'light'
    | 'outline'
    | 'primary'
    | 'link'
    | 'outline-inverted'
    | 'primary-inverted',
  className?: string,
  noBorder?: boolean,
  block?: boolean,
  onClick?: Function,
  loading?: boolean,
  disabled?: boolean,
  children: any,
  size?: 'normal' | 'small' | 'micro',
  spinnerProps?: { [key: string]: any },
  noTextTransform?: boolean,
};

const Button = (props: Props) => {
  const {
    loading,
    disabled,
    spinnerProps,
    children,
    block,
    className,
    buttonType,
    noBorder,
    noTextTransform,
    size,
    ...rest
  } = props;

  return (
    <button
      className={getClassName(props)}
      disabled={disabled || loading || false}
      {...rest}
      onClick={() => handleClick(props)}>
      <div className={getButtonContentClassNames(props)}>{children}</div>
      <div className="button-loading-spinner">
        <LoadingSpinner visible={loading || false} {...spinnerProps} />
      </div>
    </button>
  );
};

Button.defaultProps = {
  size: 'normal',
  spinnerProps: {},
  noTextTransform: false,
};

function handleClick(props: Props) {
  if (props.onClick) {
    props.onClick(this);
  }
}

function getClassName(props) {
  return classNames({
    button: true,
    btn: true,
    'btn-block': props.block,
    [`button--size-${props.size || ''}`]: true,
    [`button--${props.buttonType || ''}`]: true,
    'button--clear': props.buttonType === 'link',
    'button--no-border': props.noBorder,
    'button--no-text-transform': props.noTextTransform,
    [props.className || '']: props.className,
  });
}

function getButtonContentClassNames(props) {
  return classNames({
    'button-content': true,
    'button-content--transparent': props.loading,
  });
}

export default Button;
