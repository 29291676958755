import {
  listInterests,
  listInterestsReset as reset,
  listInterestsGetNextPage,
} from './listInterests.actions';

export const mapStateToProps = state => {
  return {
    listInterestsData: state.listInterests.data,
    listInterestsError: state.listInterests.error,
    listInterestsIsLoading: state.listInterests.isLoading,
  };
};

export const mapDispatchToProps = dispatch => {
  return {
    getListInterests() {
      dispatch(listInterests());
    },
    getListInterestsNextPage() {
      dispatch(listInterestsGetNextPage());
    },
    listInterestsReset() {
      dispatch(reset());
    },
  };
};

export default {
  mapStateToProps,
  mapDispatchToProps,
};
