import {
  getGroupDetails,
  groupDetailsReset as reset,
} from './groupDetails.actions';
import type { GroupDetails } from '../../../services/RoRGroupsApiProvider';

export const mapStateToProps = state => {
  return {
    groupDetailsData: state.groupDetails.data,
    groupDetailsError: state.groupDetails.error,
    groupDetailsIsLoading: state.groupDetails.isLoading,
  };
};

export const mapDispatchToProps = dispatch => {
  return {
    getDetailsForGroup(payload) {
      dispatch(getGroupDetails(payload));
    },
    groupDetailsReset() {
      dispatch(reset());
    },
  };
};

export type GroupDetailsMapStateToProps = {
  groupDetailsData: { group: GroupDetails },
  groupDetailsError: any,
  groupDetailsIsLoading: boolean,
};

export type GroupDetailsMapDispatchToProps = {
  getDetailsForGroup: Function,
  groupDetailsReset: Function,
};

export default {
  mapStateToProps,
  mapDispatchToProps,
};
