export const ACCOUNT_POSTS_LOADING = '@@prytany/global/ACCOUNT_POSTS_LOADING';
export const ACCOUNT_POSTS_SUCCESS = '@@prytany/global/ACCOUNT_POSTS_SUCCESS';
export const ACCOUNT_POSTS_ERROR = '@@prytany/global/ACCOUNT_POSTS_ERROR';
export const ACCOUNT_POSTS_RESET = '@@prytany/global/ACCOUNT_POSTS_RESET';
export const ACCOUNT_POSTS_SET_AS_FIRST =
  '@@prytany/global/ACCOUNT_POSTS_SET_AS_FIRST';
export const ACCOUNT_POSTS_LIKE_POST =
  '@@prytany/global/ACCOUNT_POSTS_LIKE_POST';
export const ACCOUNT_POSTS_UNLIKE_POST =
  '@@prytany/global/ACCOUNT_POSTS_UNLIKE_POST';
export const ACCOUNT_POSTS_SHARE_POST =
  '@@prytany/global/ACCOUNT_POSTS_SHARE_POST';
