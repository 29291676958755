import {
  userProfileFeedReset as reset,
  getUserProfileFeed as getFeed,
  getUserProfileFeedNextPage as getNextPage,
} from './userProfileFeed.actions';
import type {
  GetUserFeedPayload,
  GetUserFeedResponse,
} from '../../../services/RoRUsersApiProvider';

export const mapStateToProps = state => {
  return {
    userProfileFeedData: state.userProfileFeed.data,
    userProfileFeedError: state.userProfileFeed.error,
    userProfileFeedIsLoading: state.userProfileFeed.isLoading,
  };
};

export const mapDispatchToProps = dispatch => {
  return {
    getUserProfileFeed(payload: GetUserFeedPayload) {
      dispatch(getFeed(payload));
    },
    getUserProfileFeedNextPage(payload: { limit?: number, userId: number }) {
      dispatch(getNextPage(payload));
    },
    userProfileFeedReset() {
      dispatch(reset());
    },
  };
};

export type UserProfileFeedMapStateToProps = {
  userProfileFeedData: GetUserFeedResponse,
  userProfileFeedError: any,
  userProfileFeedIsLoading: boolean,
};

export type UserProfileFeedMapDispatchToProps = {
  getUserProfileFeed: (payload: GetUserFeedPayload) => void,
  getUserProfileFeedNextPage: (payload: {
    limit?: number,
    userId: number,
  }) => void,
  userProfileFeedReset: () => void,
};

export default {
  mapStateToProps,
  mapDispatchToProps,
};
