// @flow
import { createAction } from 'redux-actions';
import {
  CONTRIBUTION_DETAILS_ERROR,
  CONTRIBUTION_DETAILS_LOADING,
  CONTRIBUTION_DETAILS_RESET,
  CONTRIBUTION_DETAILS_SUCCESS,
  CONTRIBUTION_DETAILS_CHANGE_PLEDGE_CLAIM_STATUS,
} from './contributionDetails.constants';
import { instance as rorContributionsApiProvider } from '../../../services/RoRContributionsApiProvider';

export const contributionDetailsError = createAction(
  CONTRIBUTION_DETAILS_ERROR,
);

export const contributionDetailsLoading = createAction(
  CONTRIBUTION_DETAILS_LOADING,
);

export const contributionDetailsSuccess = createAction(
  CONTRIBUTION_DETAILS_SUCCESS,
);

export const contributionDetailsReset = createAction(
  CONTRIBUTION_DETAILS_RESET,
);

export const contributionDetailsChangePledgeClaimStatus = createAction(
  CONTRIBUTION_DETAILS_CHANGE_PLEDGE_CLAIM_STATUS,
);

export const getContributionDetails = (
  payload: GetContributionDetailsPayload,
) => {
  return async (dispatch: Function) => {
    try {
      dispatch(contributionDetailsLoading());
      let response;

      if (payload.type === 'direct') {
        response = await rorContributionsApiProvider.getDirectDonationDetails({
          donationId: payload.id,
        });
      } else if (payload.type === 'pledged') {
        response = await rorContributionsApiProvider.getPledgeDonationDetails({
          donationId: payload.id,
        });
      } else if (payload.type === 'committed') {
        response = await rorContributionsApiProvider.getCommittedDonationDetails(
          { donationId: payload.id },
        );
      }

      dispatch(contributionDetailsSuccess(response));
    } catch (error) {
      dispatch(contributionDetailsError(error));
    }
  };
};

export type GetContributionDetailsPayload = {
  id: number,
  type: 'direct' | 'committed' | 'pledged',
};
