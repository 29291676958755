// @flow
import React from 'react';
import _get from 'lodash/get';
import { connect } from 'react-redux';
import { message } from 'antd';
import accountContenders from '../../redux/modules/accountContenders/accountContenders.containers';
import accountDetails from '../../redux/modules/accountDetails/accountDetails.containers';
import CardContender from '../../components/CardContender/CardContender';
import type {
  AccountContendersMapDispatchToProps,
  AccountContendersMapStateToProps,
} from '../../redux/modules/accountContenders/accountContenders.containers';
import type { AccountDetailsMapStateToProps } from '../../redux/modules/accountDetails/accountDetails.containers';
import './DashboardContendersPage.scss';

type Props = AccountContendersMapDispatchToProps &
  AccountContendersMapStateToProps &
  AccountDetailsMapStateToProps & {};

class DashboardContendersPage extends React.Component<Props, {}> {
  constructor(props: Props) {
    super(props);
  }

  componentDidMount() {
    if (typeof window !== 'undefined') {
      this.props.accountContendersList({
        userId: _get(this.props, 'accountDetailsData.user.id'),
      });
      message.loading('Updating contenders...', 3);
    }
  }

  render() {
    return (
      <div className="dashboard-contenders-page container-fluid">
        {this.props.accountContendersListData ? (
          <div className="row fix-width">
            {this.props.accountContendersListData.contenders.map(
              (contender, index) => (
                <div className="col-12 col-md-4 px-2 pb-4" key={index}>
                  <CardContender {...contender} contributeButton={false} />
                </div>
              ),
            )}
          </div>
        ) : null}
        {!this.props.accountContendersListData ? (
          <div className="row fix-width">
            <p>You are not part of any race yet.</p>
          </div>
        ) : null}
      </div>
    );
  }
}

const mapStateToProps = (state, ownProps) => {
  return {
    ...accountContenders.mapStateToProps(state),
    ...accountDetails.mapStateToProps(state),
    ...ownProps,
  };
};

const mapDispatchToProps = dispatch => {
  return {
    ...accountContenders.mapDispatchToProps(dispatch),
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(DashboardContendersPage);
