// @flow
import { createAction } from 'redux-actions';
import _omit from 'lodash/omit';
import {
  GROUP_APPROVE_REQUEST_ERROR,
  GROUP_APPROVE_REQUEST_LOADING,
  GROUP_APPROVE_REQUEST_RESET,
  GROUP_APPROVE_REQUEST_SUCCESS,
} from './groupApproveRequest.constants';
import { instance as rorGroupsApiProvider } from '../../../services/RoRGroupsApiProvider';
import type {
  PutApproveAllMemberRequestsPayload,
  PutApproveMemberRequestPayload,
} from '../../../services/RoRGroupsApiProvider';
import {
  groupMemberPendingRequestsRemoveItem,
  groupMemberPendingRequestsRemoveItemsForRole,
} from '../groupMemberPendingRequests/groupMemberPendingRequests.actions';

export const groupApproveRequestError = createAction(
  GROUP_APPROVE_REQUEST_ERROR,
);

export const groupApproveRequestLoading = createAction(
  GROUP_APPROVE_REQUEST_LOADING,
);

export const groupApproveRequestSuccess = createAction(
  GROUP_APPROVE_REQUEST_SUCCESS,
);

export const groupApproveRequestReset = createAction(
  GROUP_APPROVE_REQUEST_RESET,
);

export const groupApproveRequest = (payload: ApproveRequestPayload) => {
  return async (dispatch: Function) => {
    try {
      let response = {};
      dispatch(groupApproveRequestLoading());

      if (payload.userId) {
        response = await rorGroupsApiProvider.groupApproveMemberRequest(
          payload,
        );
        dispatch(groupMemberPendingRequestsRemoveItem(payload));
        //$FlowFixMe
      } else if (payload.roleId) {
        //$FlowFixMe
        response = await rorGroupsApiProvider.groupApproveAllMemberRequest(
          _omit(payload, 'roleCode'),
        );
        //$FlowFixMe
        dispatch(
          groupMemberPendingRequestsRemoveItemsForRole({
            roleCode: payload.roleCode,
          }),
        );
      } else {
        return dispatch(
          groupApproveRequestError(new Error('roleId or userId are required.')),
        );
      }

      dispatch(groupApproveRequestSuccess(response));
    } catch (error) {
      dispatch(groupApproveRequestError(error));
    }
  };
};

type ApproveRequestPayload =
  | (PutApproveAllMemberRequestsPayload & { roleCode: string })
  | PutApproveMemberRequestPayload;
