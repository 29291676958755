//@flow
import * as React from 'react';
import { message } from 'antd';
import { connect } from 'react-redux';
import Avatar from '../Avatar/Avatar';
import Link from '../../components/Link/Link';
import FollowButton from '../FollowButton/FollowButton';
import { URL_RACE_ACTIVITY_PAGE } from '../../config/urls';
import account from '../../redux/modules/account/account.containers';
import type { AccountMapStateToProps } from '../../redux/modules/account/account.containers';
import userFollow from '../../redux/modules/userFollow/userFollow.containers';
import type {
  UserFollowMapDispatchToProps,
  UserFollowMapStateToProps,
} from '../../redux/modules/userFollow/userFollow.containers';
import userProfileFollowers from '../../redux/modules/userProfileFollowers/userProfileFollowers.containers';
import type { UserProfileFollowersMapDispatchToProps } from '../../redux/modules/userProfileFollowers/userProfileFollowers.containers';
import './UserOverview.scss';
import isPrytanyVerified from '../../utilities/isPrytanyVerified';
import type { UserDetails } from '../../services/RoRUsersApiProvider';
import { isCitizen } from '../../utilities/authorization';

type Props = AccountMapStateToProps &
  UserFollowMapDispatchToProps &
  UserFollowMapStateToProps &
  UserProfileFollowersMapDispatchToProps & {
    actionButton: any,
    createdAt: string,
    campaignTotal?: number,
    isExploratory?: boolean,
    followers?: number,
    interests?: string[],
    likes?: number,
    name: string,
    profile_image: string,
    race?: string,
    race_id?: string,
    role: string,
    isPac: boolean,
    secondaryContent: any,
    politicalParty: any,
    userId: string,
    isFollowing: boolean,
    contributionsCount: number,
    isActive: boolean,
    allowFollowers: boolean,
    messageButton: any,
    user: UserDetails,
  };

class UserOverview extends React.Component<Props> {
  // TODO: move following logic to redux
  constructor(props: Props) {
    super(props);

    this.props.userFollowReset();
  }

  componentDidUpdate(prevProps: Props) {
    if (!prevProps.userFollowData && this.props.userFollowData) {
      this.props.getUserProfileFollowers({ id: this.props.userId });
      this.props.userFollowReset();
    }

    if (!prevProps.userFollowError && this.props.userFollowError) {
      message.error(
        this.props.userFollowError.localMessage ||
          "We're unable to process your request. Try again later.",
      );
    }
  }

  render() {
    const { user } = this.props;
    return (
      <div className="user-overview container-fluid">
        <div className="row fix-width">
          <div className="col-12 col-md-8">
            <div className="row pt-3 pt-md-4 pt-lg-5">
              <div className="col-4 col-lg-2 pl-0">
                <Avatar
                  type="normal"
                  source={this.props.profile_image}
                  verifiedLevel={user.verified_level}
                  verifiedDetail={user.verified_detail}
                />
              </div>
              <div className="col-8 col-lg-10 pl-0 pl-md-3 pl-lg-2 pl-xl-0 pt-2">
                <div className="user-overview__heading">
                  <h1 className="user-overview__name">{this.props.name}</h1>
                  {this.props.role === 'Candidate' ? (
                    <img
                      src="/images/icon_candidate_big.png"
                      alt=""
                      className="pr-1 user-overview__candidate-badge"
                    />
                  ) : null}
                  {this.isLoggedInUserProfile() && this.props.allowFollowers ? (
                    <FollowButton
                      isFollowing={this.props.isFollowing}
                      onClick={this.handleToggleFollowUser}
                      loading={this.props.userFollowIsLoading}
                    />
                  ) : null}
                </div>
                {this.props.isPac && <div>{this.props.role}</div>}
                <div className="date">
                  {this.showContributionsText()}
                  {this.props.isActive
                    ? `Participant since ${this.props.createdAt}`
                    : null}
                  {this.props.isExploratory ? (
                    <span className="user-overview__exploratory-indicator">
                      | Exploratory Committee
                    </span>
                  ) : null}
                </div>
                {this.renderRaceLabel()}
                {this.props.showInterestsButton}
              </div>
            </div>
          </div>
          <div className="col-12 col-md-4 d-none d-sm-block pt-1 pt-sm-4 pt-md-5 mt-1 pr-0">
            <div className="row">
              <div className="col text-center text-md-right">
                {this.props.actionButton}
              </div>
              <div className="col text-center text-md-right">
                {this.props.messageButton}
              </div>
            </div>
            <div className="row">
              <div className="col user-overview__candidate-status text-right pr-0 pr-md-3">
                {this.props.secondaryContent}
                {this.props.politicalParty}
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }

  showContributionsText() {
    let content = null;
    if (this.props.role === 'Citizen') {
      if (this.props.contributionsCount <= 10) {
        content = `${this.props.contributionsCount} Contributions | `;
      } else {
        content = '10 + Contributions | ';
      }
    }
    return content;
  }

  renderRaceLabel() {
    let content = null;

    if (this.props.race) {
      content = (
        <div className="race">
          <Link
            href={URL_RACE_ACTIVITY_PAGE.replace(
              ':raceId',
              this.props.race_id,
            )}>
            <span className="race__name">{this.props.race}</span>
          </Link>
        </div>
      );
    }

    return content;
  }

  isLoggedInUserProfile() {
    return parseInt(this.props.userId) !== this.props.accountData.user.id;
  }

  handleToggleFollowUser = () => {
    if (this.props.isFollowing) {
      this.props.userUnFollow({ userId: this.props.userId });
    } else {
      this.props.userFollow({ userId: this.props.userId });
    }
  };
}

const mapStateToProps = (state, ownProps) => {
  return {
    ...account.mapStateToProps(state),
    ...userFollow.mapStateToProps(state),
    ...ownProps,
  };
};

const mapDispatchToProps = dispatch => {
  return {
    ...account.mapDispatchToProps(dispatch),
    ...userFollow.mapDispatchToProps(dispatch),
    ...userProfileFollowers.mapDispatchToProps(dispatch),
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(UserOverview);
