// @flow
import React from 'react';
import { withRouter } from 'react-router-dom';

type Props = {
  children: any,
  location: any,
  history: any,
};

const MobileContributionsPaymentCompletedPage = ({ location }: Props) => {
  const { message } = location.state || {
    message: 'You have successfully completed your contribution.',
  };
  return (
    <div className="mobile-contributions-direct-page">
      <div className="row fix-width">
        <div
          className="col-12 d-flex justify-content-center align-items-center flex-column"
          style={{ height: '100vh' }}>
          <h2 className="f-green fs-16 py-5 px-3 text-center">
            Successful Contribution
          </h2>
          <div
            className="fs-13"
            dangerouslySetInnerHTML={{ __html: message }}
          />
        </div>
      </div>
    </div>
  );
};

export default withRouter(MobileContributionsPaymentCompletedPage);
