import {
  requestJoinGroup as requestJoin,
  groupRequestJoinReset as reset,
} from './groupRequestJoin.actions';
import type { PostGroupRequestJoinPayload } from '../../../services/RoRGroupsApiProvider';
import type { DefaultResponse } from '../../../services/RoRApiProvider';

export const mapStateToProps = state => {
  return {
    groupRequestJoinData: state.groupRequestJoin.data,
    groupRequestJoinError: state.groupRequestJoin.error,
    groupRequestJoinIsLoading: state.groupRequestJoin.isLoading,
  };
};

export const mapDispatchToProps = dispatch => {
  return {
    requestJoinGroup(payload: PostGroupRequestJoinPayload) {
      dispatch(requestJoin(payload));
    },
    groupRequestJoinReset() {
      dispatch(reset());
    },
  };
};

export type GroupRequestJoinMapStateToProps = {
  groupRequestJoinData: DefaultResponse,
  groupRequestJoinError: any,
  groupRequestJoinIsLoading: boolean,
};

export type GroupRequestJoinMapDispatchToProps = {
  requestJoinGroup: (payload: PostGroupRequestJoinPayload) => void,
  groupRequestJoinReset: () => void,
};

export default {
  mapStateToProps,
  mapDispatchToProps,
};
