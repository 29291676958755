// @flow
import { createAction } from 'redux-actions';
import {
  ACCOUNT_CANDIDATE_COMMITMENTS_ERROR,
  ACCOUNT_CANDIDATE_COMMITMENTS_LOADING,
  ACCOUNT_CANDIDATE_COMMITMENTS_RESET,
  ACCOUNT_CANDIDATE_COMMITMENTS_SUCCESS,
} from './accountCandidateCommitments.constants';
import { instance as rorGroupsApiProvider } from '../../../services/RoRUsersApiProvider';
import type { GetCandidateCommitmentsPayload } from '../../../services/RoRUsersApiProvider';

export const accountCandidateCommitmentsError = createAction(
  ACCOUNT_CANDIDATE_COMMITMENTS_ERROR,
);

export const accountCandidateCommitmentsLoading = createAction(
  ACCOUNT_CANDIDATE_COMMITMENTS_LOADING,
);

export const accountCandidateCommitmentsSuccess = createAction(
  ACCOUNT_CANDIDATE_COMMITMENTS_SUCCESS,
);

export const accountCandidateCommitmentsReset = createAction(
  ACCOUNT_CANDIDATE_COMMITMENTS_RESET,
);

export const getAccountCandidateCommitments = (
  payload: GetCandidateCommitmentsPayload,
) => {
  return async (dispatch: Function) => {
    try {
      dispatch(accountCandidateCommitmentsLoading());

      const response = await rorGroupsApiProvider.accountCandidateCommitments(
        payload,
      );

      dispatch(accountCandidateCommitmentsSuccess(response));
    } catch (error) {
      dispatch(accountCandidateCommitmentsError(error));
    }
  };
};
