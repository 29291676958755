// @flow
import makeDefaultServerCallReducer from '../../utilities/makeDefaultServerCallReducer';
import {
  POST_DETAILS_ERROR,
  POST_DETAILS_LOADING,
  POST_DETAILS_SUCCESS,
  POST_DETAILS_RESET,
  POST_DETAILS_LIKE_POST,
  POST_DETAILS_UNLIKE_POST,
  POST_DETAILS_SHARE_POST,
} from './postDetails.constants';
import _cloneDeep from 'lodash/cloneDeep';

const initialState = {
  isLoading: false,
  error: null,
  data: null,
};

const sharePostDetails = (state, action) => {
  if (state.data) {
    const data = _cloneDeep(state.data);

    if (data.post && data.post.id === action.payload.postId) {
      data.post.share_count += 1;
    }

    return {
      ...state,
      data,
    };
  }

  return state;
};

const likePostDetails = (state, action) => {
  if (state.data) {
    const data = _cloneDeep(state.data);

    if (data.post && data.post.id === action.payload.postId) {
      data.post.like_count += 1;
      data.post.has_liked = true;
    }

    return {
      ...state,
      data,
    };
  }

  return state;
};

const unLikeUserPost = (state, action) => {
  if (state.data) {
    const data = _cloneDeep(state.data);

    if (data.post) {
      data.post.like_count -= 1;
      data.post.has_liked = false;
    }

    return {
      ...state,
      data,
    };
  }

  return state;
};

export const reducer = makeDefaultServerCallReducer({
  loadingActions: [POST_DETAILS_LOADING],
  successActions: [POST_DETAILS_SUCCESS],
  errorActions: [POST_DETAILS_ERROR],
  resetActions: [POST_DETAILS_RESET],
  otherActions: [
    { name: POST_DETAILS_LIKE_POST, callback: likePostDetails },
    { name: POST_DETAILS_UNLIKE_POST, callback: unLikeUserPost },
    { name: POST_DETAILS_SHARE_POST, callback: sharePostDetails },
  ],
  initialState,
});
