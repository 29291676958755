// @flow
import { createAction } from 'redux-actions';
import {
  GROUP_SEND_INVITATION_ERROR,
  GROUP_SEND_INVITATION_LOADING,
  GROUP_SEND_INVITATION_RESET,
  GROUP_SEND_INVITATION_SUCCESS,
} from './groupSendInvitation.constants';
import { instance as rorGroupsApiProvider } from '../../../services/RoRGroupsApiProvider';
import type { PostInvitePeoplePayload } from '../../../services/RoRGroupsApiProvider';

export const groupSendInvitationError = createAction(
  GROUP_SEND_INVITATION_ERROR,
);

export const groupSendInvitationLoading = createAction(
  GROUP_SEND_INVITATION_LOADING,
);

export const groupSendInvitationSuccess = createAction(
  GROUP_SEND_INVITATION_SUCCESS,
);

export const groupSendInvitationReset = createAction(
  GROUP_SEND_INVITATION_RESET,
);

export const groupSendInvitation = (payload: PostInvitePeoplePayload) => {
  return async (dispatch: Function) => {
    try {
      dispatch(groupSendInvitationLoading());

      const response = await rorGroupsApiProvider.groupInvitePeople(payload);

      dispatch(groupSendInvitationSuccess(response));
    } catch (error) {
      dispatch(groupSendInvitationError(error));
    }
  };
};
