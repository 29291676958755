// @flow
import makeDefaultServerCallReducer from '../../utilities/makeDefaultServerCallReducer';
import {
  LIST_RACES_ERROR,
  LIST_RACES_LOADING,
  LIST_RACES_SUCCESS,
  LIST_RACES_RESET,
} from './listRaces.constants';

const initialState = {
  isLoading: false,
  error: null,
  data: null,
};

export const reducer = makeDefaultServerCallReducer({
  loadingActions: [LIST_RACES_LOADING],
  successActions: [LIST_RACES_SUCCESS],
  errorActions: [LIST_RACES_ERROR],
  resetActions: [LIST_RACES_RESET],
  initialState,
});
