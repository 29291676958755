//@flow

/**
 *
 * Returns a function with the same signature as the specified handler but
 * will call `e.preventDefault()` on the event before calling the handler
 *
 * @param handler
 * @returns {function(Event, ...[*[]]): void}
 */
const withPreventDefault = (handler: (e: Event, ...rest: any[]) => void) => {
  return (e: Event, ...rest: any[]) => {
    e && e.preventDefault && e.preventDefault();
    return handler(e, ...rest);
  };
};

export default withPreventDefault;
