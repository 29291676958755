// @flow
import { createAction } from 'redux-actions';
import {
  GROUP_REMOVE_MODERATOR_ERROR,
  GROUP_REMOVE_MODERATOR_LOADING,
  GROUP_REMOVE_MODERATOR_RESET,
  GROUP_REMOVE_MODERATOR_SUCCESS,
} from './groupRemoveModerator.constants';
import { instance as rorGroupsApiProvider } from '../../../services/RoRGroupsApiProvider';
import type { DeleteGroupRemoveModeratorPayload } from '../../../services/RoRGroupsApiProvider';
import { groupModeratorsRemoveUserFromList } from '../groupModerators/groupModerators.actions';

export const groupRemoveModeratorError = createAction(
  GROUP_REMOVE_MODERATOR_ERROR,
);

export const groupRemoveModeratorLoading = createAction(
  GROUP_REMOVE_MODERATOR_LOADING,
);

export const groupRemoveModeratorSuccess = createAction(
  GROUP_REMOVE_MODERATOR_SUCCESS,
);

export const groupRemoveModeratorReset = createAction(
  GROUP_REMOVE_MODERATOR_RESET,
);

export const removeModeratorFromGroup = (
  payload: DeleteGroupRemoveModeratorPayload,
) => {
  return async (dispatch: Function) => {
    try {
      dispatch(groupRemoveModeratorLoading());
      const response = await rorGroupsApiProvider.groupRemoveModerator(payload);

      dispatch(groupModeratorsRemoveUserFromList(payload));
      dispatch(groupRemoveModeratorSuccess(response));
    } catch (error) {
      dispatch(groupRemoveModeratorError(error));
    }
  };
};
