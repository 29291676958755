// @flow
import makeDefaultServerCallReducer from '../../utilities/makeDefaultServerCallReducer';
import {
  USER_PROFILE_CONTRIBUTIONS_SUCCESS,
  USER_PROFILE_CONTRIBUTIONS_RESET,
  USER_PROFILE_CONTRIBUTIONS_LOADING,
  USER_PROFILE_CONTRIBUTIONS_ERROR,
} from './userProfileContributions.constants';

const initialState = {
  isLoading: false,
  error: null,
  data: null,
};

export const reducer = makeDefaultServerCallReducer({
  loadingActions: [USER_PROFILE_CONTRIBUTIONS_LOADING],
  successActions: [USER_PROFILE_CONTRIBUTIONS_SUCCESS],
  errorActions: [USER_PROFILE_CONTRIBUTIONS_ERROR],
  resetActions: [USER_PROFILE_CONTRIBUTIONS_RESET],
  initialState,
});
