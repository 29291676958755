// @flow
import { createAction } from 'redux-actions';
import {
  GROUP_DETAILS_ERROR,
  GROUP_DETAILS_LOADING,
  GROUP_DETAILS_RESET,
  GROUP_DETAILS_SUCCESS,
  GROUP_DETAILS_RENAME_GROUP,
  GROUP_DETAILS_UPDATE_GROUP,
  GROUP_DETAILS_ADD_CONTRIBUTION,
} from './groupDetails.constants';
import { instance as rorGroupsApiProvider } from '../../../services/RoRGroupsApiProvider';
import type { GetGroupDetailsPayload } from '../../../services/RoRGroupsApiProvider';

export const groupDetailsError = createAction(GROUP_DETAILS_ERROR);

export const groupDetailsLoading = createAction(GROUP_DETAILS_LOADING);

export const groupDetailsSuccess = createAction(GROUP_DETAILS_SUCCESS);

export const groupDetailsReset = createAction(GROUP_DETAILS_RESET);

export const groupDetailsRenameGroup = createAction(GROUP_DETAILS_RENAME_GROUP);

export const groupDetailsUpdateGroup = createAction(GROUP_DETAILS_UPDATE_GROUP);

export const groupDetailsAddContribution = createAction(
  GROUP_DETAILS_ADD_CONTRIBUTION,
);

export const getGroupDetails = (payload: GetGroupDetailsPayload) => {
  return async (dispatch: Function) => {
    try {
      dispatch(groupDetailsLoading());

      const response = await rorGroupsApiProvider.groupDetails(payload);

      dispatch(groupDetailsSuccess(response));
    } catch (error) {
      dispatch(groupDetailsError(error));
    }
  };
};
