import {
  accountProfileUpdateReset as reset,
  updateAccountProfile as updateProfile,
} from './accountProfileUpdate.actions';
import type {
  PutUserProfileUpdatePayload,
  UserProfile,
} from '../../../services/RoRUsersApiProvider';

export const mapStateToProps = state => {
  return {
    accountProfileUpdateData: state.accountProfileUpdate.data,
    accountProfileUpdateError: state.accountProfileUpdate.error,
    accountProfileUpdateIsLoading: state.accountProfileUpdate.isLoading,
  };
};

export const mapDispatchToProps = dispatch => {
  return {
    updateAccountProfile(payload: PutUserProfileUpdatePayload) {
      dispatch(updateProfile(payload));
    },
    accountProfileUpdateReset() {
      dispatch(reset());
    },
  };
};

export type AccountProfileUpdateMapStateToProps = {
  accountProfileUpdateData: { user: UserProfile },
  accountProfileUpdateError: any,
  accountProfileUpdateIsLoading: boolean,
};

export type AccountProfileUpdateMapDispatchToProps = {
  updateAccountProfile: Function,
  accountProfileUpdateReset: Function,
};

export default {
  mapStateToProps,
  mapDispatchToProps,
};
