// @flow
import * as React from 'react';
import { Field } from 'formik';
import classNames from 'classnames';

import './Checkboxes.scss';

type Props = {
  className?: string,
  inputClassName?: string,
  checkboxWrapperClassName?: string,
  error?: string,
  name: string,
  items: Array<{ label: any, value: string }>,
};

const Checkboxes = (props: Props) => {
  return (
    <div className={getClassName(props)}>
      <Field
        name={props.name}
        render={({ field, form }) => {
          return props.items.map(item => {
            const inputId = `checkbox-${props.name}-${item.value}`;
            return (
              <div className={getCheckboxClassName(props)} key={item.value}>
                <input
                  onBlur={field.onBlur}
                  name={props.name}
                  value={item.value}
                  id={inputId}
                  type="checkbox"
                  className="checkbox__input"
                  checked={field.value.includes(item.value)}
                  onChange={() =>
                    handleInputChange({ props, item, field, form })
                  }
                />
                <label
                  htmlFor={inputId}
                  className={getCustomInputClassName(props)}
                />
                <CheckboxLabel label={item.label} />
              </div>
            );
          });
        }}
      />
      {props.error ? (
        <div className="checkbox__error-message">{props.error}</div>
      ) : null}
    </div>
  );
};

const CheckboxLabel = ({ label }: { label: any }) => {
  if (typeof label === 'string') {
    return <span className="checkbox__label-text">{label}</span>;
  }

  return <div className="checkbox__custom-label">{label}</div>;
};

function handleInputChange({ props, item, field, form }) {
  if (field.value.includes(item.value)) {
    const nextValue = field.value.filter(value => value !== item.value);
    form.setFieldValue(props.name, nextValue);
  } else {
    const nextValue = field.value.concat(item.value);
    form.setFieldValue(props.name, nextValue);
  }
}

function getClassName(props) {
  return classNames({
    'form-group': true,
    [props.className || '']: props.className,
  });
}

function getCheckboxClassName(props) {
  return classNames({
    checkbox: true,
    'checkbox--error': props.error,
    [props.checkboxWrapperClassName || '']: props.checkboxWrapperClassName,
  });
}

function getCustomInputClassName(props) {
  return classNames({
    checkbox__label: true,
    'checkbox__label checkbox__label--error': props.error,
    [props.inputClassName || '']: props.inputClassName,
  });
}

export default Checkboxes;
