// @flow
import React from 'react';
import {
  URL_MOBILE_CONTRIBUTIONS_COMMITTED,
  URL_MOBILE_CONTRIBUTIONS_DIRECT,
  URL_MOBILE_CONTRIBUTIONS_PLEDGE,
} from '../../config/urls';
import ContributeOptions from '../../components/ContributeOptions/ContributeOptions';
import type { ContributionTypeType } from '../../components/ContributeOptions/ContributeOptions';
import userDetails from '../../redux/modules/userDetails/userDetails.containers';
import { connect } from 'react-redux';
import type {
  UserDetailsMapDispatchToProps,
  UserDetailsMapStateToProps,
} from '../../redux/modules/userDetails/userDetails.containers';
import { withMobileContributionsContext } from '../../context/mobileContributionsContext';
import type { withMobileContributionsContextProps } from '../../context/mobileContributionsContext';
import LoadingSpinner from '../../components/LoadingSpinner/LoadingSpinner';
import { canAcceptPledge } from '../../utilities/authorization';

type Props = UserDetailsMapStateToProps &
  UserDetailsMapDispatchToProps &
  withMobileContributionsContextProps & {
    children: any,
    location: any,
    history: any,
    match: any,
  };
type State = {};

class MobileContributionsOptionsPage extends React.Component<Props, State> {
  componentWillMount() {
    this.props.userDetailsReset();
  }

  componentDidMount() {
    const { mobileToken, mobileEmail, mobileUserId } = this.props;

    this.props.getDetailsForUser({
      userId: mobileUserId,
      customToken: mobileToken,
      customEmail: mobileEmail,
    });
  }

  render() {
    return (
      <div className="mobile-contributions-options-page container">
        <div className="row">
          <div className="col-12">
            {this.props.userDetailsIsLoading && (
              <div className="mobile-contributions-page__center">
                <LoadingSpinner type="dark" visible={true} />
              </div>
            )}
            {this.props.userDetailsError ? (
              <div className="mobile-contributions-page__error mobile-contributions-page__center">
                {this.props.userDetailsError.localMessage ||
                  "An error has occurred and we can't process your request."}
              </div>
            ) : null}
            {this.props.userDetailsData && (
              <ContributeOptions
                allowPledge={canAcceptPledge(this.props.userDetailsData.user)}
                onContributionTypeSelected={this.handleOptionSelected}
              />
            )}
          </div>
        </div>
      </div>
    );
  }

  handleOptionSelected = (option: ContributionTypeType) => {
    const qs = this.props.location.search;
    const userId = this.props.match.params.userId;
    const redirectTo = {
      direct: `${URL_MOBILE_CONTRIBUTIONS_DIRECT.replace(
        ':userId',
        userId,
      )}${qs}`,
      committed: `${URL_MOBILE_CONTRIBUTIONS_COMMITTED.replace(
        ':userId',
        userId,
      )}${qs}`,
      pledge: `${URL_MOBILE_CONTRIBUTIONS_PLEDGE.replace(
        ':userId',
        userId,
      )}${qs}`,
    };

    this.props.history.push(redirectTo[option]);
  };
}

const mapStateToProps = (state, ownProps) => {
  return {
    ...userDetails.mapStateToProps(state),
    ...ownProps,
  };
};

const mapDispatchToProps = dispatch => {
  return {
    ...userDetails.mapDispatchToProps(dispatch),
  };
};

export default withMobileContributionsContext(
  connect(
    mapStateToProps,
    mapDispatchToProps,
  )(MobileContributionsOptionsPage),
);
