import React from 'react';
import _get from 'lodash/get';
import Post from '../Post/Post';
import CardGroupJoin from '../CardGroupJoin/CardGroupJoin';
import CardDirectContribution from '../CardDirectContribution/CardDirectContribution';
import type { Feed } from '../../services/RoRUsersApiProvider';
import CardRaceThought from '../CardRaceThought/CardRaceThought';
import CardCommitment from '../CardCommitment/CardCommitment';
import CardPledge from '../CardPledge/CardPledge';
import CardRaceStand from '../CardRaceStand/CardRaceStand';
import CardRssItem from '../CardRssItem/CardRssItem';
import { connect } from 'react-redux';
import account from '../../redux/modules/account/account.containers';
import type { AccountMapStateToProps } from '../../redux/modules/account/account.containers';
import { canDonate, isCitizen } from '../../utilities/authorization';
import isPrytanyVerified from '../../utilities/isPrytanyVerified';

type Props = AccountMapStateToProps & {
  feedItem: Feed,
  onButtonClick: Function,
  buttonProps?: any,
  buttonChildren?: any,
  userId?: boolean,
};

const FeedItem = (props: Props) => {
  const { accountData, feedItem, onButtonClick, userId } = props;
  const { user: loggedInUser } = accountData || {};

  const { owner } = feedItem;
  const isCandidate = _get(owner, 'role.code') === 'candidate';
  const userFullName = `${_get(owner, 'first_name', '')} ${_get(
    owner,
    'last_name',
    '',
  )}`.trim();
  let component = null;

  // Workaround to avoid a break-up the page when there are missing 'details' keys coming from the backend
  if (!feedItem || (feedItem && !feedItem.details)) {
    return null;
  }

  if (feedItem.type === 'new_post') {
    const post = feedItem.details.post;
    component = (
      <Post
        userId={owner.id}
        commentCount={post.comment_count}
        commentable={post.commentable}
        createdAt={post.created_at}
        currentUserHasLiked={post.has_liked}
        id={post.id}
        likeCount={post.like_count}
        postImage={post.post_image}
        shareCount={post.share_count}
        shortDescription={post.short_description}
        title={post.title}
        userFullName={userFullName}
        userAvatar={owner.profile_image}
        userIsCandidate={isCandidate}
        userIsOwner={userId === owner.id}
        userVerifiedLevel={owner.verified_level}
        userVerifiedDetail={owner.verified_detail}
      />
    );
  } else if (feedItem.type === 'group_join') {
    const group = feedItem.details.group;
    component = (
      <CardGroupJoin
        userAvatar={owner.profile_image}
        userIsCandidate={isCandidate}
        userVerifiedLevel={owner.verified_level}
        userVerifiedDetail={owner.verified_detail}
        userFullName={userFullName}
        date={feedItem.created_at}
        userId={owner.id}
        groupId={group.id}
        groupName={group.name}
      />
    );
  } else if (feedItem.type === 'contribution') {
    const donation = feedItem.details.donation;
    const candidate = donation.candidate;

    component = (
      <CardDirectContribution
        userAvatar={owner.profile_image}
        userIsCandidate={isCandidate}
        userVerifiedLevel={owner.verified_level}
        userVerifiedDetail={owner.verified_detail}
        userFullName={userFullName}
        date={feedItem.created_at}
        userId={owner.id}
        candidateId={candidate.id}
        candidateName={`${candidate.first_name} ${candidate.last_name}`.trim()}
        candidateAvatar={candidate.profile_image}
        amount={donation.total_amount_in_cents / 100}
        onContributeButtonClick={() => onButtonClick(feedItem)}
        buttonProps={props.buttonProps}
        buttonChildren={props.buttonChildren}
        showButton={canDonate(loggedInUser)}
      />
    );
  } else if (feedItem.type === 'commitment') {
    const donation = feedItem.details.committed_donation;
    const candidate = donation.candidate;

    component = (
      <CardCommitment
        userAvatar={owner.profile_image}
        userIsCandidate={isCandidate}
        userVerifiedLevel={owner.verified_level}
        userVerifiedDetail={owner.verified_detail}
        userFullName={userFullName}
        date={feedItem.created_at}
        userId={owner.id}
        candidateId={candidate.id}
        candidateName={`${candidate.first_name} ${candidate.last_name}`.trim()}
        candidateAvatar={candidate.profile_image}
        amount={donation.total_amount_in_cents / 100}
        frequency={donation.frequency_in_months}
        period={donation.period_in_months}
        onContributeButtonClick={() => onButtonClick(feedItem)}
        buttonProps={props.buttonProps}
        buttonChildren={props.buttonChildren}
        showButton={canDonate(loggedInUser)}
      />
    );
  } else if (feedItem.type === 'pledge') {
    const pledge = feedItem.details.pledge;
    const candidate = pledge.candidate;

    component = (
      <CardPledge
        userAvatar={owner.profile_image}
        userIsCandidate={isCandidate}
        userVerifiedLevel={owner.verified_level}
        userVerifiedDetail={owner.verified_detail}
        userFullName={userFullName}
        date={feedItem.created_at}
        userId={owner.id}
        candidateId={candidate.id}
        candidateName={`${candidate.first_name} ${candidate.last_name}`.trim()}
        candidateAvatar={candidate.profile_image}
        amount={pledge.total_amount_in_cents / 100}
        condition={pledge.condition}
        subInterestName={pledge.sub_interest_name}
        period={pledge.period_in_months}
        onContributeButtonClick={() => onButtonClick(feedItem)}
        buttonProps={props.buttonProps}
        buttonChildren={props.buttonChildren}
        showButton={canDonate(loggedInUser)}
      />
    );
  } else if (feedItem.type === 'new_race_thought') {
    const thought = feedItem.details.race_thought;
    component = (
      <CardRaceThought
        userAvatar={owner.profile_image}
        userIsCandidate={isCandidate}
        userFullName={userFullName}
        userId={owner.id}
        thought={thought.thought}
        date={feedItem.created_at}
      />
    );
  } else if (feedItem.type === 'race_stand') {
    const race = feedItem.details.race;
    component = (
      <CardRaceStand
        contenders={race.contenders}
        raceName={race.name}
        raceId={race.id}
        date={feedItem.created_at}
      />
    );
  } else if (feedItem.type === 'google_alert') {
    const rss = feedItem.details.google_alert;
    component = (
      <CardRssItem
        link={rss.link.href}
        description={rss.content}
        title={rss.title}
        date={feedItem.created_at}
        source={rss.source}
      />
    );
  } else if (feedItem.type === 'post_share') {
    const post = feedItem.details.post;
    const parentOwner = post.parent_owner;
    const parentOwnerIsCandidate = _get(owner, 'role.code') === 'candidate';
    const parentOwnerFullName = `${_get(parentOwner, 'first_name', '')} ${_get(
      parentOwner,
      'last_name',
      '',
    )}`.trim();
    component = (
      <Post
        sharedBy={owner}
        userId={parentOwner.id}
        commentCount={post.comment_count}
        commentable={post.commentable}
        createdAt={post.created_at}
        currentUserHasLiked={post.has_liked}
        id={post.id}
        likeCount={post.like_count}
        postImage={post.post_image}
        shareCount={post.share_count}
        shortDescription={post.short_description}
        title={post.title}
        userFullName={parentOwnerFullName}
        userAvatar={parentOwner.profile_image}
        userIsCandidate={parentOwnerIsCandidate}
        userVerifiedLevel={owner.verified_level}
        userVerifiedDetail={owner.verified_detail}
        userIsOwner={userId === parentOwner.id}
      />
    );
  }

  return (
    <div className="row fix-width">
      <div className="col-12">{component}</div>
    </div>
  );
};

const mapStateToProps = (state, ownProps) => {
  return {
    ...account.mapStateToProps(state),
    ...ownProps,
  };
};

export default connect(mapStateToProps)(FeedItem);
