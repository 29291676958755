import {
  getGroupMemberPendingRequests,
  groupMemberPendingRequestsReset as reset,
  getGroupMemberPendingRequestsNextPage as getNextPage,
} from './groupMemberPendingRequests.actions';
import type { GetGroupMemberPendingRequestsResponse } from '../../../services/RoRGroupsApiProvider';
import type {
  GroupMemberPendingRequestsNextPagePayload,
  GroupMemberPendingRequestsPayload,
} from './groupMemberPendingRequests.actions';

export const mapStateToProps = state => {
  return {
    groupMemberPendingRequestsData: state.groupMemberPendingRequests.data,
    groupMemberPendingRequestsError: state.groupMemberPendingRequests.error,
    groupMemberPendingRequestsIsLoading:
      state.groupMemberPendingRequests.isLoading,
  };
};

export const mapDispatchToProps = dispatch => {
  return {
    getMemberPendingRequestsForGroup(
      payload: GroupMemberPendingRequestsPayload,
    ) {
      dispatch(getGroupMemberPendingRequests(payload));
    },
    groupMemberPendingRequestsReset() {
      dispatch(reset());
    },
    getGroupMemberPendingRequestsNextPage(
      payload: GroupMemberPendingRequestsNextPagePayload,
    ) {
      dispatch(getNextPage(payload));
    },
  };
};

export type GroupMemberPendingRequestsMapStateToProps = {
  groupMemberPendingRequestsData: GetGroupMemberPendingRequestsResponse,
  groupMemberPendingRequestsError: any,
  groupMemberPendingRequestsIsLoading: boolean,
};

export type GroupMemberPendingRequestsMapDispatchToProps = {
  getMemberPendingRequestsForGroup: (
    payload: GroupMemberPendingRequestsPayload,
  ) => void,
  groupMemberPendingRequestsReset: Function,
  getGroupMemberPendingRequestsNextPage: (
    payload: GroupMemberPendingRequestsNextPagePayload,
  ) => void,
};

export default {
  mapStateToProps,
  mapDispatchToProps,
};
