import {
  ACCOUNT_INBOX_MESSAGE_DELETE_SUCCESS,
  ACCOUNT_INBOX_MESSAGE_DELETE_RESET,
  ACCOUNT_INBOX_MESSAGE_DELETE_LOADING,
  ACCOUNT_INBOX_MESSAGE_DELETE_ERROR,
} from './accountInboxMessageDelete.constants';
import makeDefaultServerCallReducer from '../../utilities/makeDefaultServerCallReducer';

const initialState = {
  isLoading: false,
  error: null,
  data: null,
};

export const reducer = makeDefaultServerCallReducer({
  loadingActions: [ACCOUNT_INBOX_MESSAGE_DELETE_LOADING],
  successActions: [ACCOUNT_INBOX_MESSAGE_DELETE_SUCCESS],
  errorActions: [ACCOUNT_INBOX_MESSAGE_DELETE_ERROR],
  resetActions: [ACCOUNT_INBOX_MESSAGE_DELETE_RESET],
  initialState,
});
