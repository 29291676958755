// @flow
import { createAction } from 'redux-actions';
import {
  LIST_RACES_ERROR,
  LIST_RACES_LOADING,
  LIST_RACES_SUCCESS,
  LIST_RACES_RESET,
} from './listRaces.constants';
import { instance as rorRacesApiProvider } from '../../../services/RoRRacesApiProvider';

export const listRacesError = createAction(LIST_RACES_ERROR);

export const listRacesLoading = createAction(LIST_RACES_LOADING);

export const listRacesSuccess = createAction(LIST_RACES_SUCCESS);

export const listRacesReset = createAction(LIST_RACES_RESET);

export const listRaces = (payload: {
  name?: string,
  limit?: number,
  page?: number,
}) => {
  return async (dispatch: Function) => {
    try {
      dispatch(listRacesLoading());

      const response = await rorRacesApiProvider.listRaces(payload);

      dispatch(listRacesSuccess(response));
    } catch (error) {
      dispatch(listRacesError(error));
    }
  };
};
