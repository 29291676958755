// @flow
import React, { Fragment } from 'react';
import { connect } from 'react-redux';
import * as yup from 'yup';
import { Formik, FormikComputedProps } from 'formik';
import { Scrollbars } from 'react-custom-scrollbars';
import { Link } from 'react-router-dom';
import { URL_USER_AGREEMENT, URL_PRIVACY_POLICY_PAGE } from '../../config/urls';
import Input from '../../components/Input/Input';
import FileInput from '../../components/FileInput/FileInput';
import TextArea from '../../components/TextArea/TextArea';
import Select from '../../components/Select/Select';
import SelectableBadges from '../../components/SelectableBadges/SelectableBadges';
import Checkboxes from '../Checkboxes/Checkboxes';
import LoadingSpinner from '../LoadingSpinner/LoadingSpinner';
import Button from '../Button/Button';
import listInterests from '../../redux/modules/listInterests/listInterests.containers';
import listStates from '../../redux/modules/listStates/listStates.containers';
import './SignUpAccountProfileForm.scss';
import RoleCode from '../../constants/RoleCode';

const { CITIZEN, CANDIDATE } = RoleCode;

type FormValues = {
  telephone: string,
  addressLine1: string,
  addressLine2: string,
  biography: string,
  city: string,
  zip: string,
  interests: string[],
  state: string,
  termsAndConditions: string[],
  campaignManagers?: string,
};

type Props = {
  userRole: 'candidate' | 'citizen',
  onFormSubmit: Function,
  initialFormValues: FormValues,
  listInterestsData: any,
  listInterestsError: any,
  listInterestsIsLoading: boolean,
  listStatesData: any,
  listStatesError: any,
  listStatesIsLoading: boolean,
  getListInterests: Function,
  getListInterestsNextPage: Function,
  getListStates: Function,
};

type State = {
  profilePicture: File | null,
};

class SignUpAccountProfileForm extends React.Component<Props, State> {
  formikForm: Formik;

  constructor(props) {
    super(props);

    this.state = {
      profilePicture: null,
    };
  }

  componentDidMount() {
    this.props.getListInterests();
    this.props.getListStates();
  }

  render() {
    const states = this.props.listStatesData
      ? this.props.listStatesData.states.map(state => ({
          label: state.name,
          value: state.id.toString(),
        }))
      : [];

    return (
      <div className="signup-account-profile-form container-fluid fix-width">
        <Formik {...this.getFormikProps()}>
          {props => this.renderInnerForm(props, states)}
        </Formik>
        <hr className="mt-4" />
      </div>
    );
  }

  renderInnerForm(
    formikProps: FormikComputedProps,
    states: { value: string, label: string, disabled?: boolean }[],
  ) {
    const { touched, errors, handleSubmit } = formikProps;
    const { userRole } = this.props;

    return (
      <form className="row justify-content-md-center" onSubmit={handleSubmit}>
        <div className="col">
          <div className="row pt-4 pt-md-5">
            <div className="col-lg-10 pr-lg-5">
              {userRole === CANDIDATE && (
                <Fragment>
                  <div className="row mb-mb-3">
                    <Input
                      className="col-md-6"
                      label={
                        <label htmlFor="addressLine1">
                          Address line 1{' '}
                          <span className="required-asterisk">*</span>
                        </label>
                      }
                      name="addressLine1"
                      type="text"
                      error={
                        touched.addressLine1 && errors.addressLine1
                          ? errors.addressLine1
                          : ''
                      }
                    />
                    <Input
                      className="col-md-6"
                      label={
                        <label htmlFor="addressLine1">Address line 2</label>
                      }
                      name="addressLine2"
                      type="text"
                      error={
                        touched.addressLine2 && errors.addressLine2
                          ? errors.addressLine2
                          : ''
                      }
                    />
                  </div>
                  <div className="row mb-mb-3">
                    <Input
                      className="col-md-6"
                      label={
                        <label htmlFor="city">
                          City <span className="required-asterisk">*</span>
                        </label>
                      }
                      name="city"
                      type="text"
                      error={touched.city && errors.city ? errors.city : ''}
                    />
                    <Select
                      className="col-md-6"
                      label={
                        <label htmlFor="state">
                          State <span className="required-asterisk">*</span>
                        </label>
                      }
                      name="state"
                      options={states}
                      error={touched.state && errors.state ? errors.state : ''}
                    />
                  </div>
                  <div className="row mb-mb-3">
                    <Input
                      className="col-md-6"
                      label={
                        <label htmlFor="zip">
                          Zip <span className="required-asterisk">*</span>
                        </label>
                      }
                      name="zip"
                      type="number"
                      error={touched.zip && errors.zip ? errors.zip : ''}
                    />
                    <Input
                      className="col-md-6"
                      label={
                        <label htmlFor="telephone">
                          Telephone <span className="required-asterisk">*</span>
                        </label>
                      }
                      name="telephone"
                      type="tel"
                      error={
                        touched.telephone && errors.telephone
                          ? errors.telephone
                          : ''
                      }
                    />
                  </div>
                  <div className="row mb-mb-3">
                    <div className="col-md-6 form-group">
                      <div className="input-group mb-3">
                        <div>
                          <p className="mb-2">Profile Image</p>
                        </div>
                        <div className="col-12 px-0">
                          <FileInput
                            className="pb-5"
                            onChange={this.handleProfilePictureChange}
                          />
                        </div>
                        <div>
                          <small className="f-nunito">
                            JPG, PNG, BMP - Maximum size 50 MB
                          </small>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="row mb-mb-3">
                    <TextArea
                      className="col-md-6"
                      label="Biography"
                      name="biography"
                      error={
                        touched.biography && errors.biography
                          ? errors.biography
                          : ''
                      }
                    />
                  </div>
                </Fragment>
              )}
              <div className="row mb-mb-3">
                <div className="form-group col-md-12">
                  {userRole === CANDIDATE && (
                    <h6>Select points of interests</h6>
                  )}
                  {userRole === CITIZEN && (
                    <p>
                      Let us know what topics you’re interested in. This
                      information helps us curate news, groups, candidates, and
                      fellow constituents that share your interests.
                    </p>
                  )}
                  <Scrollbars universal style={{ height: 260 }}>
                    <InterestBadges
                      isLoading={this.props.listInterestsIsLoading}
                      serverData={this.props.listInterestsData}
                      serverError={this.props.listInterestsError}
                      fieldError={
                        touched.interests && errors.interests
                          ? errors.interests
                          : ''
                      }
                    />
                  </Scrollbars>
                  <div className="text-center pt-4">
                    {this.props.listInterestsData &&
                    this.props.listInterestsData.next_page ? (
                      <Button
                        className="link-violet"
                        type="button"
                        buttonType="link"
                        size="small"
                        onClick={this.handleLoadMoreInterests}
                        loading={this.props.listInterestsIsLoading}>
                        SEE MORE &gt;
                      </Button>
                    ) : null}
                  </div>
                </div>
              </div>
            </div>
          </div>
          <hr className="mt-4" />
          {userRole === CANDIDATE && (
            <AssignCampaignManagers
              fieldError={
                touched.campaignManagers && errors.campaignManagers
                  ? errors.campaignManagers
                  : ''
              }
            />
          )}
          <TermsAndConditions
            fieldError={
              touched.termsAndConditions && errors.termsAndConditions
                ? errors.termsAndConditions
                : ''
            }
          />
        </div>
      </form>
    );
  }

  getFormikProps() {
    return {
      initialValues: this.getFormikInitialValues(),
      validationSchema: yup.object().shape(this.getValidationSchema()),
      onSubmit: this.onFormSubmit,
      ref: this.setFormikRef,
    };
  }

  getValidationSchema() {
    const { userRole } = this.props;
    let schema: any = {
      interests:
        this.props.userRole === 'candidate'
          ? yup
              .array()
              .of(yup.string())
              .min(1, 'Select at least one interest from the list')
          : yup.array().of(yup.string()),
      termsAndConditions: yup
        .array()
        .of(yup.string())
        .min(1, 'Please accept our terms and conditions to continue.'),
    };

    if (userRole === CANDIDATE) {
      schema = {
        ...schema,
        telephone: yup.string().required('This field is required'),
        state: yup.string().required('This field is required'),
        city: yup.string().required('This field is required'),
        zip: yup
          .string()
          .required('This field is required')
          .min(4, 'Minimum 4 characters.')
          .max(10, 'Maximum 10 characters allowed'),
        addressLine1: yup.string().required('This field is required'),
        campaignManagers: yup.string().required('This field is required'),
      };
    }

    return schema;
  }

  getFormikInitialValues() {
    const defaultValues: any = {
      telephone: '',
      state: '',
      city: '',
      addressLine1: '',
      addressLine2: '',
      biography: '',
      interests: [],
      termsAndConditions: [],
    };

    if (this.props.userRole === 'candidate') {
      defaultValues.campaignManagers = '';
    }

    if (this.props.initialFormValues) {
      let values = Object.assign({}, this.props.initialFormValues);

      // Prevents an issue with back and forward from AccountProfileForm to AccountBasicsForm
      if (Array.isArray(values.campaignManagers)) {
        values.campaignManagers = values.campaignManagers.join(',');
      }

      if (this.props.userRole === 'citizen' && values.campaignManagers) {
        delete values.campaignManagers;
      }

      return values;
    }

    return defaultValues;
  }

  setFormikRef = (ref: Formik) => {
    this.formikForm = ref;
  };

  handleProfilePictureChange = (files: FileList) => {
    this.setState(() => ({
      profilePicture: files && files[0] ? files[0] : null,
    }));
  };

  handleLoadMoreInterests = () => {
    this.props.getListInterestsNextPage();
  };

  submitForm() {
    // Manually triggering touch before sending the form
    this.formikForm.setTouched({
      telephone: true,
      state: true,
      city: true,
      zip: true,
      addressLine1: true,
      addressLine2: true,
      biography: true,
      interests: true,
      termsAndConditions: true,
    });
    this.formikForm.submitForm();
  }

  onFormSubmit = (values: FormValues) => {
    if (this.props.onFormSubmit) {
      const { campaignManagers: cm, ...rest } = values;
      let campaignManagers = '';

      if (cm) {
        campaignManagers = cm.split(',');
      }

      this.props.onFormSubmit({
        values: {
          profilePicture: this.state.profilePicture,
          ...rest,
          campaignManagers,
        },
      });
    }
  };
}

const AssignCampaignManagers = ({ fieldError }: { fieldError: string }) => {
  return (
    <React.Fragment>
      <div className="row pt-md-5">
        <div className="col-lg-10 pr-lg-5">
          <div className="row mb-md-3">
            <Input
              className="col-md-6"
              label={<h6>Assign Campaign Managers</h6>}
              name="campaignManagers"
              type="text"
              error={fieldError}>
              <small className="f-nunito">
                Type email addresses separated by a comma
              </small>
            </Input>
            <div className="col-md-6">
              <div className="row pt-4 mt-1">
                <div className="col-md-7 f-nunito fs-11">
                  If you wish you can leave the rest for your assigned CMs to
                  complete and your account will be on hold until then.
                </div>
                <div className="col-md-5">
                  <Button type="submit" buttonType="primary">
                    Add CM and Complete
                  </Button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <hr className="mt-4" />
    </React.Fragment>
  );
};

type InterestBadgesProps = {
  isLoading: boolean,
  serverError: any,
  serverData: any,
  fieldError: string,
  onInterestChange?: Function,
};
const InterestBadges = (props: InterestBadgesProps) => {
  const {
    isLoading,
    serverError,
    serverData,
    fieldError,
    onInterestChange,
  } = props;

  if (isLoading && !serverData) {
    return <LoadingSpinner type="dark" visible={true} />;
  }

  if (serverError) {
    return <span>{serverError.localMessage}</span>;
  }

  if (serverData) {
    return (
      <SelectableBadges
        className="d-flex justify-content-lg-between justify-content-center flex-wrap"
        items={serverData.interests.map(interest => ({
          label: interest.name,
          value: interest.id.toString(),
        }))}
        name="interests"
        error={fieldError}
        onChange={onInterestChange}
      />
    );
  }

  return null;
};

const TermsAndConditions = ({ fieldError }: { fieldError: string }) => {
  const label = (
    <div className="right">
      Accept{' '}
      <Link to={URL_USER_AGREEMENT} className="link-violet fw-500">
        Terms and conditions
      </Link>{' '}
      & &nbsp;
      <Link to={URL_PRIVACY_POLICY_PAGE} className="link-violet fw-500">
        Privacy and Policy <span className="required-asterisk">*</span>
      </Link>
    </div>
  );

  return (
    <div className="row justify-content-md-center px-3 px-md-3 terms">
      <Checkboxes
        items={[{ value: '1', label }]}
        name="termsAndConditions"
        error={fieldError}
      />
    </div>
  );
};

const mapStateToProps = (state, ownProps) => {
  return {
    ...listInterests.mapStateToProps(state),
    ...listStates.mapStateToProps(state),
    ...ownProps,
  };
};

const mapDispatchToProps = dispatch => {
  return {
    ...listInterests.mapDispatchToProps(dispatch),
    ...listStates.mapDispatchToProps(dispatch),
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps,
  null,
  { withRef: true },
)(SignUpAccountProfileForm);
