// @flow
import React from 'react';
import { Formik, FormikComputedProps } from 'formik';
import { connect } from 'react-redux';
import * as yup from 'yup';
import classNames from 'classnames';
import Modal from '../Modal/Modal';
import Input from '../Input/Input';
import FileInput from '../FileInput/FileInput';
import Toggle from '../Toggle/Toggle';
import TextArea from '../TextArea/TextArea';
import Select from '../Select/Select';
import Button from '../Button/Button';
import accountGroups from '../../redux/modules/accountGroups/accountGroups.containers';
import postAdd from '../../redux/modules/postAdd/postAdd.containers';
import './ModalNewPost.scss';

type Props = {
  isOpen: boolean,
  onAfterOpen?: Function,
  onCloseRequest?: Function,
  className?: string,
  accountGroupsData: any,
  accountGroupsError: any,
  accountGroupsIsLoading: boolean,
  postAddData: any,
  postAddError: any,
  postAddIsLoading: boolean,
  createPost: Function,
  postAddReset: Function,
  onSuccess: Function,
  onError: Function,
  groupId?: number,
  groupName?: string,
};

type State = {
  postImage: File | null,
  postImageError: boolean,
  postImageInputTouched: boolean,
  postImagePreviewUrl: string | null,
};

type FormValues = {
  title: string,
  content: string,
  postTo: string,
  allowComments: string,
};

class ModalNewPost extends React.Component<Props, State> {
  formikForm: Formik;
  fileInput: any;

  constructor(props: Props) {
    super(props);

    this.state = {
      postImage: null,
      postImageError: false,
      postImageInputTouched: false,
      postImagePreviewUrl: null,
    };
  }

  componentWillMount() {
    this.props.postAddReset();
  }

  componentDidUpdate(prevProps: Props) {
    if (!prevProps.isOpen && this.props.isOpen) {
      this.setState(
        () => ({
          postImage: null,
          postImageInputTouched: false,
          postImagePreviewUrl: null,
        }),
        () => {
          this.props.postAddReset();
        },
      );
    }

    if (!prevProps.postAddData && this.props.postAddData) {
      this.props.onSuccess(this.props.postAddData);
    }

    if (!prevProps.postAddError && this.props.postAddError) {
      this.props.onError(this.props.postAddError);
    }
  }

  render() {
    const {
      isOpen,
      onAfterOpen,
      onCloseRequest,
      className,
      postAddData,
      postAddIsLoading,
      postAddError,
      postAddReset,
      createPost,
      ...rest
    } = this.props;

    return (
      <Modal
        contentLabel="Create a new post"
        isOpen={isOpen}
        onAfterOpen={onAfterOpen}
        onCloseRequest={onCloseRequest}
        className={this.getClassName()}
        {...rest}>
        {isOpen ? (
          <Formik {...this.getFormikProps()}>
            {props => this.renderInnerForm(props)}
          </Formik>
        ) : null}
      </Modal>
    );
  }

  renderInnerForm(props: FormikComputedProps) {
    const { touched, errors, handleSubmit } = props;

    return (
      <form className="modal-new-post__inner-form" onSubmit={handleSubmit}>
        <div className="row pb-3">
          <div className="col-12 text-center pb-2 d-block d-md-none">
            <h5>New Post</h5>
          </div>
          <div className="col-12 col-md-4 pb-4 pb-md-0 text-center text-md-left modal-new-post__content-image">
            <div
              className={
                this.state.postImageError && this.state.postImageInputTouched
                  ? 'modal-new-post__content-image modal-new-post__content-image--error'
                  : 'modal-new-post__content-image'
              }>
              <img
                className="modal-new-post__image-edit-icon"
                src="/images/icon-edit-pic.png"
                onClick={this.openPostImageFileInput}
              />
              <img
                src={
                  this.state.postImagePreviewUrl || '/images/image_empty.jpg'
                }
                alt=""
                className="modal-new-post__image"
                onClick={this.openPostImageFileInput}
              />
              <small
                className={
                  this.state.postImageError && this.state.postImageInputTouched
                    ? 'pl-1 f-nunito modal-new-post__content-image-error-disclaimer'
                    : 'pl-1 f-nunito'
                }>
                JPG, PNG, BMP - Maximum size 50 MB
              </small>
            </div>
          </div>
          <div className="col-12 col-md-8">
            <div className="row">
              <div className="col-12 pl-md-1 pl-lg-0">
                <Input
                  className="mb-4"
                  label=""
                  placeholder="Title"
                  name="title"
                  type="text"
                  size="small"
                  error={touched.title && errors.title ? errors.title : ''}
                />
                <TextArea
                  label=""
                  placeholder="Content"
                  name="content"
                  error={
                    touched.content && errors.content ? errors.content : ''
                  }
                />
              </div>
            </div>
          </div>
        </div>
        <div className="row">
          <div className="col-2 col-md-1 py-4 py-md-2 order-2 order-md-1">
            <Toggle
              name="allowComments"
              error={
                touched.allowComments && errors.allowComments
                  ? errors.allowComments
                  : ''
              }
            />
          </div>
          <div className="text-lg-left col-10 col-md-3 pl-3 pl-sm-0 pl-md-2 py-4 py-md-2 order-3 order-md-2">
            <span className="allow-comments">Allow Comments</span>
          </div>
          <div className="col-md-4 order-1 order-md-3">
            <div className="row">
              <div className="col-12 col-md-3 pt-2 pr-0">Post to</div>
              <div className="col-12 col-md-9">
                <Select
                  label=""
                  size="small"
                  name="postTo"
                  options={this.getSelectOptions()}
                  error={touched.postTo && errors.postTo ? errors.postTo : ''}
                />
              </div>
            </div>
          </div>
          <div className="col-md-4 text-right order-4">
            <div className="row">
              <div className="col-6 px-1 pl-3 d-block d-md-none">
                <Button
                  size="small"
                  loading={this.props.postAddIsLoading}
                  type="button"
                  onClick={this.handleCloseRequest}
                  buttonType="outline"
                  block>
                  Cancel
                </Button>
              </div>
              <div className="col-6 pt-2 d-none d-md-block">
                <FileInput
                  className="d-none"
                  type="icon-only"
                  onChange={this.handleFileChange}
                  ref={this.setFileInputRef}
                />
              </div>
              <div className="col-6 pl-1 pr-3">
                <Button
                  size="small"
                  loading={this.props.postAddIsLoading}
                  type="button"
                  onClick={this.submitForm}
                  buttonType="primary"
                  block
                  noBorder>
                  Post Now
                </Button>
              </div>
            </div>
          </div>
        </div>
      </form>
    );
  }

  getClassName() {
    return classNames({
      'modal-new-post': true,
      [this.props.className || '']: this.props.className,
    });
  }

  getFormikProps() {
    return {
      initialValues: this.getFormikInitialValues(),
      validationSchema: yup.object().shape(this.getValidationSchema()),
      onSubmit: this.handleFormSubmit,
      ref: this.setFormikRef,
    };
  }

  getValidationSchema() {
    let schema: any = {
      content: yup.string().test('content', 'This field is required', value => {
        let valid = true;
        if (
          (value === undefined || value.trim() === '') &&
          (this.state.postImage === null ||
            this.state.postImage.type === null ||
            this.state.postImage.type === '')
        ) {
          valid = false;
        }
        return valid;
      }),
      postTo: yup.string().required('This field is required'),
    };

    return schema;
  }

  getFormikInitialValues() {
    const postTo =
      this.props.groupId && this.props.groupName
        ? this.props.groupId.toString()
        : 'all';

    return {
      title: '',
      content: '',
      postTo,
      allowComments: '1',
    };
  }

  getSelectOptions() {
    if (this.props.groupId && this.props.groupName) {
      return [
        { label: this.props.groupName, value: this.props.groupId.toString() },
      ];
    }

    const groups =
      (this.props.accountGroupsData && this.props.accountGroupsData.groups) ||
      [];

    return [{ label: 'All followers', value: 'all' }].concat(
      groups.map(group => ({ label: group.name, value: group.id })),
    );
  }

  setFormikRef = (ref: Formik) => {
    this.formikForm = ref;
  };

  setFileInputRef = (ref: any) => {
    this.fileInput = ref;
  };

  openPostImageFileInput = () => {
    this.fileInput.inputRef.click();
  };

  handleCloseRequest() {
    if (this.props.onCloseRequest) {
      this.props.onCloseRequest();
    }
  }

  handleFileChange = (file: FileList) => {
    if (file[0]) {
      this.setState(() => ({
        postImage: file[0],
        postImageError: false,
        postImageInputTouched: false,
        postImagePreviewUrl: URL.createObjectURL(file[0]),
      }));
    } else {
      this.setState(() => ({
        postImage: null,
        postImageError: true,
        postImageInputTouched: true,
        postImagePreviewUrl: null,
      }));
    }
  };

  submitForm = () => {
    // Manually triggering touch before sending the form
    this.formikForm.setTouched({
      title: true,
      content: true,
      postTo: true,
      allowComments: true,
    });

    this.setState(
      () => ({ postImageInputTouched: true }),
      () => {
        if (!this.state.postImage) {
          const component = this;
          const xhr = new XMLHttpRequest();
          let blob = null;

          xhr.open('GET', '/images/default_post_img.png');
          xhr.responseType = 'blob';

          xhr.onload = function() {
            blob = xhr.response;
            component.setState(() => ({
              postImage: new File([blob], blob),
            }));
            component.formikForm.submitForm();
          };

          xhr.send();
        } else {
          this.formikForm.submitForm();
        }
      },
    );
  };

  handleFormSubmit = (values: FormValues) => {
    let postImage = this.state.postImage;
    this.setState(
      () => ({ postImageInputTouched: true }),
      () => {
        this.props.createPost({
          postImage: postImage.type ? postImage : '',
          ...values,
        });
      },
    );
  };
}

const mapStateToProps = (state, ownProps) => {
  return {
    ...postAdd.mapStateToProps(state),
    ...accountGroups.mapStateToProps(state),
    ...ownProps,
  };
};

const mapDispatchToProps = dispatch => {
  return {
    ...postAdd.mapDispatchToProps(dispatch),
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(ModalNewPost);
