// @flow
import React from 'react';
import './AboutPage.scss';

const AboutPage = () => (
  <div className="about">
    <section className="content">
      <div className="container-fluid fix-width">
        <div className="row justify-content-md-center">
          <div className="col-lg-8 col-md-9 pr-8-md">
            <h1>About Us</h1>
            <p>
              Prytany was founded by one Democrat, one Republican, and one
              Independent with a simple objective: to organize the world of
              political engagement and ensure that everyone’s voice is heard.
            </p>
            <p>
              Today’s political environment is increasingly polarized, in part,
              because existing tools of political engagement divide people,
              instead of unite them.
            </p>
            <p>
              Prytany is different. Our goal is to bring together people of all
              political perspectives interested in learning about, directly
              engaging with and contributing to candidates and causes across the
              spectrum. How? We’ve created a quick, one-stop donation platform
              that connects users to political and issue advocacy campaigns of
              all shapes and sizes.
            </p>
            <p>
              We have engineered a brand new platform for people to educate
              themselves about candidates from all parties, without the spin.
              We’ve also created a place where friends and neighbors can look at
              all sides of an ideological issue, and decide for themselves how
              they want their voice to be heard, without the fear of fake
              accounts and fake bots.
            </p>
            <p>
              By now you’re wondering where we got the name. The original
              Prytany was a feature of the ancient Athenian Senate. It was a
              rotational executive body of the legislature.
            </p>
            <p>
              Today’s Prytany would be similar to a rotational U.S. President.
              While that idea isn’t practical given the realities of our modern
              democracy, it is important to remember that a core characteristic
              of a healthy republic is governance by the people, for the people.
              Ours is an effort to facilitate that ideal.
            </p>
          </div>
        </div>
      </div>
    </section>
  </div>
);

export default AboutPage;
