import _cloneDeep from 'lodash/cloneDeep';
import {
  CONTRIBUTION_DETAILS_SUCCESS,
  CONTRIBUTION_DETAILS_RESET,
  CONTRIBUTION_DETAILS_LOADING,
  CONTRIBUTION_DETAILS_ERROR,
  CONTRIBUTION_DETAILS_CHANGE_PLEDGE_CLAIM_STATUS,
} from './contributionDetails.constants';
import makeDefaultServerCallReducer from '../../utilities/makeDefaultServerCallReducer';

const initialState = {
  isLoading: false,
  error: null,
  data: null,
};

const changePledgeClaimStatus = (state, action) => {
  if (state.data) {
    const data = _cloneDeep(state.data);

    if (data.pledge && data.pledge.id === action.payload.pledgeId) {
      data.pledge.claim_status = action.payload.claimStatus;
    }

    return {
      ...state,
      data,
    };
  }

  return state;
};

export const reducer = makeDefaultServerCallReducer({
  loadingActions: [CONTRIBUTION_DETAILS_LOADING],
  successActions: [CONTRIBUTION_DETAILS_SUCCESS],
  errorActions: [CONTRIBUTION_DETAILS_ERROR],
  resetActions: [CONTRIBUTION_DETAILS_RESET],
  otherActions: [
    {
      name: CONTRIBUTION_DETAILS_CHANGE_PLEDGE_CLAIM_STATUS,
      callback: changePledgeClaimStatus,
    },
  ],
  initialState,
});
