// @flow
import React from 'react';
import { Formik, FormikComputedProps } from 'formik';
import { connect } from 'react-redux';
import { Scrollbars } from 'react-custom-scrollbars';
import * as yup from 'yup';
import _get from 'lodash/get';
import _throttle from 'lodash/throttle';
import classNames from 'classnames';
import Modal from '../Modal/Modal';
import Input from '../Input/Input';
import FileInput from '../FileInput/FileInput';
import Toggle from '../Toggle/Toggle';
import Checkboxes from '../Checkboxes/Checkboxes';
import Button from '../Button/Button';
import Table from '../Table/Table';
import { instance as rorGroupsApiProvider } from '../../services/RoRGroupsApiProvider';
import listInterests from '../../redux/modules/listInterests/listInterests.containers';
import listUsers from '../../redux/modules/userList/userList.containers';
import accountGroups from '../../redux/modules/accountGroups/accountGroups.containers';
import listCandidates from '../../redux/modules/listCandidates/listCandidates.containers';
import account from '../../redux/modules/account/account.containers';
import {
  UserListMapStateToProps,
  UserListMapDispatchToProps,
} from '../../redux/modules/userList/userList.containers';
import './ModalNewGroup.scss';
import type { AccountMapStateToProps } from '../../redux/modules/account/account.containers';
import { allowedGroupType, isCitizen } from '../../utilities/authorization';
import capitalize from 'lodash/capitalize';
import GroupType from '../../constants/GroupType';
import first from 'lodash/first';
import last from 'lodash/last';
import Avatar from '../Avatar/Avatar';
import Currency from '../Currency/Currency';
import isPrytanyVerified from '../../utilities/isPrytanyVerified';
import type { UserListItem } from '../../services/RoRUsersApiProvider';

const GROUP_INFO_STEP = 'Group Info';
const SELECT_MEMBERS_STEP = 'Select Members';
const SELECT_CANDIDATES_STEP = 'Select Candidates';

type GroupStep = 'Group Info' | 'Select Members' | 'Select Candidates';

type Props = AccountMapStateToProps &
  UserListMapStateToProps &
  UserListMapDispatchToProps & {
    isOpen: boolean,
    onAfterOpen?: Function,
    onCloseRequest?: Function,
    className?: string,
    listInterestsData: any,
    listInterestsError: any,
    listInterestsIsLoading: boolean,
    onSuccess: Function,
    onError: Function,
    preselectedUsers: any[],
    getListInterests: Function,
    accountGroupsSetAsFirst: Function,
  };

type State = {
  groupImage: File | null,
  groupImageInputTouched: boolean,
  groupImagePreviewUrl: string | null,
  tableSelectedRows: any[],
  tableSelectedRowKeys: number[],
  isCreatingGroup: boolean,
  userSearch: string,

  candidatesTableSelectedRows: any[],
  candidatesTableSelectedRowKeys: number[],
  candidatesTableTotalResults: number,
  candidatesTableCurrentPage: number,
  candidateSearch: string,
  steps: GroupStep[],
  currentStep: GroupStep,
  formikFormValues: { [key: string]: any },
};

type FormValues = {
  title: string,
  content: string,
  postTo: string,
  allowComments: string,
};

const USER_TABLE_PAGE_LIMIT = 5;

class ModalNewGroup extends React.Component<Props, State> {
  formikForm: Formik;

  constructor(props: Props) {
    super(props);

    const { user } = props.accountData || {};
    const groupType = allowedGroupType(user);
    const steps = [GROUP_INFO_STEP, SELECT_MEMBERS_STEP];
    if (groupType === GroupType.GROUP) {
      steps.push(SELECT_CANDIDATES_STEP);
    }

    this.state = {
      groupImage: null,
      groupImageInputTouched: false,
      groupImagePreviewUrl: null,
      tableSelectedRows: this.props.preselectedUsers,
      tableSelectedRowKeys: this.props.preselectedUsers.map(row => row.key),
      isCreatingGroup: false,
      userSearch: '',
      candidatesTableSelectedRows: [],
      candidatesTableSelectedRowKeys: [],
      isCreatingGroup: false,
      candidatesTableTotalResults: 0,
      candidatesTableCurrentPage: 0,
      candidateSearch: '',
      steps: steps,
      currentStep: first(steps),
      formikFormValues: {},
    };
  }

  getGroupType = () => {
    const { user } = this.props.accountData || {};
    return allowedGroupType(user);
  };

  componentDidUpdate(prevProps: Props) {
    if (!prevProps.isOpen && this.props.isOpen) {
      this.resetComponentState();
    }
  }

  componentDidMount() {
    this.fetchCandidates();
  }

  render() {
    const {
      isOpen,
      onAfterOpen,
      onCloseRequest,
      className,
      ...rest
    } = this.props;

    return (
      <Modal
        contentLabel="Create a new post"
        isOpen={isOpen}
        onAfterOpen={onAfterOpen}
        onCloseRequest={onCloseRequest}
        className={this.getClassName()}
        head={`Create New ${capitalize(this.getGroupType())}`}
        noPaddingContent
        size="small"
        {...rest}>
        {isOpen ? this.renderCurrentStep() : null}
      </Modal>
    );
  }

  renderCurrentStep() {
    const { currentStep } = this.state;
    let screen = null;

    if (currentStep === GROUP_INFO_STEP) {
      screen = this.renderFirstStep();
    } else if (currentStep === SELECT_MEMBERS_STEP) {
      screen = this.renderSecondStep();
    } else if (currentStep === SELECT_CANDIDATES_STEP) {
      screen = this.renderThirdStep();
    }

    return screen;
  }

  renderFirstStep() {
    return (
      <Formik {...this.getFormikProps()}>
        {props => this.renderFirstStepForm(props)}
      </Formik>
    );
  }

  renderFirstStepForm(props: FormikComputedProps) {
    const { touched, errors, handleSubmit, values } = props;
    const imageError =
      this.state.groupImageInputTouched && !this.state.groupImage
        ? 'A group image is required'
        : '';
    const interests =
      this.props.listInterestsData && this.props.listInterestsData.interests
        ? this.props.listInterestsData.interests
        : [];

    const groupTypeLabel = capitalize(this.getGroupType());

    return (
      <form className="modal-new-group__inner-form" onSubmit={handleSubmit}>
        <div className="modal-new-group__body">
          <div className="row">
            <div className="col-12">
              <div className="modal-new-group__form-fields-wrapper">
                <div className="mb-3">
                  <p>{groupTypeLabel} Name</p>
                </div>
                <Input
                  className="mb-4"
                  label=""
                  placeholder=""
                  name="name"
                  type="text"
                  size="small"
                  error={touched.name && errors.name ? errors.name : ''}
                />
                <div className="mb-3">
                  <p>{groupTypeLabel} Image</p>
                </div>
                <FileInput
                  className="mb-2"
                  error={imageError}
                  onChange={this.handleFileChange}
                  size="small"
                />
                <div className="mb-4">
                  <small className="f-nunito">
                    JPG, PNG, BMP - Maximum size 50 MB
                  </small>
                </div>
                <div className="mb-3">
                  <p>{groupTypeLabel} Description</p>
                </div>
                <Input
                  className="mb-4"
                  label=""
                  placeholder=""
                  name="description"
                  type="text"
                  size="small"
                  error={
                    touched.description && errors.description
                      ? errors.description
                      : ''
                  }
                />
                <div className="mb-3">
                  <p>Select points of interests</p>
                </div>
                <Scrollbars universal style={{ height: 180 }}>
                  <Checkboxes
                    checkboxWrapperClassName="pb-3 modal-new-group__checkbox"
                    items={interests.map(interest => ({
                      label: interest.name,
                      value: interest.id.toString(),
                    }))}
                    name="interests"
                    error={
                      touched.interests && errors.interests
                        ? errors.interests
                        : ''
                    }
                  />
                </Scrollbars>
              </div>
            </div>
          </div>
        </div>
        <div className="modal-new-group__footer">
          <div className="row">
            <div className="col-12 col-md-6 justify-content-center">
              <div className="modal-new-group__public-toggle">
                <Toggle
                  className="modal-new-group__public-toggle-control"
                  name="public"
                  error={touched.public && errors.public ? errors.public : ''}
                />
                <span className="modal-new-group__public-toggle-label">
                  Public
                </span>
                <small className="modal-new-group__public-toggle-desc">
                  - all users can see posts
                </small>
              </div>
              <div className="modal-new-group__open-toggle">
                <Toggle
                  className="modal-new-group__open-toggle-control"
                  name="open"
                  error={touched.open && errors.open ? errors.open : ''}
                />
                <span className="modal-new-group__open-toggle-label">Open</span>
                <small className="modal-new-group__open-toggle-desc">
                  - anyone can join
                </small>
              </div>
            </div>
            <div className="col-12 col-md-6 text-right order-4">
              <div className="row justify-content-end">
                <div className="col-4">
                  <Button
                    loading={this.state.isCreatingGroup}
                    type="button"
                    onClick={this.handleCloseRequest}
                    buttonType="link"
                    noBorder
                    className="modal-new-group__cancel-button">
                    Cancel
                  </Button>
                </div>
                <div className="col-4">
                  <Button
                    loading={this.state.isCreatingGroup}
                    onClick={() => this.handleStepOneSubmit(values)}
                    buttonType="primary"
                    block
                    noBorder
                    type="button">
                    Next
                  </Button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </form>
    );
  }

  renderSecondStep() {
    return (
      <React.Fragment>
        <div className="modal-new-group__body">
          <div className="modal-new-group__table-wrapper">
            <div className="row pb-3">
              <div className="col-6 d-flex align-items-center">
                <h4 className="fs-13">
                  Select {capitalize(this.getGroupType())} Members
                </h4>
              </div>
              <Input
                className="col-6 px-0 mb-0"
                size="small"
                label=""
                placeholder=""
                name="userSearch"
                value={this.state.userSearch}
                type="text"
                icon="/images/icon_lens.png"
                useFormik={false}
                onChange={_throttle(this.handleFetchUsers, 750)}
              />
            </div>
            <Scrollbars universal style={{ height: '350px' }}>
              <Table {...this.getTableProps()} />
            </Scrollbars>
          </div>
        </div>
        {this.renderModalFooter()}
      </React.Fragment>
    );
  }

  renderThirdStep() {
    return (
      <React.Fragment>
        <div className="modal-new-group__body">
          <div className="modal-new-group__table-wrapper">
            <div className="row pb-3">
              <div className="col-6 d-flex align-items-center">
                <h4 className="fs-13">Select Candidates to Support</h4>
              </div>
              <Input
                className="col-6 px-0 mb-0"
                size="small"
                label=""
                placeholder=""
                name="candidateSearch"
                value={this.state.candidateSearch}
                type="text"
                icon="/images/icon_lens.png"
                useFormik={false}
                onChange={_throttle(this.handleFetchCandidates, 750)}
              />
            </div>
            <Scrollbars universal style={{ height: '350px' }}>
              <Table {...this.getCandidatesTableProps()} />
            </Scrollbars>
          </div>
        </div>
        {this.renderModalFooter()}
      </React.Fragment>
    );
  }

  renderModalFooter() {
    const { steps, currentStep } = this.state;

    return (
      <div className="modal-new-group__footer">
        <div className="row">
          <div className="col-3 p-3">
            {this.state.currentStep !== first(steps) && (
              <Button
                disabled={this.state.isCreatingGroup}
                type="button"
                onClick={this.goToPreviousStep}
                buttonType="link"
                block
                noBorder>
                &lt; Back
              </Button>
            )}
          </div>
          <div className="offset-2 col-3 p-3">
            <Button
              disabled={this.state.isCreatingGroup}
              type="button"
              buttonType="outline"
              onClick={this.handleCloseRequest}
              block
              noBorder>
              Cancel
            </Button>
          </div>
          <div className="col-4 p-3">
            <Button
              loading={this.state.isCreatingGroup}
              type="button"
              onClick={
                this.state.currentStep === last(steps)
                  ? this.handleCreateGroupRequest
                  : this.goToNextStep
              }
              buttonType="primary"
              block
              noBorder
              type="button">
              {currentStep === last(steps)
                ? `Create ${capitalize(this.getGroupType())}`
                : 'Next'}
            </Button>
          </div>
        </div>
      </div>
    );
  }

  resetComponentState() {
    this.setState(
      prevState => ({
        isCreatingGroup: false,
        groupImage: null,
        groupImageInputTouched: false,
        groupImagePreviewUrl: null,
        tableSelectedRows: this.props.preselectedUsers,
        tableSelectedRowKeys: this.props.preselectedUsers.map(row => row.key),
        currentStep: first(prevState.steps),
        userSearch: '',
        formikFormValues: {},
        candidatesTableSelectedRows: [],
        candidatesTableSelectedRowKeys: [],
        isCreatingGroup: false,
        candidatesTableTotalResults: 0,
        candidatesTableCurrentPage: 0,
        candidateSearch: '',
      }),
      () => {
        this.props.getListInterests();
        this.fetchUsers();
        this.fetchCandidates();
      },
    );
  }

  fetchUsers(
    page: number = 1,
    name: string = '',
    limit: number = USER_TABLE_PAGE_LIMIT,
  ) {
    this.props.getUserList({ page, name, limit });
  }

  handleFetchUsers = (e: { name: string, value: string }) => {
    this.setState(
      () => ({ [e.name]: e.value }),
      () => this.fetchUsers(1, this.state.userSearch),
    );
  };

  fetchCandidates(
    page: number = 1,
    name: string = '',
    limit: number = USER_TABLE_PAGE_LIMIT,
  ) {
    this.props.getCandidatesList({ page, name, limit });
  }

  handleFetchCandidates = (e: { name: string, value: string }) => {
    this.setState(
      () => ({ [e.name]: e.value }),
      () => this.fetchCandidates(1, this.state.candidateSearch),
    );
  };

  getClassName() {
    return classNames({
      'modal-new-group': true,
      [this.props.className || '']: this.props.className,
    });
  }

  getFormikProps() {
    return {
      initialValues: this.getFormikInitialValues(),
      validationSchema: yup.object().shape(this.getValidationSchema()),
      onSubmit: this.goToNextStep,
      ref: this.setFormikRef,
    };
  }

  getValidationSchema() {
    let schema: any = {
      name: yup.string().required('This field is required'),
      description: yup.string().required('This field is required'),
      interests: yup
        .array()
        .of(yup.string())
        .min(1, 'Select at least one interest from the list'),
    };

    return schema;
  }

  getFormikInitialValues() {
    const { formikFormValues } = this.state;

    return {
      name: formikFormValues.name || '',
      description: formikFormValues.description || '',
      interests: formikFormValues.interests || [],
      public: formikFormValues.public || '1',
      open: formikFormValues.open || '0',
    };
  }

  getTableProps() {
    const rowSelection = {
      onChange: this.handleTableRowSelection,
      getCheckboxProps: (record: any) => ({
        disabled: false,
        name: record.key,
      }),
      selectedRowKeys: this.state.tableSelectedRowKeys,
    };

    // TODO: enable sorting once the server supports it
    return {
      rowSelection,
      pagination: this.props.userListData
        ? {
            total: this.props.userListData.total_pages,
            current: this.props.userListData.current_page,
          }
        : { total: 0, current: 0 },
      dataSource: this.getDataSourceUsers(),
      columns: this.getTableColumns().map((column: any) => ({
        ...column,
        sorter: null,
      })),
      onChange: this.handleTableChange,
      loading: this.props.userListIsLoading,
    };
  }

  getTableColumns() {
    return [
      {
        title: 'NAME',
        dataIndex: 'name',
        key: 'name',
        defaultSortOrder: 'ascend',
        render: (text: string, item: any) => {
          return (
            <React.Fragment>
              <Avatar
                isCandidate={item.role === 'candidate'}
                verifiedLevel={item.verifiedLevel}
                verifiedDetail={item.verifiedDetail}
                className="dashboard-page__table-cell__name-avatar"
                source={item.profileImage}
                type="tiny"
              />
              <span className="dashboard-page__table-cell__name-text">
                {text}
              </span>
            </React.Fragment>
          );
        },
        sorter: (a: any, b: any) => a.name.localeCompare(b.name),
      },
      {
        title: 'STATE',
        dataIndex: 'state',
        key: 'state',
        sorter: (a: any, b: any) => a.state.localeCompare(b.state),
      },
      {
        title: 'SHARED INTERESTS',
        dataIndex: 'sharedInterests',
        key: 'sharedInterests',
        sorter: (a: any, b: any) => a.sharedInterests - b.sharedInterests,
      },
      {
        title: 'CONTRIBUTED',
        dataIndex: 'contributed',
        key: 'contributed',
        sorter: (a: any, b: any) => a.contributed - b.contributed,
        render: (amount: number) => <Currency amount={amount / 100} />,
      },
    ];
  }

  getCandidatesTableProps() {
    const rowSelection = {
      onChange: this.handleCandidatesTableRowSelection,
      getCheckboxProps: (record: any) => ({
        disabled: false,
        name: record.key,
      }),
      selectedRowKeys: this.state.candidatesTableSelectedRowKeys,
    };

    // TODO: enable sorting once the server supports it
    return {
      rowSelection,
      pagination: this.props.candidatesListData
        ? {
            total: this.props.candidatesListData.total_pages,
            current: this.props.candidatesListData.current_page,
          }
        : { total: 0, current: 0 },
      dataSource: this.getDataSourceCandidates(),
      columns: this.getCandidatesTableColumns().map((column: any) => ({
        ...column,
        sorter: null,
      })),
      onChange: this.handleCandidatesTableChange,
      loading: this.props.candidatesListIsLoading,
    };
  }

  getCandidatesTableColumns() {
    return [
      {
        title: 'NAME',
        dataIndex: 'name',
        key: 'name',
        defaultSortOrder: 'ascend',
        render: (text: string, item: any) => {
          return (
            <React.Fragment>
              <Avatar
                isCandidate={true}
                className="dashboard-page__table-cell__name-avatar"
                source={item.profileImage}
                type="tiny"
              />
              <span className="dashboard-page__table-cell__name-text">
                {text}
              </span>
            </React.Fragment>
          );
        },
        sorter: (a: any, b: any) => a.name.localeCompare(b.name),
      },
      {
        title: 'CONTRIBUTED',
        dataIndex: 'contributed',
        key: 'contributed',
        sorter: (a: any, b: any) => a.contributed - b.contributed,
        render: (amount: number) => <Currency amount={amount / 100} />,
      },
    ];
  }

  getDataSourceUsers = () => {
    let users = _get(this.props, 'userListData.data.users', []);

    return users.map((user: UserListItem) => ({
      key: user.id,
      name: `${user.first_name} ${user.last_name}`.trim(),
      state: 'TBD',
      profileImage: user.profile_image,
      sharedInterests: 0, // TODO: server should support shared_interests on user request
      contributed: user.donations ? user.donations.contributed : 0, // TODO: server should support shared_interests on user request
      role: user.role.code,
      verifiedLevel: user.verified_level,
      verifiedDetail: user.verified_detail,
    }));
  };

  getDataSourceCandidates = () => {
    let candidates = _get(this.props, 'candidatesListData.data.users', []);

    return candidates.map((candidate: UserListItem) => ({
      key: candidate.id,
      name: `${candidate.first_name} ${candidate.last_name}`.trim(),
      profileImage: candidate.profile_image,
      contributed: candidate.donations ? candidate.donations.contributed : 0, // TODO: server should support shared_interests on user request
      role: candidate.role.code,
    }));
  };

  goToPreviousStep = () => {
    this.setState((prevState: State) => {
      const { steps, currentStep } = prevState;
      const currentIndex = steps.indexOf(currentStep);
      const newIndex = currentIndex > 0 ? currentIndex - 1 : currentIndex;

      const newState: any = {
        currentStep: steps[newIndex],
      };

      if (newIndex === 0) {
        newState.groupImage = null;
        newState.groupImageInputTouched = false;
        newState.groupImagePreviewUrl = null;
      }

      return newState;
    });
  };

  goToNextStep = () => {
    this.setState((prevState: State) => {
      const { steps, currentStep } = prevState;
      const currentIndex = steps.indexOf(currentStep);
      const newIndex =
        currentIndex < steps.length - 1 ? currentIndex + 1 : currentIndex;
      return {
        currentStep: steps[newIndex],
      };
    });
  };

  handleTableChange = (pagination: any, filters: any, sorter: any) => {
    // TODO: add sorting logic once the server supports it
    this.fetchUsers(pagination.current, this.state.userSearch);
  };

  handleCandidatesTableChange = (
    pagination: any,
    filters: any,
    sorter: any,
  ) => {
    // TODO: add sorting logic once the server supports it
    this.fetchCandidates(pagination.current, this.state.candidateSearch);
  };

  setFormikRef = (ref: Formik) => {
    this.formikForm = ref;
  };

  handleTableRowSelection = (
    selectedRowKeys: number[],
    selectedRows: any[],
  ) => {
    this.setState(() => ({
      tableSelectedRows: selectedRows,
      tableSelectedRowKeys: selectedRowKeys,
    }));
  };

  handleCandidatesTableRowSelection = (
    selectedRowKeys: number[],
    selectedRows: any[],
  ) => {
    this.setState(() => ({
      candidatesTableSelectedRows: selectedRows,
      candidatesTableSelectedRowKeys: selectedRowKeys,
    }));
  };

  handleFileChange = (file: FileList) => {
    if (file[0]) {
      this.setState(() => ({
        groupImage: file[0],
        groupImagePreviewUrl: URL.createObjectURL(file[0]),
      }));
    }
  };

  handleStepOneSubmit = (formikFormValues: FormValues) => {
    //  Manually triggering touch before sending the form
    this.formikForm.setTouched({
      name: true,
      description: true,
      interests: true,
      public: true,
      open: true,
    });

    this.setState(
      () => ({ groupImageInputTouched: true, formikFormValues }),
      () => {
        if (this.state.groupImage) {
          this.formikForm.submitForm();
        }
      },
    );
  };

  handleCreateGroupRequest = () => {
    this.setState(
      () => ({ isCreatingGroup: true }),
      () => {
        const payload = this.parseFormData({
          groupImage: this.state.groupImage,
          ...this.state.formikFormValues,
        });

        rorGroupsApiProvider
          .groupCreate(payload)
          .then(response => {
            this.props.accountGroupsSetAsFirst(response.group);
            if (this.props.onSuccess) {
              this.props.onSuccess(response);
            }
          })
          .catch(error => {
            if (this.props.onError) {
              this.props.onError(error);
            }
          });
      },
    );
  };

  parseFormData(firstStepPayload: any) {
    return {
      name: firstStepPayload.name,
      description: firstStepPayload.description,
      group_image: firstStepPayload.groupImage,
      public_group: firstStepPayload.public,
      open_group: firstStepPayload.open,
      entity_interests_attributes: firstStepPayload.interests.map(
        (interestId: any) => ({ interest_id: parseInt(interestId) }),
      ),
      group_members_attributes: this.state.tableSelectedRowKeys.map(
        (userId: number) => ({ user_id: parseInt(userId) }),
      ),
      group_candidates_attributes: this.state.candidatesTableSelectedRowKeys.map(
        (candidateId: number) => ({ candidate_id: parseInt(candidateId) }),
      ),
    };
  }

  handleCloseRequest = () => {
    if (this.props.onCloseRequest) {
      this.props.onCloseRequest();
    }
  };
}

const mapStateToProps = (state, ownProps) => {
  return {
    ...account.mapStateToProps(state),
    ...listInterests.mapStateToProps(state),
    ...listUsers.mapStateToProps(state),
    ...listCandidates.mapStateToProps(state),
    ...ownProps,
  };
};

const mapDispatchToProps = dispatch => {
  return {
    ...listInterests.mapDispatchToProps(dispatch),
    ...accountGroups.mapDispatchToProps(dispatch),
    ...listUsers.mapDispatchToProps(dispatch),
    ...listCandidates.mapDispatchToProps(dispatch),
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(ModalNewGroup);
