import {
  ACCOUNT_VERIFY_SUCCESS,
  ACCOUNT_VERIFY_RESET,
  ACCOUNT_VERIFY_LOADING,
  ACCOUNT_VERIFY_ERROR,
} from './accountVerify.constants';
import makeDefaultServerCallReducer from '../../utilities/makeDefaultServerCallReducer';

const initialState = {
  isLoading: false,
  error: null,
  data: null,
};

export const reducer = makeDefaultServerCallReducer({
  loadingActions: [ACCOUNT_VERIFY_LOADING],
  successActions: [ACCOUNT_VERIFY_SUCCESS],
  errorActions: [ACCOUNT_VERIFY_ERROR],
  resetActions: [ACCOUNT_VERIFY_RESET],
  initialState,
});
