// @flow
import { createAction } from 'redux-actions';
import {
  USER_PROFILE_CONTRIBUTIONS_ERROR,
  USER_PROFILE_CONTRIBUTIONS_LOADING,
  USER_PROFILE_CONTRIBUTIONS_RESET,
  USER_PROFILE_CONTRIBUTIONS_SUCCESS,
} from './userProfileContributions.constants';
import { instance as rorProfileApiProvider } from '../../../services/RoRProfileApiProvider';
import type GetContributionsPayload from '../../../services/RoRProfileApiProvider';

export const userProfileContributionsError = createAction(
  USER_PROFILE_CONTRIBUTIONS_ERROR,
);

export const userProfileContributionsLoading = createAction(
  USER_PROFILE_CONTRIBUTIONS_LOADING,
);

export const userProfileContributionsSuccess = createAction(
  USER_PROFILE_CONTRIBUTIONS_SUCCESS,
);

export const userProfileContributionsReset = createAction(
  USER_PROFILE_CONTRIBUTIONS_RESET,
);

export const getUserProfileContributions = (payload: any) => {
  return async (dispatch: Function) => {
    try {
      dispatch(userProfileContributionsLoading());

      const response = await rorProfileApiProvider.getContributions(payload);

      dispatch(userProfileContributionsSuccess(response));
    } catch (error) {
      dispatch(userProfileContributionsError(error));
    }
  };
};
