// @flow
import React from 'react';
import queryString from 'query-string';
import { withRouter } from 'react-router-dom';
import type { ComponentType } from 'react';

export const MobileContributionsContext = React.createContext({});

export const MobileContributionsContextProvider = withRouter(
  ({ location, match, children }) => {
    const { email, token, group_id: groupId } = queryString.parse(
      location.search,
      { decode: true },
    );
    const {
      params: { userId },
    } = match;

    return (
      <MobileContributionsContext.Provider
        value={{
          mobileEmail: email,
          mobileToken: token,
          mobileGroupId: groupId,
          mobileUserId: userId,
        }}>
        {children}
      </MobileContributionsContext.Provider>
    );
  },
);

export const withMobileContributionsContext = (
  WrappedComponent: ComponentType<{}>,
) => {
  return (props: {}) => {
    return (
      <MobileContributionsContext.Consumer>
        {value => <WrappedComponent {...props} {...value} />}
      </MobileContributionsContext.Consumer>
    );
  };
};

export type withMobileContributionsContextProps = {
  mobileEmail: string,
  mobileToken: string,
  mobileGroupId: string,
  mobileUserId: string,
};
