// @flow
import { createAction } from 'redux-actions';
import {
  RACE_DETAILS_ERROR,
  RACE_DETAILS_LOADING,
  RACE_DETAILS_RESET,
  RACE_DETAILS_SUCCESS,
  RACE_DETAILS_SET_AS_FOLLOWED,
  RACE_DETAILS_SET_AS_UNFOLLOWED,
} from './raceDetails.constants';
import { instance as rorRacesApiProvider } from '../../../services/RoRRacesApiProvider';

export const raceDetailsError = createAction(RACE_DETAILS_ERROR);

export const raceDetailsLoading = createAction(RACE_DETAILS_LOADING);

export const raceDetailsSuccess = createAction(RACE_DETAILS_SUCCESS);

export const raceDetailsReset = createAction(RACE_DETAILS_RESET);

export const raceDetailsSetAsFollowed = createAction(
  RACE_DETAILS_SET_AS_FOLLOWED,
);

export const raceDetailsSetAsUnfollowed = createAction(
  RACE_DETAILS_SET_AS_UNFOLLOWED,
);

export const getRaceDetails = (payload: { raceId: number }) => {
  return async (dispatch: Function) => {
    try {
      dispatch(raceDetailsLoading());

      const detailsResponse = await rorRacesApiProvider.raceDetails(payload);
      const candidatesResponse = await rorRacesApiProvider.raceCandidates(
        payload,
      );

      dispatch(
        raceDetailsSuccess({
          ...detailsResponse,
          candidates: candidatesResponse,
        }),
      );
    } catch (error) {
      dispatch(raceDetailsError(error));
    }
  };
};
