// @flow
import React from 'react';
import { connect } from 'react-redux';
import Redirect from 'react-router-dom/Redirect';
import account from '../../redux/modules/account/account.containers';
import LoginForm from '../../components/LoginForm/LoginForm';
import { URL_LOGIN_PAGE } from '../../config/urls';
import type {
  AccountMapDispatchToProps,
  AccountMapStateToProps,
} from '../../redux/modules/account/account.containers';
import './LoginPage.scss';

type Props = AccountMapDispatchToProps & AccountMapStateToProps & {};

class LoginPage extends React.Component<Props> {
  constructor(props: Props) {
    super(props);

    if (
      this.props.accountError ||
      (this.props.accountLoginFirstStepData && !this.props.accountData)
    ) {
      this.props.accountReset();
    }
  }

  render() {
    return (
      <React.Fragment>
        {!this.props.accountData ? (
          this.renderContent()
        ) : (
          <Redirect
            to={{
              pathname: '/feed',
              state: { from: URL_LOGIN_PAGE },
            }}
          />
        )}
      </React.Fragment>
    );
  }

  renderContent() {
    return (
      <div className="login">
        <section className="content">
          <div className="container-fluid fix-width">
            <div className="row justify-content-md-center">
              <div className="col-lg-5 col-md-6 pr-5-md">
                <LoginForm />
              </div>
              <div className="col-lg-7 col-md-6 text-right d-none d-md-block img-white">
                <img src="/images/white_h.jpg" className="img-fluid" alt="" />
              </div>
            </div>
          </div>
        </section>
      </div>
    );
  }
}

const mapStateToProps = (state, ownProps) => {
  return {
    ...account.mapStateToProps(state),
    ...ownProps,
  };
};

const mapDispatchToProps = dispatch => {
  return {
    ...account.mapDispatchToProps(dispatch),
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(LoginPage);
