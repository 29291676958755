// @flow
import * as React from 'react';
import classNames from 'classnames';

import './UserRoleBadge.scss';

import iconCandidate from '../../../public/images/icon_candidate.png';
import iconCitizen from '../../../public/images/icon_citizen.png';
import iconPoliticalAffiliation from '../../../public/images/icon_political_affiliation.png';
import RoleCode from '../../constants/RoleCode';

const { CITIZEN, CANDIDATE } = RoleCode;

type Props = {
  role: 'candidate' | 'citizen' | 'political_affiliation',
  onClick?: Function,
  className?: string,
};

const icons = {
  candidate: iconCandidate,
  citizen: iconCitizen,
  political_affiliation: iconPoliticalAffiliation,
};

const titles = {
  citizen: 'Constituents',
  candidate: 'Candidates',
  political_affiliation: 'Political Affiliation',
};

const descriptions = {
  candidate:
    'Engage directly with verified users and grow grassroots momentum.',
  citizen: 'Join a movement and amplify your voice.',
  political_affiliation:
    'If you are acting on behalf of a National Party Committee or Political Action Committee, please contact us for support.',
};

const UserRoleBadge = (props: Props) => {
  const { role, onClick } = props;
  return (
    <button
      disabled={role === 'political_affiliation'}
      onClick={props.onClick}
      className={getClassName(props)}>
      <div className="user-role-badge__image-container">
        <img src={icons[role]} className="user-role-badge__image" alt="" />
        <h2 className="user-role-badge__title">{titles[role]}</h2>
      </div>
      <div className="user-role-badge__text-container">
        <p className="user-role-badge__text">{descriptions[role]}</p>
      </div>
      {(role === CITIZEN || role === CANDIDATE) && (
        <div className="oval m-auto rounded-circle d-flex">
          <div className="inner-oval m-auto rounded-circle align-self-center" />
        </div>
      )}
      {role === 'political_affiliation' && (
        <div className="user-role-badge__contact mx-auto text-center">
          <a href="mailto:contact@prytany.com">contact@prytany.com</a>
        </div>
      )}
    </button>
  );
};

function getClassName(props) {
  return classNames({
    'user-role-badge': true,
    [props.className || '']: props.className,
  });
}

export default UserRoleBadge;
