import {
  accountLogout as logout,
  doLogin as login,
  accountReset as reset,
  accountResetPassword,
  accountDeactivate,
} from './account.actions';
import type {
  PostLoginPayload,
  PostLoginResponse,
} from '../../../services/RoRApiProvider';

export const mapStateToProps = state => {
  return {
    accountData: state.account.data,
    accountError: state.account.error,
    accountIsLoading: state.account.isLoading,
  };
};

export const mapDispatchToProps = dispatch => {
  return {
    doLogin(payload: PostLoginPayload) {
      dispatch(login(payload));
    },
    accountLogout() {
      dispatch(logout());
    },
    accountReset() {
      dispatch(reset());
    },
    accountResetPassword(payload: { user: { email: string } }) {
      dispatch(accountResetPassword(payload));
    },
    accountDeactivate() {
      dispatch(accountDeactivate());
    },
  };
};

export type AccountMapStateToProps = {
  accountData: PostLoginResponse,
  accountLoginFirstStepData: PostLoginResponse,
  accountError: any,
  accountIsLoading: boolean,
};

export type AccountMapDispatchToProps = {
  doLogin: (payload: PostLoginPayload) => void,
  accountLogout: () => void,
  accountReset: () => void,
  accountResetPassword: (payload: { user: { email: string } }) => void,
  accountDeactivate: () => void,
};

export default {
  mapStateToProps,
  mapDispatchToProps,
};
