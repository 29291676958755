import {
  collaboratorsDelete,
  collaboratorsDeleteReset,
} from './collaboratorsDelete.actions';

export const mapStateToProps = state => {
  return {
    collaboratorsDeleteData: state.collaborators.error,
    collaboratorsDeleteIsLoading: state.collaborators.isLoading,
    collaboratorsDeleteError: state.collaborators.error,
  };
};

export const mapDispatchToProps = dispatch => {
  return {
    collaboratorsDelete(payload) {
      dispatch(collaboratorsDelete(payload));
    },
    collaboratorsDeleteReset() {
      dispatch(collaboratorsDeleteReset());
    },
  };
};

export type collaboratorsDeleteMapStateToProps = {
  collaboratorsDeleteError: any,
  collaboratorsDeleteIsLoading: boolean,
};

export type collaboratorsDeleteMapDispatchToProps = {
  collaboratorsDelete: Function,
  collaboratorsDeleteReset: Function,
};

export default {
  mapStateToProps,
  mapDispatchToProps,
};
