// @flow
import { createAction } from 'redux-actions';
import {
  CONTRIBUTION_PLEDGE_CLAIM_ERROR,
  CONTRIBUTION_PLEDGE_CLAIM_LOADING,
  CONTRIBUTION_PLEDGE_CLAIM_RESET,
  CONTRIBUTION_PLEDGE_CLAIM_SUCCESS,
} from './contributionPledgeClaim.constants';
import { instance as rorContributionsApiProvider } from '../../../services/RoRContributionsApiProvider';
import type { PostClaimPledgePayload } from '../../../services/RoRContributionsApiProvider';
import { contributionDetailsChangePledgeClaimStatus } from '../contributionDetails/contributionDetails.actions';

export const contributionPledgeClaimError = createAction(
  CONTRIBUTION_PLEDGE_CLAIM_ERROR,
);

export const contributionPledgeClaimLoading = createAction(
  CONTRIBUTION_PLEDGE_CLAIM_LOADING,
);

export const contributionPledgeClaimSuccess = createAction(
  CONTRIBUTION_PLEDGE_CLAIM_SUCCESS,
);

export const contributionPledgeClaimReset = createAction(
  CONTRIBUTION_PLEDGE_CLAIM_RESET,
);

export const claimPledge = (payload: PostClaimPledgePayload) => {
  return async (dispatch: Function) => {
    try {
      dispatch(contributionPledgeClaimLoading());

      const response = await rorContributionsApiProvider.claimPledge(payload);

      dispatch(contributionPledgeClaimSuccess(response));
      dispatch(
        contributionDetailsChangePledgeClaimStatus({
          pledgeId: payload.pledgeId,
          claimStatus: 'requested',
        }),
      );
    } catch (error) {
      dispatch(contributionPledgeClaimError(error));
    }
  };
};
