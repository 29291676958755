// @flow
import makeDefaultServerCallReducer from '../../utilities/makeDefaultServerCallReducer';
import {
  ACCOUNT_CANDIDATE_PLEDGES_ERROR,
  ACCOUNT_CANDIDATE_PLEDGES_LOADING,
  ACCOUNT_CANDIDATE_PLEDGES_SUCCESS,
  ACCOUNT_CANDIDATE_PLEDGES_RESET,
} from './accountCandidatePledges.constants';

const initialState = {
  isLoading: false,
  error: null,
  data: null,
};

export const reducer = makeDefaultServerCallReducer({
  loadingActions: [ACCOUNT_CANDIDATE_PLEDGES_LOADING],
  successActions: [ACCOUNT_CANDIDATE_PLEDGES_SUCCESS],
  errorActions: [ACCOUNT_CANDIDATE_PLEDGES_ERROR],
  resetActions: [ACCOUNT_CANDIDATE_PLEDGES_RESET],
  initialState,
});
