// @flow
import { createAction } from 'redux-actions';
import {
  ACCOUNT_INBOX_MESSAGE_DELETE_ERROR,
  ACCOUNT_INBOX_MESSAGE_DELETE_LOADING,
  ACCOUNT_INBOX_MESSAGE_DELETE_RESET,
  ACCOUNT_INBOX_MESSAGE_DELETE_SUCCESS,
} from './accountInboxMessageDelete.constants';
import { instance as rorUsersApiProvider } from '../../../services/RoRUsersApiProvider';
import type { GetUserInboxMessageDeletePayload } from '../../../services/RoRUsersApiProvider';
import { accountInboxMessageListRemoveItem } from '../accountInboxMessageList/accountInboxMessageList.actions';

export const accountInboxMessageDeleteError = createAction(
  ACCOUNT_INBOX_MESSAGE_DELETE_ERROR,
);

export const accountInboxMessageDeleteLoading = createAction(
  ACCOUNT_INBOX_MESSAGE_DELETE_LOADING,
);

export const accountInboxMessageDeleteSuccess = createAction(
  ACCOUNT_INBOX_MESSAGE_DELETE_SUCCESS,
);

export const accountInboxMessageDeleteReset = createAction(
  ACCOUNT_INBOX_MESSAGE_DELETE_RESET,
);

export const deleteAccountInboxMessage = (
  payload: GetUserInboxMessageDeletePayload,
) => {
  return async (dispatch: Function) => {
    try {
      dispatch(accountInboxMessageDeleteLoading());

      const response = await rorUsersApiProvider.accountInboxMessageDelete(
        payload,
      );

      dispatch(
        accountInboxMessageListRemoveItem({ messageId: payload.messageId }),
      );
      dispatch(accountInboxMessageDeleteSuccess(response));
    } catch (error) {
      dispatch(accountInboxMessageDeleteError(error));
    }
  };
};
