// @flow
import { createAction } from 'redux-actions';
import {
  ACCOUNT_INBOX_MESSAGE_DETAILS_ERROR,
  ACCOUNT_INBOX_MESSAGE_DETAILS_LOADING,
  ACCOUNT_INBOX_MESSAGE_DETAILS_RESET,
  ACCOUNT_INBOX_MESSAGE_DETAILS_SUCCESS,
  ACCOUNT_INBOX_MESSAGE_DETAILS_NEXT_PAGE,
} from './accountInboxMessageDetails.constants';
import { instance as rorUsersApiProvider } from '../../../services/RoRUsersApiProvider';
import type { GetUserInboxMessageDetailsPayload } from '../../../services/RoRUsersApiProvider';
import { accountInboxMessageListMarkAsReadItem } from '../accountInboxMessageList/accountInboxMessageList.actions';
import { clientStore } from '../../../redux/clientStore';
import _get from 'lodash/get';

export const accountInboxMessageDetailsError = createAction(
  ACCOUNT_INBOX_MESSAGE_DETAILS_ERROR,
);

export const accountInboxMessageDetailsLoading = createAction(
  ACCOUNT_INBOX_MESSAGE_DETAILS_LOADING,
);

export const accountInboxMessageDetailsSuccess = createAction(
  ACCOUNT_INBOX_MESSAGE_DETAILS_SUCCESS,
);

export const accountInboxMessageDetailsReset = createAction(
  ACCOUNT_INBOX_MESSAGE_DETAILS_RESET,
);

export const accountInboxMessageDetailsNextPage = createAction(
  ACCOUNT_INBOX_MESSAGE_DETAILS_NEXT_PAGE,
);

export const getAccountInboxMessageDetails = (
  payload: GetUserInboxMessageDetailsPayload,
) => {
  return async (dispatch: Function) => {
    try {
      dispatch(accountInboxMessageDetailsLoading());

      const response = await rorUsersApiProvider.accountInboxMessageDetails(
        payload,
      );

      dispatch(accountInboxMessageDetailsSuccess(response));
      dispatch(
        accountInboxMessageListMarkAsReadItem({ messageId: payload.messageId }),
      );
    } catch (error) {
      dispatch(accountInboxMessageDetailsError(error));
    }
  };
};

export const getAccountInboxMessageDetailsNextPage = (
  payload: GetUserInboxMessageDetailsPayload,
) => {
  return async (dispatch: Function) => {
    try {
      const nextPage = _get(
        clientStore.getState(),
        `accountInboxMessageDetails.data.next_page`,
      );

      if (nextPage) {
        dispatch(accountInboxMessageDetailsLoading());

        const response = await rorUsersApiProvider.accountInboxMessageDetails({
          ...payload,
          page: nextPage,
        });

        dispatch(accountInboxMessageDetailsNextPage(response));
      }
    } catch (error) {
      dispatch(accountInboxMessageDetailsError(error));
    }
  };
};
