// @flow
import { createAction } from 'redux-actions';
import {
  ACCOUNT_CANDIDATE_DIRECT_CONTRIBUTIONS_ERROR,
  ACCOUNT_CANDIDATE_DIRECT_CONTRIBUTIONS_LOADING,
  ACCOUNT_CANDIDATE_DIRECT_CONTRIBUTIONS_RESET,
  ACCOUNT_CANDIDATE_DIRECT_CONTRIBUTIONS_SUCCESS,
} from './accountCandidateDirectContributions.constants';
import { instance as rorGroupsApiProvider } from '../../../services/RoRUsersApiProvider';
import type { GetCandidateDirectContributionsPayload } from '../../../services/RoRUsersApiProvider';

export const accountCandidateDirectContributionsError = createAction(
  ACCOUNT_CANDIDATE_DIRECT_CONTRIBUTIONS_ERROR,
);

export const accountCandidateDirectContributionsLoading = createAction(
  ACCOUNT_CANDIDATE_DIRECT_CONTRIBUTIONS_LOADING,
);

export const accountCandidateDirectContributionsSuccess = createAction(
  ACCOUNT_CANDIDATE_DIRECT_CONTRIBUTIONS_SUCCESS,
);

export const accountCandidateDirectContributionsReset = createAction(
  ACCOUNT_CANDIDATE_DIRECT_CONTRIBUTIONS_RESET,
);

export const getAccountCandidateDirectContributions = (
  payload: GetCandidateDirectContributionsPayload,
) => {
  return async (dispatch: Function) => {
    try {
      dispatch(accountCandidateDirectContributionsLoading());

      const response = await rorGroupsApiProvider.accountCandidateDirectContributions(
        payload,
      );

      dispatch(accountCandidateDirectContributionsSuccess(response));
    } catch (error) {
      dispatch(accountCandidateDirectContributionsError(error));
    }
  };
};
