import {
  ACCOUNT_INBOX_MESSAGE_DETAILS_SUCCESS,
  ACCOUNT_INBOX_MESSAGE_DETAILS_RESET,
  ACCOUNT_INBOX_MESSAGE_DETAILS_LOADING,
  ACCOUNT_INBOX_MESSAGE_DETAILS_ERROR,
  ACCOUNT_INBOX_MESSAGE_DETAILS_NEXT_PAGE,
} from './accountInboxMessageDetails.constants';
import makeDefaultServerCallReducer from '../../utilities/makeDefaultServerCallReducer';
import _cloneDeep from 'lodash/cloneDeep';

const initialState = {
  isLoading: false,
  error: null,
  data: null,
};

const setNextPageData = (state, action) => {
  const { payload } = action;

  if (state.data && state.data.data) {
    const data = _cloneDeep(state.data);

    if (data.data.message) {
      const messages = (payload.data.message.messages || []).concat(
        data.data.message.messages || [],
      );

      return {
        ...state,
        data: {
          ...payload,
          data: {
            message: {
              ...payload.data.message,
              messages: messages,
            },
          },
        },
        isLoading: false,
      };
    }
  }

  return state;
};

export const reducer = makeDefaultServerCallReducer({
  loadingActions: [ACCOUNT_INBOX_MESSAGE_DETAILS_LOADING],
  successActions: [ACCOUNT_INBOX_MESSAGE_DETAILS_SUCCESS],
  errorActions: [ACCOUNT_INBOX_MESSAGE_DETAILS_ERROR],
  resetActions: [ACCOUNT_INBOX_MESSAGE_DETAILS_RESET],
  otherActions: [
    {
      name: ACCOUNT_INBOX_MESSAGE_DETAILS_NEXT_PAGE,
      callback: setNextPageData,
    },
  ],
  initialState,
});
