// @flow
import makeDefaultServerCallReducer from '../../utilities/makeDefaultServerCallReducer';
import {
  LIST_ROLES_ERROR,
  LIST_ROLES_LOADING,
  LIST_ROLES_SUCCESS,
  LIST_ROLES_RESET,
} from './listRoles.constants';

const initialState = {
  isLoading: false,
  error: null,
  data: null,
};

export const reducer = makeDefaultServerCallReducer({
  loadingActions: [LIST_ROLES_LOADING],
  successActions: [LIST_ROLES_SUCCESS],
  errorActions: [LIST_ROLES_ERROR],
  resetActions: [LIST_ROLES_RESET],
  initialState,
});
