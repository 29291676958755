// @flow
import React from 'react';
import classNames from 'classnames';
import {
  injectStripe,
  CardNumberElement,
  Elements,
  CardCVCElement,
  CardExpiryElement,
} from 'react-stripe-elements';
import Button from '../Button/Button';
import Input from '../Input/Input';
import './ContributePaymentCreditCard.scss';
import { message } from 'antd';

type Props = {
  onSourceCreated: Function,
  onSourceError: (error: any) => void,
  amount: number,
};

type State = {
  visible: boolean,
  generateSourceCallNumber: number,
};

class ContributePaymentCreditCard extends React.Component<Props, State> {
  constructor(props: Props) {
    super(props);

    this.state = {
      visible: false,
      generateSourceCallNumber: 0,
    };
  }

  componentDidMount() {
    setTimeout(() => {
      this.setState(() => ({ visible: true }));
    }, 500);
  }

  render() {
    return (
      <div className={this.getClassName()}>
        <Elements>
          <StripeCreditCardForm
            onSourceCreated={this.props.onSourceCreated}
            onSourceError={this.props.onSourceError}
            generateSourceCallNumber={this.state.generateSourceCallNumber}
            amount={this.props.amount}
          />
        </Elements>
      </div>
    );
  }

  getStripeSource() {
    this.setState(prevState => ({
      generateSourceCallNumber: prevState.generateSourceCallNumber + 1,
    }));
  }

  getClassName() {
    return classNames({
      'contribute-payment-credit-card': true,
      'contribute-payment-credit-card--visible': this.state.visible,
    });
  }
}

type StripeCardField = {
  complete: boolean,
  elementType: string,
  empty: boolean,
  error: any,
  value: any,
};

type CreditCardFormProps = {
  stripe: any,
  generateSourceCallNumber: number,
  amount: number,
  onSourceCreated: Function,
  onSourceError: (error: any) => void,
};

type CardBrand = 'visa' | 'mastercard' | 'amex' | 'unknown';
type CreditCardFormState = {
  cardNumber: (StripeCardField & { brand: CardBrand }) | null,
  cardCVC: StripeCardField | null,
  cardExpiration: StripeCardField | null,
  name: string | null,
  isLoading: boolean,
};

class CreditCardForm extends React.Component<
  CreditCardFormProps,
  CreditCardFormState,
> {
  constructor(props) {
    super(props);

    this.state = {
      cardNumber: null,
      cardCVC: null,
      cardExpiration: null,
      name: null,
      isLoading: false,
    };
  }

  componentDidUpdate(prevProps: CreditCardFormProps) {
    if (
      prevProps.generateSourceCallNumber !== this.props.generateSourceCallNumber
    ) {
      this.generateSource();
    }
  }

  render() {
    return (
      <div className="credit-card-form">
        <div className="row pb-3">
          <div className="col-12 col-md-11">
            <span className="contribute-payment__label mb-3 mt-3 mt-md-0">
              <strong>Card number</strong>
              <span className={this.getCreditCardIconClassNames('amex')} />
              <span
                className={this.getCreditCardIconClassNames('mastercard')}
              />
              <span className={this.getCreditCardIconClassNames('visa')} />
            </span>
            <CardNumberElement
              onChange={this.handleCardNumberChange}
              style={cardNumberElementStyle}
            />
          </div>
        </div>
        <div className="row pb-3">
          <div className="col-12 col-md-11">
            <span className="contribute-payment__label mb-3">
              <strong>Name</strong> (as it appears in your card)
            </span>
            <Input
              className="mb-0"
              label=""
              size="small"
              placeholder=""
              name="amount"
              type="text"
              onChange={this.handleInputNameChange}
              useFormik={false}
              useOnlyBorderedErrors
              error={
                (this.props.generateSourceCallNumber &&
                  this.state.name === null) ||
                this.state.name === ''
                  ? 'error'
                  : ''
              }
            />
          </div>
        </div>
        <div className="row pb-3">
          <div className="col-12 col-md-7">
            <span className="contribute-payment__label mb-3">
              <strong>Expiration Date</strong>
            </span>
            <CardExpiryElement
              onChange={this.handleCardExpiryChange}
              style={cardNumberElementStyle}
            />
          </div>
          <div className="col-12 col-md-4">
            <span className="contribute-payment__label mb-3 mt-3 mt-md-0">
              <strong>CVC</strong> <span className="icon-info" />
            </span>
            <CardCVCElement
              onChange={this.handleCardCCVChange}
              style={cardNumberElementStyle}
            />
          </div>
        </div>
        <div className="row pb-3">
          <div className="col-12">
            <span className="credit-card-form__cvc-information">
              <span className="icon-info" />
              For Visa/Mastercard find your 3 number code printed on the back of
              your card. AMEX: 4 on the front.
            </span>
          </div>
        </div>
        {this.renderError()}
      </div>
    );
  }

  renderError() {
    return (
      <React.Fragment>
        {this.getFieldErrors().map((error, index) => (
          <div key={index} className="row">
            <div className="col-12 col-md-11">
              <span className="credit-card-form__error">{error}</span>
            </div>
          </div>
        ))}
      </React.Fragment>
    );
  }

  generateSource = () => {
    this.setState(
      () => ({ isLoading: true }),
      async () => {
        try {
          // Reusable source with no amount
          const { source, error } = await this.props.stripe.createSource({
            type: 'card',
            owner: { name: this.state.name },
          });
          this.setState(() => ({ isLoading: false }));

          if (error) {
            if (this.props.onSourceError) {
              this.props.onSourceError(error);
            }

            return;
          }

          this.props.onSourceCreated(source);
        } catch (error) {
          if (this.props.onSourceError) {
            this.props.onSourceError(error);
          }
          this.setState(() => ({ isLoading: false }));
          message.error(
            "We're unable to process your request. Try again later.",
            5,
          );
        }
      },
    );
  };

  getCreditCardIconClassNames(cardBrand: 'amex' | 'visa' | 'mastercard') {
    return classNames({
      'icon-credit-card': true,
      'icon-credit-card--active':
        this.state.cardNumber && this.state.cardNumber.brand === cardBrand,
      [`icon-credit-card-${cardBrand}`]: true,
    });
  }

  getFieldErrors() {
    let fieldErrors = [];

    ['cardNumber', 'cardCVC', 'cardExpiration'].forEach(fieldName => {
      if (this.state[fieldName] && this.state[fieldName].error) {
        fieldErrors.push(this.state[fieldName].error.message);
      }
    });

    if (
      (this.props.generateSourceCallNumber && this.state.name === null) ||
      this.state.name === ''
    ) {
      fieldErrors.push('A valid name is required.');
    }

    return fieldErrors;
  }

  handleCardNumberChange = (
    cardFieldData: StripeCardField & { brand: CardBrand },
  ) => {
    this.setState(() => ({ cardNumber: cardFieldData }));
  };

  handleCardCCVChange = (cardFieldData: StripeCardField) => {
    this.setState(() => ({ cardCVC: cardFieldData }));
  };

  handleCardExpiryChange = (cardFieldData: StripeCardField) => {
    this.setState(() => ({ cardExpiration: cardFieldData }));
  };

  handleInputNameChange = ({ value }: { name: string, value: string }) => {
    this.setState(() => ({ name: value }));
  };
}

const cardNumberElementStyle = {
  base: {
    fontSize: '1rem',
    color: '#4a3f51',
    fontFamily: "'Poppins', sans-serif",
    '::placeholder': {
      color: '#d4ceda',
    },
  },
  invalid: {
    color: '#f44e42',
  },
  complete: {
    color: '#22a864',
  },
};

const StripeCreditCardForm = injectStripe(CreditCardForm);

export default ContributePaymentCreditCard;
