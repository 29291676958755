import {
  addNewAch as createNewAch,
  contributionAddNewAchReset as reset,
} from './contributionAddNewAch.actions';
import type { PostAddNewAchPayload } from '../../../services/RoRContributionsApiProvider';
import type { DefaultResponse } from '../../../services/RoRApiProvider';

export const mapStateToProps = state => {
  return {
    contributionAddNewAchData: state.contributionAddNewAch.data,
    contributionAddNewAchError: state.contributionAddNewAch.error,
    contributionAddNewAchIsLoading: state.contributionAddNewAch.isLoading,
  };
};

export const mapDispatchToProps = dispatch => {
  return {
    addNewAch(payload: PostAddNewAchPayload) {
      dispatch(createNewAch(payload));
    },
    contributionAddNewAchReset() {
      dispatch(reset());
    },
  };
};

export type ContributionAddNewAchMapStateToProps = {
  contributionAddNewAchData: DefaultResponse,
  contributionAddNewAchError: any,
  contributionAddNewAchIsLoading: boolean,
};

export type ContributionAddNewAchDispatchToProps = {
  addNewAch: (payload: PostAddNewAchPayload) => void,
  contributionAddNewAchReset: () => void,
};

export default {
  mapStateToProps,
  mapDispatchToProps,
};
