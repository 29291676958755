//@flow
import * as React from 'react';
import LoadingSpinner from '../LoadingSpinner/LoadingSpinner';
import './Loading.scss';
import classNames from 'classnames';

type Props = {
  loading: boolean,
  children: any,
  type?: 'background' | 'spinner',
  className?: string,
};

class Loading extends React.Component<Props, {}> {
  constructor(props: Props) {
    super(props);
  }

  render() {
    return (
      <div className={this.getClassName()}>
        {this.props.loading ? this.renderLoading() : this.renderContent()}
      </div>
    );
  }

  renderLoading() {
    return (
      <div className="loading__wrapper">
        {this.props.type === 'spinner'
          ? this.renderLoadingSpinner()
          : this.renderBackgroundLoading()}
      </div>
    );
  }

  renderBackgroundLoading() {
    return (
      <div className="loading__animated-background">
        <div className="loading__background-masker loading__background-masker-header-top" />
        <div className="loading__background-masker loading__background-masker-header-left" />
        <div className="loading__background-masker loading__background-masker-header-right" />
        <div className="loading__background-masker loading__background-masker-header-bottom" />
        <div className="loading__background-masker loading__background-masker-subheader-left" />
        <div className="loading__background-masker loading__background-masker-subheader-right" />
        <div className="loading__background-masker loading__background-masker-subheader-bottom" />
        <div className="loading__background-masker loading__background-masker-content-top" />
        <div className="loading__background-masker loading__background-masker-content-first-end" />
        <div className="loading__background-masker loading__background-masker-content-second-line" />
        <div className="loading__background-masker loading__background-masker-content-second-end" />
        <div className="loading__background-masker loading__background-masker-content-third-line" />
        <div className="loading__background-masker loading__background-masker-content-third-end" />
      </div>
    );
  }

  renderLoadingSpinner() {
    return <LoadingSpinner type={'dark'} visible={true} />;
  }

  renderContent() {
    return <div className="loading__content">{this.props.children}</div>;
  }

  getClassName() {
    return classNames({
      ['loading']: true,
      ['loading--spinner']: this.props.type === 'spinner',
      [this.props.className || '']: this.props.className,
    });
  }
}

export default Loading;
