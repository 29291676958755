import {
  removeModeratorFromGroup as removeModerator,
  groupRemoveModeratorReset as reset,
} from './groupRemoveModerator.actions';
import type { DeleteGroupRemoveModeratorPayload } from '../../../services/RoRGroupsApiProvider';
import type { DefaultResponse } from '../../../services/RoRApiProvider';

export const mapStateToProps = state => {
  return {
    groupRemoveModeratorData: state.groupRemoveModerator.data,
    groupRemoveModeratorError: state.groupRemoveModerator.error,
    groupRemoveModeratorIsLoading: state.groupRemoveModerator.isLoading,
  };
};

export const mapDispatchToProps = dispatch => {
  return {
    removeModeratorFromGroup(payload: DeleteGroupRemoveModeratorPayload) {
      dispatch(removeModerator(payload));
    },
    groupRemoveModeratorReset() {
      dispatch(reset());
    },
  };
};

export type GroupRemoveModeratorMapStateToProps = {
  groupRemoveModeratorData: DefaultResponse,
  groupRemoveModeratorError: any,
  groupRemoveModeratorIsLoading: boolean,
};

export type GroupRemoveModeratorMapDispatchToProps = {
  removeModeratorFromGroup: (
    payload: DeleteGroupRemoveModeratorPayload,
  ) => void,
  groupRemoveModeratorReset: () => void,
};

export default {
  mapStateToProps,
  mapDispatchToProps,
};
