// @flow
import { createAction } from 'redux-actions';
import {
  RACE_UNFOLLOW_ERROR,
  RACE_UNFOLLOW_LOADING,
  RACE_UNFOLLOW_RESET,
  RACE_UNFOLLOW_SUCCESS,
} from './raceUnfollow.constants';
import { instance as rorRacesApiProvider } from '../../../services/RoRRacesApiProvider';
import { raceDetailsSetAsUnfollowed } from '../raceDetails/raceDetails.actions';

export const raceUnfollowError = createAction(RACE_UNFOLLOW_ERROR);

export const raceUnfollowLoading = createAction(RACE_UNFOLLOW_LOADING);

export const raceUnfollowSuccess = createAction(RACE_UNFOLLOW_SUCCESS);

export const raceUnfollowReset = createAction(RACE_UNFOLLOW_RESET);

export const followRace = (payload: { raceId: number }) => {
  return async (dispatch: Function) => {
    try {
      dispatch(raceUnfollowLoading());

      const response = await rorRacesApiProvider.unfollowRace(payload);

      dispatch(raceUnfollowSuccess(response));
      dispatch(raceDetailsSetAsUnfollowed());
    } catch (error) {
      dispatch(raceUnfollowError(error));
    }
  };
};
