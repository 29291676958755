import { signUp as callSignUp, signUpReset as reset } from './signUp.actions';
import type { SignUpActionPayload } from './signUp.actions';
import type { PostSignUpResponse } from '../../../services/RoRApiProvider';

export const mapStateToProps = state => {
  return {
    signUpData: state.signUp.data,
    signUpError: state.signUp.error,
    signUpIsLoading: state.signUp.isLoading,
  };
};

export const mapDispatchToProps = dispatch => {
  return {
    signUp(payload: SignUpActionPayload) {
      dispatch(callSignUp(payload));
    },
    signUpReset() {
      dispatch(reset());
    },
  };
};

export type SignUpMapStateToProps = {
  signUpData: PostSignUpResponse,
  signUpError: any,
  signUpIsLoading: boolean,
};

export type SignUpMapDispatchToProps = {
  signUp: (payload: SignUpActionPayload) => void,
  signUpReset: () => void,
};

export default {
  mapStateToProps,
  mapDispatchToProps,
};
