export const ACCOUNT_INBOX_MESSAGE_DETAILS_LOADING =
  '@@prytany/global/ACCOUNT_INBOX_MESSAGE_DETAILS_LOADING';
export const ACCOUNT_INBOX_MESSAGE_DETAILS_SUCCESS =
  '@@prytany/global/ACCOUNT_INBOX_MESSAGE_DETAILS_SUCCESS';
export const ACCOUNT_INBOX_MESSAGE_DETAILS_ERROR =
  '@@prytany/global/ACCOUNT_INBOX_MESSAGE_DETAILS_ERROR';
export const ACCOUNT_INBOX_MESSAGE_DETAILS_RESET =
  '@@prytany/global/ACCOUNT_INBOX_MESSAGE_DETAILS_RESET';
export const ACCOUNT_INBOX_MESSAGE_DETAILS_NEXT_PAGE =
  '@@prytany/global/ACCOUNT_INBOX_MESSAGE_DETAILS_NEXT_PAGE';
