// @flow
import { createAction } from 'redux-actions';
import {
  USER_POSTS_ERROR,
  USER_POSTS_LOADING,
  USER_POSTS_SUCCESS,
  USER_POSTS_RESET,
  USER_POSTS_LIKE_POST,
  USER_POSTS_UNLIKE_POST,
  USER_POSTS_SHARE_POST,
} from './userPosts.constants';
import { instance as rorUsersApiProvider } from '../../../services/RoRUsersApiProvider';

export const userPostsError = createAction(USER_POSTS_ERROR);

export const userPostsLoading = createAction(USER_POSTS_LOADING);

export const userPostsSuccess = createAction(USER_POSTS_SUCCESS);

export const userPostsReset = createAction(USER_POSTS_RESET);

export const userPostsLikePost = createAction(USER_POSTS_LIKE_POST);

export const userPostsUnLikePost = createAction(USER_POSTS_UNLIKE_POST);

export const userPostsSharePost = createAction(USER_POSTS_SHARE_POST);

export const getUserPosts = (payload: {
  userId: number,
  page?: number,
  limit?: number,
}) => {
  return async (dispatch: Function) => {
    try {
      dispatch(userPostsLoading());

      const response = await rorUsersApiProvider.userPosts(payload);

      dispatch(userPostsSuccess(response));
    } catch (error) {
      dispatch(userPostsError(error));
    }
  };
};
