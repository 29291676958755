// @flow
import Axios, { AxiosResponse, AxiosError } from 'axios';
import RoRApiResponseTransformer from '../utilities/RoRApiResponseTransformer';
import RoRApiProvider from './RoRApiProvider';
import type { DefaultResponse } from './RoRApiProvider';
import type { SSAVerifiedStatus, UserListView } from './RoRUsersApiProvider';

class RoRGroupsApiProvider extends RoRApiProvider {
  constructor() {
    super();
  }

  groupCreate(payload: PostNewGroupPayload): Promise<PostNewGroupResponse> {
    const requestConfig = {
      headers: {
        'Content-Type': 'multipart/form-data',
        ...this.getAuthenticationHeaders(),
      },
    };
    const formData = getCreateOrUpdateGroupFormData(payload);

    return this.axios
      .post('/apis/groups', formData, requestConfig)
      .then((response: AxiosResponse) =>
        RoRApiResponseTransformer.parseResponse(response),
      )
      .catch((error: AxiosError) =>
        Promise.reject(RoRApiResponseTransformer.parseError(error)),
      );
  }

  groupDetails(
    payload: GetGroupDetailsPayload,
  ): Promise<GetGroupDetailsResponse> {
    const requestConfig = {
      headers: {
        'Content-Type': 'application/json',
        ...this.getAuthenticationHeaders(),
      },
    };

    return this.axios
      .get(`apis/groups/${payload.groupId}`, requestConfig)
      .then((response: AxiosResponse) =>
        RoRApiResponseTransformer.parseResponse(response),
      )
      .catch((error: AxiosError) =>
        Promise.reject(RoRApiResponseTransformer.parseError(error)),
      );
  }

  groupPosts(payload: GetGroupPostsPayload): Promise<GetGroupPostsResponse> {
    const requestConfig = {
      headers: {
        'Content-Type': 'application/json',
        ...this.getAuthenticationHeaders(),
      },
    };

    return this.axios
      .get(`apis/groups/${payload.groupId}/posts`, requestConfig)
      .then((response: AxiosResponse) =>
        RoRApiResponseTransformer.parseResponse(response),
      )
      .catch((error: AxiosError) =>
        Promise.reject(RoRApiResponseTransformer.parseError(error)),
      );
  }

  groupMembers(
    payload: GetGroupMembersPayload,
  ): Promise<GetGroupMembersResponse> {
    const requestConfig = {
      headers: {
        'Content-Type': 'application/json',
        ...this.getAuthenticationHeaders(),
      },
      params: {
        page: payload.page || 1,
        limit: payload.limit || 20,
        name: payload.name || '',
        include_moderators:
          typeof payload.includeModerators !== 'undefined'
            ? payload.includeModerators
            : true,
      },
    };

    return this.axios
      .get(`apis/groups/${payload.groupId}/members`, requestConfig)
      .then((response: AxiosResponse) =>
        RoRApiResponseTransformer.parseResponse(response, true),
      )
      .catch((error: AxiosError) =>
        Promise.reject(RoRApiResponseTransformer.parseError(error)),
      );
  }

  groupMemberContributions(
    payload: GetGroupMemberContributionsPayload,
  ): Promise<GetGroupMemberContributionsResponse> {
    const requestConfig = {
      headers: {
        'Content-Type': 'application/json',
        ...this.getAuthenticationHeaders(),
      },
    };
    const { groupId, memberId } = payload;
    return this.axios
      .get(
        `apis/groups/${groupId}/members/${memberId}/contributions`,
        requestConfig,
      )
      .then((response: AxiosResponse) =>
        RoRApiResponseTransformer.parseResponse(response),
      )
      .catch((error: AxiosError) =>
        Promise.reject(RoRApiResponseTransformer.parseError(error)),
      );
  }

  groupModerators(
    payload: GetGroupModeratorsPayload,
  ): Promise<GetGroupModeratorsResponse> {
    const requestConfig = {
      headers: {
        'Content-Type': 'application/json',
        ...this.getAuthenticationHeaders(),
      },
      params: {
        page: payload.page || 1,
        limit: payload.limit || 20,
        name: payload.name || '',
      },
    };

    return this.axios
      .get(`apis/groups/${payload.groupId}/moderators`, requestConfig)
      .then((response: AxiosResponse) =>
        RoRApiResponseTransformer.parseResponse(response, true),
      )
      .catch((error: AxiosError) =>
        Promise.reject(RoRApiResponseTransformer.parseError(error)),
      );
  }

  groupAddModerators(
    payload: PostGroupAddModeratorsPayload,
  ): Promise<PostGroupAddModeratorsResponse> {
    const requestConfig = {
      headers: {
        'Content-Type': 'application/json',
        ...this.getAuthenticationHeaders(),
      },
    };
    const data = {
      user_id: payload.userIds,
    };

    return this.axios
      .post(`apis/groups/${payload.groupId}/moderators`, data, requestConfig)
      .then((response: AxiosResponse) =>
        RoRApiResponseTransformer.parseResponse(response),
      )
      .catch((error: AxiosError) =>
        Promise.reject(RoRApiResponseTransformer.parseError(error)),
      );
  }

  groupRemoveModerator(
    payload: DeleteGroupRemoveModeratorPayload,
  ): Promise<DefaultResponse> {
    const requestConfig = {
      headers: {
        'Content-Type': 'application/json',
        ...this.getAuthenticationHeaders(),
      },
    };

    return this.axios
      .delete(
        `apis/groups/${payload.groupId}/moderators/${payload.userId}`,
        requestConfig,
      )
      .then((response: AxiosResponse) =>
        RoRApiResponseTransformer.parseResponse(response, true),
      )
      .catch((error: AxiosError) =>
        Promise.reject(RoRApiResponseTransformer.parseError(error)),
      );
  }

  groupRemoveMember(
    payload: DeleteGroupRemoveMemberPayload,
  ): Promise<DefaultResponse> {
    const requestConfig = {
      headers: {
        'Content-Type': 'application/json',
        ...this.getAuthenticationHeaders(),
      },
    };

    return this.axios
      .delete(
        `apis/groups/${payload.groupId}/members/${payload.userId}`,
        requestConfig,
      )
      .then((response: AxiosResponse) =>
        RoRApiResponseTransformer.parseResponse(response, true),
      )
      .catch((error: AxiosError) =>
        Promise.reject(RoRApiResponseTransformer.parseError(error)),
      );
  }

  groupDelete(payload: DeleteGroupDeletePayload): Promise<DefaultResponse> {
    const requestConfig = {
      headers: {
        'Content-Type': 'application/json',
        ...this.getAuthenticationHeaders(),
      },
    };

    return this.axios
      .delete(`apis/groups/${payload.groupId}`, requestConfig)
      .then((response: AxiosResponse) =>
        RoRApiResponseTransformer.parseResponse(response, true),
      )
      .catch((error: AxiosError) =>
        Promise.reject(RoRApiResponseTransformer.parseError(error)),
      );
  }

  groupMemberPendingRequests(
    payload: GetGroupMemberPendingRequestsPayload,
  ): Promise<GetGroupMemberPendingRequestsResponse> {
    const requestConfig = {
      headers: {
        'Content-Type': 'application/json',
        ...this.getAuthenticationHeaders(),
      },
      params: {
        page: payload.page || 1,
        limit: payload.limit || 20,
        role: payload.role,
      },
    };

    return this.axios
      .get(`apis/groups/${payload.groupId}/member_requests`, requestConfig)
      .then((response: AxiosResponse) =>
        RoRApiResponseTransformer.parseResponse(response, true),
      )
      .catch((error: AxiosError) =>
        Promise.reject(RoRApiResponseTransformer.parseError(error)),
      );
  }

  groupApproveMemberRequest(
    payload: PutApproveMemberRequestPayload,
  ): Promise<DefaultResponse> {
    const requestConfig = {
      headers: {
        'Content-Type': 'application/json',
        ...this.getAuthenticationHeaders(),
      },
    };

    return this.axios
      .put(
        `apis/groups/${payload.groupId}/member_requests/${payload.userId}`,
        {},
        requestConfig,
      )
      .then((response: AxiosResponse) =>
        RoRApiResponseTransformer.parseResponse(response, true),
      )
      .catch((error: AxiosError) =>
        Promise.reject(RoRApiResponseTransformer.parseError(error)),
      );
  }

  groupApproveAllMemberRequest(
    payload: PutApproveAllMemberRequestsPayload,
  ): Promise<DefaultResponse> {
    const requestConfig = {
      headers: {
        'Content-Type': 'application/json',
        ...this.getAuthenticationHeaders(),
      },
    };

    return this.axios
      .put(
        `apis/groups/${payload.groupId}/member_requests/approve?role=${
          payload.roleId
        }`,
        {},
        requestConfig,
      )
      .then((response: AxiosResponse) =>
        RoRApiResponseTransformer.parseResponse(response, true),
      )
      .catch((error: AxiosError) =>
        Promise.reject(RoRApiResponseTransformer.parseError(error)),
      );
  }

  groupDenyMemberRequest(
    payload: DeleteDenyMemberRequestPayload,
  ): Promise<DefaultResponse> {
    const requestConfig = {
      headers: {
        'Content-Type': 'application/json',
        ...this.getAuthenticationHeaders(),
      },
    };

    return this.axios
      .delete(
        `apis/groups/${payload.groupId}/member_requests/${payload.userId}`,
        requestConfig,
      )
      .then((response: AxiosResponse) =>
        RoRApiResponseTransformer.parseResponse(response, true),
      )
      .catch((error: AxiosError) =>
        Promise.reject(RoRApiResponseTransformer.parseError(error)),
      );
  }

  groupInvitePeople(
    payload: PostInvitePeoplePayload,
  ): Promise<PostInvitePeopleResponse> {
    const requestConfig = {
      headers: {
        'Content-Type': 'multipart/form-data',
        ...this.getAuthenticationHeaders(),
      },
    };
    const formData = new FormData();

    payload.userIds.forEach((userId: number, index) => {
      formData.append(
        `group[group_members_attributes][][user_id]`,
        userId.toString(),
      );
    });

    return this.axios
      .post(`apis/groups/${payload.groupId}/members`, formData, requestConfig)
      .then((response: AxiosResponse) =>
        RoRApiResponseTransformer.parseResponse(response, true),
      )
      .catch((error: AxiosError) =>
        Promise.reject(RoRApiResponseTransformer.parseError(error)),
      );
  }

  groupInviteByContact(
    payload: PostInviteByContactPayload,
  ): Promise<DefaultResponse> {
    const requestConfig = {
      headers: {
        'Content-Type': 'application/json',
        ...this.getAuthenticationHeaders(),
      },
    };
    const { groupId, ...invite } = payload;
    return this.axios
      .post(`apis/groups/${groupId}/members/invite`, { invite }, requestConfig)
      .catch((error: AxiosError) =>
        Promise.reject(RoRApiResponseTransformer.parseError(error)),
      );
  }

  groupRequestJoin(
    payload: PostGroupRequestJoinPayload,
  ): Promise<DefaultResponse> {
    const requestConfig = {
      headers: {
        'Content-Type': 'application/json',
        ...this.getAuthenticationHeaders(),
      },
    };

    return this.axios
      .post(`apis/groups/${payload.groupId}/member_requests`, {}, requestConfig)
      .then((response: AxiosResponse) =>
        RoRApiResponseTransformer.parseResponse(response, true),
      )
      .catch((error: AxiosError) =>
        Promise.reject(RoRApiResponseTransformer.parseError(error)),
      );
  }

  groupLeave(payload: DeleteGroupLeavePayload): Promise<DefaultResponse> {
    const requestConfig = {
      headers: {
        'Content-Type': 'application/json',
        ...this.getAuthenticationHeaders(),
      },
    };

    return this.axios
      .delete(`apis/groups/${payload.groupId}/members/leave`, requestConfig)
      .then((response: AxiosResponse) =>
        RoRApiResponseTransformer.parseResponse(response, true),
      )
      .catch((error: AxiosError) =>
        Promise.reject(RoRApiResponseTransformer.parseError(error)),
      );
  }

  groupRename(payload: PutGroupRenamePayload): Promise<PutGroupUpdateResponse> {
    const requestConfig = {
      headers: {
        'Content-Type': 'application/json',
        ...this.getAuthenticationHeaders(),
      },
    };
    const data = { group: { name: payload.name } };

    return this.axios
      .put(`apis/groups/${payload.groupId}`, data, requestConfig)
      .then((response: AxiosResponse) =>
        RoRApiResponseTransformer.parseResponse(response),
      )
      .catch((error: AxiosError) =>
        Promise.reject(RoRApiResponseTransformer.parseError(error)),
      );
  }

  groupUpdate(payload: PutGroupUpdatePayload): Promise<PutGroupUpdateResponse> {
    const requestConfig = {
      headers: {
        'Content-Type': 'multipart/form-data',
        ...this.getAuthenticationHeaders(),
      },
    };
    const formData = getCreateOrUpdateGroupFormData(payload);

    return this.axios
      .put(`apis/groups/${payload.groupId}`, formData, requestConfig)
      .then((response: AxiosResponse) =>
        RoRApiResponseTransformer.parseResponse(response),
      )
      .catch((error: AxiosError) =>
        Promise.reject(RoRApiResponseTransformer.parseError(error)),
      );
  }

  searchGroups(payload: GetGroupsPayload): Promise<GetGroupsResponse> {
    const requestConfig = {
      headers: {
        'Content-Type': 'application/json',
        ...this.getAuthenticationHeaders(),
      },
      params: {
        page: payload.page || 1,
        limit: payload.limit || 20,
        name: payload.name || '',
      },
    };

    return this.axios
      .get('/apis/groups', requestConfig)
      .then((response: AxiosResponse) =>
        RoRApiResponseTransformer.parseResponse(response, true),
      )
      .catch((error: AxiosError) =>
        Promise.reject(RoRApiResponseTransformer.parseError(error)),
      );
  }
}

function getCreateOrUpdateGroupFormData(
  payload: PostNewGroupPayload | PutGroupUpdatePayload,
) {
  const formData = new FormData();

  Object.keys(payload).forEach((key: string) => {
    let value: any;

    if (payload[key] instanceof File || Array.isArray(payload[key])) {
      value = payload[key];
    } else {
      value = payload[key].toString();
    }

    if (key === 'entity_interests_attributes' && value !== null) {
      value.forEach((interest, index) => {
        if (interest.interest_id) {
          formData.append(
            `group[entity_interests_attributes][${index}][interest_id]`,
            interest.interest_id,
          );
        }

        if (interest._destroy) {
          formData.append(
            `group[entity_interests_attributes][${index}][_destroy]`,
            interest._destroy,
          );
        }
      });
    } else if (key === 'group_members_attributes' && value !== null) {
      value.forEach((user, index) => {
        formData.append(
          `group[group_members_attributes][${index}][user_id]`,
          user.user_id,
        );
      });
    } else if (key === 'group_candidates_attributes' && value !== null) {
      value.forEach((candidate, index) => {
        if (candidate.candidate_id) {
          formData.append(
            `group[group_candidates_attributes][${index}][candidate_id]`,
            candidate.candidate_id,
          );
        }

        if (candidate._destroy) {
          formData.append(
            `group[group_candidates_attributes][${index}][_destroy]`,
            candidate._destroy,
          );
        }
      });
    } else if (value !== null) {
      formData.append(`group[${key}]`, value);
    }
  });

  return formData;
}

export type PostNewGroupPayload = {
  name: string,
  description: string,
  group_image: File | null,
  public_profile?: boolean,
  entity_interests_attributes?: { interest_id: number }[],
  group_members_attributes?: { user_id: number }[],
  group_candidates_attributes?: { candidate_id: number }[],
};

export type PostNewGroupResponse = DefaultResponse & {
  group: {
    id: number,
    is_public: boolean,
    name: string,
    members_count: number,
    created_at: string,
    group_image: string,
    description: string,
    interests: { id: number, name: string }[],
    owner: {
      id: number,
      first_name: string,
      last_name: string,
      profile_image: string,
      is_public: boolean,
      role: {
        id: number,
        name: string,
        code: string,
        admin: boolean,
      },
    },
    current_user: {
      is_moderator: boolean,
      is_member: boolean,
      has_active_request: boolean,
    },
  },
};

export type GetGroupsPayload = {
  name?: string,
  limit?: number,
  page?: number,
};

export type GetGroupsResponse = DefaultResponse & {
  data: {
    groups: {
      id: number,
      name: string,
      code: string,
      group_image: string,
    }[],
  },
};

export type GroupDetails = {
  id: number,
  is_public: boolean,
  name: string,
  prytany_number: string,
  members_count: number,
  pending_requests: number,
  created_at: string,
  group_image: string,
  description: string,
  interests: { id: number, name: string }[],
  owner: {
    id: number,
    first_name: string,
    last_name: string,
    profile_image: string,
    is_public: boolean,
    role: {
      id: number,
      name: string,
      code: string,
      admin: boolean,
    },
  },
  current_user: {
    is_moderator: boolean,
    has_pending_requests: boolean,
    is_member: boolean,
    has_active_request?: boolean,
  },
  donations: {
    contributed: number,
    committed: number,
    pledged: number,
  },
};

export type GetGroupDetailsPayload = {
  groupId: number,
};

export type GetGroupDetailsResponse = DefaultResponse & {
  group: GroupDetails,
};

export type GroupPost = {
  id: number,
  title: string,
  short_description: string,
  commentable: boolean,
  created_at: string,
  like_count: number,
  share_count: number,
  comment_count: number,
  has_liked: boolean,
  post_image: string,
  owner: UserListView,
};

export type GetGroupPostsPayload = {
  groupId: number,
};

export type GetGroupPostsResponse = DefaultResponse & {
  posts: GroupPost[],
};

export type GroupMember = {
  donations: {
    contributed: number,
    committed: number,
    pledged: number,
  },
  state: {
    id: number,
    name: string,
    code: string,
  },
  shared_interests: number,
} & UserListView;

export type GetGroupMembersPayload = {
  groupId: number,
  includeModerators?: boolean,
  page?: number,
  limit?: number,
  name?: string,
};

export type GetGroupMembersResponse = DefaultResponse & {
  data: { members: GroupMember[] },
};

export type GetGroupMemberContributionsPayload = {
  groupId: number,
  memberId: number,
};

export type GetGroupMemberContributionsResponse = DefaultResponse & {
  contributions: {
    direct: GroupMemberContribution[],
    committed: GroupMemberContribution[],
    pledged: GroupMemberContribution[],
  },
};

export type GroupMemberContribution = {
  id: number,
  candidate_full_name: string,
  profile_image: string,
  amount_in_cents: number,
};

export type GroupModerator = {
  id: number,
  first_name: string,
  last_name: string,
  profile_image: string,
  is_public: string,
  role: {
    id: number,
    name: string,
    code: string,
    admin: boolean,
  },
};

export type GetGroupModeratorsPayload = {
  groupId: number,
  page?: number,
  limit?: number,
  name?: string,
};

export type PostGroupAddModeratorsPayload = {
  groupId: number,
  userIds: number[],
};

export type PostGroupAddModeratorsResponse = {
  group: {
    id: number,
    name: string,
    code: string,
    group_image: string,
  },
};

export type DeleteGroupRemoveModeratorPayload = {
  groupId: number,
  userId: number,
};

export type DeleteGroupRemoveMemberPayload = {
  groupId: number,
  userId: number,
};

export type DeleteGroupDeletePayload = {
  groupId: number,
};

export type GetGroupModeratorsResponse = DefaultResponse & {
  data: { moderators: GroupModerator[] },
};

export type GetGroupMemberPendingRequestsPayload = {
  groupId: number,
  page?: number,
  limit?: number,
  role?: number,
};

export type GetGroupMemberPendingRequestsResponse = DefaultResponse & {
  data: { requests: UserListView[] },
};

export type PutApproveMemberRequestPayload = {
  userId: number,
  groupId: number,
};

export type PutApproveAllMemberRequestsPayload = {
  userId: number,
  groupId: number,
  roleId: number,
};

export type DeleteDenyMemberRequestPayload = {
  userId: number,
  groupId: number,
};

export type PostInvitePeoplePayload = {
  groupId: number,
  userIds: number[],
};

export type PostInvitePeopleResponse = DefaultResponse & {
  data: {
    group: {
      id: number,
      name: string,
      code: string,
      group_image: string,
    },
  },
};

export type PostInviteByContactPayload = {
  groupId: number,
  email?: string,
  telephone?: number | string,
};

export type PostGroupRequestJoinPayload = {
  groupId: number,
};

export type DeleteGroupLeavePayload = {
  groupId: number,
};

export type PutGroupRenamePayload = {
  name: string,
  groupId: number,
};

export type PutGroupUpdatePayload = {
  name?: string,
  group_image?: string,
  description?: string,
  entity_interests_attributes?: { interest_id: number, _destroy?: number }[],
  group_candidates_attributes?: { candidate_id: number, _destroy?: number }[],
  groupId: number,
};

export type PutGroupUpdateResponse = {
  group: GroupDetails,
};

export default RoRGroupsApiProvider;
export const instance = new RoRGroupsApiProvider();
