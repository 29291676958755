import { getRaceFeed, raceFeedReset as reset } from './raceFeed.actions';
import type { Feed } from '../../../services/RoRUsersApiProvider';

export const mapStateToProps = state => {
  return {
    raceFeedData: state.raceFeed.data,
    raceFeedError: state.raceFeed.error,
    raceFeedIsLoading: state.raceFeed.isLoading,
  };
};

export const mapDispatchToProps = dispatch => {
  return {
    getFeedForRace(payload) {
      dispatch(getRaceFeed(payload));
    },
    raceFeedReset() {
      dispatch(reset());
    },
  };
};

export type RaceFeedMapStateToProps = {
  raceFeedData: { feeds: Feed } | null,
  raceFeedError: any,
  raceFeedIsLoading: boolean,
};

export type RaceFeedMapDispatchToProps = {
  getFeedForRace: Function,
  raceFeedReset: Function,
};

export default {
  mapStateToProps,
  mapDispatchToProps,
};
