// @flow
import makeDefaultServerCallReducer from '../../utilities/makeDefaultServerCallReducer';
import {
  ACCOUNT_CANDIDATE_DIRECT_CONTRIBUTIONS_ERROR,
  ACCOUNT_CANDIDATE_DIRECT_CONTRIBUTIONS_LOADING,
  ACCOUNT_CANDIDATE_DIRECT_CONTRIBUTIONS_SUCCESS,
  ACCOUNT_CANDIDATE_DIRECT_CONTRIBUTIONS_RESET,
} from './accountCandidateDirectContributions.constants';

const initialState = {
  isLoading: false,
  error: null,
  data: null,
};

export const reducer = makeDefaultServerCallReducer({
  loadingActions: [ACCOUNT_CANDIDATE_DIRECT_CONTRIBUTIONS_LOADING],
  successActions: [ACCOUNT_CANDIDATE_DIRECT_CONTRIBUTIONS_SUCCESS],
  errorActions: [ACCOUNT_CANDIDATE_DIRECT_CONTRIBUTIONS_ERROR],
  resetActions: [ACCOUNT_CANDIDATE_DIRECT_CONTRIBUTIONS_RESET],
  initialState,
});
