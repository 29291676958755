// @flow
import makeDefaultServerCallReducer from '../../utilities/makeDefaultServerCallReducer';
import {
  RACE_DETAILS_ERROR,
  RACE_DETAILS_LOADING,
  RACE_DETAILS_SUCCESS,
  RACE_DETAILS_RESET,
  RACE_DETAILS_SET_AS_FOLLOWED,
  RACE_DETAILS_SET_AS_UNFOLLOWED,
} from './raceDetails.constants';
import _get from 'lodash/get';
import _cloneDeep from 'lodash/cloneDeep';

const initialState = {
  isLoading: false,
  error: null,
  data: null,
};

const setAsFollowed = (state, action) => {
  if (_get(state, 'data.race')) {
    const data = _cloneDeep(state.data);

    data.race.current_user.is_following = true;

    return {
      ...state,
      data,
    };
  }

  return state;
};

const setAsUnfollowed = (state, action) => {
  if (_get(state, 'data.race')) {
    const data = _cloneDeep(state.data);

    data.race.current_user.is_following = false;

    return {
      ...state,
      data,
    };
  }

  return state;
};

export const reducer = makeDefaultServerCallReducer({
  loadingActions: [RACE_DETAILS_LOADING],
  successActions: [RACE_DETAILS_SUCCESS],
  errorActions: [RACE_DETAILS_ERROR],
  resetActions: [RACE_DETAILS_RESET],
  otherActions: [
    { name: RACE_DETAILS_SET_AS_FOLLOWED, callback: setAsFollowed },
    { name: RACE_DETAILS_SET_AS_UNFOLLOWED, callback: setAsUnfollowed },
  ],
  initialState,
});
