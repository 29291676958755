// @flow
import React from 'react';
import { connect } from 'react-redux';
import { message } from 'antd';
import classNames from 'classnames';
import Modal from '../Modal/Modal';
import Button from '../Button/Button';
import account from '../../redux/modules/account/account.containers';
import postShare from '../../redux/modules/postShare/postShare.containers';
import type {
  PostShareMapDispatchToProps,
  PostShareMapStateToProps,
} from '../../redux/modules/postShare/postShare.containers';
import { URL_POST_PAGE } from '../../config/urls';

type Props = PostShareMapDispatchToProps &
  PostShareMapStateToProps & {
    isOpen: boolean,
    postId: number,
    onCloseRequest: Function,
    onSuccess?: Function,
    onError?: Function,
    className?: string,
  };

class ModalPostShare extends React.Component<Props, {}> {
  constructor(props: Props) {
    super(props);
    this.props.postShareReset();
  }

  componentDidUpdate(prevProps: Props) {
    if (!prevProps.postShareData && this.props.postShareData) {
      message.success('Post successfully shared in your feed.');
      this.props.postShareReset();
      this.props.onCloseRequest();
    }

    if (!prevProps.postShareError && this.props.postShareError) {
      message.error(
        this.props.postShareError.localMessage ||
          'Something went wrong. Please try again later.',
      );
      this.props.postShareReset();
    }
  }

  render() {
    const {
      isOpen,
      onCloseRequest,
      className,
      onSuccess,
      onError,
      ...rest
    } = this.props;

    return isOpen ? (
      <Modal
        contentLabel="Share this post"
        isOpen={isOpen}
        onCloseRequest={onCloseRequest}
        className={this.getClassName()}
        noPaddingContent
        size="small"
        {...rest}>
        {this.renderContent()}
      </Modal>
    ) : null;
  }

  renderContent() {
    return (
      <div className="modal-post-share">
        <div className="row fix-width">
          <div className="col-12 py-4 px-4">
            <h5>Share this post</h5>
          </div>
          <div className="col-12 pb-3 px-4">
            <p>You can either share this post in your feed or copy its link.</p>
          </div>
          <div className="col-12 pb-3 px-4">
            <div className="row justify-content-center">
              <Button
                onClick={this.handlePostShare}
                buttonType="primary"
                type="button"
                className="mb-3 col-6 col-md-4 mx-3"
                loading={this.props.postShareIsLoading}>
                Share on my feed
              </Button>
              <Button
                onClick={this.copyUrlToClipboard}
                buttonType="outline"
                type="button"
                className="mb-3 col-6 col-md-4 mx-3">
                Copy link
              </Button>
            </div>
          </div>
        </div>
      </div>
    );
  }

  handlePostShare = () => {
    this.props.postShare({ postId: this.props.postId });
  };

  copyUrlToClipboard = () => {
    const el = document.createElement('textarea');
    el.value = `${window.location.origin}${URL_POST_PAGE.replace(
      ':postId',
      this.props.postId,
    )}`;

    //$FlowFixMe
    document.body.appendChild(el);
    el.select();
    document.execCommand('copy');
    //$FlowFixMe
    document.body.removeChild(el);

    message.success('Successfully copied post url to your clipboard.', 2);
  };

  getClassName() {
    return classNames({
      'modal-rename-group': true,
      [this.props.className || '']: this.props.className,
    });
  }
}

const mapStateToProps = (state, ownProps) => {
  return {
    ...account.mapStateToProps(state),
    ...postShare.mapStateToProps(state),
    ...ownProps,
  };
};

const mapDispatchToProps = dispatch => {
  return {
    ...postShare.mapDispatchToProps(dispatch),
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(ModalPostShare);
