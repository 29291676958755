// @flow
import { createAction } from 'redux-actions';
import {
  SIGN_UP_OTP_VERIFICATION_ERROR,
  SIGN_UP_OTP_VERIFICATION_LOADING,
  SIGN_UP_OTP_VERIFICATION_SUCCESS,
  SIGN_UP_OTP_VERIFICATION_RESET,
} from './signUpOtpVerification.constants';
import { instance as rorApiProvider } from '../../../services/RoRApiProvider';
import type { PostVerifyOtpCode } from '../../../services/RoRApiProvider';

export const signUpOtpVerificationError = createAction(
  SIGN_UP_OTP_VERIFICATION_ERROR,
);

export const signUpOtpVerificationLoading = createAction(
  SIGN_UP_OTP_VERIFICATION_LOADING,
);

export const signUpOtpVerificationSuccess = createAction(
  SIGN_UP_OTP_VERIFICATION_SUCCESS,
);

export const signUpOtpVerificationReset = createAction(
  SIGN_UP_OTP_VERIFICATION_RESET,
);

export const signUpOtpVerification = (payload: PostVerifyOtpCode) => {
  return async (dispatch: Function) => {
    try {
      let response;

      dispatch(signUpOtpVerificationLoading());
      response = await rorApiProvider.verifyOtpCode(payload);

      dispatch(signUpOtpVerificationSuccess(response));
    } catch (error) {
      dispatch(signUpOtpVerificationError(error));
    }
  };
};
