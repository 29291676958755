import {
  SIGN_UP_OTP_VERIFICATION_ERROR,
  SIGN_UP_OTP_VERIFICATION_LOADING,
  SIGN_UP_OTP_VERIFICATION_SUCCESS,
  SIGN_UP_OTP_VERIFICATION_RESET,
} from './signUpOtpVerification.constants';
import makeDefaultServerCallReducer from '../../utilities/makeDefaultServerCallReducer';

const initialState = {
  isLoading: false,
  error: null,
  data: null,
};

export const reducer = makeDefaultServerCallReducer({
  loadingActions: [SIGN_UP_OTP_VERIFICATION_LOADING],
  successActions: [SIGN_UP_OTP_VERIFICATION_SUCCESS],
  errorActions: [SIGN_UP_OTP_VERIFICATION_ERROR],
  resetActions: [SIGN_UP_OTP_VERIFICATION_RESET],
  initialState,
});
