// @flow
import makeDefaultServerCallReducer from '../../utilities/makeDefaultServerCallReducer';
import {
  ACCOUNT_CANDIDATE_COMMITMENTS_ERROR,
  ACCOUNT_CANDIDATE_COMMITMENTS_LOADING,
  ACCOUNT_CANDIDATE_COMMITMENTS_SUCCESS,
  ACCOUNT_CANDIDATE_COMMITMENTS_RESET,
} from './accountCandidateCommitments.constants';

const initialState = {
  isLoading: false,
  error: null,
  data: null,
};

export const reducer = makeDefaultServerCallReducer({
  loadingActions: [ACCOUNT_CANDIDATE_COMMITMENTS_LOADING],
  successActions: [ACCOUNT_CANDIDATE_COMMITMENTS_SUCCESS],
  errorActions: [ACCOUNT_CANDIDATE_COMMITMENTS_ERROR],
  resetActions: [ACCOUNT_CANDIDATE_COMMITMENTS_RESET],
  initialState,
});
