// @flow
import * as React from 'react';
import * as yup from 'yup';
import { Formik, FormikComputedProps } from 'formik';
import Input from '../Input/Input';
import './SignUpCampaignManagerForm.scss';
import Button from '../Button/Button';
import {
  PASSWORD_DESCRIPTIVE_TEXT,
  PASSWORD_REGEX,
} from '../../constants/forms';

type Props = {
  initialFormValues: FormValues | null,
  isLoading: boolean,
  testProps: string,
  onFormSubmit?: Function,
};

type FormValues = {
  email: string,
  name: string,
  lastName: string,
  password: string,
  passwordConfirm: string,
};

class SignUpCampaignManagerForm extends React.Component<Props> {
  formikForm: Formik;

  constructor(props: Props) {
    super(props);
  }

  render() {
    return (
      <div className="sign-up-campaign-manager-form">
        <Formik {...this.getFormikProps()}>
          {props => this.renderInnerForm(props)}
        </Formik>
      </div>
    );
  }

  renderInnerForm(props: FormikComputedProps) {
    const { touched, errors, handleSubmit, values } = props;

    return (
      <form className="row justify-content-center" onSubmit={handleSubmit}>
        <div className="col-lg-8 pr-lg-5">
          <div className="row mb-md-3">
            <Input
              className="col-md-12"
              label="Email Address"
              name="email"
              type="email"
              error={touched.email && errors.email ? errors.email : ''}
            />
          </div>
          <div className="row mb-md-3">
            <Input
              className="col-md-6"
              label="Name"
              name="name"
              type="text"
              error={touched.name && errors.name ? errors.name : ''}
            />
            <Input
              className="col-md-6"
              label="Last Name"
              name="lastName"
              type="text"
              error={touched.lastName && errors.lastName ? errors.lastName : ''}
            />
          </div>
          <div className="row mb-md-3">
            <Input
              className="col-md-6"
              name="password"
              label="Password"
              type="password"
              error={touched.password && errors.password ? errors.password : ''}
            />
            <Input
              className="col-md-6"
              name="passwordConfirm"
              label="Confirm Password"
              type="password"
              error={
                touched.passwordConfirm && errors.passwordConfirm
                  ? errors.passwordConfirm
                  : ''
              }
            />
          </div>
          <div className="row justify-content-center mb-md-3">
            <Button
              loading={this.props.isLoading}
              disabled={
                !(
                  values.email &&
                  values.name &&
                  values.lastName &&
                  values.password &&
                  values.passwordConfirm &&
                  values.password === values.passwordConfirm
                )
              }
              className="col-md-6"
              type="submit"
              block={true}
              buttonType="primary">
              Sign Up
            </Button>
          </div>
        </div>
      </form>
    );
  }

  setFormikRef = (ref: Formik) => {
    this.formikForm = ref;
  };

  getFormikProps() {
    return {
      initialValues: this.getFormikInitialValues(),
      validationSchema: yup.object().shape(this.getValidationSchema()),
      onSubmit: this.onFormSubmit,
      ref: this.setFormikRef,
    };
  }

  getValidationSchema() {
    let schema: any = {
      email: yup
        .string()
        .required('This field is required')
        .email('This field must be an email address'),
      name: yup.string().required('This field is required'),
      lastName: yup.string().required('This field is required'),
      password: yup
        .string()
        .required('This field is required')
        .matches(PASSWORD_REGEX, PASSWORD_DESCRIPTIVE_TEXT),
      passwordConfirm: yup
        .string()
        .required('This field is required')
        .oneOf([yup.ref('password'), null], "Passwords don't match"),
    };

    return schema;
  }

  getFormikInitialValues() {
    const defaultValues: any = {
      email: '',
      name: '',
      lastName: '',
      password: '',
      passwordConfirm: '',
    };

    if (this.props.initialFormValues) {
      let values = Object.assign({}, this.props.initialFormValues);

      return values;
    }

    return defaultValues;
  }

  submitForm = () => {
    // Manually triggering touch before sending the form
    this.formikForm.setTouched({
      email: true,
      name: true,
      lastName: true,
      password: true,
      passwordConfirm: true,
    });

    this.formikForm.submitForm();
  };

  onFormSubmit = (values: FormValues) => {
    if (this.props.onFormSubmit) {
      this.props.onFormSubmit(values);
    }
  };
}

export default SignUpCampaignManagerForm;
