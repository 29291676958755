// @flow
import * as React from 'react';
import { Formik, FormikComputedProps } from 'formik';
import classNames from 'classnames';
import * as yup from 'yup';
import Recaptcha from 'react-recaptcha';
import Input from '../Input/Input';
import './SignUpAccountBasicsForm.scss';
import {
  PASSWORD_REGEX,
  PASSWORD_DESCRIPTIVE_TEXT,
  ONLY_ONE_LETTER_REGEX,
  ONLY_ONE_LETTER_DESCRIPTIVE_TEXT,
} from '../../constants/forms';
import RoleCode from '../../constants/RoleCode';

const { CITIZEN, CANDIDATE } = RoleCode;

type Props = {
  onFormSubmit?: Function,
  userRole: 'citizen' | 'candidate',
  initialFormValues: FormValues | null,
};

type FormValues = {
  email: string,
  name: string,
  lastName: string,
  dateOfBirth: Date,
  password: string,
  passwordConfirm: string,
  fecNumber?: string,
};

// TODO: ID Picture is commented due a client requirement, it might be possible it'd be enabled again in the future.

type State = {
  recaptchaToken: string,
  recaptchaError: string,
  // accountIDPicture: File | null;
  // accountIDPictureInputTouched: boolean;
};

class SignUpAccountBasicsForm extends React.Component<Props, State> {
  formikForm: Formik;

  constructor(props: Props) {
    super(props);

    this.state = {
      recaptchaToken: '',
      recaptchaError: '',
      // accountIDPicture: null,
      // accountIDPictureInputTouched: false,
    };
  }

  render() {
    return (
      <div className="sign-up-account-basics-form">
        <Formik {...this.getFormikProps()}>
          {props => this.renderInnerForm(props)}
        </Formik>
        <hr className="mt-4" />
      </div>
    );
  }

  renderInnerForm(props: FormikComputedProps) {
    const { touched, errors, handleSubmit } = props;
    const isCitizen = this.props.userRole === CITIZEN;

    return (
      <form className="row" onSubmit={handleSubmit}>
        <div className="col-lg-10">
          <AccountEVerify
            userRole={this.props.userRole}
            fecNumberError={
              touched.fecNumber && errors.fecNumber ? errors.fecNumber : ''
            }
            fileError={
              '' /*(
            this.state.accountIDPictureInputTouched &&
            !this.state.accountIDPicture
          ) ?
            'An ID image is required' : ''
          */
            }
            /*handleIDPictureChange={this.handleIDPictureChange}*/
          />
        </div>
        <div className="row">
          <div className="col-lg-10 pr-lg-5">
            {/*<div className="row">*/}
            {/*  <div className="col-md-6 d-flex align-items-baseline mb-4">*/}
            {/*    <img*/}
            {/*      src={*/}
            {/*        isCitizen*/}
            {/*          ? 'images/icon_citizen.png'*/}
            {/*          : 'images/icon_candidate.png'*/}
            {/*      }*/}
            {/*      alt="constituent"*/}
            {/*      height="60"*/}
            {/*      width="60"*/}
            {/*    />*/}
            {/*    <div className="role-header h3 ml-2">*/}
            {/*      {isCitizen ? 'Constituent' : 'Candidate'}*/}
            {/*    </div>*/}
            {/*  </div>*/}
            {/*</div>*/}
            <div className="row mb-md-3">
              <Input
                className={isCitizen ? 'col-md-5' : 'col-md-6'}
                label={
                  <label htmlFor="name">
                    {`${isCitizen ? 'Legal' : ''} First Name`}{' '}
                    <span className="required-asterisk">*</span>
                  </label>
                }
                name="name"
                type="text"
                error={touched.name && errors.name ? errors.name : ''}
              />
              {isCitizen && (
                <Input
                  className="col-md-2"
                  label="M.I."
                  name="middleInitial"
                  type="text"
                  maxLength="1"
                  error={
                    touched.middleInitial && errors.middleInitial
                      ? errors.middleInitial
                      : ''
                  }
                />
              )}
              <Input
                className={isCitizen ? 'col-md-5' : 'col-md-6'}
                label={
                  <label htmlFor="lastName">
                    Last Name <span className="required-asterisk">*</span>
                  </label>
                }
                name="lastName"
                type="text"
                error={
                  touched.lastName && errors.lastName ? errors.lastName : ''
                }
              />
            </div>
            <div className="row mb-md-3">
              <Input
                className={isCitizen ? 'col-md-12' : 'col-md-6'}
                label={
                  <label htmlFor="email">
                    Email Address <span className="required-asterisk">*</span>
                  </label>
                }
                name="email"
                type="email"
                error={touched.email && errors.email ? errors.email : ''}
              />
              {!isCitizen && (
                <Input
                  className="col-md-6"
                  label={
                    <label htmlFor="dateOfBirth">
                      Date of Birth <span className="required-asterisk">*</span>
                    </label>
                  }
                  name="dateOfBirth"
                  type="date"
                  maxDate={new Date()}
                  placeholder="MM/DD/YYYY"
                  error={
                    touched.dateOfBirth && errors.dateOfBirth
                      ? errors.dateOfBirth
                      : ''
                  }
                />
              )}
            </div>
            <div className="row mb-md-3">
              <Input
                className="col-md-6"
                label={
                  <label htmlFor="password">
                    Password <span className="required-asterisk">*</span>
                  </label>
                }
                name="password"
                type="password"
                error={
                  touched.password && errors.password ? errors.password : ''
                }
              />
              <Input
                className="col-md-6"
                label={
                  <label htmlFor="passwordConfirm">
                    Confirm Password{' '}
                    <span className="required-asterisk">*</span>
                  </label>
                }
                name="passwordConfirm"
                type="password"
                error={
                  touched.passwordConfirm && errors.passwordConfirm
                    ? errors.passwordConfirm
                    : ''
                }
              />
              <div className="col-md-12 pw-description fs-12 font-italic">
                {PASSWORD_DESCRIPTIVE_TEXT}
              </div>
            </div>
          </div>
        </div>
        <div className="col-6">
          <div className="mb-md-3">
            <Recaptcha
              sitekey={process.env.RAZZLE_RECAPTCHA_KEY}
              verifyCallback={this.handleRecaptchaVerify}
            />
          </div>
        </div>
        {this.state.recaptchaError ? (
          <div className="col-12 input__error-message">
            {this.state.recaptchaError}
          </div>
        ) : null}
      </form>
    );
  }

  setFormikRef = (ref: Formik) => {
    this.formikForm = ref;
  };

  getFormikProps() {
    return {
      initialValues: this.getFormikInitialValues(),
      validationSchema: yup.object().shape(this.getValidationSchema()),
      onSubmit: this.onFormSubmit,
      ref: this.setFormikRef,
    };
  }

  getValidationSchema() {
    let schema: any = {
      email: yup
        .string()
        .required('This field is required')
        .email('This field must be an email address'),
      name: yup.string().required('This field is required'),
      lastName: yup.string().required('This field is required'),
      middleInitial: yup
        .string()
        .matches(ONLY_ONE_LETTER_REGEX, ONLY_ONE_LETTER_DESCRIPTIVE_TEXT),
      password: yup
        .string()
        .required('This field is required')
        .matches(PASSWORD_REGEX, PASSWORD_DESCRIPTIVE_TEXT),
      passwordConfirm: yup
        .string()
        .required('This field is required')
        .oneOf([yup.ref('password'), null], "Passwords don't match"),
    };

    if (this.props.userRole === CANDIDATE) {
      schema.fecNumber = yup.string().required('This field is required');
      schema.dateOfBirth = yup.date().required('This field is required');
    }
    return schema;
  }

  getFormikInitialValues() {
    const defaultValues: any = {
      email: '',
      name: '',
      lastName: '',
      middleInitial: '',
      dateOfBirth: '',
      password: '',
      passwordConfirm: '',
    };

    if (this.props.userRole === CANDIDATE) {
      defaultValues.fecNumber = '';
    }

    if (this.props.initialFormValues) {
      let values = Object.assign({}, this.props.initialFormValues);

      if (this.props.userRole === CITIZEN && values.fecNumber) {
        delete values.fecNumber;
      }

      return values;
    }

    return defaultValues;
  }

  /*  handleIDPictureChange = (files: FileList) => {
      this.setState(() => ({ accountIDPicture: files && files[0] ? files[0] : null }));
    };*/

  handleRecaptchaVerify = (token: string) => {
    this.setState(() => ({ recaptchaToken: token, recaptchaError: '' }));
  };

  submitForm = () => {
    // Manually triggering touch before sending the form
    this.formikForm.setTouched({
      email: true,
      name: true,
      lastName: true,
      middleInitial: true,
      dateOfBirth: true,
      password: true,
      passwordConfirm: true,
    });

    if (!this.state.recaptchaToken) {
      this.setState(() => ({
        recaptchaError:
          'Please validate that you are not a bot by completing the captcha challenge.',
      }));
      return;
    }

    // if (!this.state.accountIDPicture) {
    //   this.setState(() => ({ accountIDPictureInputTouched: true }));
    //   return;
    // }

    this.formikForm.submitForm();
  };

  onFormSubmit = (values: FormValues) => {
    if (this.props.onFormSubmit) {
      //this.props.onFormSubmit({ values: { accountIDPicture: this.state.accountIDPicture, ...values } });
      this.props.onFormSubmit({
        values: { ...values, recaptchaToken: this.state.recaptchaToken },
      });
    }
  };
}

type AccountEVerifyProps = {
  fecNumberError?: string,
  fileError?: string,
  /*handleIDPictureChange: Function;*/
  userRole: 'citizen' | 'candidate',
};

const AccountEVerify = ({
  fecNumberError,
  /*handleIDPictureChange,*/ userRole,
  fileError,
}: AccountEVerifyProps) => {
  const isCandidate = userRole === CANDIDATE;

  return (
    <div className="col">
      <div className="row justify-content-center justify-content-xl-start">
        <div
          className={classNames({
            'signup-account-e-verify col-md-9 mx-3 ml-md-5 pr-lg-5 mb-5': true,
            'signup-account-e-verify--is-citizen': !isCandidate,
          })}>
          <div
            className={classNames({
              'account-e-verify-role-badge text-center': true,
              'account-e-verify-role-badge--is-citizen': !isCandidate,
            })}>
            <img
              src={
                isCandidate
                  ? 'images/icon_candidate.png'
                  : 'images/icon_citizen.png'
              }
              alt=""
            />
            <h2>{isCandidate ? 'Candidate' : 'Constituents'}</h2>
          </div>
          {isCandidate && (
            <div className="row align-items-end pb-4">
              <div className="col-md-7">
                <h2>E-Verify</h2>
                <Input
                  className="mb-4 mb-md-0"
                  label="FEC Number"
                  name="fecNumber"
                  type="text"
                  error={fecNumberError}
                />
              </div>
            </div>
          )}
          {/*<div className="row">
            <div className="col-12 px-0">
              <FileInput
                onChange={handleIDPictureChange}
                placeholder={ isCandidate ? 'Upload a valid US passport or state driver\'s license. To prevent fake accounts and bots, and to provide a higher standard for Federal Election compliance, we require your photo ID. Prytany will never sell your information.' : 'US passport or State Issued ID. To prevent fake accounts and bots, and to provide a higher standard for Federal Election compliance, we require your photo ID. Prytany will never sell your information.'}
                type="transparent"
                className={`pb-5 signup-account-e-verify__file-input signup-account-e-verify__file-input--${userRole}`}
                labelClassName="signup-account-e-verify__file-input-label"
                error={fileError}
              />
            </div>
          </div>*/}
        </div>
      </div>
    </div>
  );
};

export default SignUpAccountBasicsForm;
