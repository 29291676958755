import {
  userProfileGroupsReset as reset,
  getUserProfileGroups,
} from './userProfileGroups.actions';
import type { GetGroupsPayload } from '../../../services/RoRProfileApiProvider';

export const mapStateToProps = state => {
  return {
    profileGroupsData: state.userProfileGroups.data,
    profileGroupsError: state.userProfileGroups.error,
    profileGroupsIsLoading: state.userProfileGroups.isLoading,
  };
};

export const mapDispatchToProps = dispatch => {
  return {
    getUserProfileGroups(payload: GetGroupsPayload) {
      dispatch(getUserProfileGroups(payload));
    },
    profileGroupsReset() {
      dispatch(reset());
    },
  };
};

export type UserProfileGroupsMapStateToProps = {
  profileGroupsData: any,
  profileGroupsError: any,
  profileGroupsIsLoading: boolean,
};

export type UserProfileGroupsMapDispatchToProps = {
  profileGroupsProfile: Function,
  profileGroupsReset: Function,
};

export default {
  mapStateToProps,
  mapDispatchToProps,
};
