// @flow
import { createAction } from 'redux-actions';
import {
  GROUP_INVITE_BY_CONTACT_ERROR,
  GROUP_INVITE_BY_CONTACT_LOADING,
  GROUP_INVITE_BY_CONTACT_RESET,
  GROUP_INVITE_BY_CONTACT_SUCCESS,
} from './groupInviteByContact.constatnts';
import { instance as rorGroupsApiProvider } from '../../../services/RoRGroupsApiProvider';
import type { PostInviteByContactPayload } from '../../../services/RoRGroupsApiProvider';

export const groupInviteByContactError = createAction(
  GROUP_INVITE_BY_CONTACT_ERROR,
);

export const groupInviteByContactLoading = createAction(
  GROUP_INVITE_BY_CONTACT_LOADING,
);

export const groupInviteByContactSuccess = createAction(
  GROUP_INVITE_BY_CONTACT_SUCCESS,
);

export const groupInviteByContactReset = createAction(
  GROUP_INVITE_BY_CONTACT_RESET,
);

export const groupInviteByContact = (payload: PostInviteByContactPayload) => {
  return async (dispatch: Function) => {
    try {
      dispatch(groupInviteByContactLoading());

      const response = await rorGroupsApiProvider.groupInviteByContact(payload);

      dispatch(groupInviteByContactSuccess(response));
    } catch (error) {
      dispatch(groupInviteByContactError(error));
    }
  };
};
