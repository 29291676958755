// @flow
import { createAction } from 'redux-actions';
import {
  LIST_ROLES_ERROR,
  LIST_ROLES_LOADING,
  LIST_ROLES_SUCCESS,
  LIST_ROLES_RESET,
} from './listRoles.constants';
import { instance as rorApiProvider } from '../../../services/RoRApiProvider';

export const listRolesError = createAction(LIST_ROLES_ERROR);

export const listRolesLoading = createAction(LIST_ROLES_LOADING);

export const listRolesSuccess = createAction(LIST_ROLES_SUCCESS);

export const listRolesReset = createAction(LIST_ROLES_RESET);

export const listRoles = (payload: { interest: string }) => {
  return async (dispatch: Function) => {
    try {
      dispatch(listRolesLoading());

      const response = await rorApiProvider.listBasedOnTypes({ type: 'roles' });

      dispatch(listRolesSuccess(response));
    } catch (error) {
      dispatch(listRolesError(error));
    }
  };
};
