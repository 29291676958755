// @flow
import { createAction } from 'redux-actions';
import {
  USER_FOLLOW_ERROR,
  USER_FOLLOW_LOADING,
  USER_FOLLOW_RESET,
  USER_FOLLOW_SUCCESS,
  USER_UNFOLLOW_ERROR,
  USER_UNFOLLOW_LOADING,
  USER_UNFOLLOW_SUCCESS,
} from './userFollow.constants';
import { instance as rorPostsApiProvider } from '../../../services/RoRUsersApiProvider';
import type {
  PostUnFollowUserPayload,
  PostFollowUserPayload,
} from '../../../services/RoRUsersApiProvider';
import {
  userDetailsSetAsFollowed,
  userDetailsSetAsUnfollowed,
} from '../userDetails/userDetails.actions';
import {
  accountFollowingsAdd,
  accountFollowingsRemove,
} from '../accountFollowings/accountFollowings.actions';

export const userFollowError = createAction(USER_FOLLOW_ERROR);

export const userFollowLoading = createAction(USER_FOLLOW_LOADING);

export const userFollowSuccess = createAction(USER_FOLLOW_SUCCESS);

export const userFollowReset = createAction(USER_FOLLOW_RESET);

export const userUnFollowError = createAction(USER_UNFOLLOW_ERROR);

export const userUnFollowLoading = createAction(USER_UNFOLLOW_LOADING);

export const userUnFollowSuccess = createAction(USER_UNFOLLOW_SUCCESS);

export const userFollow = (payload: PostFollowUserPayload) => {
  return async (dispatch: Function) => {
    try {
      let response = {};
      dispatch(userFollowLoading());

      if (payload.userId) {
        response = await rorPostsApiProvider.followUser(payload);
        dispatch(userDetailsSetAsFollowed(response));
        dispatch(accountFollowingsAdd(response));
      } else {
        return dispatch(userFollowError(new Error('userId is required.')));
      }

      dispatch(userFollowSuccess(response));
    } catch (error) {
      dispatch(userFollowError(error));
    }
  };
};

export const userUnFollow = (payload: PostUnFollowUserPayload) => {
  return async (dispatch: Function) => {
    try {
      let response = {};
      dispatch(userUnFollowLoading());

      if (payload.userId) {
        response = await rorPostsApiProvider.unfollowUser(payload);
        dispatch(userDetailsSetAsUnfollowed(response));
        dispatch(accountFollowingsRemove(response));
      } else {
        return dispatch(userUnFollowError(new Error('userId is required.')));
      }

      dispatch(userUnFollowSuccess(response));
    } catch (error) {
      dispatch(userUnFollowError(error));
    }
  };
};
