//@flow
import {
  getGroupMemberContributions,
  groupMemberContributionsReset,
} from './groupMemberContributions.actions';
import type {
  GetGroupMemberContributionsPayload,
  GetGroupMemberContributionsResponse,
} from '../../../services/RoRGroupsApiProvider';

export const mapStateToProps = (state: any) => {
  return {
    groupMemberContributionsData: state.groupMemberContributions.data,
    groupMemberContributionsError: state.groupMemberContributions.error,
    groupMemberContributionsIsLoading: state.groupMemberContributions.isLoading,
  };
};

export const mapDispatchToProps = (dispatch: Function) => {
  return {
    getGroupMemberContributions(payload: GetGroupMemberContributionsPayload) {
      dispatch(getGroupMemberContributions(payload));
    },
    groupMemberContributionsReset() {
      dispatch(groupMemberContributionsReset());
    },
  };
};

export type GroupMemberContributionsMapStateToProps = {
  groupMemberContributionsData: GetGroupMemberContributionsResponse,
  groupMemberContributionsError: any,
  groupMemberContributionsIsLoading: boolean,
};

export type GroupMemberContributionsMapDispatchToProps = {
  getGroupMemberContributions: (
    payload: GetGroupMemberContributionsPayload,
  ) => void,
  groupMemberContributionsReset: () => void,
};

export default {
  mapStateToProps,
  mapDispatchToProps,
};
