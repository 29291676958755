// @flow
import { createAction } from 'redux-actions';
import {
  ACCOUNT_CANDIDATE_PLEDGES_ERROR,
  ACCOUNT_CANDIDATE_PLEDGES_LOADING,
  ACCOUNT_CANDIDATE_PLEDGES_RESET,
  ACCOUNT_CANDIDATE_PLEDGES_SUCCESS,
} from './accountCandidatePledges.constants';
import { instance as rorGroupsApiProvider } from '../../../services/RoRUsersApiProvider';
import type { GetCandidatePledgesPayload } from '../../../services/RoRUsersApiProvider';

export const accountCandidatePledgesError = createAction(
  ACCOUNT_CANDIDATE_PLEDGES_ERROR,
);

export const accountCandidatePledgesLoading = createAction(
  ACCOUNT_CANDIDATE_PLEDGES_LOADING,
);

export const accountCandidatePledgesSuccess = createAction(
  ACCOUNT_CANDIDATE_PLEDGES_SUCCESS,
);

export const accountCandidatePledgesReset = createAction(
  ACCOUNT_CANDIDATE_PLEDGES_RESET,
);

export const getAccountCandidatePledges = (
  payload: GetCandidatePledgesPayload,
) => {
  return async (dispatch: Function) => {
    try {
      dispatch(accountCandidatePledgesLoading());

      const response = await rorGroupsApiProvider.accountCandidatePledges(
        payload,
      );

      dispatch(accountCandidatePledgesSuccess(response));
    } catch (error) {
      dispatch(accountCandidatePledgesError(error));
    }
  };
};
