// @flow
import * as React from 'react';
import * as ReactDOM from 'react-dom';
import DatePicker from 'react-datepicker';
import { Field } from 'formik';
import classNames from 'classnames';
import Button from '../Button/Button';
import './Input.scss';
import parseDateOrNull from '../../utilities/parseDateOrNull';
import formatDateOrNull from '../../utilities/formatDateOrNull';

type Props = {
  className?: string,
  inputClassName?: string,
  error?: string,
  useOnlyBorderedErrors?: boolean,
  label?: any,
  name: string,
  placeholder?: string,
  type: string,
  children?: any,
  size?: 'normal' | 'small',
  useFormik?: boolean,
  addonButtonProps?: any,
  addonButtonContent?: any,
  addonButtonResetInputOnClick?: boolean,
  icon?: string,
  isDisabled?: boolean,
};

class Input extends React.Component<Props> {
  static defaultProps = {
    size: 'normal',
    useFormik: true,
    isDisabled: false,
    addonButtonProps: {},
    addonButtonResetInputOnClick: false,
    useOnlyBorderedErrors: false,
  };

  render() {
    const { useFormik, ...rest } = this.props;

    if (this.props.type === 'date') {
      return <InputDate {...rest} />;
    } else if (useFormik) {
      return <InputFormik {...rest} />;
    } else {
      return <InputIsolated {...rest} />;
    }
  }

  focus() {
    const inputWrapperNode: any = ReactDOM.findDOMNode(this);

    if (inputWrapperNode) {
      inputWrapperNode.querySelector('input').focus();
    }
  }
}

class InputFormik extends React.Component<Props> {
  render() {
    const {
      className,
      isDisabled,
      addonButtonContent,
      addonButtonProps,
      addonButtonResetInputOnClick,
      useFormik,
      useOnlyBorderedErrors,
      label,
      size,
      icon,
      inputClassName,
      children,
      ...rest
    } = this.props;
    return (
      <div className={getClassName(this.props)}>
        <InputLabel id={`select-${this.props.name}`} label={this.props.label} />
        <Field
          className={getInputClassName(this.props)}
          disabled={isDisabled}
          {...rest}
        />
        {children || null}
        <InputAddon
          inputValue="read formik field value"
          onClick={(value, name) => this.handleAddonButtonClick(value, name)}
          {...this.props}
        />
        {!this.props.useOnlyBorderedErrors ? (
          <InputError error={this.props.error} />
        ) : null}
      </div>
    );
  }

  handleAddonButtonClick(value: string, name: string) {
    if (this.props.addonButtonProps && this.props.addonButtonProps.onClick) {
      this.props.addonButtonProps.onClick({ value, name });
    }
  }
}

type InputDateProps = Props & {
  format?: string,
  minDate?: Date,
  maxDate?: Date,
  onChange?: ({ name: string, value: string }) => void,
};

class InputDate extends React.Component<InputDateProps> {
  render() {
    const {
      isDisabled,
      format = 'MM/DD/YYYY',
      minDate = null,
      maxDate = null,
      ...rest
    } = this.props;
    return (
      <div className={getClassName(this.props)}>
        <InputLabel id={`select-${this.props.name}`} label={this.props.label} />
        <Field
          disabled={isDisabled}
          render={({ field, form }) => {
            return (
              <DatePicker
                className={getInputClassName(this.props)}
                selected={parseDateOrNull(field.value, format)}
                minDate={minDate}
                maxDate={maxDate}
                onChange={value => {
                  form.setFieldValue(
                    this.props.name,
                    formatDateOrNull(value, format),
                  );
                  if (this.props.onChange) {
                    this.props.onChange({
                      name: this.props.name,
                      value: value,
                    });
                  }
                }}
              />
            );
          }}
          {...rest}
        />
        {!this.props.useOnlyBorderedErrors ? (
          <InputError error={this.props.error} />
        ) : null}
      </div>
    );
  }
}

type InputIsolatedProps = Props & { onChange?: Function, value?: string };
type InputIsolatedState = { value: string };

class InputIsolated extends React.Component<
  InputIsolatedProps,
  InputIsolatedState,
> {
  inputId: string;

  constructor(props) {
    super(props);

    this.inputId = `input-${props.name}`;
    this.state = {
      value: this.props.value || '',
    };
  }

  componentDidUpdate(prevProps) {
    if (
      prevProps.value !== this.props.value &&
      this.state.value !== this.props.value
    ) {
      this.setState(() => ({ value: this.props.value }));
    }
  }

  render() {
    const {
      className,
      onChange,
      addonButtonContent,
      addonButtonProps,
      addonButtonResetInputOnClick,
      label,
      error,
      children,
      size,
      inputClassName,
      icon,
      useOnlyBorderedErrors,
      ...rest
    } = this.props;

    return (
      <div className={getClassName(this.props)}>
        <InputLabel id={this.inputId} label={label} />
        {icon ? <img src={icon} alt="" className="input__icon" /> : null}
        <input
          className={getInputClassName(this.props)}
          id={this.inputId}
          onChange={this.handleChange}
          value={this.state.value}
          {...rest}
        />
        <InputAddon
          {...this.props}
          inputValue={this.state.value}
          onClick={this.handleAddonButtonClick}
        />
        {children || null}
        {!useOnlyBorderedErrors ? <InputError error={error} /> : null}
      </div>
    );
  }

  handleChange = (e: any) => {
    const value = e.target.value;

    this.setState(
      () => ({ value }),
      () => {
        if (this.props.onChange) {
          this.props.onChange({
            name: this.props.name,
            value: this.state.value,
          });
        }
      },
    );
  };

  handleAddonButtonClick = (inputValue: string, name: string) => {
    if (this.props.addonButtonProps && this.props.addonButtonProps.onClick) {
      this.props.addonButtonProps.onClick({ value: inputValue, name });
    }

    if (this.props.addonButtonResetInputOnClick) {
      this.setState({ value: '' });
    }
  };
}

const InputLabel = ({ label, id }: { label: any, id: string }) => {
  if (label && typeof label === 'string') {
    return <label htmlFor={id}>{label}</label>;
  }

  return label ? <div className="input__custom-label">{label}</div> : null;
};

const InputAddon = (
  props: Props & { inputValue: string, onClick?: Function },
) => {
  const {
    addonButtonProps,
    addonButtonContent,
    size,
    inputValue,
    name,
  } = props;
  //$FlowFixMe
  const { onClick: parentClickHandler, ...rest } = addonButtonProps;

  return addonButtonContent ? (
    <Button
      block
      size={size}
      buttonType="primary"
      onClick={() => {
        if (props.onClick) props.onClick(inputValue, name);
      }}
      className="input__addon-button"
      {...rest}>
      {addonButtonContent}
    </Button>
  ) : null;
};

const InputError = ({ error }: { error?: string }) =>
  error ? <span className="input__error-message">{error}</span> : null;

function getClassName(props) {
  return classNames({
    'form-group': true,
    input: true,
    'input--with-icon': props.icon,
    [`input--${props.size || ''}`]: true,
    [props.className || '']: props.className,
  });
}

function getInputClassName(props) {
  return classNames({
    'form-control': true,
    'form-control--error': props.error,
    'input--date-picker': props.type === 'date',
    [props.inputClassName || '']: props.inputClassName,
  });
}

export default Input;
