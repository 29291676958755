import * as React from 'react';
import Loading from '../Loading/Loading';
import Button from '../Button/Button';

import './CreditCardsList.scss';

type Props = {
  loading: boolean,
  sources: {
    stripe_source_id: string,
    source_type: string,
    credit_card_type: string,
    credit_card_number: string,
    status: string,
    exp_month: number,
    exp_year: number,
  }[],
};

class CreditCardsList extends React.Component<Props, State> {
  constructor(props: Props) {
    super(props);
  }

  render() {
    return (
      <React.Fragment>
        <Loading loading={this.props.loading} type="spinner">
          <ul className="credit-card-list">{this.renderCreditCards()}</ul>
        </Loading>
      </React.Fragment>
    );
  }

  renderCreditCards() {
    if (this.props.sources.length) {
      return this.props.sources.map((source, key) => {
        return (
          <li key={key} className="credit-card-list__item">
            <div className="credit-card-list__item-line">
              <img
                src="/images/icon_visa.png"
                className="img-fluid credit-card-list__item-image"
                alt=""
              />
              {source.credit_card_number}
            </div>
          </li>
        );
      });
    } else {
      return (
        <li className="credit-card-list__item credit-card-list__empty-message">
          You have no credit cards added yet
        </li>
      );
    }
  }
}

export default CreditCardsList;
