import { listRoles, listRolesReset as reset } from './listRoles.actions';

export const mapStateToProps = state => {
  return {
    listRolesData: state.listRoles.data,
    listRolesError: state.listRoles.error,
    listRolesIsLoading: state.listRoles.isLoading,
  };
};

export const mapDispatchToProps = dispatch => {
  return {
    getListRoles() {
      dispatch(listRoles());
    },
    listRolesReset() {
      dispatch(reset());
    },
  };
};

export type ListRolesMapStateToProps = {
  listRolesData: { roles: { id: number, code?: string, name: string }[] },
  listRolesError: any,
  listRolesIsLoading: boolean,
};

export type ListRolesMapDispatchToProps = {
  getListRoles: Function,
  listRolesReset: Function,
};

export default {
  mapStateToProps,
  mapDispatchToProps,
};
