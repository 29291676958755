//@flow
export const isFirst = <T>(steps: T[], step: T) => {
  return steps.indexOf(step) === 0;
};

export const isLast = <T>(steps: T[], step: T) => {
  return steps.indexOf(step) === steps.length - 1;
};

export const previous = <T>(steps: T[], currentStep: T): T => {
  const currentIndex = steps.indexOf(currentStep);
  return steps[currentIndex > 0 ? currentIndex - 1 : currentIndex];
};

export const next = <T>(steps: T[], currentStep: T): T => {
  const currentIndex = steps.indexOf(currentStep);
  return steps[
    currentIndex < steps.length - 1 ? currentIndex + 1 : currentIndex
  ];
};
