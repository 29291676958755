//@flow
import * as React from 'react';

import './TabPanelActionable.scss';
import TabPanel from '../TabPanel/TabPanel';
import Currency from '../Currency/Currency';
import classNames from 'classnames';
import Button from '../Button/Button';
import Avatar from '../Avatar/Avatar';

type Props = {
  className?: string,
  canDonate?: boolean,
  centered?: boolean,
  contributed?: number,
  committed?: number,
  initialActiveIndex?: number,
  isSticky: boolean,
  items: any,
  name: string,
  pledged?: number,
  profile_image: string,
  role?: string,
  onTabChange: Function,
  onActionButtonClick?: Function,
};

class TabPanelActionable extends React.Component<Props, null> {
  constructor(props: Props) {
    super(props);
  }

  render() {
    return (
      <div className={this.getClassNames()}>
        {this.renderContributions()}
        {this.renderTabs()}
      </div>
    );
  }

  renderContributions() {
    let content = null;

    if (!this.props.isSticky) {
      content =
        this.props.role === 'Candidate' ? (
          <ul className="row text-center tab-panel-actionable__contributions fix-width">
            <li className="col-4 tab-panel-actionable__contribution-type">
              <span className="tab-panel-actionable__contribution-title">
                CONTRIBUTED
              </span>
              <Currency
                amount={parseFloat(this.props.contributed)}
                showDecimals={true}
                className="tab-panel-actionable__contribution-amount"
              />
            </li>
            <li className="col-4 tab-panel-actionable__contribution-type">
              <span className="tab-panel-actionable__contribution-title">
                COMMITTED
              </span>
              <Currency
                amount={parseFloat(this.props.committed)}
                showDecimals={true}
                className="tab-panel-actionable__contribution-amount"
              />
            </li>
            <li className="col-4 tab-panel-actionable__contribution-type">
              <span className="tab-panel-actionable__contribution-title">
                PLEDGED
              </span>
              <Currency
                amount={parseFloat(this.props.pledged)}
                showDecimals={true}
                className="tab-panel-actionable__contribution-amount"
              />
            </li>
          </ul>
        ) : (
          <ul className="row text-center tab-panel-actionable__contributions fix-width">
            {/*TODO: update with real data once citizen contributions is returned from the server*/}
            <li className="col-4 tab-panel-actionable__contribution-type">
              <Currency
                amount={parseFloat(this.props.contributed)}
                showDecimals={true}
                className="tab-panel-actionable__contribution-amount"
              />
              <span className="tab-panel-actionable__contribution-title">
                DIRECT
              </span>
            </li>
            <li className="col-4 tab-panel-actionable__contribution-type">
              <Currency
                amount={parseFloat(this.props.committed)}
                showDecimals={true}
                className="tab-panel-actionable__contribution-amount"
              />
              <span className="tab-panel-actionable__contribution-title">
                COMMITTED
              </span>
            </li>
            <li className="col-4 tab-panel-actionable__contribution-type">
              <Currency
                amount={parseFloat(this.props.pledged)}
                showDecimals={true}
                className="tab-panel-actionable__contribution-amount"
              />
              <span className="tab-panel-actionable__contribution-title">
                PLEDGED
              </span>
            </li>
          </ul>
        );
    }

    return content;
  }

  renderTabs() {
    const isCandidate = this.props.role === 'Candidate';
    const hasTabPanel = this.props.items.length > 1 && isCandidate;
    let content = null;
    const tabs = hasTabPanel ? (
      <TabPanel {...this.getTabPanelProps()} />
    ) : (
      <div className="col-4" />
    );

    if (this.props.isSticky) {
      content = isCandidate ? (
        <div
          className={`row tab-panel-actionable__menu-container ${
            hasTabPanel ? 'pt-2' : 'fix-width pt-md-2'
          }`}>
          <div className="col-4 tab-panel-actionable__menu-item">
            <Avatar
              source={this.props.profile_image}
              type="tiny"
              className="tab-panel-actionable__menu-image"
            />
            <span className="tab-panel-actionable__menu-name">
              {this.props.name}
            </span>
            <img
              src="/images/icon_candidate_big.png"
              className="tab-panel-actionable__menu-badge"
            />
          </div>
          {tabs}
          <div className="col-3 tab-panel-actionable__menu-item">
            {this.props.canDonate ? (
              <Button {...this.getContributeButtonProps()}>Contribute</Button>
            ) : null}
          </div>
        </div>
      ) : (
        <div
          className={`row tab-panel-actionable__menu-container ${
            hasTabPanel ? 'pt-3' : 'fix-width pt-md-3'
          }`}>
          <div className="col-4 tab-panel-actionable__menu-item">
            <Avatar
              source={this.props.profile_image}
              type="tiny"
              className="tab-panel-actionable__menu-image"
            />
            <span className="tab-panel-actionable__menu-name">
              {this.props.name}
            </span>
          </div>
          {tabs}
          <div className="col-4 tab-panel-actionable__menu-item">
            <Button
              type="button"
              buttonType="primary"
              className="tab-panel-actionable__menu-button">
              Invite to Group
            </Button>
          </div>
        </div>
      );
    } else {
      content = (
        <div className="row tab-panel-actionable__menu-container fix-width">
          <div className="col-4" />
          {tabs}
          <div className="col-4" />
        </div>
      );
    }

    return content;
  }

  getTabPanelProps() {
    return {
      items: this.props.items,
      onTabChange: this.props.onTabChange,
      centered: true,
      className: 'col-4',
      initialActiveIndex: this.props.initialActiveIndex,
    };
  }

  getContributeButtonProps() {
    let props: any = {
      type: 'button',
      buttonType: 'primary',
      className: 'tab-panel-actionable__menu-button',
    };

    if (
      this.props.onActionButtonClick &&
      typeof this.props.onActionButtonClick === 'function'
    ) {
      props.onClick = this.props.onActionButtonClick;
    }

    return props;
  }

  getClassNames() {
    return classNames({
      'tab-panel-actionable': true,
      'tab-panel-actionable--no-tabs': this.props.role === 'Citizen',
      'tab-panel-actionable--fixed': this.props.isSticky,
      [this.props.className || '']: this.props.className,
    });
  }
}

export default TabPanelActionable;
