// @flow
const SearchType = Object.freeze({
  CANDIDATES: 'candidates',
  CITIZENS: 'citizens',
  POLITICAL_PARTIES: 'political_parties',
  PACS: 'pacs',
  GROUPS: 'groups',
  RACES: 'races',
});

export default SearchType;
