//@flow
import React from 'react';
import moment from 'moment';
import _get from 'lodash/get';
import { Pagination } from 'antd';
import { connect } from 'react-redux';
import './SearchPage.scss';
import { Formik, FormikComputedProps } from 'formik';
import type {
  SearchMapDispatchToProps,
  SearchMapStateToProps,
} from '../../redux/modules/search/search.containers';
import {
  mapStateToProps as searchMapStateToProps,
  mapDispatchToProps as searchMapDispatchToProps,
} from '../../redux/modules/search/search.containers';
import {
  URL_GROUP_PAGE,
  URL_RACE_ACTIVITY_PAGE,
  URL_USER_PUBLIC_PROFILE,
} from '../../config/urls';
import * as yup from 'yup';
import Input from '../../components/Input/Input';
import Link from '../../components/Link/Link';
import Loading from '../../components/Loading/Loading';
import RadioButtons from '../../components/RadioButtons/RadioButtons';
import Avatar from '../../components/Avatar/Avatar';
import SearchType from '../../constants/SearchType';
import queryString from 'query-string';
import { isPac } from '../../utilities/authorization';
import isPrytanyVerified from '../../utilities/isPrytanyVerified';

type Props = SearchMapDispatchToProps & SearchMapStateToProps;

type State = {
  searchType: $Values<typeof SearchType>,
  currentPage: number,
  currentSearchTerm: string,
  forceSearch: boolean,
};

const MAX_RESULTS_PER_PAGE = 10;

class SearchPage extends React.Component<Props, State> {
  constructor(props: Props) {
    super(props);
    this.props.searchReset();
    const params = queryString.parse(this.props.location.search);
    this.state = {
      searchType: params.searchType || SearchType.CANDIDATES,
      currentPage: 1,
      currentSearchTerm: '',
      forceSearch: false,
    };
  }

  componentDidMount() {
    this.callSearchService();
  }

  componentDidUpdate(prevProps: Props, prevState: State) {
    const {
      searchType,
      currentPage,
      currentSearchTerm,
      forceSearch,
    } = this.state;

    if (
      searchType !== prevState.searchType ||
      currentPage !== prevState.currentPage ||
      currentSearchTerm !== prevState.currentSearchTerm ||
      forceSearch
    ) {
      this.setState(
        () => ({ forceSearch: false }),
        () => {
          this.callSearchService();
        },
      );
    }
  }

  render() {
    return (
      <section className="search-page content">
        <div className="container-fluid">
          <div className="fix-width">
            <div className="row">
              <div className="col">
                <Formik {...this.getFormikProps()}>
                  {props => this.renderInnerForm(props)}
                </Formik>
              </div>
            </div>
            <div className="row search-page__results pt-3">
              <div className="col-12 px-4">{this.renderSearchResults()}</div>
            </div>
          </div>
        </div>
      </section>
    );
  }

  renderInnerForm(props: FormikComputedProps) {
    const { touched, errors, handleSubmit, values } = props;

    return (
      <form className="row justify-content-md-center" onSubmit={handleSubmit}>
        <div className="col">
          <div className="row">
            <div className="col-md-12">
              <Input
                name="search"
                placeholder="Enter your search"
                type="text"
                error={touched.search && errors.search ? errors.search : ''}
                addonButtonContent="Search"
                addonButtonProps={{
                  disabled: !values.search || this.props.searchIsLoading,
                  type: 'submit',
                }}
                addonButtonResetInputOnClick={true}
              />
            </div>
          </div>
          <div className="row">
            <div className="col-md-12">
              <div className="form-group">
                <RadioButtons
                  label="Search for:"
                  className="col-12"
                  items={[
                    { label: 'Candidates', value: SearchType.CANDIDATES },
                    { label: 'Constituents', value: SearchType.CITIZENS },
                    {
                      label: 'Political Parties',
                      value: SearchType.POLITICAL_PARTIES,
                    },
                    { label: 'PACs', value: SearchType.PACS },
                    { label: 'Groups', value: SearchType.GROUPS },
                    { label: 'Races', value: SearchType.RACES },
                  ]}
                  name="searchType"
                  value={this.state.searchType}
                  useFormik={false}
                  onChange={this.handleSearchTypeChange}
                  error=""
                />
              </div>
            </div>
          </div>
        </div>
      </form>
    );
  }

  renderSearchResults() {
    const { searchType } = this.state;

    let content = null;
    if (this.props.searchIsLoading || this.props.searchData) {
      if (searchType === SearchType.CANDIDATES) {
        content = this.renderCandidatesResults();
      } else if (searchType === SearchType.CITIZENS) {
        content = this.renderConstituentsResults();
      } else if (
        searchType === SearchType.POLITICAL_PARTIES ||
        searchType === SearchType.PACS
      ) {
        content = this.renderPoliticalPartiesResult();
      } else if (searchType === SearchType.GROUPS) {
        content = this.renderGroupsResults();
      } else if (searchType === SearchType.RACES) {
        content = this.renderRacesResults();
      }
    }

    return (
      <Loading loading={this.props.searchIsLoading}>
        {content}
        {this.renderPagination()}
      </Loading>
    );
  }

  renderCandidatesResults() {
    let content = null;
    const users = _get(this.props, 'searchData.data.users');

    if (users) {
      content = users.map(candidate => (
        <Link
          key={candidate.id}
          href={URL_USER_PUBLIC_PROFILE.replace(':id', candidate.id)}>
          <div className="row mb-3">
            <div className="col-md-6 col-sm-12">
              <div className="row">
                <div className="col-12 d-flex align-items-center">
                  <Avatar
                    type="small"
                    source={candidate.profile_image}
                    isCandidate={true}
                  />
                  <h5 className="px-3 search-page__result-name">
                    {`${candidate.first_name} ${candidate.last_name}`}
                    {candidate.exploratory_committee_profile ? (
                      <span className="search-page__exploratory-indicator">
                        &bull; Exploratory Committee
                      </span>
                    ) : null}
                  </h5>
                </div>
              </div>
            </div>
          </div>
        </Link>
      ));
    } else {
      content = 'No results. Please try a different search.';
    }

    return content;
  }

  renderConstituentsResults() {
    let content = null;
    const users = _get(this.props, 'searchData.data.users');

    if (users) {
      content = users.map(constituent => (
        <Link
          key={constituent.id}
          href={URL_USER_PUBLIC_PROFILE.replace(':id', constituent.id)}>
          <div className="row mb-3">
            <div className="col-md-6 col-sm-12">
              <div className="row">
                <div className="col-12 d-flex align-items-center">
                  <Avatar
                    type="small"
                    source={constituent.profile_image}
                    verifiedLevel={constituent.verified_level}
                    verifiedDetail={constituent.verified_detail}
                  />
                  <h5 className="px-3 search-page__result-name">{`${
                    constituent.first_name
                  } ${constituent.last_name}`}</h5>
                </div>
              </div>
            </div>
          </div>
        </Link>
      ));
    } else {
      content = 'No results. Please try a different search.';
    }

    return content;
  }

  renderPoliticalPartiesResult() {
    let content = null;
    const users = _get(this.props, 'searchData.data.users');

    if (users) {
      content = users.map(party => (
        <Link
          href={URL_USER_PUBLIC_PROFILE.replace(':id', party.id)}
          key={party.id}>
          <div className="row mb-3" key={party.id}>
            <div className="col-md-6 col-sm-12">
              <div className="row">
                <div className="col-12 d-flex align-items-center">
                  <Avatar
                    type="medium"
                    source={party.profile_image}
                    isCandidate={true}
                  />
                  <div className="d-flex flex-column px-3">
                    <h5 className="search-page__result-name">
                      {`${party.first_name}`}
                    </h5>
                    {isPac(party) && (
                      <div className="search-page__result-type">
                        {party.role.name}
                      </div>
                    )}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </Link>
      ));
    } else {
      content = 'No results. Please try a different search.';
    }

    return content;
  }

  renderGroupsResults() {
    let content = null;
    const groups = _get(this.props, 'searchData.data.groups');

    if (groups) {
      content = groups.map((group, id) => (
        <Link href={URL_GROUP_PAGE.replace(':groupId', group.id)}>
          <div className="row mb-3" key={id}>
            <div className="col-md-6 col-sm-12">
              <div className="row align-items-center">
                <div className="col-12 d-flex align-items-center">
                  <Avatar type="small" source={group.group_image} />
                  <h5 className="px-3 search-page__result-name">
                    {group.name}
                  </h5>
                </div>
              </div>
            </div>
          </div>
        </Link>
      ));
    } else {
      content = 'No results. Please try a different search.';
    }

    return content;
  }

  renderRacesResults() {
    let content = null;
    const races = _get(this.props, 'searchData.data.races');

    if (races) {
      content = races.map((race, id) => (
        <div className="row mb-3" key={id}>
          <div className="col-12">
            <h5 className="search-page__result-name">
              <Link href={URL_RACE_ACTIVITY_PAGE.replace(':raceId', race.id)}>
                {`${race.name} ${moment(race.due_date).format('YYYY')}`}
              </Link>
            </h5>
          </div>
          <div className="col-12">
            Due date: {moment(race.due_date).format('MMMM Do YYYY')}
          </div>
        </div>
      ));
    } else {
      content = 'No results. Please try a different search.';
    }

    return content;
  }

  renderPagination() {
    const totalPages = _get(this.props, 'searchData.total_pages');

    return totalPages && totalPages > 1 ? (
      <div className="row">
        <div className="col-12">
          <Pagination
            size="small"
            current={this.state.currentPage}
            onChange={this.handlePageChange}
            total={totalPages * MAX_RESULTS_PER_PAGE}
            pageSize={MAX_RESULTS_PER_PAGE}
          />
        </div>
      </div>
    ) : null;
  }

  getFormikProps() {
    return {
      initialValues: this.props.initialFormValues || {
        search: '',
        searchType: this.state.searchType,
      },
      validationSchema: yup.object().shape({
        search: yup.string().required('This field is required'),
      }),
      onSubmit: this.onFormSubmit,
      isSubmitting: this.props.isLoading,
    };
  }

  handlePageChange = (pageNumber: number) => {
    this.setState(() => ({ currentPage: pageNumber }));
  };

  handleSearchTypeChange = event => {
    this.setState(() => ({ searchType: event.value, currentPage: 1 }));
  };

  callSearchService() {
    this.props.search({
      search: (this.state.currentSearchTerm || '').trim(),
      searchType: this.state.searchType,
      page: this.state.currentPage,
      limit: MAX_RESULTS_PER_PAGE,
    });
  }

  onFormSubmit = (values: { search: string, searchType: string }) => {
    if (this.props.searchIsLoading) return;

    this.setState(() => ({
      currentSearchTerm: values.search,
      currentPage: 1,
      forceSearch: true,
    }));
  };
}

const mapStateToProps = (state, ownProps) => {
  return {
    ...searchMapStateToProps(state),
    ...ownProps,
  };
};

const mapDispatchToProps = dispatch => {
  return {
    ...searchMapDispatchToProps(dispatch),
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(SearchPage);
