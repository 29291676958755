import {
  getGroupModerators,
  getGroupModeratorsNextPage,
  groupModeratorsReset as reset,
} from './groupModerators.actions';
import type {
  GetGroupModeratorsPayload,
  GetGroupModeratorsResponse,
} from '../../../services/RoRGroupsApiProvider';

export const mapStateToProps = state => {
  return {
    groupModeratorsData: state.groupModerators.data,
    groupModeratorsError: state.groupModerators.error,
    groupModeratorsIsLoading: state.groupModerators.isLoading,
  };
};

export const mapDispatchToProps = dispatch => {
  return {
    getModeratorsForGroup(payload: GetGroupModeratorsPayload) {
      dispatch(getGroupModerators(payload));
    },
    getModeratorsForGroupNextPage(payload: GetGroupModeratorsPayload) {
      dispatch(getGroupModeratorsNextPage(payload));
    },
    groupModeratorsReset() {
      dispatch(reset());
    },
  };
};

export type GroupModeratorsMapStateToProps = {
  groupModeratorsData: GetGroupModeratorsResponse,
  groupModeratorsError: any,
  groupModeratorsIsLoading: boolean,
};

export type GroupModeratorsMapDispatchToProps = {
  getModeratorsForGroup: (payload: GetGroupModeratorsPayload) => void,
  getModeratorsForGroupNextPage: (payload: GetGroupModeratorsPayload) => void,
  groupModeratorsReset: () => void,
};

export default {
  mapStateToProps,
  mapDispatchToProps,
};
