// @flow
import React from 'react';
import './FollowButton.scss';
import Button from '../Button/Button';

type Props = {
  isFollowing: boolean,
  onClick: (isFollowing: boolean) => void,
};

const FollowButton = ({ isFollowing, onClick, ...rest }: Props) => {
  return (
    <div className="follow-button">
      <Button
        className={`follow-button__button ${
          isFollowing ? 'follow-button__button--following' : ''
        }`}
        type="button"
        noBorder={false}
        onClick={() => onClick(!isFollowing)}
        spinnerProps={{
          type: isFollowing ? 'dark' : 'light',
        }}
        {...rest}>
        <div className="follow-button__content">
          {isFollowing ? (
            <React.Fragment>
              <span className="follow-button__text">Following</span>
              <span className="follow-button__arrow-down" />
            </React.Fragment>
          ) : (
            <span className="follow-button__text">Follow</span>
          )}
        </div>
      </Button>
    </div>
  );
};

export default FollowButton;
