import { postFlag, postFlagReset } from './postFlag.actions';
import type { DefaultResponse } from '../../../services/RoRApiProvider';

export const mapStateToProps = state => {
  return {
    postFlagData: state.postFlag.data,
    postFlagError: state.postFlag.error,
    postFlagIsLoading: state.postFlag.isLoading,
  };
};

export const mapDispatchToProps = dispatch => {
  return {
    flagPost(payload) {
      dispatch(postFlag(payload));
    },
    flagPostReset() {
      dispatch(postFlagReset());
    },
  };
};

export type PostFlagMapStateToProps = {
  postFlagData: DefaultResponse,
  postFlagError: any,
  postFlagIsLoading: boolean,
};

export type PostFlagMapDispatchToProps = {
  flagPost: () => void,
  flagPostReset: () => void,
};

export default {
  mapStateToProps,
  mapDispatchToProps,
};
