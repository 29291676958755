// @flow
import { createAction } from 'redux-actions';
import {
  GROUP_RENAME_ERROR,
  GROUP_RENAME_LOADING,
  GROUP_RENAME_RESET,
  GROUP_RENAME_SUCCESS,
} from './groupRename.constants';
import { instance as rorGroupsApiProvider } from '../../../services/RoRGroupsApiProvider';
import type { PutGroupRenamePayload } from '../../../services/RoRGroupsApiProvider';
import { groupDetailsRenameGroup } from '../groupDetails/groupDetails.actions';

export const groupRenameError = createAction(GROUP_RENAME_ERROR);

export const groupRenameLoading = createAction(GROUP_RENAME_LOADING);

export const groupRenameSuccess = createAction(GROUP_RENAME_SUCCESS);

export const groupRenameReset = createAction(GROUP_RENAME_RESET);

export const renameGroup = (payload: PutGroupRenamePayload) => {
  return async (dispatch: Function) => {
    try {
      dispatch(groupRenameLoading());
      const response = await rorGroupsApiProvider.groupRename(payload);

      dispatch(groupRenameSuccess(response));
      dispatch(groupDetailsRenameGroup(response));
    } catch (error) {
      dispatch(groupRenameError(error));
    }
  };
};
