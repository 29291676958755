import {
  followRace as raceUnfollow,
  raceUnfollowReset as reset,
} from './raceUnfollow.actions';
import type { RaceDetails } from '../../../services/RoRRacesApiProvider';

export const mapStateToProps = state => {
  return {
    raceUnfollowData: state.raceUnfollow.data,
    raceUnfollowError: state.raceUnfollow.error,
    raceUnfollowIsLoading: state.raceUnfollow.isLoading,
  };
};

export const mapDispatchToProps = dispatch => {
  return {
    unfollowRace(payload) {
      dispatch(raceUnfollow(payload));
    },
    raceUnfollowReset() {
      dispatch(reset());
    },
  };
};

export type RaceUnfollowMapStateToProps = {
  raceUnfollowData: { race: RaceDetails } | null,
  raceUnfollowError: any,
  raceUnfollowIsLoading: boolean,
};

export type RaceUnfollowMapDispatchToProps = {
  unfollowRace: Function,
  raceUnfollowReset: Function,
};

export default {
  mapStateToProps,
  mapDispatchToProps,
};
