import {
  followRace as raceFollow,
  raceFollowReset as reset,
} from './raceFollow.actions';
import type { RaceDetails } from '../../../services/RoRRacesApiProvider';

export const mapStateToProps = state => {
  return {
    raceFollowData: state.raceFollow.data,
    raceFollowError: state.raceFollow.error,
    raceFollowIsLoading: state.raceFollow.isLoading,
  };
};

export const mapDispatchToProps = dispatch => {
  return {
    followRace(payload) {
      dispatch(raceFollow(payload));
    },
    raceFollowReset() {
      dispatch(reset());
    },
  };
};

export type RaceFollowMapStateToProps = {
  raceFollowData: { race: RaceDetails } | null,
  raceFollowError: any,
  raceFollowIsLoading: boolean,
};

export type RaceFollowMapDispatchToProps = {
  followRace: Function,
  raceFollowReset: Function,
};

export default {
  mapStateToProps,
  mapDispatchToProps,
};
