export const GROUP_DETAILS_SUCCESS = '@@prytany/global/GROUP_DETAILS_SUCCESS';
export const GROUP_DETAILS_LOADING = '@@prytany/global/GROUP_DETAILS_LOADING';
export const GROUP_DETAILS_ERROR = '@@prytany/global/GROUP_DETAILS_ERROR';
export const GROUP_DETAILS_RESET = '@@prytany/global/GROUP_DETAILS_RESET';
export const GROUP_DETAILS_RENAME_GROUP =
  '@@prytany/global/GROUP_DETAILS_RENAME_GROUP';
export const GROUP_DETAILS_UPDATE_GROUP =
  '@@prytany/global/GROUP_DETAILS_UPDATE_GROUP';
export const GROUP_DETAILS_ADD_CONTRIBUTION =
  '@@prytany/global/GROUP_DETAILS_ADD_CONTRIBUTION';
