// @flow
import React from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import MediaQuery from 'react-responsive';
import _get from 'lodash/get';
import {
  URL_LOGIN_PAGE,
  URL_MY_ACCOUNT_MAIN_PAGE,
  URL_VERIFY_ACCOUNT_PAGE,
  URL_DASHBOARD_CONTRIBUTIONS_PAGE,
  URL_FEED_PAGE,
  URL_INBOX_PAGE,
  URL_USER_PUBLIC_PROFILE,
  URL_SEARCH_PAGE,
  URL_PRYTANY_TV_PAGE,
} from '../../config/urls';
import { INVITE_USERS_MAIL } from '../../constants/forms';
import Link from '../Link/Link';
import account from '../../redux/modules/account/account.containers';
import MenuMobile from './MenuMobile';
import Flyout from '../Flyout/Flyout';
import Button from '../Button/Button';
import type {
  AccountMapDispatchToProps,
  AccountMapStateToProps,
} from '../../redux/modules/account/account.containers';
import './Header.scss';
import { hasDashboard } from '../../utilities/authorization';
import type { AuthenticatedUser } from '../../services/RoRApiProvider';

type Props = AccountMapStateToProps &
  AccountMapDispatchToProps & {
    location: any,
  };

class Header extends React.Component<Props> {
  //TODO: move media queries to config file
  render() {
    return (
      <section className="header">
        <MediaQuery query="(max-device-width: 727px)">
          <MenuMobile
            {...this.props}
            key={`menu-${this.props.location.pathname}`}
            logout={() => this.props.accountLogout()}
          />
        </MediaQuery>
        <MediaQuery query="(min-device-width: 727px)">
          <HeaderDesktop {...this.props} />
        </MediaQuery>
      </section>
    );
  }
}

const HeaderDesktop = (props: Props) => {
  const { accountData, location } = props;
  const { user } = accountData || {};

  return (
    <div className="container-fluid fix-width-1290 px-4">
      <div className="row">
        <div className="col-2 logo">
          <Link
            href={
              !user
                ? '/'
                : hasDashboard(user)
                ? URL_DASHBOARD_CONTRIBUTIONS_PAGE
                : URL_FEED_PAGE
            }>
            <img src="/images/logo.png" alt="" />
          </Link>
        </div>
        {!accountData && location.pathname !== URL_LOGIN_PAGE && (
          <NavLoggedOut />
        )}
        {accountData && (
          <NavLoggedIn
            user={accountData.user}
            location={location}
            logout={() => props.accountLogout()}
            isLoading={props.accountIsLoading}
          />
        )}
      </div>
    </div>
  );
};

const NavLoggedOut = () => (
  <div className="col-8 offset-2 text-right pt-md-4 pt-3">
    <div className="row">
      <div className="col-md-10 px-0">
        <span>Have an account? </span>
      </div>
      <div className="col-md-2 pr-0 pl-1 text-md-left">
        <Link href={URL_LOGIN_PAGE} className="link-violet">
          Log In
        </Link>
      </div>
    </div>
  </div>
);

type NavLoggedInProps = {
  user: AuthenticatedUser,
  location: any,
  logout: Function,
  isLoading: boolean,
};

const NavLoggedIn = ({
  user,
  location,
  logout,
  isLoading,
}: NavLoggedInProps) => {
  return (
    <div className="col-10 text-right pt-md-4 pt-3 menu-desk">
      <div className="row">
        <div className="col-md-12 px-0">
          <ul>
            <li>
              <Link href={URL_SEARCH_PAGE} type="secondary">
                <span className="d-inline-block mr-2">Search</span>
                <img src="/images/mag-menu.png" alt="" />
              </Link>
            </li>
            {hasDashboard(user) && (
              <li className="fw-500">
                <Link
                  href={URL_DASHBOARD_CONTRIBUTIONS_PAGE}
                  type="secondary"
                  className={getLinkClassName(
                    location.pathname,
                    URL_DASHBOARD_CONTRIBUTIONS_PAGE,
                  )}>
                  Dashboard
                </Link>
              </li>
            )}
            <li className="fw-500">
              <Link
                href={URL_FEED_PAGE}
                type="secondary"
                className={getLinkClassName(location.pathname, URL_FEED_PAGE)}>
                {hasDashboard(user) ? 'Activity' : 'Feed'}
              </Link>
            </li>
            <li className="black fw-600">
              <Link
                href="/"
                type="secondary"
                className={getLinkClassName(location.pathname, '/')}>
                How it works
              </Link>
            </li>
            <li className="black fw-600">
              <Link
                href={URL_PRYTANY_TV_PAGE}
                type="secondary"
                className={getLinkClassName(location.pathname, URL_PRYTANY_TV_PAGE)}>
                CandidateTV
              </Link>
            </li>
            <li className="black fw-600">
              <Flyout
                trigger={
                  <div>
                    <Button
                      size="small"
                      buttonType="link"
                      type="button"
                      className={getLinkClassName(
                        location.pathname,
                        URL_MY_ACCOUNT_MAIN_PAGE,
                      )}>
                      My Account
                    </Button>
                  </div>
                }
                hoverable={false}>
                <div className="header__my-account-flyout">
                  <Link
                    className="link-violet"
                    href={URL_INBOX_PAGE}
                    type="secondary">
                    Inbox
                  </Link>
                  <Link
                    className="link-violet"
                    href={URL_VERIFY_ACCOUNT_PAGE}
                    type="secondary">
                    Account Verification
                  </Link>
                  <Link
                    className="link-violet"
                    href={URL_MY_ACCOUNT_MAIN_PAGE}
                    type="secondary">
                    Edit Profile
                  </Link>
                  <Link
                    className="link-violet"
                    href={URL_USER_PUBLIC_PROFILE.replace(':id', user.id)}
                    type="secondary">
                    View Profile
                  </Link>
                  <Link
                    className="link-violet"
                    isExternal={true}
                    href={INVITE_USERS_MAIL.replace(
                      ':user',
                      `${user.first_name} ${user.last_name}`,
                    )}
                    type="secondary">
                    Invite People
                  </Link>
                  <Button
                    loading={isLoading}
                    buttonType="link"
                    noBorder={true}
                    onClick={logout}
                    type="submit">
                    Logout
                  </Button>
                </div>
              </Flyout>
            </li>
          </ul>
        </div>
      </div>
    </div>
  );
};

function getLinkClassName(currentUrl, linkUrl) {
  return currentUrl === linkUrl ? 'header__link--active' : '';
}

const mapStateToProps = (state, ownProps) => {
  return {
    ...account.mapStateToProps(state),
    ...ownProps,
  };
};

const mapDispatchToProps = dispatch => {
  return {
    ...account.mapDispatchToProps(dispatch),
  };
};

export default withRouter(
  connect(
    mapStateToProps,
    mapDispatchToProps,
  )(Header),
);
