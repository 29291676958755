// @flow
import React from 'react';
import { Formik, FormikComputedProps } from 'formik';
import * as yup from 'yup';
import Input from '../Input/Input';
import './SignUpTwoFactorForm.scss';

type Props = {
  onFormSubmit?: Function,
};

type FormValues = {
  otpCode: string,
};

class SignUpTwoFactorForm extends React.Component<Props> {
  formikForm: Formik<{}, { otpCode: string }>;

  constructor(props: Props) {
    super(props);
  }

  render() {
    return (
      <div className="sign-up-two-factor-form">
        <h1>Account Verification Required</h1>
        <p>
          We have sent you a code to your email address. Please, enter that code
          here to verify this is you.
        </p>
        <Formik {...this.getFormikProps()}>
          {props => this.renderInnerForm(props)}
        </Formik>
      </div>
    );
  }

  renderInnerForm(formikProps: FormikComputedProps) {
    const { touched, errors, handleSubmit } = formikProps;

    return (
      <form onSubmit={handleSubmit}>
        <div className="row">
          <div className="col-md-6">
            <Input
              name="otpCode"
              label={
                <label htmlFor="optCode">
                  Verification Code <span className="required-asterisk">*</span>
                </label>
              }
              type="password"
              error={touched.otpCode && errors.otpCode ? errors.otpCode : ''}
            />
          </div>
        </div>
        {/*<div className="mx-0">*/}
        {/*  <Button*/}
        {/*    loading={this.props.signUpOtpVerificationIsLoading}*/}
        {/*    type="submit"*/}
        {/*    block={true}*/}
        {/*    buttonType="primary"*/}
        {/*    className="col-md-4 offset-md-8 offset-6 col-6"*/}
        {/*    disabled={!!this.props.signUpOtpVerificationData}>*/}
        {/*    Send*/}
        {/*  </Button>*/}
        {/*  <div className="hr">*/}
        {/*    <hr />*/}
        {/*  </div>*/}
        {/*  <div className="sign-up-form__service-error">*/}
        {/*    {this.props.signUpOtpVerificationError ? (*/}
        {/*      <span className="sign-up-form__service-error-message">*/}
        {/*        The code you entered is not valid.*/}
        {/*      </span>*/}
        {/*    ) : null}*/}
        {/*    {this.props.signUpOtpVerificationData ? (*/}
        {/*      <span className="sign-up-form__service-success-message">*/}
        {/*        Your account is verified, proceed to the next step to complete*/}
        {/*        the sign-up.*/}
        {/*      </span>*/}
        {/*    ) : null}*/}
        {/*  </div>*/}
        {/*</div>*/}
        <div />
      </form>
    );
  }

  setFormikRef = (ref: Formik) => {
    this.formikForm = ref;
  };

  getFormikProps() {
    return {
      initialValues: {
        otpCode: '',
      },
      validationSchema: yup.object().shape({
        otpCode: yup.string().required('This field is required'),
      }),
      onSubmit: this.onFormSubmit,
      ref: this.setFormikRef,
    };
  }

  submitForm = () => {
    // Manually triggering touch before sending the form
    this.formikForm.setTouched({
      otpCode: true,
    });

    this.formikForm.submitForm();
  };

  onFormSubmit = (values: FormValues) => {
    if (this.props.onFormSubmit) {
      this.props.onFormSubmit({ values });
    }
  };
}
export default SignUpTwoFactorForm;
