// @flow
import React from 'react';
import moment from 'moment';
import { Popconfirm } from 'antd';
import { connect } from 'react-redux';
import Avatar from '../Avatar/Avatar';
import Button from '../Button/Button';
import Link from '../Link/Link';
import Input from '../Input/Input';
import { Scrollbars } from 'react-custom-scrollbars';
import sendMessage from '../../redux/modules/sendMessage/sendMessage.containers';
import { URL_USER_PUBLIC_PROFILE } from '../../config/urls';
import './InboxMessageThread.scss';
import LoadingSpinner from '../../components/LoadingSpinner/LoadingSpinner';
import type { UserListView } from '../../services/RoRUsersApiProvider';
import { isCandidate, isCitizen } from '../../utilities/authorization';
import isPrytanyVerified from '../../utilities/isPrytanyVerified';

type Props = {
  sender: UserListView,
  date: string,
  details: { [key: string]: any },
  onRemoveButtonClick: () => void,
  removeLoading?: boolean,
  messageThread?: any,
  sendMessage: Function,
  sendMessageIsLoading: boolean,
  onMessageSendSuccess: Function,
  loadHistory: Function,
  shouldScroll: boolean,
  messageDetailsIsLoading: boolean,
};

type State = {
  messageError: string | null,
  message: string,
};

class InboxMessageThread extends React.Component<Props, State> {
  messagesEnd = {};
  scrollbar = {};

  constructor(props: Props) {
    super(props);

    this.state = {
      messageError: null,
      message: '',
    };
  }

  componentDidMount() {
    if (this.props.shouldScroll) {
      this.messagesEnd.scrollIntoView();
    }
  }

  componentDidUpdate(prevProps: Props, prevState: State) {}

  loadHistory = e => {
    if (e.top === 0) {
      this.props.loadHistory();
      this.scrollbar.scrollTop(10);
    }
  };

  render() {
    const { sender, date, details, messageThread } = this.props;
    const messageMoment = moment(date);
    const senderProfileUrl = URL_USER_PUBLIC_PROFILE.replace(':id', sender.id);
    const { message, messageError } = this.state;

    return (
      <div className="inbox-message">
        <div className="inbox-message__head">
          <Link href={senderProfileUrl}>
            <Avatar
              source={sender.profile_image}
              type="x-small"
              isCandidate={isCandidate(sender)}
              verifiedLevel={sender.verified_level}
              verifiedDetail={sender.verified_detail}
            />
          </Link>
          <div className="inbox-message__head-name-date">
            <div>
              <Link
                className="inbox-message__head-name"
                href={senderProfileUrl}>
                {`${sender.first_name} ${sender.last_name}`}
              </Link>
            </div>
            <span className="inbox-message__head-date">
              {messageMoment.format('MMM').toUpperCase()}{' '}
              {messageMoment.format('Do, HH:mm A')}
            </span>
          </div>
          <div className="inbox-message__head-remove">
            <Popconfirm
              title="Are you sure you want to delete this message?"
              onConfirm={this.props.onRemoveButtonClick}
              okText="Yes"
              cancelText="No">
              <Button
                buttonType="link"
                type="button"
                loading={this.props.removeLoading}
                spinnerProps={{ type: 'dark' }}>
                <img src="/images/icon_trash.png" alt="" />
              </Button>
            </Popconfirm>
          </div>
        </div>
        <hr className="mt-4" />
        <div className="inbox-message__body">
          <React.Fragment>
            <Scrollbars
              ref={node => (this.scrollbar = node)}
              universal
              style={{ height: 320 }}
              onScrollFrame={e => {
                this.loadHistory(e);
              }}>
              {this.props.messageDetailsIsLoading && (
                <LoadingSpinner
                  type="dark"
                  visible={this.props.messageDetailsIsLoading}
                />
              )}
              {messageThread &&
                messageThread.map(message => (
                  <div
                    key={message.id}
                    className={`inbox-message__${
                      message.is_sent ? 'sent' : 'recieved'
                    }`}>
                    <React.Fragment>
                      <p>{message.body}</p>
                      <br />
                      <span>
                        {moment(message.sent_at).format('Do, HH:mm A')}
                      </span>
                    </React.Fragment>
                  </div>
                ))}
              <div ref={node => (this.messagesEnd = node)} />
            </Scrollbars>
            <div className="row">
              <Input
                name="message"
                onChange={e => this.handleInputChange(e)}
                className="col-10 mt-2"
                useFormik={false}
                type="text"
              />
              {messageError && <p>{messageError}</p>}
              <Button
                className="col-2 btn mt-3"
                onClick={this.sendMessage}
                loading={this.props.sendMessageIsLoading}
                buttonType="primary"
                disabled={!message.trim()}
                size="small">
                Send
              </Button>
            </div>
          </React.Fragment>
        </div>
      </div>
    );
  }

  handleInputChange(e: { name: string, value: string }) {
    this.setState(() => ({ [e.name]: e.value }));
  }

  sendMessage = () => {
    this.props.sendMessage({
      receiver_id: this.props.sender.id,
      message: this.state.message,
    });
  };
}

const mapStateToProps = (state, ownProps) => {
  return {
    ...sendMessage.mapStateToProps(state),
    ...ownProps,
  };
};

const mapDispatchToProps = dispatch => {
  return {
    ...sendMessage.mapDispatchToProps(dispatch),
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(InboxMessageThread);
