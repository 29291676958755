// @flow
import React, { Fragment } from 'react';
import _get from 'lodash/get';
import Route from 'react-router-dom/Route';
import Redirect from 'react-router-dom/Redirect';
import { message } from 'antd';
import { connect } from 'react-redux';
import GroupsTopMenu, {
  EditGroupMenu,
} from '../../components/GroupsTopMenu/GroupsTopMenu';
import GroupPostsPage from './GroupPostsPage';
import GroupMembersPage from './GroupMembersPage';
import GroupAboutPage from './GroupAboutPage';
import GroupCandidatesPage from './GroupCandidatesPage';
import withDisplayDimensions from '../../hoc/withDisplayDimensions';
import Button from '../../components/Button/Button';
import GroupDelete from '../../components/GroupDelete/GroupDelete';
import GroupRename from '../../components/GroupRename/GroupRename';
import GroupEditModerators from '../../components/GroupEditModerators/GroupEditModerators';
import ModalRenameGroup from '../../components/ModalRenameGroup/ModalRenameGroup';
import ModalGroupEditModerators from '../../components/ModalGroupEditModerators/ModalGroupEditModerators';
import ModalGroupDelete from '../../components/ModalGroupDelete/ModalGroupDelete';
import ModalContributeOptions from '../../components/ModalContributeOptions/ModalContributeOptions';
import ModalContributeCandidate from '../../components/ModalContributeCandidate/ModalContributeCandidate';
import {
  URL_DASHBOARD_PEOPLE_GROUPS_PAGE,
  URL_FEED_PAGE,
  URL_GROUP_ABOUT_PAGE,
  URL_GROUP_MEMBERS_PAGE,
  URL_GROUP_POSTS_PAGE,
  URL_GROUP_CANDIDATES_PAGE,
} from '../../config/urls';
import account from '../../redux/modules/account/account.containers';
import accountDetails from '../../redux/modules/accountDetails/accountDetails.containers';
import groupDetails from '../../redux/modules/groupDetails/groupDetails.containers';
import groupModerators from '../../redux/modules/groupModerators/groupModerators.containers';
import groupRequestJoin from '../../redux/modules/groupRequestJoin/groupRequestJoin.containers';
import groupLeave from '../../redux/modules/groupLeave/groupLeave.containers';
import userDetails from '../../redux/modules/userDetails/userDetails.containers';
import ModalInvitePeople from '../../components/ModalInvitePeople/ModalInvitePeople';
import ModalInviteByContact from '../../components/ModalInviteByContact/ModalInviteByContact';

import type {
  AccountDetailsMapDispatchToProps,
  AccountDetailsMapStateToProps,
} from '../../redux/modules/accountDetails/accountDetails.containers';
import type {
  GroupDetailsMapDispatchToProps,
  GroupDetailsMapStateToProps,
} from '../../redux/modules/groupDetails/groupDetails.containers';
import type { WithDisplayDimensionsOutputProps } from '../../hoc/withDisplayDimensions';
import type { GroupModeratorsMapDispatchToProps } from '../../redux/modules/groupModerators/groupModerators.containers';
import type { GroupRequestJoinMapDispatchToProps } from '../../redux/modules/groupRequestJoin/groupRequestJoin.containers';
import type { GroupLeaveMapDispatchToProps } from '../../redux/modules/groupLeave/groupLeave.containers';
import './GroupPage.scss';
import type { AccountMapStateToProps } from '../../redux/modules/account/account.containers';
import copyToClipboard from '../../utilities/copyToClipboard';
import getBaseUrl from '../../utilities/getBaseUrl';
import GroupType from '../../constants/GroupType';
import reject from 'lodash/reject';
import type { ContributionTypeType } from '../../components/ContributeOptions/ContributeOptions';
import ModalContribute from '../../components/ModalContribute/ModalContribute';

type Props = AccountDetailsMapStateToProps &
  AccountDetailsMapDispatchToProps &
  GroupDetailsMapStateToProps &
  GroupDetailsMapDispatchToProps &
  WithDisplayDimensionsOutputProps &
  GroupModeratorsMapDispatchToProps &
  GroupRequestJoinMapDispatchToProps &
  GroupLeaveMapDispatchToProps &
  AccountMapStateToProps & {
    children: any,
    match: any,
    location: any,
    history: any,
  };

type MobileSubSections =
  | 'edit-menu'
  | 'rename'
  | 'moderators'
  | 'delete'
  | 'none';

type State = {
  initialTabIndex: number | null,
  mobileSubSection: MobileSubSections,
  desktopEditFlyoutOpen: boolean,
  desktopModalOpened: 'rename' | 'moderators' | 'delete' | 'none',
  contributeOptionsModalOpen: boolean,
  contributionType: ContributionTypeType,
  contributeModalOpen: boolean,
  selectCandidateModalOpen: boolean,
  selectedCandidateToDonateId: number | null,
  invitePeopleModalOpen: boolean,
  inviteByContactModalOpen: boolean,
  subSections: { label: string, path: string, component: any }[],
};

const allSubSections = [
  {
    label: 'Posts',
    path: URL_GROUP_POSTS_PAGE,
    component: GroupPostsPage,
  },
  {
    label: 'Members',
    path: URL_GROUP_MEMBERS_PAGE,
    component: GroupMembersPage,
  },
  { label: 'About', path: URL_GROUP_ABOUT_PAGE, component: GroupAboutPage },
  {
    label: 'Candidates',
    path: URL_GROUP_CANDIDATES_PAGE,
    component: GroupCandidatesPage,
  },
];

const getInitialTabIndex = (subSections, { match, location }: Props) => {
  const initialTabIndex = subSections.findIndex(subSection => {
    return (
      subSection.path.replace(':groupId', match.params.groupId) ===
      location.pathname
    );
  });
  return initialTabIndex >= 0 ? initialTabIndex : null;
};

type ModalNames = 'selectCandidate' | 'contributeOptions' | 'contribute';
class GroupPage extends React.Component<Props, State> {
  constructor(props: Props) {
    super(props);
    this.state = {
      subSections: allSubSections,
      initialTabIndex: getInitialTabIndex(allSubSections, props),
      initialTabIndex: null,
      mobileSubSection: 'none',
      desktopEditFlyoutOpen: false,
      desktopModalOpened: 'none',
      contributeOptionsModalOpen: false,
      contributionType: null,
      contributeModalOpen: false,
      selectCandidateModalOpen: false,
      selectedCandidateToDonateId: null,
      invitePeopleModalOpen: false,
      inviteByContactModalOpen: false,
    };
  }

  componentWillMount() {
    const groupId = parseInt(this.props.match.params.groupId);
    // TODO: this request should probably be executed on the server side before rendering this view
    this.props.groupDetailsReset();
    this.props.getAccountDetails();
    this.props.getDetailsForGroup({ groupId });
  }

  componentDidUpdate(prevProps: Props) {
    if (this.props.location.pathname !== prevProps.location.pathname) {
      //this can happen if it's a redirect
      this.setState({
        initialTabIndex: getInitialTabIndex(this.state.subSections, this.props),
      });
    }

    if (!prevProps.groupDetailsData && this.props.groupDetailsData) {
      const { group } = this.props.groupDetailsData;
      const subSections = reject(
        allSubSections,
        subSection =>
          group.group_type === GroupType.CAMPAIGN &&
          subSection.label === 'Candidates',
      );
      this.setState({
        subSections,
        initialTabIndex: getInitialTabIndex(subSections, this.props),
      });
    }

    if (this.props.groupDetailsError) {
      if (this.props.groupDetailsError.code === 'NOT_FOUND') {
        // TODO: redirect?
        message.error('The group you are looking for does not exists.', 5);
      } else {
        message.error(this.props.groupDetailsError.localMessage, 5);
      }
    } else if (this.props.groupDetailsData) {
      // TODO: evaluate if user has access to this page
      console.log(this.props.groupDetailsData);
    }

    if (this.props.isMobile !== prevProps.isMobile) {
      this.setState(() => ({
        desktopEditFlyoutOpen: false,
        desktopModalOpened: 'none',
        mobileSubSection: 'none',
      }));
    }

    if (!prevProps.groupRequestJoinError && this.props.groupRequestJoinError) {
      this.handleGroupRequestJoinError();
    } else if (
      !prevProps.groupRequestJoinData &&
      this.props.groupRequestJoinData
    ) {
      this.handleGroupRequestJoinSuccess();
    }

    if (!prevProps.groupLeaveError && this.props.groupLeaveError) {
      this.handleGroupLeaveError(this.props.groupLeaveError.localMessage);
    } else if (!prevProps.groupLeaveData && this.props.groupLeaveData) {
      this.handleGroupLeaveSuccess();
    }
  }

  render() {
    return this.state.initialTabIndex == null
      ? this.renderRedirect()
      : this.renderContent();
  }

  // TODO: fix re-mount redirection issue
  renderRedirect() {
    const url = URL_GROUP_POSTS_PAGE.replace(
      ':groupId',
      this.props.match.params.groupId,
    );
    return (
      <Redirect
        to={{
          pathname: url,
          state: { from: url },
        }}
      />
    );
  }

  renderContent() {
    return (
      <div className="group-page content">
        <section className="group-page__content">
          {!this.props.isMobile || this.state.mobileSubSection === 'none' ? (
            <React.Fragment>
              <GroupsTopMenu {...this.getTopMenuProps()} />
              {this.state.subSections.map((subSection: any) => (
                <Route
                  exact
                  key={subSection.path}
                  path={subSection.path}
                  component={subSection.component}
                />
              ))}
            </React.Fragment>
          ) : null}
          {this.props.isMobile ? this.renderMobileSubSection() : null}
          <ModalRenameGroup
            isOpen={this.state.desktopModalOpened === 'rename'}
            onCloseRequest={this.closeModals}
          />
          <ModalGroupEditModerators
            isOpen={this.state.desktopModalOpened === 'moderators'}
            onModeratorsAssigned={this.handleModeratorsAssigned}
            onCloseRequest={this.closeModals}
          />
          <ModalGroupDelete
            isOpen={this.state.desktopModalOpened === 'delete'}
            onSuccess={this.handleGroupDeleted}
            onCloseRequest={this.closeModals}
          />
          {this.props.groupDetailsData && (
            <ModalContributeCandidate
              candidateList={
                this.props.groupDetailsData.group.supporting_candidates
              }
              isOpen={this.state.selectCandidateModalOpen}
              onCloseRequest={this.handleCloseAllModals}
              onNextClick={this.handleOpenContributeOptionsModal}
            />
          )}
          <ModalContributeOptions
            isOpen={this.state.contributeOptionsModalOpen}
            onContributionTypeSelected={this.handleContributionTypeSelected}
            onCloseRequest={this.handleCloseAllModals}
          />
          {this.props.userDetailsData && (
            <ModalContribute
              isOpen={this.state.contributeModalOpen}
              onCloseRequest={this.handleCloseAllModals}
              contributionType={this.state.contributionType}
              userDetails={this.props.userDetailsData.user}
              groupId={_get(this.props, 'groupDetailsData.group.id')}
              onGoBackRequest={this.handleGoBackToContributeOptionsModal}
              onSuccess={this.handleCloseAllModals}
            />
          )}
          {this.props.groupDetailsData && (
            <Fragment>
              <ModalInvitePeople
                isOpen={this.state.invitePeopleModalOpen}
                onCloseRequest={this.handleCloseInvitePeopleModal}
                onSuccess={this.handleSendInvitationsSuccess}
                onError={this.handleSendInvitationsError}
                groupDetails={this.props.groupDetailsData.group}
              />
              <ModalInviteByContact
                isOpen={this.state.inviteByContactModalOpen}
                onCloseRequest={this.handleCloseInviteByContactModal}
                groupDetails={this.props.groupDetailsData.group}
              />
            </Fragment>
          )}
        </section>
      </div>
    );
  }

  handleOpenContributeOptionsModal = (candidateId: string) => {
    this.props.getDetailsForUser({ userId: candidateId });
    this.setModalState(['contributeOptions']);
  };

  handleCloseAllModals = () => {
    this.setModalState([], { contributionType: null });
  };

  handleGoBackToContributeOptionsModal = () => {
    this.setModalState(['contributeOptions']);
  };

  handleContributionTypeSelected = (type: ContributionTypeType) => {
    this.setModalState(['contribute'], { contributionType: type });
  };

  setModalState(
    activeModals: ModalNames[],
    others: { [key: string]: any } = {},
  ) {
    const modalStateKeys = [
      'contributeOptionsModalOpen',
      'contributeModalOpen',
      'selectCandidateModalOpen',
    ];
    let state = { ...others };

    modalStateKeys.forEach((key: string) => {
      state[key] = activeModals.indexOf(key.replace('ModalOpen', '')) !== -1;
    });

    this.setState(() => state);
  }

  groupRequestJoin = () => {
    const groupId = parseInt(this.props.match.params.groupId);

    this.props.requestJoinGroup({ groupId });
  };

  leaveFromGroup = () => {
    const groupId = parseInt(this.props.match.params.groupId);

    this.props.leaveGroup({ groupId });
  };

  handleGroupRequestJoinSuccess() {
    message.success('Request has been sent successfully.');
  }

  handleGroupRequestJoinError() {
    message.error('Something went wrong. Please try again later.');
  }

  handleGroupLeaveSuccess() {
    message.success('You have left the group.');
  }

  handleGroupLeaveError(errorMessage) {
    message.error(
      errorMessage || 'Something went wrong. Please try again later.',
    );
  }

  renderMobileSubSection() {
    const sections = {
      'edit-menu': this.renderMobileEditGroupMenu,
      delete: () => <GroupDelete onSuccess={this.handleGroupDeleted} />,
      rename: () => (
        <GroupRename
          onSuccess={() => this.handleMobileBackButtonClick('none')}
        />
      ),
      moderators: () => (
        <GroupEditModerators
          onModeratorsAssigned={() => this.handleMobileBackButtonClick('none')}
        />
      ),
      none: () => null,
    };

    const titles = {
      'edit-menu': null,
      delete: 'Remove group',
      rename: 'Rename group',
      moderators: 'Moderators',
      none: null,
    };

    return this.state.mobileSubSection !== 'none' ? (
      <div className="group-page__mobile-subsection">
        <div className="group-page__mobile-subsection-top-bar">
          <Button
            buttonType="link"
            type="button"
            onClick={this.handleMobileBackButtonClick}>
            &larr; Back
          </Button>
          {titles[this.state.mobileSubSection] ? (
            <h6>{titles[this.state.mobileSubSection]}</h6>
          ) : null}
        </div>
        {sections[this.state.mobileSubSection]()}
      </div>
    ) : null;
  }

  renderMobileEditGroupMenu = () => {
    return (
      <div className="group-page__mobile-edit-group">
        <h4>{this.props.groupDetailsData.group.name}</h4>
        <EditGroupMenu onOptionSelected={this.handleEditOptionClick} />
      </div>
    );
  };

  getTopMenuProps() {
    return {
      initialTabIndex: this.state.initialTabIndex,
      accountData: this.props.accountData,
      availableTabs: this.state.subSections,
      onTabChange: this.handleTabChange,
      groupDetails:
        this.props.groupDetailsData && this.props.groupDetailsData.group,
      onEditButtonClick: this.handleEditButtonClick,
      onEditOptionClick: this.handleEditOptionClick,
      onCloseEditFlyoutRequest: this.handleEditButtonClick,
      isEditFlyoutOpen: this.state.desktopEditFlyoutOpen,
      groupRequestJoin: this.groupRequestJoin,
      leaveFromGroup: this.leaveFromGroup,
      groupRequestJoinIsLoading: this.props.groupRequestJoinIsLoading,
      groupLeaveIsLoading: this.props.groupLeaveIsLoading,
      groupDetailsIsLoading: this.props.groupDetailsIsLoading,
      openSelectCandidateModal: this.handleOpenContributeModal,
      onInviteExistingUsers: this.handleOpenInvitePeopleModal,
      onInviteByContact: this.handleOpenInviteByContactModal,
      onCopyLinkToInvitation: this.handleCopyLinkToInvitation,
    };
  }

  handleOpenContributeModal = () => {
    this.setModalState(['selectCandidate']);
  };

  handleTabChange = (tabData: any) => {
    this.props.history.push(
      this.state.subSections[tabData.index].path.replace(
        ':groupId',
        this.props.match.params.groupId,
      ),
    );
  };

  handleEditButtonClick = () => {
    if (this.props.isMobile) {
      this.setState(() => ({ mobileSubSection: 'edit-menu' }));
    } else {
      this.setState((prevState: State) => ({
        desktopEditFlyoutOpen: !prevState.desktopEditFlyoutOpen,
      }));
    }
  };

  handleEditOptionClick = (
    key: 'rename' | 'moderators' | 'delete' | 'leave',
  ) => {
    if (key !== 'leave') {
      if (!this.props.isMobile) {
        this.setState(() => ({ desktopModalOpened: (key: any) }));
      } else {
        this.setState(() => ({ mobileSubSection: (key: any) }));
      }
    }
  };

  handleMobileBackButtonClick = (section?: MobileSubSections) => {
    let mobileSubSection = 'none';

    if (
      ['rename', 'moderators', 'delete'].indexOf(
        this.state.mobileSubSection,
      ) !== -1
    ) {
      mobileSubSection = 'edit-menu';
    }

    if (section) {
      mobileSubSection = section;
    }

    this.setState(() => ({ mobileSubSection }));
  };

  handleModeratorsAssigned = () => {
    this.closeModals();
    this.props.getModeratorsForGroup({
      groupId: this.props.groupDetailsData.group.id,
    });
  };

  handleGroupDeleted = () => {
    if (
      this.props.accountDetailsData &&
      this.props.accountDetailsData.user.role.code === 'candidate'
    ) {
      this.props.history.replace(URL_DASHBOARD_PEOPLE_GROUPS_PAGE);
    } else {
      this.props.history.replace(URL_FEED_PAGE);
    }
  };

  closeModals = () => {
    this.setState(() => ({ desktopModalOpened: 'none' }));
  };

  handleOpenInvitePeopleModal = () => {
    this.setState({ invitePeopleModalOpen: true });
  };

  handleCloseInvitePeopleModal = () => {
    this.setState({ invitePeopleModalOpen: false });
  };

  handleSendInvitationsSuccess = () => {
    message.success('Users were successfully invited.');
  };

  handleSendInvitationsError = (error: string) => {
    message.error(error);
  };

  handleOpenInviteByContactModal = () => {
    console.log('opening invite by contact modal...');
    this.setState({ inviteByContactModalOpen: true });
  };

  handleCloseInviteByContactModal = () => {
    this.setState({ inviteByContactModalOpen: false });
  };

  handleCopyLinkToInvitation = () => {
    const url = getBaseUrl();
    url.pathname = '/signup';
    url.searchParams.set('group_id', this.props.groupDetailsData.group.id);
    copyToClipboard(url.toString());
    message.success(
      'A link inviting people to join your group has been copied to the clipboard',
    );
  };
}

const mapStateToProps = (state, ownProps) => {
  return {
    ...account.mapStateToProps(state),
    ...accountDetails.mapStateToProps(state),
    ...groupDetails.mapStateToProps(state),
    ...groupRequestJoin.mapStateToProps(state),
    ...groupLeave.mapStateToProps(state),
    ...userDetails.mapStateToProps(state),
    ...ownProps,
  };
};

const mapDispatchToProps = dispatch => {
  return {
    ...accountDetails.mapDispatchToProps(dispatch),
    ...groupDetails.mapDispatchToProps(dispatch),
    ...groupModerators.mapDispatchToProps(dispatch),
    ...groupRequestJoin.mapDispatchToProps(dispatch),
    ...groupLeave.mapDispatchToProps(dispatch),
    ...userDetails.mapDispatchToProps(dispatch),
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(withDisplayDimensions(GroupPage));
