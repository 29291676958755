export const ACCOUNT_FOLLOWINGS_LOADING =
  '@@prytany/global/ACCOUNT_FOLLOWINGS_LOADING';
export const ACCOUNT_FOLLOWINGS_SUCCESS =
  '@@prytany/global/ACCOUNT_FOLLOWINGS_SUCCESS';
export const ACCOUNT_FOLLOWINGS_ERROR =
  '@@prytany/global/ACCOUNT_FOLLOWINGS_ERROR';
export const ACCOUNT_FOLLOWINGS_RESET =
  '@@prytany/global/ACCOUNT_FOLLOWINGS_RESET';
export const ACCOUNT_FOLLOWINGS_ADD = '@@prytany/global/ACCOUNT_FOLLOWINGS_ADD';
export const ACCOUNT_FOLLOWINGS_REMOVE =
  '@@prytany/global/ACCOUNT_FOLLOWINGS_REMOVE';
