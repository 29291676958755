import { listRaces, listRacesReset as reset } from './listRaces.actions';
import type {
  GetRacesPayload,
  GetRacesResponse,
} from '../../../services/RoRRacesApiProvider';

export const mapStateToProps = state => {
  return {
    listRacesData: state.listRaces.data,
    listRacesError: state.listRaces.error,
    listRacesIsLoading: state.listRaces.isLoading,
  };
};

export const mapDispatchToProps = dispatch => {
  return {
    getListRaces(payload: GetRacesPayload) {
      dispatch(listRaces(payload));
    },
    listRacesReset() {
      dispatch(reset());
    },
  };
};

export type ListRacesMapStateToProps = {
  listRacesData: GetRacesResponse,
  listRacesError: any,
  listRacesIsLoading: boolean,
};

export type ListRacesMapDispatchToProps = {
  getListRaces: (payload: GetRacesPayload) => void,
  listRacesReset: () => void,
};

export default {
  mapStateToProps,
  mapDispatchToProps,
};
