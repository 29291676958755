// @flow
import * as React from 'react';
import { Field } from 'formik';
import classNames from 'classnames';

import './Toggle.scss';

type Props = {
  className?: string,
  disabled?: boolean,
  error?: string,
  name: string,
};

const Toggle = (props: Props) => {
  return (
    <div className={getClassName(props)}>
      <Field
        name={props.name}
        render={({ field, form }) => {
          const inputId = `toggle-${props.name}`;
          return (
            <React.Fragment>
              <input
                className="toggle__input tgl tgl-ios"
                onBlur={field.onBlur}
                name={props.name}
                disabled={props.disabled}
                value="1"
                id={inputId}
                type="checkbox"
                checked={field.value === '1'}
                onChange={() => handleInputChange({ props, field, form })}
              />
              <label htmlFor={inputId} className="tgl-btn" />
            </React.Fragment>
          );
        }}
      />
      {props.error ? (
        <span className="toggle__error-message">{props.error}</span>
      ) : null}
    </div>
  );
};

function getClassName(props) {
  return classNames({
    'form-group': true,
    toggle: true,
    'toggle--disabled': props.disabled,
    [props.className || '']: props.className,
  });
}

function handleInputChange({ props, field, form }) {
  if (field.value === '1') {
    form.setFieldValue(props.name, '');
  } else {
    form.setFieldValue(props.name, '1');
  }
}

export default Toggle;
