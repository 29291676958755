// @flow
import makeDefaultServerCallReducer from '../../utilities/makeDefaultServerCallReducer';
import {
  CONTRIBUTION_COMMITTED_DONATION_CALCULATE_ERROR,
  CONTRIBUTION_COMMITTED_DONATION_CALCULATE_LOADING,
  CONTRIBUTION_COMMITTED_DONATION_CALCULATE_SUCCESS,
  CONTRIBUTION_COMMITTED_DONATION_CALCULATE_RESET,
} from './contributionCommittedDonationCalculate.constants';

const initialState = {
  isLoading: false,
  error: null,
  data: null,
};

export const reducer = makeDefaultServerCallReducer({
  loadingActions: [CONTRIBUTION_COMMITTED_DONATION_CALCULATE_LOADING],
  successActions: [CONTRIBUTION_COMMITTED_DONATION_CALCULATE_SUCCESS],
  errorActions: [CONTRIBUTION_COMMITTED_DONATION_CALCULATE_ERROR],
  resetActions: [CONTRIBUTION_COMMITTED_DONATION_CALCULATE_RESET],
  initialState,
});
