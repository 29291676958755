// @flow
import { createAction } from 'redux-actions';
import {
  ACCOUNT_FEED_ERROR,
  ACCOUNT_FEED_LOADING,
  ACCOUNT_FEED_RESET,
  ACCOUNT_FEED_SUCCESS,
  ACCOUNT_FEED_LIKE_POST,
  ACCOUNT_FEED_UNLIKE_POST,
  ACCOUNT_FEED_NEXT_PAGE,
  ACCOUNT_FEED_SHARE_POST,
} from './accountFeed.constants';
import { instance as rorUsersApiProvider } from '../../../services/RoRUsersApiProvider';
import { clientStore } from '../../../redux/clientStore';
import _get from 'lodash/get';
import type { GetUserFeedPayload } from '../../../services/RoRUsersApiProvider';

export const accountFeedError = createAction(ACCOUNT_FEED_ERROR);

export const accountFeedLoading = createAction(ACCOUNT_FEED_LOADING);

export const accountFeedSuccess = createAction(ACCOUNT_FEED_SUCCESS);

export const accountFeedReset = createAction(ACCOUNT_FEED_RESET);

export const accountFeedLikePost = createAction(ACCOUNT_FEED_LIKE_POST);

export const accountFeedUnlikePost = createAction(ACCOUNT_FEED_UNLIKE_POST);

export const accountFeedSharePost = createAction(ACCOUNT_FEED_SHARE_POST);

export const accountFeedNextPage = createAction(ACCOUNT_FEED_NEXT_PAGE);

export const getAccountFeed = (payload: GetUserFeedPayload) => {
  return async (dispatch: Function) => {
    try {
      dispatch(accountFeedLoading());

      const response = await rorUsersApiProvider.accountFeed(payload);

      dispatch(accountFeedSuccess(response));
    } catch (error) {
      dispatch(accountFeedError(error));
    }
  };
};

export const getAccountFeedNextPage = (payload: GetUserFeedPayload) => {
  return async (dispatch: Function) => {
    try {
      const nextPage = _get(
        clientStore.getState(),
        `accountFeed.data.next_page`,
      );

      if (nextPage) {
        dispatch(accountFeedLoading());
        let params: GetUserFeedPayload = {
          userId: payload.userId,
          limit: payload.limit,
          page: nextPage,
        };

        if (payload.feed_type) {
          params.feed_type = payload.feed_type;
        }

        if (payload.role) {
          params.role = payload.role;
        }

        const response = await rorUsersApiProvider.accountFeed(params);

        dispatch(accountFeedNextPage(response));
      }
    } catch (error) {
      dispatch(accountFeedError(error));
    }
  };
};
