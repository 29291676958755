// @flow
import React from 'react';
import { connect } from 'react-redux';
import classNames from 'classnames';
import Modal from '../Modal/Modal';
import GroupEditModerators from '../GroupEditModerators/GroupEditModerators';

type Props = {
  isOpen: boolean,
  onCloseRequest: Function,
  onModeratorsAssigned?: Function,
  onModeratorRemoved?: Function,
  className?: string,
};

class ModalGroupEditModerators extends React.Component<Props, {}> {
  render() {
    const { isOpen, onCloseRequest, className, ...rest } = this.props;

    return (
      <Modal
        contentLabel="Edit or remove moderators"
        isOpen={isOpen}
        onCloseRequest={onCloseRequest}
        className={this.getClassName()}
        noPaddingContent
        size="small"
        {...rest}>
        {isOpen ? (
          <GroupEditModerators
            onModeratorsAssigned={this.props.onModeratorsAssigned}
            onModeratorRemoved={this.props.onModeratorRemoved}
            onCancel={this.props.onCloseRequest}
          />
        ) : null}
      </Modal>
    );
  }

  getClassName() {
    return classNames({
      'modal-group-edit-moderators': true,
      [this.props.className || '']: this.props.className,
    });
  }
}

export default ModalGroupEditModerators;
