// @flow
import _get from 'lodash/get';
import _cloneDeep from 'lodash/cloneDeep';
import makeDefaultServerCallReducer from '../../utilities/makeDefaultServerCallReducer';
import {
  RACE_FEED_ERROR,
  RACE_FEED_LOADING,
  RACE_FEED_SUCCESS,
  RACE_FEED_RESET,
  RACE_FEED_SET_ITEM_AS_FIRST,
  RACE_FEED_LIKE_POST,
  RACE_FEED_UNLIKE_POST,
  RACE_FEED_SHARE_POST,
} from './raceFeed.constants';
import _find from 'lodash/find';

const initialState = {
  isLoading: false,
  error: null,
  data: null,
};

const setFeedItemAsFirst = (state, action) => {
  if (_get(state, 'data.data.feeds')) {
    const data = _cloneDeep(state.data);

    data.data.feeds.unshift(action.payload);

    return {
      ...state,
      data,
    };
  }

  return {
    ...state,
    data: {
      data: {
        feeds: [action.payload],
      },
    },
  };
};

const likeRacePost = (state, action) => {
  if (state.data) {
    const data = _cloneDeep(state.data);

    if (_get(data, 'data.feeds')) {
      let postToUpdate = _find(
        data.data.feeds,
        feed => _get(feed.details, 'post.id') === action.payload.postId,
      );

      if (postToUpdate) {
        postToUpdate.details.post.like_count += 1;
        postToUpdate.details.post.has_liked = true;
      }
    }

    return {
      ...state,
      data,
    };
  }

  return state;
};

const shareRacePost = (state, action) => {
  if (state.data) {
    const data = _cloneDeep(state.data);

    if (_get(data, 'data.feeds')) {
      let postToUpdate = _find(
        data.data.feeds,
        feed => _get(feed.details, 'post.id') === action.payload.postId,
      );

      if (postToUpdate) {
        postToUpdate.details.post.share_count += 1;
      }
    }

    return {
      ...state,
      data,
    };
  }

  return state;
};

const unLikeRacePost = (state, action) => {
  if (state.data) {
    const data = _cloneDeep(state.data);

    if (_get(data, 'data.feeds')) {
      let postToUpdate = _find(
        data.data.feeds,
        feed => _get(feed.details, 'post.id') === action.payload.postId,
      );

      if (postToUpdate) {
        postToUpdate.like_count -= 1;
        postToUpdate.has_liked = false;
      }
    }

    return {
      ...state,
      data,
    };
  }

  return state;
};

export const reducer = makeDefaultServerCallReducer({
  loadingActions: [RACE_FEED_LOADING],
  successActions: [RACE_FEED_SUCCESS],
  errorActions: [RACE_FEED_ERROR],
  resetActions: [RACE_FEED_RESET],
  otherActions: [
    { name: RACE_FEED_SET_ITEM_AS_FIRST, callback: setFeedItemAsFirst },
    { name: RACE_FEED_LIKE_POST, callback: likeRacePost },
    { name: RACE_FEED_UNLIKE_POST, callback: unLikeRacePost },
    { name: RACE_FEED_SHARE_POST, callback: shareRacePost },
  ],
  initialState,
});
