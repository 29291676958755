// @flow
import React from 'react';
import * as yup from 'yup';
import _get from 'lodash/get';
import { message } from 'antd';
import { Formik, FormikComputedProps } from 'formik';
import { connect } from 'react-redux';
import { Scrollbars } from 'react-custom-scrollbars';
import Avatar from '../Avatar/Avatar';
import Button from '../Button/Button';
import Input from '../Input/Input';
import Select from '../Select/Select';
import Toggle from '../Toggle/Toggle';
import RadioButtons from '../RadioButtons/RadioButtons';
import withDisplayDimensions from '../../hoc/withDisplayDimensions';
import account from '../../redux/modules/account/account.containers';
import accountDetails from '../../redux/modules/accountDetails/accountDetails.containers';
import contributionPledgeDonationMake from '../../redux/modules/contributionPledgeDonationMake/contributionPledgeDonationMake.containers';
import userCandidateSubInterests from '../../redux/modules/userCandidateSubInterests/userCandidateSubInterests.containers';
import type { AccountDetailsMapStateToProps } from '../../redux/modules/accountDetails/accountDetails.containers';
import type { UserDetails } from '../../services/RoRUsersApiProvider';
import type {
  UserCandidateSubInterestsMapDispatchToProps,
  UserCandidateSubInterestsMapStateToProps,
} from '../../redux/modules/userCandidateSubInterests/userCandidateSubInterests.containers';
import type { WithDisplayDimensionsOutputProps } from '../../hoc/withDisplayDimensions';
import LoadingSpinner from '../LoadingSpinner/LoadingSpinner';
import { CONTRIBUTE_MIN_AMOUNT } from '../ContributePayment/ContributePayment';
import ContributePaymentMethodSelector from '../ContributePaymentMethodSelector/ContributePaymentMethodSelector';
import type {
  ContributionPledgeDonationMakeDispatchToProps,
  ContributionPledgeDonationMakeMapStateToProps,
} from '../../redux/modules/contributionPledgeDonationMake/contributionPledgeDonationMake.containers';
import './ContributePledge.scss';
import {
  CONTRIBUTION_CREDIT_CARD_EXTRA_CHARGE,
  CONTRIBUTION_ACH_FREE,
} from '../../constants/forms';
import { receivingContributionLimit } from '../../utilities/authorization';
import formatCurrency from '../../utilities/formatCurrency';

const ratingEmptySrc = '/images/icon_condition_empty.png';
const ratingIncreaseSrc = '/images/icon_condition_increase.png';
const ratingMaintainSrc = '/images/icon_condition_maintain.png';
const ratingDecreaseSrc = '/images/icon_condition_decrease.png';

type Props = AccountDetailsMapStateToProps &
  UserCandidateSubInterestsMapDispatchToProps &
  UserCandidateSubInterestsMapStateToProps &
  WithDisplayDimensionsOutputProps &
  ContributionPledgeDonationMakeDispatchToProps &
  ContributionPledgeDonationMakeMapStateToProps & {
    userDetails: UserDetails,
    onGoBackRequest: Function,
    onPaymentRequest: Function,
    customPaymentToken?: string,
    customPaymentEmail?: string,
    onAddNewBankAccountRequest?: Function,
    onPaymentSuccess?: (paymentData: any) => void,
    groupId?: number,
  };

type State = {
  ratingCondition: 'increase' | 'decrease' | 'maintain',
  mobileStep: number,
};

class ContributePledge extends React.Component<Props, State> {
  formikForm: any = null;

  constructor(props: Props) {
    super(props);

    this.state = {
      ratingCondition: 'maintain',
      mobileStep: 1,
    };

    this.props.contributionPledgeDonationMakeReset();
  }

  componentDidUpdate(prevProps: Props) {
    if (this.props.isMobile !== prevProps.isMobile) {
      this.setState(() => ({ mobileStep: 1 }));
    }

    if (
      !prevProps.contributionPledgeDonationMakeData &&
      this.props.contributionPledgeDonationMakeData
    ) {
      message.success(
        `
        You have successfully made a pledge contribution to 
        ${this.props.userDetails.first_name} ${this.props.userDetails.last_name}
      `,
        5,
      );

      if (this.props.onPaymentSuccess) {
        this.props.onPaymentSuccess({
          ...this.props.contributionPledgeDonationMakeData,
          type: 'pledge',
        });
      }
    }

    if (
      !prevProps.contributionPledgeDonationMakeError &&
      this.props.contributionPledgeDonationMakeError
    ) {
      message.error(
        this.props.contributionPledgeDonationMakeError.localMessage ||
          'An error has occurred and your contribution has been canceled',
        5,
      );
    }
  }

  componentWillMount() {
    this.props.userCandidateSubInterestsReset();
  }

  componentDidMount() {
    this.props.getCandidateSubInterestsForUser({
      userId: this.props.userDetails.id,
      customToken: this.props.customPaymentToken,
      customEmail: this.props.customPaymentEmail,
    });
  }

  render() {
    const interests = _get(
      this.props,
      'userCandidateSubInterestsData.candidate.interests.interests',
      [],
    );

    return (
      <div className="contribute-pledge__content">
        {this.renderHead()}
        <div className="contribute-pledge__body">
          {interests.length ? (
            <Formik {...this.getFormikProps()}>
              {props => this.renderInnerForm(props)}
            </Formik>
          ) : null}
          {!interests.length &&
          !this.props.userCandidateSubInterestsIsLoading ? (
            <div className="px-5 py-5">
              The candidate you selected currently does not have any available
              special interest group ratings.
            </div>
          ) : null}
          {this.props.userCandidateSubInterestsIsLoading ? (
            <div className="d-flex justify-content-center py-5">
              <LoadingSpinner type="dark" visible={true} />
            </div>
          ) : null}
        </div>
      </div>
    );
  }

  renderHead() {
    return (
      <div className="align-items-center pt-4 pb-1 pl-3 pr-3 border-bottom text-center">
        <div className="row fix-width">
          <div className="col-12 col-md-4 text-left contribute-pledge__button-change-wrapper">
            <Button
              className="contribute-pledge__button-change"
              size="small"
              type="button"
              buttonType="link"
              onClick={this.handleGoBackButton}>
              &lt;{' '}
              {this.props.isMobile && this.state.mobileStep > 1
                ? 'Back'
                : 'Change'}
            </Button>
          </div>
          <div className="col-12 col-md-4 mt-0 mb-3">
            <h3 className="contribute-pledge__title">Pledge Contribution</h3>
          </div>
          <div className="col-12 col-md-4 text-md-right mb-4">
            <div className="contribute-pledge__candidate justify-content-center justify-content-md-end">
              <span>To</span>
              <Avatar
                source={this.props.userDetails.profile_image}
                type="x-small"
                isCandidate
              />
              <span>
                {this.props.userDetails.first_name}{' '}
                {this.props.userDetails.last_name}
              </span>
            </div>
          </div>
        </div>
      </div>
    );
  }

  renderInnerForm(props: FormikComputedProps) {
    const { handleSubmit } = props;
    return (
      <form className="contribute-pledge__form" onSubmit={handleSubmit}>
        <div className="row no-gutters">
          {!this.props.isMobile || this.state.mobileStep === 1
            ? this.renderFirstStep(props)
            : null}
          {!this.props.isMobile || this.state.mobileStep === 2
            ? this.renderSecondStep(props)
            : null}
          {!this.props.isMobile || this.state.mobileStep === 3
            ? this.renderThirdStep(props)
            : null}
        </div>
        {this.renderFooter(props)}
      </form>
    );
  }

  renderFirstStep(props: FormikComputedProps) {
    const { touched, errors, values } = props;

    return (
      <div className="col-md-4 px-4 py-4 contribute-pledge__step1">
        <div className="row justify-content-between pt-4 pb-2">
          <div className="col-12 d-flex text-left align-items-center">
            <div className="contribute-pledge__total-text">
              <h4 className="contribute-pledge__label--big mb-0">Total</h4>
            </div>
          </div>
        </div>
        <div className="row pb-5">
          <div className="col-5 justify-content-end d-flex align-items-center">
            <div className="contribute-pledge__total-text-sign">$</div>
          </div>
          <div className="col-7 pl-0 d-flex align-items-center">
            <Input
              className="mb-0"
              label=""
              placeholder=""
              name="total"
              type="number"
              min={CONTRIBUTE_MIN_AMOUNT}
              max={this.getMaxContribution()}
              error={touched.total && errors.total ? errors.total : ''}
            />
          </div>
        </div>
        <div className="row">
          <div className="col-12 text-left">
            <p className="contribute-pledge__label">
              Pay ${parseFloat(values.total || 0).toFixed(2)} with:
            </p>
            <ContributePaymentMethodSelector
              error={
                touched.paymentMethod && errors.paymentMethod
                  ? errors.paymentMethod
                  : ''
              }
              customToken={this.props.customPaymentToken}
              customEmail={this.props.customPaymentEmail}
            />
            <p className="contribute-pledge__label--micro">
              {CONTRIBUTION_CREDIT_CARD_EXTRA_CHARGE}{' '}
              <span className="contribute-pledge__label--micro-strong">
                {CONTRIBUTION_ACH_FREE}
              </span>
            </p>
          </div>
        </div>
      </div>
    );
  }

  renderSecondStep(props: FormikComputedProps) {
    const { touched, errors } = props;
    const { ratingCondition } = this.state;
    return (
      <div className="col-md-4 px-4 py-4 contribute-pledge__step2">
        <div className="px-md-4 py-md-4">
          <h4 className="contribute-pledge__label--big mb-0 mt-1">Condition</h4>
          <div className="row contribute-pledge__condition pt-2 pb-4 mb-1">
            <div className="col-4 text-center fw-500">
              <a onClick={e => this.handleRatingConditionClick(e, 'decrease')}>
                <img
                  src={
                    ratingCondition === 'decrease'
                      ? ratingDecreaseSrc
                      : ratingEmptySrc
                  }
                  alt=""
                  className="pb-2"
                />
              </a>
              <br />
              Decrease
              <br />
              Rating
            </div>
            <div className="col-4 text-center fw-500">
              <a onClick={e => this.handleRatingConditionClick(e, 'maintain')}>
                <img
                  src={
                    ratingCondition === 'maintain'
                      ? ratingMaintainSrc
                      : ratingEmptySrc
                  }
                  alt=""
                  className="pb-2"
                />
              </a>
              <br />
              Maintain
              <br />
              Rating
            </div>
            <div className="col-4 text-center fw-500">
              <a onClick={e => this.handleRatingConditionClick(e, 'increase')}>
                <img
                  src={
                    ratingCondition === 'increase'
                      ? ratingIncreaseSrc
                      : ratingEmptySrc
                  }
                  alt=""
                  className="pb-2"
                />
              </a>
              <br />
              Increase
              <br />
              Rating
            </div>
          </div>
          <div className="row no-gutters pt-4 pb-3">
            <div className="col-4 pt-2 mt-1">
              <p className="contribute-pledge__label">Over the next</p>
            </div>
            <div className="col-8 pl-3">
              <Select
                label=""
                size="small"
                name="timeSpan"
                options={this.getSelectTimeSpanOptions()}
                error={
                  touched.timeSpan && errors.timeSpan ? errors.timeSpan : ''
                }
              />
            </div>
          </div>
        </div>
      </div>
    );
  }

  renderThirdStep(props: FormikComputedProps) {
    const { touched, errors, values } = props;
    const interests = _get(
      this.props,
      'userCandidateSubInterestsData.candidate.interests.interests',
      [],
    );

    return (
      <div className="col-md-4 px-4 py-4 contribute-pledge__step3">
        <div className="px-md-4 py-md-4">
          <h4 className="contribute-pledge__label--big mb-0 mt-1">About</h4>
          <Scrollbars universal style={{ height: 320 }}>
            <div>
              <div className="row fix-width">
                {interests.map(interest => {
                  return (
                    <div
                      key={interest.id}
                      className="contribute-pledge__interests-group">
                      <h4 className="contribute-pledge__label--medium my-4">
                        {interest.name}
                      </h4>
                      <RadioButtons
                        label=""
                        className="col-12"
                        items={interest.sub_interests.map(subInterest => ({
                          label: subInterest.name,
                          value: subInterest.id.toString(),
                        }))}
                        size="big"
                        name="subInterests"
                      />
                    </div>
                  );
                })}
                {!interests.length &&
                !this.props.userCandidateSubInterestsIsLoading ? (
                  <div className="col-12">
                    This candidate is not available to receive pledges.
                  </div>
                ) : null}
              </div>
            </div>
          </Scrollbars>
          {touched.subInterests && errors.subInterests ? (
            <span className="contribute-pledge__error">
              {errors.subInterests}
            </span>
          ) : null}
        </div>
      </div>
    );
  }

  renderFooter(props: FormikComputedProps) {
    const { touched, values, errors } = props;
    const timeSpan =
      parseInt(values.timeSpan) > 1 ? `${values.timeSpan} months` : 'month';
    const currentSubInterestLabel = this.getCurrentSubInterestLabel(
      values.subInterests,
    );

    return (
      <div className="contribute-pledge__footer">
        {this.props.onAddNewBankAccountRequest ? (
          <div className="row">
            <div className="col-3 pt-3">
              <Button
                className="text-left"
                type="button"
                buttonType="link"
                block
                noBorder
                onClick={this.props.onAddNewBankAccountRequest}
                size="small"
                noTextTransform={true}>
                Add new bank account
              </Button>
            </div>
          </div>
        ) : null}
        <div className="row pt-4 pb-2">
          <div className="col-md-4 text-center text-left-md order-2 order-md-1">
            <div className="d-flex pt-3 pb-2 pl-3">
              <Toggle
                name="public"
                error={touched.public && errors.public ? errors.public : ''}
              />
              <p className="modal-toggle-label text-uppercase">Public</p>
            </div>
            <hr className="mt-1 d-block d-md-none" />
          </div>
          <div className="col-md-4 text-center px-3 px-md-0 contribute-pledge__condition-text order-1 order-md-2">
            {currentSubInterestLabel ? (
              <p>
                I’ll contribute <strong>${values.total}</strong> if you{' '}
                <strong>{this.state.ratingCondition}</strong> your&nbsp;
                <strong>rating</strong> over the next{' '}
                <strong>{timeSpan}</strong> with the&nbsp;
                <strong>{currentSubInterestLabel}</strong>
              </p>
            ) : null}
          </div>
          <div className="col-md-4 pb-4 pb-md-0 d-flex align-items-center justify-content-md-center justify-content-md-end order-3">
            <Button
              type="button"
              buttonType="outline"
              className="mt-0"
              block
              noBorder
              onClick={() => this.props.onGoBackRequest()}
              size={this.props.isMobile ? 'small' : 'normal'}>
              Cancel
            </Button>
            {!this.props.isMobile || this.state.mobileStep === 3 ? (
              <Button
                type="submit"
                buttonType="primary"
                className="mt-0"
                block
                noBorder
                size={this.props.isMobile ? 'small' : 'normal'}
                loading={this.props.contributionPledgeDonationMakeIsLoading}>
                Contribute now
              </Button>
            ) : null}
            {this.props.isMobile && this.state.mobileStep < 3 ? (
              <Button
                type="button"
                buttonType="primary"
                className="mt-0"
                block
                noBorder
                onClick={() => this.handleMobileNextButton(props)}
                size="small">
                Next
              </Button>
            ) : null}
          </div>
        </div>
      </div>
    );
  }

  getMaxContribution = () => {
    return receivingContributionLimit(this.props.userDetails);
  };

  getFormikProps() {
    const maxContribution = this.getMaxContribution();
    return {
      initialValues: {
        total: '0',
        paymentMethod: 'newCard',
        timeSpan: '12',
        public: '1',
        subInterests: '',
      },
      validationSchema: yup.object().shape({
        total: yup
          .number()
          .min(1, 'You should contribute at least one Dollar.')
          .max(
            maxContribution,
            `You can contribute up to ${formatCurrency(maxContribution)}.`,
          ),
        paymentMethod: yup.string().required('This field is required'),
        timeSpan: yup.string().required('This field is required'),
        subInterests: yup
          .string()
          .required('Please select at least one option.'),
      }),
      onSubmit: this.handleFormSubmit,
      ref: this.setFormikRef,
    };
  }

  getSelectTimeSpanOptions() {
    // TODO: confirm allowed options
    return [
      { label: '12 months (full cycle)', value: '12' },
      { label: '6 months', value: '6' },
      { label: '3 months', value: '3' },
      { label: '1 month', value: '1' },
    ];
  }

  getCurrentSubInterestLabel(subInterestId) {
    const interests = _get(
      this.props,
      'userCandidateSubInterestsData.candidate.interests.interests',
      [],
    );
    let label = '';

    interests.forEach(interest => {
      interest.sub_interests.forEach(subInterest => {
        if (subInterest.id.toString() === subInterestId) {
          label = subInterest.name;
        }
      });
    });

    return label;
  }

  setFormikRef = (ref: Formik) => {
    this.formikForm = ref;
  };

  handleGoBackButton = () => {
    if (
      this.props.onGoBackRequest &&
      (!this.props.isMobile || this.state.mobileStep === 1)
    ) {
      this.props.onGoBackRequest();
    } else {
      this.setState((prevState: State) => ({
        mobileStep: prevState.mobileStep - 1,
      }));
    }
  };

  handleMobileNextButton = (props: FormikComputedProps) => {
    const { values, setTouched } = props;
    if (
      this.state.mobileStep === 1 &&
      (!parseFloat(values.total) || !values.paymentMethod)
    ) {
      setTouched({
        total: true,
        paymentMethod: true,
        timeSpan: false,
        public: true,
        subInterests: false,
      });
    } else if (this.state.mobileStep === 2 && !values.timeSpan) {
      setTouched({
        total: true,
        paymentMethod: true,
        timeSpan: true,
        public: true,
        subInterests: false,
      });
    } else {
      this.setState((prevState: State) => ({
        mobileStep: prevState.mobileStep + 1,
      }));
    }
  };

  handleRatingConditionClick = (
    e,
    ratingCondition: 'increase' | 'decrease' | 'maintain',
  ) => {
    e.preventDefault();
    this.setState(() => ({ ratingCondition }));
  };

  handleFormSubmit = (values: any) => {
    if (values.paymentMethod === 'newCard') {
      this.props.onPaymentRequest({
        formValues: Object.assign(
          { ratingCondition: this.state.ratingCondition },
          values,
        ),
        typeOfContribution: 'pledge',
        userDetails: this.props.userDetails,
      });
    } else {
      let payload: any = {
        amount_in_cents: parseInt(parseFloat(values.total) * 100),
        candidate_id: this.props.userDetails.id,
        public: values.public === '1',
        stripe_source_id: values.paymentMethod,
        period_in_months: parseInt(values.timeSpan),
        condition: this.state.ratingCondition,
        sub_interest_id: values.subInterests,
      };

      if (this.props.groupId) {
        payload.group_id = this.props.groupId;
      }

      if (this.props.customPaymentEmail && this.props.customPaymentToken) {
        payload.customEmail = this.props.customPaymentEmail;
        payload.customToken = this.props.customPaymentToken;
      }

      this.props.makePledgeDonation(payload);
    }
  };
}

const mapStateToProps = (state, ownProps) => {
  return {
    ...account.mapStateToProps(state),
    ...accountDetails.mapStateToProps(state),
    ...userCandidateSubInterests.mapStateToProps(state),
    ...contributionPledgeDonationMake.mapStateToProps(state),
    ...ownProps,
  };
};

const mapDispatchToProps = dispatch => {
  return {
    ...userCandidateSubInterests.mapDispatchToProps(dispatch),
    ...contributionPledgeDonationMake.mapDispatchToProps(dispatch),
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(withDisplayDimensions(ContributePledge));
