// @flow
import { createAction } from 'redux-actions';
import {
  RACE_FEED_ERROR,
  RACE_FEED_LOADING,
  RACE_FEED_RESET,
  RACE_FEED_SUCCESS,
  RACE_FEED_SET_ITEM_AS_FIRST,
  RACE_FEED_LIKE_POST,
  RACE_FEED_UNLIKE_POST,
  RACE_FEED_SHARE_POST,
} from './raceFeed.constants';
import { instance as rorRacesApiProvider } from '../../../services/RoRRacesApiProvider';
import type { GetRaceFeedPayload } from '../../../services/RoRRacesApiProvider';

export const raceFeedError = createAction(RACE_FEED_ERROR);

export const raceFeedLoading = createAction(RACE_FEED_LOADING);

export const raceFeedSuccess = createAction(RACE_FEED_SUCCESS);

export const raceFeedReset = createAction(RACE_FEED_RESET);

export const raceFeedSetItemAsFirst = createAction(RACE_FEED_SET_ITEM_AS_FIRST);

export const raceFeedLikePost = createAction(RACE_FEED_LIKE_POST);

export const raceFeedUnLikePost = createAction(RACE_FEED_UNLIKE_POST);

export const raceFeedSharePost = createAction(RACE_FEED_SHARE_POST);

export const getRaceFeed = (payload: GetRaceFeedPayload) => {
  return async (dispatch: Function) => {
    try {
      dispatch(raceFeedLoading());

      const response = await rorRacesApiProvider.raceFeed(payload);

      dispatch(raceFeedSuccess(response));
    } catch (error) {
      dispatch(raceFeedError(error));
    }
  };
};
