//@flow
import * as React from 'react';
import { Link as RouterLink } from 'react-router-dom';
import classNames from 'classnames';

import './Link.scss';

type Props = {
  href: string,
  className?: string,
  children: any,
  isExternal?: boolean,
  type?: 'link' | 'secondary' | 'button' | 'dark',
  buttonType?: 'primary' | 'outline',
};

const Link = (props: Props) => {
  return props.isExternal ? (
    <a href={props.href} className={getClassName(props)}>
      {props.children}
    </a>
  ) : (
    <RouterLink
      to={props.href === 'back' ? '#' : props.href}
      className={getClassName(props)}>
      {props.children}
    </RouterLink>
  );
};

function getClassName(props) {
  return classNames({
    link: true,
    [`link--${props.type || ''}`]: props.type,
    'btn button button--primary button--no-text-transform':
      props.type === 'button',
    [props.className || '']: props.className,
  });
}

export default Link;
