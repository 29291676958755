// @flow
import makeDefaultServerCallReducer from '../../utilities/makeDefaultServerCallReducer';
import {
  USER_PROFILE_FOLLOWERS_SUCCESS,
  USER_PROFILE_FOLLOWERS_RESET,
  USER_PROFILE_FOLLOWERS_LOADING,
  USER_PROFILE_FOLLOWERS_ERROR,
} from './userProfileFollowers.constants';

const initialState = {
  isLoading: false,
  error: null,
  data: null,
};

export const reducer = makeDefaultServerCallReducer({
  loadingActions: [USER_PROFILE_FOLLOWERS_LOADING],
  successActions: [USER_PROFILE_FOLLOWERS_SUCCESS],
  errorActions: [USER_PROFILE_FOLLOWERS_ERROR],
  resetActions: [USER_PROFILE_FOLLOWERS_RESET],
  initialState,
});
