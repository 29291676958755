export const GROUP_MEMBER_PENDING_REQUESTS_SUCCESS =
  '@@prytany/global/GROUP_MEMBER_PENDING_REQUESTS_SUCCESS';
export const GROUP_MEMBER_PENDING_REQUESTS_LOADING =
  '@@prytany/global/GROUP_MEMBER_PENDING_REQUESTS_LOADING';
export const GROUP_MEMBER_PENDING_REQUESTS_ERROR =
  '@@prytany/global/GROUP_MEMBER_PENDING_REQUESTS_ERROR';
export const GROUP_MEMBER_PENDING_REQUESTS_RESET =
  '@@prytany/global/GROUP_MEMBER_PENDING_REQUESTS_RESET';
export const GROUP_MEMBER_PENDING_REQUESTS_NEXT_PAGE =
  '@@prytany/global/GROUP_MEMBER_PENDING_REQUESTS_NEXT_PAGE';
export const GROUP_MEMBER_PENDING_REQUESTS_REMOVE_ITEM =
  '@@prytany/global/GROUP_MEMBER_PENDING_REQUESTS_REMOVE_ITEM';
export const GROUP_MEMBER_PENDING_REQUESTS_REMOVE_ITEMS_FOR_ROLE =
  '@@prytany/global/GROUP_MEMBER_PENDING_REQUESTS_REMOVE_ITEMS_FOR_ROLE';
