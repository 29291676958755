// @flow
import React from 'react';
import _get from 'lodash/get';
import classNames from 'classnames';
import moment from 'moment';
import { connect } from 'react-redux';
import { Pagination, message } from 'antd';
import Button from '../../components/Button/Button';
import accountInboxMessageList from '../../redux/modules/accountInboxMessageList/accountInboxMessageList.containers';
import accountInboxMessageDetails from '../../redux/modules/accountInboxMessageDetails/accountInboxMessageDetails.containers';
import accountInboxMessageDelete from '../../redux/modules/accountInboxMessageDelete/accountInboxMessageDelete.containers';
import groupRequestJoin from '../../redux/modules/groupRequestJoin/groupRequestJoin.containers';
import InboxMessage from '../../components/InboxMessage/InboxMessage';
import ModalNewMessage from '../../components/ModalNewMessage/ModalNewMessage';
import InboxMessageThread from '../../components/InboxMessageThread/InboxMessageThread';

import type {
  AccountInboxMessageListMapDispatchToProps,
  AccountInboxMessageListMapStateToProps,
} from '../../redux/modules/accountInboxMessageList/accountInboxMessageList.containers';
import Loading from '../../components/Loading/Loading';
import type {
  UserInboxGroupInvitationMessageDetails,
  UserInboxGroupInvitationMessageListItem,
} from '../../services/RoRUsersApiProvider';
import Avatar from '../../components/Avatar/Avatar';
import type {
  AccountInboxMessageDetailsMapDispatchToProps,
  AccountInboxMessageDetailsMapStateToProps,
} from '../../redux/modules/accountInboxMessageDetails/accountInboxMessageDetails.containers';
import type {
  AccountInboxMessageDeleteMapDispatchToProps,
  AccountInboxMessageDeleteMapStateToProps,
} from '../../redux/modules/accountInboxMessageDelete/accountInboxMessageDelete.containers';
import withDisplayDimensions from '../../hoc/withDisplayDimensions';
import type { WithDisplayDimensionsOutputProps } from '../../hoc/withDisplayDimensions';
import type {
  GroupRequestJoinMapDispatchToProps,
  GroupRequestJoinMapStateToProps,
} from '../../redux/modules/groupRequestJoin/groupRequestJoin.containers';
import './InboxPage.scss';
import { isCandidate, isCitizen } from '../../utilities/authorization';
import isPrytanyVerified from '../../utilities/isPrytanyVerified';

type Props = AccountInboxMessageListMapDispatchToProps &
  AccountInboxMessageListMapStateToProps &
  AccountInboxMessageDetailsMapDispatchToProps &
  AccountInboxMessageDetailsMapStateToProps &
  AccountInboxMessageDeleteMapDispatchToProps &
  AccountInboxMessageDeleteMapStateToProps &
  GroupRequestJoinMapDispatchToProps &
  GroupRequestJoinMapStateToProps &
  WithDisplayDimensionsOutputProps & {
    children: any,
    location: any,
    history: any,
  };

type State = {
  selectedMessageId: number | null,
  modalOpened: string | null,
};

const MESSAGE_PAGE_LIMIT = 6;

// TODO: mobile version
class InboxPage extends React.Component<Props, State> {
  constructor(props: Props) {
    super(props);

    this.state = {
      selectedMessageId: null,
      modalOpened: null,
    };

    this.props.accountInboxMessageListReset();
    this.props.accountInboxMessageDeleteReset();
    this.props.accountInboxMessageDetailsReset();
  }

  componentDidMount() {
    this.props.getAccountInboxMessageList({
      page: 1,
      limit: MESSAGE_PAGE_LIMIT,
    });
  }

  componentDidUpdate(prevProps: Props, prevState: State) {
    if (
      this.state.selectedMessageId &&
      prevState.selectedMessageId !== this.state.selectedMessageId
    ) {
      this.props.getAccountInboxMessageDetails({
        messageId: this.state.selectedMessageId,
      });
    }

    this.handleRemoveMessageSuccess(prevProps);
    this.handleRequestGroupJoinSuccess(prevProps);
    this.displayErrors(prevProps);
  }

  render() {
    return (
      <div className="inbox-page">
        {this.renderTopBar()}
        {this.renderContent()}
      </div>
    );
  }

  renderTopBar() {
    return (
      <div className="container-fluid inbox-page__top-bar">
        <div className="row fix-width">
          <div className="col-8">
            <div className="row pt-5">
              <div className="col-9 col-lg-10 pl-4 pt-1">
                <h1 className="name">Inbox</h1>
              </div>
            </div>
          </div>
          <div className="col-4 pt-5 mt-1 pr-0">
            <div className="row">
              <div className="col text-right">
                <Button
                  onClick={() => this.openModal('newMessage')}
                  buttonType="primary"
                  type="button"
                  className="mb-3">
                  New Message
                </Button>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }

  openModal = (modalKey: string = null) => {
    this.setState(() => ({ modalOpened: modalKey }));
  };

  closeModal = () => {
    this.setState(() => ({ modalOpened: null }));
  };

  handleSendMessageSuccess = () => {
    message.success('Message sent successfully!');
    this.closeModal();
    this.props.getAccountInboxMessageList({
      page: 1,
      limit: MESSAGE_PAGE_LIMIT,
    });
    this.props.getAccountInboxMessageDetails({
      messageId: this.state.selectedMessageId,
    });
  };

  handleSendMessageError = () => {
    message.error('Something went wrong. Please try after some time!');
  };

  loadHistory = () => {
    this.props.getAccountInboxMessageDetailsNextPage({
      messageId: this.state.selectedMessageId,
    });
  };

  renderContent() {
    const messages = _get(
      this.props,
      'accountInboxMessageListData.data.messages',
      [],
    );

    return (
      <section className="inbox-page__content">
        <Loading
          loading={this.props.accountInboxMessageListIsLoading}
          type="spinner">
          <div className="row fix-width">
            <div className="col-12 col-md-4">
              {messages.length ? (
                <div className="d-flex justify-content-center align-items-center flex-column mb-5">
                  <ul className="inbox-page__message-list">
                    {messages.map(this.renderMessageListItem)}
                  </ul>
                  <Pagination
                    size="small"
                    hideOnSinglePage={true}
                    defaultCurrent={
                      this.props.accountInboxMessageListData.current_page
                    }
                    pageSize={MESSAGE_PAGE_LIMIT}
                    total={
                      this.props.accountInboxMessageListData.total_pages *
                      MESSAGE_PAGE_LIMIT
                    }
                    onChange={this.handlePageChange}
                  />
                </div>
              ) : null}
            </div>
            <div className="col-12 col-md-8">{this.renderMessageDetails()}</div>
            <ModalNewMessage
              isOpen={this.state.modalOpened === 'newMessage'}
              onCloseRequest={this.closeModal}
              onSuccess={this.handleSendMessageSuccess}
              onError={this.handleSendMessageError}
            />
          </div>
        </Loading>
      </section>
    );
  }

  renderMessageListItem = (
    message: UserInboxGroupInvitationMessageListItem,
  ) => {
    const details = message.details;
    const sender = message.sender;
    const date = moment(message.created_at);

    // TODO: add support for direct messages
    return (
      <li
        className={classNames({
          'message-list-item': true,
          'message-list-item--active':
            message.id === this.state.selectedMessageId,
        })}
        key={message.id}
        onClick={() => this.handleMessageSelect(message)}>
        {sender.profile_image ? (
          <Avatar
            source={sender.profile_image}
            type="x-small"
            isCandidate={isCandidate(sender)}
            verifiedLevel={sender.verified_level}
            verifiedDetail={sender.verified_detail}
          />
        ) : null}
        <div className="message-list-item__description">
          {details.group && (
            <span className="message-list-item__title">
              Group Invitation: <strong>{details.group.name}</strong>
            </span>
          )}
          <span className="message-list-item__send-info">
            {`${sender.first_name} ${sender.last_name}`}{' '}
            {date.format('MMM').toUpperCase()} {date.format('Do, HH:mm A')}
          </span>
        </div>
        {!message.is_read ? (
          <div className="message-list-item__not-read" />
        ) : null}
      </li>
    );
  };

  renderMessageDetails() {
    const messages = _get(
      this.props,
      'accountInboxMessageListData.data.messages',
      [],
    );

    if (!messages.length && !this.props.accountInboxMessageListIsLoading) {
      return this.renderEmptyDetailsBox("You don't have any messages.");
    } else if (
      messages.length &&
      !this.state.selectedMessageId &&
      !this.props.accountInboxMessageListIsLoading
    ) {
      const emptyDetailsBoxMessage = this.props.isMobile
        ? 'Select a message from the list.'
        : 'Select a message from the left column.';
      return this.renderEmptyDetailsBox(emptyDetailsBoxMessage);
    } else if (messages.length && this.state.selectedMessageId) {
      const message: UserInboxGroupInvitationMessageDetails = _get(
        this.props,
        'accountInboxMessageDetailsData.data.message',
      );
      let component;
      if (message) {
        switch (message.content_type) {
          case 'conversation':
          case 'group_invitation':
            component = (
              <InboxMessage
                sender={message.sender}
                date={message.created_at}
                details={message.details}
                type={message.content_type}
                body={message.body}
                onRemoveButtonClick={() =>
                  this.handleRemoveMessage({ messageId: message.id })
                }
                onJoinGroupButtonClick={() =>
                  this.handleJoinGroup({ groupId: message.details.group.id })
                }
                removeLoading={this.props.accountInboxMessageDeleteIsLoading}
                joinGroupLoading={this.props.groupRequestJoinIsLoading}
              />
            );
            break;
          case 'conversation_thread':
            component = (
              <InboxMessageThread
                shouldScroll={
                  this.props.accountInboxMessageDetailsData.current_page === 1
                }
                sender={message.sender}
                date={message.created_at}
                details={message.details}
                onMessageSendSuccess={this.onMessageSendSuccess}
                loadHistory={this.loadHistory}
                messageThread={message.messages}
                onRemoveButtonClick={() =>
                  this.handleRemoveMessage({ messageId: message.id })
                }
                removeLoading={this.props.accountInboxMessageDeleteIsLoading}
                messageDetailsIsLoading={
                  this.props.accountInboxMessageDetailsIsLoading
                }
              />
            );
            break;
          default:
            component = null;
        }
      }
      return (
        <Loading
          loading={
            (!message || this.props.accountInboxMessageDetailsIsLoading) &&
            !this.props.accountInboxMessageDetailsData
          }
          type="spinner">
          {message ? component : null}
        </Loading>
      );
    }

    return null;
  }

  onMessageSendSuccess = () => {
    this.props.getAccountInboxMessageDetails({
      messageId: this.state.selectedMessageId,
    });
  };

  renderEmptyDetailsBox(message: string) {
    return (
      <div className="inbox-page__empty-details-box">
        <p>{message}</p>
      </div>
    );
  }

  displayErrors(prevProps: Props) {
    const defaultError = 'An error has occurred please reload this page.';

    if (
      this.props.accountInboxMessageDetailsError &&
      !prevProps.accountInboxMessageDetailsError
    ) {
      message.error(
        this.props.accountInboxMessageDetailsError.localMessage || defaultError,
        3,
      );
      this.props.accountInboxMessageDetailsReset();
    } else if (
      this.props.accountInboxMessageListError &&
      !prevProps.accountInboxMessageListError
    ) {
      message.error(
        this.props.accountInboxMessageListError.localMessage || defaultError,
        3,
      );
      this.props.accountInboxMessageListReset();
    } else if (
      this.props.accountInboxMessageDeleteError &&
      !prevProps.accountInboxMessageDeleteError
    ) {
      message.error(
        this.props.accountInboxMessageDeleteError.localMessage || defaultError,
        3,
      );
      this.props.accountInboxMessageDeleteReset();
    } else if (
      this.props.groupRequestJoinError &&
      !prevProps.groupRequestJoinError
    ) {
      if (this.props.groupRequestJoinError.httpCode === 422) {
        message.warning('You are already part of this group.');
      } else {
        message.error(
          this.props.groupRequestJoinError.localMessage || defaultError,
          3,
        );
      }
    }
  }

  handleRequestGroupJoinSuccess(prevProps: Props) {
    if (this.props.groupRequestJoinData && !prevProps.groupRequestJoinData) {
      message.success('You have successfully joined in the group.', 3);
      this.props.groupRequestJoinReset();
    }
  }

  handleRemoveMessageSuccess(prevProps: Props) {
    if (
      this.props.accountInboxMessageDeleteData &&
      !prevProps.accountInboxMessageDeleteData
    ) {
      const currentPageMessages = _get(
        this.props,
        'accountInboxMessageListData.data.messages',
        [],
      );
      const currentPage = _get(
        this.props,
        'accountInboxMessageListData.current_page',
        1,
      );
      message.success('Message successfully deleted.', 3);

      if (currentPageMessages <= 1 && currentPage > 1) {
        this.handlePageChange(currentPage - 1);
      } else if (currentPageMessages <= 1) {
        this.handlePageChange(1);
      } else {
        this.setState(() => ({ selectedMessageId: null }));
      }

      this.props.accountInboxMessageDeleteReset();
    }
  }

  handleRemoveMessage = ({ messageId }: { messageId: number }) => {
    this.props.deleteAccountInboxMessage({ messageId });
  };

  handleJoinGroup = ({ groupId }: { groupId: number }) => {
    this.props.requestJoinGroup({ groupId });
  };

  handlePageChange = pageNumber => {
    this.props.getAccountInboxMessageList({
      page: pageNumber,
      limit: MESSAGE_PAGE_LIMIT,
    });
    this.setState(() => ({ selectedMessageId: null }));
  };

  handleMessageSelect = (message: UserInboxGroupInvitationMessageListItem) => {
    this.setState(() => ({ selectedMessageId: message.id }));
  };
}

const mapStateToProps = (state, ownProps) => {
  return {
    ...accountInboxMessageList.mapStateToProps(state),
    ...accountInboxMessageDetails.mapStateToProps(state),
    ...accountInboxMessageDelete.mapStateToProps(state),
    ...groupRequestJoin.mapStateToProps(state),
    ...ownProps,
  };
};

const mapDispatchToProps = dispatch => {
  return {
    ...accountInboxMessageList.mapDispatchToProps(dispatch),
    ...accountInboxMessageDetails.mapDispatchToProps(dispatch),
    ...accountInboxMessageDelete.mapDispatchToProps(dispatch),
    ...groupRequestJoin.mapDispatchToProps(dispatch),
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(withDisplayDimensions(InboxPage));
