import {
  userProfileContributionsReset as reset,
  getUserProfileContributions,
} from './userProfileContributions.actions';

export const mapStateToProps = state => {
  return {
    profileContributionsData: state.userProfileContributions.data,
    profileContributionsError: state.userProfileContributions.error,
    profileContributionsIsLoading: state.userProfileContributions.isLoading,
  };
};

export const mapDispatchToProps = dispatch => {
  return {
    getUserProfileContributions(payload) {
      dispatch(getUserProfileContributions(payload));
    },
    profileContributionsReset() {
      dispatch(reset());
    },
  };
};

export type UserProfileContributionsMapStateToProps = {
  profileContributionsData: any,
  profileContributionsError: any,
  profileContributionsIsLoading: boolean,
};

export type UserProfileContributionsMapDispatchToProps = {
  getUserProfileContributions: Function,
  profileContributionsReset: Function,
};

export default {
  mapStateToProps,
  mapDispatchToProps,
};
